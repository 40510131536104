<template>
    <v-card
        :loading="loading"
        style="overflow-x: hidden"
        class="px-4 py-4">
        <v-row class="pb-0" justify="space-between" align="center">
            <v-col class="ml-10">
                <div class="d-flex justify-center align-center">
                    <v-btn 
                        icon 
                        @click="move('left')"
                        :disabled="disableMoveLeft">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-card-title class="d-flex justify-center align-center">{{ selectedMonth }} {{ selectedYear }}</v-card-title>
                    <v-btn 
                        icon 
                        @click="move('right')"
                        :disabled="disableMoveRight">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col>
                <v-card-title class="d-flex justify-center align-center pb-0">{{ productSKU }}</v-card-title>
            </v-col>
            <v-col>
                <v-card-title style="width: 10vw;" class="d-flex justify-center align-center pb-0">
                    <v-text-field
                        label="Restock Qty"
                        hide-details
                        v-model="tempRestockQty"
                        @change="changeRestockQty()"/>
                </v-card-title>
            </v-col>
            <v-btn class="mr-4 mt-4" icon @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>
        <v-card-subtitle class="d-flex justify-center align-center py-0">
            {{ productName }} - Floor: {{ productFloor }}
        </v-card-subtitle>
        <v-row>
            <v-col>
                <v-card-title class="d-flex justify-center align-center pt-0">
                    <u>Forecast</u>
                    <v-switch
                        class="pl-4 mt-0"
                        hide-details
                        color="accent"
                        label="Show Sales Forecast"
                        v-model="showSalesForecastCategories"/>
                </v-card-title>
                <v-card v-if="selectedForecastDetails.length" >
                    <v-card-title class="d-flex justify-space-between">
                        <u>{{ forecastTitle }}</u>
                        <v-spacer/>
                        <div class="d-flex-column align-end justify-end">
                            <u>Qty</u>
                            <v-card-subtitle class="px-0 py-0">Total: {{ totalForecastQty }}</v-card-subtitle>
                        </div>
                    </v-card-title>
                </v-card>
                <div v-if="selectedForecastDetails.length" style="max-height: 40vh; overflow-y: scroll;">
                    <v-card
                        class="d-flex-column"
                        v-for="(item, index) in forecastList"
                        :key="index">
                        <v-card-title class="d-flex justify-space-between">
                            {{ item.name }}
                            <v-spacer/>
                            {{ determineForecastQty(item.qty) }}
                        </v-card-title>
                    </v-card>
                </div>
                <div v-else>
                    <v-card class="d-flex justify-center align-center">
                        <v-card-title class="d-flex justify-center align-center">
                            No Forecast Found
                        </v-card-title>
                    </v-card>
                </div>
            </v-col>
            <v-col>
                <v-card-title class="d-flex justify-center align-center pt-0">
                    <u>Branch Transfers</u>
                </v-card-title>
                <v-card v-if="selectedBranchTransferDetails.length">
                    <v-card-title class="d-flex justify-space-between">
                        <u>Reference</u>
                        <v-spacer/>
                        <u>ETA</u>
                        <v-spacer/>
                        <u>Qty</u>
                    </v-card-title>
                </v-card>
                <div v-if="selectedBranchTransferDetails.length" style="max-height: 40vh; overflow-y: scroll;">
                    <v-card
                        class="d-flex-column"
                        v-for="(item, index) in selectedBranchTransferDetails"
                        :key="index">
                        <v-card-title class="d-flex justify-space-between">
                            <a :href="cin7Link(item.Id)" target="_blank">{{ item.Reference }}</a>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        class="pl-8"
                                        icon 
                                        color="primary" 
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="refreshBranchTransfer(item.Id)"
                                        :loading="refreshLoading">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Only Use If Necessary</span>
                            </v-tooltip>
                            <v-spacer/>
                            <div class="d-flex-column justify-center align-center">
                                <div class="d-flex justify-center align-center">
                                    {{ item.ETAMonth }} / {{ item.ETADate }} / {{ item.ETAYear }}
                                </div>
                                <v-card-subtitle 
                                    class="px-0 py-0"
                                    v-if="isInSecondHalf(item.ETADate, item.ETAMonth, item.ETAYear)">
                                    In Second Half of Previous Month
                                </v-card-subtitle>
                            </div>
                            <v-spacer/>
                            {{ item.Qty }}
                        </v-card-title>
                    </v-card>
                </div>
                <div v-else>
                    <v-card class="d-flex justify-center align-center">
                        <v-card-title class="d-flex justify-center align-center">
                            No Branch Transfers Found
                        </v-card-title>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <v-row justify="space-between">
            <v-col>
                <div :class="'d-flex justify-start align-center ' + startQtyClass">
                Start Qty<br>
                {{ selectedStartQty }}
                </div>
            </v-col>
            <v-col>
                <div :class="'d-flex justify-end align-center ' + endQtyClass">
                End Qty<br>
                {{ selectedEndQty }}
                </div>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-card>
</template>

<script>
export default {
    props: {
        loading: {type: Boolean},
        opsGroup: {type: String},
        selectedMonth: {type: String},
        selectedMonthNum: {type: Number},
        prevMonthSelected: {type: Number},
        selectedYear: {type: Number},
        prevYearSelected: {type: Number},
        productSKU: {type: String},
        selectedRestockQty: {type: Number},
        productName: {type: String},
        selectedStartQty: {type: Number},
        selectedEndQty: {type: Number},
        selectedBranchTransferDetails: {type: Array},
        selectedForecastDetails: {type: Array},
        disableMoveLeft: {type: Boolean},
        disableMoveRight: {type: Boolean},
        productFloor: {type: Number},
    },
    beforeMount() {
        this.tempRestockQty = this.$props.selectedRestockQty
    },
    watch: {
        selectedRestockQty: function(newValue) {
            this.tempRestockQty = newValue
        }
    },
    data() {
        return {
            showSalesForecastCategories: false,
            tempRestockQty: 0,
            refreshLoading: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        closeDialog() {
            this.$emit('close-restock-detail-modal')
        },
        changeRestockQty() {
            this.$emit('change-restock-qty-from-dialog', Number(this.tempRestockQty))
        },
        cin7Link(id) {
            return 'https://go.cin7.com/Cloud/TransactionEntry/TransactionEntry.aspx?idCustomerAppsLink=985828&OrderId=' + id
        },
        move(direction) {
            this.$emit('move-months', direction)
        },
        isInSecondHalf(date, month, year) {
            console.log(month);
            let halfMonth = Math.round(new Date(year,month - 1, 0).getDate() / 2)
            console.log(halfMonth);
            if (date > halfMonth) {
                return true
            } else {
                return false
            }
        },
        //returns a fraction if the selected month is the current one and splits the forecast into the fraction and the whole
        //else returns the whole forecast
        determineForecastQty(qty) {
            let d = new Date()
            let month = d.getMonth() + 1
            let year = d.getFullYear()
            if (this.selectedMonthNum === month && this.selectedYear === year) {
                let currentDay = d.getDate()
                let daysInMonth = new Date(year, month - 1, 0).getDate()
                let fractionOfMonth = (daysInMonth - currentDay + 1) / daysInMonth
                let fractionOfForecast = qty * fractionOfMonth
                return Math.round(fractionOfForecast * 100) / 100 + ' / ' + qty
            } else {
                return qty
            }
        },
        refreshBranchTransfer(id) {
            //AWS Url
            //https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_RefreshBranchTransfer?newFunction=true&tab=code
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/branchtransfers'
            const data = {
                id: id
            }
            const request = {
                method: 'PUT',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.refreshLoading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }

                setTimeout(() => {
                    this.$emit('refresh-branch-transfers', id)
                    this.refreshLoading = false
                    this.snack(data.message, 'success', 3000)
                }, 30000)

            }).catch(error => {
                this.refreshLoading = false
                this.snack(error, 'error', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        forecastList() {
            let list = {}
            let details = this.$props.selectedForecastDetails

            if (!this.showSalesForecastCategories) {
                for (let i = 0; i < details.length; i++) {
                    if (Object.prototype.hasOwnProperty.call(list, details[i].Ops_Forecast)) {
                        list[details[i].Ops_Forecast] += details[i].Qty
                    } else {
                        list[details[i].Ops_Forecast] = details[i].Qty
                    }
                }
            } else {
                for (let i = 0; i < details.length; i++) {
                    if(Object.prototype.hasOwnProperty.call(list, details[i].Forecast_Name)) {
                        list[details[i].Forecast_Name] += details[i].Qty
                    } else {
                        list[details[i].Forecast_Name] = details[i].Qty
                    }
                }
            }
            let arrayList = []
            let tempObject = {}

            for (let item in list) {
                if (list[item] === 0) {
                    delete list[item]
                } else {
                    list[item] = Math.round(list[item])
                    tempObject = {
                        name: item,
                        qty: list[item]
                    }
                    arrayList.push(tempObject)
                }
            }
            let sortedArray = arrayList.sort((a,b) => b.qty - a.qty)
            return sortedArray
        },
        forecastTitle() {
            if (this.showSalesForecastCategories) {
                return 'Sales Forecast Category'
            } else {
                return 'Ops Forecast Category'
            }
        },
        totalForecastQty() {
            let d = new Date()
            let thisMonthIndex = d.getMonth()
            let thisMonth = thisMonthIndex + 1
            let thisYear = d.getFullYear()

            let fractionOfMonth = 1
            if (this.$props.selectedMonthNum === thisMonth && this.$props.selectedYear === thisYear) {
                let currentDay = d.getDate()
                let daysInMonth = new Date(thisYear, thisMonthIndex, 0).getDate()
                fractionOfMonth = (daysInMonth - currentDay + 1) / daysInMonth
            }
            let total = this.$props.selectedForecastDetails.reduce((sum, record) => {
                return sum + record.Qty
            },0)
            if (fractionOfMonth === 1) {
                return Math.round(total)
            } else {
                return Math.round(total * fractionOfMonth) + " / " + Math.round(total)
            }
        },
        endQtyClass() {
            if (this.$props.productFloor > this.$props.selectedEndQty) {
                return 'red--text'
            } else if (this.$props.productFloor === this.$props.selectedEndQty) {
                return 'orange--text'
            } else {
                return 'green--text'
            }
        },
        startQtyClass() {
            if (this.$props.productFloor > this.$props.selectedStartQty) {
                return 'red--text'
            }  else if (this.$props.productFloor === this.$props.selectedStartQty) {
                return 'orange--text'
            } else {
                return 'green--text'
            }
        },
    }
}
</script>

<style>

</style>