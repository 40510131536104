<template>
    <v-app>
        <v-main>
            <v-row width="90vw" justify="center" class="pb-4 px-8">
                <v-text-field
                    label="Ask a Question"
                    hide-details
                    v-model="question">
                    <template v-slot:append>
                        <v-autocomplete
                            :items="types"
                            hide-details
                            label="Question Type"
                            item-text="Type"
                            item-value="Id"
                            v-model="type">
                            <template v-slot:append>
                                <v-btn icon x-small @click="type = ''">
                                    <v-icon color="black">mdi-close-circle-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-autocomplete>
                    </template>
                </v-text-field>
            </v-row>
            <v-row class="py-4" justify="center" v-for="(question, index) in filteredQuestions" :key="index">
                <v-card width="80vw" height="100%">
                    <v-card-title class="d-flex justify-center align-center">
                        {{ getType(question.Type) }}
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                        {{ question.Question }}
                    </v-card-subtitle>
                    <v-divider class="mx-8"/>
                    <v-card-text class="text-center" v-if="question.Answer !== null">
                        {{ question.Answer }}
                    </v-card-text>
                    <template v-else>
                        <v-card-text class="text-center">
                            This question has not been answered yet... poke the Developor?
                        </v-card-text>
                        <v-card-actions class="d-flex justify-center align-center">
                            <v-btn
                                @click="poke(question)"
                                color="primary"
                                :loading="loading">
                                POKE
                            </v-btn>
                        </v-card-actions>
                    </template> 
                </v-card>
            </v-row>
            <v-row class="pt-4" justify="center">
                <v-card width="80vw" height="25vh">
                    <v-card-title class="d-flex justify-center align-center">
                        Don't See Your Question Here? 
                    </v-card-title>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn color="primary" @click="newQuestion.dialog = true">
                            ASK HERE
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>
            <v-dialog v-model="newQuestion.dialog" width="50vw">
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Ask a question
                    </v-card-title>
                    <v-textarea
                        v-model="newQuestion.question"
                        label="Question"/>
                    <v-select
                        v-model="newQuestion.type"
                        :items="types"
                        label="Question Type"
                        item-value="Id"
                        item-text="Type"/>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            color="primary"
                            :disabled="!newQuestion.question || !newQuestion.type"
                            @click="askQuestion()"
                            :loading="loading">
                            ASK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - FAQs'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','FAQs')
        this.getQuestions()
    },
    data() {
        return {
            questions: [],
            question: '',
            types: [],
            type: '',
            newQuestion: {
                dialog: false,
                question: '',
                type: ''
            },
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        getType(id) {
            return this.types.find(item => item.Id === id).Type
        },
        async getQuestions() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_GetFAQs?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/faq',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    faqs,
                    types
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                this.questions = faqs
                this.types = types
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async askQuestion() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_AskFAQ?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/faq',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            type: this.newQuestion.type,
                            question: this.newQuestion.question,
                        })
                    }
                )
                const {
                    message,
                    questions
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                await this.sendEmail()

                this.questions = questions
                this.loading = false
                this.newQuestion.dialog = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async sendEmail() {
            try {
                let user = this.$store.getters.getUsername
                let getType = this.types.find(item => item.Id === this.newQuestion.type).Type
                let body = `<body>${user} has asked a new question in the Sanbox<br>Type: ${getType}<br>Question: ${this.newQuestion.question}</body>`
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/email',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            body: body,
                            subject: 'New Sandbox Question',
                            to: ['dev@matadorup.com']
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async poke(question) {
            try {
                let user = this.$store.getters.getUsername
                let getType = this.types.find(item => item.Id === question.Type).Type
                let body = `<body>${user} wants a question answered in the Sanbox<br>Type: ${getType}<br>Question: ${question.Question}</body>`
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/email',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            body: body,
                            subject: 'New Sandbox Question',
                            to: ['dev@matadorup.com']
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
                console.log(message);
                this.loading = false
                this.snack("They have been poked...",'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        filteredQuestions() {
            return this.filteredByType.filter(item => 
                item.Question.toLowerCase().includes(this.question.toLowerCase()) || 
                item.Answer.toLowerCase().includes(this.question.toLowerCase())
            )
        },
        filteredByType() {
            if (this.type) {
                return this.questions.filter(item => item.Type === this.type)
            } else {
                return this.questions
            }
        }
    }
}
</script>

<style>

</style>