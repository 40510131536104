<template>
    <v-container width="100vw" class="mt-12 flex-column justify-center align-center">
        <v-responsive width="30vw">
            <v-text-field
                class="py-2"
                v-model="search"
                placeholder="Ask the wiz kid"
                label="Search"
                single-line
                hide-details/>
        </v-responsive>
        <v-list height="80vh" style="overflow-y: scroll;" width="30vw">
            <v-list-item
                v-for="(item, index) in filteredItems"
                :key="index"
                class="py-2"
                @click="item.dialog = true">
                <div class="px-4 d-flex justify-center align-center">
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <div class="flex-column justify-center align-center">
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap">
                            {{ item.description }}
                        </v-list-item-subtitle>
                    </div>
                    <v-spacer/>
                    <v-list-item-action class="d-flex justify-end align-endn">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-action>
                </div>
                <component 
                    :is="item.name" 
                    @close="closeWindow(item)"
                    :dialog="item.dialog"/>
            </v-list-item>
        </v-list>
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            :timeout="snackbarTimeout">
            {{ snackbarText }}    
        </v-snackbar>
    </v-container>
</template>

<script>
import ediProcess from '/src/components/PAM/pages/Edi-Process.vue';
import accountProcess from '/src/components/PAM/pages/Account-Process.vue';
import envoyContact from '/src/components/PAM/pages/Envoy-Contact.vue';
import swapEnvoyOrder from '/src/components/PAM/pages/Swap-Envoy-Order.vue';
import StoreLocator from '/src/components/PAM/pages/Store-Locator.vue';
import PrinterSetup from '/src/components/PAM/pages/Printer-Setup.vue';
import voidingBranchTransfersCorrectly from '/src/components/PAM/pages/Voided-Branch-Transfers.vue';
import forecastTool from '/src/components/PAM/pages/Forecast-Tool.vue';
import addingTerms from '/src/components/PAM/pages/Adding-Terms.vue';
import stockMarket from '/src/components/PAM/pages/Stock-Market.vue';
import addForecastCategory from '/src/components/PAM/pages/Adding-Forecast-Category.vue';

export default {
    name: 'Home',
    components: {
        ediProcess,
        accountProcess,
        envoyContact,
        swapEnvoyOrder,
        StoreLocator,
        PrinterSetup,
        voidingBranchTransfersCorrectly,
        forecastTool,
        addingTerms,
        stockMarket,
        addForecastCategory
    },
    beforeMount() {
        document.title = 'Sandbox - PAM'
        this.$store.dispatch('updateShowWaffle', true)
        
        this.$store.dispatch('updateNavbarTitle','Post Austin Matador')

        this.$store.dispatch('updateNavbarContent', [
            { title: 'Home', path: '/', icon: 'mdi-home'},
            { title: 'PAM', path: '/pam', icon: 'mdi-wizard-hat'}
        ])
        
        if (this.$route.query.page) {
            let page = this.$route.query.page
            let item = this.items.find(item => item.name === page)
            if (item) {
                item.dialog = true
            }
        }
    },
    data() {
        return {
            search: '',
            items: [
                {
                    title: 'EDI Process',
                    description: 'How the EDI process works',
                    name: 'ediProcess',
                    dialog: false,
                    icon: 'mdi-file-document'
                },
                {
                    title: 'Account Creation Process',
                    description: 'Matador custom flow of creating/onboarding accounts',
                    name: 'accountProcess',
                    dialog: false,
                    icon: 'mdi-account'
                },
                {
                    title: 'Envoy Contact Not Created',
                    description: 'How to create a contact for Envoy',
                    name: 'envoyContact',
                    dialog: false,
                    icon: 'mdi-account-multiple-plus'
                },
                {
                    title: 'Swap Envoy Order',
                    description: 'How to swap customers on an Envoy order for when there is a duplicate account made and they need to be merged',
                    name: 'swapEnvoyOrder',
                    dialog: false,
                    icon: 'mdi-swap-horizontal'
                },
                {
                    title: 'Store Locator',
                    description: 'How the store locator works and how to use it',
                    name: 'storeLocator',
                    dialog: false,
                    icon: 'mdi-map-marker'
                },
                {
                    title: 'Printer Setup',
                    description: 'How to setup a printer for the Matador office',
                    name: 'printerSetup',
                    dialog: false,
                    icon: 'mdi-printer'
                },
                {
                    title: 'Voiding Branch Transfers Correctly',
                    description: 'How to void a branch transfer in the stock market correctly',
                    name: 'voidingBranchTransfersCorrectly',
                    dialog: false,
                    icon: 'mdi-git'
                },
                {
                    title: 'Forecast Tool',
                    description: 'How to use the forecast tool',
                    name: 'forecastTool',
                    dialog: false,
                    icon: 'mdi-chart-line'
                },
                {
                    title: 'Adding Payment Terms',
                    description: 'How to add payment terms to the system',
                    name: 'addingTerms',
                    dialog: false,
                    icon: 'mdi-cash'
                },
                {
                    title: 'Stock Market',
                    description: 'How the stock market works',
                    name: 'stockMarket',
                    dialog: false,
                    icon: 'mdi-abacus'
                },
                {
                    title: 'Adding Forecast Category',
                    description: 'How to correctly add a forecast category to the system',
                    name: 'addForecastCategory',
                    dialog: false,
                    icon: 'mdi-chart-box-plus-outline'
                }
            ],
            loading: false,
            snackbar: false,
            snackbarColor: '',
            snackbarText: '',
            snackbarTimeout: 3000,
        }
    },
    methods: {
        closeWindow(item) {
            item.dialog = false;
        }
    },
    computed: {
        filteredItems() {
            return this.items.filter(item => {
                return item.title.toLowerCase().includes(this.search.toLowerCase()) || item.description.toLowerCase().includes(this.search.toLowerCase());
            });
        }
    },
}
</script>

<style>

</style>