<template>
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card class="pa-4">
            <customTitle 
                :title="'Stock Market'" 
                :name="'stockMarket'" 
                @close="$emit('close')"/>
            <v-card-text>
                Oh the stock market, where ops manager's dreams are made. This tool is used to track inventory across our 'Ops Groups' which are essentially our warehouses. 
                There are five main parts to the stock market:
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>1. Home Page</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                                The stock market uses a lot of data and in order to pull it all in we have to call each item individually. As you'll see when you load the home page, the data is pulled in the following order:
                                <ol>
                                    <li>Products Data</li>
                                    <li>Forecast Data</li>
                                    <li>Branch Transfers Data</li>
                                    <li>Stock Market Specific Data</li>
                                    <li>Stock On Hand Data</li>
                                </ol> 
                                If any of these fail, there is a refresh button next to the item that can be used to retry the call. If the row continues to fail, check to make sure your internet connection is strong and if it persists, reach out to Austin.
                            </p>
                            <p>
                                The top row is comprised of 9 different elements:
                                <ol>
                                    <li>Refresh</li>
                                    <ul>
                                        <li>
                                            You can refresh all the data in the stock market by clicking the main refresh button. Alternatively, by selecting the dropdown next to the refresh button, you can refresh each individual item.
                                        </li>
                                    </ul>
                                    <li>Check Forecast</li>
                                    <ul>
                                        <li>
                                            This button will give you an overview of all the products and their forecasts over the given months. 
                                        </li>
                                        <li>
                                            <v-simple-table><tbody><tr>
                                                <td style="background-color: red;"></td> 
                                                <td>If a product falls below the product floor, it will be highlighted in red</td>
                                            </tr></tbody></v-simple-table>
                                        </li>
                                        <li>
                                            <v-simple-table><thead><tr>
                                                <td style="background-color: orange;"></td>
                                                <td>If a product falls within 10% of its product floor, the cell will be highlighted orange</td>
                                            </tr></thead></v-simple-table>
                                        </li>
                                        <li>
                                            <v-img
                                                max-width="10vw" 
                                                src="../src/Stock-Market-Check-Forecast.png"/>
                                            You can click on the cell to see the details of the that month and navigate directly to that months detail card to input a restock number or view the specific forecast or branch transfers coming in.
                                        </li>
                                    </ul>
                                    <li>Ops Group</li>
                                    <ul>
                                        <li>
                                            This is the warehouse that the product is located in. You can change the warehouse by selecting the dropdown and choosing a different warehouse. This list is generated from the distinct list of 'Ops Groups' in the 'Manage Groups' tab. By adding a new group to an account, there will be a new warehouse available in the dropdown.
                                        </li>
                                    </ul>
                                    <li>Card Height</li>
                                    <ul>
                                        <li>
                                            This is the height of the card. You can change the height of the card by dragging the slider left and right. This is useful for products that have a lot of data and need more space to display it all.
                                        </li>
                                    </ul>
                                    <li>Visible Months</li>
                                    <ul>
                                        <li>
                                            This is the number of months that are visible on the graph. You can change the number of months by selecting the using the buttons on either side of the number and selecting submit after changing the number. This is useful for when you need to see less or more months on the graph.
                                        </li>
                                    </ul>
                                    <li>Include BT Drafts</li>
                                    <ul>
                                        <li>
                                            This is a checkbox that will include or exclude branch transfers that are in draft status. This is useful for when you need to see the branch transfers that are in draft status overlayed on the graph.
                                        </li>
                                    </ul>
                                    <li>Include Inactive Products</li>
                                    <ul>
                                        <li>
                                            This is a checkbox that will include or exclude products that are inactive. This is useful for when you need to see the inactive products that may still have some forecasts attached to them.
                                        </li>
                                    </ul>
                                    <li>Download</li>
                                    <ul>
                                        <li>
                                            This button will download all the current stock market data into a csv with the headers SKU, Month, Year, and Restock Qty. This is useful for when you need to import the data into a branch transfer in Cin7.
                                        </li>
                                    </ul>
                                    <li>Delete All</li>
                                    <ul>
                                        <li>
                                            This button will delete all the stock market data. This is useful for when you need to start fresh without any restock numbers.
                                        </li>
                                    </ul>
                                </ol>
                            </p>
                            <p>
                                The product card is comprised of 5 main elements:
                                <ol>
                                    <li>Product Name / Color / Active Status</li>
                                    <ul>
                                        <li>
                                            By clicking on the product name you can change the main color of the card and the products active status. This is useful for when you need to differentiate between products on the graph.
                                        </li>
                                    </ul>
                                    <li>Stock on Hand</li>
                                    <ul>
                                        <li>
                                            This is the current stock on hand for the product in the selected warehouse. This is where the graph will start on the graph.
                                        </li>
                                    </ul>
                                    <li>Product Floor</li>
                                    <ul>
                                        <li>
                                            This is where the horizontal dotted line will be placed on the graph. This is useful for when you want to keep SOH above a certain threshold.
                                        </li>
                                    </ul>
                                    <li>The Main Graph</li>
                                    <ul>
                                        <li>
                                            This is the main graph that will display the stock of a product over the visible months given current stock on hand, forecast, and incoming branch transfers. 
                                        </li>
                                        <li>
                                            You can hover over the graph to see the exact stock predicted for that month. And input a restock number to see how an increase of stock in a given month will change the run rate of that product over the visible months.
                                        </li>
                                        <li>
                                            You can also click on the graph to see the details of that month.
                                        </li>
                                    </ul>
                                    <li>The Detail Card</li>
                                    <ul>
                                        <li>
                                            By clicking on a month within the graph of a product, you can view everything that is going into that number.
                                        </li>
                                        <li>
                                            Starting in the upper left, you see the selected month and toggle between the months by using the arrows on either side of the month.
                                        </li>
                                        <li>
                                            Next to the month, you can see the selected product sku and below that the name and product floor of the product.
                                        </li>
                                        <li>
                                            Finally, on the top row we have the restock qty which is the same input as hovering over a month on the graph.
                                        </li>
                                        <li>
                                            In the middle left we have the forecast for that month/product/ops group. This can be viewed by Sales Forecast Categories or by Ops Forecast Categories depending on the use case (although now adays, those two categories are almost identical).
                                        </li>
                                        <li>
                                            In the middle right we have the incoming branch transfers for that month/product/ops group. You can see when when the ETA for the branch transfer is and what qty is coming in. You can also click on the BT reference to navigate directly to the Cin7 BT page.
                                        </li>
                                        <li>
                                            Finally, on the bottom left and right we can see the months starting qty and then the months ending qty as a function of all the information provided above.
                                        </li>
                                    </ul>
                                </ol>
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>2. Managing Groups</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            This is where you can manage how Sales Forecast categories are mapped to ops forecast categories and ops groups. These are all just text fields so please be careful in your spelling (capitalization DOES matter). 
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>3. Managing Branch Transfers</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            This is where you can manage all the branch transfers impacting the stock market.
                            <ul>
                                <li>'Get Most Recent Branch Transfers' will make an API call to Cin7 to collect all BT modified within the past 2 days</li>
                                <li>'Show Only Empty ETA' will filter the BTs in the list to only the ones that do NOT have an ETA assigned to them</li>
                                <li>'Get All ETA' will send the bot out to scrape Cin7 for all avaiable ETAs on BTs that have been modified within the past 2 days</li>
                                <li>Within the table showing all the BTs:</li>
                                <ul>
                                    <li>Each row can be expanded to see which items are in each BT</li>
                                    <li>The entire BT can be requeryed from Cin7(<v-icon color="primary">mdi-refresh</v-icon>), deleting the current record and regathering the information from Cin7</li>
                                    <li>The BT can also be deleted by selecting <v-icon color="red">mdi-delete-outline</v-icon>. Please note, if you delete and the Project Name does not contain the word 'delete' (case insensitive) the BT will come back when selecting 'Get Most Recent Branch Transfers'</li>
                                    <li>By selecting the <v-icon color="primary">mdi-calendar-clock</v-icon> the bot will go into the BT and gather the ETA from Cin7</li>
                                    <li>An ETA can be manually assigned by selecting the row's ETA cell and selecting a date</li>
                                </ul>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>4. Managing Branches</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            This is where you can manage which branch is assigned to which ops group. This is useful for when you need to change the warehouse that a branch is assigned to. To create a new Ops Group, just input the desired name into the Ops Group field.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>5. Behind the scenes</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p>
                                The main behind the scenes script that occurs for this tool is the ETA scrapper. The ETA for BTs are not exposed to Cin7's API and therefore require a bot to go out and collect the ETA dates for all BTs.
                            </p>
                            <p>
                                If this bot breaks for whatever reason (Cin7 implementing 2FA for example), the ETA date will not be collected automatically and will need to be manually inputted through the 'Manage Branch Transfers' tab.
                            </p>
                            <p>
                                There is a known bug for Branch Transfers that have an ETA of the 16th day of the month for 31 day months. Since the script is written to put stock that arrives after the second half of the month into the next month, stock that lands on the 16th of 31 days months get ignored. 
                                We have tried to implement fixes to this but haven't really found a good solution and as is Matador fashion, if its good enough, its good for us! So just don't have ETAs of the 16th!
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import customTitle from '/src/components/PAM/pages/title.vue';
export default {
    name: 'StockMarket',
    components: {
        customTitle
    },
    props: {
        dialog: Boolean
    }
}
</script>

<style>

</style>