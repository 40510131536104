<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['chartData','refresh'],
    mounted () {
        // Overwriting base render method with actual data.
        this.renderChart(this.chartData, this.options)
    },
    methods: {
    },
    computed: {
        options() {
            return {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false
                        },
                        min: 0
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: false,
                },
                onHover: (e,item) => {
                    if(item.length > 0) {
                        // console.log(item);
                        this.$emit('input',{
                            type: 'hover',
                            index: item[0]._index, 
                            x: e.clientX,
                            y: e.clientY
                        })
                    }
                },
                onClick: (e,item) => {
                    if (item.length > 0) {
                        this.$emit('input',{
                            type: 'click',
                            index: item[0]._index
                        })
                    }
                },
                
            }
        }
    }
}
</script>

<style>

</style>