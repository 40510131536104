<template>
  <div>
      <div v-if="loading" class="loader"></div>
        <v-card-title>
            {{ channel }} Metrics
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="metrics"
            :search="search"
            class="elevation-1">
            <template v-slot:[`item.CVR`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.CVR"
                    large
                    @save="save(prop.item.Date, 'CVR', prop.item.CVR)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.CVR }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.CVR"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:[`item.AOV`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.AOV"
                    large
                    @save="save(prop.item.Date, 'AOV', prop.item.AOV)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.AOV }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.AOV"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:[`item.Sessions`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.Sessions"
                    large
                    @save="save(prop.item.Date, 'Sessions', prop.item.Sessions)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.Sessions }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.Sessions"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:[`item.RCR`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.RCR"
                    large
                    @save="save(prop.item.Date, 'RCR', prop.item.RCR)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.RCR }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.RCR"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:[`item.Percent_Paid_Sales`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.Percent_Paid_Sales"
                    large
                    @save="save(prop.item.Date, 'Percent_Paid_Sales', prop.item.Percent_Paid_Sales)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.Percent_Paid_Sales }}%
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.Percent_Paid_Sales"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
        </v-data-table>
        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            >
            {{ snackText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                v-bind="attrs"
                text
                @click="snack = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

export default {
    props: {
        inputtedDate: {type: Object},
        channel: {type: Object}
    },
    beforeMount() {
        this.getShopify();
    },
    watch: {
        inputtedDate: function() {
            this.getShopify();
        }
    },
    data: () => ({
        metrics: [
            {
                "Date": '',
                "CVR": '',
                "AOV": '',
                "Sessions": '',
                "RCR": '',
                "Percent_Paid_Sales": '',
            }
        ],
        snack: false,
        snackColor: '',
        snackText: '', 
        search: '',
        loading: false,
    }),
    methods: {
        getShopify: function() {
            this.loading = true;
            if (this.$props.inputtedDate.startDate && this.$props.inputtedDate.endDate) {
                const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/shopify';
                const data = {
                    'startDate': this.$props.inputtedDate.startDate,
                    'endDate': this.$props.inputtedDate.endDate,
                    'channel': this.$props.channel
                }
                const request = {
                    method: 'POST',
                    Headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(data)
                };
                fetch(url, request).then((response) => {
                    return response.json();
                }).then((data) => {
                    for (let items in data) {
                        data[items].Date = data[items].Date.toString().split('T')[0]
                        if (data[items].CVR === null) {
                            data[items].CVR = 0;
                        } else if (data[items].AOV === null) {
                            data[items].AOV = 0;
                        } else if (data[items].Sessions === null) {
                            data[items].sessions = 0;
                        } else if (data[items].RCR === null) {
                            data[items].RCR = 0;
                        } else if (data[items].Percent_Paid_Sales === null) {
                            data[items].Percent_Paid_Sales = 0;
                        } 

                        data[items].Percent_Paid_Sales = data[items].Percent_Paid_Sales * 100;
                    }

                    this.metrics = data;
                    this.loading = false;
                });
            }
            this.loading = false;
        },
        save (date, name, value) {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Data saved'
            this.updateShopify(date, name, value);
        },
        cancel () {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Canceled'
        },
        open () {
            this.snack = true
            this.snackColor = 'info'
            this.snackText = 'Dialog opened'
        },
        close () {
            console.log('Dialog closed')
        },
        updateShopify: function(date, name, value) {
            if (name === "Percent_Paid_Sales") {
                value = value / 100;
            } 

            const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/shopify';
            const data = {
                'Date': date,
                'Name': name,
                'Value': value,
                'Channel': this.$props.channel
            }
            const request = {
                method: 'PUT',
                Headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(data)
            };
            fetch(url, request).then((response) => {
                return response.json();
            }).then((data) => {
                console.log(data)
            });
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: 'Date',
                    value: 'Date'
                },
                {
                    text: 'CVR',
                    value:'CVR'
                },
                {
                    text: 'AOV',
                    value: 'AOV'
                },
                {
                    text: 'Sessions',
                    value:'Sessions'
                },
                {
                    text: 'RCR',
                    value:'RCR'
                },
                {
                    text: 'Percent_Paid_Sales',
                    value:'Percent_Paid_Sales'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>