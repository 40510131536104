<template>
    <v-app>
        <v-main>
            <v-row class="pt-6 px-4" align="center">
                <v-text-field 
                    :loading="loading"
                    class="px-4 py-0" 
                    v-model="search" 
                    hide-details
                    v-on:keyup.enter="getAccounts()"
                    label="Search"/>
                <CustomFilter
                    v-model="filter"
                    @loaded="getAccounts()"
                    :salesManagers="salesManagers"
                    :salesReps="salesReps"
                    :accountTypes="accountTypes"
                    :channels="channels"
                    :industries="industries"
                    :forecastCategories="forecastCategories"
                    :doors="doors"
                    :userCin7Id="userCin7Id"
                    :klaviyoLists="klaviyoLists"/>
                <newAccount
                    :managers="salesManagers"
                    :reps="salesReps"
                    :accountTypes="accountTypes"
                    :channels="channels"
                    :industries="industries"
                    :forecastCategories="forecastCategories"
                    :priceCategories="priceLists"
                    :costCategories="costLists"
                    :userCin7Id="userCin7Id"
                    :klaviyoLists="klaviyoLists"
                    :paymentTerms="paymentTerms"/>
            </v-row>
            <v-row v-if="accounts.length" justify="center" align="center" class="pt-4 px-4">
                <v-data-table
                    multi-sort
                    :headers="headers"
                    :items="accounts"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50]
                    }"
                    item-key="Account ID"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :hide-default-header="selected.length || checkAll ? true : false"
                    :hide-default-footer="selected.length || checkAll ? true : false">
                    <template v-slot:[`header.checkbox`] v-if="!selected.length || !checkAll">
                        <v-simple-checkbox 
                            v-model="checkAll" 
                            :ripple="false"
                            @input="changeSelection()"/>
                    </template>
                    <template v-slot:header v-if="selected.length || checkAll">
                        <!-- <thead> -->
                            <tr>
                                <th class="d-flex justify-center align-center pt-2 pl-2">
                                    <v-simple-checkbox 
                                        class="pt-2 pl-2"
                                        :ripple="false"
                                        v-model="checkAll" 
                                        @input="changeSelection()"/>
                                </th>
                                <th>
                                    {{ selectedTotal }} Selected
                                </th>
                                <th class="pr-2">
                                    <v-autocomplete
                                        v-model="fieldToUpdate"
                                        :items="accountFields"
                                        item-text="label"
                                        class="pr-2"
                                        hide-details
                                        return-object
                                        label="Field To Update"/>
                                </th>
                                <th class="pl-2">
                                    <v-autocomplete
                                        label="Update To Value"
                                        class="pl-2"
                                        hide-details
                                        v-model="valueToUpdate"
                                        :items="objectForFieldToUpdate"
                                        :item-text="fieldToUpdate.text"
                                        return-object/>
                                </th>
                                <th>
                                    <v-btn 
                                        color="primary"
                                        :disabled="disableUpdate"
                                        :loading="bulkUpdateLoading"
                                        @click="bulkUpdateAccounts()">Update</v-btn>
                                </th>
                            </tr>
                        <!-- </thead> -->
                    </template>
                    <template v-slot:[`item.checkbox`]="{ item }">
                        <v-checkbox 
                            class="d-flex justify-center align-center"
                            v-model="item.checkbox"
                            @change="selectAccount(item)"/>
                    </template>
                    <template v-slot:[`item.Company`]="{ item }">
                        <v-btn 
                            :to="'/sales/crm/accounts/' + item['Account ID']"
                            text
                            color="primary">
                            {{ item.Company }}
                        </v-btn>
                    </template>
                    <template v-slot:[`item.Industry`]="{ item }">
                        <v-autocomplete
                            class="pr-2"
                            :items="industries"
                            item-value="Id"
                            item-text="Industry"
                            hide-details
                            @change="updateAccount(item['Account ID'], 'Industry_Id','Group',item.Industry_Id,getIndustry(item.Industry_Id))"
                            v-model="item.Industry_Id"/>
                    </template>
                    <template v-slot:[`item.Account_Type`]="{ item }">
                        <v-autocomplete
                            class="pr-2 pt-4"
                            :items="accountTypes"
                            item-value="Id"
                            item-text="Account_Type"
                            hide-details
                            @change=" updateAccount(item['Account ID'], 'Account_Type_Id','members_1001',item.Account_Type_Id,getAccountType(item.Account_Type_Id))"
                            v-model="item.Account_Type_Id"/>
                    </template>
                    <template v-slot:[`item.Channel`]="{ item }">
                        <v-select
                            class="pr-2"
                            :items="channels"
                            item-value="Id"
                            item-text="Channel"
                            hide-details
                            @change="updateAccount(item['Account ID'], 'Channel_Id',null,item.Channel_Id)"
                            v-model="item.Channel_Id"/>
                    </template>
                    <template v-slot:[`item.Forecast_Category`]="{ item }">
                        <v-autocomplete
                            class="pr-2"
                            :items="forecastCategories"
                            item-value="Forecast_Id"
                            item-text="Forecast_Name"
                            hide-details
                            @change="updateAccount(item['Account ID'], 'Forecast_Id','SubGroup',item.Forecast_Id,getForecastCategory(item.Forecast_Id))"
                            v-model="item.Forecast_Id"/>
                    </template>
                    <template v-slot:[`item.Sales_Manager`]="{ item }">
                        <v-autocomplete
                            class="pr-2"
                            :items="salesManagers"
                            item-value="User ID"
                            item-text="First Name"
                            hide-details
                            @change="updateAccount(item['Account ID'], 'Sales Rep','SalesPersonId',item['Sales Rep'])"
                            v-model="item['Sales Rep']">
                            <template v-slot:selection="{ item }">
                                {{ item['First Name'] }} {{ item['Last Name']}}
                            </template>
                             <template v-slot:item="{ item }">
                                {{ item['First Name'] }} {{ item['Last Name']}}
                            </template>
                        </v-autocomplete>
                    </template>
                    <template v-slot:[`item.Sales_Rep`]="{ item }">
                        <v-autocomplete
                            class="pr-2"
                            :items="salesReps"
                            item-value="Id"
                            item-text="Agency"
                            hide-details
                            @change="updateAccount(item['Account ID'], 'External_Sales_Rep_Id','members_1000',item.External_Sales_Rep_Id, getSalesRep(item.External_Sales_Rep_Id))"
                            v-model="item.External_Sales_Rep_Id"/>
                    </template>
                    <template v-slot:[`item.Door_Count`]="{ item }">
                        <v-text-field
                            class="pr-2"
                            hide-details
                            @change="updateAccount(item['Account ID'], 'Door Count','members_1002',item['Door Count'],item['Door Count'])"
                            v-model="item['Door Count']"/>
                    </template>
                </v-data-table>
            </v-row>
            <v-snackbar
                v-model="snackActive"
                :color="snackColor"
                :timeout="snackTimeout">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
import CustomFilter from './CustomFilter.vue'
import newAccount from './createAccount.vue'

export default {
    components: { 
        CustomFilter,
        newAccount
    },
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - CRM'
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Leads', path: '/sales/crm/leads/home', icon: 'mdi-account-box-outline', notification: false},
            {title: 'Accounts', icon: 'mdi-domain', path: '/sales/crm/accounts/home', notification: false},
            {title: 'Admin', path: '/sales/crm/admin/home', icon: 'mdi-cog', notification: false},
            {title: 'Batches', path: '/sales/crm/batches', icon: 'mdi-account-convert', notification: false},
            {title: 'Export', path: '/sales/crm/accounts/export', icon: 'mdi-export-variant', notification: false},
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ])
        this.$store.dispatch('updateNavbarTitle','CRM Accounts')
    },
    watch: {
        options: {
            handler() {
                this.getAccounts()
            },
            deep: true
        }
    },
    data() {
        return {
            search: '',
            totalItems: 0,
            options: {
                itemsPerPage: 10,
                page: 1
            },
            accounts: [],
            selected: [],
            unselected: [],
            accountTypes: [],
            industries: [],
            salesReps: [],
            salesManagers: [],
            costLists: [],
            priceLists: [],
            forecastCategories: [],
            paymentTerms: [],
            klaviyoLists: [],
            envoyDiscount: [
                {
                    label: '2DISCOUNT',
                    Envoy_Discount: '2DISCOUNT'
                },
                {
                    label: '5DISCOUNT',
                    Envoy_Discount: '5DISCOUNT'
                },
                {
                    label: '10DISCOUNT',
                    Envoy_Discount: '10DISCOUNT'
                },
                {
                    label: '15DISCOUNT',
                    Envoy_Discount: '15DISCOUNT'
                }
            ],
            doors: [],
            channels: [],
            filter: {
                active: {
                    value: [1],
                    key: 'Active'
                },
                salesManagers: {
                    value: [],
                    key: 'Sales Rep'
                },
                salesReps: {
                    value: [],
                    key: 'External_Sales_Rep_Id'
                },
                industries: {
                    value: [],
                    key: 'Industry_Id'
                },
                accountTypes: {
                    value: [],
                    key: 'Account_Type_Id'
                },
                channels: {
                    value: [],
                    key: 'Channel_Id'
                },
                forecastCategories: {
                    value: [],
                    key: 'Forecast_Id'
                },
                doors: {
                    value: [],
                    key: 'Door Count'
                }
            },
            fieldToUpdate: {},
            valueToUpdate: {},
            checkAll: false,
            selectCount: 0,
            userCin7Id: 0,
            bulkUpdateLoading: false,
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        async getAccounts() {
            let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts')
            url.searchParams.append('email', this.$store.getters.getUserEmail)
            url.searchParams.append('page', this.options.page)
            url.searchParams.append('itemsPerPage', this.options.itemsPerPage)
            if (this.search.length) {
                url.searchParams.append('search', this.search)
            }
            if (this.filter.active.value.length > 0) {
                url.searchParams.append('active', this.filter.active.value.join(","))
            }
            if (this.filter.salesManagers.value.length > 0) {
                url.searchParams.append('salesManagers', this.filter.salesManagers.value.join(","))
            }
            if (this.filter.salesReps.value.length > 0) {
                url.searchParams.append('salesReps', this.filter.salesReps.value.join(","))
            }
            if (this.filter.industries.value.length > 0) {
                url.searchParams.append('industries', this.filter.industries.value.join(","))
            }
            if (this.filter.accountTypes.value.length > 0) {
                url.searchParams.append('accountTypes', this.filter.accountTypes.value.join(","))
            }
            if (this.filter.channels.value.length > 0) {
                url.searchParams.append('channels', this.filter.channels.value.join(","))
            }
            if (this.filter.forecastCategories.value.length > 0) {
                url.searchParams.append('forecastCategories', this.filter.forecastCategories.value.join(","))
            }
            if (this.filter.doors.value.length > 0) {
                url.searchParams.append('doors', this.filter.doors.value.join(","))
            }
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_GetAccounts?newFunction=true&tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    totalItems,
                    accounts,
                    salesReps,
                    salesManagers,
                    accountTypes,
                    industries,
                    forecastCategories,
                    channels,
                    costList,
                    priceList,
                    paymentTerms
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.totalItems = totalItems
                this.accounts = accounts;
                this.salesReps = salesReps
                this.salesManagers = salesManagers
                this.accountTypes = accountTypes
                this.industries = industries
                this.forecastCategories = forecastCategories
                this.channels = channels
                this.costLists = costList
                this.priceLists = priceList
                this.doors = [... new Set(this.accounts.map(item => item['Door Count']))]
                this.paymentTerms = paymentTerms
                let userEmail = this.$store.getters.getUserEmail
                if (userEmail.includes('matadorup.com')) {
                    userEmail = userEmail.replace('@matadorup.com','@matadorequipment.com')
                }
                this.userCin7Id = this.salesManagers.find(item => item['Email Address'].toLowerCase() === userEmail.toLowerCase())['User ID']
                this.accounts.forEach(item => item.checkbox = false)

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateAccount(id, key, cin7key, value, cin7value) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_AccountUpdate?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: id,
                            key: key,
                            cin7key: cin7key,
                            value: value,
                            cin7Value: cin7value || value
                        })
                    }
                )
                
                const {
                    message,
                } = await result.json()
                if (!result.ok) {
                    throw message
                }
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async bulkUpdateAccounts() {
            try {
                this.bulkUpdateLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_BulkUpdateAccounts?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/bulkupdate?email=' + this.$store.getters.getUserEmail,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            checkAll: this.checkAll,
                            search: this.search,
                            accounts: this.selectedIds,
                            unselected: this.unselectedIds,
                            fieldToUpdate: this.fieldToUpdate,
                            valueToUpdate: this.valueToUpdate,
                            filter: this.filter
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                // this.selected = []
                //this.unselected = []
                // this.fieldToUpdate = {}
                // this.valueToUpdate = {}
                // this.checkAll = false
                // await this.getAccounts()
                this.bulkUpdateLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.bulkUpdateLoading = false
                this.snack(error,'error',3000)
            }
        },
        getAccountType(id) {
            return this.accountTypes.find(item => item.Id === id).Account_Type
        },
        getIndustry(id) {
            return this.industries.find(item => item.Id === id).Industry
        },
        getForecastCategory(id) {
            return this.forecastCategories.find(item => item.Forecast_Id === id).Forecast_Name
        },
        getSalesRep(id) {
            return this.salesReps.find(item => item.Id === id).Agency
        },
        changeSelection() {
            this.accounts.forEach(item => {
                item.checkbox = this.checkAll
                if (this.checkAll) {
                    this.selected.push(item)
                }
            })
            if (!this.checkAll) {
                this.selected = []
                this.unselected = []
            }
            this.anyChecked = this.checkAll 
        },
        selectAccount(account) {
            let copy = JSON.parse(JSON.stringify(account))
            delete copy.checkbox
            if (account.checkbox) {
                this.selected.push(copy)
            } else {
                let index = this.selected.indexOf(copy)
                this.selected.splice(index, 1)
                this.unselected.push(copy)
            }
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        selectedTotal() {
            if (this.checkAll) {
                return this.totalItems - (this.options.itemsPerPage - this.selectedIds.length)
            } else {
                return this.selected.length
            }
        },
        selectedIds() {
            if (this.selected.length) {
                return this.selected.map(item => item['Account ID'])
            } else{
                return []
            }
        },
        unselectedIds() {
            if (this.unselected.length) {
                return this.unselected.map(item => item['Account ID'])
            } else{
                return []
            }
        },
        accountFields() {
            return [
                {
                    label: 'Industry',
                    object: 'industries',
                    text: 'Industry',
                    value: 'Industry_Id',
                    cin7Field: 'Group',
                    identifier: 'Id',
                },
                {
                    label: 'Account Type',
                    object: 'accountTypes',
                    text: 'Account_Type',
                    value: 'Account_Type_Id',
                    cin7Field: 'members_1001',
                    identifier: 'Id'
                },
                {
                    label: 'Channel',
                    object: 'channels',
                    text: 'Channel',
                    value: 'Channel_Id',
                    cin7Field: null,
                    identifier: 'Id'
                },
                {
                    label: 'Forecast Category',
                    object: 'forecastCategories',
                    text: 'Forecast_Name',
                    value: 'Forecast_Id',
                    cin7Field: 'subGroup',
                    identifier: 'Forecast_Id'
                },
                {
                    label: 'Manager',
                    object: 'salesManagers',
                    text: 'First Name',
                    value: 'User ID',
                    cin7Field: 'salesPersonId',
                    identifier: 'User ID'
                },
                {
                    label: 'Sales Rep',
                    object: 'salesReps',
                    text: 'Agency',
                    value: 'External_Sales_Rep_Id',
                    cin7Field: 'members_1000',
                    identifier: 'Id'
                },
                {
                    label: 'Cost Category',
                    object: 'costLists',
                    text: 'Name',
                    value: 'Cost ID',
                    cin7Field: null,
                    identifier: 'Cost ID'
                },
                {
                    label: 'Primary Price Category',
                    object: 'priceLists',
                    text: 'Name',
                    value: 'Price ID',
                    cin7Field: null,
                    identifier: 'Price ID'
                },
                {
                    label: 'Secondary Price Category',
                    object: 'priceLists',
                    text: 'Name',
                    value: 'Secondary_Price_Id',
                    cin7Field: null,
                    identifier: 'Price ID'
                },
                {
                    label: 'Envoy Discount',
                    object: 'envoyDiscount',
                    text: 'label',
                    value: 'Envoy_Discount',
                    cin7Field: 'members_1007',
                    identifier: 'Envoy_Discount'
                },
            ]
        },
        objectForFieldToUpdate() {
            return this[this.fieldToUpdate.object]
        },
        disableUpdate() {
            return !this.fieldToUpdate.value || !this.valueToUpdate[this.fieldToUpdate.identifier]
        },
        headers () {
            return [
                {
                    text: 'checkbox',
                    align: 'start',
                    sortable: false,
                    value: 'checkbox'
                },
                {
                    text: 'Company',
                    align: 'start',
                    sortable: true,
                    value: 'Company',
                },
                {
                    text: 'Industry',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    value: 'Industry',
                },
                {
                    text: 'Account Type',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    value: 'Account_Type',
                },
                {
                    text: 'Channel',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    value: 'Channel',
                },
                {
                    text: 'Forecast Category',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    value: 'Forecast_Category',
                },
                {
                    text: 'Sales Manager',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    value: 'Sales_Manager',
                },
                {
                    text: 'Sales Rep',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    value: 'Sales_Rep',
                },
                {
                    text: 'Door Count',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    value: 'Door_Count',
                }
            ]
        }
    }
}
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>