<template>
    <div class="px-2">
        <v-row justify="center" align="center" class="mx-2 mb-6">
            <v-col>Categories in Forecast</v-col>
            <v-col v-for="(item, index) in allCategories" :key="index">
                <v-switch
                @change="updateActiveProductCategories(item.active,item.category)"
                :label="item.category"
                v-model="item.active"
                color="primary"/>
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mx-2">
            <v-data-table
                :search="search"
                :headers="headers"
                :items="productData">
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"/>
                </template>
                <template v-slot:[`item.Active`]="{ item }">
                    <v-switch
                        v-model="item.Active"
                        readonly
                        :true-value="1"
                        :false-value="0"
                        color="primary"/>
                </template>
                <template v-slot:[`item.included`]="{ item }">
                    <v-tooltip bottom v-if="item.included === 'No'">
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                v-bind="attrs"
                                v-on="on">
                                {{ item.included }}
                            </span>
                        </template>
                        <span><div v-for="(reason, index) in item.reasons" :key="index">{{ reason }}</div></span>
                    </v-tooltip>
                    <span v-else>
                        {{ item.included }}
                    </span>
                </template>
            </v-data-table>
        </v-row>
        <v-snackbar
			v-model="snackbar"
			:timeout="timeout"
			:color="snackColor">
            {{ snackText}}
		</v-snackbar>
    </div>
</template>

<script>

export default {
    components: {
    },
    beforeMount() {
        this.getProductDetails();
    },
    created() {
        if(this.$route.params.newList) {
            this.createNewCostPriceListDialog = true
        } else {
            this.createNewCostPriceListDialog = false
        }
        if(this.$route.params.filter) {
            this.search = this.$route.params.filter
        } else {
            this.search = ''
        }
    },
    data: () => ({
        activeCategories: [],
        productData: [],
        search: '',
        snackText: "",
        snackbar: false,
        snackColor: "",
        timeout: 3000,
        loading: true,
    }),
    methods: {
        updateActiveProductCategories: function(Active, Category) {
            const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/productdetails/productcategories'
            const data = {
                Active: Active,
                Category: Category
            }
            const request = {
				method: 'POST',
				Headers: {
					'Content-Type': 'application/json',
				},
                body: JSON.stringify(data)
			};

            this.loading = true
			fetch(url, request).then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json()
                } else {
                    throw Error(response.statusText);
                }
			}).then((result) => {
                this.loading = false
                this.snack(result.response,'success',3000)
            }).catch((error) => {
                this.loading = false
                this.snack(error,'error',3000)
            })

        },
        getProductDetails: function() {
            //Link to lambda function
            //https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/productCategories?tab=code
			const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/productdetails';
			const request = {
				method: 'GET',
				Headers: {
					'Content-Type': 'application/json',
				},
			};

            this.loading = true
			fetch(url, request).then((response) => {
				return response.json();
			}).then((productLists) => {
                //adds active categories to the data

                const data = JSON.parse(productLists.body)

                this.activeCategories = [... new Set(data.categories.map(item => item.Category))]
                this.productData = data.products
                
                for (let i = 0; i < this.productData.length; i++) {
                    this.productData[i].included = 'Yes'
                    this.productData[i].reasons = []
                    if (this.productData[i].Active === 0) {
                        this.productData[i].included = 'No'
                        this.productData[i].reasons.push('Product Not Active')
                    }
                    if (this.activeCategories.indexOf(this.productData[i].Category) === -1) {
                        this.productData[i].included = 'No'
                        this.productData[i].reasons.push('Category Not Active')
                    }
                }
                this.loading = false
                this.snack('Loaded','success',3000)
            });
            // setTimeout(() => this.filterTable(), 1000)
		},
        updateProduct: function(item) {
            if (item.Active == true) {
                item.Active = 1
            } else {
                item.Active = 0
            }
            const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/productdetails';
			const data = {
                Category: item.Category,
                Active: item.Active,
                SKU: item['Style Code']
            }
            const request = {
				method: 'POST',
				Headers: {
					'Content-Type': 'application/json',
				},
                body: JSON.stringify(data)
			};
            this.snack('','primary',true)
			fetch(url, request).then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json()
                } else {
                    throw Error(response.statusText);
                }
			}).then((result) => {
                this.loading = false
                this.snack(result.message,'success',3000)
                // this.filterTable()
            }).catch((error) => {
                this.loading = false
                this.snack (error,'error',3000)
            })
        },
        
        snack(text, color, timeout) {
				this.snackbar = true
				this.snackText = text
				this.snackColor = color
				this.timeout = timeout
		},
    },
    computed: {
        headers() {
            return [
                {
					text: 'Active',
					align: 'start',
					value: 'Active',
					fixed: true,
				},
                {
					text: 'Product Name',
					align: 'start',
					value: 'Product Name',
					fixed: true,
				},
                {
					text: 'Option 1',
					align: 'start',
					value: 'Option 1',
					fixed: true,
				},
                {
					text: 'SKU',
					align: 'start',
					value: 'Style Code',
					fixed: true,
				},
                {
					text: 'Category',
					align: 'start',
					value: 'Category',
					fixed: true,
				},
                {
                    text: 'Should Be Included',
                    align: 'start',
                    value: 'included',
                    fixed: true
                }
            ]
        },
        allCategoriesInFlatArray: function() {
            let flat = [...new Set(this.allCategories.map(item => item.category))]
            return flat
        },
        allCategories() {
            if (this.productData.length) {
                let allCategories = [...new Set(this.productData.map(item => item.Category))]
                allCategories.sort((a,b) => b - a)
                //see if category is active or not for the allCategories array
                for (let index = 0; index < allCategories.length; index++) {
                    let object = {};
                    if (this.activeCategories.indexOf(allCategories[index]) !== -1 ) {
                        object = {
                            category: allCategories[index],
                            active: true
                        }
                    } else {
                        object = {
                            category: allCategories[index],
                            active: false
                        }
                    }
                    allCategories[index] = object
                }
                return allCategories
            } else {
                return []
            }
            
        }
    }

}
</script>

<style>

</style>