<template>
    <v-row class="noScroll" justify="center" align="center">
        <v-col cols="6">
            <v-card elevation="2" width="25vw" class="px-5 py-5 mx-auto my-auto">
            <v-card-title class="justify-center">Login</v-card-title>
            <v-text-field 
                id="username"
                class="px-16"
                label="Email"
                v-model="email"/>
            <v-text-field
                id="password"
                type="password"
                class="px-16"
                label="Password"
                v-model="password"/>
                <v-row justify="center" class="py-5">
                <v-btn
                    @click="login()" 
                    :disabled="formNotComplete" 
                    color="primary"
                    :loading="loading">Login</v-btn>
                </v-row>
                <v-row justify="center">
                    <v-card-subtitle class="d-flex justify-center align-center">
                        <v-btn 
                            x-small
                            text
                            @click="forgotPasswordDialog = true">
                            Forgot Password
                        </v-btn>
                    </v-card-subtitle>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-img 
                src="../../../assets/homePicture.jpg"
                max-height="95vh"
                max-width="50vw">
                <template v-slot:placeholder>
                    <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                    >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
        </v-col>
        <v-dialog
            v-model="forgotPasswordDialog"
            width="50vw">
            <v-card class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Forgot Password
                </v-card-title>
                <v-card-subtitle class="d-flex justify-center align-center">
                    A randomized password will be sent to your email if there is account associated with it.
                </v-card-subtitle>
                <v-text-field
                    v-model="email"
                    label="Email"/>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn
                        color="primary"
                        @click="resetPassword()">
                        Reset Password
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-row>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateNavbarTitle','Matador Store')
        this.$store.dispatch('updateShowWaffle', false)
    },
    data() {
        return {
            email: '',
            password: '',
            forgotPasswordDialog: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            loading: false,
        }
    },
    methods: {
        async login() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    '',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email: this.email,
                            password: this.password
                        })
                    }
                )
            
                const {
                    message,
                    contactId
                } = await result.json()

                localStorage.shopperId = contactId
                
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async resetPassword() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    '',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        formNotComplete() {
        if (this.email.length && this.password.length) {
            return false
        } else {
            return true
        }
        }
    }
}
</script>

<style>

</style>