<template>
  <v-app>
    <v-main>
        <v-row justify="center" align="center" class="mt-8">
            <v-card class="pa-4" height="100%" width="80vw">
                <v-card-title class="d-flex justify-center align-center py-0">
                    Volcom Report Steps
                </v-card-title>
                <v-carousel 
                    v-model="step"
                    hide-delimiter-background
                    hide-delimiters
                    :show-arrows="false"
                    height="100%"
                    class="overflow-y-auto pt-0">
                    <v-carousel-item height="100%">
                        <!-- upload new valid values -->
                        <v-card-title class="d-flex justify-center align-center">
                            Step 1
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            Enter Valid Values
                        </v-card-subtitle>
                        <v-sheet height="100%" class="overflow-y-auto">
                            <ol>
                                <li>
                                    Sign into <a href="https://royaltyzone.net/session/new">Royalty Zone</a>
                                </li>
                                <ol type="a">
                                    <li>
                                        Username: cclearman@matadorup.com
                                    </li>
                                    <li>
                                        password: Mata2014!
                                    </li>
                                </ol>
                                <li>
                                    After one tab is signed in click <a href="https://www.royaltyzone.net/sales_data/data_items/50983">here</a> to download valid values<br>
                                </li>
                                <li>
                                    Upload that file below
                                </li>
                            </ol>
                            <v-file-input
                                accept=".csv"
                                @change="importCsv()"
                                label="Upload Valid Values"
                                outlined
                                v-model="validValuesFile"/>
                            <v-row justify="space-around">
                                <v-btn
                                    :disabled="!stepOneComplete"
                                    @click="stepOne()"
                                    :loading="loading"
                                    color="primary">
                                    Next
                                </v-btn>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                    <!-- Set Dates -->
                    <v-carousel-item height="100%">
                        <v-card-title class="d-flex justify-center align-center">
                            Step 2
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            Enter Report Dates
                        </v-card-subtitle>
                        <v-sheet height="100%" class="overflow-y-auto">
                            <v-row align="center" justify="center">
                                <v-select
                                    width="10%"
                                    v-model="quarterSelected"
                                    :items="quarters"
                                    return-object
                                    item-text="name"
                                    label="Quarter"
                                    :hint="sortedDates[0] + ' to ' + sortedDates[1]"/>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-date-picker
                                    range
                                    v-model="dateRange"/>
                            </v-row>
                            <v-row justify="space-around">
                                <v-btn
                                    @click="step = 0">
                                    Prev
                                </v-btn>
                                <v-btn
                                    :disabled="!stepTwoComplete"
                                    @click="stepTwo()"
                                    :loading="loading"
                                    color="primary">
                                    Next
                                </v-btn>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                    <!-- assign unassigned accounts / email if need to add -->
                    <v-carousel-item height="100%">
                        <v-card-title class="d-flex justify-center align-center">
                            Step 3
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            Map New Companies
                        </v-card-subtitle>
                        <v-sheet height="100%" class="overflow-y-auto">
                            <v-data-table
                                class="overflow-y-auto pb-2"
                                :headers="headers"
                                :items="newAccounts">
                                <template v-slot:top>
                                    Unverified Accounts With Royalty Zone
                                </template>
                                <template v-slot:[`item.Volcom_Territory`]="{ item }">
                                    <v-autocomplete
                                        v-model="item.Volcom_Territory"
                                        :items="validTerritories"
                                        item-text="Territory"
                                        item-value="Id"
                                        @change="stepThree(item.Account_Id,'update','Volcom_Territory',item.Volcom_Territory)"/>
                                </template>
                                <template v-slot:[`item.Volcom_Distribution_Method`]="{ item }">
                                    <v-autocomplete
                                        v-model="item.Volcom_Distribution_Method"
                                        :items="validMethods"
                                        item-text="Method"
                                        item-value="Id"
                                        @change="stepThree(item.Account_Id,'update','Volcom_Distribution_Method',item.Volcom_Distribution_Method)"/>
                                </template>
                                <template v-slot:[`item.exclude`]="{ item }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                            color="primary"
                                            dark
                                            @click="stepThree(item.Account_Id, 'exclude')"
                                            v-bind="attrs"
                                            v-on="on">
                                            mdi-close
                                            </v-icon>
                                        </template>
                                        <span>Exclude Company From All Volcom Reports</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                            <v-row justify="space-around" class="pb-4">
                                <v-btn
                                    @click="step = 1">
                                    Prev
                                </v-btn>
                                <v-btn
                                    @click="setEmail()"
                                    :disabled="!newAccounts.length"
                                    color="secondary">
                                    Create Email
                                </v-btn>
                                <v-btn
                                    :disabled="!stepThreeComplete"
                                    @click="stepFour()"
                                    :loading="loading"
                                    color="primary">
                                    Next
                                </v-btn>
                            </v-row>
                            <v-card-subtitle class="d-flex justify-center align-center text-center">
                                The report cannot be generated until all accounts are either excluded or are assigned both a Territory and Distribution Method.<br>
                                If you would like to export the report regardless please assign all new accounts with the 'placeholder' distribution method.<br/>
                                Just remember this will need to be changed to a valid value before Royalty Zone will accept it.<br/>
                                This field can also be changed in the Accounts CRM.
                            </v-card-subtitle>
                        </v-sheet>
                    </v-carousel-item>
                    <!-- edit report as needed -->
                    <v-carousel-item height="100%">
                        <v-card-title class="d-flex justify-center align-center">
                            Step 4
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            Edit/Download Report
                        </v-card-subtitle>
                        <v-sheet height="100%" class="overflow-y-auto">
                            <v-data-table
                                :items="report"
                                :headers="reportHeaders">
                                <template v-slot:top class="text-h5">
                                    Total Revenue: {{ totalRevenue | currency }} | Royalties Due: {{ calculatePayment | currency}}
                                </template>
                                <template v-slot:[`item.Invoice_Date`]="{ item }">
                                    <v-text-field
                                        v-model="item['Invoice Date']"/>
                                </template>
                                <template v-slot:[`item.Customer`]="{ item }">
                                    <v-text-field
                                        v-model="item.Customer"/>
                                </template>
                                <template v-slot:[`item.SKU`]="{ item }">
                                    <v-autocomplete
                                        v-model="item.SKU"
                                        :items="validProducts"
                                        item-text="Product"
                                        item-value="Product"/>
                                </template>
                                <template v-slot:[`item.Territory`]="{ item }">
                                    <v-autocomplete
                                        v-model="item.Territory"
                                        :items="validTerritories"
                                        item-text="Territory"
                                        item-value="Territory"/>
                                </template>
                                <template v-slot:[`item.Distribution_Method`]="{ item }">
                                    <v-autocomplete
                                        v-model="item['Distribution Method']"
                                        :items="validMethods"
                                        item-text="Method"
                                        item-value="Method"/>
                                </template>
                                <template v-slot:[`item.Quantity`]="{ item }">
                                    <v-text-field
                                        v-model="item.Quantity"/>
                                </template>
                                <template v-slot:[`item.Unit_Price`]="{ item }">
                                    <v-text-field
                                        prefix="$"
                                        v-model="item['Unit Price']"/>
                                </template>
                                <template v-slot:[`item.Gross_Sales`]="{ item }">
                                    <v-text-field
                                        prefix="$"
                                        v-model="item['Gross Sales']"/>
                                </template>
                                <template v-slot:[`item.Sales_Tax`]="{ item }">
                                    <v-text-field
                                        prefix="$"
                                        v-model="item['Sales Tax']"/>
                                </template>
                                <template v-slot:[`item.Exemption_Reason`]="{ item }">
                                    <v-text-field
                                        v-model="item['Exemption Reason']"/>
                                </template>
                                <template v-slot:[`item.FOB`]="{ item }">
                                    <v-text-field
                                        v-model="item.FOB"/>
                                </template>
                                <template v-slot:[`item.Currency`]="{ item }">
                                    <v-text-field
                                        v-model="item.Currency"/>
                                </template>
                            </v-data-table>
                            <v-row align="center" justify="center">
                                <v-btn color="primary">
                                <download-csv
                                    class   = "btn btn-default"
                                    :data   = "report"
                                    name    = "Volcom_Royalty_Report.csv">
                                    Download Report
                                </download-csv>
                            </v-btn>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </v-card>
        </v-row>
        <v-dialog
            v-model="email.dialog"
            width="50vw"
            height="50vh">
            <v-card class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Email to Royalty Zone
                </v-card-title>
                <v-text-field
                    v-model="email.to"
                    outlined
                    label="To"/>
                <v-text-field
                    v-model="email.subject"
                    outlined
                    label="Subject"/>
                    <tiptap-vuetify
                        v-model="email.body"
                        :extensions="extensions"/>
                <v-card-actions class="d-flex justify-space-around align-center">
                    <v-btn
                        @click="email.dialog = false">
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="sendEmail()"
                        color="primary"
                        :loading="loading">
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { TiptapVuetify, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'

export default {
    components: { TiptapVuetify },
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Volcom Report'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Volcom Report')
    },
    watch: {
        quarterSelected: function () {
            let d = new Date()
            let year = Number(d.getFullYear())
            if (d.getMonth() == 0) {
                year -= 1
            }
            this.dateRange = [
                String(year) + "-" + this.quarterSelected.start,
                String(year) + "-" + this.quarterSelected.end
            ]
        }
    },
    data() {
        return {
            step: 0,
            quarters: [
                {
                    name: 'Quarter 1',
                    start: "01-01",
                    end: "03-31"
                },
                {
                    name: 'Quarter 2',
                    start: "04-01",
                    end: "07-30"
                },
                {
                    name: 'Quarter 3',
                    start: "08-01",
                    end: "09-30"
                },
                {
                    name: 'Quarter 4',
                    start: "10-01",
                    end: "12-31"
                }
            ],
            quarterSelected: {},
            validProducts: [],
            validMethods: [],
            validTerritories: [],
            validValuesFile: [],
            validValuesJson: [],
            dateRange: [""],
            newAccounts: [],
            report: [],
            email: {
                dialog: false,
                to: ["jrobel@authenticbrands.com","hbyun@authenticbrands.com"],
                subject: 'New Valid Values For Matador x Volcom',
                body: `<p>Hi Royalty Zone,<br>Please add the following companies as valid Distribution Methods:<br></p>`
            },
            extensions: [
                ListItem,
                BulletList,
                OrderedList
            ],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        importCsv() {
            let reader = new FileReader();
            reader.readAsText(this.validValuesFile)
            reader.onload = () => {
                this.csvToJson(reader.result)
            }
        },
        csvToJson(csvString) {
            const csv = require('csvtojson')
            // const lines = csvString.split("\n")
            // const csvHeaders = lines[0].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)

            csv({
                noheader: false,
                output: "json",
                flatKeys: true
            })
            .fromString(csvString)
            .then((jsonObject) => {
                this.validValuesJson = jsonObject
            })
        },
        async stepOne() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Volcom_Valid_Values?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/volcom/valid_values',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            products: this.products,
                            territories: this.territories,
                            methods: this.methods
                        })
                    }
                )
                if (!result.ok) {
                    throw result.message
                }
                const {
                    message,
                    products,
                    methods,
                    territories
                } = await result.json()
                
                this.validProducts = products
                this.validMethods = methods
                this.validTerritories = territories
                this.step = 1
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async stepTwo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Volcom_New_Accounts?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/volcom/new_accounts',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            startDate: this.sortedDates[0],
                            endDate: this.sortedDates[1]
                        })
                    }
                )
                if (!result.ok) {
                    throw result
                }
                const {
                    message,
                    newAccounts
                } = await result.json()
                if(newAccounts.length) {
                    this.newAccounts = newAccounts
                    this.step = 2
                } else {
                    this.stepFour()
                }
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                console.log(error)
                this.snack(JSON.parse(error),'error',3000)
            }
        },
        async stepThree(id, type, field, value) {
            if (type === 'exclude' || value !== null || value !== 0) {
                try {
                    this.loading = true
                    let body = {}
                    //if the update is to exclude the account then 
                    if (type === 'exclude') {
                        //set body for fetch request
                        body = {
                            type: type,
                            id: id
                        }
                        
                    } else if (type === 'update' && value !== null && value !== 0){
                        //if the udpate is not to exclude then
                        let update = {}
                        //set the update for the fetch request to be the field that equals the given value
                        update[field] = value
                        body = {
                            type: type,
                            id: id,
                            update: update
                        }
                    }
                    const result = await fetch(
                        //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Volcom_Update_Accounts?newFunction=true&tab=code
                        'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/volcom/new_accounts',
                        {
                            method: 'PUT',
                            Headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(body)
                        }
                    )
                    let account = this.newAccounts.find(item => item.Account_Id === id)
                    
                    if (type === 'exclude' || (account.Volcom_Distribution_Method !== 0 && account.Volcom_Territory !== 0)) {
                        //remove the account from the new accounts
                        let index = this.newAccounts.indexOf(account)
                        this.newAccounts.splice(index,1)
                    }
                    const {
                        message,
                    } = await result.json()
                
                    this.loading = false
                    this.snack(message,'success',3000)
                } catch (error) {
                    this.loading = false
                    this.snack(error,'error',3000)
                }
            }
        },
        async stepFour() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_VolcomRoyaltyReport?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/volcom',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            startDate: this.sortedDates[0],
                            endDate: this.sortedDates[1]
                        })
                    }
                )
            
                const {
                    message,
                    report
                } = await result.json()

                this.report = report
                this.step = 3
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        setEmail() {
            this.email.body= `<p>Hi Royalty Zone,<br>Please add the following companies as valid Distribution Methods:<br></p>`
            this.email.body += `<ul>`
            for (let i = 0; i < this.newAccounts.length; i++) {
                let country = this.validTerritories.find(item => item.Id === this.newAccounts[i].Volcom_Territory).Territory
                this.email.body += `<li>` + this.newAccounts[i].Company + `</li><ul><li>Country: ` + country + `</li><li>Specialty Retailer that carries similar brands to Volcom</li></ul>`
            }
            this.email.body += `</ul>`
            let newTerritories = this.newAccounts.filter(item => item.Volcom_Territory === 0)
            newTerritories = [... new Set(newTerritories.map(item => item.Country))]
            if (newTerritories.length) {
                this.email.body += `Please also add the following countries as valid Territories (given as country codes):<br>`
                for (let i = 0; i < newTerritories.length; i++) {
                    this.email.body += newTerritories[i] + `<br>`
                }
            }
            this.email.dialog = true
        },
        async sendEmail() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/email',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            body: this.email.body,
                            subject: this.email.subject,
                            to: this.email.to
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
                this.email.dialog = false
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        products() {
            return this.validValuesJson.map(item => item.SKU).filter(item => item)
        },
        territories() {
            return this.validValuesJson.map(item => item.Territory).filter(item => item)
        },
        methods() {
            return this.validValuesJson.map(item => item['Distribution Method']).filter(item => item)
        },
        stepOneComplete() {
            if (this.products.length || this.territories.length || this.methods.length) {
                return true
            } else {
                return false
            }
        },
        sortedDates() {
            if (this.dateRange.length === 2) {
                let firstDate = new Date(Date.parse(this.dateRange[0]))
                let secondDate = new Date(Date.parse(this.dateRange[1]))

                if (firstDate > secondDate) {
                    return [this.dateRange[1], this.dateRange[0]]
                } else {
                    return [this.dateRange[0], this.dateRange[1]]
                }
            } else if (this.dateRange.length === 1) { 
                return [this.dateRange[0]]
            } else {
                return []
            }
        },
        stepTwoComplete() {
            if (this.sortedDates.length === 2) {
                return true
            } else {
                return false
            }
        },
        stepThreeComplete() {
            if (this.newAccounts.length) {
                return false
            } else {
                return true
            }
        },
        totalRevenue() {
            return this.report.reduce((sum, record) => {
                return sum + record['Gross Sales']
            },0)
        },
        calculatePayment() {
            return this.totalRevenue * 0.08
        },
        headers() {
            return [
                {
                    align: 'start',
                    text: 'Company',
                    value: 'Company'
                },
                {
                    align: 'start',
                    text: 'Shipping Country',
                    value: 'Country'
                },
                {
                    align: 'start',
                    text: 'Volcom Territory',
                    value: 'Volcom_Territory'
                },
                {
                    align: 'start',
                    text: 'Volcom Distribution Method',
                    value: 'Volcom_Distribution_Method'
                },
                {
                    align: 'start',
                    text: 'Exclude',
                    value: 'exclude'
                },
            ]
        },
        reportHeaders() {
            return [
                {
                    align: 'start',
                    text: 'Invoice Date',
                    value: 'Invoice_Date'
                },
                {
                    align: 'start',
                    text: 'Customer',
                    value: 'Customer'
                },
                {
                    align: 'start',
                    text: 'SKU',
                    value: 'SKU'
                },
                {
                    align: 'start',
                    text: 'Territory',
                    value: 'Territory'
                },
                {
                    align: 'start',
                    text: 'Distribution Method',
                    value: 'Distribution_Method'
                },
                {
                    align: 'start',
                    text: 'Quantity',
                    value: 'Quantity'
                },
                {
                    align: 'start',
                    text: 'Unit Price',
                    value: 'Unit_Price'
                },
                {
                    align: 'start',
                    text: 'Gross Sales',
                    value: 'Gross_Sales'
                },
                {
                    align: 'start',
                    text: 'Sales Tax',
                    value: 'Sales_Tax'
                },
                {
                    align: 'start',
                    text: 'Exemption Reason',
                    value: 'Exemption_Reason'
                },
                {
                    align: 'start',
                    text: 'FOB',
                    value: 'FOB'
                },
                {
                    align: 'start',
                    text: 'Currency',
                    value: 'Currency'
                }
            ]
        }
    }
}
</script>

<style>

</style>