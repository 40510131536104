<template>
    <div class="flex-column justify-center align-center pt-16 px-8">
        <v-select
            class="pt-6"
            v-model="listId"
            :items="lists"
            hide-details
            item-text="Name"
            item-value="Id"
            label="List"
            @change="page = 1; getInfo()"/>
        <v-row class="py-0 my-0">
            <v-col cols="10">
                <v-card :loading="loading" width="100%">
                    <div class="d-flex justify-center align-center pr-4">
                        <v-text-field
                            class="pa-4"
                            v-model="search"
                            prepend-icon="mdi-magnify"
                            label="Search"
                            single-line
                            @keydown.enter="page = 1; getInfo()"
                            hide-details>
                            <template v-slot:prepend>
                                <v-btn
                                    icon
                                    @click="page = 1; getInfo()">
                                    <v-icon 
                                        :class="(search !== submittedSearch) ? 'pulse-icon': ''"
                                        :color="(search !== submittedSearch) ? 'primary' : 'grey'">mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                        <v-switch
                            v-model="showNulls"
                            color="primary"
                            label="Show Missing Costs"
                            @change="getInfo()"/>
                    </div>
                    <v-sheet height="60vh" style="overflow-y: scroll;">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <td class="flex-column justify-center align-center">
                                        Product
                                    </td>
                                    <td>SKU</td>
                                    <td>
                                        <v-select
                                            v-model="selectedCategory"
                                            :items="categories"
                                            item-text="Name"
                                            item-value="Cost ID"
                                            @change="page = 1; getInfo()"
                                            label="Category">
                                            <template v-slot:prepend>
                                                <v-btn
                                                    icon
                                                    @click="sortDirection = !sortDirection; getInfo()">
                                                    <v-icon>{{ sortDirection ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-select>
                                    </td>
                                </tr>
                            </thead>
                            <tbody v-if="!loading">
                                <tr 
                                    v-for="(product, index) in products" :key="index">
                                    <td>{{ product.name }}</td>
                                    <td>{{ product.sku }}</td>
                                    <td>
                                        <v-text-field
                                            :loading="product.loading"
                                            v-model="product.cost"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            @keydown.enter="singleUpdate(product, product)"
                                            @change="singleUpdate(product, product)"
                                            dense
                                            hide-details/>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-sheet>
                    <v-row>
                        <v-col>
                            <v-pagination
                                @input="getInfo()"
                                v-model="page"
                                :length="totalPages"/>
                        </v-col>
                        <v-col class="pr-10">
                            <v-select
                                v-model="limit"
                                :items="[10, 25, 50, 100]"
                                label="Items per page"
                                @change="getInfo('limit')"/>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-textarea
                    :loading="bulkLoading"
                    outlined
                    height="70vh"
                    v-model="bulkUpdate"
                    hide-details
                    :placeholder="`Bulk Update\n${currentBulkUpdate}\nPaste CSV here w/o headers\nSKU\tCost`"/>
                <v-btn
                    @click="bulkUpdateCosts()"
                    :disabled="!bulkUpdate"
                    color="primary"
                    class="mt-2">
                    Bulk Update
                </v-btn>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            :timeout="snackbarTimeout">
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>

<script>
export default {
    async beforeMount () {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - Ops Costs'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Ops Costs')
        if (this.$route.query.listId && this.$route.query.categoryId && this.$route.query.sku) {
            this.listId = Number(this.$route.query.listId)
            this.selectedCategory = Number(this.$route.query.categoryId)
            this.search = this.$route.query.sku
            this.showNulls = true
        } else if ('costSettings' in localStorage) {
            let settings = JSON.parse(localStorage.costSettings)
            this.listId = Number(settings.listId)
            this.limit = Number(settings.limit)
            this.selectedCategory = Number(settings.selectedCategory)
            this.showNulls = settings.showNulls
        }
        await this.getInfo()
    },
    data() {
        return {
            page: 1,
            totalPages: 0,
            limit: 10,
            listId: '',
            selectedCategory: '',
            search: '',
            bulkUpdate: '',
            sortDirection: true,
            submittedSearch: '',
            lists: [],
            categories: [],
            products: [],
            showNulls: true,
            loading: false,
            bulkLoading: false,
            snackbar: false,
            snackbarText: '',
            snackbarColor: '',
            snackbarTimeout: 3000,
        }
    },
    methods: {
        async getInfo(limitChange) {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/cost')
                //limit change goes first because it is also based on the search being different
                if(limitChange || (this.search === "" && this.search !== this.submittedSearch)) {
                    url.searchParams.append('limitChange', true)
                }
                //if the search is different, reset the page
                if (this.submittedSearch !== this.search) {
                    this.page = 1
                    this.submittedSearch = this.search
                }
                //append the 3 required parameters, email, page, and limit
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                url.searchParams.append('page', this.page)
                url.searchParams.append('limit', this.limit)
                url.searchParams.append('showNulls', this.showNulls)

                //if the listId is already loaded in the page, tell the server
                if(this.listId) {
                    url.searchParams.append('listId', this.listId)
                }
                if(this.selectedCategory) {
                    url.searchParams.append('categoryId', this.selectedCategory)
                }
                //if the categories are already loaded in the page, tell the server
                if(this.categories.length) {
                    url.searchParams.append('categoryLength', this.categories.length)
                }
                
                //if there is a search, append it to the url
                if (this.search.length) {
                    url.searchParams.append('search', this.search)
                }
                //append sort direction
                url.searchParams.append('sortDirection', this.sortDirection ? 'asc' : 'desc')
                
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Costs_GetInfo?code&tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                if(!result.ok) {
                    throw message
                }
                const {
                    message,
                    lists,
                    categories,
                    products,
                    listId,
                    categoryId,
                    totalPages
                } = await result.json()
                
                if (!this.listId) {
                    this.lists = lists
                    this.categories = categories
                    this.listId = listId
                    this.totalPages = totalPages
                    this.selectedCategory = categoryId
                }
                if (categories) {
                    this.categories = categories
                    this.lists = lists
                }
                if (totalPages) {
                    this.totalPages = totalPages
                }
                this.products = products
                this.products.forEach(product => {
                    product.loading = false
                })
                localStorage.costSettings = JSON.stringify({
                    listId: this.listId,
                    selectedCategory: this.selectedCategory,
                    showNulls: this.showNulls,
                    limit: this.limit
                })
                this.loading = false
                this.snack('Success', 'success', 3000)
            } catch (error) {
                this.errorMessage = JSON.stringify(error)
                this.loading = false

            }
        },
        //use this function to call this.updateCost with a array of the copy/paste parsed as an array of objects
        async bulkUpdateCosts() {
            this.bulkLoading = true
            //split rows by new line
            let rows = this.bulkUpdate.split('\n')
            let update = []
            //list through each row and split by tab
            for (let i = 0; i < rows.length; i++) {
                let row = rows[i].split('\t')
                //if the row has 2 items, push it to the update array
                if (row.length === 2) {
                    update.push({
                        'Cost ID': this.selectedCategory,
                        List_Id: this.listId,
                        SKU: row[0],
                        Cost: row[1],
                    })
                }
            }
            if (!update.length) {
                this.snack('No valid rows found. Ensure there is a price (including 0) next to ever sku.', 'error', 3000)
                this.bulkLoading = false
                return
            } else {
                this.snack(`Updating ${update.length} product costs`, 'info', 3000)
            }
            //call the updateCost function with the update array
            await this.updateCost(update)
            this.bulkLoading = false
        },
        //use this function to call this.updateCost with a single product object
        async singleUpdate(product) {
            product.loading = true
            let update = [{
                'Cost ID': this.selectedCategory,
                List_Id: this.listId,
                SKU: product.sku,
                Cost: product.cost,
            }]
            await this.updateCost(update)
            product.loading = false
        },
        async updateCost(update) {
            try {
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/cost')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Costs_UpsertCost?tab=code
                    url,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            update: update
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.snack(message,'success',3000)
            } catch (error) {
                this.snack(error,'error',3000)
            }
        },
        snack(text, color, timeout) {
            this.snackbarText = text;
            this.snackbarColor = color;
            this.snackbarTimeout = timeout;
        }
    },
    computed: {
        currentBulkUpdate() {
            let category = this.categories.find(item => item['Cost ID'] === this.selectedCategory)
            let list = this.lists.find(item => item.Id === this.listId)
            category = (category) ? category.Name : ''
            list = (list) ? list.Name : ''
            return `${list} ${category}`
        }
    }
}
</script>

<style>
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }
    50% {
        transform: scale(1.5);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.5;
    }
}

.pulse-icon {
    animation: pulse 1s infinite;
}

</style>