<template>
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card>
            <customTitle 
                :title="'EDI Process'" 
                :name="'ediProcess'" 
                @close="$emit('close')"/>
            <v-card-text>
                <p>
                    The EDI process is now been simplified from a user experience perspective. It is managed from the  
                    <a href="https://clearmanlabs.com/#/Ops/EDI-Stores" target="_blank">Sandbox</a> and the backend is covered by 
                    <a href="https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_EDI_Process?tab=code" target="_blank">AWS</a>. 
                    <ul>
                        <li>The login for AWS is:</li>
                        <li>Username: dev@matadorup.com</li>
                        <li>Password: Tiny2019!</li>
                    </ul>
                </p>
                <p class="d-flex justify-center align-center">
                    <u>Order Flow:</u>
                </p>
                <p class="d-flex justify-center align-center">
                    SPS Commerce > ShipStation > Cin7
                </p>
                <p>
                    The SPS Commerce to ShipStation connection is taken care of by ShipStation and I (Austin) have no control/input into that process. 
                    My contribution to the process is the ShipStation to Cin7 connection and is as follows:
                    <ol>
                        <li>Order is brought into ShipStation from SPS</li>
                        <li>Every morning at 5am (MST) all stores listed in the <a href="https://clearmanlabs.com/#/Ops/EDI-Stores" target="_blank">EDI manager</a> will be scanned for all on hold orders.</li>
                        <ul>
                            <li>If an order is on hold, it will be sent to Cin7</li>
                            <li>If an order is not on hold, it will be skipped</li>
                        </ul>
                        <li>All orders listed in that ShipStation store will be moved to Cin7 with the following map:</li>
                            <ul>
                                <li @click="showMap = !showMap" style="cursor: pointer;">
                                    <v-icon v-if="!showMap">mdi-chevron-right</v-icon> 
                                    <v-icon v-else>mdi-chevron-down</v-icon>
                                    See Map
                                </li>
                                <ul v-if="showMap">
                                    <li>Information:</li>
                                        <ul>
                                            <li>customerDetails and memberId is what is listed under Cin7 ID in the EDI manager</li>
                                            <li>|| is used as an OR operator so if the first field is not provided the option after the '||' is used</li>
                                        </ul>
                                    <li>
                                        <strong> 
                                            <a href="https://api.cin7.com/api/Help/Api/GET-v1-SalesOrders-id" target="_blank">Cin7 API Field</a>: 
                                            <a href="https://www.shipstation.com/docs/api/orders/list-orders/" target="_blank">ShipStation API Field</a>
                                        </strong>
                                    </li>
                                    <li>stage: 'Hold'</li>
                                    <li>total: shipstationOrder.orderTotal</li>
                                    <li>branchId: if the requested ship date is 150 days from today use the Preseason Branch from the EDI manager otherwise use the Normal Branch</li>
                                    <li>memberId: memberId</li>
                                    <li>lineItems:</li>
                                        <ul>
                                            <li>styleCode: shipstationOrder.items[].sku</li>
                                            <li>qty: shipstationOrder.items[].quantity</li>
                                            <li>unitPrice: shipstationOrder.items[].unitPrice</li>
                                            <li>code: shipstationOrder.items[].sku</li>
                                        </ul>
                                    <li>createdDate:	shipstationOrder.shipByDate?.split(".")[0] || shipstationOrder.createDate.split(".")[0] || ""</li>
                                    <li>billingCity: shipstationOrder.billTo.city</li>
                                    <li>billingState: shipstationOrder.billTo.country</li>
                                    <li>deliveryCity: shipstationOrder.shipTo.city</li>
                                    <li>productTotal: shipstationOrder.orderTotal</li>
                                    <li>deliveryState: shipstationOrder.shipTo.state</li>
                                    <li>invoiceNumber: shipstationOrder.orderNumber</li>
                                    <li>billingCompany: shipstationOrder.billTo.company || ""</li>
                                    <li>billingCountry:	shipstationOrder.billTo.country</li>
                                    <li>billingAddress1: shipstationOrder.billTo.street1</li>
                                    <li>billingAddress2: shipstationOrder.billTo.street2 || ""</li>
                                    <li>billingLastName: shipstationOrder.billTo.name.split(" ")[1] || ""</li>
                                    <li>customerOrderNo: shipstationOrder.orderNumber</li>
                                    <li>reference: shipstationOrder.orderNumber</li>
                                    <li>deliveryCompany: shipstationOrder.shipTo.company || ""</li>
                                    <li>deliveryCountry: shipstationOrder.shipTo.country</li>
                                    <li>billingFirstName: shipstationOrder.billTo.name.split(" ")[0] || ""</li>
                                    <li>deliveryAddress1: shipstationOrder.shipTo.street1</li>
                                    <li>deliveryAddress2: shipstationOrder.shipTo.street2 || ""</li>
                                    <li>deliveryLastName: shipstationOrder.shipTo.name.split(" ")[1] || ""</li>
                                    <li>billingPostalCode: shipstationOrder.billTo.postalCode</li>
                                    <li>deliveryFirstName: shipstationOrder.shipTo.name.split(" ")[0] || ""</li>
                                    <li>deliveryPostalCode:	shipstationOrder.shipTo.postalCode</li>
                                    <li>customFields: </li>
                                    <ul>
                                        <li>orders_1007 (Envoy Catalog Id): if the requested ship date is 150 days from today then use the <a href="https://go.cin7.com/Cloud/CRM/Contact.aspx?mode=N&idMember=1140&idWebSite=21518&idCustomerAppsLink=985802" target="_blank">Fax Field</a> else '63fe777c707bbf2775f2f771'</li>
                                        <li>orders_1005 (Submission Date): new Date().toISOString().split(".")[0]</li>
                                        <li>orders_1000 (External Sales Rep Field): customerDetails.customFields.members_1000</li>
                                        <li>orders_1001 (Shipping Account): customerDetails.customFields.members_1005</li>
                                    </ul>
                                    <li>accountingAttributes: </li>
                                        <ul>
                                            <li>accountingImportStatus: 'NotImported'</li>
                                        </ul>
                                    <li>estimatedDeliveryDate: shipstationOrder.shipByDate</li>
                                </ul>
                                <li @click="showMapChange = !showMapChange" style="cursor: pointer;">
                                    <v-icon v-if="!showMapChange">mdi-chevron-right</v-icon> 
                                    <v-icon v-else>mdi-chevron-down</v-icon>
                                    How to change the map
                                </li>
                                <ul v-if="showMapChange">
                                    <li>Go to the <a href="https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_EDI_Process?tab=code" target="_blank">AWS Function</a></li>
                                    <li>Find the function 'formatForCin7' (use crtl + f)</li>
                                    <li>Line item details are between lines 490 - 495 in the variable cin7Lines</li>
                                    <li>Order details are between lines 499 - 542 after the 'return' statement</li>
                                    <li>Reference the 
                                        <a href="https://api.cin7.com/api/Help/Api/GET-v1-SalesOrders-id" target="_blank">Cin7 API</a> and 
                                        <a href="https://www.shipstation.com/docs/api/orders/list-orders/" target="_blank">ShipStation API</a> 
                                        docs on what different fields are called (inspect element is also helpful for determining custom fields in Cin7)
                                    </li>
                                </ul>
                            </ul>
                        <li>After the order has been uploaded to Cin7, all order's 'Created Date' will be updated to the ShipStation 'Ship By Date'</li>
                        <li>After the Created Date has been updated successfully the order will be deleted from ShipStation</li>
                        <ul>
                            <li>If the order fails to be uploaded/updated in Cin7, the order will NOT be deleted and the error will be logged in the daily summary email</li>
                        </ul>
                    </ol>
                </p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import customTitle from './title.vue';
export default {
    name: 'EdiProcess',
    components: {
        customTitle
    },
    props: {
        dialog: Boolean
    },
    data() {
        return {
            showMap: false,
            showMapChange: false
        }
    },
    methods: {
        
    }
}
</script>

<style>

</style>