<template>
    <div>
        <v-text-field
            v-model="search"
            label="Search"
            @keydown.enter="page = 1; getInfo()"
            class="mx-4"/>
        <v-simple-table class="forecastTable">
            <thead>
                <tr>
                    <th style="min-width:350px !important">
                        <v-btn
                            icon
                            @click="sortDirection = !sortDirection; getInfo()">
                            <v-icon>{{ sortDirection ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}</v-icon>
                        </v-btn>
                        Product Name
                    </th>
                    <th v-for="(header, index) in headers" :key="index" style="min-width:125px !important;">
                        {{ header.text }}
                    </th>
                </tr>
            </thead> 
            <tbody v-if="!pageLoading">
                <tr v-for="(product, key, index) in tableData" :key="index" :class="productInactive(product.Active)">
                    <td style="min-width:350px !important" class="pl-8">
                        {{ product.Name }}<br><br>
                        {{ key }}
                    </td>
                    <td v-for="(header, index2) in headers" 
                        :key="index2 + 'h'" 
                        style="min-width:125px !important;"
                        :class="header.class">
                        <cellDetails 
                            @Update-Forecast="handleForecastUpdate"
                            @priceNotFound="handlePriceNotFound"
                            @warning="handleWarning"
                            @details="handleDetails"
                            :forecast="header.forecast"
                            :months="header.month"
                            :year="year"
                            :product="filterByMonths(product, header.month)"/>
                    </td>
                </tr>
                <!-- total row -->
                <tr :class="(getOtherSettings.showTotalRow) ? 'sticky-table-footer' : ''">
                    <td style="min-width:350px !important" class="pl-8">
                        Total
                    </td>
                    <td v-for="(header, index2) in headers" 
                        :key="index2 + 'h'"
                        :class="header.class">
                        <cellDetails
                            :forecast="header.forecast"
                            :months="header.month"
                            :year="year"
                            :product="filterByMonths(monthTotals, header.month)"/>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(number) in 7" :key="number">
                    <td 
                        :colspan="headers.length + 1" 
                        style="height: 75px;">
                        <div class="flex-column justify-space-around">
                            <v-progress-linear
                                class="mb-4"
                                color="blue-grey lighten-3"
                                stream
                                buffer-value="0"
                                rounded/>
                            <v-progress-linear
                                class="mb-4"
                                color="blue-grey lighten-4"
                                stream
                                buffer-value="0"
                                rounded/>
                            <v-progress-linear
                                color="blue-grey lighten-5"
                                stream
                                buffer-value="0"
                                rounded/>
                        </div>    
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <v-row>
            <v-col>
                <v-pagination
                    @input="getInfo()"
                    v-model="page"
                    :length="totalPages"/>
            </v-col>
            <v-col>
                <v-select
                    v-model="limit"
                    @change="getInfo()"
                    :items="[10,25,50,100]"
                    label="Items per page"
                    class="mx-4"/>
            </v-col>
        </v-row>
        <detail-card
            @Update-Forecast="handleForecastUpdate"
            @Update-Price-Cost="handlePriceOrCostsUpdate"
            @close="detailDialog = false"
            @Month-Change="handleMonthChange"
            :category="category"
            :year="year"
            :sku="selectedSKU"
            :month="selectedMonth"
            :dialog="detailDialog"
            :costName="costName"
            :priceName="priceName"
            :product="productData"/>
        <snack-warning
            @close="snackbar.show = false"
            :productInfo="productWarning.productInfo"
            :monthName="productWarning.monthName"
            :snackbar="snackbar"/>
        <price-not-found
            @Update-Price-Cost="handlePriceOrCostsUpdate"
            @close="priceNotFoundDialog = false"
            :dialog="priceNotFoundDialog"
            :costName="costName"
            :priceName="priceName"
            :year="year"
            :month="selectedMonth"
            :sku="selectedSKU"
            :priceNotFoundInfo="priceNotFoundInfo"/>
    </div>
</template>

<script>
//import { filter } from 'vue/types/umd';
//import productChart from './productChart.vue'
//import warning from '../General/warning.vue'
import cellDetails from './cellDetails.vue'
import snackWarning from './snackWarning.vue'
import priceNotFound from './priceNotFound.vue'
import detailCard from './detailCard.vue'
import { mapGetters } from 'vuex';

export default {
    name: 'Forecast-Table',
    components: {
        cellDetails,
        snackWarning,
        priceNotFound,
        detailCard
    //     productChart,
    //     warning
    },
    created() {
		window.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') {
				this.snackbar.show = false
			} 
            if (e.key === 'Right' && this.detailDialog) {
                if (this.selectedMonth < 12) {
                    this.selectedMonth++
                } else {
                    this.selectedMonth = 1
                }
                this.handleMonthChange({month: this.selectedMonth})
            }
            if (e.key === 'Left' && this.detailDialog) {
                if (this.selectedMonth > 1) {
                    this.selectedMonth--
                } else {
                    this.selectedMonth = 12
                }
                this.handleMonthChange({month: this.selectedMonth})
            }
		});
        if (this.$props.category && this.$props.year) {
            this.getInfo()
        }
	},
    watch: {
        category() {
            if (!this.pageLoading && this.$props.category && this.$props.year) {
                this.getInfo()
            }
        },
        year() {
            if (!this.pageLoading && this.$props.category && this.$props.year) {
                this.getInfo()
            } 
        },
        hideInactiveProducts() {
            this.getInfo()
        }
    },
    props: {
        year: {type: Number},
        category: {type: Object}
    },
    data() {
        return {
            data: [],
            productInfo: {},
            selectedMonth: 1,
            selectedSKU: '',
            priceName: '',
            costName: '',
            costPriceInfo: [],
            closeCells: 0,
            tableData: {},
            monthTotals: {},
            priceNotFoundDialog: false,
            productWarning: {
                productInfo: {},
                monthName: ''
            },
            priceNotFoundInfo: {
                type: '',
                listId: '',
                listName: ''
            },
            search: '',
            sortDirection: true,
            page: 1,
            limit: 10,
            totalPages: 0,
            detailDialog: false,
            pageLoading: false,
            cellLoading: false,
            snackbar: {
                complex: false,
                show: false,
                message: '',
                color: '',
                timeout: 3000,
            }
        }
    },
    methods: {
        async getInfo() {
            try {
                this.pageLoading = true
                let url = new URL('https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/forecast-details')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                url.searchParams.append('year', this.$props.year)
                url.searchParams.append('category', this.$props.category.Forecast_Id)
                url.searchParams.append('limit', this.limit)
                url.searchParams.append('page', this.page)
                url.searchParams.append('search', this.search)
                url.searchParams.append('sort', this.sortDirection ? 'asc' : 'desc')
                url.searchParams.append('hideInactive', this.getOtherSettings.hideInactiveProducts)
                
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_GetForecastInfoNew?fullscreen=true&tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    data,
                    monthTotals,
                    totalPages,
                    costName,
                    priceName,
                    costPriceInfo
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.data = data
                this.totalPages = (totalPages) ? totalPages : this.totalPages
                this.costName = (costName) ? costName[0].Name : this.costName
                this.priceName = (priceName) ? priceName[0].Name : this.priceName
                this.$emit('costPriceInfo', {
                    costName: this.costName,
                    priceName: this.priceName
                })

                this.costPriceInfo = costPriceInfo
                this.tableData = {}
                for (let i = 0; i < this.data.length; i++) {
                    const { 
                        SKU,
                        Active,
                        Name,
                        Month, 
                        Year,
                        Notes,
                        Discount_Percent,
                        Price,
                        Cost,
                        Forecast_Qty, 
                        Forecast_Total, 
                        Actual_Qty, 
                        Actual_Total } = this.data[i];

                    let costPrice = costPriceInfo.find(item => 
                        item.SKU === SKU && 
                        new Date(item.Start_Date) <= new Date(Year, Month - 1, 1) && 
                        new Date(item.End_Date) >= new Date(Year, Month - 1, 1)
                    )
                    const record = {
                        Notes,
                        Discount_Percent,
                        Recorded_Cost: Cost,
                        Recorded_Price: Price,
                        Listed_Cost_Price: costPrice,
                        Forecast_Total,
                        Actual_Qty,
                        Actual_Total,
                        Loading: false
                    };
                    if (Forecast_Qty !== null && Forecast_Qty !== undefined) {
                        record.Forecast_Qty = Math.round(Forecast_Qty)
                    } else {
                        record.Forecast_Qty = 0
                    }
                    if (!this.tableData[SKU]) {
                        this.tableData[SKU] = {};
                    }

                    this.tableData[SKU].Name = Name
                    this.tableData[SKU].Active = Active

                    if (!this.tableData[SKU][Month] && Month) {
                        this.tableData[SKU][Month] = {};
                    }
                    this.tableData[SKU][Month][Year] = record;
                }
                if (monthTotals) {
                    this.monthTotals= {}
                    for (let i = 0; i < monthTotals.length; i++) {
                        const { 
                            Month, 
                            Year,
                            Forecast_Qty, 
                            Forecast_Total, 
                            Actual_Qty, 
                            Actual_Total } = monthTotals[i];
    
                        const record = {
                            Forecast_Qty,
                            Forecast_Total,
                            Actual_Qty,
                            Actual_Total
                        };
    
                        if (!this.monthTotals[Month] && Month) {
                            this.monthTotals[Month] = {};
                        }
                        this.monthTotals[Month][Year] = record;
                    }
                }
                this.pageLoading = false
                if (this.page !== 1) {
                    this.snack(`Page ${this.page} of ${this.totalPages}`,'success',3000)
                }  else {
                    this.snack(message,'success',3000)
                }
            } catch (error) {
                this.pageLoading = false
                this.snack(error,'error',3000)
            }
        },
        handlePriceOrCostsUpdate(payload) {
            const {
                sku,
                listName,
                year,
                month,
                type,
                value
            } = payload
            for (let m in this.tableData[sku]) {
                if (m !== 'Name' && this.tableData[sku][m][year].Listed_Cost_Price.Cost_Price_List_Name === listName) {
                    if (type === 'price') {
                        this.tableData[sku][m][year].Listed_Cost_Price.Price = value
                    } else if (type === 'cost') {
                        this.tableData[sku][m][year].Listed_Cost_Price.Cost = value
                    }
                }
            }
            if(!this.getOtherSettings.speedRound) {
                if (type === 'cost') {
                    this.productInfo.listedCost = value
                } else {
                    this.productInfo.listedPrice = value
                }
                this.selectedMonth = month
                this.selectedSKU = sku
                this.detailDialog = true
            }
        },
        handlePriceNotFound(payload) {
            let today = new Date()
            let selectedDate = new Date(this.$props.year, payload.month - 1, 1)
            if (selectedDate <= today) {
                return
            }
            this.selectedMonth = payload.month
            this.selectedSKU = payload.SKU
            this.priceNotFoundInfo.type = payload.type
            this.priceNotFoundInfo.listId = payload.listId
            this.priceNotFoundInfo.listName = payload.listName
            this.priceNotFoundDialog = true
        },
        handleDetails(payload) {
            let today = new Date()
            let selectedDate = new Date(this.$props.year, payload.month - 1, 1)
            today.setDate(1)
            today.setHours(0,0,0,0)
            if (selectedDate < today) {
                this.snack('You cannot edit past months','error',3000)
                return
            }
            this.productInfo = payload.productInfo
            this.productInfo.costName = this.costName
            this.productInfo.priceName = this.priceName

            this.selectedSKU = payload.productInfo.SKU
            this.selectedMonth = payload.month
            if (this.productInfo.listedCost !== undefined && 
                this.productInfo.listedCost !== null &&
                this.productInfo.listedPrice!== undefined &&
                this.productInfo.listedPrice !== null) {
                if (
                    (
                        this.getOtherSettings.showPriceWarning && 
                        this.productInfo.cost !== this.productInfo.listedCost
                    ) || 
                    (
                        this.getOtherSettings.showPriceWarning && 
                        this.productInfo.price !== this.productInfo.listedPrice
                    )) {
                        this.productWarning.productInfo = this.productInfo
                        this.productWarning.monthName = payload.monthName
                        this.snackbar.color = 'orange'
                        this.snackbar.show = true
                        this.snackbar.timeout = -1
                        this.snackbar.complex = true
                    } 
                
                this.detailDialog = true
            } 
        },
        async handleForecastUpdate(payload) {
            let hide = payload.hide
            delete payload.hide
            const isNullOrUndefined = (value) => value === null || value === undefined
            let editObj = this.data.find(item => 
                item.SKU === payload.SKU &&
                item.Year === payload.Year &&
                item.Month === payload.Month
            )
            
            //subtract qtys and totals from the month total object
            this.monthTotals[payload.Month][payload.Year].Forecast_Qty -= Number(editObj.Forecast_Qty)
            this.monthTotals[payload.Month][payload.Year].Forecast_Total -= Number(editObj.Forecast_Total)        

            editObj.Qty = payload.Qty
            editObj.Forecast_Qty = payload.Qty
            editObj.Forecast_Category = this.$props.category.Forecast_Id

            if (!isNullOrUndefined(payload.Price)) {
                editObj.Price = payload.Price
            }
            if (!isNullOrUndefined(payload.Cost)) {
                editObj.Cost = payload.Cost
            }
            if (!isNullOrUndefined(payload.Discount_Percent)) {
                editObj.Discount_Percent = payload.Discount_Percent
            }
            if (!isNullOrUndefined(payload.Notes)) {
                editObj.Notes = payload.Notes
            }
            if (!isNullOrUndefined(payload.Total)) {
                editObj.Total = payload.Total
            }
            
            let objCopy = Object.assign({}, editObj)
            
            delete objCopy.Actual_Qty
            delete objCopy.Actual_Total
            delete objCopy.Name
            delete objCopy.Active
            delete objCopy.Forecast_Qty
            delete objCopy.Forecast_Total
            delete objCopy.Loading

            try {
                this.tableData[payload.SKU][payload.Month][payload.Year].Loading = true
                const url = new URL(`https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/forecast-details`)
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_UpdateForecastHoldingTable?tab=code
                    url,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            insert: objCopy
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.tableData[payload.SKU][payload.Month][payload.Year].Forecast_Qty = Number(payload.Qty)
                this.tableData[payload.SKU][payload.Month][payload.Year].Forecast_Total = Number(payload.Total)
                this.tableData[payload.SKU][payload.Month][payload.Year].Recorded_Cost = Number(payload.Cost)
                this.tableData[payload.SKU][payload.Month][payload.Year].Recorded_Price = Number(payload.Price)
                this.tableData[payload.SKU][payload.Month][payload.Year].Discount_Percent = Number(payload.Discount_Percent)
                this.tableData[payload.SKU][payload.Month][payload.Year].Notes = payload.Notes

                //add qtys and totals to the month total object
                this.monthTotals[payload.Month][payload.Year].Forecast_Qty += Number(payload.Qty)
                this.monthTotals[payload.Month][payload.Year].Forecast_Total += Number(payload.Total)

                this.tableData[payload.SKU][payload.Month][payload.Year].Loading = false
                if (this.getOtherSettings.hideModalAfterSubmit && hide) {
                    this.detailDialog = false
                }
                this.snack(message,'success',3000)
            } catch (error) {
                this.tableData[payload.SKU][payload.Month][payload.Year].Loading = false
                this.snack(error,'error',3000)
            }
        },
        handleMonthChange(payload) {
            let d = new Date()
            d.setDate(1)
            d.setHours(0,0,0,0)
            let selectedDate = new Date(this.$props.year, payload.month - 1, 1)
            if (selectedDate < d) {
                this.snack('You cannot edit past months','error',3000)
                return
            }
            this.selectedMonth = payload.month
            let productInfo = this.tableData[this.selectedSKU][this.selectedMonth][this.$props.year].Listed_Cost_Price
            // Helper function to check for null or undefined
            const isNullOrUndefined = (value) => value === null || value === undefined;

            const { Price, Cost, Cost_Price_List_Id, Cost_Price_List_Name } = productInfo;

            if (isNullOrUndefined(Price) || isNullOrUndefined(Cost)) {
                let type = isNullOrUndefined(Price) ? 'price' : 
                            isNullOrUndefined(Cost) ? 'cost' : 
                            'both';

                this.priceNotFoundInfo = {
                    type,
                    listId: Cost_Price_List_Id,
                    listName: Cost_Price_List_Name
                };

                this.priceNotFoundDialog = true;
                this.detailDialog = false
            }
        },
        cellClicked(active) {
            this.cellIsActive = active
        },
        filterByMonths(data, months) {
            let filtered = {}
            for (let i = 0; i < months.length; i++) {
                const month = months[i];
                if (data[month]) {
                    filtered[month] = data[month]
                }
            }
            if ('Name' in data) {
                filtered.Name = data.Name
            }
            return filtered
        },
        handleWarning(payload) {
            this.productWarning.productInfo = payload.productInfo
            this.productWarning.monthName = payload.monthName
            this.snackbar.color = 'orange'
            this.snackbar.show = true
            this.snackbar.timeout = -1
            this.snackbar.complex = true
            this.productWarning.snackbar = payload.snackbar
        },
        productInactive(active) {
            if (active == "0") {
                return 'inactiveProduct'
            } else {
                return ''
            }
        },
        snack(text,color,timeout) {
            this.snackbar.complex = false
            this.snackbar.timeout = timeout
            this.snackbar.message = text
            this.snackbar.color = color
            this.snackbar.show = true
        },
    },
    computed: {
        //map otherSettings getter
        ...mapGetters([
            'getOtherSettings'
        ]),
        hideInactiveProducts() {
            return this.getOtherSettings.hideInactiveProducts
        },
        productData() {
            //if there is a product selected, send that data
            if (this.tableData[this.selectedSKU]) {
                return this.tableData[this.selectedSKU]
            //else create a blank structure to send to the component
            } else {
                let temp = {}
                for (let i = 1; i < 13; i++) {
                    temp[i] = {}
                    for (let y = this.$props.year; y >= this.$props.year - 2; y--) {
                        temp[i][y] = {
                            Actual_Qty: 0,
                            Forecast_Qty: 0
                        }
                    }
                }
                temp.Name = ''
                return temp
            }
        },
        headers() {
			return [
				{
					text: 'Jan Hist',
					value: 'hist_1',
					month: [1],
					forecast: false,
                    class: ''
				},
				{
					text: 'Jan',
					value: '1',
					align: 'center',
					month: [1],
					forecast: true,
                    class: ''
				},
				{
					text: 'Feb Hist',
					value: 'hist_2',
					month: [2],
					forecast: false,
                    class: ''
				},
				{
					text: 'Feb',
					value: '2',
					align: 'center',
					month: [2],
					forecast: true,
                    class: ''
				},
				{
					text: 'Mar Hist',
					value: 'hist_3',
					month: [3],
					forecast: false,
                    class: ''
				},
				{
					text: 'Mar',
					value: '3',
					align: 'center',
					month: [3],
					forecast: true,
                    class: ''
				},
				{
					text: 'Apr Hist',
					value: 'hist_4',
					month: [4],
					forecast: false,
                    class: ''
				},
				{
					text: 'Apr',
					value: '4',
					align: 'center',
					month: [4],
					forecast: true,
                    class: ''
				},
				{
					text: 'May Hist',
					value: 'hist_5',
					month: [5],
					forecast: false,
                    class: ''
				},
				{
					text: 'May',
					value: '5',
					align: 'center',
					month: [5],
					forecast: true,
                    class: ''
				},
				{
					text: 'Jun Hist',
					value: 'hist_6',
					month: [6],
					forecast: false,
                    class: ''
				},
				{
					text: 'Jun',
					value: '6',
					align: 'center',
					month: [6],
					forecast: true,
                    class: ''
				},
				{
					text: '1st Half Total',
					value: '1stHalfTotal',
					align: 'center',
					month: [1,2,3,4,5,6],
					forecast: false,
                    class: 'actualTotals'
				},
				{
					text: '1st Half Forecast Total',
					value: '1stHalfForecastTotal',
					align: 'center',
					month: [1,2,3,4,5,6],
					forecast: true,
                    class: 'forecastTotals'
				},
				{
					text: 'Jul Hist',
					value: 'hist_7',
					month: [7],
					forecast: false,
                    class: ''
				},
				{
					text: 'Jul',
					value: '7',
					align: 'center',
					month: [7],
					forecast: true,
                    class: ''
				},
				{
					text: 'Aug Hist',
					value: 'hist_8',
					month: [8],
					forecast: false,
                    class: ''
				},
				{
					text: 'Aug',
					value: '8',
					align: 'center',
					month: [8],
					forecast: true,
                    class: ''
				},
				{
					text: 'Sep Hist',
					value: 'hist_9',
					month: [9],
					forecast: false,
                    class: ''
				},
				{
					text: 'Sep',
					value: '9',
					align: 'center',
					month: [9],
					forecast: true,
                    class: ''
				},
				{
					text: 'Oct Hist',
					value: 'hist_10',
					month: [10],
					forecast: false,
                    class: ''
				},
				{
					text: 'Oct',
					value: '10',
					align: 'center',
					month: [10],
					forecast: true,
                    class: ''
				},
				{
					text: 'Nov Hist',
					value: 'hist_11',
					month: [11],
					forecast: false,
                    class: ''
				},
				{
					text: 'Nov',
					value: '11',
					align: 'center',
					month: [11],
					forecast: true,
                    class: ''
				},
				{
					text: 'Dec Hist',
					value: 'hist_12',
					month: [12],
					forecast: false,
                    class: ''
				},
				{
					text: 'Dec',
					value: '12',
					align: 'center',
					month: [12],
					forecast: true,
                    class: ''
				},
				{
					text: '2nd Half Total',
					value: '2ndHalfTotal',
					align: 'center',
					month: [7,8,9,10,11,12],
					forecast: false,
                    class: 'actualTotals'
				},
				{
					text: '2nd Half Forecast Total',
					value: '2ndHalfForecastTotal',
					align: 'center',
					month: [7,8,9,10,11,12],
					forecast: true,
                    class: 'forecastTotals'
				},
				{
					text: 'Grand Total',
					value: 'total',
					align: 'center',
					month: [1,2,3,4,5,6,7,8,9,10,11,12],
					forecast: false,
                    class: 'actualTotals'
				},
				{
					text: 'Forecast Grand Total',
					value: 'forecastTotal',
					align: 'center',
					month: [1,2,3,4,5,6,7,8,9,10,11,12],
					forecast: true,
                    class: 'forecastTotals'
				},
			];
		},
    },
}
</script>

<style>
.inactiveProduct {
    color: red;
}
.actualTotals {
    background-color: var(--v-primary-base) !important;
    color: white;
}

.forecastTotals {
    background-color: var(--v-primary-lighten1) !important;
    color: white;
}
.sticky-table-footer td {
    font-weight: bold;
    position: sticky;
    bottom: 0;
    background-color: white;;
    border-top: thin solid rgba(0,0,0,.12);
}
.forecastTable {
    overflow-x: scroll !important;
    width: 100%;
    height: 65vh;
    table-layout: fixed;
    overflow-y: scroll !important;
}
.forecastTable table {
    width: 100%;
}
.forecastTable table > tbody > tr:nth-of-type(even) > td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 1;
    background: white;
}

.forecastTable tbody > tr:nth-of-type(odd) > td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 1;
    background: rgb(242,242,242);;
}

.forecastTable thead > tr:nth-child(1) {
    z-index: 2;
    top: 0;
    position: sticky !important;
    position: -webkit-sticky !important;
    background-color: white;
}
.forecastTable thead > tr > th:nth-child(1) {
    z-index: 3;
    top: 0;
    left: 0;
    position: sticky !important;
    position: -webkit-sticky !important;
    background-color: white;
}
.forecastTable tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}
.v-data-table__wrapper {
    overflow-x: visible;
    overflow-y: visible;
}
</style>