<template>
    <v-app>
        <v-main class="d-flex justify-center align-center">
            <sales-rep-list
                class="d-flex justify-center align-center"
                @new-sales-rep-added="newSalesRepAdded"
                @new-list-added="newListAdded"
                :salesRepData="salesRepData"
                :managers="managers"
                :lists="lists"/>
            <v-snackbar
                v-model="snackActive"
                :color="snackColor"
                :timeout="snackTimeout">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
import salesRepList from './components/salesRepList.vue'
export default {
    beforeMount() {
        this.getSalesRepInformation()
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - Manage Sales Rep'
        this.$store.dispatch('updateNavbarContent', [
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ])
        this.$store.dispatch('updateNavbarTitle','Manage Sales Rep')
    },
    components: {
        salesRepList
    },
    data() {
        return {
            salesRepData: [],
            lists: [],
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        async getSalesRepInformation() {
            try {
                //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/SandBox_SalesReps_GetAllSalesRep?tab=code
                const response = await fetch(
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/manage',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    salesRepData,
                    managers,
                    lists
                } = await response.json()
                this.managers = managers
                this.salesRepData = salesRepData
                this.lists = lists
                this.snack(message, 'success', 3000)
            } catch (error) {
                this.snack(error,'error',3000)
            }
        },
        newSalesRepAdded(data) {
            this.salesRepData = data
        },
        newListAdded(data) {
            this.lists.push(data)
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    }
}
</script>

<style>

</style>