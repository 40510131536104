<template>
    <v-app>
        <v-main>
            <v-row justify="center" align="start" height="100%" class="pt-8" v-if="!loading.master">
                <v-col>
                    <v-card-title class="d-flex justify-center align-center">
                        Envoy Visual Progress Lighthouse
                    </v-card-title>
                    <v-simple-table
                        :loading="loading">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    Info
                                </th>
                                <th class="text-center">
                                    Category
                                </th>
                                <th class="text-center">
                                    Refresh Main Envoy
                                </th>
                                <th class="text-center">
                                    Refresh Distributor Envoy
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <v-tooltip bottom v-if="!loading.branches">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Select which Cin7 branches should be monitored for updates and order status to send to Envoy<br>
                                            You cannot select the same branch for both Envoy instances.
                                        </span>
                                    </v-tooltip>
                                    <v-progress-circular v-else
                                        indeterminate
                                        color="primary"/>
                                </td>
                                <td>
                                    <strong>Branches</strong>
                                </td>
                                <td class="flex-column justify-center align-center" height="100%">
                                    <v-btn
                                        @click="branch = 'Matador'"
                                        color="primary">
                                        Branches
                                    </v-btn>
                                </td>
                                <td class="flex-column justify-center align-center" height="100%">
                                    <v-btn
                                        @click="branch = 'Matador Global'"
                                        color="primary">
                                        Branches
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Push all updates made to orders in Cin7 in the past day to Envoy.<br>
                                            Filters orders based on the branches selected in the Branches row<br>
                                            and if the order has been updated (saved) in the past 24 hours<br>
                                            This happens naturally at the bottom of every hour during the workday from Mon - Fri.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <v-btn
                                        icon
                                        color="grey"
                                        @click="updateSpecificOrder = !updateSpecificOrder">
                                        <v-icon v-if="!updateSpecificOrder">mdi-chevron-right</v-icon>
                                        <v-icon v-else>mdi-chevron-down</v-icon>
                                    </v-btn>
                                    <strong>Order Updates:</strong> Cin7 > Envoy
                                </td>
                                <td class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            color="primary"
                                            class="my-2"
                                            :loading="loading.orderUpdates"
                                            @click="pullAllCin7Updates()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ orderUpdateTime }}</span>
                                    </div>
                                </td>
                                <td class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            color="primary"
                                            class="my-2"
                                            :loading="loading.distributorOrderUpdates"
                                            @click="pullAllCin7UpdatesToDistributor()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ distributorOrderUpdateTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="updateSpecificOrder">
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>Push only updates made to a single order in Cin7 to Envoy</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    Update Specific Envoy Order From Cin7
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="id"
                                        label="Cin7 Sales Order Id"
                                        class="px-8">
                                        <template v-slot:append>
                                            <v-btn
                                                width="100%"
                                                color="primary"
                                                @click="updateOrder(id)"
                                                :loading="loading.specificOrder"
                                                :disabled="!id.length">
                                                Update
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </td>
                                <td colspan="2" class="flex-column flex-column align-center" height="100%">
                                    <v-text-field
                                        v-model="distributorId"
                                        label="Cin7 Sales Order Id"
                                        class="px-8">
                                        <template v-slot:append>
                                            <v-btn
                                                width="100%"
                                                color="primary"
                                                @click="updateDistributorOrder(distributorId)"
                                                :loading="loading.specificDistributorOrder"
                                                :disabled="!distributorId.length">
                                                Update
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </td>
                            </tr>
                            <tr v-if="updateSpecificOrder && (updateComplete || updateError)">
                                <td colspan="3" v-if="updateComplete">
                                    <v-icon color="green">mdi-check-circle</v-icon><br>
                                    Order {{ id }} has been sent to Envoy Servers. Order updates take 10-30 minutes, so please be patient.
                                </td>
                                <td colspan="3" v-else-if="updateError">
                                    <v-icon color="red">mdi-close-circle</v-icon><br>
                                        Something went wrong... take a screenshot and send this to the developer:
                                        {{ errorMessage }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Look for Orders in the SFTP server waiting to come over to Cin7.<br>
                                            This happens naturally at the top of every hour during the workday from Mon - Fri.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Orders: </strong>Envoy > Cin7
                                </td>
                                <td class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            color="primary"
                                            class="my-2"
                                            :loading="loading.envoyOrders"
                                            @click="pullAllEnvoyOrders()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ pushOrderTime }}</span>
                                    </div>
                                </td>
                                <td class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            color="primary"
                                            class="my-2"
                                            :loading="loading.distributorEnvoyOrders"
                                            @click="pullAllDistributorEnvoyOrders()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ pushDistributorOrderTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Push all order status information (Tracking, Ship Date, Status) that have been marked as Dispatched in Cin7 in the past day.<br>
                                            This happens naturally at the top of every hour during the workday from Mon - Fri.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Order Status: </strong>Cin7 > Envoy
                                </td>
                                <td class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            color="primary"
                                            class="my-2"
                                            :loading="loading.orderStatus"
                                            @click="pushEnvoyStatus()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ orderStatusTime }}</span>
                                    </div>
                                </td>
                                <td class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            color="primary"
                                            class="my-2"
                                            :loading="loading.distributorOrderStatus"
                                            @click="pushDistributorEnvoyStatus()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ distributorOrderStatusTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            View and retry orders that have failed to be pushed to Cin7.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Order Exports: </strong>Envoy > Cin7
                                </td>
                                <td colspan="2" class="flex-column flex-column align-center" height="100%">
                                    <v-btn
                                        width="100%"
                                        class="my-2"
                                        color="primary"
                                        @click="getHistoricalOrders(); historicalOrdersDialog = true;">
                                        View Historical Orders
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Push all products that have been updated in Cin7 in the past 2 days to Envoy.<br>
                                            This happens naturally every morning at 6 am.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Products: </strong>Cin7 > Envoy
                                </td>
                                <td colspan="2" class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            width="100%"
                                            class="my-2"
                                            color="primary"
                                            :loading="loading.products"
                                            @click="refreshProducts()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ productTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Pushes product updates from Cin7 to the Database.<br>
                                            Do this if syncing stock is not acting has you expect.<br>
                                            Remember, a product must be Active, not a component, and in the B2B channel to sync stock.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Products: </strong>Cin7 > Database
                                </td>
                                <td colspan="2" class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                        width="100%"
                                        color="primary"
                                        class="my-2"
                                        :loading="loading.databaseProducts"
                                        @click="refreshDatabaseProducts()">
                                        Refresh
                                    </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ databaseProductsTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Push stock updates from Cin7 to Envoy.<br>
                                            This happens naturally every day at 6 am.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Stock: </strong>Cin7 > Envoy
                                </td>
                                <td colspan="2" class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            width="100%"
                                            class="my-2"
                                            color="primary"
                                            :loading="loading.stock"
                                            @click="refreshStock()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ stockTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Push prices from the sandbox to Envoy.<br>
                                            This does NOT current happen automatically, but will be changed to happen automatically in a few weeks (3/28/23).
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Prices: </strong>Sandbox > Envoy
                                </td>
                                <td colspan="2" class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            width="100%"
                                            class="my-2"
                                            color="primary"
                                            :loading="loading.prices"
                                            @click="refreshPrices()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ priceTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            This is just an FYI for when the last time a new customer was added to Envoy!
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    Last Customer Created in Envoy
                                </td>
                                <td colspan="2" class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    width="100%"
                                                    class="my-2"
                                                    color="grey"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on">
                                                    Refresh
                                                </v-btn>
                                            </template>
                                            <span>Customer Creation Cannot be Refreshed</span>
                                        </v-tooltip>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ customerCreateTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Push updates made to customers in the past day from Cin7 to Envoy.<br>
                                            The customer must be assigned the MatadorUp.com, Wholesale, or Canada Wholesale price list<br>
                                            Must be active, not assigned to the Sales Rep or Distributor Industry and be assigned the Wholesale Channel<br>
                                            They must have been created within the last 6 months OR placed an order since 2021-01-01.<br>
                                            This happens naturally every morning at 6 am.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <v-btn
                                        icon
                                        color="grey"
                                        @click="updateSpecificCustomer = !updateSpecificCustomer">
                                        <v-icon v-if="!updateSpecificCustomer">mdi-chevron-right</v-icon>
                                        <v-icon v-else>mdi-chevron-down</v-icon>
                                    </v-btn>
                                    <strong>Customers: </strong>Cin7 > Database
                                </td>
                                <td colspan="2" class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            width="100%"
                                            class="my-2"
                                            color="primary"
                                            :loading="loading.customer"
                                            @click="refreshCustomers()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ customerUpdateTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="updateSpecificCustomer">
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Push updates only for a specific customer.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    Update Specific Customer
                                </td>
                                <td>
                                    <v-text-field
                                        label="Cin7 Customer Id"
                                        v-model="customer"
                                        class="px-4"/>
                                </td>
                                <td colspan="2">
                                    <v-btn
                                        width="100%"
                                        color="primary"
                                        :disabled="!customer.length"
                                        :loading="loading.specificCustomer"
                                        @click="updateSpecificCustomerId()">
                                        Update
                                    </v-btn>
                                </td> 
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Pushes customers recently updated in the Sandbox to Envoy<br>
                                            This should be used if there is a field not present in Cin7 that was updated in the Sandbox.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Customers: </strong>Sandbox > Envoy
                                </td>
                                <td class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            color="primary"
                                            class="my-2"
                                            :loading="loading.sandboxCustomerUpdate"
                                            @click="sandboxCustomerUpdate()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ sandboxCustomerUpdateTime }}</span>
                                    </div>
                                </td>
                                <td class="flex-column flex-column align-center" height="100%">
                                    <div class="text-center">
                                        <v-btn
                                            color="primary"
                                            class="my-2"
                                            :loading="loading.sandboxDistributorCustomerUpdate"
                                            @click="sandboxDistributorCustomerUpdate()">
                                            Refresh
                                        </v-btn>
                                    </div>
                                    <div class="text-center">
                                        <span>{{ sandboxDistributorCustomerUpdateTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Swaps an order from one Envoy account to another.<br>
                                            Must be used in conjunction with 'Order Exports: Envoy > Cin7' tool.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>Envoy Order Swap</strong>
                                </td>
                                <td colspan="2">
                                    <v-btn
                                        width="100%"
                                        color="primary"
                                        @click="getHistoricalOrders(); swapOrdersDialog = true;">
                                        View Orders
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            These orders will be ignored by the Bot that crawls Envoy looking for orders that are in Cin7 but NOT in Envoy.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <strong>
                                        Envoy Only Orders
                                    </strong>
                                </td>
                                <td colspan="2">
                                    <v-btn
                                        width="100%"
                                        color="primary"
                                        @click="getenvoyOnlyOrders()">
                                        View Orders
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
                <v-col>
                    <v-card-title class="d-flex justify-center align-center">
                        Other Ops Actions
                    </v-card-title>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-center">
                                    Info
                                </th>
                                <th class="text-center">
                                    Category
                                </th>
                                <th class="text-center">
                                    Refresh
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            Removes all lines in the forecasting tool marked as preorder. This should be used if the SGT is providing different data than the forecasting tool.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td class="text-center">
                                    Remove Preorders From Forecast
                                </td>
                                <td class="text-center">
                                    <v-btn
                                        color="primary"
                                        :loading="loading.forecastPreorders"
                                        @click="removeForecastPreorders()">
                                        Refresh
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            The forecasting tool updates a holding table for fast input/update of the forecast. After every input/update, it is supposed to update the Lines table so that each account can have its own allocation of the inputed forecast based on its forecast ratio. However, if for some reason the SGT is different than the forecasting tool, try selecting this refresh button to manually push the holding table to the lines table.
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td class="text-center">
                                    Push Forecasting Holding Table to Lines Table
                                </td>
                                <td class="text-center">
                                    <v-btn
                                        color="primary"
                                        :loading="loading.forecastHoldingToLines"
                                        @click="forecastHoldingToLines()">
                                        Refresh
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-row  justify="center" align="center" height="100%" class="pt-8" v-else>
                <v-progress-circular indeterminate color="primary"/>
            </v-row>
            <v-dialog
                v-model="dialog"
                width="50vw">
                <v-card width="50vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Items Updated
                    </v-card-title>
                    <v-simple-table v-if="updated.length">
                        <thead>
                            <tr>
                                <td v-for="(header, index) in updatedHeaders" :key="index">
                                    {{  header }}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in updated" :key="index">
                                <td v-for="(header, index2) in updatedHeaders" :key="index2">
                                    {{ item[header] }}
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card-subtitle class="d-flex justify-center align-center" v-else>
                        Nothing found in the update
                    </v-card-subtitle>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            color="secondary"
                            @click="dialog = false">
                            Okay
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="errorDialog" width="50vw">
                <v-card width="50vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Whoops... Error
                    </v-card-title>
                    <v-card-text class="d-flex justify-center align-center">
                        {{ errorMessage }}
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="branchDialog" width="50vw">
                <v-card width="50vw" height="50vh" class="pa-4" :loading="loading.branch">
                    <v-card-title class="d-flex justify-center align-center">
                        {{ branch }} Branches
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                        If a branch is disabled it means that it is active in the other Envoy instance. To select this branch, unselect it from the other instance.
                    </v-card-subtitle>
                    <v-sheet class="d-flex justify-center align-center">
                        <v-chip-group column multiple v-model="selectedInstanceBranches" active-class="primary">
                            <v-chip v-for="(branch, index) in branches" :key="index" @click="updateBranch(branch)" :disabled="isBranchDisabled(branch)">
                                {{ branch.company }}
                            </v-chip>
                        </v-chip-group>
                    </v-sheet>
                </v-card>
            </v-dialog>
            <!-- Historical Orders -->
            <v-dialog
                v-model="historicalOrdersDialog"
                width="80vw"
                height="80vh">
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Historical Orders
                    </v-card-title>
                    <v-progress-circular v-if="loading.historicalOrders" indeterminate color="primary" class="d-flex justify-center align-center"/>
                    <v-row v-else>
                        <v-col cols="6">
                            <v-card height="100%" class="pa-4" :loading="loading.retryHistoricalOrder1">
                                <v-card-title class="d-flex justify-center align-center">
                                    Matador
                                </v-card-title>
                                <v-text-field
                                    v-model="historicalOrderSearch"
                                    label="Search"
                                    hide-details
                                    prepend-icon="mdi-magnify"/>
                                <v-container class="d-flex justify-center align-start">
                                    <v-list>
                                        <v-list-item 
                                            v-for="(item, index) in filteredHistoricalOrders" 
                                            :key="index"
                                            class="d-flex justify-start align-start">
                                                <v-list-item-title>
                                                    {{ formatOrder(item) }}
                                                </v-list-item-title>
                                                <v-list-item-action>
                                                    <v-btn
                                                        icon
                                                        color="primary"
                                                        @click="retryHistoricalOrder(item, 'retryHistoricalOrder1')">
                                                        <v-icon>mdi-refresh</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-card class="pa-4" :loading="loading.retryHistoricalOrder2">
                                <v-card-title class="d-flex justify-center align-center">
                                    Matador Global
                                </v-card-title>
                                <v-text-field
                                    v-model="historicalOrderSearch2"
                                    label="Search"
                                    hide-details
                                    prepend-icon="mdi-magnify"/>
                                <v-container class="d-flex justify-center align-start">
                                    <v-list>
                                        <v-list-item 
                                            v-for="(item, index) in filteredHistoricalOrders2" 
                                            :key="index"
                                            class="d-flex justify-start align-start">
                                                <v-list-item-title>
                                                    {{ formatOrder(item) }}
                                                </v-list-item-title>
                                                <v-list-item-action>
                                                    <v-btn
                                                        icon
                                                        color="primary"
                                                        @click="retryHistoricalOrder(item, 'retryHistoricalOrder2')">
                                                        <v-icon>mdi-refresh</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row> 
                </v-card>
            </v-dialog>
            <v-dialog v-model="swapOrdersDialog" width="50vw">
                <v-card class="pa-4" :loading="loading.historicalOrders">
                    <v-card-title class="d-flex justify-center align-center">
                        Swap Orders
                        <v-btn
                            icon
                            class="pl-4"
                            color="primary"
                            to="/pam?page=swapEnvoyOrder">
                            <v-icon
                                color="primary"
                                @click="swapOrdersDialog = false">
                                mdi-wizard-hat
                            </v-icon>
                        </v-btn>
                        
                    </v-card-title>
                    <v-row>
                        <v-col>
                            <v-autocomplete
                                :disabled="loading.historicalOrders"
                                v-model="selectedHistoricalOrderForSwap"
                                @change="getOrderSwapInfo(selectedHistoricalOrderForSwap)"
                                :items="allHistoricalOrders"
                                :loading="loading.getSwapOrder"
                                label="Select Order"/>
                        </v-col>
                        <v-col>
                            <u>
                                <strong>
                                    Current Customer Assigned
                                </strong>
                            </u>
                            {{ currentCustomerIdForSwap }}: {{ customerName }}
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                :items="allCustomers"
                                item-text="Concatenated"
                                item-value="Id"
                                label="New Customer Id"
                                v-model="newCustomerIdForSwap"/>
                        </v-col>
                    </v-row>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            color="primary"
                            :loading="loading.swapOrder"
                            :disabled="newCustomerIdForSwap === '' || 
                                    selectedHistoricalOrderForSwap === '' ||
                                    currentCustomerIdForSwap === 'Select Order to View Customer Id' ||
                                    currentCustomerIdForSwap === 'Error Getting Customer Id' ||
                                    showSwapOrderMessage"
                            @click="swapOrder()">
                            Swap Order
                        </v-btn>
                    </v-card-actions>
                    <v-row v-if="showSwapOrderMessage">
                        <v-col>
                            <v-alert
                                type="info">
                                This order has been swapped. 
                                You can now use the 'Order Exports: Envoy > Cin7' tool to push the order to Cin7.<br>
                                It will be updated in Envoy in the next 20-30 minutes.
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-if="showSwapOrderMessage" justify="center" align="center">
                        <v-btn
                            color="primary"
                            @click="closeSwapOrderDialog()">
                            Close
                        </v-btn>
                    </v-row>
                </v-card>
            </v-dialog>
            <!-- Envoy Only Orders -->
            <v-dialog v-model="envoyOnlyOrdersDialog" width="50vw">
                <v-card class="pa-4" :loading="loading.envoyOnlyOrders">
                    <v-card-title class="d-flex justify-center align-center">
                        Envoy Only Orders
                    </v-card-title>
                    <v-text-field  
                        v-model="envoyOnlyOrderAdd"
                        label="Add Ignored Order"
                        hide-details
                        append-icon="mdi-plus"
                        @click:disabled="envoyOnlyOrderAdd === ''"
                        @click:append="addEnvoyOnlyOrder()"/>
                    <v-card-text class="d-flex justify-center align-center">
                        <v-list>
                            <v-list-item v-for="(order, index) in envoyOnlyOrders" :key="index">
                                <v-list-item-title>
                                    {{ order.Order_Reference }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
    
</template>

<script>
export default {
    async beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - Envoy Visual Progress Lighthouse'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Envoy Visual Progress Lighthouse')
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.updateSpecificOrder = true
            await this.updateOrder(this.$route.query.id)
            await this.getAllUpdateTimes()
        } else if (this.$route.query.ignoreRef) {
            this.envoyOnlyOrdersDialog = true
            this.envoyOnlyOrderAdd = this.$route.query.ignoreRef
            await this.getEnvoyOnlyOrders()
            await this.addEnvoyOnlyOrder() 
        } else {
            this.loading.master = true
            await this.getAllUpdateTimes()
            this.loading.master = false
        } 
    },
    watch: {
        branch: function() {
            if (this.branch.length) {
                this.branchDialog = true
                this.selectedInstanceBranches = []
                if (this.branch === 'Matador Global') {
                    for (let i = 0; i < this.matadorGlobalBranches.length; i++) {
                        this.selectedInstanceBranches.push(this.branches.indexOf(this.matadorGlobalBranches[i]))
                    }
                } else if (this.branch === 'Matador') {
                    for (let i = 0; i < this.matadorBranches.length; i++) {
                        this.selectedInstanceBranches.push(this.branches.indexOf(this.matadorBranches[i]))
                    }
                }
            }
        },
        branchDialog: function() {
            if (!this.branchDialog) {
                this.branch = ''
                this.selectedInstanceBranches = []
            }
        }
    },
    data() {
        return {
            customer: '',
            loading: {
                orderUpdates: false,
                distributorOrderUpdates: false,
                specificOrder: false,
                specificDistributorOrder: false,
                envoyOrders: false,
                distributorEnvoyOrders: false,
                orderStatus: false,
                distributorOrderStatus: false,
                products: false,
                stock: false,
                prices: false,
                customer: false,
                specificCustomer: false,
                databaseProducts: false,
                sandboxCustomerUpdate: false,
                sandboxDistributorCustomerUpdate: false,
                master: false,
                branch: false,
                historicalOrders: false,
                retryHistoricalOrder1: false,
                retryHistoricalOrder2: false,
                getSwapOrder: false,
                swapOrder: false,
                envoyOnlyOrders: false,
                forecastPreorders: false,
                forecastHoldingToLines: false,
            },
            id: '',
            envoyOnlyOrderAdd: '',
            envoyOnlyOrders: [],
            distributorId: '',
            times: [],
            branches: [],
            branch: '',
            selectedInstanceBranches: [],
            matadorBranches: [],
            matadorGlobalBranches: [],
            updated: [],
            dialog: false,
            branchDialog: false,
            errorDialog: false,
            updateSpecificOrder: false,
            updateSpecificCustomer: false,
            updateComplete: false,
            updateError: false,
            historicalOrdersDialog: false,
            swapOrdersDialog: false,
            historicalOrderSearch: '',
            allHistoricalOrders: [],
            selectedHistoricalOrderForSwap: '',
            currentCustomerIdForSwap: 'Select Order to View Customer Id',
            newCustomerIdForSwap: '',
            allCustomers: [],
            showSwapOrderMessage: false,
            historicalOrders: [],
            historicalOrderSearch2: '',
            historicalOrders2: [],
            errorMessage: '',
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getAllUpdateTimes() {
            try {
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_GetLastUpdatedTimes?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/updatedtime`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    branches,
                    times
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.branches = branches
                this.times = times
                this.matadorBranches = this.branches.filter(item => item.envoyInstance === "Matador")
                this.matadorGlobalBranches = this.branches.filter(item => item.envoyInstance === "Matador Global")

            } catch (error) {
                this.errorMessage = JSON.stringify(error)
            }
        },
        async getHistoricalOrders() {
            try {
                this.loading.historicalOrders = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/VPL_GetHistoricalOrderExports?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/historicalOrders?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    fileNames
                } = await result.json()

                if(!result.ok) {
                    throw message
                }
                this.allHistoricalOrders = fileNames
                let copy = JSON.parse(JSON.stringify(fileNames))
                this.historicalOrders = fileNames.reverse().filter(item => !item.includes('MTD2'))
                this.historicalOrders2 = copy.reverse().filter(item => item.includes('MTD2'))
                this.loading.historicalOrders = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading.historicalOrders = false
                this.snack(error,'error',3000)
            }
        },
        async getOrderSwapInfo(order) {
            try {
                this.loading.getSwapOrder = true
                this.currentCustomerIdForSwap = 'Select Order to View Customer Id'
                this.newCustomerIdForSwap = ''
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/swap-order')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                url.searchParams.append('order', order)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_GetOrderInfoForSwappingCustomers?tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    memberId,
                    allContacts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                if (memberId) {
                    this.currentCustomerIdForSwap = memberId
                } else {
                    this.currentCustomerIdForSwap = 'Error Getting Customer Id'
                }
                
                this.allCustomers = allContacts

                this.loading.getSwapOrder = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading.getSwapOrder = false
                this.snack(error,'error',3000)
            }
        },
        async swapOrder() {
            try {
                this.loading.swapOrder = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/swap-order')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_SwapCustomerOnOrder?tab=code
                    url,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            csv: this.selectedHistoricalOrderForSwap,
                            fromMemberId: this.currentCustomerIdForSwap,
                            toMemberId: this.newCustomerIdForSwap
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.showSwapOrderMessage = true
                this.loading.swapOrder = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading.swapOrder = false
                this.snack(error,'error',3000)
            }
        },
        closeSwapOrderDialog() {
            this.newCustomerIdForSwap = ''
            this.selectedHistoricalOrderForSwap = ''
            this.currentCustomerIdForSwap = 'Select Order to View Customer Id'
            this.showSwapOrderMessage = false
            this.swapOrdersDialog = false
        },
        async retryHistoricalOrder(order, loading) {
            try {
                this.loading[loading] = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/VPL_RetryHistoricalOrder?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/historicalOrders?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            csv: order
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading[loading] = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading[loading] = false
                this.snack(error,'error',3000)
            }
        },
        formatOrder(order) {
            let suffix = order.split('EnvoyB2B_Order_')[1]
            return suffix.split('.csv')[0]
        },
        isBranchDisabled(branch) {
            if (this.branch === 'Matador') {
                if (this.matadorGlobalBranches.includes(branch)) {
                    return true
                }
                return false
            } else if (this.branch === 'Matador Global') {
                if (this.matadorBranches.includes(branch)) {
                    return true
                }
                return false
            }
        },
        async updateBranch(branch) {
            try {
                this.loading.branch = true
                let instance = null
                if (!this.matadorBranches.includes(branch) && !this.matadorGlobalBranches.includes(branch)) {
                    instance = this.branch
                } 

                const result = await fetch(
                    //Lambda Function: 
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/branches?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            branchId: branch.id,
                            instance: instance
                        })
                    }
                )
                const {
                    message,
                    branches
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.branches = branches
                this.matadorBranches = this.branches.filter(item => item.envoyInstance === "Matador")
                this.matadorGlobalBranches = this.branches.filter(item => item.envoyInstance === "Matador Global")
                this.loading.branch = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading.branch = false
                this.snack(error,'error',3000)
            }
        },
        async updateOrder(id) {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_UpdateOrders?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/update-envoy?id=${id}`,
                'specificOrder'
            )
        },
        async updateDistributorOrder(id) {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy2_UpdateOrders?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy2/updateorders?id=${id}`,
                'specificDistributorOrder'
            )
        },
        async pullAllCin7Updates() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_UpdateOrders?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/updateorders`,
                'orderUpdates'
            )
        },
        async pullAllCin7UpdatesToDistributor() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy2_UpdateOrders?tab=code
            await this.apiCall(
                'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy2/updateorders',
                'distributorOrderUpdates'
            )
        },
        async pullAllDistributorEnvoyOrders() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy2_ExportOrders?tab=configure
            await this.apiCall(
                'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy2/exportorders',
                'distributorEnvoyOrders'
            )
        },
        async pushDistributorEnvoyStatus() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy2_OrderStatus?tab=configure
            await this.apiCall(
                'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy2/orderstatus',
                'distributorOrderStatus'
            )
        },
        async sandboxDistributorCustomerUpdate() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy2_SandboxAccountUpdate?tab=code
            await this.apiCall(
                'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy2/sandboxaccountupdate',
                'sandboxDistributorCustomerUpdate'
            )
        },
        async pullAllEnvoyOrders() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_ExportOrders?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/exportorders`,
                'envoyOrders'
            )
        },
        async pushEnvoyStatus() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_OrderStatus?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/orderstatus`,
                'orderStatus'
            )
        },
        async getEnvoyOnlyOrders() {
            try {
                this.envoyOnlyOrdersDialog = true
                this.loading.envoyOnlyOrders = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/cin7-only-orders')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_envoyOnlyOrders?newFunction=true&tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    orders
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.envoyOnlyOrders = orders
                this.loading.envoyOnlyOrders = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading.envoyOnlyOrders = false
                this.snack(error,'error',3000)
            }
        },
        async addEnvoyOnlyOrder() {
            try {
                if (this.envoyOnlyOrders.indexOf(this.envoyOnlyOrderAdd) > -1) {
                    this.snack('Order already ignored','info',3000)
                    return
                }
                this.loading.envoyOnlyOrders = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/cin7-only-orders')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                url.searchParams.append('order', this.envoyOnlyOrderAdd)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_envoyOnlyOrders?newFunction=true&tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    orders
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.envoyOnlyOrders = orders
                this.envoyOnlyOrderAdd = ''

                this.loading.envoyOnlyOrders = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading.envoyOnlyOrders = false
                this.snack(error,'error',3000)
            }
        },
        async refreshProducts() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/SFTP_Test?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/products`,
                'products'
            )
        },
        async refreshStock() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_UploadStock?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/stock`,
                'stock'
            )
        },
        async refreshPrices() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_UploadPrices?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/price`,
                'prices'
            )
        },
        async refreshCustomers() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Cin7_UpdateAccounts?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/updateaccount`,
                'customer'
            )
        },
        async updateSpecificCustomerId() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_UpdateAccount?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/updateaccount`,
                'specificCustomer',
                {ids: [this.customer]}
            )
        },
        async sandboxCustomerUpdate() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_SandboxAccountUpdate?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/sandboxaccountupdate`,
                'sandboxCustomerUpdate'
            )
        },
        async refreshDatabaseProducts() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/MySQL_Products?tab=code
            await this.apiCall(
                `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/updatedatabaseproducts`,
                'databaseProducts'
            )
        },
        async apiCall(url, loading, body) {
            let options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            if (body) {
                options = {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            }
            try {
                this.loading[loading] = true
                const result = await fetch(
                    url,
                    options
                )
                const {
                    message,
                    updated
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.updated = updated
                this.dialog = true
                this.loading[loading] = false
                await this.getAllUpdateTimes()
            } catch (error) {
                this.errorDialog = true
                this.errorMessage = JSON.stringify(error)
                if (this.errorMessage.includes('Unable to acquire a connection at Client_MySQL')) {
                    this.errorMessage += '\n - This is a known issue and seems to only be fixed by waiting about a half hour and trying again.'
                }
                this.loading[loading] = false
            }
        },
        async removeForecastPreorders() {
            try {

                this.loading.forecastPreorders = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/forecast-preorders')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_DeletePreorders?tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.loading.forecastPreorders = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading.forecastPreorders = false
                this.snack(error,'error',3000)
            }
        },
        async forecastHoldingToLines() {
            try {

                this.loading.forecastHoldingToLines = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/holding-to-lines')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_HoldingToLines?tab=code
                    url,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.loading.forecastHoldingToLines = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading.forecastHoldingToLines = false
                this.snack(error,'error',3000)
            }
        },
        formatTime(time) {
            let mstDate = new Date(time);
            mstDate.setHours(mstDate.getHours() + 4)
            return mstDate.toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        customerName() {
            if (this.currentCustomerIdForSwap === 'Select Order to View Customer Id') {
                return ''
            }
            let customer = this.allCustomers.find(item => item.Id === Number(this.currentCustomerIdForSwap))
            if (customer) {
                return customer.Company
            }
            return 'No Customer Found'
        },
        filteredHistoricalOrders() {
            if (this.historicalOrderSearch.length) {
                return this.historicalOrders.filter(item => item.toLowerCase().includes(this.historicalOrderSearch.toLowerCase()))
            }
            return this.historicalOrders
        },
        filteredHistoricalOrders2() {
            if (this.historicalOrderSearch2.length) {
                return this.historicalOrders2.filter(item => item.toLowerCase().includes(this.historicalOrderSearch2.toLowerCase()))
            }
            return this.historicalOrders2
        },
        updatedHeaders() {
            if (this.updated.length) {
                return Object.keys(this.updated[0])
            } 
            return []
        },
        orderUpdateTime() {
            let time = this.times.find(item => item.Type === 'Update Envoy Order From Cin7')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        distributorOrderUpdateTime() {
            let time = this.times.find(item => item.Type === 'Update Envoy Distributor Order From Cin7')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        pushOrderTime() {
            let time = this.times.find(item => item.Type === 'Pull Envoy Orders To Cin7')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        pushDistributorOrderTime() {
            let time = this.times.find(item => item.Type === 'Update Envoy Distributor Order From Cin7')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        orderStatusTime() {
            let time = this.times.find(item => item.Type === 'Envoy Status')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        distributorOrderStatusTime() {
            let time = this.times.find(item => item.Type === 'Envoy Distributor Status')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        productTime() {
            let time = this.times.find(item => item.Type === 'Products')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        priceTime() {
            let time = this.times.find(item => item.Type === 'Prices')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        stockTime() {
            let time = this.times.find(item => item.Type === 'Stock')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        customerCreateTime() {
            let time = this.times.find(item => item.Type === 'Create Customers')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        customerUpdateTime() {
            let time = this.times.find(item => item.Type === 'Update Customer')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        databaseProductsTime() {
            let time = this.times.find(item => item.Type === 'Database Products')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        sandboxCustomerUpdateTime() {
            let time = this.times.find(item => item.Type === 'Sandbox Customer Update')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        },
        sandboxDistributorCustomerUpdateTime() {
            let time = this.times.find(item => item.Type === 'Sandbox Distributor Customer Update')
            if (time) {
                return this.formatTime(time.Timestamp)
            }
            return ""
        }
    }
}
</script>

<style>

</style>