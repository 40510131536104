<template>
  <v-dialog
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="px-4 mx-2"
        width="90%"
        color="primary"
        dark
        v-bind="attrs"
        v-on="on">
        <v-icon>mdi-shape</v-icon>Category - {{ selectedCategory.Forecast_Name }}
      </v-btn>
    </template>
      <v-card>
        <v-card-title>Forecast Category</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            @input="filterForecastCategories()"
            label="Search"
            class="mx-4"
          ></v-text-field>
            <v-chip-group
                v-model="categoryIndex"
                column
            >
                <v-chip
                    v-for="(category) in filteredForecastCategories"
                    :key="category['Forecast_Name']"
                    active-class="accent"
                    @click="changeCategory(category)"
                >
                  {{ category['Forecast_Name'] }}
                </v-chip>
            </v-chip-group>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
const fetch = require('node-fetch');

export default {
  name: 'ForecastCategory',
  delimiters: ['${', '}'],
  beforeMount() {
    this.getForecastCategories();
    if (localStorage.category) {
      this.selectedCategory = JSON.parse(localStorage.category)
    } else {
      this.selectedCategory = {
        Forecast_Name: 'None Selected',
        Active: 0,
        Preorder: 1
      }
    }
    this.$emit('input', this.selectedCategory);
  },
  data: () => ({
    dialog: false,
    search: '',
    categoryIndex: '',
    selectedCategory: {
      Forecast_Name: 'None Selected',
      Active: 1,
      Preorder: 0,
    },
    forecastCategories: [],
    filteredForecastCategories: [],
  }),
  watch: {
    categoryIndex: function() {
      if (this.categoryIndex) {
        this.dialog = false
      }
    },
  },
  methods: {
    getForecastCategories: function() {
      const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/categories';
      const request = {
        method: 'GET',
        Headers: {
          'Content-Type': 'application/json',
        },
      };
      fetch(url, request).then((response) => {
        return response.json();
      }).then((categories) => {
        for (let category = 0; category < categories.length; category++) {
          this.forecastCategories.push(categories[category]);
        }
        this.filteredForecastCategories = this.forecastCategories.filter(item => item.Active == 1)
      });
    },
    changeCategory: function(category) {
      this.dialog = false
      this.selectedCategory = category
      localStorage.category = JSON.stringify(category)
      this.$store.dispatch('updatePreorderDiscount',0)
      this.$emit('input', category);
    },
    filterForecastCategories: function() {
      this.filteredForecastCategories = this.forecastCategories.filter(item => 
        item.Forecast_Name.toLowerCase().includes(this.search.toLowerCase()) &&
        item.Active === 1
      )
    }
  },
};
</script>
