var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-row',{staticClass:"pt-8"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{attrs:{"items":_vm.assignedProducts,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._v(" Assigned ")]},proxy:true},{key:"item.Parent",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-img',{attrs:{"max-height":"50px","max-width":"50px","src":item.Image}}),_c('v-text-field',{attrs:{"hint":item.Parent_Id,"hide-details":false,"label":"Shown Product Name"},model:{value:(item.Parent_Name),callback:function ($$v) {_vm.$set(item, "Parent_Name", $$v)},expression:"item.Parent_Name"}}),_c('v-btn',[_vm._v(" Refresh Pictures ")])],1)]}},{key:"item.Child",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',_vm._l((item.Children),function(children,index){return _c('v-chip',{key:index},[_vm._v(" "+_vm._s(children.Child_Id)+" ")])}),1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{attrs:{"items":_vm.unassignedProducts,"headers":_vm.unassignedProductsHeaders},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._v(" Unassigned ")]},proxy:true},{key:"item.Image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.Image,"max-width":"50px","max-height":"50px"}})]}},{key:"item.Action",fn:function(child){return [_c('v-btn',{attrs:{"chip":""},on:{"click":function($event){return _vm.assign(child.item.sku, child.item.sku)}}},[_vm._v(" Make Parent ")]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","chip":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Make Child ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-4"},[_c('v-autocomplete',{attrs:{"items":_vm.allParents,"label":"Avaiable Parents"},on:{"input":function($event){_vm.assign(_vm.selectedParent, child.item.sku); _vm.selectedParent = ''}},model:{value:(_vm.selectedParent),callback:function ($$v) {_vm.selectedParent=$$v},expression:"selectedParent"}})],1)],1)]}}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackColor},model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.childCard),callback:function ($$v) {_vm.childCard=$$v},expression:"childCard"}},[_c('v-card',{staticClass:"pa-4"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }