<template>
  <v-card class="mx-auto px-auto mt-16" height="100%" width="20vw">
    <v-card-title class="justify-center">Review Dates</v-card-title>
      <v-tabs
        color="primary"
        left>
        <v-tab>My Dates</v-tab>
        <v-tab v-if="allowViewOfAllEmployeesTab">All Employees</v-tab>
        <v-tab v-if="allowViewOfHolidays">Holidays</v-tab>
        <v-tab-item>
          <v-list>
            <!-- <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Company Average</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>{{ averageHours }} Hours</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>My Total</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>{{ userHours }} Hours</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <personal-dates :year="year" :events="events"/>
        </v-tab-item>
        <v-tab-item>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Company Average</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title> {{ averageHours }} Hours</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(employees, index) in employeeTotals" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ employees.Username }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>{{ employees.Hours }} Hours</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <holiday-input :events="events"/>
        </v-tab-item>
      </v-tabs>
  </v-card>
</template>

<script>
import HolidayInput from './holidayInput.vue'
import PersonalDates from './personalDates.vue'

export default {
  components: {
    PersonalDates,
    HolidayInput,
  },
  props: {
    events: {type: Array},
    year: {type: Number}
  },
  watch: {
    year: function() {
      this.computeEmployeeTotals()
    },
    events: function() {
      this.computeEmployeeTotals()
    }
  },
  data() {
        return {
            employeeTotals: {},
            averageHours: 0,
            userHours: 0,
            permissions: this.$store.getters.permissions
        }
    },
    methods: {
      computeEmployeeTotals() {
        this.employeeTotals = {}
        let filteredEvents = this.$props.events.filter(item => {
          let d = new Date(item.Start_Date)
          let startYear = d.getFullYear()
          return (startYear === this.$props.year && item.Active === 1)
        })

        for (let i = 0; i < filteredEvents.length; i++) {
          const currentEvent = filteredEvents[i] 
          if (Object.prototype.hasOwnProperty.call(this.employeeTotals, currentEvent.UserId)) {
            this.employeeTotals[currentEvent.UserId].Hours += currentEvent.Hours
          } else {
            this.employeeTotals[currentEvent.UserId] = {
              'Username': currentEvent.Username,
              'Hours': currentEvent.Hours
            }
          }
        }

        let total = 0
        for (let totals in this.employeeTotals) {
          total += this.employeeTotals[totals].Hours
        }

        let average = total / Object.keys(this.employeeTotals).length

        this.averageHours = Math.round(average * 100) / 100

        let userId = this.$store.getters.getUserId
        if (Object.prototype.hasOwnProperty.call(this.employeeTotals, userId)) {
          this.userHours = this.employeeTotals[userId].Hours
        } else {
          this.userHours = 0
        }
      }
    },
    computed: {
      allowViewOfAllEmployeesTab() {
        if (this.$store.getters.getPermissions.includes('PTO_Admin')) {
          return true
        } else {
          return false
        }
      },
      allowViewOfHolidays() {
        if (this.$store.getters.getPermissions.includes('Admin')) {
          return true
        } else {
          return false
        }
      }
    }
  
}
</script>

<style>

</style>