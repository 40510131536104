<template>
    <v-app>
        <v-main class="d-flex justify-space-between align-center">
            <v-row justify="center">
                <v-card :loading="loading" width="50vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Order Numbers to Fix
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-cetner align-center">
                        For multiple orders have each order on a new line
                    </v-card-subtitle>
                    <v-textarea class="px-4" v-model="orderNumbers" label="Order Numbers"/>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn 
                            color="primary"
                            @click="getOrders()"
                            :disabled="orderNumbersPresent">
                            Get Orders    
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-row> 
            <v-dialog
                width="75vw"
                v-model="dialog" 
                transition="dialog-bottom-transition" 
                class="d-flex justify-center align-center">
                <v-card>
                    <v-card-title class="d-flex justify-center align-center">
                        Orders To Fix Overview
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center align-center">
                        Review and correct item prices if needed
                    </v-card-subtitle>
                    <v-expansion-panels v-model="panels" multiple>
                        <v-expansion-panel v-for="(order,index) in formattedOrders" :key="index">
                            <v-expansion-panel-header>
                                {{ order.reference }}
                                <v-icon v-if="order.missingPrice" color="red">mdi-alert-circle</v-icon>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table v-if="order.message !== 'Not Found'">
                                    <thead>
                                        <tr>
                                            <th>
                                                SKU
                                            </th>
                                            <th>
                                                Qty
                                            </th>
                                            <th>
                                                Price (MSRP)
                                            </th>
                                            <th>
                                                New Price (Cost of Item)
                                            </th>
                                            <th>
                                                Line Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(line, index1) in order.lines" :key="index1"  :class="line.color">
                                            <td>
                                                {{ line.SKU }}
                                            </td>
                                            <td>
                                                {{ line.qty }}
                                            </td>
                                            <td>
                                                ${{ line.oldPrice }}
                                            </td>
                                            <td>
                                                <v-text-field
                                                    prefix="$"
                                                    @input="updateMissingBooleans(order), formatText(order, line)"
                                                    v-model="line.newPrice"/>
                                            </td>
                                            <td>
                                                ${{ line.qty * line.newPrice}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                                <span v-else>
                                    <div>
                                        Order was not found in Database. Input the order Id from Cin7 and press the button below to attempt to get the order from Cin7.
                                    </div>
                                    <v-text-field
                                        v-model="getId"/>
                                    <v-btn
                                        @click="putOrderInDatabase()"
                                        :disabled="!getId.length"
                                        :loading="loading"
                                        color="primary">
                                        Pull From Cin7
                                    </v-btn>
                                </span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn color="secondary" @click="dialog = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="sendUpdates()"
                            :disabled="notReadyForSendUpdates()"
                            :loading="loading">
                            Update
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="updateResponse.dialog"
                width="75vw"
                class="d-flex justify-center align-center">
                <v-card>
                    <v-card-title class="d-flex justify-center align-center">
                        Update Status
                    </v-card-title>
                    <v-row justify="center">
                        <v-col v-if="updateResponse.success.length">
                            <v-card-subtitle class="d-flex justify-center align-center">
                                Success
                            </v-card-subtitle>
                            <v-row v-for="(ref, index) in updateResponse.success" 
                                :key="index" 
                                justify="center">
                                <a :href="'https://ship9.shipstation.com/orders/all-orders-search-result?quickSearch=' + encodeURIComponent(ref)"
                                    target="_blank"
                                    class="green--text">
                                    {{ ref }}
                                </a>
                            </v-row>
                        </v-col>
                        <v-divider class="py-4" vertical v-if="updateResponse.success.length && updateResponse.unsure.length"/>
                        <v-col v-if="updateResponse.unsure.length">
                            <v-card-subtitle class="d-flex justify-center align-center">
                                Unsure
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            class="pl-2"
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on">
                                            mdi-information
                                        </v-icon>
                                    </template>
                                    <span>
                                        The order update output did not match the input.<br>
                                        Please confirm the order has the expected values.
                                    </span>
                                </v-tooltip>
                            </v-card-subtitle>
                            <v-row v-for="(ref, index) in updateResponse.unsure" 
                                :key="index" 
                                justify="center">
                                <a :href="'https://ship9.shipstation.com/orders/all-orders-search-result?quickSearch=' + encodeURIComponent(ref)"
                                    class="orange--text"
                                    target="_blank">
                                    {{ ref }}
                                </a>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-card-actions class="d-flex justify-center align-center pt-4">
                        <v-btn
                            @click="updateResponse.dialog = false"
                            color="primary">
                            Okay    
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>

    export default {
        beforeMount() {
            this.$store.dispatch('updateShowWaffle', true)
            this.$store.dispatch('updateNavbarContent', [
                {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
            ])
            this.$store.dispatch('updateNavbarTitle','SS Customs Updater')
            document.title = 'Customs Updater'
        },
        data() {
            return {
                dialog: false,
                panels: [],
                orderNumbers: '',
                getId: '',
                orders: [],
                updateResponse: {
                    success: [],
                    unsure: [],
                    dialog: false
                },
                ordersFixed: [],
                itemCosts: [],
                loading: false,
                snackBar: false,
                snackText: '',
                snackColor: 'success',
                timeout: 3000
            }
        },
        methods: {
            async getOrders() {
                try {
                    this.loading = true
                    const result = await fetch(
                        //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_GetCustomsFormOrders?newFunction=true&tab=code
                        'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/customsform/getorders',
                        {
                            method: 'POST',
                            Headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                orders: this.splitOrders
                            })
                        }
                    )
                
                    const {
                        message,
                        orders,
                        itemCosts
                    } = await result.json()
                    
                    this.itemCosts = itemCosts
                    this.orders = orders
                    let unique = [... new Set(this.orders.map(item => item.Reference))]
                    for (let i = 0; i < unique.length; i++) {
                        this.panels.push(i)
                    }
                    this.dialog = true
                    this.loading = false
                    this.snack(message,'success',3000)
                } catch (error) {
                    this.loading = false
                    this.snack(error,'error',3000)
                }
            },
            async sendUpdates() {
                try {
                    this.loading = true
                    const result = await fetch(
                        //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_FixShipStationCustomsForm?tab=code
                        'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/customsform',
                        {
                            method: 'POST',
                            Headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(this.formattedOrders)
                        }
                    )
                
                    const {
                        message,
                        success,
                        unsure
                    } = await result.json()

                    this.loading = false
                    this.updateResponse.success = success
                    this.updateResponse.unsure = unsure
                    this.dialog = false
                    this.updateResponse.dialog = true
                    this.orderNumbers = ''
                    this.snack(message,'success',3000)
                } catch (error) {
                    console.log(error)
                    this.loading = false
                    this.updateResponse.success = []
                    this.updateResponse.unsure = []
                    this.snack(error,'error',3000)
                }
            },
            async putOrderInDatabase() {
                try {
                    this.loading = true
                    const result = await fetch(
                        //Lambda Function: 
                        'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/updateorders',
                        {
                            method: 'POST',
                            Headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                url: "https://api.cin7.com/api/v1/SalesOrders?where=id IN (" + this.getId + ")&rows=250&page="
                            })
                        }
                    )
                
                    const {
                        message,
                    } = await result.json()

                    this.getOrders()
                    this.loading = false
                    this.snack(message,'success',3000)
                } catch (error) {
                    this.loading = false
                    this.snack(error,'error',3000)
                }
            }, 
            async envoypush() {
                try {
                    this.loading = true
                    const result = await fetch(
                        //Lambda Function: 
                        '',
                        {
                            method: 'GET',
                            Headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    )
                    const {
                        message,
                    } = await result.json()
                
                    if(!result.ok) {
                        throw message
                    }
                
                    this.loading = false
                    this.snack(message,'success',3000)
                } catch (error) {
                    this.loading = false
                    this.snack(error,'error',3000)
                }
            },
            updateMissingBooleans(order) {
                this.panels.push(101)
                let missing = false
                for(let i = 0; i < order.lines.length; i++) {
                    if(isNaN(Number(order.lines[i].oldPrice))) {
                        missing = true
                        order.lines[i].color = 'red--text'
                    } else {
                        missing = false
                        order.lines[i].color = 'black--text'
                    }
                }
                order.missingPrice = missing
                this.panels.splice(this.panels.length-1,1)
            },
            formatText(order, line) {
                if(isNaN(Number(line.newPrice))) {
                    order.missingPrice = true
                    line.color = 'red--text'
                } else {
                    line.color = 'black--text'
                }
            },
            notReadyForSendUpdates() {
                let missingPrices = [... new Set(this.formattedOrders.map(item => item.missingPrice))]
                if (missingPrices.indexOf(true) !== -1) {
                    return true
                } else {
                    return false
                }
            },
            snack(text,color,timeout) {
                this.snackBar = true
                this.snackText = text
                this.snackColor = color
                this.timeout = timeout
            },
        },
        computed: {
            orderNumbersPresent() {
                if (this.orderNumbers.length) {
                    return false
                } else {
                    return true
                }
            },
            splitOrders() {
                if (this.orderNumbers.length) {
                    return this.orderNumbers.split("\n")
                } else {
                    return []
                }
            },
            formattedOrders() {
                let formattedOrders = []
                let orders = this.splitOrders
                if (orders.length) {
                    for (let i = 0; i < orders.length; i++) {
                        let foundOrders = this.orders.filter(item => item.Reference === orders[i])
                        if(foundOrders.length > 0) {
                            
                            for (let r = 0; r < foundOrders.length; r++) {
                                let existingOrder = formattedOrders.find(item => item.reference === foundOrders[r].Reference)
                                let newPrice = this.itemCosts.find(item => item.SKU === foundOrders[r].SKU)
                                let lineColor = ''
                                if (newPrice) {
                                    newPrice = newPrice.Cost
                                    lineColor = 'black--text'
                                } else {
                                    newPrice = 'No Price Found'
                                    lineColor = 'red--text'
                                }
                                if (existingOrder) {
                                    if (newPrice === 'No Price Found') {
                                        existingOrder.missingPrice = true
                                    }
                                    existingOrder.message = "Found"
                                    existingOrder.lines.push({
                                        SKU: foundOrders[r].SKU,
                                        oldPrice: foundOrders[r].Price,
                                        newPrice: newPrice,
                                        color: lineColor,
                                        qty:foundOrders[r].Qty
                                    })
                                } else {
                                    let missingPrice = false
                                    if (newPrice === 'No Price Found') {
                                        missingPrice = true
                                    } else {
                                        missingPrice = false
                                    }
                                    formattedOrders.push({
                                    missingPrice: missingPrice,
                                    message: 'Found',
                                    reference: foundOrders[r].Reference,
                                    lines: [
                                        {
                                            SKU: foundOrders[r].SKU,
                                            oldPrice: foundOrders[r].Price,
                                            newPrice: newPrice,
                                            color: lineColor,
                                            qty: foundOrders[r].Qty
                                        }
                                    ]
                                })
                                }
                            }
                        } else {
                            formattedOrders.push({
                                message: 'Not Found',
                                reference: orders[i],
                                lines: []
                            })
                        }
                    }
                }
                return formattedOrders
                
            }
        },
    }
</script>
