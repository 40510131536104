<template>
    <v-snackbar
        :color="snackbar.color"
        v-model="snackbar.show"
        :timeout="snackbar.timeout">
        <template v-if="snackbar.complex">
            <v-row>
                <v-btn icon @click="turnOffPriceWarning()" color="white">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on">
                                mdi-bell-off
                            </v-icon>
                        </template>
                        <span>
                            Turn off Price Warning
                        </span>
                    </v-tooltip>
                </v-btn>
                <v-col>
                    <v-card-title class="d-flex justify-center align-center">
                        {{ monthName }} - {{ productInfo.name }}
                    </v-card-title>
                </v-col>
                <v-btn icon @click="emit('close')" color="white">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-card flat class="snackCard" color="transparent">
                <v-row no-gutters>
                    <template v-if="pricesDifferent">
                        <v-col cols="5">
                            <v-card-title class="d-flex justify-center align-center">Listed Price</v-card-title>
                            <v-card-subtitle class="text-center">{{ productInfo.listedPrice }}</v-card-subtitle>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-center align-center">
                            <v-icon color="white">mdi-not-equal-variant</v-icon>
                        </v-col>
                        <v-col cols="5">
                            <v-card-title class="d-flex justify-center align-center">Recorded Price</v-card-title>
                            <v-card-subtitle class="text-center">{{ productInfo.price }}</v-card-subtitle>
                        </v-col>
                    </template>
                </v-row>
                <v-divider v-if="costsDifferent && pricesDifferent"/>
                <v-row no-gutters>
                    <template v-if="costsDifferent" >
                        <v-col cols="5">
                            <v-card-title class="d-flex justify-center align-center">Listed Cost</v-card-title>
                            <v-card-subtitle class="text-center">{{ productInfo.listedCost }}</v-card-subtitle>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-center align-center">
                            <v-icon color="white">mdi-not-equal-variant</v-icon>
                        </v-col>
                        <v-col cols="5">
                            <v-card-title class="d-flex justify-center align-center">Recorded Cost</v-card-title>
                            <v-card-subtitle class="text-center">{{ productInfo.cost }}</v-card-subtitle>
                        </v-col>
                    </template>
                </v-row>
            </v-card>
        </template>
        <span v-else>
            {{ snackbar.message }}
        </span>
    </v-snackbar>
</template>

<script>
export default {
    name: 'snackWarning',
    props: {
        productInfo: {
            type: Object,
            required: false
        },
        monthName: {
            type: String,
            required: false
        },
        snackbar: {
            type: Object,
            required: true
        }
    },
    methods: {
        turnOffPriceWarning() {
            //turn off speedRound in actions vuex
            this.$store.dispatch('updatePriceWarning', false)
            let temp = JSON.parse(localStorage.otherSettings)
            temp.showPriceWarning = false
            localStorage.otherSettings = JSON.stringify(temp)
            this.$emit('close')
        }
    },
    computed: {
        costsDifferent() {
            return this.$props.productInfo.cost !== this.$props.productInfo.listedCost
        },
        pricesDifferent() {
            return this.$props.productInfo.price !== this.$props.productInfo.listedPrice
        }
    }
}
</script>

<style>

</style>