<template>
  <v-main>
      <v-data-table
        :headers="headers"
        :items="amazonProducts"
        :search="search"
        :loading="loading"
        :items-per-page="-1"
        :disable-pagination="true"
        loading-text="loading"
        class="elevation-1 pt-4"
        fixed-header
        height="80vh"
        width="80vw">
        <!-- search field on top -->
			<template v-slot:top>
				<v-text-field
				v-model="search"
				label="Search"
				class="mx-4">
                <template v-slot:append-outer>
                    <v-dialog
                        v-model="checkNewItemDialog"
                        width="20vw">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                icon>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="d-flex justify-center">Enter Matador SKU</v-card-title>
                            <v-card-text class="d-flex justify-center">
                                <v-text-field
                                    hide-details
                                    v-model="matadorSku"/>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-space-around">
                                <v-btn
                                    color="secondary"
                                    @click="checkNewItemDialog = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="confirmSku()"
                                    :loading="loading">
                                    Confirm
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="newItemDialog"
                        width="40vw">
                        <v-card>
                            <v-card-title>{{newItem.Name}} - {{newItem.Option1}}</v-card-title>
                            <v-card-subtitle>{{newItem.SKU }}</v-card-subtitle>
                            <v-card-text>
                                <v-row class="pb-2">
                                    <v-text-field
                                        class="px-2"
                                        hide-details
                                        type="text"
                                        label="ASIN"
                                        v-model="newItem.ASIN"/>
                                    <v-text-field
                                        class="px-2"
                                        hide-details
                                        type="text"
                                        label="Amazon SKU"
                                        v-model="newItem.Amazon_SKU"/>
                                </v-row>
                                <v-row>
                                    <v-text-field
                                        class="px-2"
                                        hide-details
                                        type="number"
                                        prefix="$"
                                        label="Listing Price (Optional)"
                                        v-model="newItem.Listing_Price"/>
                                    <v-text-field
                                        class="px-2"
                                        hide-details
                                        type="number"
                                        prefix="$"
                                        label="Lowest Price (Optional)"
                                        v-model="newItem.Buy_Box"/>
                                    <v-text-field
                                        class="px-2"
                                        hide-details
                                        type="number"
                                        label="# of Sellers (Optional)"
                                        v-model="newItem.Number_of_Buyers"/>
                                    <v-text-field
                                        class="px-2"
                                        hide-details
                                        type="number"
                                        prefix="$"
                                        label="Amazon Fees (Optional)"
                                        v-model="newItem.Amazon_Fees"/>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-space-around">
                                <v-btn
                                    color="secondary"
                                    @click="newItemDialog = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    :loading="loading"
                                    :disabled="addItemButtonDisabled"
                                    @click="addItem()">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="productNotFoundDialog"
                        width="20vw">
                        <v-card>
                            <v-card-title class="d-flex justify-center">SKU Not Found</v-card-title>
                            <v-card-actions class="d-flex justify-space-around">
                                <v-btn
                                    color="secondary"
                                    @click="productNotFoundDialog = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="retry()">
                                    Retry
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </v-text-field>
			</template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="edit(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteDialog(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
      </v-data-table>
      <v-snackbar
			v-model="snackActive"
			:timeout="snackTimeout"
			:color="snackColor"
		>
            {{ snackText}}
			<template v-slot:action="{ attrs }">
				<v-btn
					v-bind="attrs"
					text
					@click="snackActive = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
        <v-dialog
            v-model="editItemDialog"
            width="40vw">
            <v-card>
                <v-card-title>{{editItem.Name}} - {{editItem.Option1}}</v-card-title>
                <v-card-subtitle>{{editItem.SKU }}</v-card-subtitle>
                <v-card-text>
                    <v-row class="pb-2">
                        <v-text-field
                            class="px-2"
                            hide-details
                            label="ASIN"
                            v-model="editItem.ASIN"/>
                        <v-text-field
                            class="px-2"
                            hide-details
                            label="Amazon SKU"
                            v-model="editItem.Amazon_SKU"/>
                    </v-row>
                    <v-row>
                        <v-text-field
                            class="px-2"
                            hide-details
                            prefix="$"
                            type="number"
                            label="Listing Price"
                            v-model="editItem.Listing_Price"/>
                        <v-text-field
                            class="px-2"
                            hide-details
                            prefix="$"
                            type="number"
                            label="Lowest Price"
                            v-model="editItem.Buy_Box"/>
                        <v-text-field
                            class="px-2"
                            hide-details
                            type="number"
                            label="Number of Sellers"
                            v-model="editItem.Number_of_Buyers"/>
                        <v-text-field
                            class="px-2"
                            hide-details
                            prefix="$"
                            type="number"
                            label="Amazon Fees"
                            v-model="editItem.Amazon_Fees"/>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-around">
                    <v-btn
                        color="secondary"
                        @click="editItemDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="updateItem()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="deleteItemDialog"
            width="20vw">
            <v-card>
                <v-card-title class="d-flex justify-center">Are You Sure You Want to Delete {{ itemToDelete.ASIN }}?</v-card-title>
                <v-card-actions class="d-flex justify-space-around pb-4">
                    <v-btn
                        color="secondary"
                        @click="deleteItemDialog = false">
                        No
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="loading"
                        @click="deleteItem()">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  </v-main>
</template>

<script>
export default {
    components: {
    },
    beforeMount() {
        document.title = 'Sandbox - Amazon Fees'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Amazon Fees')
        this.getAllProductDetails();
    },
    data() {
        return {
            deleteItemDialog: false,
            itemToDelete: {},
            editItemDialog: false,
            editItem: {},
            amazonProducts: [],
            snackText: '',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            newItem: {
                ASIN: '',
                Amazon_SKU: ''
            },
            checkNewItemDialog: false,
            newItemDialog: false,
            productNotFoundDialog: false,
            matadorSku: 'MATBE18001BK',
            matadorProductDetails: {},
            search: '',
            loading: false,
        }   
    },
    methods: {
        getAllProductDetails() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazonfees'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }

                this.amazonProducts = data.amazonProducts
                this.loading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
        edit(item) {
            this.editItem = item
            this.editItemDialog = true
        },
        deleteDialog(item){
            this.deleteItemDialog = true
            this.itemToDelete = item
        },
        deleteItem() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazonfees'
            const data = {
                ASIN: this.itemToDelete.ASIN
            }
            const request = {
                method: 'DELETE',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => {
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.loading = false
                this.deleteItemDialog = false
                this.getAllProductDetails()
                this.snack(data.message, 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.deleteItemDialog = false
                this.snack(error, 'error', 3000)
            })
        },
        updateItem() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazonfees'
            const data = {
                editItem: this.editItem
            }
            const request = {
                method: 'PUT',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.loading = false
                this.editItemDialog = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.editItemDialog = false
                this.snack(error, 'error', 3000)
            })
        },
        confirmSku() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazonfees/confirmsku'
            const data = {
                sku: this.matadorSku
            }
            const request = {
                method: 'POST',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.matadorProductDetails = data.product
                this.loading = false
                this.snack('SKU Found', 'success', 3000)
                this.newItem = data.product
                this.matadorSku = ''
                this.checkNewItemDialog = false
                this.newItemDialog = true
            }).catch(error => {
                this.checkNewItemDialog = false
                this.productNotFoundDialog = true
                this.matadorSku = ''
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        retry() {
            this.productNotFoundDialog = false
            this.checkNewItemDialog = true
        },
        addItem() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazonfees'
            const data = {
                matadorSKU: this.newItem.SKU,
                asin: this.newItem.ASIN,
                amazonSKU: this.newItem.Amazon_SKU,
                listingPrice: this.newItem.Listing_Price || 0,
                buyBox: this.newItem.Buy_Box || 0,
                numberOfBuyers: this.newItem.Number_of_Buyers || 0,
                amazonFees: this.newItem.Amazon_Fees || 0,
            }
            const request = {
                method: 'POST',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                
                this.loading = false
                this.newItemDialog = false
                this.newItem = {
                    ASIN: '',
                    Amazon_SKU: ''
                }
                this.getAllProductDetails()
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        }
    },
    computed: {
        addItemButtonDisabled() {
            if (this.newItem.Amazon_SKU && this.newItem.ASIN) {
                return false
            } else {
                return true
            }
        },
        headers() {
            return [
                {
                    text: "Name",
                    value: "Name",
                    align: "start",
                    fixed: true
                },
                {
                    text: "Option 1",
                    value: "Option1",
                    align: "start",
                    fixed: true
                },
                {
                    text: "SKU",
                    value: "SKU",
                    align: "start",
                    fixed: true
                },
                {
                    text: "ASIN",
                    value: "ASIN",
                    align: "start",
                    fixed: true
                },
                {
                    text: "Amazon SKU",
                    value: "Amazon_SKU",
                    align: "start",
                    fixed: true
                },
                {
                    text: "Listing Price",
                    value: "Listing_Price",
                    align: "start",
                    fixed: true
                },
                {
                    text: "Lowest Price",
                    value: "Buy_Box",
                    align: "start",
                    fixed: true
                },
                {
                    text: "Number of Sellers",
                    value: "Number_of_Buyers",
                    align: "start",
                    fixed: true
                },
                {
                    text: "Amazon Fees",
                    value: "Amazon_Fees",
                    align: "start",
                    fixed: true
                },
                {
                    text: "Actions",
                    value: "actions",
                    align: "start",
                    fixed: true
                }
            ]
        }
    }
}
</script>

<style>

</style>