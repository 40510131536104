<template>
    <v-main>
        <v-row class="pt-8" justify="center" align="center">
            <v-card class="pa-4" width="50vw" height="100%">
                <v-card-title class="d-flex justify-center align-center">
                    Insert Payment By Quickbooks Payment Id
                </v-card-title>
                <v-textarea
                    v-model="ids"
                    :placeholder="'123432\n567876\n912321'"/>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn
                        color="primary"
                        :disabled="!ids.length"
                        :loading="loading"
                        @click="fixPayments()">
                        Fix
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-row>
    </v-main>
</template>

<script>
export default {
    async beforeMount(){
        document.title = 'Sandbox - Fix Payments'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Sales Orders', path: '/sales/database/fix/salesorders', icon: 'mdi-currency-usd', notification: false},
            {title: 'Credit Notes', icon: 'mdi-credit-card-sync-outline', path: '/sales/database/fix/creditnotes', notification: false},
            {title: 'Payments', icon: 'mdi-cash-clock', path: '/sales/database/fix/payments', notification: false},
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ])
        this.$store.dispatch('updateNavbarTitle','Fix Database - Payments')
        if (this.$route.query.id) {
            this.ids = this.$route.query.id
            await this.fixPayments()
        }
    },
    data() {
        return {
            ids: '',
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async fixPayments() {
            let ids = [];
            if (this.ids.includes('\n')) {
                ids = this.ids.split('\n')
            } else {
                ids = [this.ids]
            }
            try {
                this.loading = true
                const result = await fetch(
                    //Make Function: https://us1.make.com/107347/scenarios/1262415/edit
                    `https://hook.us1.make.com/6gwx1nyfmto7pu3raue0bx6w0jymgl7i`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "ids": ids
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    }
}
</script>

<style>

</style>