<template>
    <v-col class="px-6 d-flex align-center">
        <div class="d-flex align-center">
            <v-btn
                icon
                color="primary"
                :loading="isLoading"
                @click="refreshAll()">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-menu
                open-on-hover
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        two-line
                        @click="refreshStock()"
                        class="pointer">
                        <v-list-item-content>
                            <v-list-item-title>
                                Refresh Stock
                            </v-list-item-title>
                            <v-list-item-subtitle>Last Refreshed: {{ lastRefreshed.stock }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item 
                        two-line
                        @click="refreshBranchTransfers()"
                        class="pointer">
                        <v-list-item-content>
                            <v-list-item-title>
                                Refresh Branch Transfers
                            </v-list-item-title>
                            <v-list-item-subtitle>Last Refreshed: {{ lastRefreshed.branchTransfers }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        two-line
                        @click="refreshSalesForecast()"
                        class="pointer">
                        <v-list-item-content>
                            <v-list-item-title>
                                Refresh Sales Forecast
                            </v-list-item-title>
                            <v-list-item-subtitle>Last Refreshed: {{ lastRefreshed.salesForecast }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item 
                        two-line
                        @click="refreshETADates(false)"
                        class="pointer d-flex-column">
                        <v-list-item-content>
                            <v-list-item-title>
                                Refresh ETA Dates
                            </v-list-item-title>
                            <v-list-item-subtitle>ETA Last Refreshed: {{ lastRefreshed.etaBot }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <div v-if="counterActive" class="d-flex-column justify-center">
                <v-subheader style="height: 100%;" class="d-flex justify-center">{{ getEtaBotTimer }}</v-subheader>
                <v-subheader style="height: 100%;" class="d-flex justify-center">{{ getEtaBotMessage }}</v-subheader>
            </div>
        </div>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-col>
</template>

<script>
export default {
    beforeMount() {
        if (localStorage.stockMarket_etaBot_timer) {
            this.timer = Number(localStorage.stockMarket_etaBot_timer)
        } else {
            this.timer = 0
        }
        if (localStorage.stockMarket_etaBot_attemptCounter) {
            this.attempts = Number(localStorage.stockMarket_etaBot_attemptCounter)
        } else {
            this.attempts = 0
        }
        if (localStorage.stockMarket_etaBot_result) {
            this.result = localStorage.stockMarket_etaBot_result
        } else {
            this.result = ''
        }
    },
    mounted() {
        if (localStorage.stockMarket_etaBot_lastRefreshed) {
            this.lastRefreshed.etaBot = localStorage.stockMarket_etaBot_lastRefreshed
        } else {
            let d = new Date()
            this.lastRefreshed.etaBot = d.getMonth() + '/' + d.getDate() + '/' + d.getFullYear() + ' 5:00:00'
            localStorage.stockMarket_etaBot_lastRefreshed = this.lastRefreshed.etaBot
        }
        this.timestamp('stock')
        this.timestamp('branchTransfers')
        this.timestamp('salesForecast')
        this.etaBotLastRefreshed()
    },
    watch: {
        timer: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timer--
                        localStorage.stockMarket_etaBot_timer = this.timer
                    }, 1000)
                } else if (value <= 0 && this.result === 'inProgress') {
                    this.refreshETADates(false)
                } else if (value === 0 && this.result === 'complete') {
                    this.attempts = 0
                    this.loading = false
                    this.timestamp('etaBot')
                    this.etaBotLastRefreshed()
                }
            },
            immediate: true
        },
        attempts: function() {
            localStorage.stockMarket_etaBot_attemptCounter = this.attempts
        },
        result: function() {
            localStorage.stockMarket_etaBot_result = this.result
        },
        refreshBranchTransfer: function() {
            this.refreshBranchTransfers()
        }
    },
    props: {
        otherLoading: {type: Boolean},
        refreshBranchTransfer: {type: Number},
    },
    data() {
        return {
            lastRefreshed: {
                stock: 'date',
                branchTransfers: 'date',
                salesForecast: 'date',
                etaBot: 'date'
            },
            timer: 0,
            attempts: 0,
            result: '',
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            ordinalNumbers: ['First','Second','Third','Fourth','Fifth','Sixth','Seventh','Eighth','Ninth','Tenth'],
        }
    },
    methods: {
        refreshAll() {
            this.refreshStock()
            this.refreshBranchTransfers()
            this.refreshSalesForecast()
        },
        refreshStock() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Cin7_GetStock?tab=code
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/stock'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
            setTimeout(() => {
                const url2 = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/stock'
                fetch(url2, request).then(async response => { 
                    const data = await response.json()
                
                    if (!response.ok) {
                        const error = (data && data.message) || response.status
                        return Promise.reject(error)
                    }
                    this.$emit('SOH-Data', data.stock)
                    this.loading = false
                    this.snack('SOH Refreshed', 'success', 3000)
                    this.timestamp('stock')
                }).catch(error => {
                    this.loading = false
                    this.snack(error, 'error', 3000)
                })
            }, 10000)
        },
        refreshBranchTransfers() {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/cin7branchtransfers'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
            setTimeout(() => {
                const url2 = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/branchtransfers'
                this.loading = true
                fetch(url2, request).then(async response => { 
                    const data = await response.json()
                
                    if (!response.ok) {
                        const error = (data && data.message) || response.status
                        return Promise.reject(error)
                    }
                    this.$emit('Branch-Transfer-Data',data.branchTransfers)
                    this.loading = false
                    this.snack('Branch Transfers Refreshed', 'success', 3000)
                    this.timestamp('branchTransfers')
                }).catch(error => {
                    this.loading = false
                    this.snack(error, 'error', 3000)
                })
            }, 10000)
        },
        refreshSalesForecast() {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/forecast'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.$emit('Sales-Forecast-Data', data.forecastData) 
                this.loading = false
                this.snack('Sales Forecast Refreshed', 'success', 3000)
                this.timestamp('salesForecast')
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        refreshETADates(id) {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/eta'
            const data = {
                id: id
            }
            const request = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.result = data.result
            }).catch(error => {
                this.snack(error, 'error', 3000)
            })
        },
        etaBotLastRefreshed() {
            let lastRefresh = Date.parse(this.lastRefreshed.etaBot)
            let d = new Date()
            let day = d.getDate()
            let month = d.getMonth()
            let year = d.getFullYear()

            if (lastRefresh < d) {
                this.lastRefreshed.etaBot =  month + '/' + day + '/' + year + ' 5:00:00'
                localStorage.stockMarket_etaBot_lastRefreshed = this.lastRefreshed.etaBot
            } 
        },
        timestamp(object) {
            let d = new Date()
            let seconds = "0" + d.getSeconds()
            let minutes = "0" + d.getMinutes()
            let hours = d.getHours()
            let day = d.getDate()
            let month = d.getMonth()
            let year = d.getFullYear()
            this.lastRefreshed[object] = month + '/' + day + '/' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
        },
        snack(text, color, timeout) {
            this.snackText = text
            this.snackColor = color
            this.snackTimeout = timeout
            this.snackActive = true
        },
    },
    computed: {
        counterActive() {
            if (this.timer > 0) {
                return true
            } else {
                return false
            }
        },
        getEtaBotTimer() {
            let minute = Math.floor(this.timer / 60)
            let second = this.timer - (minute * 60)
            if (second < 10) {
                second = "0" + second
            }
            return minute + ":" + second
        },
        getEtaBotMessage() {
            if (this.attempts > 9) {
                return 'Something went wrong...'
            } else if (this.result === 'complete') {
                this.snack('ETA Bot Refreshed','success',4000)
                return 'all done'
            } else {
                return this.ordinalNumbers[this.attempts - 1] + ' Attempt'
            }
        },
        isLoading() {
            if (this.$props.otherLoading || this.loading) {
                return true
            } else {
                return false
            }
        },
        updateLastRefreshedEtaBot() {
            localStorage.stockMarket_etaBot_lastRefreshed = this.lastRefreshed.etaBot
            return true
        }
    }
}
</script>

<style>
.pointer {
    cursor: pointer;
}
.pointer:hover {
    background-color: var(--v-accent-lighten5);
}
</style>