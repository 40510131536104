<template>
    <v-app>
        <v-main>
            <form>
                <v-row class="my-4" justify='center'>
                    <v-img src="../../../../assets/homePicture.jpg" max-width="75vw" max-height="50vh" class="pa-4"/>
                    <v-card width="75vw" class="py-4 px-4">
                        <v-card-title class="d-flex justify-center align-center pt-0">
                            Company information
                        </v-card-title>
                        <v-row justify="space-around" align="center" class="px-4">
                            <v-text-field
                                class="px-4"
                                v-model="companyName"
                                :error-messages="requiredError('companyName')"
                                required
                                maxlength="128"
                                label="Company Name"
                                @input="$v.companyName.$touch()"
                                @blur="$v.companyName.$touch()"/>
                            <v-text-field
                                class="px-4"
                                v-model="buyerFirstName"
                                :error-messages="requiredError('buyerFirstName')"
                                required
                                maxlength="64"
                                label="Buyer First Name"
                                @input="$v.buyerFirstName.$touch()"
                                @blur="$v.buyerFirstName.$touch()"/>
                            <v-text-field
                                class="px-4"
                                v-model="buyerLastName"
                                :error-messages="requiredError('buyerLastName')"
                                required
                                maxlength="64"
                                label="Buyer Last Name"
                                @input="$v.buyerLastName.$touch()"
                                @blur="$v.buyerLastName.$touch()"/>
                            <v-text-field
                                class="px-4"
                                v-model="email"
                                :error-messages="emailErrors"
                                required
                                maxlength="64"
                                label="Buyer Email"
                                @input="$v.email.$touch()"
                                @blur="$v.email.$touch()">
                                <template v-slot:prepend>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                v-bind="attrs"
                                                v-on="on" 
                                                color="primary">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            (Order Confirmation, Shipping/Tracking, Invoice)
                                        </span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-row>
                        <v-row justify="space-around" align="center" class="px-4">
                            <v-text-field
                            class="px-4"
                                v-model="phone"
                                :error-messages="requiredError('phone')"
                                required
                                maxlength="64"
                                label="Phone"
                                @input="$v.phone.$touch()"
                                @blur="$v.phone.$touch()"/>
                            <v-text-field
                                class="px-4"
                                v-model="website"
                                :error-messages="requiredError('website')"
                                required
                                maxlength="64"
                                label="Website"
                                @input="$v.website.$touch()"
                                @blur="$v.website.$touch()"/>
                            <v-text-field
                                class="px-4"
                                v-model="doorCount"
                                :error-messages="requiredError('doorCount')"
                                required
                                :min="0"
                                :max="2147483647"
                                type="number"
                                label="Door Count"
                                @input="$v.doorCount.$touch()"
                                @blur="$v.doorCount.$touch()">
                                <template v-slot:prepend>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                v-bind="attrs"
                                                v-on="on" 
                                                color="primary">
                                            mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            If your store is online only, list 0
                                        </span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-row>
                        <v-row justify="space-around" align="center" class="px-4">
                            <v-text-field
                                class="px-4"
                                v-model="thirdPartySites"
                                :error-messages="requiredError('thirdPartySites')"
                                required
                                maxlength="256"
                                label="Third Party Sites"
                                @input="$v.thirdPartySites.$touch()"
                                @blur="$v.thirdPartySites.$touch()">
                                <template v-slot:prepend>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                v-bind="attrs"
                                                v-on="on" 
                                                color="primary">
                                            mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            List any third party sites or platforms your company uses (Amazon, Jet.com, eBay, etc) If none, please type “none”
                                        </span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                            <v-checkbox
                                class="px-2"
                                v-model="onlineStore"
                                label="Online Store"/>
                            <v-checkbox
                                class="pl-2 pr-4"
                                v-model="majorityOnlineBusiness"
                                label="Is over 50% of your gross revenue generated by ecommerce and/or online sales?"/>
                        </v-row>
                    </v-card>
                </v-row>
                <v-row class="py-4" justify="center">
                    <v-card width="75vw" justify="center">
                        <v-card-title class="d-flex justify-center align-center">
                            Buyer Information
                        </v-card-title>
                        <v-card-subtitle class="text-center">
                            Every buyer added here will get a login to the Matador B2B portal if approved. This is for if you need to have multiple people within our company placing orders that cannot share a login.
                            <br>
                            The first buyer is added by default based on the information above. 
                            You can only have a maximum of 5 buyers.
                        </v-card-subtitle>
                        <v-list>
                            <v-list-item class="d-flex justify-start align-center">
                                <v-col cols="1">
                                    <v-list-item-title class="d-flex justify-start align-start">1.</v-list-item-title>
                                </v-col>
                                <v-text-field
                                    class="px-2"
                                    label="First Name"
                                    hint="Change Buyer First Name in 'Company information' to edit"
                                    v-model="buyerFirstName"
                                    readonly/>
                                <v-text-field
                                    class="px-2"
                                    label="Last Name"
                                    hint="Change Buyer Last Name in 'Company information' to edit"
                                    v-model="buyerLastName"
                                    readonly/>
                                <v-text-field
                                    class="px-2"
                                    label="Email"
                                    hint="Change Buyer Email in 'Company information' to edit"
                                    v-model="email"
                                    readonly/>
                            </v-list-item>
                            <v-list-item v-for="(contact, index) in contacts" :key="index">
                                <v-col cols="1">
                                    <v-list-item-title>{{ index + 2 }}.</v-list-item-title>
                                </v-col>
                                <v-text-field
                                    label="First Name"
                                    class="px-2"
                                    maxlength="64"
                                    v-model="contact.First_Name"/>
                                <v-text-field
                                    label="Last Name"
                                    class="px-2"
                                    maxlength="64"
                                    v-model="contact.Last_Name"/>
                                <v-text-field
                                    label="Email"
                                    class="px-2"
                                    maxlength="256"
                                    v-model="contact.Email"
                                    @input="$v.email.$touch()"
                                    @blur="$v.email.$touch()"
                                    :error-messages="emailErrors"/>
                                <v-btn
                                    icon
                                    color="grey"
                                    @click="contacts.splice(index,1)">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-list-item>
                            <template v-if="contacts.length < 4 && !disableContactAdd">
                                <v-list-item class="flex-column justify-center pb-0 mb-0">
                                    <v-btn
                                        icon
                                        class="pb-0"
                                        :disabled="disableContactAdd"
                                        @click="contacts.push({First_Name: '', Last_Name: '', Email: ''})">
                                        <v-icon color="primary">mdi-plus</v-icon>
                                    </v-btn>
                                    <v-list-item-subtitle class="text--grey py-0 mb-0 text-center">
                                        Click to add additional buyer
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </template>    
                        </v-list>
                    </v-card>
                </v-row>
                <v-row class="py-4" justify='center'>
                    <v-card width="75vw" class="pa-4">
                        <v-card-title class="d-flex justify-center align-center pt-0">
                            Shipping/Billing information
                        </v-card-title>
                        <v-row justify="start" class="pl-4 pr-2">
                            <v-col :cols="(billingAddressSameAsShipping) ? '12' : '6'">
                                <v-row justify="start" align="center" class="pl-2">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn 
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                                class="px-0 mx-0"
                                                @click="openAddressDialog('Shipping Address')" 
                                                color="primary">
                                                <v-progress-circular indeterminate v-if="loading" color="primary"/>
                                                <v-icon v-else>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Add Address Manually
                                        </span>
                                    </v-tooltip>
                                    <vuetify-google-autocomplete
                                        class="pr-4 pl-0"
                                        label="Shipping Address"
                                        hint="Please choose from the autocomplete options. If any problems, select '+'."
                                        id="shippingMap"
                                        v-model="Shipping_Address_Show"
                                        v-on:placechanged="getShippingAddressData"
                                        placeholder="Shipping Address"/>
                                </v-row>
                            </v-col>    
                            <v-col cols="6" v-if="!billingAddressSameAsShipping">
                                <v-row justify="center" align="center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn 
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                                class="px-0 mx-0"
                                                @click="openAddressDialog('Billing Address')" 
                                                color="primary">
                                                <v-progress-circular indeterminate v-if="loading" color="primary"/>
                                                <v-icon v-else>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            Add Address Manually
                                        </span>
                                    </v-tooltip>
                                    <vuetify-google-autocomplete
                                        class="pr-4 pl-0 ml-0"
                                        label="Billing Address"
                                        hint="Please choose from the autocomplete options. If any problems, select '+'."
                                        id="billingMap"
                                        :disabled="poBoxCheck"
                                        v-model="Billing_Address_Show"
                                        v-on:placechanged="getBillingAddressData"
                                        placeholder="Billing Address"/>
                                </v-row>
                            </v-col>    
                        </v-row>
                        <v-row class="mt-0 px-4">
                            <v-checkbox
                                hide-details
                                class="px-4 pt-0 mt-0"
                                v-model='billingAddressSameAsShipping'
                                label="Billing Address Same As Shipping"/>
                        </v-row>
                        <v-row class="px-4">
                            <v-text-field
                                class="px-4"
                                v-model="shippingAccount"
                                :error-messages="requiredError('shippingAccount')"
                                required
                                maxlength="64"
                                label="Shipping Acount"
                                @input="$v.shippingAccount.$touch()"
                                @blur="$v.shippingAccount.$touch()">
                                    <template v-slot:prepend>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                    v-bind="attrs"
                                                    v-on="on" 
                                                    color="primary">
                                                    mdi-information-outline
                                                </v-icon>
                                            </template>
                                            <span>
                                                If applicable, please include carrier, account number, and billing zip code for the shipping account. (List N/A if Not Applicable)
                                            </span>
                                        </v-tooltip>
                                    </template>
                                </v-text-field> 
                            <v-text-field
                                class="px-4"
                                v-model="apEmail"
                                :error-messages="apEmailErrors"
                                required
                                maxlength="128"
                                label="AP Email (Invoices)"
                                @input="$v.apEmail.$touch()"
                                @blur="$v.apEmail.$touch()"/>
                            <v-text-field
                                class="px-4"
                                v-model="billingCompany"
                                :error-messages="billingCompanyErrors"
                                required
                                maxlength="2048"
                                label="Billing Company Name"
                                @input="$v.companyName.$touch()"
                                @blur="$v.companyName.$touch()"/>
                        </v-row>
                    </v-card>
                </v-row>
                <v-row class="py-4" justify="center">
                    <v-card width="75vw">
                        <v-card-title class="d-flex justify-center align-center pt-0">
                            Terms of Sale
                        </v-card-title>
                        <v-card-text class="d-flex-column justify-center align-center">
                            <div class="red--text">
                                <strong>MATADOR TERMS OF SALE:</strong>
                            </div>
                            <div>
                                - All resellers are expected to upload Matador's MAP Policy which can be found
                                <a href="https://matadorup.com/pages/matador-mappolicy">here</a><br>
                                - Matador does not allow resellers to list Matador products on Amazon.com. Accounts or retailer who do not comply will be canceled immediately.<br>
                                - We request that all retailers use our product photos for online listings. Please request photos from your Matador sales rep.<br>
                                - By placing an order with Matador, you agree to willfully comply with these terms and conditions.<br>
                                - By checking the box below you are acknowledging and accepting Matador's Terms of Sale
                            </div>
                            <v-checkbox
                                label="I agree to abide by the above"
                                v-model="termsOfSaleAgreement"/>
                            <div class="d-flex justify-center align-center">
                                <v-select
                                    class="px-4"
                                    :items="['ACH Transfer','Wire Transfer', 'Credit Card']"
                                    v-model="paymentType"
                                    :error-messages="requiredError('paymentType')"
                                    label="Payment Type"
                                    @input="$v.paymentType.$touch()"
                                    @blur="$v.paymentType.$touch()"/>
                                <payment-terms/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-row>
                <v-row class="flex-column pb-4" justify="center" align="center">
                    <v-btn
                        width="75vw"
                        @click="submit()"
                        color="primary"
                        :loading="loading"
                        :disabled="$v.$invalid || !this.termsOfSaleAgreement || this.disableContactAdd">
                        Submit
                    </v-btn>
                    <span v-if="$v.$invalid || this.disableContactAdd" class="red--text">
                        <v-btn icon @click="showErrors = !showErrors">
                            <v-icon v-if="!showErrors" color="grey">mdi-chevron-right</v-icon>
                            <v-icon v-else color="grey">mdi-chevron-down</v-icon>
                        </v-btn>
                        Please complete all fields
                        <v-list v-if="showErrors">
                            <v-list-item v-for="(error, index) in formErrors" :key="index" class="red--text center-text">
                                {{ error }}
                            </v-list-item>
                        </v-list>
                    </span>
                </v-row>
            </form>
            <v-dialog
                v-model="confirmDialog"
                width="75vw"
                persistent>
                <v-card>
                    <v-card-title class="d-flex justify-center align-center">
                        <v-icon color="green" class="pr-2">
                            mdi-check-circle
                        </v-icon> Application Submitted
                    </v-card-title>
                    <v-card-text class="d-flex justify-center align-center">
                        <p class="text-center">
                            Thank you for apply for a wholesale account with Matador.<br> 
                            You should recieve a confirmation email in your inbox shortly.<br>
                            Please allow 2-4 business days for a sales manager to review your application and approve it.<br>
                            For questions please reach out to <a href="mailto:sales@matadorup.com">sales@matadorup.com</a>
                        </p>
                    </v-card-text>
                    <v-card-text class="text-h5 d-flex justify-center align-center">
                        Please Close This Tab
                    </v-card-text>
                </v-card>    
            </v-dialog>
            <v-dialog
                v-model="repWarningDialog"
                width="75vw"
                persistent>
                <v-card>
                    <v-card-title class="d-flex justify-center align-center">
                        <v-icon color="red" class="pr-2">mdi-alert-circle</v-icon>
                        Warning: Invalid URL
                    </v-card-title>
                    <v-card-text class="d-flex justify-center align-center black--text text-center">
                        Please check your link to make sure there is "?rep=" or "?manager=" followed by a number at the end of the URL.<br><br>
                        Current URL: "{{ getUrl }}"
                    </v-card-text>
                </v-card>   
            </v-dialog>
            <v-dialog
                v-model="error.dialog"
                persistent
                width="50vw">
                <v-card class="overflow-y-auto" height="70vh">
                    <v-card-title class="d-flex justify-center align-center">
                        Error: Whoops... that wasn't suppose to happen.
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                        Please take a screenshot of this window and send it to dev@matadorup.com
                    </v-card-subtitle>
                    <v-card-text>
                        {{ error.message }}
                    </v-card-text>
                    <v-card-text class="text-center">
                        We apologize for any inconvience and the developer will reach out as soon as possible with next steps.
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog 
                v-model="enterAddressDialog"
                width="50vw"
                persistent>
                <v-card class="overflow-y-auto pa-4" height="110%" width="50vw" :loading="loading">
                    <v-card-title class="d-flex justify-center align-center pb-4">
                        Confirm {{ addressType }}
                    </v-card-title>
                    <v-card-subtitle class="text-center" v-if="addressType === 'Shipping Address'">
                        Shipping addresses CANNOT be a PO box
                    </v-card-subtitle>
                    <v-row class="px-4" justify="center" align="center" v-if="addressType === 'Billing Address'">
                        <v-checkbox
                            color="secondary"
                            label="PO Box"
                            append-icon="mdi-package-variant-closed"
                            v-model="poBoxCheck"/>
                    </v-row>
                    <v-row v-if="!poBoxCheck && addressType === 'Shipping Address'" justify="center" align="center" class="flex-column" width="100vw">
                        <v-text-field
                            label="Address 1"
                            v-model="shippingAddress.address1"
                            class="px-4"/>
                        <v-text-field
                            label="Address 2"
                            v-model="shippingAddress.address2"
                            class="px-4"/>
                        <v-text-field
                            label="City"
                            v-model="shippingAddress.city"
                            class="px-4"/>
                        <v-text-field
                            label="State (2-Letter Abbrv.)"
                            v-model="shippingAddress.state"
                            class="px-4"/>
                        <v-text-field
                            label="Zip Code"
                            v-model="shippingAddress.zip"
                            class="px-4"/>
                        <v-text-field
                            label="Country (2-Letter Abbrv.)"
                            maxlength="2"
                            v-model="shippingAddress.country"
                            class="px-4"/>
                    </v-row>
                    <v-row v-else-if="!poBoxCheck && addressType === 'Billing Address'" justify="center" align="center" class="flex-column" width="100vw">
                        <v-text-field
                            label="Address 1"
                            v-model="billingAddress.address1"
                            class="px-4"/>
                        <v-text-field
                            label="Address 2"
                            v-model="billingAddress.address2"
                            class="px-4"/>
                        <v-text-field
                            label="City"
                            v-model="billingAddress.city"
                            class="px-4"/>
                        <v-text-field
                            label="State (2-Letter Abbrv.)"
                            v-model="billingAddress.state"
                            class="px-4"/>
                        <v-text-field
                            label="Zip Code"
                            v-model="billingAddress.zip"
                            class="px-4"/>
                        <v-text-field
                            label="Country (2-Letter Abbrv.)"
                            maxlength="2"
                            v-model="billingAddress.country"
                            class="px-4"/>
                    </v-row>
                    <v-row justify="center" align="center" v-if="poBoxCheck" class="px-4 flex-column">
                        <v-text-field
                            label="PO Box Number"
                            v-model="billingAddress.address1"
                            class="px-4"/>
                        <v-text-field
                            label="PO Box City"
                            v-model="billingAddress.city"
                            class="px-4"/>
                        <v-text-field
                            label="PO Box State (2-Letter Abbrv.)"
                            v-model="billingAddress.state"
                            class="px-4"/>
                        <v-text-field
                            label="PO Box Zip Code"
                            v-model="billingAddress.zip"
                            class="px-4"/>
                        <v-text-field
                            label="PO Box Contry (2-Letter Abbrv.)"
                            v-model="billingAddress.country"
                            maxlength="2"
                            class="px-4"/>
                    </v-row>
                    <v-row justify="space-around" align="center" class="mb-2">
                        <v-btn 
                            color="secondary"
                            @click="cancelAddress()">
                            Cancel
                        </v-btn>
                        <v-btn 
                            color="primary"
                            @click="fillOutAddress()">
                            Confirm
                        </v-btn>
                    </v-row>
                </v-card>
            </v-dialog>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>


<script>
import { validationMixin } from 'vuelidate'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import paymentTerms from './paymentTerms.vue'

export default {
    components: { 
        paymentTerms, 
    },
    mixins: [validationMixin],
 
    beforeMount() {
        document.title = 'Matador NDA'
        this.$store.dispatch('updateShowWaffle', false)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Matador New Dealer Application')
    },
    async created() {
        if(this.$route.query.rep) {
            this.rep = this.$route.query.rep
        } else {
            this.rep = 0
        }
        if(this.$route.query.manager) {
            this.manager = this.$route.query.manager
        }
        
        if(this.$route.query.id) {
            this.getLead(this.$route.query.id)
            this.id = this.$route.query.id
            this.type = 'update'
        }
        if (!this.rep && !this.$route.query.id && !this.$route.query.manager) {
            this.repWarningDialog = true
        }
    },
    watch: {
        billingAddressSameAsShipping: function() {
            if (!this.billingAddressSameAsShipping && !this.billingAddress) {
                this.billingAddress = {}
            } else if (!this.billingAddress){
                this.billingAddress = this.shippingAddress
            }
        }
    },
    data() {
        return {
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
            rep: '',
            companyName: '',
            buyerFirstName: '',
            buyerLastName: '',
            email: '',
            phone: '',
            website: '',
            doorCount: 1,
            onlineStore: false,
            majorityOnlineBusiness: false,
            thirdPartySites: '',
            shippingAccount: 'N/A',
            apEmail: '',
            billingAddressSameAsShipping: true,
            termsOfSaleAgreement: false,
            paymentType: '',
            manager: '',
            addressType: '',
            enterAddressDialog: false,
            Placeholder_Address_Show: '',
            shippingAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: ''
            },
            Shipping_Address_Show: '',
            billingAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: ''
            },
            contacts: [],
            Billing_Address_Show: '',
            addressFilledOut: false,
            poBoxCheck: false,
            billingCompany: '',
            creditApplicationComplete: false,
            type: 'new',
            id: 0,
            creditReference: {},
            error: {
                dialog: false,
                message: ''
            },
            showErrors: false,
            confirmDialog: false,
            repWarningDialog: false
        }
    },
    validations: {
            companyName: { required },
            buyerFirstName: { required },
            buyerLastName: { required },
            email: { required, email },
            phone: { required },
            website: { required },
            doorCount: { required },
            thirdPartySites: { required },
            shippingAccount: { required },
            apEmail: { required, email },
            paymentType: { required },
            shippingAddress : { 
                address1: { required },
                city: { required },
                state: { required },
                zip: { required },
                country: { required }
             },
            billingAddress: { 
                address1: {
                    required: requiredIf(function() {
                        return !this.billingAddressSameAsShipping
                    })
                },
                city: {
                    required: requiredIf(function() {
                        return !this.billingAddressSameAsShipping
                    })
                },
                state: {
                    required: requiredIf(function() {
                        return !this.billingAddressSameAsShipping
                    })
                },
                zip: {
                    required: requiredIf(function() {
                        return !this.billingAddressSameAsShipping
                    })
                },
                country: {
                    required: requiredIf(function() {
                        return !this.billingAddressSameAsShipping
                    })
                },
            },
            billingCompany: { required },
    },
    methods: {
        requiredError(item) {
            const errors = []
            if (!this.$v[item].$dirty) return errors
            !this.$v[item].required && errors.push('Required')
            return errors
        },
        async getLead(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_GetLeadDetails?tab=code
                    "https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/details?id=" + id,
                    {
                        method: "GET",
                        Headers: {
                            "Content-Type": "application/json",
                        }
                    }
                )
                const {
                    message,
                    details,
                    contacts
                } = await result.json()

                this.email = details[0].Email
                this.rep = details[0]
                this.manager = details[0].Sales_Manager
                this.rep = details[0].External_Sales_Rep
                this.companyName = details[0].Company_Name
                this.buyerFirstName = details[0].First_Name
                this.buyerLastName = details[0].Last_Name
                this.phone = details[0].Phone
                this.website = details[0].Website
                this.doorCount = details[0].Door_Count
                this.onlineStore = details[0].Online_Store === 1 ? true : false
                this.majorityOnlineBusiness = details[0].Majority_Online_Business
                this.thirdPartySites = details[0].Third_Party_Sites ? details[0].Third_Party_Sites : 'None'
                this.shippingAccount = details[0].Shipping_Account ? details[0].Shipping_Account : 'N/A'
                this.apEmail = details[0].AP_Email
                this.paymentType = details[0].Payment_Type
                this.manager = details[0].Sales_Manager
                this.billingCompany = details[0].Billing_Company
                this.contacts = contacts.filter(item => item.Email !== this.email)
                this.billingAddress = {
                    address1: details[0].Billing_Address_1 || '', 
                    address2: details[0].Billing_Address_2 || '', 
                    city: details[0].Billing_City || '', 
                    state: details[0].Billing_State || '', 
                    zip: details[0].Billing_Zip || '', 
                    country: details[0].Billing_Country || '', 
                }
                    
                this.formatShow('Billing_Address_Show',this.billingAddress)

                this.shippingAddress = {
                    address1: details[0].Shipping_Address_1 || '', 
                    address2: details[0].Shipping_Address_2 || '', 
                    city: details[0].Shipping_City || '', 
                    state: details[0].Shipping_State || '', 
                    zip: details[0].Shipping_Zip || '', 
                    country: details[0].Shipping_Country || '', 
                }
                this.formatShow('Shipping_Address_Show', this.shippingAddress)

                if ((this.billingAddress && !this.shippingAddress) || (this.billingAddress !== this.shippingAddress && this.shippingAddress && this.billingAddress)) {
                    this.billingAddressSameAsShipping = false
                } 
                if(!result.ok) {
                    throw message
                }
                
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        getShippingAddressData: async function (addressData) {
            this.shippingAddress = await this.getAddressDetails(addressData.place_id)
            this.openAddressDialog('Shipping Address')
        },
        getBillingAddressData: async function (addressData) {
            this.billingAddress = await this.getAddressDetails(addressData.place_id)
            this.openAddressDialog('Billing Address')
        },
        async getAddressDetails(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_ConvertLead?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/nda/address?id=' + id,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    address
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.loading = false
                return address
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        openAddressDialog(type) {
            this.addressType = type
            this.enterAddressDialog = true
        },
        formatShow(show, address) {
            let string = address.address1 + ', '
            if (address.address2 && address.address2.length > 0) {
                string = string + address.address2 + ', '
            }
            string = string + address.city + ', ' +
                    address.state + ' ' + 
                    address.zip + ' ' + 
                    address.country
            let check = string.replaceAll(",","")
            check = check.replaceAll(" ","")
            this[show] = (check.length === 0) ? '' : string

        },
        cancelAddress() {
            if (this.addressType === 'Billing Address') {
                this.Billing_Address_Show = ''
                this.billingAddress = {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                }
            } else {
                this.Shipping_Address_Show = ''
                this.shippingAddress = {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                }
            }
            this.poBoxCheck = false
            this.enterAddressDialog = false
        },
        async submit() {
            if (this.billingAddressSameAsShipping) {
                this.billingAddress = this.shippingAddress
            }
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_CreateLead?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            type: this.type,
                            rep: this.rep,
                            id: this.id,
                            companyName: this.companyName,
                            buyerFirstName: this.buyerFirstName,
                            buyerLastName: this.buyerLastName,
                            email: this.email,
                            phone: this.phone,
                            website: this.website,
                            doorCount: this.doorCount,
                            onlineStore: this.onlineStore,
                            majorityOnlineBusiness: this.majorityOnlineBusiness,
                            thirdPartySites: this.thirdPartySites,
                            trackingEmail: this.trackingEmail,
                            shippingAccount: this.shippingAccount,
                            apEmail: this.apEmail,
                            shippingAddress: this.shippingAddress,
                            billingAddress: this.billingAddress,
                            billingCompany: this.billingCompany,
                            paymentType: this.paymentType,
                            applyForCredit: this.creditReference.complete,
                            creditReference: this.creditReference,
                            manager: this.manager,
                            contacts: this.contacts
                        })
                    }
                )
            
                const {
                    message,
                    id
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                this.$router.push(`/confirmation/${id}`)

                this.loading = false
                this.confirmDialog = true
            } catch (error) {
                this.loading = false
                this.confirmDialog = false
                this.error.message = error
                this.error.dialog = true
            }
        },
        titleCase(words) {
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
            }
            return words.join(" ")
        },
        fillOutAddress() {
            if (this.addressType === 'Billing Address') {
                this.formatShow('Billing_Address_Show',this.billingAddress)
            } else {
                this.formatShow('Shipping_Address_Show',this.shippingAddress)
            }
            this.poBoxCheck = false
            this.enterAddressDialog = false
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Must be valid e-mail')
            !this.$v.email.required && errors.push('E-mail is required')
            return errors
        },
        apEmailErrors() {
            const errors = []
            if (!this.$v.apEmail.$dirty) return errors
            !this.$v.apEmail.email && errors.push('Must be valid e-mail')
            !this.$v.apEmail.required && errors.push('E-mail is required')
            return errors
        },
        billingCompanyErrors() {
            const errors = []
            if (!this.$v.billingCompany.$dirty) return errors
            !this.$v.billingCompany.required && errors.push('Billing Company is required')
            return errors
        },
        getUrl() {
            return window.location.href
        },
        formErrors() {
            let errors = []
            let validation = Object.keys(this.$v)
            validation = validation.filter(item => !item.startsWith("$"))
            for (let i = 0; i < validation.length; i++) {
                if (this.$v[validation[i]].$invalid) {
                    let message = validation[i].split(/(?=[A-Z])/)
                    message = this.titleCase(message)
                    errors.push(message)
                }
            }
            if (!this.termsOfSaleAgreement) {
                errors.push('Term of Sale Agreement/Checkbox')
            }
            if (this.disableContactAdd) {
                errors.push('Confirm All Buyer Information is Complete')
            }
            return errors
        },
        disableContactAdd() {
            let l = this.contacts.length
            let recent = this.contacts[l - 1]
            if (l > 0) {
                if (!recent.First_Name.length || !recent.Last_Name.length || !recent.Email.length) {
                    return true
                } else {
                    return false
                }
            } else {
                if (!this.buyerFirstName.length || !this.buyerLastName.length || !this.email.length) {
                    return true
                } else {
                    return false
                }
            }
            
        }
    }
}
</script>

<style>

</style>