<template>
    <v-container class="d-flex justify-center align-center mt-8">
        <v-card class="mt-16">
            <v-card-title>
                <div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                icon
                                @click="newStoreDialog = true"
                                dark>
                                <v-icon>mdi-plus</v-icon> 
                                </v-btn>
                        </template>
                        <span>Create Store</span>
                    </v-tooltip>
                    Stores
                </div>
                <v-spacer/>
                <div class="d-flex justify-center align-center">
                    <v-responsive class="ma-0 pa-0" :width="`${lookBackPeriod.toString().length * 5}.5rem`">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    class="shrink"
                                    v-model="lookBackPeriod"
                                    type="number"
                                    suffix="Days"
                                    v-bind="attrs"
                                    v-on="on"/>
                            </template>
                            <span>How many days back to search for 'on_hold' orders in ShipStation. This only applies to manual refreshes.</span>
                        </v-tooltip>
                    </v-responsive>
                    <v-btn
                        class="ml-2"
                        color="grey"
                        @click="infoDialog = true"
                        icon>
                        <v-icon>mdi-information</v-icon>    
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="stores"
                    :search="search"
                    :loading="loading">
                    <template v-slot:item.Active="{ item }">
                        <v-switch
                            v-model="item.Active"
                            @change="updateStore(item)"/>
                    </template>
                    <template v-slot:item.Name="{ item }">
                        <v-text-field
                            v-model="item.Name"
                            :disabled="item.Active === 0"
                            @change="updateStore(item)"/>
                    </template>
                    <template v-slot:item.Store_Id="{ item }">
                        <v-autocomplete
                            v-model="item.Store_Id"
                            :items="storeOptions"
                            :hint="item.Store_Id.toString()"
                            persistent-hint
                            item-value="id"
                            item-text="name"
                            :disabled="item.Active === 0"
                            @change="updateStore(item)"/>
                    </template>
                    <template v-slot:item.Cin7_Id="{ item }">
                        <v-autocomplete
                            v-model="item.Cin7_Id"
                            :items="accounts"
                            item-value="Id"
                            item-text="Company"
                            :hint="item.Cin7_Id.toString()"
                            persistent-hint
                            :disabled="item.Active === 0"
                            @change="updateStore(item)"/>
                    </template>
                    <template v-slot:item.Normal_Branch="{ item }">
                        <v-autocomplete
                            v-model="item.Normal_Branch"
                            :items="branches"
                            item-value="Id"
                            item-text="Company"
                            :disabled="item.Active === 0"
                            @change="updateStore(item)"/>
                    </template>
                    <template v-slot:item.Preorder_Branch="{ item }">
                        <v-autocomplete
                            v-model="item.Preorder_Branch"
                            :items="branches"
                            item-value="Id"
                            item-text="Company"
                            :disabled="item.Active === 0"
                            @change="updateStore(item)"/>
                    </template>
                    <template v-slot:item.Currency_Code="{ item }">
                        <v-responsive class="ma-0 pa-0" :width="`5rem`">
                            <v-text-field
                                v-model="item.Currency_Code"
                                :rules="[v => (!!v && v.length === 3)|| '3 digit Currency Code is required']"
                                :disabled="item.Active === 0"
                                @change="updateStore(item)"/>
                        </v-responsive>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-menu 
                            v-model="item.deleteMenu" 
                            :close-on-content-click="false" 
                            offset-y
                            :disabled="item.Active === 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    color="grey"
                                    icon
                                    dark
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="refreshEDI(item.Id)">
                                    <v-list-item-title>
                                        <v-icon>mdi-refresh</v-icon>
                                        Refresh Orders
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="deleteStore(item.Id)">
                                    <v-list-item-title class="red--text">
                                        <v-icon color="red">mdi-delete</v-icon>
                                        Delete
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="item.deleteMenu = false">
                                    <v-list-item-title>
                                        <v-icon>mdi-close</v-icon>
                                        Cancel
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog
            width="75%"
            v-model="newStoreDialog">
            <v-card>
                <v-card-title>
                    Create Store
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="createStore" class="flex-column justify-center align-center">
                        <div class="d-flex justify-center align-center">
                            <v-text-field 
                                v-model="newStore.Name" 
                                label="Name"
                                class="ml-4 mr-2"/>
                            <v-autocomplete
                                class="mx-2"
                                v-model="newStore.Store_Id"
                                :items="storeOptions"
                                item-value="id"
                                item-text="name"
                                label="ShipStation Store"/>
                            <v-autocomplete
                                class="mx-2"
                                v-model="newStore.Cin7_Id"
                                :items="accounts"
                                item-value="Id"
                                item-text="Company"
                                label="Cin7 ID"/>
                            <v-autocomplete
                                class="mx-2"
                                v-model="newStore.Normal_Branch"
                                :items="branches"
                                item-value="Id"
                                item-text="Company"
                                label="Normal Branch (<150 Days)"/>
                            <v-autocomplete
                                class="ml-2"
                                v-model="newStore.Preorder_Branch"
                                :items="branches"
                                item-value="Id"
                                item-text="Company"
                                label="Preorder Branch (>150 Days)"/>
                            <v-text-field
                                class="mx-2"
                                v-model="newStore.Currency_Code"
                                label="Currency Code"
                                :rules="[v => (!!v && v.length === 3)|| '3 digit Currency Code is required']"/>
                        </div>
                        <div class="d-flex justify-space-around align-center">
                            <v-btn @click="clearNewStore()">Cancel</v-btn>
                            <v-btn 
                                type="submit" 
                                :loading="loading"
                                :disabled="!isFormValid" 
                                color="primary">
                                Create
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog width="75vw" v-model="infoDialog">
            <v-card>
                <v-card-title class="d-flex justify-center align-center">
                    <div>
                        <v-icon>mdi-information</v-icon>
                        Information
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-list>
                        <v-list-item class="flex-column justify-center align-center">
                            <v-list-item-title>Active</v-list-item-title>
                            <v-list-item-subtitle>Whether the store is active or not. Stores that are not active will not be scanned for new orders to be brought into Cin7.</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="flex-column justify-center align-center">
                            <v-list-item-title>Store ID</v-list-item-title>
                            <v-list-item-subtitle>The ShipStation store ID that should be watched for new orders.</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="flex-column justify-center align-center">
                            <v-list-item-title>Name</v-list-item-title>
                            <v-list-item-subtitle>Just used to help identify which record is which. Not important.</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="flex-column justify-center align-center">
                            <v-list-item-title>Cin7 ID</v-list-item-title>
                            <v-list-item-subtitle>Whichever Cin7 Id is listed is where all the orders from the SS store will be moved to.</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="flex-column justify-center align-center">
                            <v-list-item-title>Normal Branch</v-list-item-title>
                            <v-list-item-subtitle>The branch that will be used for orders that are within the next 150 days</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="flex-column justify-center align-center">
                            <v-list-item-title>Preorder Branch</v-list-item-title>
                            <v-list-item-subtitle>The branch that will be used for orders that are beyond the next 150 days</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="flex-column justify-center align-center">
                            <v-list-item-title>Currency Code</v-list-item-title>
                            <v-list-item-subtitle>The currency code that will be listed on all sales orders coming into Cin7 from this ShipStation store</v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn 
                        :to="`/pam?page=ediProcess&return=/Ops/EDI-Stores`"
                        color="secondary">
                        More Information
                    </v-btn>
                </v-card-actions>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn 
                        @click="infoDialog = false"
                        color="primary">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - EDI Stores'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [
            { title: 'PAM', path: '/pam?page=ediProcess', icon: 'mdi-wizard-hat'}
        ])
        this.$store.dispatch('updateNavbarTitle','EDI Stores')
        this.getInfo();
    },
    data() {
        return {
            headers: [
                { text: 'Active', value: 'Active' },
                { text: 'Name', value: 'Name' },
                { text: 'ShipStation Store', value: 'Store_Id' },
                { text: 'Cin7 ID', value: 'Cin7_Id' },
                { text: 'Normal Branch (<150 Days)', value: 'Normal_Branch' },
                { text: 'Preorder Branch (>150 Days)', value: 'Preorder_Branch' },
                { text: 'Currency Code', value: 'Currency_Code' },
                { text: 'Actions', value: 'actions', sortable: false}
            ],
            storeOptions: [],
            stores: [],
            accounts: [],
            branches: [],
            search: '',
            totalStores: 0,
            perPage: 10,
            page: 1,
            lookBackPeriod: 2,
            newStore: {
                Store_Id: '',
                Name: '',
                Cin7_Id: null,
                Normal_Branch: null,
                Preorder_Branch: null,
                Currency_Code: 'USD'
            },
            newStoreDialog: false,
            infoDialog: false,
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        };
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/edi')
                url.searchParams.append('email', this.$store.state.user.email)
                //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_EDI_Dashboard_getInfo?tab=code
                const response = await fetch(
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                
                const {
                    message,
                    stores,
                    accounts,
                    branches,
                    storeOptions
                } = await response.json()

                if (!response.ok) {
                    throw new Error(message)
                }

                this.branches = branches
                this.accounts = accounts
                this.stores = stores
                this.storeOptions = storeOptions

                this.stores.forEach(store => {
                    store.deleteMenu = false
                })
                this.loading = false
                this.snack(message, 'success', 3000)
            } catch(error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateStore(store) {
            try {
                if (/^\d+$/.test(parseInt(store.Store_Id)) === false) {
                    this.snack('Store ID must be a number','error',3000)
                    return
                }
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/edi')
                url.searchParams.append('email', this.$store.state.user.email)
                //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_EDI_Dashboard_editStore?tab=code
                let id = store.Id
                let update = {
                    Active: store.Active,
                    Store_Id: store.Store_Id,
                    Name: store.Name,
                    Cin7_Id: store.Cin7_Id,
                    Normal_Branch: store.Normal_Branch,
                    Preorder_Branch: store.Preorder_Branch,
                    Currency_Code: store.Currency_Code
                }
                const response = await fetch(
                    url,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            update: update,
                            id: id
                        })
                    }
                )
                const {
                    stores,
                    message
                } = await response.json()

                if (!response.ok) {
                    throw new Error(message)
                }

                this.stores = stores

                this.loading = false
                this.snack(message, 'success', 3000)
            } catch(error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async createStore() {
            this.loading = true
            let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/edi')
            url.searchParams.append('email', this.$store.state.user.email)
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_EDI_Dashboard_createStore?tab=code
            const response = await fetch(
                url,
                {
                    method: 'POST',
                    Headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.newStore)
                }
            )
            const {
                stores,
                message
            } = await response.json()

            if (!response.ok) {
                this.loading = false
                throw new Error(message)
            }

            this.stores = stores
            this.newStoreDialog = false
            this.newStore = {
                Store_Id: '',
                Name: '',
                Cin7_Id: null,
                Normal_Branch: null,
                Preorder_Branch: null,
            }
            this.loading = false
            this.snack(message, 'success', 3000)
        },
        async deleteStore(id) {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/edi')
                url.searchParams.append('email', this.$store.state.user.email)
                url.searchParams.append('id', id)
                //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_EDI_Dashboard_deleteStore?tab=code
                const response = await fetch(
                    url,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    stores,
                    message
                } = await response.json()

                if (!response.ok) {
                    throw new Error(message)
                }

                this.stores = stores

                this.loading = false
                this.snack(message, 'success', 3000)
            } catch(error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async refreshEDI(Id) {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/edi/refresh')
                url.searchParams.append('email', this.$store.state.user.email)
                url.searchParams.append('id', Id)
                url.searchParams.append('lookBackPeriod', this.lookBackPeriod)
                //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_EDI_Process?fullscreen=false&tab=code
                const response = await fetch(
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message
                } = await response.json()

                if (!response.ok) {
                    throw new Error(message)
                }

                this.loading = false
                this.snack(message, 'success', 3000)
            } catch(error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        clearNewStore() {
            this.newStoreDialog = false
            this.newStore = {
                Store_Id: '',
                Name: '',
                Cin7_Id: null,
                Normal_Branch: null,
                Preorder_Branch: null,
            }
        },
        snack(text, color, timeout) {
            this.snackText = text
            this.snackColor = color
            this.snackTimeout = timeout
            this.snackActive = true
        }
    },
    computed: {
        isFormValid() {
            return (
                this.newStore.Store_Id &&
                this.newStore.Name &&
                this.newStore.Cin7_Id &&
                this.newStore.Normal_Branch &&
                this.newStore.Preorder_Branch
            );
        },
    }
};
</script>
