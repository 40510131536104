<template>
    <v-app>
        <v-main style="overflow-y: hidden; overflow-x: hidden;">
            <v-row class="px-2 pt-4" justify="space-between" v-if="ecomRevenue.length">
                <v-col class="pr-0 mr-0" cols="3">
                    <!-- Settings -->
                    <v-card class="pa-4 overflow-y-auto" style="overflow-x: hidden;" height="85vh">
                        <v-row justify="start" align="start">
                            <div>
                                <v-btn
                                    @click="infoDialogCount++"
                                    icon
                                    color="primary">
                                    <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                            </div>
                            <v-spacer/>
                            <div class="d-flex justify-center align-center">
                                <v-btn
                                    icon 
                                    color="black"
                                    @click="lookForward--"
                                    :disabled="(lookForward === 0)">
                                    <v-icon large>mdi-chevron-down</v-icon>
                                </v-btn>
                                <div class="flex-column justify-center align-center">
                                    <v-card-title class="d-flex justify-center align-center">
                                        {{ lookForward }}
                                    </v-card-title>
                                    <v-card-subtitle class="text-center pb-0 mb-0">
                                        Week
                                    </v-card-subtitle>
                                    <v-card-subtitle class="text-center pt-0 mt-0">
                                        Look Ahead
                                    </v-card-subtitle>
                                </div>    
                                <v-btn
                                    icon
                                    color="black"
                                    :disabled="lookForward === 52"
                                    @click="lookForward++">
                                    <v-icon large>mdi-chevron-up</v-icon>
                                </v-btn>
                            </div>
                            <v-spacer/>
                            <v-spacer/>
                        </v-row>
                        <v-divider/>
                        <v-row>
                            <v-col>
                                <div class="pb-0">
                                    <v-card-title class="d-flex justify-center align-center text-h5 text-no-wrap">
                                        <strong>{{ tableFormattedData[0].Bank_Balance + overdueBalance | currency }}</strong>
                                    </v-card-title>
                                    <v-card-subtitle class="d-flex justify-center align-center pb-2 text-center">
                                        Final Bank Balance After {{ lookForward }} Weeks<br/>
                                    </v-card-subtitle>
                                </div>
                                <v-row justify="center" class="pt-1"> 
                                    <v-card-subtitle v-if="(bankDifference > 0)" class="text-center green--text pt-0">
                                        +{{ bankDifference | currency }}
                                    </v-card-subtitle>
                                    <v-card-subtitle v-else class="text-center red--text pt-0">
                                        {{ bankDifference | currency }}
                                    </v-card-subtitle>
                                    
                                </v-row>
                            </v-col>
                            <v-divider class="my-8" vertical/>
                            <v-col class="d-flex justify-center align-center">
                                <div style="cursor: pointer">
                                    <v-card-title @click="overdueDialogCount++" class="d-flex justify-center align-center text-h5 red--text pb-2 pr-0">
                                        <strong>{{ overdueBalance | currency }}</strong>
                                    </v-card-title>
                                    <v-card-subtitle class="d-flex justify-center align-center pb-2 pt-0">
                                        Overdue Payments
                                    </v-card-subtitle>
                                </div>    
                                <v-row>
                                    <div class="d-flex justify-center align-center">
                                        <v-btn
                                            icon 
                                            color="black"
                                            @click="overdueLookback--"
                                            :disabled="(overdueLookback === 0)">
                                            <v-icon large>mdi-chevron-down</v-icon>
                                        </v-btn>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on" class="flex-column justify-center align-center">
                                                    <v-card-title class="d-flex justify-center align-center">
                                                        {{ overdueLookback }}
                                                    </v-card-title>
                                                    <v-card-subtitle class="text-center">
                                                        Weeks Ago
                                                    </v-card-subtitle>
                                                </div>
                                            </template>
                                            <span>Recommended to stay at 2 weeks</span>
                                        </v-tooltip>
                                        <v-btn
                                            icon
                                            color="black"
                                            :disabled="overdueLookback === 52"
                                            @click="overdueLookback++">
                                            <v-icon large>mdi-chevron-up</v-icon>
                                        </v-btn>
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>    
                        
                        <v-divider/>
                        <v-row justify="center">
                            <v-menu 
                                offset-y 
                                :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="flex-column justify-center align-center">
                                        <v-card-title 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            class="d-flex justify-center align-center">
                                            {{ bankBalance | currency}}
                                        </v-card-title>
                                        <v-card-subtitle class="text-center">
                                            Bank Balance
                                        </v-card-subtitle>
                                    </div>    
                                </template>
                                <v-card class="pa-4">
                                    <v-text-field
                                        v-model="bankBalance"
                                        label="Edit Bank Balance"/>
                                </v-card>
                            </v-menu>
                        </v-row>
                        <v-divider/>
                        <v-card-title class="d-flex justify-center align-center">
                            Settings
                        </v-card-title>
                        <v-row justify="space-around" class="pb-1">
                            <v-btn
                                @click="expense.dialog = true"
                                small
                                :disabled="allowed"
                                color="secondary">
                                Add Expense
                            </v-btn>
                            <v-btn
                                @click="saveDialog = true"
                                small
                                :disabled="allowed"
                                :class="(saveForecastWarning) ? 'bounce' : ''"
                                :color="(saveForecastWarning) ? 'error': 'secondary'">
                                Save Forecast
                            </v-btn>
                        </v-row>
                        <v-row justify="space-around" class="pb-1">
                            <v-menu 
                                offset-y 
                                v-model="parseExpenseMenu"
                                :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="allowed"
                                        class="pl-4 pr-2"
                                        color="secondary">
                                        Parse Expenses
                                    </v-btn>
                                </template>
                                <v-card class="pa-4">
                                    <v-card-title class="d-flex justify-center align-center">
                                        Report Week Start Date
                                    </v-card-title>
                                    <v-select
                                        :items="weekStartDates"
                                        item-text="week"
                                        item-value="date"
                                        v-model="expenseStartDate"
                                        hide-details/>
                                    <v-card-actions class="d-flex justify-center align-center">
                                        <v-btn
                                            color="primary"
                                            :disabled="!expenseStartDate.length || allowed"
                                            :loading="loading"
                                            @click="parseExpenses()">
                                            Submit
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>  
                            
                            <v-btn
                                @click="openCharts()"
                                small
                                color="secondary">
                                See Charts
                            </v-btn>
                        </v-row>
                        <v-row justify="center" align="center" class="pt-1 pb-2 mb-2">
                            <v-switch
                                label="Show Details"
                                v-model="showDetails"
                                hide-details
                                color="primary"/>
                        </v-row>
                        <v-row justify="center" align="center">
                            <div class="d-flex justify-center align-center">
                                <span 
                                    class="d-flex justify-center align-center pr-2">
                                    Use Growth
                                </span>
                                <v-switch
                                    v-model="useForecast"
                                    :loading="forecastLoading"
                                    @change="getForecast()"
                                    color="primary"/>
                                <span 
                                    class="d-flex justify-center align-center pl-2">
                                    Use Forecast
                                </span>
                            </div>
                            
                        </v-row>
                        <!-- <v-divider/> -->
                        <v-row 
                            v-if="!useForecast"
                            class="px-1 py-2"
                            justify="center" 
                            align="center">
                            <v-card 
                                class="pa-4" 
                                width="100%"
                                elevation="0">
                                 <v-row justify="space-between" align="center">
                                    <v-card-subtitle class="px-0 py-1">Normalize</v-card-subtitle>
                                    <v-spacer/>
                                    <v-divider vertical/>
                                    <v-spacer/>
                                    <v-card-subtitle class="px-0 py-1">Company</v-card-subtitle>
                                    <v-spacer/>
                                    <v-divider vertical/>
                                    <v-spacer/>
                                    <v-card-subtitle class="px-0 py-1">Growth Rate</v-card-subtitle>
                                    <v-spacer/>
                                </v-row>
                            </v-card>
                        </v-row>
                        <v-row v-if="!useForecast">
                            <v-card width="100%" elevation="0" class="pa-4 mb-1" v-for="(item, index) in companies" :key="index">
                                <v-row align="center" justify="space-between">
                                    <v-col cols="6">
                                        <v-checkbox
                                            class="pr-0 mt-0 pt-0"
                                            hide-details
                                            @change="updateLocalStorage();"
                                            :label="item.company"
                                            v-model="item.replaceOutliers"
                                            color="primary"/>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field 
                                            suffix="%"
                                            class="mt-0 pt-0"
                                            @change="updateLocalStorage()"
                                            type="number"
                                            :hint="getAvgGrowthRateMessage(item.avgGrowth)"
                                            hide-details="auto"
                                            v-model="item.growth"/>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-row>
                        <v-divider/>
                        <v-row justify="center">
                            <v-card-title class="d-flex justify-center align-center">
                                Table Key
                            </v-card-title>
                            <v-row align="center" justify="space-around">
                                <span class="orange--text">
                                    Predicted
                                </span>
                                <span class="black--text">
                                    Actual
                                </span>
                                <span class="green--text">
                                    This Week
                                </span>
                            </v-row>
                            <v-card-subtitle class="d-flex justify-center align-center">
                                *Weeks start on Monday
                            </v-card-subtitle>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Table -->
                <v-col cols="9">
                    <v-card class="pa-4" height="85vh">
                        <v-simple-table fixed-header height="83vh" v-if="!forecastLoading">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Created Date</th>
                                        <th v-for="(header, index) in tableHeaders" :key="index">
                                            {{ header.text }}
                                        </th>
                                        <th v-if="showDetails">
                                            Wholesale
                                        </th>
                                        <th>
                                            Revenue Total
                                        </th>
                                        <th v-if="showDetails">
                                            Payroll
                                        </th>
                                        <th v-if="showDetails">
                                            Weekly Expenses
                                        </th>
                                        <th v-if="showDetails">
                                            Large Expenses
                                        </th>
                                        <th v-if="showDetails">
                                            Inventory Purchases
                                        </th>
                                        <th>
                                            Expense Total
                                        </th>
                                        <th>
                                            Weekly Delta
                                        </th>
                                        <th>
                                            Bank Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in tableFormattedData" :key="index" :class="{'font-weight-bold' : row.Created_Date === currentDateFormatted}">
                                        <td :class="rowClass(row.Created_Date)">
                                            {{ row.Created_Date }}
                                            <v-icon x-small v-if="row.Created_Date === currentDateFormatted" class="green--text">
                                                mdi-circle
                                            </v-icon>
                                        </td>
                                        <v-menu
                                            v-for="(column, index) in tableHeaders" :key="index"
                                            offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <td :class="cellClass(row[column.text].Actual)" v-bind="attrs" v-on="on">
                                                    {{ row[column.text].Value | currency }}
                                                </td>
                                            </template>
                                            <v-card class="pa-4" v-if="!row[column.text].Actual && !useForecast">
                                                <v-card-title class="d-flex justify-center align-center">
                                                    Previous Year
                                                </v-card-title>
                                                <v-card-text class="d-flex justify-center align-center">
                                                    {{ getPreviousYearValue(column.text, row.Created_Date) | currency}}
                                                </v-card-text>
                                                <v-card-subtitle class="d-flex justify-center align-center">
                                                    Based on a {{ getGrowthRate(column.text) }}% growth rate
                                                </v-card-subtitle>
                                            </v-card>
                                            <v-card class="pa-4" v-else-if="!row[column.text].Actual && useForecast && column.text in forecast && row.Created_Date in forecast[column.text]">
                                                <v-card-title class="d-flex justify-center align-center">
                                                    Forecasted
                                                </v-card-title>
                                                <v-card-text class="d-flex justify-center align-center">
                                                    {{ forecast[column.text][row.Created_Date] | currency}}
                                                </v-card-text>
                                                <v-card-subtitle class="d-flex justify-center align-center">
                                                    Based on Forecast Submitted by Sales/Ops
                                                </v-card-subtitle>
                                            </v-card>
                                        </v-menu>
                                        <td 
                                            v-if="showDetails"
                                            :class="cellClass(row.Wholesale.Actual)"
                                            @click="selectedWeek = row.Created_Date">
                                            {{ row.Wholesale.Value | currency }}
                                        </td>
                                        <td :class="cellClass(row.Total_Revenue.Actual)"> 
                                            <!-- Total Revenue for all channels -->
                                            {{ row.Total_Revenue.Value | currency }}
                                        </td>
                                        <td 
                                            v-if="showDetails"
                                            :class="cellClass(row.Payroll.Actual)">
                                            {{ row.Payroll.Value | currency }}
                                        </td>
                                        <v-menu 
                                            v-if="row['Weekly Expense'].Actual && showDetails"
                                            offset-y 
                                            ref="weeklyExpenseMenu"
                                            :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <td v-bind="attrs" v-on="on">
                                                    <!-- Weekly Expense -->
                                                    {{ row['Weekly Expense'].Value | currency }}
                                                </td>
                                            </template>
                                            <v-card class="pa-4" :loading="editExpenseLoading">
                                                <v-card-title class="d-flex justify-center align-center">
                                                    Edit Expense
                                                </v-card-title>
                                                <v-text-field
                                                    prefix="$"
                                                    v-model="row['Weekly Expense'].Value"/>
                                                <v-card-actions>
                                                    <v-btn 
                                                        color="secondary" 
                                                        :disabled="allowed"
                                                        @click="editWeeklyExpense(row.Created_Date, row['Weekly Expense'].Value); $refs.weeklyExpenseMenu.save()">
                                                        Edit
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                        <td v-else-if="showDetails" class="orange--text">
                                            {{ row['Weekly Expense'].Value | currency }}
                                        </td>
                                        <v-menu 
                                            v-if="showDetails"
                                            offset-y 
                                            ref="largeExpenseMenu"
                                            :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <td v-bind="attrs" v-on="on" @click="showLargeExpenses(row.Created_Date)">
                                                    <!-- Large Expense -->
                                                    {{ row['Large Expense'].Value | currency }}
                                                </td>
                                            </template>
                                            <v-card class="pa-8" height="100%" width="100%" :loading="loading">
                                                <v-card-title class="d-flex justify-center align-center pt-0">
                                                    Week: {{ row.Created_Date }}
                                                </v-card-title>
                                                <template v-if="(largeExpenseDetails.length > 0)">
                                                    <v-row v-for="(expense, index) in largeExpenseDetails" :key="index">
                                                        <v-col class="d-flex justify-center align-center" width="100%">
                                                            <v-text-field 
                                                                v-model="expense.Description" 
                                                                :disabled="allowed"
                                                                @change="editLargeExpense(expense.Id, expense.Description, expense.Value, row.Created_Date)"
                                                                label="Description"/>
                                                        </v-col>    
                                                        <v-col class="d-flex justify-center align-center">
                                                            <v-text-field 
                                                                type="number"
                                                                v-model="expense.Value"
                                                                :disabled="allowed"
                                                                @change="editLargeExpense(expense.Id, expense.Description, expense.Value, row.Created_Date)"
                                                                label="Value" 
                                                                prefix="$"/>
                                                        </v-col>
                                                        <v-col cols="1" class="d-flex justify-center align-center">
                                                            <v-menu offset-y>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn color="red" icon v-bind="attrs" v-on="on">
                                                                        <v-icon>mdi-delete-outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-card class="pa-4">
                                                                    <v-card-title class="d-flex justify-center align-center">
                                                                        Are you Sure?
                                                                    </v-card-title>
                                                                    <v-card-actions class="d-flex justify-space-around align-center">
                                                                        <v-btn 
                                                                            color="secondary" 
                                                                            @click="deleteLargeExpense(expense.Id)"
                                                                            :disabled=allowed>
                                                                            Yes
                                                                        </v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                                <template v-else>
                                                    <v-card-subtitle class="d-flex justify-center align-center">
                                                        No Large Expenses
                                                    </v-card-subtitle>
                                                </template>
                                            </v-card>
                                        </v-menu>
                                        <td 
                                            v-if="showDetails"
                                            :class="cellClass(row['Inventory Purchase'].Actual)">
                                            <!-- Inventory Purchases -->
                                            {{ row['Inventory Purchase'].Value | currency }}
                                        </td>
                                        <td :class="cellClass(row.Total_Expense.Actual)">
                                            <!-- Expense Total -->
                                            {{ row.Total_Expense.Value | currency }}
                                        </td>
                                        <td :class="deltaClass(row.Total_Revenue.Value - row.Total_Expense.Value)">
                                            <!-- Weekly Delta -->
                                            {{ row.Total_Revenue.Value - row.Total_Expense.Value | currency }}
                                        </td>
                                        <td>
                                            <!-- Bank Balance -->
                                            <strong>{{ row.Bank_Balance | currency }}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>     
                </v-col>
            </v-row>
            <v-row class="pt-8" justify="center" v-else>
                <v-progress-circular indeterminate/>
            </v-row>
            <v-dialog v-model="chartDialog" width="75vw" height="75vh">
                <v-card class="pa-4">
                    <v-row justify="end">
                        <v-spacer/>
                        <v-card-title>
                            Charts
                        </v-card-title>
                        <v-spacer/>
                        <v-btn
                            @click="chartDialog = false"
                            icon
                            color="grey">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-row>
                    <v-row justify="center">
                        <v-card class="pa-4" width="72vw">
                            <v-card-title class="d-flex justify-center align-center">
                                Bank Balance vs Forecast
                            </v-card-title>
                            <chart
                                :refresh="refresh"
                                :chartData="bankBalanceChartData"/>
                        </v-card>
                    </v-row>
                    <v-row justify="center">
                        <v-col v-for="(data, index) in chartDataSets" :key="index">
                            <v-card class="pa-4">
                                <v-card-title class="d-flex justify-center align-center">
                                    {{ data.title }}
                                </v-card-title>
                                <v-card-subtitle class="text-center">
                                    Y = {{ data.equation.m }}x + {{ data.equation.b }}
                                </v-card-subtitle>
                                <chart
                                    :refresh="refresh"
                                    :chartData="data"/>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>    
            </v-dialog>   
            <v-dialog
                v-model="expense.dialog"
                width="50vw"
                height="50vh">
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Add Expense
                    </v-card-title>
                    <v-row justify="space-around" class="px-4 pb-4">
                        <v-text-field
                            v-model="expense.date"
                            hint="YYYY-MM-DD Format"
                            :error="incorrectExpenseDate"
                            class="pr-2"
                            label="Date"/>
                        <v-text-field
                            v-model="expense.description"
                            hide-details
                            class="px-2"
                            label="Description"/>
                        <v-text-field
                            v-model="expense.value"
                            hide-details
                            label="Value"
                            prefix="$"
                            class="pl-2"
                            type="number"
                            :min="0"/>
                    </v-row>
                    <v-card-actions class="d-flex justify-space-around align-center">
                        <v-btn
                            color="accent"
                            @click="expense.dialog = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            color="secondary"
                            @click="submitExpense()"
                            :diabled="disableExpense || allowed"
                            :loading="loading">
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="saveDialog" width="50vw" height="50vh">
                <v-card class="pa-4" width="50vw">
                    <v-card-title class="d-flex justify-center align-center">
                        Save the following Forecast?
                    </v-card-title>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Week</th>
                                <th>Weeks Out</th>
                                <th>Predicted Bank Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in bankData" :key="key">
                                <td>{{ value.Week }}</td>
                                <td>{{ value.Weeks_Out }}</td>
                                <td>{{ value.Forecast | currency }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card-actions class="d-flex justify-space-around align-center">
                        <v-btn
                            color="accent"
                            @click="saveDialog = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="updateBankForecast();"
                            :loading="loading">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <wholesale-details
                :selectedWeek="selectedWeek"/>
            <overdue-details
                :dialogCount="overdueDialogCount"
                :overdueLookback="overdueLookback"
                @overdue-lookback-changed="(n) => overdueLookback = n"/>
            <info
                :dialogCount="infoDialogCount"/>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
import chart from './chart.vue'
import wholesaleDetails from './wholesaleDetails.vue'
import OverdueDetails from './overdueDetails.vue'
import Info from './info.vue'

export default {
    async beforeMount() {
        document.title = 'Sandbox - Cash Flow Model'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Cash Flow Model')
        
        //calculate current week
        let d = new Date()
        let startDate = new Date(d.getFullYear(), 0, 1)
        let days = Math.floor((d - startDate) / (24 * 60 * 60 * 1000));
        let week = Math.ceil(days / 7)
        this.currentWeek = (week < 10) ? "0" + week : week
        this.currentYear = d.getFullYear()
        this.currentDateFormatted = this.currentYear + "-" + this.currentWeek
        this.currentDateNumber = Number(d.getFullYear().toString() + this.currentWeek.toString())

        let currentDay = d.getDay()
        let weekStartDay = 1
        let adjustedDay = currentDay - weekStartDay
        if (adjustedDay < 0) {
            adjustedDay = 7 + adjustedDay
        }
        this.proportionOfWeek = 1 - (adjustedDay / 7);

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        this.todayString = daysOfWeek[d.getDay()];

        await this.getInfo()
        await this.setCompanies()
        
        //pull in data from local storage if it is present
        if (localStorage.cashFlowModel) {
            let info = JSON.parse(localStorage.cashFlowModel)
            if (info.version === undefined) {
                delete localStorage.cashFlowModel
            } else if (info.version !== this.version) {
                delete localStorage.cashFlowModel
            } else if (info.version === this.version) {

                this.lookForward = 6
                if (info.lookForward !== null) {
                    this.lookForward = Number(info.lookForward)
                } 

                this.overdueLookback = 6
                if (info.overdueLookback !== null) {
                    this.overdueLookback = Number(info.overdueLookback)
                } 
                if (info.useForecast) {
                    this.useForecast = true
                    await this.getForecast()
                }
            } else {
                this.updateLocalStorage()
            }
        }
    },
    watch: {
        lookForward: function() {
            this.updateLocalStorage()
            this.setCompanies()
        },
        overdueLookback: function() {
            this.updateLocalStorage()
            this.setCompanies()
        },
        useForecast: function() {
            this.updateLocalStorage()
        }
    },
    components: {
        chart,
        wholesaleDetails,
        OverdueDetails,
        Info
    },
    data() {
        return {
            version: 2,
            expenseStartDate: '',
            currentWeek: 0,
            currentYear: 0,
            proportionOfWeek: 0,
            currentDateFormatted: 0,
            showDetails: true,
            todayString: '',
            currenDateNumber: 0,
            infoDialogCount: 0,
            overdueDialogCount: 0,
            companyGrowthAverages: {},
            //how many weeks ahead to look
            lookForward: 4,
            //chart refresh
            refresh: 0,
            //revenue for all ecom channels
            ecomRevenue: [],
            //wholesale data by payment dates not ship date
            wholesaleData: {
                historicalData: [],
                futureData: []
            },
            //all large expenses
            largeExpenses: [],
            //all weekly expenses
            weeklyExpenses: [],
            //payroll expenses
            payrollExpenses: [],
            //all inventory purchases
            inventoryPurchases: [],
            bankBalanceData: [],
            //all companies to run regression on
            companies: [],
            forecast: {},
            forecastLoading: false,
            useForecast: false,
            forecastLoaded: false,
            //selected Week to view details about the wholesale payments incoming
            selectedWeek: '',
            //current bank balance
            bankBalance: 100000,
            //whether or not to replace outliers when doing regression
            replaceOutliers: true,
            //show all charts
            chartDialog: false,
            //credit card dialog
            //create a large expense
            expense: {
                dialog: false,
                date: '',
                description: '',
                value: 0
            },
            //when viewing expenses for a given week
            largeExpenseDetails: [],
            //overdue balance
            overdueWeeks: [],
            //overdue Lookback
            overdueLookback: 10,
            //the object that holds all the specific information about 'This Week'
            editExpenseLoading: false,
            parseExpenseMenu: false,
            saveDialog: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CashFlowModel_GetRevenues?newFunction=true&tab=code
                    "https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/cashflowmodel", 
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    ecomRevenue,
                    largeExpenses,
                    weeklyExpenses,
                    payrollExpenses,
                    inventoryPurchases,
                    wholesaleData,
                    bankBalance,
                    companyGrowthAverages,
                    overdueWeeks,
                    bankBalanceData
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.ecomRevenue = ecomRevenue
                this.largeExpenses = largeExpenses
                this.weeklyExpenses = weeklyExpenses
                this.payrollExpenses = payrollExpenses
                this.inventoryPurchases = inventoryPurchases
                this.wholesaleData = wholesaleData
                this.bankBalance = bankBalance
                this.companyGrowthAverages = companyGrowthAverages
                this.overdueWeeks = overdueWeeks
                this.bankBalanceData = bankBalanceData

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async getForecast() {
            if (this.useForecast && !('MatadorUp.com' in this.forecast)) {
                let companies = this.tableHeaders.map(item => item.text)
                companies.push('Wholesale')
                this.forecastLoading = true
                for (let i = 0; i < companies.length; i++) {
                    try {
                        const result = await fetch(
                            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CashFlowModel_GetForecast?tab=code
                            `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/cashflowmodel/forecast?email=${this.$store.getters.getUserEmail}&company=${companies[i]}`,
                            {
                                method: 'GET',
                                Headers: {
                                    'Content-Type': 'application/json',
                                }
                            }
                        )
                        const {
                            message,
                            forecast
                        } = await result.json()
                    
                        if(!result.ok) {
                            throw message
                        }
                        this.forecast[companies[i]] = forecast

                        this.snack(`${i + 1} of ${companies.length} forecasts collected`,'info', -1)
                    } catch (error) {
                        this.loading = false
                        this.snack(error,'error',3000)
                    }
                }
                this.snack('Forecasts Collected','success',3000)
                this.forecastLoading = false

                //turn off and on the useForecast to get the computed element tableFormatted data to update
                this.useForecast = false
                this.useForecast = true
            }
        },
        async setCompanies() {
            let object = []
            let companies = [... new Set(this.ecomRevenue.map(item => item.Company))]
            let index = companies.indexOf('GMA Matador')
            if (index !== -1) {
                companies.splice(index, 1)
            }
            //ecom indsutries
            let avgGrowth = 0
            let allCompanyInfo = []
            let info = {}
            if (localStorage.cashFlowModel) {
                info = JSON.parse(localStorage.cashFlowModel)
                if (info.companyInfo) {
                    allCompanyInfo = info.companyInfo
                }
                
            } else {
                allCompanyInfo = this.companies
            }

            for (let i = 0; i < companies.length; i++) {
                let companyInfo = allCompanyInfo.find(item => item.company === companies[i])
                    
                if (Object.keys(this.companyGrowthAverages).includes(companies[i])) {
                    avgGrowth = this.companyGrowthAverages[companies[i]].average * 100
                    avgGrowth = Math.round(avgGrowth * 100) / 100
                } else {
                    avgGrowth = 0
                }
                
                let growth = (companyInfo === undefined) ? 10 : companyInfo.growth
                let replaceOutliers = (companyInfo === undefined) ? true : companyInfo.replaceOutliers
                
                object[i] = {
                    company: companies[i], 
                    data: this.ecomRevenue.filter(item => item.Company === companies[i]),
                    growth: growth,
                    avgGrowth: avgGrowth,
                    replaceOutliers: replaceOutliers,
                    average: this.getBoxValues(this.ecomRevenue.filter(item => item.Company === companies[i]).map(item => item.Value)).average
                }
            }
            localStorage.cashFlowModel = JSON.stringify(info)
            let futureWholesaleData = []
            let tempWeek = this.currentWeek
            let tempYear = this.currentYear
            for (let i = 0; i < this.lookForward + 1; i++) {
                if (tempWeek == 53) {
                    tempYear++
                    tempWeek = 1
                } else {
                    tempWeek++
                }
                let weekString = (tempWeek < 10) ? "0" + tempWeek : tempWeek
                let tempDate = String(tempYear) + '-' + String(weekString)

                let futureData = this.wholesaleData.futureData.find(item => item.Created_Date === tempDate)
                if (futureData) {
                    futureWholesaleData.push(futureData)
                } else {
                    futureWholesaleData.push({
                        Company: 'Wholesale',
                        Created_Date: tempDate,
                        Value: null
                    })
                }
            }

            let weeklyExpenseGrowth = 10
            let weeklyExpenseReplaceOutliers = true
            let payrollGrowth = 10
            let payrollReplaceOutliers = true
            let inventoryGrowth = 10
            let inventoryReplaceOutliers = true
            let wholesaleGrowth = 10
            let wholesaleReplaceOutliers = true

            if (allCompanyInfo.length) {
                let weeklyExpenseDetails = allCompanyInfo.find(item => item.company === 'Weekly Expense')
                weeklyExpenseGrowth = (weeklyExpenseDetails !== undefined) ? weeklyExpenseDetails.growth : 10
                weeklyExpenseReplaceOutliers = (weeklyExpenseDetails !== undefined) ? weeklyExpenseDetails.replaceOutliers : true
                
                let payrollDetails = allCompanyInfo.find(item => item.company === 'Payroll')
                payrollGrowth = (payrollDetails !== undefined) ? payrollDetails.growth : 10
                payrollReplaceOutliers = (payrollDetails !== undefined) ? payrollDetails.replaceOutliers : true

                let inventoryDetails = allCompanyInfo.find(item => item.company === 'Inventory Purchases')
                inventoryGrowth = (inventoryDetails !== undefined) ? inventoryDetails.growth : 10
                inventoryReplaceOutliers = (inventoryDetails !== undefined) ? inventoryDetails.replaceOutliers : true

                let wholesaleDetails = allCompanyInfo.find(item => item.company === 'Wholesale')
                wholesaleGrowth = (wholesaleDetails !== undefined) ? wholesaleDetails.growth : 10
                wholesaleReplaceOutliers = (wholesaleDetails !== undefined) ? wholesaleDetails.replaceOutliers : true

            }
            let historicalCopy = JSON.parse(JSON.stringify(this.wholesaleData.historicalData))
            let futureCopy = JSON.parse(JSON.stringify(futureWholesaleData))
            let wholesaleData = historicalCopy.reverse().concat(futureCopy)
            object.push(
                {
                    company: 'Weekly Expense',
                    data: this.weeklyExpenses,
                    growth: weeklyExpenseGrowth,
                    replaceOutliers: weeklyExpenseReplaceOutliers,
                    average: this.getBoxValues(this.weeklyExpenses.map(item => item.Value)).average
                },
                {
                    company: 'Payroll',
                    data: this.payrollExpenses,
                    growth: payrollGrowth,
                    replaceOutliers: payrollReplaceOutliers,
                    average: this.getBoxValues(this.payrollExpenses.map(item => item.Value).filter(item => item !== 0)).average
                },
                {
                    company: 'Inventory Purchase',
                    data: this.inventoryPurchases,
                    growth: inventoryGrowth,
                    replaceOutliers: inventoryReplaceOutliers,
                    average: this.getBoxValues(this.inventoryPurchases.map(item => item.Value)).average
                },
                {
                    company: 'Wholesale',
                    data: wholesaleData,
                    growth: wholesaleGrowth,
                    replaceOutliers: wholesaleReplaceOutliers,
                    average: this.getBoxValues(wholesaleData.map(item => item.Value)).average
                }
            )
            this.companies = object
        },
        async deleteLargeExpense(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CashFlowModel_DeleteLargeExpense?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/cashflowmodel/largeexpense',
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: id
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                //find large expense in existing data
                let index = this.largeExpenses.indexOf(this.largeExpenses.find(item => item.Id === id))
                //delete the object in the array using the index from the previous line
                this.largeExpenses.splice(index,1)
                //stop loading
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async submitExpense() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/cashflowmodel/largeexpense',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            date: this.expense.date,
                            description: this.expense.description,
                            value: this.expense.value
                        })
                    }
                )
                const {
                    message,
                    largeExpenses
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.largeExpenses = largeExpenses
                this.expense.dialog = false
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async editWeeklyExpense(date, value) {
            //split date into year and week parts
            let formatedDate = date.split('-')
            let year = formatedDate[0]
            let week = formatedDate[1]
            //convert week to day of year
            let weekToDay = (1 + (week - 1) * 7) + 1
            //create date from day of year
            let d = new Date(year, 0, weekToDay)
            //create string for MySQL date
            d = d.toISOString().split('T')[0]
            //set body for MySQL update
            let body = {
                date: d,
                value: value
            }
            //call edit expense 
            await this.editExpense(body)

            //hide menu 
            //not sure if this works because it is editing a computed element which is a no no
            this.tableFormattedData.find(item => item.Created_Date === date).Weekly_Expense_Menu = false 
        },
        async editLargeExpense(id, description, value, date) {
            this.snack('Loading...','info',-1)
            let body = {
                id: id,
                description: description,
                value: value
            }
            await this.editExpense(body)
            this.tableFormattedData.find(item => item.Created_Date === date).Large_Expense_Menu = false 
        },
        async editExpense(body) {
            try {
                this.editExpenseLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CashFlowModel_CreateExpense?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/cashflowmodel/largeexpense',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body)
                    }
                )
                const {
                    message,
                    weeklyExpenses
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                if (weeklyExpenses) {
                    this.weeklyExpenses = weeklyExpenses
                }
                
                this.editExpenseLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.editExpenseLoading = false
                this.snack(error,'error',3000)
            }
        },
        async parseExpenses() {
            try {
                let startDate = new Date(this.expenseStartDate)
                let endDate = new Date(this.expenseStartDate)
                endDate.setDate(startDate.getDate() + 6)
                endDate = endDate.toISOString()
                endDate = endDate.split('T')[0]
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    `https://hook.us1.make.com/2xd64jkhafu75aglww4yq1ytqthpr7t8`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            startDate: this.expenseStartDate,
                            endDate: endDate
                        })
                    }
                )
            
                if(!result.ok) {
                    throw result
                }
            
                this.loading = false
                this.snack('Report Refreshed. Please Wait...','info',3000)
                await this.getInfo()
                await this.setCompanies()
                this.parseExpenseMenu = false
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async parsePayments() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    `?email=${this.store.getters.getUserEmail}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        updateLocalStorage() {
            if (this.companies.length) {
                let temp = {
                    lookForward: this.lookForward,
                    overdueLookback: this.overdueLookback,
                    companyInfo: [],
                    useForecast: this.useForecast,
                    version: this.version
                }
                for (let i = 0; i < this.companies.length; i++) {
                    let company = {
                        company: this.companies[i].company,
                        growth: Number(this.companies[i].growth),
                        replaceOutliers: this.companies[i].replaceOutliers
                    }
                    temp.companyInfo.push(company)
                }
                localStorage.cashFlowModel = JSON.stringify(temp)
            } else {
                let obj = JSON.parse(localStorage.cashFlowModel)
                obj.lookForward = this.lookForward
                localStorage.cashFlowModel = JSON.stringify(obj)
            }
        },
        cellClass(actual) {
            return actual ? '' : 'orange--text'
        },
        getEquation(company) {
            let set = this.chartDataSets.find(item => item.title === company) 
            let equation = set.equation
            return "Y = " + equation.m + " x + " + equation.b
        },
        rowClass(date) {
            let d = new Date()
            let startDate = new Date(d.getFullYear(), 0, 1)
            
            let first = d.getDate() - d.getDay() + 1
            let currentMonday = new Date(d.setDate(first))
            let days = Math.floor((currentMonday - startDate) / (24 * 60 * 60 * 1000))
            let currentWeek = Math.ceil(days / 7)
            let currentYear = currentMonday.getFullYear()
            let year = date.split('-')[0]
            let week = date.split('-')[1]
            if (currentWeek == week && currentYear == year) {
                return 'green--text d-flex'
            } else {
                return ''
            }
        },
        regression(values_x,values_y) {
            if (values_x.length < 2 && values_y.length < 2) {
                return {
                    values: [],
                    equation: {
                        m: 0,
                        b: 0
                    }
                }
            }
            let sum_x = 0
            let sum_y = 0
            let sum_xy = 0
            let sum_xx = 0
            let count = 0
            let x = 0
            let y = 0
            let length = values_x.length

            for (let i = 0; i < length; i++) {
                x = Number(values_x[i])
                y = Number(values_y[i])
                sum_x += x
                sum_y += y
                sum_xx += x*x
                sum_xy += x*y
                count++
            }

            //calculate y = mx + b
            let m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x)
            let b = (sum_y / count) - (m * sum_x) / count

            let result_x = []
            let result_y = []

            for (let i = 0; i < length; i++) {
                x = values_x[i]
                y = x * m + b
                result_x.push(x)
                result_y.push(y)
            }
            let values = [result_x, result_y]
            let equation = {
                m: Math.round(m * 100) / 100,
                b: Math.round(b * 100) / 100
            }
            return {
                values: values,
                equation: equation
            }
        },
        openCharts() {
            this.chartDialog = true
            this.$nextTick(() => {
                this.refresh += 1
            })
        },
        getBoxValues(data) {
            var boxValues = {};
            boxValues.low = Math.min.apply(Math,data);
            boxValues.q1 = this.getPercentile(data, 25);
            boxValues.median = this.getPercentile(data, 50);
            boxValues.average = Math.round(data.reduce((record, sum) => {
                return record + sum
            },0) / data.length * 100) / 100
            boxValues.q3 = this.getPercentile(data, 75);
            boxValues.high = Math.max.apply(Math,data);
            boxValues.iqr = boxValues.q3 - boxValues.q1
            boxValues.lowerFence = boxValues.q1 - (boxValues.iqr * 1.5)
            boxValues.upperFence = boxValues.q3 + (boxValues.iqr * 1.5)
            return boxValues;
        },
        getPercentile(data, percentile) {
            data.sort(this.numSort);
            var index = (percentile/100) * data.length;
            var result;
            if (Math.floor(index) == index) {
                result = (data[(index-1)] + data[index])/2;
            }
            else {
                result = data[Math.floor(index)];
            }
            return result;
        },
        numSort(a,b) { 
            return a - b; 
        },
        showLargeExpenses(date) {
            this.largeExpenseDetails = this.largeExpenses.filter(item => item.Created_Date === date)
        },
        getPreviousYearValue(company, date) {
            let year = Number(date.split('-')[0])
            let week = date.split('-')[1]
            let prevYear = String(year - 1) + "-" + String(week)
            let dataset = this.normalizedData.find(item => item.company === company)
            let value = dataset.data.find(item => item.Created_Date === prevYear)
            if (value){
                return value.Value
            } else {
                return 0
            }
        },
        getGrowthRate(company) {
            return this.companies.find(item => item.company === company).growth
        },
        getAvgGrowthRateMessage(growthRate) {
            if (growthRate === undefined) {
                return 'Growth Rate Unknown'
            } else {
                return 'Avg YoY Growth: ' + growthRate + '%'
            }
        },
        getISOWeekNumber(date) {
            let target = new Date(date.valueOf());
            let dayNr = (date.getDay() + 6) % 7;
            target.setDate(target.getDate() - dayNr + 3);
            let firstThursday = target.valueOf();
            target.setMonth(0, 1);
            if (target.getDay() !== 4) {
                target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
            }
            return 1 + Math.ceil((firstThursday - target) / 604800000);
        },
        deltaClass(value) {
            if (value < 0) {
                return 'red--text'
            } else {
                return 'green--text'
            }
        },
        async updateBankForecast() {
            try {
                if (this.bankData.length) {
                    this.loading = true
                    const result = await fetch(
                        //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CashFlowModel_UpdateBankData?tab=code
                        `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/cashflowmodel/forecast?email=${this.$store.getters.getUserEmail}`,
                        {
                            method: 'POST',
                            Headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                bankInfo: this.bankData
                            })
                        }
                    )
                    const {
                        message,
                        bankData
                    } = await result.json()
                
                    if(!result.ok) {
                        throw message
                    }
                    
                    this.bankBalanceData = bankData
                    this.loading = false
                    this.saveDialog = false
                    this.snack(message,'success',3000)
                }
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        allowed() {
            if (this.$store.getters.getUsername === 'Sandbox Guest') {
                return true
            } else {
                return false
            }
        },
        disableExpense() {
            if (!this.incorrectExpenseDate || !this.expense.description.length || !this.expense.value) {
                return true
            } else {
                return false
            }
        },
        incorrectExpenseDate() {
            if (!this.expense.date.length) {
                return false
            } else if (this.expense.date.match(/^\d{4}-\d{2}-\d{2}$/)) {
                let date = this.expense.date.split('-')
                let year = date[0]
                let month = Number(date [1])
                let day = Number(date[2])

                if (year.substring(0,2) === "20" && month > 0 && month <= 12 && day > 0 && day <= 31) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },
        previousData() {
            let data = []
            //get all unique dates 
            if (this.ecomRevenue.length) {
                let allRevenues = this.ecomRevenue.concat(this.wholesaleData.historicalData)
                let dates = [... new Set(allRevenues.map(item => item.Created_Date))]
                let allCompanies = [... new Set(allRevenues.map(item => item.Company))]
                let GMAIndex = allCompanies.indexOf('GMA Matador')
                if (GMAIndex !== -1) {
                    allCompanies.splice(GMAIndex,1)
                }
                let d = new Date()
                let startDate = new Date(d.getFullYear(), 0, 0)
                let day = Math.floor((d - startDate) / (1000 * 60 * 60 * 24))
                let thisYear = d.getFullYear()
                let max = Math.ceil(day / 7)
                // let maxPossible = 52
                //add leading zero to the max week if it is under 10 else max
                max = (max < 10) ? "0" + String(max): max
                let currentConcatDate = Number(String(thisYear).concat(max))
                //list through dates to get historical data
                for (let i = 0; i < dates.length; i++) {
                    let temp = {
                        Created_Date: dates[i],
                        Total_Revenue: {
                            Value: 0,
                            Actual: true
                        },
                        Total_Expense: {
                            Value: 0,
                            Actual: true
                        },
                        Large_Expense_Menu: false,
                        Weekly_Expense_Menu: false
                    }
                    let thisConcatDate = Number(dates[i].replace("-",""))
                    //if the date is historical then input the historical data
                    if (thisConcatDate <= currentConcatDate) {
                        let tempRevenue = allRevenues.filter(item => item.Created_Date === dates[i])
                        for (let c = 0; c < allCompanies.length; c++) {
                            let companyObject = tempRevenue.find(item => item.Company === allCompanies[c])
                            let companyRevenue = 0
                            if (companyObject) {
                                companyRevenue = companyObject.Value
                            }
                            temp[allCompanies[c]] = {
                                Value: companyRevenue,
                                Actual: true
                            }
                            temp.Total_Revenue.Value += companyRevenue
                        }
                        if (thisConcatDate === currentConcatDate) {
                            temp.Bank_Balance = this.bankBalance
                        }
                        data.push(temp)
                    }

                    //add large expenses
                    let largeExpense = this.largeExpenses.reduce((sum, record) => {
                        if (record.Created_Date === dates[i]) {
                            return sum + record.Value
                        } else {
                            return sum
                        }
                    }, 0)
                    temp['Large Expense'] = {
                        Value: largeExpense,
                        Actual: true
                    }
                    //add weekly expense
                    let Weekly_Expense = this.weeklyExpenses.find(item => item.Created_Date === dates[i])
                    if (Weekly_Expense) {
                        temp['Weekly Expense'] = {
                            Value: Weekly_Expense.Value,
                            Actual: true
                        }
                    } else {
                        temp['Weekly Expense'] = {
                            Value: 0,
                            Actual: true
                        }
                    }

                    //add Payroll
                    let payroll = this.payrollExpenses.find(item => item.Created_Date === dates[i])
                    if (payroll) {
                        temp.Payroll = {
                            Value: payroll.Value,
                            Actual: true
                        }
                    } else {
                        temp.Payroll = {
                            Value: 0,
                            Actual: true
                        }
                    }

                    //add inventory purchases
                    let inventoryPurchases = this.inventoryPurchases.reduce((sum, record) => {
                        if (record.Created_Date === dates[i]) {
                            return sum + record.Value
                        } else {
                            return sum
                        }
                    },0)

                    temp['Inventory Purchase'] = {
                        Value: inventoryPurchases,
                        Actual: true
                    }

                    //input expense totals
                    temp.Total_Expense.Value = Number(temp['Large Expense'].Value) + Number(temp['Weekly Expense'].Value) + Number(temp['Inventory Purchase'].Value)
                }
            }
            return data
        },
        tableFormattedData() {
            let data = []
            //get all unique dates 
            if (this.ecomRevenue.length) {
                let allRevenues = this.ecomRevenue.concat(this.wholesaleData.historicalData)
                let dates = [... new Set(allRevenues.map(item => item.Created_Date))]
                let allCompanies = [... new Set(allRevenues.map(item => item.Company))]
                let GMAIndex = allCompanies.indexOf('GMA Matador')
                if (GMAIndex !== -1) {
                    allCompanies.splice(GMAIndex,1)
                }
                let unassignedIndex = allCompanies.indexOf('Unassigned')
                if (unassignedIndex !== -1) {
                    allCompanies.splice(unassignedIndex,1)
                }
                let d = new Date()
                let startDate = new Date(d.getFullYear(), 0, 0)
                let day = Math.floor((d - startDate) / (1000 * 60 * 60 * 24))
                let thisYear = d.getFullYear()
                let max = Math.ceil(day / 7)
                let maxPossible = 52
                //add leading zero to the max week if it is under 10 else max
                max = (max < 10) ? "0" + String(max): max
                let currentConcatDate = Number(String(thisYear).concat(max))

                //const currentDate = new Date();
                //const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
                //const daysInWeek = 7;

                // Calculate days left until the end of the week (Sunday, 6 - currentDay)
                //const daysLeft = (currentDay === 0 ? daysInWeek : daysInWeek - currentDay);

                // Calculate the portion left
                //const portionLeft = (daysLeft / daysInWeek);

                //list through dates to get historical data
                for (let i = 0; i < dates.length; i++) {
                    let temp = {
                        Created_Date: dates[i],
                        Total_Revenue: {
                            Value: 0,
                            Actual: true
                        },
                        Total_Expense: {
                            Value: 0,
                            Actual: true
                        },
                        Large_Expense_Menu: false,
                        Weekly_Expense_Menu: false
                    }
                    let thisConcatDate = Number(dates[i].replace("-",""))
                    //if the date is historical then input the historical data
                    if (thisConcatDate < currentConcatDate) {
                        //let tempRevenue = allRevenues.filter(item => item.Created_Date === dates[i])
                        for (let c = 0; c < allCompanies.length; c++) {
                            //let companyObject = tempRevenue.find(item => item.Company === allCompanies[c])
                            let companyObject = this.normalizedData.find(item =>
                                item.company === allCompanies[c]
                            )
                            let companyData = {}
                            if (companyObject !== undefined) {
                                companyData = companyObject.data.find(item =>
                                    item.Created_Date == dates[i]
                                )
                            }
                            
                            let companyRevenue = 0
                            if (companyData) {
                                companyRevenue = companyData.Value
                            }
                            temp[allCompanies[c]] = {
                                Value: companyRevenue,
                                Actual: true
                            }
                            temp.Total_Revenue.Value += companyRevenue
                        }
                        if (thisConcatDate === currentConcatDate - 1) {
                            temp.Bank_Balance = this.bankBalance
                        }
                        data.push(temp)
                    } 

                    //add large expenses
                    let largeExpense = this.largeExpenses.reduce((sum, record) => {
                        if (record.Created_Date === dates[i]) {
                            return sum + record.Value
                        } else {
                            return sum
                        }
                    }, 0)
                    temp['Large Expense'] = {
                        Value: largeExpense,
                        Actual: true
                    }
                    //add weekly expense
                    //let Weekly_Expense = this.weeklyExpenses.find(item => item.Created_Date === dates[i])
                    let Weekly_Expense_Company = this.normalizedData.find(item => 
                        item.company === 'Weekly Expense'
                    )
                    let Weekly_Expense = {}
                    if (Weekly_Expense_Company !== undefined) {
                        Weekly_Expense = Weekly_Expense_Company.data.find(item => 
                            item.Created_Date === dates[i]
                        )
                    }
                    
                    if (Weekly_Expense) {
                        temp['Weekly Expense'] = {
                            Value: Weekly_Expense.Value,
                            Actual: true
                        }
                    } else {
                        temp['Weekly Expense'] = {
                            Value: 0,
                            Actual: true
                        }
                    }

                    //add Payroll
                    let payroll = this.payrollExpenses.find(item => item.Created_Date === dates[i])
                    if (payroll) {
                        temp.Payroll = {
                            Value: payroll.Value,
                            Actual: true
                        }
                    } else {
                        temp.Payroll = {
                            Value: 0,
                            Actual: true
                        }
                    }

                    //add inventory purchases
                    let inventoryPurchases = this.inventoryPurchases.reduce((sum, record) => {
                        if (record.Created_Date === dates[i]) {
                            return sum + record.Value
                        } else {
                            return sum
                        }
                    },0)

                    temp['Inventory Purchase'] = {
                        Value: inventoryPurchases,
                        Actual: true
                    }

                    //input expense totals
                    temp.Total_Expense.Value = Number(temp['Large Expense'].Value) + Number(temp['Weekly Expense'].Value) + Number(temp['Inventory Purchase'].Value)
                }
                //set index equal to the length of the current data to start inputting
                //data after the most recent row
                let index = data.length
                //set a counter for the current look forward date that the loop is on
                //starting at the max range of the current dates
                let lookForwardDate = Number(data[index - 1].Created_Date.split("-")[1])
                let lookForwardYear = Number(data[index - 1].Created_Date.split("-")[0])

                //start the forecasted rows
                let currentBankBalance = Number(this.bankBalance)
                //find inventory purchase growth rate
                let inventoryPurchaseGrowthObj = this.companies.find(item => item.company === 'Inventory Purchase')
                let inventoryPurchaseGrowth = (inventoryPurchaseGrowthObj === undefined) ? 0 : inventoryPurchaseGrowthObj.growth

                //list through the number of look forward months inputted by the user
                for (let i = 0; i < Number(this.lookForward) + 1; i++) {
                    let prevWeekString = (lookForwardDate < 10) ? String(lookForwardYear - 1) + "-0" + lookForwardDate : String(lookForwardYear - 1) + "-" + lookForwardDate
                    
                    //increment the index  amd current look forward date 1
                    index++
                    lookForwardDate++
                    //increment the look forward year if the current look forward date is beyond the possible
                    if (lookForwardDate > maxPossible) {
                        lookForwardDate = 1
                        lookForwardYear++
                    }
                    //set the date to show the user
                    let lookForwardDateString = (lookForwardDate < 10) ? "0" + lookForwardDate : lookForwardDate
                    let date = String(lookForwardYear) + '-' + String(lookForwardDateString)
                    let monthDate = new Date(lookForwardYear, 0, lookForwardDate * 7)
                    
                    let month = monthDate.getMonth() + 1

                    let nextWeek = lookForwardDate + 1
                    let nextWeekString = ""
                    if (nextWeek > maxPossible) {
                        nextWeekString = String(lookForwardYear) + "-01"
                     } else {
                        nextWeekString = (nextWeek < 10) ? String(lookForwardYear - 1) + "-0" + nextWeek : String(lookForwardYear - 1) + "-" + nextWeek
                    }
                    let lastYearDateString = String(lookForwardYear - 1) + '-' + String(lookForwardDateString)
                    //start the temp object for the table
                    let temp = {
                        Created_Date: date
                    }
                    let previousYear = String(lookForwardYear - 1) + '-' + String(lookForwardDateString)
                    
                    //find future values for large expenses
                    let value = this.largeExpenses.reduce((sum, record) => {
                        if (record.Created_Date === date) {
                            return sum + record.Value
                        } else {
                            return sum
                        }
                    }, 0)
                    temp['Large Expense'] = {
                        Value: value,
                        Actual: false
                    }
                    
                    //look to see if any purchases have been made in the month and year that the current
                    //lookForward date is 
                    //if there are any purchases for that month then look to see if that purchase was made
                    //on the current week 
                    //If made on that week insert that value as an 'Actual' value and mark boolean as true
                    //else assign a 0 value
                    //if there are NOT any purchases for that month then look at the previous years week and
                    //add the growth rate to that value for that week

                    //inventory purchases
                    let prevInventoryPurchases = 0
                    
                    //find if there are inventory purchases made in this month
                    //this should return true for multiple weeks when a purchase has been made in the month
                    let monthlyInventoryPurchase = this.inventoryPurchases.find(item => 
                        item.Month === String(month) && 
                        Number(item.Created_Date.split('-')[0]) === lookForwardYear
                    )
                    if (monthlyInventoryPurchase) {
                        //see if the purchase was made on this week
                        //this should only return true for one week per month
                        let inventoryPurchaseValue = this.inventoryPurchases.find(item => item.Created_Date === date)
                        if (inventoryPurchaseValue) {
                            temp['Inventory Purchase'] = {
                                Value: inventoryPurchaseValue.Value,
                                Actual: true
                            }
                        //if not found then assign value as 0
                        } else {
                            temp['Inventory Purchase'] = {
                                Value: 0,
                                Actual: true
                            }
                        }
                    //if there is NOT an inventory purchase for this month
                    //should meet condition multiple weeks per month
                    } else {
                        //find sum of inventory purchase for the current look foward week
                        prevInventoryPurchases = this.inventoryPurchases.reduce((sum, record) => {
                            if (record.Created_Date === String(lookForwardYear - 1) + "-" + String(lookForwardDateString)) {
                                return sum + record.Value
                            } else {
                                return sum
                            }
                        }, 0)
                        //multiply by the growth rate
                        prevInventoryPurchases *= (1 + (inventoryPurchaseGrowth / 100))
                        temp['Inventory Purchase'] = {
                            Value: prevInventoryPurchases,
                            Actual: false
                        }
                    }

                    //list through the equations to add key value pairs to the temp object just created
                    for (let r = 0; r < this.normalizedData.length; r++) {
                        let inputValue = 0
                        let previousYearData = {}
                        if (this.normalizedData[r].company === 'Payroll'){
                            if (data[data.length - 1].Payroll.Value < this.payrollAverage) {
                                previousYearData = this.normalizedData[r].data.find(item => 
                                    (item.Created_Date === lastYearDateString && item.Value !== 0) ||
                                    (item.Created_Date === prevWeekString && item.Value !== 0) ||
                                    (item.Created_Date === nextWeekString && item.Value !== 0)
                                )
                            } else {
                                previousYearData = {
                                    Value: 0
                                }
                            }
                        } else {
                                previousYearData = this.normalizedData[r].data.find(item => item.Created_Date === previousYear)
                        }

                        if (previousYearData !== undefined && previousYearData !== null && !this.useForecast && Object.keys(previousYearData).length > 0 ) {
                            inputValue = Math.round(previousYearData.Value * (1 + (this.normalizedData[r].growth / 100)) * 100) / 100
                        } else if (!this.useForecast) {
                            let equations = this.chartDataSets.find(item => item.title === this.normalizedData[r].company).equation
                            console.log({equations})
                            inputValue = equations.m * index + equations.b
                        }
                        if (inputValue < 0) {
                            inputValue = this.companies.find(item => item.company === this.normalizedData[r].company).average
                        }
                        
                        let excludedCompanies = ['Large Expenses','Inventory Purchase', 'Wholesale']
                        if (!excludedCompanies.includes(this.normalizedData[r].company)) {
                            if (this.useForecast && this.normalizedData[r].company in this.forecast) {
                                inputValue = this.forecast[this.normalizedData[r].company][date]
                            }
                            temp[this.normalizedData[r].company] = {
                                Value: inputValue,
                                Actual: false
                            }

                        // if the column is wholesale then
                        } else if (this.normalizedData[r].company === 'Wholesale'){
                            //find actual data
                            if (this.useForecast && this.normalizedData[r].company in this.forecast) {
                                inputValue = this.forecast[this.normalizedData[r].company][date]
                            }
                            let actualValue = this.normalizedData[r].data.find(item => item.Created_Date === date)
                            let actualValueBoolean = (actualValue !== undefined && actualValue !== null && actualValue.Value > inputValue)

                            temp['Wholesale'] = {
                                Value: (actualValueBoolean) ? actualValue.Value : inputValue,
                                Actual: actualValueBoolean
                            }
                        }
                    }

                    let totalDateRevenue = 0
                    let totalDateExpense = 0
                    //set keys that should be subtracted (expenses)
                    let expenseKeys = ['Weekly Expense','Payroll','Large Expense','Inventory Purchase']

                    //list through all keys in the temporary object
                    for (let keys in temp) {
                        //if the key is not created date and not included in the expense keys then add to total revenue
                        if (keys !== 'Created_Date' && !expenseKeys.includes(keys)) {
                            totalDateRevenue += temp[keys].Value
                        //else if the key is not created date then add to total expenses
                        } else if (keys !== 'Created_Date') {
                            totalDateExpense += temp[keys].Value
                        }
                    }
                    //increment bank balance with total revenue
                    currentBankBalance += totalDateRevenue
                    //decrement bank balance with total expense
                    currentBankBalance -= totalDateExpense
                    //set bank balance
                    temp.Bank_Balance = currentBankBalance
                    //set total expense
                    temp.Total_Expense = {
                        Value: totalDateExpense,
                        Actual: false
                    }
                    //set total revenue
                    temp.Total_Revenue = {
                        Value: totalDateRevenue,
                        Actual: false
                    }
                    data.push(temp)
                }
            }
            //reverse the data so that the most recent date is at the top
            data.reverse()
            return data
        },
        bankData() {
            return this.tableFormattedData.reduce((acc, item, index) => {
                let nextWeek = this.currentDateFormatted.split('-')[1]
                let year = this.currentDateFormatted.split('-')[0]
                nextWeek = Number(nextWeek) + 1
                if (nextWeek === 53) {
                    nextWeek = 1
                    year = Number(year) + 1
                }
                let nextWeekFormatted = (nextWeek < 10) ? year + '-0' + nextWeek : year + '-' + nextWeek
                if (item.Created_Date >= nextWeekFormatted && item.Bank_Balance) {
                    acc.push({
                        Submit_Date: new Date().toISOString().split('T')[0],
                        Weeks_Out: this.lookForward - index,
                        Week: item.Created_Date,
                        Forecast: item.Bank_Balance,
                    });
                }
                return acc;
            }, []);
        },
        normalizedData() {
            let tempCompanyRevenues = []
            let companies = this.companies

            // list through all the difference columns within the companies array
            for (let s = 0; s < companies.length; s++) {
                //set the temp obj with company name and growth rate
                let temp = {
                    company: companies[s].company,
                    growth: companies[s].growth
                }
                let data = []

                //get the respective boxValues 
                let boxValues = {}
                //if the company is not Payroll just use all the values
                //if the comapny is payroll remove the zeros as those should not be used to find the average
                if (companies[s].company !== 'Payroll') {
                    boxValues = this.getBoxValues(companies[s].data.map(item => item.Value))
                } else {
                    boxValues = this.getBoxValues(companies[s].data.filter(item => item.Value !== 0).map(item => item.Value))
                }
                
                //list through each of the companies data for each week
                for (let i = 0; i < companies[s].data.length; i++) {

                    //set the company name based on if the data has a company name else use another attribute
                    let company = ''
                    if (companies[s].data[i].Company) {
                        company = companies[s].data[i].Company
                    } else {
                        company = companies[s].company
                    }

                    //IF the datas weekly revenue value is greater than the upper fence of the normalized box values 
                    //OR the value is less than the lower fence of the normalized box values
                    //THEN use the average value 
                    //ELSE use the actual value
                    let useAverageBoolean = (
                        (
                            companies[s].data[i].Value > boxValues.upperFence || 
                            companies[s].data[i].Value < boxValues.lowerFence
                        ) && 
                        //dont normalize data that is in the future as that is actual data
                        Number(companies[s].data[i].Created_Date.replace("-","")) < Number(this.currentDateFormatted.replace("-","")) &&
                        companies[s].replaceOutliers
                    )
                    data.push({
                        Company: company,
                        Created_Date: companies[s].data[i].Created_Date,
                        Value: (useAverageBoolean) ? boxValues.average: companies[s].data[i].Value
                    })
                }
                //push that data collected from the data of each company/column to the company
                temp.data = data
                //push the temp object into the company revenues array
                tempCompanyRevenues.push(temp)
            }
            return tempCompanyRevenues
        },
        saveForecastWarning() {
            let lastSavedWeek = this.bankBalanceData[this.bankBalanceData.length - 1].Week
            if (lastSavedWeek <= this.currentDateFormatted) {
                return true
            } else {
                return false
            }
        },
        bankBalanceChartData() {
            let groupBySubmitDate = {};

            // Group data by Submit_Date
            this.bankBalanceData.forEach(item => {
                if (!groupBySubmitDate[item.Submit_Date]) {
                    groupBySubmitDate[item.Submit_Date] = [];
                }
                groupBySubmitDate[item.Submit_Date].push(item);
            });

            let allDataSets = [];
            let actualData = [];
            let labels = [... new Set(this.bankBalanceData.map(item => item.Week))];
            labels.sort();
            console.log({labels})
            for (let i = 0; i < labels.length; i++) {
                if (this.bankBalanceData.find(item => item.Week === labels[i])) {
                    actualData.push({
                        x: labels[i],
                        y: this.bankBalanceData.find(item => item.Week === labels[i]).Actual
                    });
                }
            }
            for (const [submitDate, data] of Object.entries(groupBySubmitDate)) {
                let predictedData = [];
                for (let i = 0; i < labels.length; i++) {
                    let value = data.find(item => item.Week === labels[i])
                    if (value) {
                        predictedData.push({
                            x: labels[i],
                            y: value.Forecast
                        });
                    }
                }
                
                let r = Math.floor(Math.random() * 256);
                let g = Math.floor(Math.random() * 256);
                let b = Math.floor(Math.random() * 256);

                allDataSets.push({
                    type: 'line',
                    borderColor: `rgb(${r},${g},${b})`,
                    data: predictedData,
                    label: `${submitDate.split('T')[0]} ${predictedData.length} Week Prediction`,
                    fill: false,
                    borderDash: [5, 15]
                });
            }

            allDataSets.push({
                type: 'line',
                backgroundColor: 'rgb(255,0,0)',
                borderColor: 'rgb(255,0,0)',
                data: actualData,
                label: 'Actual Bank Balance',
                fill: false
            });

            return {
                labels: labels,
                datasets: allDataSets
            };
        },
        chartDataSets() {
            let sets = []
            for (let s = 0; s < this.normalizedData.length; s++) {
                let data = this.normalizedData[s].data
                let labels = data.map(item => item.Created_Date)
                let scatterData = []
                for (let i = 0; i < data.length; i++) {
                    scatterData.push({
                        x: i, 
                        y: data[i].Value
                    })
                }

                let y_values = data.map(item => item.Value)
                let x_values = scatterData.map(item => item.x)
                let linearRegression = this.regression(x_values, y_values)
                let linearRegressionEquation = linearRegression.equation
                let linearRegressionValues = linearRegression.values
                let lineData = linearRegressionValues[1]
                sets.push({
                    labels: labels,
                    title: this.normalizedData[s].company,
                    equation: linearRegressionEquation,
                    replaceOutliers: true,
                    datasets: [
                        {
                            type: 'scatter',
                            backgroundColor: 'rgb(255,0,0)',
                            borderColor: 'rgb(255,0,0)',
                            data: scatterData,
                            label: 'Actual Revenue'
                        },
                        {
                            type: 'line',
                            borderColor: 'rgb(0,0,255)',
                            data: lineData,
                            label: 'Regression',
                            fill: false,
                            borderDash: [5,15],
                            pointRadius: 0
                        }
                    ]
                })
            } 
            return sets
        }, 
        lookForwardDate() {
            let year = this.currentYear
            let week = this.currentWeek
            for (let i = 0; i < this.lookForward; i++) {
                if (week === 53) {
                    week = 1
                    year++
                } else {
                    week++
                }
            }
            week = (week < 10) ? "0" + week : week
            return year.toString() + "-" + week.toString()
        },
        lookForwardDateNumber() {
            return Number(this.lookForwardDate.replace('-',''))
        },
        bankDifference() {
            return Math.round((this.tableFormattedData[0].Bank_Balance - this.bankBalance + this.overdueBalance) * 100) / 100
        },
        overdueBalance() {
            let filtered = this.overdueWeeks.slice(0,this.overdueLookback + 1)
            return filtered.reduce((sum, record) => {
                return sum + record.totalOwed
            }, 0)
        },
        allDates() {
            let d = new Date()
            let startDate = new Date(d.getFullYear(), 0, 1)
            
            let first = d.getDate() - d.getDay() + 1
            let currentMonday = new Date(d.setDate(first))
            let days = Math.floor((currentMonday - startDate) / (24 * 60 * 60 * 1000))
            let currentWeek = Math.ceil(days / 7)
            currentWeek = (currentWeek < 10) ? "0" + currentWeek : currentWeek
            let currentYear = currentMonday.getFullYear()
            let date = String(currentYear) + "-" + String(currentWeek)
            let dates = [date]
            for (let i = 0; i < 52; i++) {
                currentWeek = Number(currentWeek) + 1
                currentWeek = (currentWeek < 10) ? "0" + currentWeek : currentWeek
                date = String(currentYear) + "-" + String(currentWeek)
                dates.push(date)
            }
            return(dates)
        },
        tableHeaders() {
            let headers = []
            if (this.tableFormattedData.length && this.showDetails) {
                let companies = Object.keys(this.tableFormattedData[0])
                let exclude = [
                    'Created_Date',
                    'Actual',
                    'Bank_Balance',
                    'Total_Revenue',
                    'Total_Expense',
                    'Large Expense',
                    'Inventory Purchase',
                    'Weekly Expense',
                    'Large_Expense_Menu',
                    'Weekly_Expense_Menu',
                    'Wholesale',
                    'Payroll'
                ]
                for (let i = 0; i < companies.length; i++) {
                    if (!exclude.includes(companies[i])) {
                        headers.push({
                            text: companies[i],
                            align: 'start',
                            sortable: true,
                            value: companies[i]
                        })
                    }
                }
            }
            return headers
        },
        currentWeekDetails() {
            return this.tableFormattedData.find(item => item.Created_Date === this.currentDateFormatted)
        },
        payrollAverage() {
            let sum = this.payrollExpenses.reduce((sum, record) => {
                return sum + record.Value
            },0)
            return sum / this.payrollExpenses.length
        },
        weekStartDates() {
            let dates = [];
            let currentDate = new Date();

            // Find the first Monday of the current week
            let day = currentDate.getDay();
            let diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
            let firstMonday = new Date(currentDate.setDate(diff));

            // Get the year and week number
            let year = firstMonday.getFullYear();
            let weekNumber = this.getISOWeekNumber(firstMonday);

            // Generate week start dates for 52 weeks
            for (let i = 0; i < 52; i++) {
                let formattedWeekNumber = weekNumber.toString().padStart(2, '0');
                dates.push({
                    week: `${year}-${formattedWeekNumber}`,
                    date: firstMonday.toISOString().split('T')[0]
                })
                firstMonday.setDate(firstMonday.getDate() - 7);
                year = firstMonday.getFullYear();
                weekNumber = this.getISOWeekNumber(firstMonday);
            }
            return dates;

        }
    }
}
</script>

<style>
:pic {
    outline-width: medium;
    outline: thin;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}
</style>