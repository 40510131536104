<template>
  <v-card-title class="d-flex justify-center align-center">
        <h3 class="text-center flex-grow-1">
            <u>{{ title }}</u>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn 
                        icon 
                        color="primary"
                        class="mr-auto" 
                        @click="copyLink()" 
                        v-on="on">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </template>
                <span>Copy Link</span>
            </v-tooltip>
        </h3>
        <v-btn icon class="ml-auto" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            :timeout="snackbarTimeout">
            {{ snackbarText }}
        </v-snackbar>
    </v-card-title>
</template>

<script>
export default {
    name: 'Title',
    props: {
        name: String,
        title: String
    },
    data() {
        return {
            snackbar: false,
            snackbarText: '',
            snackbarColor: '',
            snackbarTimeout: 3000
        }
    },
    methods: {
        copyLink() {
            let url = window.location.href
            let copyText = url + '?page=' + this.$props.name
            navigator.clipboard.writeText(copyText);
            this.snack('Link Copied', 'success', 2000)
        },
        snack(text, color, timeout) {
            this.snackbarText = text
            this.snackbarColor = color
            this.snackbarTimeout = timeout
            this.snackbar = true
        }
    }
}
</script>

<style>

</style>