<template>
    <v-app>
        <v-main>
            <!-- Main account page -->
            <template v-if="account['Account ID'] && account.Reviewed_By_Sales === 1">
                <v-row justify="center" align="center" class="px-4 pt-8">
                    <!-- change it so that there is a dialog when you want to change the company name as there is a more complex process that goes into that -->
                    <v-text-field
                        class="centered-input text-h4 px-4"
                        v-model="account.Company"
                        @change="updateAccount('Company','Company',account.Company)"
                        hide-details
                        solo>
                        <template v-slot:append-outer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="primary"
                                        v-on="on"
                                        :loading="refreshLoading"
                                        @click="refreshCin7()"
                                        icon>
                                        <v-icon large color="primary">mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                Refresh From Cin7
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </v-row>
                <v-row justify="center" class="px-4">
                    <v-col cols="4">
                        <v-card class="pa-4" height="100%">
                            <v-select
                                v-model="account.Image"
                                label="Image Selector"
                                :items="imageOptions"
                                item-text="title"
                                item-value="link"
                                @change="updateAccount('Image',null,account.Image)"/>
                            <v-img :src="account.Image" width="25vw" contain>
                                <template v-slot:placeholder>
                                    <span class="subtitle">Select another image option</span>
                                </template>
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card class="pa-4" height="100%">
                            <v-card-title class="d-flex justify-center align-center pt-0">
                                General Information
                            </v-card-title>
                            <v-row justify="space-around" class="pt-2 px-2">
                                <v-col cols="6" class="flex-column">
                                    <v-text-field
                                        label="Billing Address 1"
                                        v-model="account['Billing Address']"
                                        @change="updateAccount('Billing Address','postalAddress1',account['Billing Address'])"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Billing Address 2"
                                        v-model="account.Billing_Address_2"
                                        @change="updateAccount('Billing_Address_2','postalAddress2',account.Billing_Address_2)"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Billing City"
                                        v-model="account['Billing City']"
                                        @change="updateAccount('Billing City','postalCity',account['Billing City'])"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Billing State"
                                        v-model="account['Billing State']"
                                        @change="updateAccount('Billing State','postalState',account['Billing State'])"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Billing Zip Code"
                                        v-model="account['Billing Postal Code']"
                                        @change="updateAccount('Billing Postal Code','postalPostCode',account['Billing Postal Code'])"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Billing Country"
                                        v-model="account['Billing Country']"
                                        @change="updateAccount('Billing Country','postalCountry',account['Billing Country'])"
                                        class="py-2"
                                        hide-details/>
                                </v-col>
                                <v-col cols="6" class="flex-column">
                                    <v-text-field
                                        label="Shipping Address 1"
                                        v-model="account['Delivery Address']"
                                        @change="updateAccount('Delivery Address','address1',account['Delivery Address'])"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Shipping Address 2"
                                        v-model="account.Delivery_Address_2"
                                        @change="updateAccount('Delivery_Address_2','address2',account.Delivery_Address_2)"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Shipping City"
                                        v-model="account['Delivery City']"
                                        @change="updateAccount('Delivery City','city',account['Delivery City'])"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Shipping State"
                                        v-model="account['Delivery State']"
                                        @change="updateAccount('Delivery State','state',account['Delivery State'])"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Shipping Zip Code"
                                        v-model="account['Delivery Postal Code']"
                                        @change="updateAccount('Delivery Postal Code','postCode',account['Delivery Postal Code'])"
                                        class="py-2"
                                        hide-details/>
                                    <v-text-field
                                        label="Shipping Country"
                                        v-model="account['Delivery Country']"
                                        @change="updateAccount('Delivery Country','country',account['Delivery Country'])"
                                        class="py-2"
                                        hide-details/>
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="account.Website"
                                label="Website"
                                class="px-2 pb-2"
                                hide-details
                                @change="updateAccount('Website','Website',account.Website)"/>
                            <v-row justify="space-around" align="center">
                                <v-checkbox
                                    v-model="account['Online Store']"
                                    label="Online Store"
                                    @change="updateAccount('Online Store','members_1004',account['Online Store'],account['Online Store'] ? 'Yes': 'No')"/>
                                <v-switch
                                    label="Active"
                                    class="pl-2"
                                    v-model="account.Active"
                                    @change="updateAccount('Active','isActive',account.Active, account.Active ? 'On' : 'Off')"
                                    color="primary"/>
                            </v-row>
                            <v-row justify="center" align="center">
                                <v-btn
                                    @click="seeAddressCounter++"
                                    color="primary">
                                    Addresses
                                </v-btn>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            @click="copyToClipboard()"
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                            color="primary">
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Copy Store Locator Form Link</span>
                                </v-tooltip>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- Technical Information -->
                    <v-col cols="4" class="px-4">
                        <v-card class="pa-4" height="100%">
                            <v-card-title class="d-flex justify-center align-center pt-0">
                                Technical Information
                            </v-card-title>
                            <v-text-field
                                v-model="account['Billing Email']"
                                label="Billing Email"
                                hide-details
                                @change="updateAccount('Billing Email','BillingEmail',account['Billing Email'])"/>
                            <v-text-field
                                v-model="account['Door Count']"
                                label="Door Count"
                                hide-details="auto"
                                type="number"
                                @change="updateAccount('Door Count','members_1002',account['Door Count'])"/>
                            <v-autocomplete
                                v-model="account['Payment Terms']"
                                label="Payment Terms"
                                :items="paymentTerms"
                                item-text="Payment_Terms"
                                item-value="Payment_Terms"
                                @change="updateAccount('Payment Terms','paymentTerms',account['Payment Terms'])"
                                hide-details/>
                            <v-text-field
                                v-model="account['Shipping_Account']"
                                label="Shipping Account"
                                hide-details
                                @change="updateAccount('Shipping_Account','members_1005',account['Shipping_Account'])"/>
                            <v-text-field
                                v-model="account.Payment_Type"
                                label="Payment Type"
                                @change="updateAccount('Payment_Type',null,account.Payment_Type)"
                                hide-details="auto"
                                readonly/>
                            <v-text-field
                                v-model="account['Balance Owing']"
                                label="Balance Owing"
                                prefix="$"
                                hint="View Only Field"
                                hide-details="auto"
                                readonly/>
                            <v-text-field
                                v-model="account['Account Number']"
                                hint="Id number for the account within the database"
                                label="Account Number"
                                class="pr-2"
                                hide-details
                                readonly/>
                            <v-text-field
                                readonly
                                hide-details
                                label="Cin7 Notes (Only Ops Can Change in Cin7)"
                                v-model="account.Notes"/>
                            <v-text-field
                                hide-details
                                label="Sandbox Notes"
                                @change="updateAccount('Sandbox_Notes',null,account.Sandbox_Notes)"
                                v-model="account.Sandbox_Notes"/>
                            <v-row class="pt-4" align="center" justify="space-around">
                                <v-checkbox
                                    v-model="account.Majority_Online_Business"
                                    label="Majority Online Business"
                                    @change="updateAccount('Majority_Online_Business',null,account.Majority_Online_Business)"/>
                                <v-menu>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on">
                                            Envoy Details
                                        </v-btn>
                                    </template>
                                    <v-card class="pa-4 flex-columns justify-center align-center">
                                        <v-checkbox
                                            v-model="account.Prevent_Envoy_Import"
                                            label="Prevent Envoy Import"
                                            :false-value="0"
                                            :true-value="1"
                                            @change="updateAccount('Prevent_Envoy_Import',null,account.Prevent_Envoy_Import)"/>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-on="on" v-bind="attrs">
                                                    <v-checkbox
                                                        v-model="account.Imported_To_Envoy"
                                                        label="Imported to Envoy"
                                                        :false-value="0"
                                                        :true-value="1"
                                                        @change="updateAccount('Imported_To_Envoy',null,account.Imported_To_Envoy)"/>
                                                </div>
                                            </template>
                                            <span>Should only be used if fixing an Envoy batch error.<br>Otherwise, keep ya grubby paws off...</span>
                                        </v-tooltip>
                                        <v-menu offset-y :close-on-content-click="false" v-model="envoyImportMenu">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="primary"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :disabled="account.Prevent_Envoy_Import === 1">
                                                    Import to Envoy
                                                </v-btn>
                                            </template>
                                            <v-card class="pa-4">
                                                <v-card-title class="d-flex justify-center align-center">
                                                    Are you sure?
                                                </v-card-title>
                                                <v-card-subtitle class="d-flex justify-center align-center">
                                                    This action will attempt to push the account to the Envoy SFTP server AND make the following users.
                                                </v-card-subtitle>
                                                <v-row v-for="(contact, index) in contacts" :key="index" class="px-4" justify="space-around" align="center">
                                                    <v-text-field
                                                        v-model="contact.First_Name"
                                                        label="First Name"
                                                        hide-details
                                                        class="pr-2"
                                                        @change="updateContact(contact.Id, 'First_Name','firstName',contact.First_Name)"/>
                                                    <v-text-field
                                                        v-model="contact.Last_Name"
                                                        label="Last Name"
                                                        class="px-2"
                                                        hide-details
                                                        @change="updateContact(contact.Id, 'Last_Name','lastName', contact.Last_Name)"/>
                                                    <v-text-field
                                                        label="Email"
                                                        v-model="contact.Email"
                                                        @change="updateContact(contact.Id,'Email','email',contact.Email)"
                                                        hide-details/>
                                                </v-row>
                                                <v-card-actions class="d-flex justify-space-around align-center pt-8">
                                                    <v-btn
                                                        color="secondary"
                                                        @click="envoyImportMenu = false">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        color="primary"
                                                        :disabled="contacts.length === 0"
                                                        @click="sendEnvoyCreationRequest()">
                                                        Yes
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                        <v-btn
                                            color="secondary"
                                            @click="openEnvoyPage()">
                                            To Envoy Page
                                        </v-btn>
                                    </v-card>
                                </v-menu>
                            </v-row>
                            <v-row justify="space-around" align="center">
                                <v-btn
                                    :href="'https://go.cin7.com/Cloud/CRM/Contact.aspx?mode=N&idMember=' + mainContactId + '&idWebSite=21518&idCustomerAppsLink=985802'"
                                    target="_blank"
                                    color="accent">
                                    To Cin7
                                </v-btn>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            @click="createCin7Account()"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="primary">
                                            Move to Cin7    
                                        </v-btn>
                                    </template>
                                    <span>Only select this if the account is not in Cin7</span>
                                </v-tooltip>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center" class="pb-4 px-4">
                <!-- Classifications -->
                    <v-col cols="6">
                        <v-card class="pa-4" height="100%">
                            <v-card-title class="d-flex justify-center align-center">
                                Classifications
                            </v-card-title>
                            <v-row>
                                <v-col>
                                    <v-select
                                        label="Channel"
                                        class="pr-2"
                                        :items="channels"
                                        item-value="Id"
                                        item-text="Channel"
                                        hide-details
                                        @change="updateAccount('Channel_Id',null,account.Channel_Id)"
                                        v-model="account.Channel_Id"/>
                                    <v-autocomplete
                                        label="Account Type"
                                        class="pr-2 pt-4"
                                        :items="accountTypes"
                                        item-value="Id"
                                        item-text="Account_Type"
                                        hide-details
                                        @change="updateAccount('Account_Type_Id','members_1001',account.Account_Type_Id,getAccountType(account.Account_Type_Id))"
                                        v-model="account.Account_Type_Id"/>
                                    <v-autocomplete
                                        label="Industry"
                                        class="pr-2"
                                        :items="industries"
                                        item-value="Id"
                                        item-text="Industry"
                                        hide-details
                                        @change="updateAccount('Industry_Id','Group',account.Industry_Id,getIndustry(account.Industry_Id))"
                                        v-model="account.Industry_Id"/>
                                    <v-row>
                                        <v-col cols="9">
                                            <v-autocomplete
                                                label="Forecast Category"
                                                class="pr-2"
                                                :items="forecastCategories"
                                                item-value="Forecast_Id"
                                                item-text="Forecast_Name"
                                                hide-details
                                                @change="updateAccount('Forecast_Id','SubGroup',account.Forecast_Id,getForecastCategory(account.Forecast_Id))"
                                                v-model="account.Forecast_Id"/>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        hide-details
                                                        v-model="account.Forecast_Ratio"
                                                        label="Ratio"/>
                                                </template>
                                                <span class="text-center">
                                                    Percent of forecast allocated to this account within its given category<br>
                                                    (read-only)<br/>
                                                    Account Revenue {{ lastYear }}: {{ accountRevenue | currency }}<br/>
                                                    Forecast Category Revenue {{ lastYear }}: {{ forecastCategoryRevenue | currency }}
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        :items="['2DISCOUNT','5DISCOUNT','10DISCOUNT','15DISCOUNT']"
                                        label="Envoy Discount"
                                        v-model="account.Envoy_Discount"
                                        hide-details
                                        @change="updateAccount('Envoy_Discount','members_1007',account.Envoy_Discount)"/>
                                    <v-autocomplete
                                        v-if="orderedVolcom"
                                        label="Volcom Territory"
                                        :items="volcomTerritories"
                                        item-text="Territory"
                                        item-value="Id"
                                        hide-details
                                        v-model="account.Volcom_Territory"
                                        @change="updateAccount('Volcom_Territory',null,account.Volcom_Territory)"/>
                                </v-col>
                                <v-col>
                                    <v-autocomplete
                                        label="Internal Manager"
                                        class="pr-2"
                                        :items="salesManagers"
                                        item-value="User ID"
                                        item-text="First Name"
                                        hide-details
                                        @change="updateAccount('Sales Rep','SalesPersonId',account['Sales Rep'])"
                                        v-model="account['Sales Rep']">
                                        <template v-slot:selection="{ item }">
                                            {{ item['First Name'] }} {{ item['Last Name']}}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item['First Name'] }} {{ item['Last Name']}}
                                        </template>
                                    </v-autocomplete>
                                    <v-autocomplete
                                        label="Sales Rep"
                                        class="pr-2"
                                        :items="salesReps"
                                        item-value="Id"
                                        item-text="Agency"
                                        hide-details
                                        @change="updateAccount('External_Sales_Rep_Id','members_1000',account.External_Sales_Rep_Id, getSalesRep(account.External_Sales_Rep_Id))"
                                        v-model="account.External_Sales_Rep_Id"/>
                                        <v-autocomplete
                                        label="Primary Price Category"
                                        :items="priceCategories"
                                        item-text="Name"
                                        item-value="Price ID"
                                        hide-details
                                        @change="updateAccount('Price ID',null, account['Price ID'])"
                                        v-model="account['Price ID']"/>
                                    <v-autocomplete
                                        label="Secondary Price Category"
                                        :items="priceCategories"
                                        item-text="Name"
                                        item-value="Price ID"
                                        hide-details
                                        @change="updateAccount('Secondary_Price_Id',null, account['Secondary_Price_Id'])"
                                        v-model="account['Secondary_Price_Id']"/>
                                    <v-autocomplete
                                        label="Cost Category"
                                        :items="costCategories"
                                        item-text="Name"
                                        item-value="Cost ID"
                                        hide-details
                                        @change="updateAccount('Cost ID',null,account['Cost ID'])"
                                        v-model="account['Cost ID']"/>
                                    <v-autocomplete
                                        v-if="orderedVolcom"
                                        label="Volcom Distribution Method"
                                        :items="volcomDistMethods"
                                        item-text="Method"
                                        item-value="Id"
                                        hide-details
                                        @change="updateAccount('Volcom_Distribution_Method',null,account.Volcom_Distribution_Method)"
                                        v-model="account.Volcom_Distribution_Method"/>
                                </v-col>
                            </v-row>
                            <v-row
                                justify="center"
                                align="center" 
                                v-if="orderedVolcom && (account.Volcom_Distribution_Method === 0 || account.Volcom_Territory === 0)">
                                <v-tooltip bottom :disabled="account.Volcom_Territory !== 0">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-on="on">
                                            <v-btn 
                                                color="primary" 
                                                @click="setEmail()" 
                                                :disabled="account.Volcom_Territory === 0"
                                                v-bind="attrs">
                                                Create Email For RoyaltyZone
                                            </v-btn>
                                        </div>
                                    </template>
                                    <span>Select Volcom Territory Before Creating Email</span>
                                </v-tooltip>
                            </v-row>
                            <v-card-subtitle 
                                v-if="orderedVolcom && (account.Volcom_Distribution_Method === 0 || account.Volcom_Territory === 0)"
                                class="text-center orange--text">
                                This account has ordered Volcom products but has not been cleared with RoyaltyZone as a Distribution Method.<br/>
                                Click the button above to draft and send an email to RoyaltyZone to approve them as a Distribution Method.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <!-- Contacts -->
                    <v-col cols="6">
                        <v-card height="100%" class="pa-4" :loading="contactLoading">
                            <v-card-title class="d-flex justify-center align-center pt-0">
                                <v-btn icon @click="newContact.dialog = true">
                                    <v-icon color="primary">
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                                Contacts
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            :to="`/pam?page=envoyContact`"
                                            v-on="on"
                                            v-bind="attrs">
                                            <v-icon color="primary">
                                                mdi-wizard-hat
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>PAM: Envoy Contact Not Created</span>
                                </v-tooltip>
                            </v-card-title>
                            <v-carousel
                                light
                                :continuous="false"
                                :cycle="false"
                                :show-arrows="false"
                                delimiter-icon="mdi-minus"
                                height="100%"
                                class="mb-4"
                                hide-delimiter-background>
                                <v-carousel-item
                                    height="100%"
                                    class="mb-4 pb-4"
                                    v-for="(contact, index) in contacts" :key="index">
                                    <v-card class="pa-4" elevation="0">
                                        <v-row>
                                            <v-text-field
                                                v-model="contact.First_Name"
                                                label="First Name"
                                                class="pr-2"
                                                @change="updateContact(contact.Id, 'First_Name','firstName',contact.First_Name)"/>
                                            <v-text-field
                                                v-model="contact.Last_Name"
                                                label="Last Name"
                                                class="px-2"
                                                @change="updateContact(contact.Id, 'Last_Name','lastName', contact.Last_Name)"/>
                                            <v-text-field
                                                v-model="contact.Job_Title"
                                                label="Job Title"
                                                class="pl-2"
                                                @change="updateContact(contact.Id, 'Job_Title','jobTitle',contact.Job_Title)"/>
                                        </v-row>
                                        <v-row>
                                            <v-text-field
                                                v-model="contact.Email"
                                                label="Email"
                                                class="pr-2"
                                                @change="updateContact(contact.Id, 'Email','email',contact.Email)"/>
                                            <v-text-field
                                                v-model="contact.Password"
                                                label="Password"
                                                class="pl-2"
                                                @change="updateContact(contact.Id, 'Password','password',contact.Password)"/>
                                        </v-row>
                                        <v-row>
                                            <v-text-field
                                                v-model="contact.Notes"
                                                label="Notes"
                                                @change="updateContact(contact.Id, 'Notes','Notes',contact.Notes)"/>
                                        </v-row>
                                        <v-card-actions class="d-flex justify-space-around align-center">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div 
                                                        v-on="on"
                                                        v-bind="attrs">
                                                        <v-checkbox
                                                            v-model="contact.Envoy_Import"
                                                            :true-value="1"
                                                            :false-value="0"
                                                            label="Contact Imported to Envoy"
                                                            @change="updateContact(contact.Id, 'Envoy_Import',null,contact.Envoy_Import)"/>
                                                    </div>
                                                </template>
                                                <span>Should only be used if trying to correct a batch failure.<br>Otherwise, keep ya grubby paws off...</span>
                                            </v-tooltip>
                                            <v-tooltip bottom :disabled="contact.Credit_App_Sent !== 1">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-on="on">
                                                        <v-btn 
                                                            color="red" 
                                                            @click="deleteContact(contact.Id)" 
                                                            :disabled="contact.Credit_App_Sent === 1"
                                                            v-bind="attrs"
                                                            icon>
                                                            <v-icon color="red">mdi-delete-outline</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </template>
                                                <span>Cannot delete contact when credit application has been sent</span>
                                            </v-tooltip>
                                            <v-menu 
                                                v-model="creditApplicationConfirmationMenu"
                                                :close-on-content-click="false"
                                                offset-y 
                                                v-if="contact.Credit_App_Sent == 0">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    color="primary"
                                                    dark
                                                    :loading="creditApplicationLoading"
                                                    v-bind="attrs"
                                                    v-on="on">
                                                    Send Credit App
                                                    </v-btn>
                                                </template>
                                                <v-card class="pa-4">
                                                    <v-card-title class="d-flex justify-center align-center">
                                                        Are you sure?
                                                    </v-card-title>
                                                    <v-card-subtitle class="d-flex justify-center align-center">
                                                        Email to {{ contact.Email }}
                                                    </v-card-subtitle>
                                                    <v-card-actions class="d-flex justify-space-around align-center">
                                                        <v-btn
                                                            color="secondary"
                                                            class="mr-2"
                                                            @click="creditApplicationConfirmationMenu = false">
                                                            No... I Regret My Actions
                                                        </v-btn>
                                                        <v-btn
                                                            color="primary"
                                                            class="mr-2"
                                                            @click="sendCreditApplication(contact); creditApplicationConfirmationMenu = false;">
                                                            Yes, I'm Sure!
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-menu>
                                            <v-btn
                                                v-else
                                                color="primary"
                                                class="ml-2"
                                                @click="creditApplicationDialog = true">
                                                See Credit App    
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-carousel-item>
                            </v-carousel>
                        </v-card>
                    </v-col>
                    <v-col>
                        <locator-addresses/>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-card class="pa-4" width="91vw" height="100%">
                        <v-card-title class="d-flex justify-center align-center">
                            {{ chartTitle }}
                        </v-card-title>
                        <v-row justify="center" align="center">
                            <v-col cols="3">
                                <v-select
                                    class="pt-0"
                                    v-model="chartYAxis"
                                    :items="chartAxisOptions"
                                    item-value="value"
                                    item-text="name"/>
                                <v-date-picker
                                    v-model="dateFilter"
                                    range/>
                                <v-card-subtitle class="grey--text text--lighten-1 text-center d-flex justify-center align-center">
                                    Only YTD Data is shown here. Open SGT for more information.
                                </v-card-subtitle>
                            </v-col>
                            <v-col cols="6">
                                <sales-chart
                                    class="pt-0"
                                    :style="'height: 50vh'"
                                    v-model="chartEvent"
                                    :chartData="chartData"
                                    :yAxisValue="chartYAxis"/>
                            </v-col>
                        </v-row>    
                    </v-card>
                    <v-card width="91vw" class="mt-4">
                        <v-card-title class="d-flex justify-center align-center">
                            Orders
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            Only YTD Data is shown here. Open SGT for more information.
                        </v-card-subtitle>
                        <v-data-table
                            :headers="headers"
                            :items="uniqueOrders"
                            :single-expand="true"
                            :expanded.sync="expanded"
                            item-key="Id"
                            show-expand
                            class="elevation-1">
                            <template v-slot:[`item.Reference`]="{ item }">
                                <v-btn 
                                    :href="'https://go.cin7.com/Cloud/TransactionEntry/TransactionEntry.aspx?idCustomerAppsLink=985806&OrderId=' + item.Id"
                                    text
                                    color="primary">
                                    {{ item.Reference }}
                                </v-btn>
                            </template>
                            <template v-slot:[`item.Created_Date`]="{ item }">
                                <span>{{ new Date(item.Created_Date).toLocaleString().split(",")[0] }}</span>
                            </template>
                            <template v-slot:[`item.Order_Total`]="{ item }">
                                {{ item.Order_Total | currency }}
                            </template>
                            <template v-slot:expanded-item="{ item }">
                                <td colspan="3" class="py-4">
                                    <v-row justify="center" width="75vw">
                                        <v-data-table
                                            :items-per-page="item.Lines.length"
                                            width="75vw"
                                            :headers="itemHeaders"
                                            :items="item.Lines">
                                            <template v-slot:[`item.Line_Total`]="{ item }">
                                                {{ item.Line_Total | currency }}
                                            </template>
                                            <template v-slot:[`body.append`]>
                                                <tr>
                                                    <td><strong>Total:</strong></td>
                                                    <td></td>
                                                    <td>{{ sumLines(item.Lines, 'Qty').toLocaleString()}}</td>
                                                    <td>{{ sumLines(item.Lines, 'Line_Total') | currency}}</td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-row>
                                </td>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-row>
                <!-- Credit App Dialog -->
                <v-dialog
                    v-model="creditApplicationDialog"
                    width="80vw">
                    <v-card class="pa-4">
                        <v-row align="center">
                            <v-col cols="11">
                                <v-card-title class="ml-4 d-flex justify-center align-center">
                                    Credit Application (Read-only)
                                </v-card-title>
                            </v-col>
                            <v-col cols="1">
                                <v-card-actions class="d-flex justify-center align-center">
                                    <v-btn icon @click="creditApplicationDialog=false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                        <v-row class="px-3">
                            <v-card width="100%" class="mb-4 pa-2">
                                <v-card-title class="d-flex justify-center align-center">
                                    Banking Information
                                </v-card-title>
                                <v-card-text class="d-flex justify-center align-center">
                                    <v-text-field
                                        v-model="account.Bank_Name"
                                        readonly
                                        class="pl-4 pr-2"
                                        label="Bank Name"/>
                                    <v-text-field
                                        v-model="account.Bank_Account_Number"
                                        readonly
                                        class="px-4"
                                        label="Account Number"/>
                                    <v-select
                                        v-model="account.Bank_Account_Type"
                                        readonly
                                        class="pl-2 pr-4"
                                        label="Type of Account"
                                        :items="['Checking','Savings']"/>
                                    </v-card-text>
                                </v-card>
                        </v-row>
                        <v-row v-for="(v, index) in  creditApplication" :key="index" class="mb-4 pa-2">
                            <v-col>
                                <v-card class="pa-4" height="100%">
                                    <v-card-title class="d-flex justify-center align-center">
                                        Vendor {{ Number(index) + 1}} Information
                                    </v-card-title>
                                    <v-row>
                                        <v-text-field
                                            class="pl-4 pr-2"
                                            v-model="v.Company_Name"
                                            readonly
                                            label="Company Name"/>
                                        <v-text-field
                                            class="px-4"
                                            readonly
                                            v-model="v.Primary_Contact"
                                            label="Primary Contact"/>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                            class="px-4"
                                            readonly
                                            v-model="v.Phone"
                                            label="Phone"/>
                                        <v-text-field
                                            class="pl-2 pr-4"
                                            readonly
                                            v-model="v.Email"
                                            label="Email"/>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card class="pa-4" height="100%">
                                    <v-card-title class="d-flex justify-center align-center">
                                        Vendor {{ Number(index) + 1 }} Response
                                    </v-card-title>
                                    <template v-if="!v.Account_Open_Date">
                                        <v-card-text class="d-flex justify-center align-center text-center">
                                            This vendor has not replied yet<br>
                                            The request was last sent on {{ v.Last_Sent.split('T')[0] }}<br>
                                            The request has been sent {{ v.Sent_Count }} time(s)
                                        </v-card-text>
                                        <v-card-actions class="d-flex justify-center align-center">
                                            <v-btn
                                                color="primary"
                                                @click="sendEmail(v)"
                                                :loading="v.loading">
                                                Resend Request
                                            </v-btn>
                                        </v-card-actions>
                                    </template>
                                    <template v-else>
                                        <v-row class="px-2">
                                            <v-text-field
                                                class="pr-2"
                                                v-model="v.Account_Open_Date.split('T')[0]"
                                                readonly
                                                label="Account Open Date"/>
                                            <v-text-field
                                                class="pr-2"
                                                v-model="v.Terms"
                                                readonly
                                                label="Terms"/>
                                        </v-row>
                                        <v-row class="px-2">
                                            <v-text-field
                                                class="pr-2"
                                                v-model="v.High_Credit"
                                                readonly
                                                prefix="$"
                                                label="High Credit"/>
                                            <v-text-field
                                                class="pr-2"
                                                v-model="v.Current_Balance"
                                                readonly
                                                prefix="$"
                                                label="Current Balance"/>
                                        </v-row>
                                        <v-row class="px-2">
                                            <v-text-field
                                                class="pr-2"
                                                v-model="v.Amount_Past_Due"
                                                readonly
                                                prefix="$"
                                                label="Amount Past Due"/>
                                            <v-text-field
                                                class="pr-2"
                                                v-model="v.Average_Days_To_Pay"
                                                readonly
                                                label="Average Days To Pay"/>
                                        </v-row>
                                        <v-row class="px-2">
                                            <v-text-field
                                                class="pr-2"
                                                v-model="v.Last_Order_Date.split('T')[0]"
                                                readonly
                                                label="Last Order Date"/>
                                            <v-text-field
                                                class="pr-2"
                                                v-model="v.Comments"
                                                readonly
                                                label="Comments"/>
                                        </v-row>
                                    </template>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-autocomplete
                                v-model="account['Payment Terms']"
                                label="Payment Terms"
                                :items="paymentTerms"
                                item-text="Payment_Terms"
                                item-value="Payment_Terms"
                                hide-details/>
                                <v-btn
                                    color="primary"
                                    @click="updateAccount('Payment Terms','paymentTerms',account['Payment Terms'])"
                                    :disabled="!account['Payment Terms'].length">
                                    Update Payment Terms
                                </v-btn>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="email.dialog"
                    width="50vw"
                    height="50vh">
                    <v-card class="pa-4">
                        <v-card-title class="d-flex justify-center align-center">
                            Email to Royalty Zone
                        </v-card-title>
                        <v-text-field
                            v-model="email.to"
                            outlined
                            label="To"/>
                        <v-text-field
                            v-model="email.subject"
                            outlined
                            label="Subject"/>
                            <tiptap-vuetify
                                v-model="email.body"
                                :extensions="extensions"/>
                        <v-card-actions class="d-flex justify-space-around align-center">
                            <v-btn
                                @click="email.dialog = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                @click="sendVolcomEmail()"
                                color="primary"
                                :loading="loading">
                                Send
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- new contact dialog -->
                <v-dialog
                    v-model="newContact.dialog"
                    width="50vw">
                    <v-card class="pa-4" width="50vw">
                        <v-card-title class="d-flex justify-center align-center">
                            Create New Contact
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            This DOES NOT create the user in Envoy. Please create the user in Envoy after making it here.
                        </v-card-subtitle>
                        <v-row class="px-4">
                            <v-text-field
                                v-model="newContact.firstName"
                                label="First Name"
                                class="pr-2"/>
                            <v-text-field
                                v-model="newContact.lastName"
                                label="Last Name"
                                class="pl-2"/>
                        </v-row>
                        <v-row class="px-4">
                            <v-text-field
                                v-model="newContact.email"
                                label="Email"
                                class="pr-2"/>
                            <v-text-field
                                v-model="newContact.jobTitle"
                                label="Job Title"
                                class="pl-2"/>
                        </v-row>
                        <v-card-actions class="d-flex justify-space-around align-center">
                            <v-btn @click="newContact.dialog = false">
                                Cancel
                            </v-btn>
                            <v-btn 
                                color="primary" 
                                :loading="newContact.loading" 
                                @click="createNewContact()"
                                :disabled="!newContact.email.length">
                                Create
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- move to envoy dialog-->
                <v-dialog v-model="movingToEnvoyDialog" width="50vw">
                    <v-card class="pa-4" width="50vw">
                        <v-card-title class="d-flex justify-start align-center">
                            Moving this account to Envoy{{ threeDots }}
                        </v-card-title>
                        <v-progress-linear
                            v-model="trickProgressValue"/>
                        <v-card-subtitle class="d-flex justify-center align-center" v-if="trickProgressValue > 50">
                            Lol you thought I knew how long this will take! Nope... the mystery of SFTP integrations will continue...<br/>
                            You will be emailed once everything has been completed in 5-15 minutes.
                        </v-card-subtitle>
                        <v-card-actions class="d-flex justify-center align-center">
                            <v-btn
                                color="primary"
                                @click="movingToEnvoyDialog = false; trickProgressValue = 0">
                                Okay
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- Store Locator Addresses -->
                <locator-addresses
                    :dialogTrigger="seeAddressCounter"
                    :contactId="mainContactId"/>
            </template>
            <!-- Stuff to render if the account still needs to be reviewed and completed by sales -->
            <template v-else-if="account['Account ID'] && account.Reviewed_By_Sales === 0">
                <v-row class="pt-8" justify="center" align="center">
                    <v-card class="pa-4" width="75vw" height="100%" :loading="loading">
                        <v-card-title class="d-flex justify-center align-center">
                        {{ account.Company }} Has Not Been Reviewed By Sales
                        </v-card-title>
                        <v-card-subtitle class="text-center">
                            Please select the following classifications to mark this account as reviewed<br>
                            * Indicates a Required Field
                        </v-card-subtitle>
                        <v-form v-model="reviewComplete">
                            <v-row class="px-4" justify="center" align="center">
                                <v-text-field
                                    v-model="review.Website"
                                    label="Website"
                                    class="pl-2"/>
                                <v-checkbox
                                    v-model="review.Majority_Online_Business"
                                    :true-value="1"
                                    :false-value="0"
                                    label="Majority Online Business"
                                    class="px-2"/>
                                <v-text-field
                                    v-model="review['Door Count']"
                                    label="Door Count*"
                                    type="number"
                                    required
                                    class="px-2"/>
                                <v-checkbox
                                    v-model="review['Online Store']"
                                    :true-value="1"
                                    :false-value="0"
                                    label="Online Store"
                                    class="pr-4"/>
                            </v-row>
                            <v-row class="px-4" justify="center" align="center">
                                <v-col cols="6">
                                    <v-select
                                        :items="channels"
                                        item-text="Channel"
                                        item-value="Id"
                                        v-model="review.Channel_Id"
                                        required
                                        label="Channel*"/>
                                    <v-select
                                        :items="accountTypes"
                                        item-text="Account_Type"
                                        item-value="Id"
                                        v-model="review.Account_Type_Id"
                                        required
                                        label="Account Type*"/>
                                    <v-select
                                        :items="industries"
                                        item-text="Industry"
                                        item-value="Id"
                                        v-model="review.Industry_Id"
                                        required
                                        label="Industry*"/>
                                    <v-select
                                        :items="forecastCategories"
                                        item-text="Forecast_Name"
                                        item-value="Forecast_Id"
                                        v-model="review.Forecast_Id"
                                        required
                                        label="Forecast Category*"/>
                                    <v-select
                                        :items="salesManagers"
                                        item-value="User ID"
                                        v-model="review['Sales Rep']"
                                        required
                                        label="Interal Sales Rep*">
                                        <template v-slot:selection="{ item }">
                                            {{ item['First Name'] }} {{ item['Last Name']}}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item['First Name'] }} {{ item['Last Name']}}
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        :items="salesReps"
                                        item-value="Id"
                                        item-text="Agency"
                                        v-model="review.External_Sales_Rep_Id"
                                        required
                                        label="External Sales Rep*"/>
                                    <v-select
                                        :items="priceCategories"
                                        item-text="Name"
                                        item-value="Price ID"
                                        v-model="review['Price ID']"
                                        required
                                        label="Primary Price Category*"/>
                                    <v-select
                                        :items="priceCategories"
                                        item-text="Name"
                                        item-value="Price ID"
                                        v-model="review['Secondary_Price_Id']"
                                        required
                                        label="Secondary Price Category*"/>
                                    <v-select
                                        :items="costCategories"
                                        item-text="Name"
                                        item-value="Cost ID"
                                        v-model="review['Cost ID']"
                                        required
                                        label="Cost Category*"/>
                                    <v-select
                                        :items="['','2DISCOUNT','5DISCOUNT','8DISCOUNT','10DISCOUNT','15DISCOUNT']"
                                        v-model="review.Envoy_Discount"
                                        label="Envoy Discount"/>
                                </v-col>
                            </v-row>
                            <v-row justify="center" align="center" class="px-4">
                                <v-checkbox
                                    v-model="review.Prevent_Envoy_Import"
                                    :true-value="1"
                                    :false-value="0"
                                    label="Prevent Envoy Import"/>
                            </v-row>
                            <template v-if="review.Prevent_Envoy_Import === 0" >
                                <v-row v-for="(contact, index) in contacts" :key="index" justify="center" align="center" class="px-4">
                                    <v-text-field
                                        v-model="contact.First_Name"
                                        required
                                        label="First Name*"
                                        class="pl-4"/>
                                    <v-text-field
                                        v-model="contact.Last_Name"
                                        required
                                        label="Last Name*"
                                        class="px-2"/>
                                    <v-text-field
                                        v-model="contact.Email"
                                        required
                                        :rules="emailRules"
                                        label="Email*"
                                        class="pr-4"/>
                                    <v-btn
                                        v-if="index !== 0"
                                        @click="contacts.splice(index,1)"
                                        icon
                                        color="red">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </v-row>
                                <v-card-actions class="d-flex justify-center align-center">
                                    <v-btn
                                        color="secondary"
                                        @click="contacts.push({
                                            Account_Id: account['Account ID'],
                                            First_Name: '',
                                            Last_Name: '',
                                            Email: ''
                                        })">
                                        Add Contact
                                    </v-btn>
                                </v-card-actions>
                            </template>
                        </v-form>
                        <v-card-actions class="d-flex justify-center align-center">
                            <v-btn
                                color="primary"
                                :disabled="!reviewComplete"
                                @click="reviewAccount()">
                                {{ review.Prevent_Envoy_Import ? 'Update' : 'Update and Send to Envoy' }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-row>    
            </template>
            <!-- Loading element -->
            <template v-else>
                <v-row class="pt-16" justify="center" align="center">
                    <v-progress-circular
                        indeterminate/>
                </v-row>
            </template>
            <v-snackbar
                v-model="snackActive"
                :color="snackColor"
                :loading="loading"
                :timeout="snackTimeout">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
import SalesChart from './salesChart.vue'
import locatorAddresses from './locatorAddresses.vue'
import { TiptapVuetify, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'

export default {
    components: {
        SalesChart,
        locatorAddresses,
        TiptapVuetify
    }, 
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - CRM'
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Leads', path: '/sales/crm/leads/home', icon: 'mdi-account-box-outline', notification: false},
            {title: 'Accounts', icon: 'mdi-domain', path: '/sales/crm/accounts/home', notification: false},
            {title: 'Admin', path: '/sales/crm/admin/home', icon: 'mdi-cog', notification: false},
            {title: 'Batches', path: '/sales/crm/batches', icon: 'mdi-account-convert', notification: false},
            {title: 'Export', path: '/sales/crm/accounts/export', icon: 'mdi-export-variant', notification: false},
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ])
        this.$store.dispatch('updateNavbarTitle','CRM Accounts')
    },
    async created() {
        if(this.$route.params.id) {
            await this.getAccount(this.$route.params.id)
        }
        if ((this.$route.query.firstTime && this.$route.query.firstTime === 1) || this.account.Imported_To_Envoy === 0) {
            this.sendEnvoyCreationRequest()
        }
        let start = new Date()
        this.lastYear = start.getFullYear() - 1
        start.setDate(1)
        start.setMonth(0)
        let end = new Date()
        start = start.toISOString().split('T')[0];
        end = end.toISOString().split('T')[0];
        this.dateFilter = [start,end]
    },
    data() {
        return {
            creditApplicationConfirmationMenu: false,
            creditApplicationDialog: false,
            movingToEnvoyDialog: false,
            seeAddressCounter: 0,
            envoyImportMenu: false,
            trickProgressValue: 0,
            syncWithCin7: true,
            lastYear: 2021,
            accountRevenue: 0,
            forecastCategoryRevenue: 0,
            account: {},
            mainContactId: 0,
            contacts: [],
            tempContacts: [],
            threeDots: '',
            chartAxisOptions: [
                {
                    name: 'By Quantity',
                    value: 'Qty'
                },
                {
                    name: 'By Revenue',
                    value: 'Order_Total'
                }
            ],
            chartYAxis: 'Order_Total',
            salesReps: [],
            salesManagers: [],
            forecastCategories: [],
            channels: [],
            industries: [],
            accountTypes: [],
            costCategories: [],
            priceCategories: [],
            paymentTerms: [],
            creditApplication: [],
            branches: [],
            volcomDistMethods: [],
            volcomTerritories: [],
            orders: [],
            imageOptions: [],
            dateFilter: [],
            expanded: [],
            chartEvent: {},
            orderedVolcom: false,
            email: {
                dialog: false,
                to: ["jrobel@authenticbrands.com","hbyun@authenticbrands.com"],
                subject: 'New Valid Values For Matador x Volcom',
                body: `<p>Hi Royalty Zone,<br>Please add the following companies as valid Distribution Methods:<br></p>`
            },
            newContact: {
                dialog: false,
                firstName: '',
                lastName: '',
                email: '',
                jobTitle: '',
                loading: false
            },
            extensions: [
                ListItem,
                BulletList,
                OrderedList
            ],
            review: {
                Website: '',
                Majority_Online_Business: 0,
                'Door Count': '',
                'Online Store': 0,
                Channel_Id: '',
                Account_Type_Id: '',
                Industry_Id: '',
                Forecast_Id: '',
                'Sales Rep': '',
                External_Sales_Rep_Id: '',
                'Price ID': '',
                'Secondary_Price_Id': '',
                'Cost ID': '',
                Envoy_Discount: '',
                Prevent_Envoy_Import: 0,
                Reviewed_By_Sales: 1
            },
            reviewComplete: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            creditApplicationLoading: false,
            loading: false,
            contactLoading: false,
            cin7Loading: false,
            refreshLoading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        async getAccount(id) {
            this.loading = true
            try {
                //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_GetAccounts?tab=code
                const response = await fetch(
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts?id=' + id,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const data = await response.json()

                this.account = data.account[0]
                this.contacts = data.contacts
                this.salesReps = data.salesReps
                this.salesManagers = data.salesManagers
                this.forecastCategories = data.forecastCategories
                this.industries = data.industries
                this.accountTypes = data.accountTypes
                this.channels = data.channels
                this.orders = data.orders
                this.imageOptions = JSON.parse(this.account.Image_Options)
                this.costCategories = data.costList
                this.priceCategories = data.priceList
                this.branches = data.branches
                this.creditApplication = data.creditReference
                this.volcomDistMethods = data.volcomDistMethods
                this.volcomTerritories = data.volcomTerritories
                this.orderedVolcom = data.orderedVolcom
                this.accountRevenue = data.accountRevenue
                this.forecastCategoryRevenue = data.forecastCategoryRevenue
                this.mainContactId = data.mainContactId
                this.paymentTerms = data.paymentTerms
                this.locatorAddresses = data.locatorAddresses

                for (const i of this.creditApplication) {
                    i.loading = false
                }

                if (this.account.Billing_Address_2) {
                    this.account.Billing_Address_Show = 
                        this.account['Billing Address'] + ", " + 
                        this.account.Billing_Address_2 + ", " + 
                        this.account['Billing City'] + " " + 
                        this.account['Billing State'] + ", " + 
                        this.account['Billing Postal Code'] + " " +
                        this.account['Billing Country']
                } else {
                    this.account.Billing_Address_Show = 
                        this.account['Billing Address'] + ", " + 
                        this.account['Billing City'] + " " + 
                        this.account['Billing State'] + ", " + 
                        this.account['Billing Postal Code'] + " " +
                        this.account['Billing Country']
                }

                if (this.account.Delivery_Address_2) {
                    this.account.Shipping_Address_Show = 
                        this.account['Delivery Address'] + ", " + 
                        this.account.Delivery_Address_2 + ", " + 
                        this.account['Delivery City'] + " " + 
                        this.account['Delivery State'] + ", " + 
                        this.account['Delivery Postal Code'] + " " +
                        this.account['Delivery Country']
                } else {
                    this.account.Shipping_Address_Show = 
                        this.account['Delivery Address'] + ", " + 
                        this.account['Delivery City'] + " " + 
                        this.account['Delivery State'] + ", " + 
                        this.account['Delivery Postal Code'] + " " +
                        this.account['Delivery Country']
                }

                this.loading = false
			} catch (error) {
				this.snack(error,'error',3000)
                this.loading = false
			}
        },
        async createCin7Account() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_PushAccountToCin7?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/cin7/fromdatabase?email=${this.$store.getters.getUserEmail}&id=${this.$route.params.id}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async reviewAccount() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_AccountReview?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/review?email=' + this.$store.getters.getUserEmail,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: this.account['Account ID'],
                            review: this.review,
                            company: this.account.Company,
                            contacts: this.contacts
                        })
                    }
                )
                const {
                    message,
                    account
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.account = account[0]
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async sendEnvoyCreationRequest() {
            if (this.account.Prevent_Envoy_Import === 0) {
                this.trickProgressValue = 0
                this.startTrickProgress()
                this.movingToEnvoyDialog = true
                try {
                    const result = await fetch(
                        //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_CreateAccounts?tab=configure
                        `https://y7bnre858h.execute-api.us-east-2.amazonaws.com/prod/createcustomer?id=${this.mainContactId}&email=${this.$store.getters.getUserEmail}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            keepalive: true
                        }
                    )
                    const {
                        message,
                    } = await result.json()
                
                    if(!result.ok) {
                        throw message
                    }
                
                } catch (error) {
                    if (error.statusCode !== 504) {
                        this.snack(error,'error',3000)
                    }
                }
            }
            
        },
        async updateAccount(key, cin7key, value, cin7value) {
            try {
                this.snack("Loading...","info",-1)
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_AccountUpdate?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: this.account['Account ID'],
                            key: key,
                            cin7key: cin7key,
                            value: value,
                            cin7Value: cin7value || value
                        })
                    }
                )
                
                const {
                    message,
                } = await result.json()
                if (!result.ok) {
                    throw message
                }
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateContact(id, key, cin7key, value) {
            try {
                this.contactLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_UpdateContact?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/contacts',
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: id,
                            key: key,
                            cin7key: cin7key,
                            value: value
                        })
                    }
                )
                
                const {
                    message,
                } = await result.json()
                if (!result.ok) {
                    throw message
                }
                this.contactLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.contactLoading = false
                this.snack(error,'error',3000)
            }
        },  
        async refreshCin7() {
            try {
                this.refreshLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_SyncDatabaseFromCin7?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/cin7/todatabase',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: this.account['Account ID']
                        })
                    }
                )
                const {
                    message,
                    account
                } = await result.json()

                if(!result.ok) {
                    throw message
                }
                this.account = account
                this.refreshLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.refreshLoading = false
                this.snack(error,'error',3000)
            }
        },
        async createNewContact() {
            try {
                this.newContact.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_CreateContact?newFunction=true&tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/contacts?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            accountId: this.account['Account ID'],
                            firstName: this.newContact.firstName,
                            lastName: this.newContact.lastName,
                            email: this.newContact.email,
                            jobTitle: this.newContact.jobTitle
                        })
                    }
                )
                const {
                    message,
                    contacts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.contacts = contacts
                this.newContact.dialog = false
                this.newContact.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.newContact.loading = false
                this.snack(error,'error',3000)
            }
        },
        async deleteContact(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_DeleteContact?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/contacts?id=' + id,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                let index = this.contacts.map(item => item.Id).indexOf(id)
                this.contacts.splice(index, 1)
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async sendCreditApplication(contact) {
            try {
                this.creditApplicationLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_SendCreditApplication?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/creditreference/sendapplication',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            contact: contact
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
                contact.Credit_App_Sent = 1
                this.creditApplicationLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                contact.Credit_App_Sent = 0
                this.creditApplicationLoading = false
                this.snack(error,'error',3000)
            }
        },
        openEnvoyPage() {
            let url = ''
            if (this.account.Account_Type_Id === 1) {
                url = `https://matador-global.envoyb2b.com/envoy/customers/${this.mainContactId}`
            } else {
                url = `https://matador.envoyb2b.com/envoy/customers/${this.mainContactId}`
            }
            //open url in new tab
            window.open(url, '_blank')
        },
        setEmail() {
            this.email.body= `<p>Hi Royalty Zone,<br>Please add the following companies as valid Distribution Methods:<br></p>`
            this.email.body += `<ul>`
            let country = this.volcomTerritories.find(item => item.Id === this.account.Volcom_Territory).Territory
            this.email.body += `<li>` + this.account.Company + `</li><ul><li>Country: ` + country + `</li><li>Specialty Retailer that carries similar brands to Volcom</li></ul>`
            this.email.body += `</ul>`
            this.email.dialog = true
        },
        async sendVolcomEmail() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/email',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            body: this.email.body,
                            subject: this.email.subject,
                            to: this.email.to
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
                this.email.dialog = false
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async downloadFromCin7() {
            try {
                this.cin7Loading = true
                let minContactId = Math.min(...this.contacts.map(item => item['Contact ID']))
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_SyncDatabaseFromCin7?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/cin7/todatabase',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: minContactId
                        })
                    }
                )
            
                const {
                    message,
                    account
                } = await result.json()

                this.account = account

                this.cin7Loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                console.log(error);
                this.cin7Loading = false
                this.snack(error,'error',3000)
            }
        },
        async copyToClipboard() {
            await navigator.clipboard.writeText(`http://clearmanlabs.com/#/store-locator/form?id=${this.mainContactId}`)
        },
        orderItems(id) {
            return this.orders.filter(item => item.Id === id)
        },
        orderDetails(id) {
            return this.orders.find(item => item.Id === id)
        },
        sumLines(lines, type) {
            return lines.reduce((sum, record) => {
                return sum + record[type]
            },0)
        },
        getAccountType(id) {
            return this.accountTypes.find(item => item.Id === id).Account_Type
        },
        getIndustry(id) {
            return this.industries.find(item => item.Id === id).Industry
        },
        getForecastCategory(id) {
            return this.forecastCategories.find(item => item.Forecast_Id === id).Forecast_Name
        },
        getSalesRep(id) {
            return this.salesReps.find(item => item.Id === id).Agency
        },
        async sendEmail(v) {
            try {
                let subject = "Matador Credit Reference For " + this.account.Company
                let body = `<html><body>Hi ${v.Primary_Contact},<br>
                            ${this.account.Company} has listed ${v.Company_Name} as a trade reference.<br>
                            We would appreciate it if you would complete this <a href='https://clearmanlabs.com/#/Credit_Reference?id=${v.Id}'>form</a><br>
                            (https://clearmanlabs.com/#/Credit_Response?id=${v.Id})<br>
                            Please contact our Credit Manager, Christina, at (720) 242-6437 if you have any questions<br>
                            Thank you<body><html>`
                let to = [v.Email]
                v.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_SendEmail?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/email',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            subject: subject,
                            body: body,
                            to: to
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()

                //increment the amount of times this reference has been contacted
                let count = Number(v.Sent_Count)
                count += 1
                let d = new Date()
                d = d.toISOString()
                d.replace("T"," ")
                d = d.split(".")[0]
                const result2 = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_UpdateCreditReference?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/creditreference',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Id: v.Id,
                            Update: {
                                Sent_Count: count,
                                Last_Sent: d
                            }
                        })
                    }
                )
            
                const {
                    message2
                } = await result2.json()
                console.log(message2);
                v.Sent_Count = count

                v.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                v.loading = false
                this.snack(error,'error',3000)
            }
        },
        getBillingAddressData: function (addressData) {
            this.account.Billing_Address_Update = addressData;
            this.updateAccount('Billing_Address', null, addressData.place_id)
        },
        getShippingAddressData: function (addressData) {
            this.account.Shipping_Address_Update = addressData;
            this.updateAccount('Shipping_Address', null, addressData.place_id)
        },
        startTrickProgress() {
            setInterval(() => {
                this.trickProgressValue += Math.random() * (15 - 5) + 5
            },2000)
            let dotCount = 0
            this.threeDots = ''
            // while (this.movingToEnvoyDialog) {
                setInterval(() => {
                    this.threeDots = '.'.repeat(dotCount % 4)
                    dotCount++
                }, 500)
            // }
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        contactCount() {
            let notImportedCount = 0;
            let importedCount = 0;

            for (const item of this.contacts) {
                if (item.Envoy_Import === 0) {
                notImportedCount++;
                } else if (item.Envoy_Import === 1) {
                importedCount++;
                }
            }

            return { notImportedCount, importedCount };
        },
        chartData() {
            let labels = []
            let orderData = []
            let data = {}
            let currentDate = new Date(new Date(this.dateFilterSorted[0]).toUTCString().substr(0, 25))
            let startDate = new Date(new Date(this.dateFilterSorted[0]).toUTCString().substr(0, 25))
            let endDate = new Date(new Date(this.dateFilterSorted[1]).toUTCString().substr(0, 25))
            let ordersCounted = []

            while (currentDate < endDate) {
                labels.push(currentDate.toLocaleString('default', { month: 'short' }) + " " + currentDate.getFullYear())
                let monthTotal = this.orders.reduce((sum, record) => {
                    let date = new Date(new Date(record.Created_Date).toUTCString().substr(0, 25))
                    if (
                        date.getMonth() === currentDate.getMonth() && 
                        date >= startDate &&
                        date <= endDate &&
                        ordersCounted.indexOf(record.Reference) === -1) {
                            ordersCounted.push(record.Reference)
                            return sum + record[this.chartYAxis]
                    } else {
                        return sum
                    }
                }, 0)
                orderData.push(monthTotal)
                currentDate.setMonth(currentDate.getMonth() + 1)
            }
            data = {
                labels: this.chartLabels.map(item => item.label),
                datasets: [
                    {
                        label: 'Order Value ($)',
                        fill: false,
                        data: orderData,
                        borderColor: this.$vuetify.theme.currentTheme.primary
                    }
                ]
            }
            return data
        },
        chartLabels() {
            let currentDate = new Date(new Date(this.dateFilterSorted[0]).toUTCString().substr(0, 25))
            let endDate = new Date(new Date(this.dateFilterSorted[1]).toUTCString().substr(0, 25))
            let labels = []
            while (currentDate < endDate) {
                let temp = {
                    label: currentDate.toLocaleString('default', { month: 'short' }) + " " + currentDate.getFullYear(),
                    month:currentDate.getMonth(),
                    year: currentDate.getFullYear()
                }
                labels.push(temp)
                currentDate.setMonth(currentDate.getMonth() + 1)
            }
            return labels
        },
        dateFilterSorted() {
            if (this.dateFilter.length == 2) {
                let first = new Date(this.dateFilter[0])
                let second = new Date(this.dateFilter[1])
                if (first < second) {
                    return [first.toUTCString(), second.toUTCString()]
                } else {
                    return [second.toUTCString(), first.toUTCString()]
                }
            } else {
                return []
            }
        },
        chartTitle() {
            if (this.chartYAxis === "Order_Total") {
                return "Revenue"
            } else if (this.chartYAxis === "Qty") {
                return "Quantity"
            } else {
                return ""
            }
        },  
        uniqueProducts() {
            return [... new Set(this.orders.map(item => item.SKU))]
        },
        uniqueOrders() {
            let orders = []
            let tracker = []
            for (let i = 0; i < this.orders.length; i++) {
                if (tracker.indexOf(this.orders[i].Id) === -1) {
                    let temp = {
                        Created_Date: this.orders[i].Created_Date,
                        Id: this.orders[i].Id,
                        Order_Total: this.orders[i].Order_Total,
                        Reference: this.orders[i].Reference,
                        Lines: [{
                            SKU: this.orders[i].SKU,
                            Product_Name: this.orders[i].Product_Name + " " + this.orders[i].Option_1,
                            Option_1: this.orders[i].Option_1,
                            Qty: this.orders[i].Qty,
                            Line_Total: this.orders[i].Line_Total
                        }]
                    }
                    orders.push(temp)
                    tracker.push(this.orders[i].Id)
                } else {
                    let temp = {
                        SKU: this.orders[i].SKU,
                        Product_Name: this.orders[i].Product_Name + " " + this.orders[i].Option_1,
                        Option_1: this.orders[i].Option_1,
                        Qty: this.orders[i].Qty,
                        Line_Total: this.orders[i].Line_Total
                    }
                    orders.find(item => item.Id === this.orders[i].Id).Lines.push(temp)
                }
            }
            orders.sort((a,b) => {
                return new Date(b.Created_Date) - new Date(a.Created_Date)
            })
            return orders
        },
        headers() {
            return [
                {
                    text: 'Order Ref',
                    align: 'start',
                    sortable: false,
                    value: 'Reference',
                },
                {
                    text: 'Created Date',
                    align: 'start',
                    sortable: false,
                    value: 'Created_Date',
                },
                {
                    text: 'Order Total',
                    align: 'start',
                    sortable: false,
                    value: 'Order_Total',
                }
            ]
        },
        itemHeaders() {
            return [
                {
                    text: 'Product Name',
                    align: 'start',
                    sortable: false,
                    value: 'Product_Name',
                },
                {
                    text: 'SKU',
                    align: 'start',
                    sortable: false,
                    value: 'SKU',
                },
                {
                    text: 'Qty',
                    align: 'start',
                    sortable: false,
                    value: 'Qty',
                },
                {
                    text: 'Line Total',
                    align: 'start',
                    sortable: false,
                    value: 'Line_Total',
                }
            ]
        }
    }
}
</script>

<style>
.centered-input input {
  text-align: center;
}
</style>