<template>
    <div :class="(forecast && months.length === 1) ? 'pointer' : ''">
        <span v-if="!forecast">
            <span 
                v-for="(year, key, index) in monthTotals.actuals" 
                :key="index">
                <strong>{{ key }}: </strong>{{ year.qty.toLocaleString("en-US") }}<br>
                {{ year.total | currency}}<br>
            </span>
        </span>
        <span 
            v-else-if="forecast" 
            @click="boxClicked()">
            <v-progress-circular
                v-if="loading && getOtherSettings.speedRound"
                indeterminate
                color="primary"/>
            <div v-else-if="getOtherSettings.speedRound && clicked">
                <v-text-field
                    ref="input"
                    id="input"
                    v-model.number="qty" 
                    @keydown.enter="updateForecast(qty)"
                    @focus="$event.target.select()"
                    @blur="updateForecast(qty)"
                    persistent-hint
                    :hint="speedRoundHint"
                    :disabled="disableSpeedRound"
                    type="number"
                    dense/>
            </div>
            <div v-else style="text-align:center;vertical-align:middle;">
                <span>{{ Number(monthTotals.forecast[year].qty.toFixed(0)).toLocaleString("en-US")  }}</span><br>
                <span>{{ percentGrowth }}</span><br>
                <span>{{ monthTotals.forecast[year].total | currency}}</span>
            </div>
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.clicked = false
                this.$store.commit('CHANGE_CLICKED_CELL_ID', '')
            }
        })
    },
    props: {
        forecast: {type: Boolean},
        product: {type: Object},
        months: {type: Array},
        year: {type: Number},
    },
    watch: {
        getClickedCellId() {
            if (this.getClickedCellId !== this.cellId) {
                this.clicked = false
            }
        },
        getLoadingState() {
            if (this.getLoadingState) {
                this.loading = true
            } else {
                this.loading = false
            }
        },
        disabled() {
            if (!this.disabled) {
                setTimeout(() => {
                    this.$refs.input.focus() 
                }, 25)
            }
        }
    },
    data() {
        return {
            clicked: false,
            localProduct: this.$props.product,
            monthNames: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            costWarning: false,
            priceWarning: false,
            loading: false,
            qty: 0
        }
    },
    methods: {
        updateForecast(qty) {
            this.$emit('Update-Forecast', {
                Month: this.months[0],
                Year: this.$props.year,
                SKU: this.productInfo.SKU,
                Qty: qty,
                Price: this.productInfo.price || this.productInfo.listedPrice,
                Cost: this.productInfo.cost || this.productInfo.listedCost,
                Total: qty * this.productInfo.listedPrice,
                Discount_Percent: 0
            })
            this.$forceUpdate()
            this.clicked = false
        },
        boxClicked() {
            let priceWarningType = ''
            if (this.productInfo.listedCost === null) {
                priceWarningType = 'cost'
            }
            if (this.productInfo.listedPrice === null) {
                priceWarningType = 'price'
            }
            if (this.productInfo.listedCost === null && this.productInfo.listedPrice === null) {
                priceWarningType = 'both'
            }
            if (this.productInfo.listName === null) {
                priceWarningType = 'list'
            }
            if (priceWarningType) {
                this.$emit('priceNotFound', {
                    listId: this.productInfo.listId,
                    listName: this.productInfo.listName,
                    SKU: this.productInfo.SKU,
                    month: this.months[0],
                    type: priceWarningType
                })
            }
            if (this.getOtherSettings.speedRound && this.$props.months.length === 1) {
                if (this.productInfo.cost && this.productInfo.price && this.getOtherSettings.showPriceWarning) {
                    this.$emit('warning', {
                        productInfo: this.productInfo,
                        monthName: this.monthName
                    })
                }
                this.clicked = true
                this.$store.commit('CHANGE_CLICKED_CELL_ID', this.cellId)
                this.qty = this.monthTotals.forecast[this.$props.year].qty
                setTimeout(() => {
                    this.$refs.input.focus() 
                }, 25)
            } else if (this.$props.months.length === 1) {
                this.$emit('details', {
                    productInfo: this.productInfo,
                    monthName: this.monthName,
                    month: this.months[0]
                })
            }

        },
    },
    computed: {
        getLoadingState() {
            return this.product?.[this.$props.months[0]]?.[this.$props.year]?.Loading
        },
        monthName() {
            if (this.$props.months.length === 1) {
                return this.monthNames[this.$props.months[0] - 1]
            } else {
                return 'Total'
            }
        },
        currentDate() {
            if (this.$props.months.length === 1) {
                return new Date(this.$props.year, this.$props.months[0] - 1, 1)
            } else {
                return false
            }
        },
        productInfo() {
            if (this.$props.months.length === 1) {
                let correctYear = this.$props.product[this.$props.months[0]][this.$props.year]
                return {
                    price: correctYear.Recorded_Price,
                    listedPrice: correctYear.Listed_Cost_Price?.Price,
                    listedCost: correctYear.Listed_Cost_Price?.Cost,
                    listId: correctYear.Listed_Cost_Price?.Cost_Price_List_Id,
                    listName: correctYear.Listed_Cost_Price?.Cost_Price_List_Name,
                    cost: correctYear.Recorded_Cost,
                    discount: correctYear.Discount_Percent,
                    name: this.$props.product.Name,
                    SKU: correctYear.Listed_Cost_Price.SKU
                }
            } else {
                return 'Total'
            }
        },
        speedRoundHint() {
            let listedCost = this.productInfo.listedCost
            let listedPrice = this.productInfo.listedPrice
            let today = new Date()
            today.setDate(1)
            today.setHours(0, 0, 0, 0)
            
            if (today > this.currentDate) {
                return "You can't edit past months"
            } else if (listedPrice === null || listedPrice === undefined) {
                return "You need to set a price first"
            } else if (listedCost === null || listedCost === undefined) {
                return "You need to set a cost first"
            }

            let qty = this.qty
            return `$${listedPrice} * ${qty.toFixed(0).toLocaleString('en-US')} = $${(listedPrice * qty).toFixed(2)}`
        },
        disableSpeedRound() {
            let today = new Date()
            today.setDate(1)
            today.setHours(0, 0, 0, 0)
            if (
                !this.getOtherSettings.speedRound || 
                this.$props.months.length !== 1 || 
                today > this.currentDate ||
                this.productInfo.listedCost === null ||
                this.productInfo.listedCost === undefined ||
                this.productInfo.listedPrice === null ||
                this.productInfo.listedPrice === undefined) {
                return true
            } else {
                return false
            }
        },
        cellId() {
            if ('Name' in this.$props.product) {
                return this.$props.product.Name + "_" + this.$props.months.join(",")
            } else {
                return 'total_' + this.$props.months.join(",")
            }
        },
        monthTotals() {
            let actuals = {}
            let forecast = {}
            forecast[this.$props.year] = { qty: 0, total: 0 }
            if (this.$props.product && this.$props.months && this.$props.months.length) {
                let year = this.$props.year

                for (let i = 0; i < this.$props.months.length; i++) {
                    let month = this.$props.months[i]
                    let monthValues = this.$props.product[month]
                    if (monthValues && monthValues[year]) {
                        forecast[this.$props.year].qty += monthValues[this.$props.year]?.Forecast_Qty
                        forecast[this.$props.year].total += monthValues[this.$props.year]?.Forecast_Total

                        for (const key in monthValues) {
                            if (Object.prototype.hasOwnProperty.call(monthValues, key)) {
                                const { Actual_Qty, Actual_Total } = monthValues[key];

                                actuals[key] = actuals[key] || { qty: 0, total: 0 };
                                actuals[key].qty += Actual_Qty;
                                actuals[key].total += Actual_Total;

                            }
                        }
                    }
                }
            }
            return { actuals, forecast }
        },
        computedQty() {
            return this.monthTotals.forecast[this.$props.year].qty
        },
        percentGrowth() {
            let year = this.$props.year
            let productInfo = Object.keys(this.$props.product)
            let monthInfo = Object.keys(this.$props.months)
            if (year && productInfo.length && monthInfo.length) {
                let lastYearActual = this.monthTotals.actuals[this.$props.year - 1]?.qty
                let thisYearForecast = this.monthTotals.forecast[this.$props.year]?.qty
                if (lastYearActual === 0) return "Infinity%"
                return ((thisYearForecast - lastYearActual) / lastYearActual * 100).toFixed(2) + "%"
            } else {
                return 0
            }
        },
        ...mapGetters([
            'getOtherSettings',
            'getClickedCellId'
        ])
    },
}
</script>

<style>
.snackTable table {
    background: transparent !important;
}

.pointer {
    cursor: pointer;
    background-color: transparent !important;
}
</style>