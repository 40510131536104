<template>
    <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                class="pr-6 pt-2"
                dark
                icon
                v-bind="attrs"
                v-on="on">
                <v-badge
                    color="red"
                    :content="activeFilters">
                    <v-icon>mdi-filter-variant</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-card width="25vw" class="pa-4">
            <v-row align="center" justify="center" class="pt-2 mb-2">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="clearFilters()"
                            class="mr-2">
                            Reset Filters
                        </v-btn>
                    </template>
                    <span>Use me if the results are not what you expect</span>
                </v-tooltip>
            </v-row>
            <v-row align="center" class="mb-2">
                <v-col class="py-0 pr-0">
                    Active Status
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        chips
                        class="px-2"
                        hide-details
                        multiple
                        v-model="filter.active.value"
                        @change="change()"
                        :items="active"
                        item-text="text"
                        item-value="value"/>
                </v-col>
            </v-row>
            <v-row align="center" class="my-2">
                <v-col class="py-0 pr-0">
                    Forecast Category
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        class="px-2"
                        hide-details
                        multiple
                        v-model="filter.forecastCategories.value"
                        @change="change()"
                        :items="forecastCategories"
                        item-text="Forecast_Name"
                        item-value="Forecast_Id">
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item.Forecast_Name }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                            (+{{ filter.forecastCategories.length - 1 }} others)
                            </span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row align="center" class="my-2">
                <v-col class="py-0 pr-0">
                    Sales Manager
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        class="px-2"
                        hide-details
                        multiple
                        v-model="filter.salesManagers.value"
                        @change="change()"
                        :items="salesManagers"
                        item-text="First Name"
                        item-value="User ID">
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item['First Name'] }} {{ item['Last Name'] }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                            (+{{ filter.salesManagers.value.length - 1 }} others)
                            </span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row align="center" class="my-2">
                <v-col class="py-0 pr-0">
                    Sales Reps
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select 
                        class="px-2"
                        hide-details
                        multiple
                        v-model="filter.salesReps.value"
                        @change="change()"
                        :items="salesReps"
                        item-text="Agency"
                        item-value="Id">
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item.Agency }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                            (+{{ filter.salesReps.value.length - 1 }} others)
                            </span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row align="center" class="my-2">
                <v-col class="py-0 pr-0">
                    Industries
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        class="px-2"
                        hide-details
                        multiple
                        item-text="Industry"
                        item-value="Id"
                        v-model="filter.industries.value"
                        @change="change()"
                        :items="industries">
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item.Industry }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                            (+{{ filter.industries.value.length - 1 }} others)
                            </span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row align="center" class="my-2">
                <v-col class="py-0 pr-0">
                    Account Types
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        class="px-2"
                        hide-details
                        multiple
                        item-text="Account_Type"
                        item-value="Id"
                        v-model="filter.accountTypes.value"
                        @change="change()"
                        :items="accountTypes">
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item.Account_Type }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                            (+{{ filter.accountTypes.value.length - 1 }} others)
                            </span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row align="center" class="mt-2">
                <v-col class="py-0 pr-0">
                    Channels
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        class="px-2"
                        chips
                        hide-details
                        multiple
                        item-text="Channel"
                        item-value="Id"
                        v-model="filter.channels.value"
                        @change="change()"
                        :items="channels"/>
                </v-col>
            </v-row>
            <v-row align="center" class="my-2">
                <v-col class="py-0 pr-0">
                    Door Count
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        class="px-2"
                        hide-details
                        multiple
                        v-model="filter.doors.value"
                        @change="change()"
                        :items="doors">
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                            >
                            (+{{ filter.doors.value.length - 1 }} others)
                            </span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
        </v-card>
    </v-menu>
</template>

<script>

export default {
    props: {
        salesManagers: {type: Array},
        salesReps: {type: Array},
        industries: {type: Array},
        accountTypes: {type: Array},
        channels: {type: Array},
        forecastCategories: {type: Array},
        userCin7Id: {type: Number},
        doors: {type: Array}
    },
    async beforeMount() {
        if (!localStorage.crm_accounts_filters) {
            localStorage.crm_accounts_filters = JSON.stringify(this.filter)
        } else {
            let filter = JSON.parse(localStorage.crm_accounts_filters)
            if (
                filter.accountTypes &&
                filter.active &&
                filter.channels &&
                filter.forecastCategories &&
                filter.industries &&
                filter.salesManagers &&
                filter.salesReps &&
                filter.doors
            ) {
                this.filter = filter
            } else {
                localStorage.crm_accounts_filters = JSON.stringify(this.filter)
            }
            this.$emit('input', this.filter)
            this.$emit('loaded', true)
        }
    },
    watch: {
        userCin7Id: function() {
            if (!localStorage.crm_accounts_filters) {
                this.filter.salesManagers.value[0] = this.$props.userCin7Id
            }
            this.change()
        }
    },
    data() {
        return {
            active: [
                {
                    value: 0,
                    text: 'Not Active'
                },
                {
                    value: 1,
                    text: 'Active'
                }
            ],
            filter: {
                active: {
                    value: [1],
                    key: 'Active'
                },
                salesManagers: {
                    value: [],
                    key: 'Sales Rep'
                },
                salesReps: {
                    value: [],
                    key: 'External_Sales_Rep_Id'
                },
                industries: {
                    value: [],
                    key: 'Industry_Id'
                },
                accountTypes: {
                    value: [],
                    key: 'Account_Type_Id'
                },
                channels: {
                    value: [],
                    key: 'Channel_Id'
                },
                forecastCategories: {
                    value: [],
                    key: 'Forecast_Id'
                },
                doors: {
                    value: [],
                    key: 'Door Count'
                }
            }
        }
    },
    methods: {
        change() {
            localStorage.crm_accounts_filters = JSON.stringify(this.filter)
            this.$emit('input', this.filter)
            this.$emit('loaded', true)
        },
        clearFilters() {
            this.filter = {
                active: {
                    value: [1],
                    key: 'Active'
                },
                salesManagers: {
                    value: [],
                    key: 'Sales Rep'
                },
                salesReps: {
                    value: [],
                    key: 'External_Sales_Rep_Id'
                },
                industries: {
                    value: [],
                    key: 'Industry_Id'
                },
                accountTypes: {
                    value: [],
                    key: 'Account_Type_Id'
                },
                channels: {
                    value: [],
                    key: 'Channel_Id'
                },
                forecastCategories: {
                    value: [],
                    key: 'Forecast_Id'
                },
                doors: {
                    value: [],
                    key: 'Door Count'
                }
            }
            localStorage.crm_accounts_filters = JSON.stringify(this.filter)
            this.$emit('input', this.filter)
            this.$emit('loaded', true)
        }
    },
    computed: {
        activeFilters() {
            let foundFilters = []
            for (const [key, value] of Object.entries(this.filter)) {
                if (value.value.length) {
                    foundFilters.push(this.filter[key])
                }
            }
            return foundFilters.length
        }
    }
}
</script>

<style>

</style>