<template>
    <div>
        <v-row>
            <v-select
                class="pl-4 pr-8"
                v-model="colorPicked"
                :items="colorData"
                :item-text="colorData.name"
                :item-value="colorData.id"
                label="Select Colors"
                return-object>
                <template v-slot:selection="{ item }">
                    {{ item.name }}
                    <v-spacer/>
                    <v-sheet class="mb-2 d-flex justify-center align-center white--text" width="25%" height="50" :color="item.primary">
                       <v-card-subtitle >Primary</v-card-subtitle>
                    </v-sheet>
                    <v-sheet class="mb-2 d-flex justify-center align-center white--text" width="25%" height="50" :color="item.secondary">
                        <v-card-subtitle>Secondary</v-card-subtitle>
                    </v-sheet>
                    <v-sheet class="mb-2 d-flex justify-center align-center white--text" width="25%" height="50" :color="item.accent">
                        <v-card-subtitle>Accent</v-card-subtitle>
                    </v-sheet>
                </template>
                <template v-slot:item="{ item }">
                    {{ item.name }}
                    <v-spacer/>
                    <v-sheet class="mb-2 d-flex justify-center align-center white--text" width="25%" height="50" :color="item.primary">
                        <v-card-subtitle>Primary</v-card-subtitle>
                    </v-sheet>
                    <v-sheet class="mb-2 d-flex justify-center align-center white--text" width="25%" height="50" :color="item.secondary">
                        <v-card-subtitle>Secondary</v-card-subtitle>
                    </v-sheet>
                    <v-sheet class="mb-2 d-flex justify-center align-center white--text" width="25%" height="50" :color="item.accent">
                        <v-card-subtitle>Accent</v-card-subtitle>
                    </v-sheet>
                </template>
            </v-select>
        </v-row>
        <v-row justify="center" class="pb-6">
            <v-btn
                @click="updateColorPreference()"
                color="primary">
                Update Color
            </v-btn>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="newColorModal"
                width="50vw">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on">
                        Create New color
                    </v-btn>
                </template>
                <v-card class="px-4 pb-6" width="50vw" :loading="loading">
                    <v-card-title class="justify-center">New Color Set</v-card-title>
                    <v-row>
                        <v-col>
                            <v-text-field
                                class="pr-4 pl-8"
                                label="Color Name"
                                v-model="newColorName"/>
                        </v-col>
                        <v-col class="md-auto">
                            <v-menu  
                                ref="primaryDialog"
                                v-model="primaryModal"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                width="25vw">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        class="px-4"
                                        label="Primary Color"
                                        v-model="newPrimaryColor"
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon slot="prepend-inner" :color="newPrimaryColor">
                                            mdi-square
                                        </v-icon>
                                    </v-text-field>
                                </template>
                                <v-color-picker
                                    v-model="newPrimaryColor"
                                    class="ma-2"
                                    dot-size="30"
                                    hide-inputs/>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu  
                                ref="secondaryDialog"
                                v-model="secondaryModal"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                width="25vw">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        class="px-4"
                                        label="Secondary Color"
                                        v-model="newSecondaryColor"
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon slot="prepend-inner" :color="newSecondaryColor">
                                            mdi-square
                                        </v-icon>
                                    </v-text-field>
                                </template>
                                <v-color-picker
                                    v-model="newSecondaryColor"
                                    class="ma-2"
                                    dot-size="30"
                                    hide-inputs/>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu  
                                ref="accentDialog"
                                v-model="accentModal"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                width="25vw">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        class="px-4"
                                        label="Accent Color"
                                        v-model="newAccentColor"
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon slot="prepend-inner" :color="newAccentColor">
                                            mdi-square
                                        </v-icon>
                                    </v-text-field>
                                </template>
                                <v-color-picker
                                    v-model="newAccentColor"
                                    class="ma-2"
                                    dot-size="30"
                                    hide-inputs/>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row justify="space-around">
                        <v-btn
                            color="accent"
                            @click="closeModal()"
                        >Cancel</v-btn>
                        <v-btn
                            color="primary"
                            :disabled="isFormFilledOut"
                            @click="createNewColor(false)"
                        >Create</v-btn>
                        <v-btn
                            color="primary"
                            :disabled="isFormFilledOut"
                            @click="createAndUpdate()"
                        >Create And Set As My Color</v-btn>
                    </v-row>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
export default {
    beforeMount() {
        this.getColorOptions()
        this.colorPicked = this.$store.getters.getColor
    },
    watch: {
        loading: function() {
            this.$emit('input',this.loading)
        }
    },
    data() {
        return {
            newColorName: '',
            newColorModal: false,
            primaryModal: false,
            newPrimaryColor: '',
            secondaryModal: false,
            newSecondaryColor: '',
            accentModal: false,
            newAccentColor: '',
            colorPicked: {},
            colorData: [],
            loading: false,
            setNewColorAsCurrentColor: false,
        }
    },
    methods: {
        closeModal() {
            this.newColorName = ''
            this.newPrimaryColor = ''
            this.newSecondaryColor = ''
            this.newAccentColor = ''
            this.newColorModal= false
        },
        getColorOptions() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/admin/user/color'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.colorData = data.colors

            }).catch(error => {
                console.log(error);
            })
        },
        createNewColor(update) {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/admin/user/color'
            //prepare data for database by parsing the hex codes for each color
            const data = {
                name: this.newColorName,
                primary: this.newPrimaryColor.slice(0,-2),
                secondary: this.newSecondaryColor.slice(0,-2),
                accent: this.newAccentColor.slice(0,-2)
            }
            const request = {
                method: 'POST',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.colorData = data.colors
                //if this function is being called to update the users color then change the variable for picked color
                if (update) {
                    this.colorPicked = data.colors.find(item => item.name === this.newColorName)
                } else {
                    //if not an update and just a create then close the create color popup
                    this.newColorModal = false
                }

                this.loading = false
            }).catch(error => {
                console.log(error);
                this.loading = false
            })
        },
        updateColorPreference() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/admin/user/color'
            //set data for the lambda function
            //what color id is to be assigned
            //what user to assign it to
            const data = {
                colorId: this.colorPicked.id,
                id: this.$store.getters.getUserId
            }
            const request = {
                method: 'PUT',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                //save color to local storage to be used again when user enters site
                localStorage.color = JSON.stringify(this.colorPicked)
                //communicate this change with the vuex store
                this.$store.dispatch('updateColor',this.colorPicked)
                //change all vuetify color variables with the new picked colors
                this.$vuetify.theme.themes.light.primary = this.colorPicked.primary
                this.$vuetify.theme.themes.light.secondary = this.colorPicked.secondary
                this.$vuetify.theme.themes.light.accent = this.colorPicked.accent

                this.loading = false
            }).catch(error => {
                this.loading = false
                console.log(error);
            })
        },
        createAndUpdate() {
            //calls the create new color with the udpate parameter as true
            this.createNewColor(true)            
            this.updateColorPreference()
            this.newColorModal = false
        }
    },
    computed: {
        isFormFilledOut() {
            if (this.newColorName.length && 
                this.newPrimaryColor.length && 
                this.newSecondaryColor.length && 
                this.newAccentColor.length) {
                    return false
                } else {
                    return true
                }
        }
    }
}
</script>

<style>

</style>