<template>
    <v-app>
        <v-main>
            <v-card>
                <v-card-title>
                    <v-text-field
                        class="px-4"
                        v-model="search"
                        label="Search"
                        prepend-icon="mdi-magnify"/>
                    <v-menu offset-y :close-on-content-click="false" max-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            class="pr-8"
                            color="primary"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on">
                            <v-icon color="primary">
                                mdi-dots-vertical
                            </v-icon>
                            </v-btn>
                        </template>
                        <v-card class="pa-8">
                            <v-card-title class="pt-0">
                                Columns Shown
                            </v-card-title>
                            <v-row
                                width="100%"
                                v-for="(value, key) in settings.columns"
                                :key="key">
                                <v-checkbox v-model="settings.columns[key].value" :label="settings.columns[key].name"></v-checkbox>
                            </v-row>
                        </v-card>
                    </v-menu>
                    <v-menu 
                        v-model="newLead.menu"
                        offset-y 
                        :close-on-content-click="false" 
                        max-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                icon
                                v-bind="attrs"
                                v-on="on">
                                <v-icon color="primary">
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card class="pa-4">
                            <v-card-title class="d-flex justify-center align-center">
                                Create New Lead
                            </v-card-title>
                            <v-text-field
                                label="Company"
                                v-model="newLead.company"
                                hide-details="auto"/>
                            <v-text-field
                                label="Email"
                                v-model="newLead.email"
                                hide-details="auto"/>
                            <v-select
                                v-model="newLead.manager"
                                hide-details="auto"
                                :items="managers"
                                item-text="First Name"
                                item-value="User ID"
                                label="Sales Manager">
                                <template v-slot:selection="{ item }">
                                    {{ item['First Name'] }} {{ item['Last Name']}}
                                </template>
                                <template v-slot:item="{ item }">
                                    {{ item['First Name'] }} {{ item['Last Name']}}
                                </template>
                            </v-select>
                            <v-select
                                v-model="newLead.salesRep"
                                hide-details="auto"
                                :items="reps"
                                item-text="Agency"
                                item-value="Id"
                                label="Sales Rep"/>
                            <v-card-actions class="d-flex justify-center align-center">
                                <v-btn
                                    color="secondary"
                                    @click="newLead.menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    :loading="newLead.loading"
                                    :disabled="!newLead.company.length || !newLead.email.length"
                                    @click="createNewLead()">
                                    Create
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-card-title>
                <v-data-table
                    class="mx-4"
                    :items="leads"
                    :headers="filteredHeaders"
                    :loading="loading"
                    :hide-default-header="selected.length ? true : false"
                    :search="search">
                    <template v-slot:[`header.checkbox`] v-if="!selected.length">
                        <v-checkbox 
                            v-model="checkAll" 
                            @change="changeSelection()"/>
                    </template>
                    <template v-slot:header v-if="selected.length">
                        <!-- <thead> -->
                            <tr>
                                <th class="d-flex justify-center align-center">
                                    <v-checkbox 
                                        v-model="checkAll" 
                                        @change="changeSelection()"/>
                                </th>
                                <th>
                                    {{ selected.length }} Selected
                                </th>
                                <th class="pr-2">
                                    <v-autocomplete
                                        v-model="fieldToUpdate"
                                        :items="leadFields"
                                        item-text="label"
                                        class="pr-2 pl-4"
                                        hide-details
                                        return-object
                                        label="Field To Update"/>
                                </th>
                                <th class="pl-2">
                                    <v-autocomplete
                                        label="Update To Value"
                                        class="pl-2 pr-4"
                                        hide-details
                                        v-model="valueToUpdate"
                                        :items="objectForFieldToUpdate"
                                        :item-text="fieldToUpdate.text"
                                        return-object/>
                                </th>
                                <th>
                                    <v-btn 
                                        color="primary"
                                        :disabled="!fieldToUpdate && !valueToUpdate"
                                        :loading="bulkUpdateLoading"
                                        @click="bulkUpdateLead()">Update</v-btn>
                                </th>
                            </tr>
                        <!-- </thead> -->
                    </template>
                    <template v-slot:[`item.checkbox`]="{ item }">
                        <v-checkbox 
                            class="d-flex justify-center align-center"
                            v-model="item.checkbox"
                            @change="selectLead(item)"/>
                    </template>
                    <template v-slot:[`item.Company_Name`]="{ item }">
                        <v-btn
                            :to="'/sales/crm/leads/'+item.Id"
                            class="pa-0"
                            text
                            x-large
                            color="primary">
                            {{ item.Company_Name }}
                        </v-btn>
                    </template>
                    <template v-slot:[`item.Channel_Id`]="{ item }">
                        <v-select
                            v-if="settings.columns.channels.value"
                            label="Channel"
                            class="pr-2"
                            :items="channels"
                            item-value="Id"
                            item-text="Channel"
                            hide-details
                            @change="updateLead(item)"
                            v-model="item.Channel_Id"/>
                    </template>
                    <template v-slot:[`item.Account_Type_Id`]="{ item }">
                        <v-select
                            v-if="settings.columns.accountTypes.value"
                            label="Account Type"
                            class="pr-2"
                            :items="accountTypes"
                            item-value="Id"
                            item-text="Account_Type"
                            hide-details
                            @change="updateLead(item)"
                            v-model="item.Account_Type_Id"/>
                    </template>
                    <template v-slot:[`item.Industry_Id`]="{ item }">
                        <v-select
                            v-if="settings.columns.industries.value"
                            label="Industry"
                            class="pr-2"
                            :items="industries"
                            item-value="Id"
                            item-text="Industry"
                            hide-details
                            @change="updateLead(item)"
                            v-model="item.Industry_Id"/>
                    </template>
                    <template v-slot:[`item.Forecast_Id`]="{ item }">
                        <v-select
                            v-if="settings.columns.forecastCategories.value"
                            label="Forecast"
                            class="pr-2"
                            :items="forecastCategories"
                            item-value="Forecast_Id"
                            item-text="Forecast_Name"
                            hide-details
                            @change="updateLead(item)"
                            v-model="item.Forecast_Id"/>
                    </template>
                    <template v-slot:[`item.Sales_Manager`]="{ item }">
                        <v-select
                            v-if="settings.columns.managers.value"
                            label="Internal Manager"
                            class="pr-2"
                            :items="managers"
                            item-value="User ID"
                            item-text="First Name"
                            hide-details
                            @change="updateLead(item)"
                            v-model="item.Sales_Manager"/>
                    </template>
                    <template v-slot:[`item.External_Sales_Rep`]="{ item }">
                        <v-select
                            v-if="settings.columns.reps.value"
                            label="Sales Rep"
                            class="pr-2"
                            :items="reps"
                            item-value="Id"
                            item-text="Agency"
                            hide-details
                            @change="updateLead(item)"
                            v-model="item.External_Sales_Rep"/>
                    </template>
                </v-data-table>
            </v-card>
            <!-- <span v-else class="d-flex justify-center align-center text-caption">
                No Leads Match The Search
            </span> -->
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - CRM'
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Leads', path: '/sales/crm/leads/home', icon: 'mdi-account-box-outline', notification: false},
            {title: 'Accounts', icon: 'mdi-domain', path: '/sales/crm/accounts/home', notification: false},
            {title: 'Admin', path: '/sales/crm/admin/home', icon: 'mdi-cog', notification: false},
            {title: 'Batches', path: '/sales/crm/batches', icon: 'mdi-account-convert', notification: false},
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ])
        this.$store.dispatch('updateNavbarTitle','CRM Leads')
        this.getLeads()
        
    }, 
    data() {
        return {
            settings: {
                columns: {
                    channels: {
                        value: true,
                        name: 'Channels',
                        column: 'Channel_Id'
                    },
                    accountTypes: {
                        value: true,
                        name: 'Account Types',
                        column: 'Account_Type_Id'
                    },
                    industries: {
                        value: true,
                        name: 'Industries',
                        column: 'Industry_Id'
                    },
                    forecastCategories: {
                        value: true,
                        name: 'Forecast Categories',
                        column: 'Forecast_Id'
                    },
                    managers: {
                        value: true,
                        name: 'Managers',
                        column: 'Sales_Manager'
                    },
                    reps: {
                        value: true,
                        name: 'Sales Reps',
                        column: 'External_Sales_Rep'
                    }
                }
            },
            newLead: {
                menu: false,
                email: '',
                company: '',
                manager: 0,
                salesRep: 0,
                loading: false
            },
            fieldToUpdate: {},
            valueToUpdate: {},
            checkAll: false,
            bulkUpdateLoading: false,
            leads: [],
            industries: [],
            channels: [],
            accountTypes: [],
            reps: [],
            managers: [],
            forecastCategories: [],
            selected: [],
            search: '',
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getLeads() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_GetAllLeads?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
            
                const {
                    message,
                    leads,
                    industries,
                    channels,
                    accountTypes,
                    managers,
                    reps,
                    forecastCategories
                } = await result.json()

                this.industries = industries
                this.leads = leads
                this.channels = channels
                this.accountTypes = accountTypes
                this.managers = managers
                this.reps = reps
                this.forecastCategories = forecastCategories
                let manager = this.managers.find(item => item['Email Address'].toLowerCase() === this.$store.getters.getUserEmail.toLowerCase())
                if (manager) {
                    this.newLead.manager = manager['User ID']
                } else {
                    this.newLead.manager = 72708
                }
                this.leads.forEach(item => item.checkbox = false)
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateLead(lead) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_UpdateLead?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: lead.Id,
                            update: {
                                Channel_Id: lead.Channel_Id,
                                Account_Type_Id: lead.Account_Type_Id,
                                Industry_Id: lead.Industry_Id,
                                Forecast_Id: lead.Forecast_Id,
                                Sales_Manager: lead.Sales_Manager,
                                External_Sales_Rep: lead.External_Sales_Rep
                            }
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },  
        async createNewLead() {
            try {
                this.newLead.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_CreateTempLead?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/create',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            company: this.newLead.company,
                            email: this.newLead.email,
                            manager: this.newLead.manager,
                            salesRep: this.newLead.salesRep
                        })
                    }
                )
            
                const {
                    message,
                    id
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }

                this.$router.push('/sales/crm/leads/' + id)

                this.newLead.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.newLead.loading = false
                this.snack(error,'error',3000)
            }
        },
        async bulkUpdateLead() {
            try {
                this.bulkUpdateLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_BulkUpdateLeads?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/bulkupdate',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            leads: this.selectedIds,
                            fieldToUpdate: this.fieldToUpdate,
                            valueToUpdate: this.valueToUpdate
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                for (let i = 0; i < this.selectedIds.length; i++) {
                    let lead = this.leads.find(item => item.Id === this.selectedIds[i])
                    lead[this.fieldToUpdate.value] = this.valueToUpdate[this.fieldToUpdate.identifier]
                    lead.checkbox = false
                }
                this.selected = []
                this.fieldToUpdate = {}
                this.valueToUpdate = {}
                this.bulkUpdateLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.bulkUpdateLoading = false
                this.snack(error,'error',3000)
            }
        },
        changeSelection() {
            this.leads.forEach(item => {
                item.checkbox = this.checkAll
                if (this.checkAll) {
                    this.selected.push(item)
                }
            })
            if (!this.checkAll) {
                this.selected = []
            }
            this.anyChecked = this.checkAll 
        },
        selectLead(lead) {
            if (lead.checkbox) {
                this.selected.push(lead)
            } else {
                let index = this.selected.indexOf(lead)
                this.selected.splice(index, 1)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        filteredHeaders() {
            let headers = [
                {
                    text: 'checkbox',
                    align: 'start',
                    sortable: true,
                    value: 'checkbox',
                },
                {
                    text: 'Company',
                    align: 'start',
                    sortable: true,
                    value: 'Company_Name',
                }
            ]
            let validHeaders = this.headers
            Object.keys(this.settings.columns).forEach(key => {
                let column = this.settings.columns[key]
                if (column.value) {
                    let header = validHeaders.find(item => item.value === column.column)
                    if (header) {
                        headers.push(header)
                    }
                }
            })
            return headers
        },
        objectForFieldToUpdate() {
            return this[this.fieldToUpdate.object]
        },
        selectedIds() {
            if (this.selected.length) {
                return this.selected.map(item => item.Id)
            } else {
                return []
            }
        },
        leadFields() {
            return [
                {
                    label: 'Industry',
                    object: 'industries',
                    text: 'Industry',
                    value: 'Industry_Id',
                    identifier: 'Id',
                },
                {
                    label: 'Account Type',
                    object: 'accountTypes',
                    text: 'Account_Type',
                    value: 'Account_Type_Id',
                    identifier: 'Id'
                },
                {
                    label: 'Channel',
                    object: 'channels',
                    text: 'Channel',
                    value: 'Channel_Id',
                    identifier: 'Id'
                },
                {
                    label: 'Forecast Category',
                    object: 'forecastCategories',
                    text: 'Forecast_Name',
                    value: 'Forecast_Id',
                    identifier: 'Forecast_Id'
                },
                {
                    label: 'Manager',
                    object: 'managers',
                    text: 'First Name',
                    value: 'Sales_Manager',
                    identifier: 'User ID'
                },
                {
                    label: 'Sales Rep',
                    object: 'reps',
                    text: 'Agency',
                    value: 'External_Sales_Rep',
                    identifier: 'Id'
                }
            ]
        },
        headers() {
            return [
                {
                    text: 'checkbox',
                    align: 'start',
                    sortable: true,
                    value: 'checkbox',
                },
                {
                    text: 'Company',
                    align: 'start',
                    sortable: true,
                    value: 'Company_Name',
                },
                {
                    text: 'Channel',
                    align: 'start',
                    sortable: true,
                    value: 'Channel_Id',
                },
                {
                    text: 'Account Type',
                    align: 'start',
                    sortable: true,
                    value: 'Account_Type_Id',
                },
                {
                    text: 'Industry',
                    align: 'start',
                    sortable: true,
                    value: 'Industry_Id',
                },
                {
                    text: 'Forecast Category',
                    align: 'start',
                    sortable: true,
                    value: 'Forecast_Id',
                },
                {
                    text: 'Sales Manager',
                    align: 'start',
                    sortable: true,
                    value: 'Sales_Manager',
                },
                {
                    text: 'Sales Rep',
                    align: 'start',
                    sortable: true,
                    value: 'External_Sales_Rep',
                }
            ]
        }
    }
}
</script>

<style>

</style>