<template>
    <v-dialog v-model="dialog" width="50vw">
        <v-card class="pa-4">
            <template v-if="loading">
                <v-progress-linear
                    color="primary"
                    indeterminate
                />
            </template>
            <template v-else>
                <v-card-title class="d-flex justify-center align-center">
                    Products To Compare
                </v-card-title>
                <v-row v-for="(product, index) in products"
                    :key="index"
                    class="d-flex justify-space-between align-center my-4">
                    <!-- the year selector allows any year to be charted for the comparision product -->
                    {{index + 1}}.
                    <v-select
                        class="shrink"
                        :items="getYears"
                        hide-details
                        v-model="product.year"
                        label="Compare Year"
                        dense/>
                    <!-- this selector allows the user to super impose any product over the existing product -->
                    <v-autocomplete
                        label="Add Product to Compare"
                        class="shrink"
                        hide-details
                        return-object
                        item-text="Name"
                        v-model="product.product"
                        :items="productOptions"
                        dense/>
                    <v-menu
                        ref="colorPicker"
                        :close-on-content-click="false"
                        v-model="product.colorModal"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        width="20vw">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon 
                                :color="product.color"
                                v-bind="attrs"
                                v-on="on">
                                mdi-circle
                            </v-icon>
                        </template>
                        <v-card class="d-flex-column align-center">
                            <v-color-picker
                                v-model="product.color"
                                class="ma-2"
                                dot-size="30"
                                hide-inputs/>
                        </v-card>
                    </v-menu>
                    <v-btn color="primary" icon @click="removeProductFromCompareArray(index)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-row>
                <v-row class="d-flex justify-space-around align-center pb-4">
                    <!-- the year selector allows any year to be charted for the comparision product -->
                    <v-select
                        class="shrink"
                        :items="getYears"
                        hide-details
                        v-model="selectedYear"
                        label="Compare Year"
                        dense/>
                    <!-- this selector allows the user to super impose any product over the existing product -->
                    <v-autocomplete
                        label="Add Product to Compare"
                        class="shrink"
                        return-object
                        item-text="Name"
                        hide-details
                        v-model="selectedProduct"
                        :items="productOptions"
                        dense/>
                    <v-btn 
                        icon 
                        color="primary" 
                        :disabled="!selectedYear || !selectedProduct"
                        @click="addNewCompareProductToArray()">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-row>
                <v-card-actions class="d-flex justify-space-around align-center">
                    <v-btn 
                        color="secondary" 
                        @click="$emit('close')">
                        Cancel
                    </v-btn>
                    <v-btn 
                        color="primary" 
                        :disabled="disabled" 
                        @click="getProductInfo()">
                        Add Products
                    </v-btn>
                </v-card-actions>
            </template>
        </v-card>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    beforeMount() {
        this.getProducts()
    },
    props: {
        dialog: {type: Boolean},
        year: {type: Number},
        forecastId: {type: Number}
    },
    data() {
        return {
            productOptions: [],
            products: [],
            seriesData: [],
            selectedYear: '',
            selectedProduct: '',
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getProducts() {
            try {
                this.loading = true
                let url = new URL('https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/compare-products')
                url.searchParams.append('email', this.$store.state.user.email)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_GetCompareProducts?tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    products
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.productOptions = products
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async getProductInfo() {
            try {
                this.loading = true
                let url = new URL('https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/compare-products')
                url.searchParams.append('email', this.$store.state.user.email)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_CompareProductInfo?tab=code
                    url,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            products: this.products,
                            forecastId: this.$props.forecastId
                        })
                    }
                )
                const {
                    message,
                    seriesData
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                } 
                this.seriesData = seriesData
                this.$emit('compareProducts',this.seriesData)
                this.$emit('close')
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        addNewCompareProductToArray() {
            if (this.$props.sku === this.selectedProduct.sku) {
                this.snack('You cannot compare a product to itself','info',3000)
                return
            }
            let productCopy = JSON.parse(JSON.stringify(this.products))
            let includedProducts = productCopy.filter(product => product.product === this.selectedProduct && product.year === this.selectedYear)
            if (includedProducts.length > 0) {
                this.snack('You cannot compare the same product/year twice','info',3000)
                return
            }
            let randomColor = Math.floor(Math.random()*16777215).toString(16);
            this.products.push({
                year: this.selectedYear,
                product: this.selectedProduct,
                color: `#${randomColor}`,
                colorModal: false,
            })
            this.selectedYear = ''
            this.selectedProduct = {}
        },
        removeProductFromCompareArray(index) {
            this.products.splice(index,1)
            this.seriesData.splice(index,1)
            this.$emit('compareProducts',this.seriesData)
            
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        getYears() {
			let years = []
			for (let i = this.$props.year - 3; i < this.$props.year; i++) {
				years.push(i)
			}
			return years
		},
        disabled() {
            if (this.products.length) {
                return false
            } else {
                return true
            }
        }
    }
}
</script>

<style>

</style>