<template>
    <v-card 
        class="pa-4"
        height="100%"
        :width="90 / itemsPerRow + 'vw'" 
        v-if="product">
        <v-row>
            <v-carousel 
                v-if="productImages.length > 0"
                height="100%"
                v-model="currentImage"
                hide-delimiter-background
                hide-delimiters>
                <v-carousel-item
                    v-for="(image, i) in productImages"
                    :key="i">
                    <v-img class="mx-auto" contian :src="image"/>
                </v-carousel-item>
            </v-carousel>
            <v-img class="mx-auto" max-width="" src="../../../assets/placeholder.jpg" v-else/>
        </v-row>
        <v-row justify="start">
                <v-card-title 
                    @click="toProduct()"
                    :style="'cursor: pointer;'"
                    class="pb-0">
                    {{ product.Product_Name }} - {{ product.Option_1 }}
                </v-card-title>
        </v-row>
        <v-row>
            <v-card-subtitle class="pt-0">
                {{ product.SKU }} | {{product.SOH }} left
            </v-card-subtitle>
        </v-row>
        <v-row class="px-4">
            <v-text-field
                type="number"
                label="Quantity"
                v-model="selected"/>
        </v-row>
    </v-card>
    <v-card v-else>
        <v-card-title>
            Whoopsie
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    props:{
        product: {type: Object},
        itemsPerRow: {type: Number}
    },
    data() {
        return {
            selected: 1,
            currentImage: '',
        }
    },
    methods: {
        toProduct() {
            this.$router.push("/store/product/" + this.$props.product.Id)
        }
    },
    computed: {
        productImages() {
            if (!this.$props.product) {
                return []
            }
            let images = this.$props.product.Images
            if (images && images.length) {
                return JSON.parse(images)
            } else {
                return []
            }
        }
    }
}
</script>

<style>

</style>