<template>
  <v-app>
    <v-main>
         <v-row>
            <v-col>
                <v-select
                    v-model="activeFilter"
                    :items="activeItems"
                    item-text="Name"
                    item-value="Value"
                    attach
                    chips
                    label="Active"
                    multiple
                    @change="filterTable()"
                ></v-select>
            </v-col>
            <v-col>
                <v-autocomplete
                    v-model="forecastCategoryFilter"
                    :items="forecastList"
                    item-text="Forecast_Name"
                    item-value="Forecast_Name"
                    attach
                    chips
                    label="Categories"
                    multiple
                    @change="filterTable()"
                >
                    <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="toggleSelectAll">
                            <v-list-item-action>
                                <v-icon :color="forecastCategoryFilter.length > 0 ? 'primary' : ''">
                                    {{ selectAllIcon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Select All
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mb-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 3">
                            <span>{{item.Forecast_Name}}</span>
                        </v-chip>
                        <span v-if="index === 3" class="grey--text text-caption">
                            (+{{ forecastCategoryFilter.length - 3}} others)
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <template>
                    <v-data-table
                        :headers="headers"
                        :items="filteredAccountDetails"
                        item-key="Account Id"
                        :search="search"
                        :sort-desc="[false, true]"
                        multi-sort
                        class="elevation-1"
                        :loading="loading"
                        fixed-header
                    >
                        <template v-slot:top>
                            <v-text-field
                                v-model="search"
                                label="Search"
                                class="mx-4"
                            ></v-text-field>
                        </template>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="(item,index) in items" :key="index">
                                    <td>
                                        <v-switch
                                            @change="updateAccount(item)"
                                            v-model="item.Active"
                                            color="primary"
                                        ></v-switch>
                                    </td>
                                    <td>
                                        {{ item.Company }}
                                    </td>
                                    <td style="text-align: center; vertical-align: middle;">
                                        <v-autocomplete
                                            @change="updateAccount(item)"
                                            v-model="item.Forecast_Name"
                                            :items="forecastList"
                                            item-text="Forecast_Name"
                                            item-value="Forecast_Name"
                                            hide-details
                                            solo
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            @change="updateAccount(item)"
                                            v-model="item.Price_Name"
                                            :items="priceList"
                                            item-text="Name"
                                            item-value="Name"
                                            hide-details
                                            solo
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                            @change="updateAccount(item)"
                                            v-model="item.Cost_Name"
                                            :items="costList"
                                            item-text="Name"
                                            item-value="Name"
                                            hide-details
                                            solo
                                        ></v-autocomplete>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-snackbar
                        v-model="snackbar"
                        :timeout="timeout"
                        :color="snackColor"
                    >
                        <template v-if="!loading">
                            {{ snackText }}
                        </template>
                        <template v-else-if="loading">
                            <v-text-field
                                color="primary"
                                :loading="loading"
                            ></v-text-field>
                        </template>
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack = false"
                            > Close </v-btn>
                        </template>
                    </v-snackbar>
                </template>
            </v-col>
        </v-row>
    </v-main>
  </v-app>
</template>

<style src="../stylesheet.css" scoped></style>

<script>

export default {
  name: 'Accounts',
  beforeMount() {
    document.title = 'Sandbox - Forecast Tool'
    this.$store.dispatch('updateShowWaffle', true)
    this.$store.dispatch('updateNavbarContent',
    [
        {title: 'Home', path: '/sales/forecast/home', icon: 'mdi-home', notification: false},
        {title: 'Assign Lists', icon: 'mdi-account-box', path: '/sales/forecast/accounts', notification: false},
        {title: 'Products', icon: 'mdi-package-variant-closed', path: '/sales/forecast/products', notification: false},
        {title: 'Forecast Categories', icon: 'mdi-shape', path: '/sales/forecast/categories', notification: false},
        {title: 'Bulk Upload', icon: 'mdi-upload', path: '/sales/forecast/bulk-upload', notification: false},
        {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},  
    ]
    )
    this.$store.dispatch('updateNavbarTitle','Forecast Tool')
    this.getAccountDetails();
  },
  created() {
    if(this.$route.params.filter) {
      this.search = this.$route.params.filter
    } else {
      this.search = ''
    }
  },
  data: () => ({
        search: '',
        forecastList: [],
        priceList: [],
        costList: [],
        accountDetails: [],
        activeItems: [{Name:'Active',Value:1},{Name:'Not Active',Value:0}],
        activeFilter: [1],
        forecastCategoryFilter: [],
        filteredAccountDetails: [],
        newCategoryName: '',
        newCategoryPreOrder: 0,
        snackbar: false,
        snackText: '',
        snackColor: '',
        timeout: 3000,
        loading: false,
    }),
    methods: {
        getAccountDetails: function() {
            const url = "https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/accounts/details"
            const request = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            fetch(url, request).then((response) => {
                return response.json()
            }).then((result) => {
                this.forecastList = result.forecastList
                this.priceList = result.priceList
                this.costList = result.costList
                this.filterdAccountDetails = result.accountDetails
                this.accountDetails = result.accountDetails
                this.forecastCategoryFilter = result.forecastList.map(item => item.Forecast_Name)
                this.forecastCategoryFilter.splice(this.forecastCategoryFilter.indexOf("Ignore"),1)
                this.forecastCategoryFilter.splice(this.forecastCategoryFilter.indexOf("Unassigned"),1)
                this.filterTable()
            })
        },
        updateAccount: function(item) {
            console.log(item);
            const Cost_Id = this.costList.filter(e => {return e.Name === item.Cost_Name})[0]['Cost ID']
            const Price_Id = this.priceList.filter(e => {return e.Name === item.Price_Name})[0]['Price ID']
            const Forecast_Id = this.forecastList.filter(e => {return e.Forecast_Name === item.Forecast_Name})[0]['Forecast_Id']

            const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/accounts/details'
            const data = {
                Price_Id: Price_Id,
                Cost_Id: Cost_Id,
                Forecast_Id: Forecast_Id,
                Active: item.Active,
                Account_Id: item['Account ID']
            }
            const request = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }

            this.loading = true
            fetch(url, request).then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json()
                } else {
                    throw Error(response.statusText)
                }
            }).then((result) => {
                this.snack(result.response,'success',false)
            }).catch((error) => {
                this.snack(error,'error',false)
            })
        },
        filterTable: function() {
            this.filteredAccountDetails = [];
            for (let account = 0; account < this.accountDetails.length; account++) {
            let activeFilterResult = this.activeFilter.indexOf(this.accountDetails[account].Active)
            let forecastCategoryResult = this.forecastCategoryFilter.indexOf(this.accountDetails[account].Forecast_Name)
            
            if (activeFilterResult !== -1 && forecastCategoryResult !== -1) {
                this.filteredAccountDetails.push(this.accountDetails[account])
            }
            }
        },
        toggleSelectAll: function() {
            this.$nextTick(() => {
                if (this.selectedAllCategories) {
                    this.forecastCategoryFilter = []
                    setTimeout(() => this.filterTable(), 1000)
                } else {
                    this.forecastCategoryFilter = this.forecastList.map(item => item.Forecast_Name)
                    setTimeout(() => this.filterTable(), 1000)
                }
            })
        },
        snack(text, color, loading) {
			if (loading) {
				this.loading = true
				this.snackbar = true
				this.snackText = text
				this.snackColor = color
				this.timeout = -1
			} else {
				this.loading = false
				this.snackbar = true
				this.snackText = text
				this.snackColor = color
				this.timeout = 2000
			}
		},
    },
    computed: {
        selectedAllCategories () {
            return this.forecastCategoryFilter.length === this.forecastList.length
        },
        selectedSomeCategories () {
            return this.forecastCategoryFilter.length > 0 && !this.selectedAllCategories
        },
        selectAllIcon () {
            if (this.selectedAllCategories) return 'mdi-close-box'
            if (this.selectedSomeCategories) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        headers() {
            return [
                {
                    text: 'Active',
                    align: 'center',
                    value: 'Active',
                    fixed: true
                },
                {
                    text: 'Company',
                    align: 'center',
                    value: 'Company',
                    fixed: true
                },
                {
                    text: 'Forecast Category',
                    align: 'center',
                    value: 'Forecast_Name',
                    fixed: true
                },
                {
                    text: 'Price list',
                    align: 'center',
                    value: 'Price_Name',
                    fixed: true
                },
                {
                    text: 'Cost List',
                    align: 'center',
                    value: 'Cost_Name',
                    fixed: true
                },
            ]
        }
    }
};
</script>
