<template>
    <v-app>
        <v-main>
            <v-card>
                <v-card-title class="d-flex justify-center align-center">
                    404 Page Not Found
                </v-card-title>
                <v-card-text class="text-center">

                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center">
                    
                </v-card-actions>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch("updateShowWaffle", false);
        document.title = "Not Found";
        this.$store.dispatch("updateNavbarContent", []);
        this.$store.dispatch("updateNavbarTitle", "Not Found");
    },
}
</script>

<style>

</style>