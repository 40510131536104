<template>
    <v-app>
        <v-main>
            <v-row class="pt-4">
                <v-col cols="1" class="d-flex justify-center align-center">
                    <v-menu
                        :close-on-content-click="false"
                        offset-y>
                        <template v-slot:activator="{ on, attrs}">
                            Transfer
                            <v-icon v-bind="attrs" v-on="on" color="primary">mdi-transfer</v-icon>
                        </template>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Transfer to</th>
                                    <th v-for="(category, index) in priceInfo" :key="index">
                                        {{ category.Name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Envoy</td>
                                    <td v-for="(category, index) in priceInfo" :key="index">
                                        <v-switch 
                                            class="px-2"
                                            :true-value="1"
                                            :false-value="0"
                                            @change="updateCategory(category)"
                                            v-model="category.To_Envoy"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Cin7</td>
                                    <td v-for="(category, index) in priceInfo" :key="index">
                                        <v-switch 
                                            class="px-2"
                                            :true-value="1"
                                            :false-value="0"
                                            @change="updateCategory(category)"
                                            v-model="category.To_Cin7"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td :colspan="priceInfo.length + 1">
                                        This toggle only applies to FUTURE imports/updates. Unselecting this toggle will not delete that category within the service.<br>
                                        It will only prevent or allow that category to carry through to that service in the future.
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-select
                        prepend-icon="mdi-plus"
                        @click:prepend="newLists.push({Name: '',Start_Date: '', End_Date: ''}); newListDialog = true"
                        :items="listInfo"
                        item-text="Name"
                        class="pr-4 pl-2"
                        hide-details
                        return-object
                        v-model="selectedList"
                        label="Price List"/>
                </v-col>
                <v-col>
                    <v-menu
                        :close-on-content-click="false"
                        :return-value.sync="selectedList.Start_Date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="selectedList.Start_Date"
                                label="Start Date"
                                hide-details
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"/>
                        </template>
                        <v-date-picker
                            v-model="selectedList.Start_Date"
                            no-title
                            @change="updateCostPriceListValidDates()"
                            scrollable/>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-menu
                        :close-on-content-click="false"
                        :return-value.sync="selectedList.End_Date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="selectedList.End_Date"
                                label="End Date"
                                hide-details
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"/>
                        </template>
                        <v-date-picker
                            v-model="selectedList.End_Date"
                            @change="updateCostPriceListValidDates()"
                            no-title
                            scrollable/>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="px-4 py-2">
                <v-col cols="6" class="d-flex">
                    <v-textarea
                        width=100%
                        v-model="quickImportText"
                        label="Copy/Paste Import"
                        hide-details
                        class="pb-0 mb-0"
                        outlined
                        rounded>
                        <template v-slot:prepend>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on">
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>
                                    No Headers<br>
                                    First column is SKU<br>
                                    Second column is price<br>
                                    Import will ONLY apply to current selected category/list
                                </span>
                            </v-tooltip>
                        </template>
                        <template v-slot:append>
                            <v-btn
                                color="primary"
                                icon
                                @click="quickImport()">
                                <v-icon>mdi-send</v-icon>
                            </v-btn>
                        </template>
                    </v-textarea>
                    <v-subheader class="d-flex justify-center align-start pt-0 mt-0">
                        SKUs will only be sent to Envoy if they are not a component and are in the B2B category within Cin7.<br/>
                        If you update an attribute in Cin7, be click the 'Update Products From Cin7 to Database' button in the VPL.
                    </v-subheader>
                </v-col>
                <v-col cols="3" class="flex-column justify-space-around align-center">
                        <v-btn
                            color="primary"
                            @click="downloadPrices(null, 'All', null, 'All')">
                            Download All Lists <v-icon>mdi-download</v-icon>
                        </v-btn>
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    icon
                                    v-bind="attrs"
                                    v-on="on">
                                    By Price Category <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-group
                                    v-for="(item, index) in priceInfo"
                                    :key="index">
                                    <template v-slot:activator>
                                        <v-list-item-icon>
                                            <v-btn
                                                icon
                                                color="primary"
                                                @click="downloadPrices(item.Name, 'All')">
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>    
                                        </v-list-item-icon>
                                        <v-list-item-title>{{ item.Name }}</v-list-item-title>
                                    </template>
                                    <v-list-item
                                        no-action
                                        v-for="(list, index2) in listInfo"
                                        :key="index2"> 
                                        <v-list-item-action>
                                            <v-btn
                                                icon
                                                color="primary"
                                                @click="downloadPrices(item.Name, list.Name)">
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-title>{{ list.Name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>
                        </v-menu>
                        <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    icon
                                    v-bind="attrs"
                                    v-on="on">
                                    By Price List <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-group
                                    v-for="(list, index) in listInfo"
                                    :key="index">
                                    <template v-slot:activator>
                                        <v-list-item-icon>
                                            <v-btn
                                                icon
                                                color="primary"
                                                @click="downloadPrices('All', list.Name)">
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>    
                                        </v-list-item-icon>
                                        <v-list-item-title>{{ list.Name }}</v-list-item-title>
                                    </template>
                                    <v-list-item
                                        no-action
                                        v-for="(item, index2) in priceInfo"
                                        :key="index2"> 
                                        <v-list-item-action>
                                            <v-btn
                                                icon
                                                color="primary"
                                                @click="downloadPrices(item.Name, list.Name)">
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-title>{{ item.Name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>
                        </v-menu>
                </v-col>
                <v-col cols="3">
                    <v-file-input
                        accept=".csv"
                        label="Click here to Upload Cost List"
                        append-icon="mdi-send"
                        @click:append="uploadCsv()"
                        outlined
                        v-model="csvFile"/>
                </v-col>
            </v-row>
            <v-simple-table class="px-4">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Option 1</th>
                        <th>SKU</th>
                        <th>
                            <v-select
                                prepend-icon="mdi-plus"
                                @click:prepend="newCategories.push({
                                    Name: '',
                                    Cin7_Prefix: '',
                                    To_Cin7: 1,
                                    To_Envoy: 1
                                }); reviewNewCategoriesDialog = true"
                                :items="priceInfo"
                                item-text="Name"
                                class="pr-2 pl-4"
                                return-object
                                v-model="selectedCategory"
                                label="Price Category"/>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="selectedPrices && selectedPrices.prices.length === 0">
                    <tr>
                        <td colspan="4" class="grey--text">No Prices Found In {{ selectedPrices.listName }} {{ selectedPrices.name }}</td>
                    </tr>
                </tbody> 
                <tbody v-else-if="selectedPrices">
                    <tr v-for="(price, index) in selectedPrices.prices" :key="index">
                        <td>{{ price.Product_Name }}</td>
                        <td>{{ price.Product_Option }}</td>
                        <td>{{ price.SKU }}</td>
                        <td>
                            <v-text-field
                                v-model="price.Price"
                                solo
                                flat
                                dense
                                prefix="$"
                                @change="masterUpdate([{
                                    SKU: price.SKU, 
                                    'Price ID': selectedCategory['Price ID'],
                                    List_Id: selectedList.Id,
                                    Price: price.Price
                                }])"
                                hide-details/>
                        </td>
                    </tr>
                </tbody>  
            </v-simple-table>
            <v-dialog width = "75vw" v-model="importDialog">
                <v-card width="75vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center text-h4">
                        Import Review
                    </v-card-title>
                    <v-divider/>
                    <v-row class="mb-2 pb-2">
                        <v-col>
                            <v-card-title class="d-flex justify-center align-center text-h5">
                                Price List Review
                            </v-card-title>
                            <v-row class="d-flex justify-center align-center">
                                <v-card-subtitle class="d-flex justify-center align-center text-h6">
                                    <u>List That Will Be Updated</u>
                                </v-card-subtitle>
                            </v-row>
                            <v-row class="d-flex justify-center align-center">
                                <v-list class="d-flex justify-center align-center">
                                    <v-list-item v-for="(list, index) in listNamesThatDoExist" :key="index">
                                        {{ list }}
                                    </v-list-item>
                                </v-list>
                            </v-row>
                            <template v-if="listNamesThatDoNotExist.length">
                                <v-row class="d-flex justify-center align-center">
                                    <v-card-subtitle class="d-flex justify-center align-center text-h6">
                                        <u>Lists That Do Not Exists, Delete or Create?</u>
                                    </v-card-subtitle>
                                </v-row>  
                                <v-row class="d-flex justify-center align-center">
                                    <v-list class="d-flex justify-center align-center">
                                        <v-list-item v-for="(list, index) in listNamesThatDoNotExist" :key="index">
                                            <v-btn
                                                icon
                                                color="red"
                                                @click="listNamesThatDoNotExist.splice(index,1)">
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                            {{ list }}
                                        </v-list-item>
                                    </v-list>
                                </v-row>  
                                <v-row class="d-flex justify-space-around align-center">    
                                    <v-btn @click="deleteLists()">Delete New Lists</v-btn>
                                    <v-btn @click="reviewNewLists()" color="primary">Create New Lists</v-btn>
                                </v-row>    
                            </template>
                        </v-col>
                        <v-divider vertical/>
                        <v-col>
                            <v-card-title class="d-flex justify-center align-center text-h5">
                                Price Category Review
                            </v-card-title>
                            <v-row class="d-flex justify-center align-center">
                                <v-card-subtitle class="text-h6">
                                    <u>Categories That Will Be Updated</u>
                                </v-card-subtitle>
                            </v-row>
                            <v-row class="d-flex justify-center align-center">
                                <v-list>
                                    <v-list-item v-for="(category, index) in categoryNamesThatDoExist" :key="index">
                                        {{ category }}
                                    </v-list-item>
                                </v-list>
                            </v-row>
                            <template v-if="categoryNamesThatDoNotExist.length">
                                <v-row class="d-flex justify-center align-center">
                                    <v-card-subtitle class="d-flex justify-center align-center text-h6">
                                        <u>Categories That Do Not Exists</u>
                                    </v-card-subtitle>
                                </v-row>    
                                <v-row class="d-flex justify-center align-center">
                                    <v-list>
                                        <v-list-item v-for="(category, index) in categoryNamesThatDoNotExist" :key="index">
                                            <v-btn
                                                icon
                                                color="red"
                                                @click="categoryNamesThatDoNotExist.splice(index,1)">
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                            {{ category }}
                                        </v-list-item>
                                    </v-list>
                                </v-row>
                                <v-row class="d-flex justify-space-around align-center">
                                    <v-btn @click="deleteCategories()">Delete New Category</v-btn>
                                    <v-btn @click="reviewNewCategories()" color="primary">Create New Category</v-btn>
                                </v-row>
                            </template>
                        </v-col>    
                    </v-row>
                    <v-card-actions class="d-flex justify-space-around align-center">
                        <v-btn 
                            @click="importDialog = false">
                            Cancel
                        </v-btn>
                        <v-btn 
                            class="d-flex justify-center align-center"
                            :disabled="disableBulkUpload"
                            color="primary"
                            @click="masterBulkUpdate()">
                            Submit
                        </v-btn>
                    </v-card-actions>
                    <div class="red--text d-flex justify-space-around align-center" v-if="disableBulkUpload">
                        Please Deal with new Lists and Categories before submitting or make sure there is at least one list and one category being updated
                    </div>
                    <v-card-subtitle class="text-center">
                        Please ensure that all unnecessary skus and lines are removed from the upload. The more lines there are in the bulk update, the longer and more unstable the Cin7 upload becomes.
                    </v-card-subtitle>
                </v-card>
            </v-dialog>
            <v-dialog v-model="newListDialog" width="50vw" persistent>
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Select Dates For New Price Lists
                    </v-card-title>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>List Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(names, index) in newLists" :key="index">
                                <td>
                                    <v-text-field
                                        v-model="names.Name"/>
                                </td>
                                <td>
                                    <v-menu
                                        ref="startDateMenu"
                                        v-model="startDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="names.Start_Date"
                                                label="Picker in menu"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"/>
                                        </template>
                                        <v-date-picker
                                            v-model="names.Start_Date"
                                            no-title
                                            scrollable>
                                            <v-spacer/>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="startDateMenu = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.startDateMenu[index].save(names.Start_Date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </td>
                                <td>
                                    <v-menu
                                        ref="endDateMenu"
                                        v-model="endDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="names.End_Date"
                                                label="Picker in menu"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"/>
                                        </template>
                                        <v-date-picker
                                            v-model="names.End_Date"
                                            no-title
                                            scrollable>
                                            <v-spacer/>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="endDateMenu = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.endDateMenu[index].save(names.End_Date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card-actions class="d-flex justify-space-around align-center">
                        <v-btn 
                            @click="newListDialog = false; newLists = []">
                            Cancel
                        </v-btn>
                        <v-btn 
                            @click="createNewListReplacementCombinations(); newListDialog = false; reviewNewListsDialog = true" 
                            :loading="loading"
                            :disabled="disableNewListReview"
                            color="primary">
                            Review
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="reviewNewListsDialog" persistent>
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Choose Cin7 Lists To Replace With The New Lists
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center align-center">
                        Looks like you will need to replace {{ newListReplacementCombinations.length }} lists. It's recommended to replace the oldest season first
                    </v-card-subtitle>  
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>New List</th>
                                <th>Cin7 Name</th>
                                <th>Cin7 List To Replace</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(list, index) in newListReplacementCombinations" :key="index">
                                <td>
                                    {{ list.Name }}
                                </td>
                                <td>
                                    {{ list.cin7 }}
                                </td>
                                <td>
                                    <v-select
                                        :items="cin7PriceLists"
                                        item-text="Name"
                                        return-object
                                        v-model="list.replace"
                                        label="Cin7 List To Replace"/>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card-actions class="d-flex justify-space-around align-center">
                        <v-btn 
                            @click="reviewNewListsDialog = false; newListReplacementCombinations = []">
                            Cancel
                        </v-btn>
                        <v-btn 
                            @click="createNewLists(); reviewNewListsDialog = false" 
                            :loading="loading"
                            :disabled="disableNewListSubmit"
                            color="primary">
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="reviewNewCategoriesDialog" 
                persistent
                width="50vw">
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Review New Categories
                    </v-card-title>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Cin7 Prefix</th>
                                <th>To Envoy</th>
                                <th>To Cin7</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in newCategories" :key="index">
                                <td>
                                    <v-text-field
                                        label="Name"
                                        v-model="item.Name"/>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="item.Cin7_Prefix"
                                        label="Cin7 Prefix"
                                        suffix="xFW24"
                                        :rules="[value => value.length <= 10 || 'Must be less than 10 characters']"
                                        hint="i.e. ECOMxFW24"/>
                                </td>
                                <td>
                                    <v-switch
                                        v-model="item.To_Envoy"
                                        :false-value="0"
                                        :true-value="1"
                                        color="primary"/>
                                </td>
                                <td>
                                    <v-switch
                                        v-model="item.To_Cin7"
                                        :false-value="0"
                                        :true-value="1"
                                        color="primary"/>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card-actions class="d-flex justify-space-around align-center">
                        <v-btn 
                            @click="newCategories = []; reviewNewCategoriesDialog = false">Cancel</v-btn>
                        <v-btn 
                            @click="createCategories()" 
                            :disabled="disableNewCategorySubmit"
                            :loading="loading"
                            color="primary">Submit</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="downloadDialog" width="50vw">
                <v-card width="50vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Download {{ csvName }}
                    </v-card-title>
                    <v-card-text v-if="showGif" class="d-flex justify-center align-center">
                        <iframe :src="randomGif" width="377" height="480" frameBorder="0" class="giphy-embed"></iframe>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center align-center">
                        <download-csv
                            class   = "btn btn-default"
                            :data   = "data"
                            :name    = "csvName">
                            <v-btn
                                color="primary"
                                :disabled="showGif"
                                @click="closeDownloadDialog()">
                                Download
                            </v-btn>
                        </download-csv>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="confirmationDialog" width="50vw">
                <v-card width="50vw">
                    <v-card-title class="d-flex justify-center align-center">
                        Where all SKUs were sent to
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center align-center">
                        Key
                    </v-card-subtitle>
                    <v-card-subtitle class="d-flex justify-center align-center">
                        <v-simple-table><tr>
                            <td bgcolor="green" class="white--text pl-2 pr-2">Transfered</td>
                            <td bgcolor="orange" class="white--text px-2">Some Transfered</td>
                            <td bgcolor="grey" class="white--text pl-2 pr-2">Not Transfered</td>
                        </tr></v-simple-table>
                    </v-card-subtitle>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>SKU</th>
                                <th>Database</th>
                                <th>Cin7</th>
                                <th>Envoy</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in confirmationTable" :key="index">
                                <td>{{ item.name }}</td>
                                <td>{{ item.sku }}</td>
                                <td bgcolor="green"/>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td 
                                            v-bind="attrs"
                                            v-on="on"
                                            :bgcolor="item.cin7.color"/>
                                    </template>
                                    <span>
                                        <p v-for="(category, index) in item.cin7.categories" :key="index">
                                            {{ category }}
                                        </p>
                                    </span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td 
                                            v-bind="attrs"
                                            v-on="on"
                                            :bgcolor="item.envoy.color"/>
                                    </template>
                                    <span>
                                        <p v-for="(category, index) in item.envoy.categories" :key="index">
                                            {{ category }}
                                        </p>
                                    </span>
                                </v-tooltip>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-dialog>
            <v-dialog v-model="invalidSkuDialog" width="50vw">
                <v-card width="50vw">
                    <v-card-title class="d-flex justify-center align-center">
                        Invalid {{ (invalid.length === 1) ? 'SKU' : 'SKUs' }} in upload
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center align-center">
                        Please remove or update {{ (invalid.length === 1) ? '' : 'all of ' }}the following {{ (invalid.length === 1) ? 'SKU' : 'SKUs' }}
                    </v-card-subtitle>
                    <v-card-text class="d-flex justify-center align-center">
                        <span v-for="(sku, index) in invalid" :key="index">
                            {{ sku[0] }} on line {{ sku[1] }} 
                        </span>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            color="primary"
                            @click="invalidSkuDialog = false">
                            I accept my mistakes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>

export default {
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - Price Is Righteous'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Price Is Righteous')
        this.getInfo()
        if (localStorage.selectedPriceCategory) {
            this.selectedCategory = JSON.parse(localStorage.selectedPriceCategory)
        }
        if (localStorage.selectedPriceList) {
            this.selectedList = JSON.parse(localStorage.selectedPriceList)
        }
    },
    watch: {
        selectedCategory: function() {
            localStorage.selectedPriceCategory = JSON.stringify(this.selectedCategory)
        },
        selectedList: function() {
            localStorage.selectedPriceList = JSON.stringify(this.selectedList)
        }
    },
    data() {
        return {
            confirmationDialog: false,
            update: [],
            downloadDialog: false,
            invalidSkuDialog: false,
            showGif: false,
            reviewNewCategoriesDialog: false,
            reviewNewListsDialog: false,
            newListDialog: false,
            importDialog: false,
            endDateMenu: false,
            startDateMenu: false,
            data: [],
            envoyProducts: [],
            allProducts: [],
            invalid: [],
            newListReplacementCombinations: [],
            csvName: '',
            quickImportText: '',
            newLists: [],
            newCategories: [],
            selectedCategory: {
                Name: '',
                'Price ID': '',
                To_Envoy: 0
            },
            selectedList: {
                End_Date: '',
                Id: 0,
                Name: '',
                Start_Date: ''
            },
            listEndDate: '',
            listStartDate: '',
            prices: [],
            priceInfo: [],
            cin7PriceLists: [],
            formattedPrices: [],
            listInfo: [],
            priceMatrix: [],
            csvFile: [],
            jsonObject: [],
            categoryNamesThatDoNotExist: [],
            categoryNamesThatDoExist: [],
            listNamesThatDoNotExist: [],
            listNamesThatDoExist: [],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/PriceLists_GetInfo?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/pricelist',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                const {
                    message,
                    prices,
                    priceInfo,
                    listInfo,
                    priceMatrix,
                    envoyProducts,
                    allProductSkus,
                    cin7PriceLists
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.prices = prices
                this.priceMatrix = priceMatrix
                this.priceInfo = priceInfo
                this.listInfo = listInfo
                this.envoyProducts = envoyProducts
                this.allProducts = allProductSkus
                this.cin7PriceLists = cin7PriceLists.map(item => item.Name)

                if (this.selectedCategory && !this.selectedCategory.Name) {
                    this.selectedCategory = priceInfo[0]
                }
                if (this.selectedList && !this.selectedList.Name) {
                    this.selectedList = listInfo[listInfo.length - 1]
                }
                
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateCostPriceListValidDates() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_UpdateCostPriceList?newFunction=true&tab=code
                    'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/productdetails/costpricelistinfo',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: this.selectedList.Id,
                            name: this.selectedList.Name,
                            startDate: this.listStartDate,
                            endDate: this.listEndDate
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async createNewLists() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_CreateCostPriceList?tab=code
                    'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/productdetails/costpricelistinfo',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({insert: this.newListReplacementCombinations})
                    }
                )
                const {
                    message,
                    lists
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.createNewListDialog = false
                this.newListDialog = false
                this.newLists = []
                this.listInfo = lists

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async downloadPrices(categoryName, listName) {
            console.log(`List: ${listName} | Category: ${categoryName}`)
            let tempMatrix = JSON.parse(JSON.stringify(this.priceMatrix))
            let keysToKeep = ['List Name','SKU','Product Name','Product Option']
            //if it is a specific category only include that column
            if (listName === 'All' && categoryName !== 'All') {
                keysToKeep.push(categoryName)
                let keysToRemove = Object.keys(tempMatrix[0]).filter(key => !keysToKeep.includes(key));
                for (let i = tempMatrix.length - 1; i >= 0; i--) {
                    keysToRemove.forEach(key => delete tempMatrix[i][key]);
                } 
            } else if (listName !== 'All' && categoryName === 'All') {
                tempMatrix = tempMatrix.filter(item => item['List Name'] === listName)
            } else if (listName !== 'All' && categoryName !== 'All') {
                let keysToRemove = []
                keysToKeep.push(categoryName)
                keysToRemove = Object.keys(tempMatrix[0]).filter(key => !keysToKeep.includes(key));
                for (let i = tempMatrix.length - 1; i >= 0; i--) {
                    if (tempMatrix[i]['List Name'] === listName) {
                        for (let k = 0; k < keysToRemove.length; k++) {
                            delete tempMatrix[i][keysToRemove[k]]
                        }
                    } else {
                        tempMatrix.splice(i,1)
                    }
                }
            }
            this.data = tempMatrix

            this.csvName =  `${listName} ${categoryName} Prices.csv`
            if (listName === 'All' && categoryName === 'All') {
                this.csvName =  'All Prices.csv'
            }
            this.downloadDialog = true
        },
        closeDownloadDialog() {
            this.showGif = true
            this.snack('Downloading... (May take longer than this dialog is present)','info',15000)
            setTimeout(() => {
                this.downloadDialog = false
                this.showGif = false
            },15000)
        },
        uploadCsv() {
            this.categoryNamesThatDoNotExist = []
            this.categoryNamesThatDoExist = []
            this.listNamesThatDoNotExist = []
            this.listNamesThatDoExist = []

            let reader = new FileReader();

            reader.readAsText(this.csvFile)

            reader.onload = () => {
                const csv=require('csvtojson')
                csv({
                    noheader: false,
                    output:"json",
                    flatKeys: true,
                }).fromString(reader.result)
                .then((jsonObject) => {
                    this.jsonObject = jsonObject
                    let listNames = [...new Set(jsonObject.map(item => item['List Name']))];
                    let existingListNames = [... new Set(this.listInfo.map(item => item.Name))]
                    for (let i = 0; i < listNames.length; i++) {
                        let exists = existingListNames.indexOf(listNames[i])
                        if (exists === -1) {
                            this.listNamesThatDoNotExist.push(listNames[i])
                        } else {
                            this.listNamesThatDoExist.push(listNames[i])
                        }
                    }
                    
                    let categoryNames = Object.keys(jsonObject[0])
                    let columnsToRemove = ['List Name','SKU','Product Name','Product Option']

                    for (let i = 0; i < columnsToRemove.length; i++) {
                        let index = categoryNames.indexOf(columnsToRemove[i])
                        categoryNames.splice(index,1)
                    }

                    let existingCategoryNames = [... new Set(this.priceInfo.map(item => item.Name))]
                    for(let i = 0; i < categoryNames.length; i++) {
                        let exists = existingCategoryNames.indexOf(categoryNames[i])
                        if (exists === -1) {
                            this.categoryNamesThatDoNotExist.push(categoryNames[i])
                        } else {
                            this.categoryNamesThatDoExist.push(categoryNames[i])
                        }
                    }
                })
            }
            this.importDialog = true
        },
        deleteCategories() {
            for (let i = 0; i < this.jsonObject.length; i++) {
                for (let c = 0; c < this.categoryNamesThatDoNotExist.length; c++) {
                    delete this.jsonObject[i][this.categoryNamesThatDoNotExist[c]]
                }
            }
            this.categoryNamesThatDoNotExist = []
        },
        deleteLists() {
            this.jsonObject = this.jsonObject.filter(item => !this.listNamesThatDoNotExist.includes(item['List Name']))
            this.listNamesThatDoNotExist = []
        },
        reviewNewCategories() {
            this.newCategories = [];
            for (let i = 0; i < this.categoryNamesThatDoNotExist.length; i++) {
                this.newCategories.push({
                    Name: this.categoryNamesThatDoNotExist[i],
                    Cin7_Prefix: '',
                    To_Cin7: 1,
                    To_Envoy: 1
                })
            }
            this.reviewNewCategoriesDialog = true
        },
        async updateCategory(category) {
            try {
                this.snack('Loading...','info',-1)
                let update = { ...category}
                delete update['Price ID']

                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/PriceTool_UpdatePriceCategory?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/price/updatecategory',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            update: update,
                            id: category['Price ID']
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async createCategories() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/PriceTool_CreateCategories?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/price/createcategory',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            categories: this.newCategories
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.newCategories = []
                this.categoryNamesThatDoNotExist = []

                this.reviewNewCategoriesDialog = false
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        reviewNewLists() {
            this.newListDialog = true
            this.newLists = []
            for (let i = 0; i < this.listNamesThatDoNotExist.length; i++) {
                this.newLists.push({
                    Name: this.listNamesThatDoNotExist[i],
                    Start_Date: '',
                    End_Date: ''
                })
            }
        },
        async masterBulkUpdate() {
            let listKeys = {}
            let categoryKeys = {}
            for (let i = 0; i < this.listInfo.length; i++) {
                listKeys[this.listInfo[i].Name] = this.listInfo[i].Id
            }
            for (let i = 0; i < this.priceInfo.length; i++) {
                categoryKeys[this.priceInfo[i].Name] = this.priceInfo[i]['Price ID']
            }
            let update = [];
            let copy = JSON.parse(JSON.stringify(this.jsonObject))
            for (let i = 0; i < copy.length; i++) {
                delete copy[i]['Product Name']
                delete copy[i]['Product Option']
                let sku = copy[i].SKU
                let listId = listKeys[copy[i]['List Name']]
                delete copy[i].SKU
                delete copy[i]['List Name']
                let categories = Object.keys(copy[i])
                for (let r = 0; r < categories.length; r++) {
                    let categoryId = categoryKeys[categories[r]]
                    update.push({
                        Price: Number(copy[i][categories[r]]) || 0,
                        'Price ID': categoryId,
                        List_Id: listId,
                        SKU: sku
                    })
                }
            }
            await this.masterUpdate(update)
            this.importDialog = false
        },
        async masterUpdate(update) {
            let uniqueSkus = [... new Set(update.map(item => item.SKU))]
            this.invalid = [];
            for (let i = 0; i < uniqueSkus.length; i++) {
                let valid = this.allProducts.indexOf(uniqueSkus[i])
                if (valid === -1) {
                    this.invalid.push([uniqueSkus[i], i+1])
                }
            }
            if (this.invalid.length === 0) {
                this.update = update
                if (update.length === 1) this.snack('Loading...', 'info', -1)

                const [cin7Categories, envoyCategories] = this.priceInfo.reduce((acc, item) => {
                    if (item.To_Cin7 === 1) acc[0].add(item['Price ID'])
                    if (item.To_Envoy === 1) acc[1].add(item['Price ID'])
                    return acc
                }, [new Set(), new Set()])
                const [cin7Update, envoyUpdate] = update.reduce((acc, item) => {
                    if (cin7Categories.has(item['Price ID'])) acc[0].push(item)
                    if (envoyCategories.has(item['Price ID']) && this.envoyProducts.indexOf(item.SKU) !== -1) acc[1].push(item)
                    return acc
                }, [[], []])

                let snackText = "Sent to: Database"
                await this.uploadDatabasePrices(update)
                
                if (envoyUpdate.length > 0) {
                    // await this.uploadEnovyPrices(envoyUpdate) 
                    snackText += " | Envoy"
                }

                if (cin7Update.length > 0) {
                    this.uploadCin7Prices(cin7Update)
                    snackText += " | Cin7"
                }

                (update.length === 1) ? this.snack(snackText, 'success', 3000) : this.confirmationDialog = true
            } else {
                this.invalidSkuDialog = true
            }
            
        },
        async uploadDatabasePrices(update) {
            try {
                //this.snack('Updating Database...','info',-1)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sales_PriceQuickImport?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/price',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            update: update
                        })
                    }
                )
                const {
                    message,
                    prices
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.prices = prices
                //this.snack('Updated Updated','success',3000)
            } catch (error) {
                //this.snack(error,'error',3000)
            }
        },
        uploadCin7Prices(update) {
            //this.snack('Sent update to Cin7, you will get an email when it is done', 'info',5000)
            navigator.sendBeacon(
                'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/price/cin7prices/createcin7prices', 
                JSON.stringify({update: update, user: this.$store.getters.getUserEmail})
            )
        },
        async quickImport() {
            let rows = this.quickImportText.split('\n')
            let update = [];
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].length) {
                    let price = rows[i].split('\t')[1]
                    price = Number(price.replace("$","").trim())
                    
                    update.push({
                        SKU: rows[i].split('\t')[0],
                        Price: price,
                        List_Id: this.selectedList.Id,
                        'Price ID': this.selectedCategory['Price ID']
                    })
                }
                
            }
            await this.masterUpdate(update)
            this.quickImportText = ''
        },
        createNewListReplacementCombinations() {
            let cin7Lists = this.priceInfo.filter(item => item.To_Cin7 === 1)
            for (let i = 0; i < this.newLists.length; i++) {
                for (let r = 0; r < cin7Lists.length; r++) {
                    this.newListReplacementCombinations.push({
                        Name: this.newLists[i].Name,
                        Start_Date: this.newLists[i].Start_Date,
                        End_Date: this.newLists[i].End_Date,
                        cin7: cin7Lists[r].Cin7_Prefix + 'x' + this.newLists[i].Name,
                        replace: ''
                    })
                }
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        selectedPrices() {
            let temp = []
            for (let i = 0; i < this.priceInfo.length; i++) {
                for (let r = 0; r < this.listInfo.length; r++) {
                    temp.push({
                        name: this.priceInfo[i].Name,
                        id: this.priceInfo[i]['Price ID'],
                        startDate: this.listInfo[r].Start_Date,
                        endDate: this.listInfo[r].End_Date,
                        listName: this.listInfo[r].Name,
                        listId: this.listInfo[r].Id,
                        prices: this.prices.filter(item => 
                            item['Price ID'] === this.priceInfo[i]['Price ID'] && 
                            item.List_Id === this.listInfo[r].Id
                        )
                    })
                }
            }
            return temp.find(item => 
                item.listId === this.selectedList.Id &&
                item.id === this.selectedCategory['Price ID']
            )
        },
        disableNewCategorySubmit() {
            for (let i = 0; i < this.newCategories.length; i++) {
                if (this.newCategories[i].Cin7_Prefix.length === 0 || this.newCategories[i].Cin7_Prefix.length > 10) {
                    return true
                }
            }
            return false
        },
        newListsSubmitDisabled() {
            for (let i = 0; i < this.newLists.length; i++) {
                if (
                    !this.newLists[i].Start_Date.length ||
                    !this.newLists[i].End_Date.length
                ) {
                    return true
                }
            }
            return false
        },
        disableBulkUpload() {
            if (
                this.categoryNamesThatDoNotExist.length > 0 ||
                this.listNamesThatDoNotExist.length > 0 ||
                this.categoryNamesThatDoExist.length === 0 ||
                this.listNamesThatDoExist.length === 0
            ) {
                return true
            } else {
                return false
            }
        },
        confirmationTable() {
            if (this.update.length > 0) {
                let categoryKeys = {}

                for (let i = 0; i < this.priceInfo.length; i++) {
                    categoryKeys[this.priceInfo[i]['Price ID']] = {
                        Name: this.priceInfo[i].Name,
                        To_Cin7: this.priceInfo[i].To_Cin7,
                        To_Envoy: this.priceInfo[i].To_Envoy
                    }
                }
                let table = []
                let products = {}

                let allCategories = [... new Set(this.update.map(item => item['Price ID']))]
                let allCategoriesLength = allCategories.length
                for (let i = 0; i < this.update.length; i++) {
                    if (!(this.update[i].SKU in products)) {
                        products[this.update[i].SKU] = {
                            To_Cin7: [],
                            To_Envoy: [],
                            Cin7_Color: 'green',
                            Envoy_Color: 'green'
                        }
                    }
                    let category = categoryKeys[this.update[i]['Price ID']]
                    if (category.To_Cin7 === 1) {
                        products[this.update[i].SKU].To_Cin7.push(category.Name)
                    }
                    if (category.To_Envoy === 1 && this.envoyProducts.includes(this.update[i].SKU)) {
                        products[this.update[i].SKU].To_Envoy.push(category.Name)
                    }

                }
                
                for (let key in products) {
                    let details = this.prices.find(item => item.SKU === key)
                    let productName = details.Product_Name
                    if (details.Product_Option) {
                        productName += ` ${details.Product_Option}`
                    }
                    table.push({
                        name: productName,
                        sku: key,
                        cin7: {
                            color: (products[key].To_Cin7.length === 0) ? 'grey' : (products[key].To_Cin7.length === allCategoriesLength) ? 'green': 'orange',
                            categories: products[key].To_Cin7
                        },
                        envoy: {
                            color: (products[key].To_Envoy.length === 0) ? 'grey' : (products[key].To_Envoy.length === allCategoriesLength) ? 'green': 'orange',
                            categories: products[key].To_Envoy
                        }
                    })
                }
                return table
            } else {
                return []
            }
        },
        disableNewListReview() {
            return this.newLists.filter(item => item.Start_Date.length === 0 || item.End_Date.length === 0 || item.Name.length === 0 || item.Start_Date >= item.End_Date).length > 0
        },
        disableNewListSubmit() {
            return this.newListReplacementCombinations.filter(item => item.replace.length === 0).length > 0
        },
        randomGif() {
            let gifs = [
                'https://giphy.com/embed/IRFQYGCokErS0',
                'https://giphy.com/embed/xThuWu82QD3pj4wvEQ',
                'https://giphy.com/embed/3o7WTAkv7Ze17SWMOQ',
                'https://giphy.com/embed/T8Dhl1KPyzRqU'
            ]
            return gifs[Math.floor(Math.random() * (gifs.length - 1))]
        }
    }
}
</script>

<style>

</style>