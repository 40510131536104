<template>
  <v-app>
    <v-main>
        <v-row class="pt-8 px-8" justify="center" align="center">
            <v-select
                :items="distinctCategory"
                label="Category"
                v-model="selectedCategory"/>
            <v-switch
                label="Show Resolved"
                v-model="showResolved"
                color="primary"/>
        </v-row>
        <v-row justify="center" align="center" class="px-8">
            <v-simple-table>
                <thead>
                    <tr>
                        <td>
                            Date
                        </td>
                        <td>
                            Category
                        </td>
                        <td>
                            Error
                        </td>
                        <td>
                            Related Id
                        </td>
                        <td>
                            Mark as Complete
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in errors" :key="index">
                        <td>
                            {{ new Date(item.Date).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})  }}
                        </td>
                        <td>
                            {{ item.Category }}
                        </td>
                        <td>
                            {{ item.Error }}
                        </td>
                        <td>
                            {{ item.Related_Id }}
                        </td>
                        <td>
                            <v-checkbox
                                v-model="item.Resolved"
                                color="primary"
                                @change="resolveError(item.Id, item.Resolved)"
                                hide-details/>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-row>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - Errors'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Errors')
        this.getErrors()
    },
    data() {
        return {
            errors: [],
            selectedCategory: 'All',
            showResolved: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getErrors() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_GetError?tab=code
                    "https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/error", 
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    errors
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.errors = errors
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async resolveError(id, input) {
            let value = 0
            if (input) {
                value = 1
            }
            try {
                this.snack('Loading...','info',false)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_ResolveError?newFunction=true&tab=code
                    "https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/error", 
                    {
                        method: 'PUT',
                        body: JSON.stringify({
                            id: id,
                            value: value
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.snack(message,'success',3000)
            } catch (error) {
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        filteredErrors() {
            if (this.selectedCategory !== 'All') {
                if (this.showResolved) {
                    return this.errors.filter(item => item.Function === this.selectedCategory)
                } else {
                    return this.errors.filter(item => item.Function === this.selectedCategory && item.Resolved === 0)
                }
            } else {
                if (this.showResolved) {
                    return this.errors
                } else {
                    return this.errors.filter(item => item.Resolved === 0)
                }
            }
        },
        distinctCategory() {
            let array = [... new Set(this.errors.map(item => item.Category))]
            array.push('All')
            return array
        }
    }
}
</script>

<style>

</style>