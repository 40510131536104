var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fliud":""}},[_c('v-dialog',{staticStyle:{"overflow":"hidden"},attrs:{"width":"50vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var dialogAttrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","darky":""}},'v-btn',Object.assign({}, dialogAttrs, tooltipAttrs),false),Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v("Settings")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4",staticStyle:{"overflow":"hidden"},attrs:{"width":"50vw"}},[_c('v-card-title',{staticClass:"d-flex justify-center align-center"},[_vm._v(" Settings ")]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('v-switch',{attrs:{"change":_vm.change()},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Sticky Total Row")])]}}])},[_vm._v(" Have total row stick to the bottom as you scroll ")])]},proxy:true}]),model:{value:(_vm.otherSettings.showTotalRow),callback:function ($$v) {_vm.$set(_vm.otherSettings, "showTotalRow", $$v)},expression:"otherSettings.showTotalRow"}}),_c('v-switch',{attrs:{"change":_vm.change()},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Collapse After Submit")])]}}])},[_vm._v(" After submiting forecast, close forecast window ")])]},proxy:true}]),model:{value:(_vm.otherSettings.hideModalAfterSubmit),callback:function ($$v) {_vm.$set(_vm.otherSettings, "hideModalAfterSubmit", $$v)},expression:"otherSettings.hideModalAfterSubmit"}}),_c('v-switch',{attrs:{"change":_vm.change()},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{staticClass:"d-flex justify-center",attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Speed Round")])]}}])},[_vm._v(" Only show input forecast when clicking of forecast month and product"),_c('br'),_vm._v(" Keyboard Shortcut: Alt + S ")])]},proxy:true}]),model:{value:(_vm.otherSettings.speedRound),callback:function ($$v) {_vm.$set(_vm.otherSettings, "speedRound", $$v)},expression:"otherSettings.speedRound"}})],1),_c('v-col',[_c('v-switch',{attrs:{"change":_vm.change()},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Save on Month Change")])]}}])},[_vm._v(" Automatically save changes when switching months or products ")])]},proxy:true}]),model:{value:(_vm.otherSettings.saveOnMonthChange),callback:function ($$v) {_vm.$set(_vm.otherSettings, "saveOnMonthChange", $$v)},expression:"otherSettings.saveOnMonthChange"}}),_c('v-switch',{attrs:{"change":_vm.change()},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Hide Inactive Products")])]}}])},[_vm._v(" Hides all the inactive products from view ")])]},proxy:true}]),model:{value:(_vm.otherSettings.hideInactiveProducts),callback:function ($$v) {_vm.$set(_vm.otherSettings, "hideInactiveProducts", $$v)},expression:"otherSettings.hideInactiveProducts"}}),_c('v-switch',{attrs:{"change":_vm.change()},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-tooltip',{attrs:{"color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Show Price Warning")])]}}])},[_vm._v(" Will warn user if there is a mismatch between the previous forecasted price and the current price selected ")])]},proxy:true}]),model:{value:(_vm.otherSettings.showPriceWarning),callback:function ($$v) {_vm.$set(_vm.otherSettings, "showPriceWarning", $$v)},expression:"otherSettings.showPriceWarning"}})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }