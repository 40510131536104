<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['chartData','yAxisValue'],
    mounted() {
        this.renderChart(this.chartData, this.options)
    },
    computed: {
        options() {
            return {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false
                        },
                        min: 0,
                        ticks: {
                            callback: function(value) {
                                if (this.yAxisValue === "Order_Total") {
                                    if(parseInt(value) >= 1000){
                                        return "$" + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    } else {
                                        return "$" + value.toFixed(2);
                                    }
                                } else {
                                    if(parseInt(value) >= 1000){
                                        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    } else {
                                        return value.toFixed(2);
                                    }
                                }
                            }
                        }
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                },
            }
        }
    }
}
</script>
