<template>
    <v-app>
        <v-main>
            <v-row class="my-4" justify="center">
                <v-img src="../../../../assets/homePicture.jpg" max-width="75vw" max-height="50vh" class="pa-4"/>
                <v-card width="75vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Submit Potential Lead
                    </v-card-title>
                    <v-row>
                        <v-text-field
                            label="Company Name"
                            v-model="company"
                            class="px-4"/>
                        <v-text-field
                            label="Email"
                            v-model="email"
                            class="px-4"/>
                        <v-text-field
                            label="Location"
                            v-model="location"
                            class="px-4"/>
                    </v-row>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            color="primary"
                            :loading="loading"
                            @click="submitLead()">
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Matador Leads'
        this.$store.dispatch('updateShowWaffle', false)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Matador Leads')
    },
    data() {
        return {
            company: '',
            email: '',
            location: '',
            stateOptions: [],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async submitLead() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/LeadGeneration_CreateLead?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/leadgeneration',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            company: this.company,
                            email: this.email,
                            location: this.location
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.company = ''
                this.email = ''
                this.location = ''

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {

    }
}
</script>

<style>

</style>