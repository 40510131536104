<template>
    <v-simple-table width="75vw">
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Product</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">Quantity</th>
                    <th class="text-left">Employee Dollars Used</th>
                    <th class="text-left">Total Paid</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                    <td>{{ new Date(order.Date).toLocaleDateString() }}</td>
                    <td>{{ getProductName(order.SKU) }}</td>
                    <td>{{ order.Description }}</td>
                    <td>{{ order.Qty }}</td>
                    <td>{{ order.Employee_Dollars_Used | currency }}</td>
                    <td>{{ order.Total_To_Pay | currency }}</td>
                </tr>
                <tr>
                    <td colspan="4"><strong>Totals:</strong></td>
                    <td>{{ orders.reduce((acc, curr) => acc + curr.Employee_Dollars_Used, 0) | currency }}</td>
                    <td>{{ orders.reduce((acc, curr) => acc + curr.Total_To_Pay, 0) | currency }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    name: 'historyTable',
    props: {
        orders: Array,
        products: Array
    },
    data() {
        return {
            getProductName(sku) {
                return this.$props.products.find(product => product.SKU === sku).Name
            },
        }
    },
    methods: {

    }
}
</script>

<style>

</style>