<template>
    <v-app>
        <v-main class="pt-16">
            <v-row class="pt-8" justify="center" align="center" v-if="invalidDialog === null">
                <v-card class="pa-4" width="50vw">
                    <v-card-title class="d-flex justify-center align-center">
                        Validating URL{{ threeDots }}<span class="white--text">{{ ".".repeat(3 - threeDots.length) }}</span>
                    </v-card-title>
                    <v-row justify="center" align="center">
                        <v-progress-circular indeterminate/>
                    </v-row>
                </v-card>
            </v-row>
            <v-row class="pt-8" justify="center" align="center" v-else-if="invalidDialog !== true">
                <v-card class="pa-4" :loading="loading">
                    <v-card-title>Add Store Address To Matador's Store Locator</v-card-title>
                    <vuetify-google-autocomplete
                        class="px-4 pb-4"
                        hint="Please choose from the autocomplete options. If problems, refresh page."
                        id="addressMap"
                        v-model="Address_Show"
                        v-on:placechanged="getAddressData">
                        <template v-slot:prepend>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-on="on"
                                        v-bind="attrs"
                                        color="primary"
                                        @click="location = center; reverseGeocode()">
                                        <v-icon>mdi-map-marker</v-icon>
                                    </v-btn>
                                </template>
                                <span>Use Current Location</span>
                            </v-tooltip>
                        </template>
                    </vuetify-google-autocomplete> 
                    <v-row class="flex-column" justify="center" align="center">
                        <GmapMap
                            :center="(location.lat) ? location : center"
                            :options="mapOptions"
                            :zoom="15"
                            style="width: 50vw; height: 50vh">
                            <gmapInfoWindow 
                                :options="infoOptions" 
                                :opened="(location.lat) ? true : false"
                                :position="(tempLocation.lat) ? tempLocation : location"/>
                            <GmapMarker
                                v-if="location.lat && location.lng"
                                :position="(tempLocation.lat) ? tempLocation : location"
                                :clickable="true"
                                :draggable="true"
                                :icon="{ url: require('../../../assets/marker.png'), scaledSize: { width: 42, height: 55 } }"
                                @drag="changeLocation($event.latLng)"/>
                        </GmapMap>
                        <v-card-text class="text-center" v-if="location.lat && location.lng">
                            Does this location look correct? If not, please drag it to the desired location.
                        </v-card-text>
                        <v-btn
                            v-if="tempLocation.lat && location.lat"
                            color="primary"
                            @click="tempLocation = {}">
                            Reset Marker
                        </v-btn>
                    </v-row>
                    <v-row justify="center" align="center" v-if="location.lat && location.lng" class="pb-4">
                        <v-text-field
                            v-model="name"
                            class="px-4"
                            label="What should this location be named? (Required)"
                            hide-details/>
                    </v-row>
                    <v-row justify="center" align="center" v-if="location.lat && location.lng" class="pb-4">
                        <v-text-field
                            v-model="Final_Address_Show"
                            class="px-4"
                            label="Displayed Address (Required)"
                            hide-details>
                            <template v-slot:prepend v-if="currentLocations.length">
                                <v-menu>
                                    <template v-slot:activator="{ on: menu, attrs }">
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn
                                            color="primary"
                                            dark
                                            icon
                                            v-bind="attrs"
                                            v-on="{ ...tooltip, ...menu }">
                                            <v-icon>mdi-auto-fix</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Suggestions</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list>
                                        <v-list-item 
                                            v-for="(item, index) in currentLocations" 
                                            :key="index"
                                            @click="chooseAddress(item)">
                                            {{ item.formatted_address }}
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </v-row>
                    <v-row justify="center" align="center" v-if="location.lat && location.lng" class="pb-4">
                        <v-text-field
                            v-model="phone"
                            class="px-4"
                            :maxlength="32"
                            label="Phone Number (Optional: Please format as needed and add country code if necessary.)"
                            hide-details/>
                    </v-row>
                    <v-card-actions class="d-flex justify-center align-center" v-if="location.lat && location.lng">
                        <v-btn 
                            @click="addAddress()"
                            color="primary"
                            :loading="submitLoading"
                            :disabled="!name.length || !Final_Address_Show.length">
                            Add
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
            <v-dialog v-model="success" width="50vw">
                <v-card width="50vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Address Submited
                    </v-card-title>
                    <v-card-text class="text-center">
                        You can view all the addresses <a href="https://www.matadorup.com/storelocator">here</a>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            :loading="loading"
                            @click="anotherOne()"
                            color="primary">
                            Add Another
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="invalidDialog" width="50vw">
                <v-card width="50vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Invalid URL
                    </v-card-title>
                    <v-card-text class="text-center">
                        Looks like the URL you clicked on is not valid. 
                        Please contact your sales rep, a sales manager at Matador, 
                        or <a href="mailto:sales@matadorup.com">Sales@Matadorup.com</a> 
                        to get a valid URL.
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', false)
        document.title = 'Matador Store Locator Portal'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Store Locator Portal')
    },
    async created() {
        if (this.$route.query.id) {
            await this.isValid(this.$route.query.id)
        } else {
            this.invalidDialog = true
        }
        navigator.geolocation.getCurrentPosition(
            position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
            },
            error => {
                console.log(error.message)
                this.center = {
                    lat: 0,
                    lng: 0
                }
            }
        )

    },
    data() {
        return {
            success: false,
            threeDots: '',
            Address_Show: '',
            Final_Address_Show: '',
            phone: '',
            id: 0,
            name: '',
            center: {
                lat: 40.018698, 
                lng: -105.245614
            },
            place_id: '',
            location: {},
            currentLocations: [],
            tempLocation: {},
            invalidDialog: null,
            submitLoading: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        getAddressData: async function (addressData) {
            await this.getAddressDetails(addressData.place_id)
        },
        async isValid(id) {
            this.threeDotIncremement()
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_ValidateURL?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/validate?email=austin@matadorup.com&id=${id}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    valid
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.id = id
                this.invalidDialog = !valid
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async reverseGeocode() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_ReverseGeocode?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/reverse-geocode?email=austin@matadorup.com`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            location: (this.tempLocation.lat) ? this.tempLocation : this.location
                        })
                    }
                )
                const {
                    message,
                    details
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.currentLocations = details.results
                this.Address_Show = details.results[0].formatted_address
                this.Final_Address_Show = this.Address_Show
                this.place_id = details.results[0].place_id

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        threeDotIncremement() {
            let dotCount = 0
            this.threeDots = ''
            setInterval(() => {
                this.threeDots = '.'.repeat(dotCount % 4)
                dotCount++
            }, 500)
        },
        chooseAddress(address) {
            this.Final_Address_Show = address.formatted_address
            this.location = address.geometry.location
            this.place_id = address.place_id
            this.center = this.location
        },
        changeLocation(location) {
            this.tempLocation = {
                lat: location.lat(),
                lng: location.lng()
            }
        },
        anotherOne() {
            this.name = ''
            this.location = {}
            this.tempLocation = {}
            this.currentLocations = []
            this.Final_Address_Show = ''
            this.Address_Show = ''
            this.success = false
        },
        formatShow(address) {
            let string = address.address1 + ', '
            if (address.address2 && address.address2.length > 0) {
                string = string + address.address2 + ', '
            }
            string = string + address.city + ', ' +
                    address.state + ' ' + 
                    address.zip + ' ' + 
                    address.country
            let check = string.replaceAll(",","")
            check = check.replaceAll(" ","")
            this.Address_Show = (check.length === 0) ? '' : string
        },
        async getAddressDetails(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_GetLatLong?tab=code
                    'https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/lat-long?id=' + id,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    location,
                    address
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.location = location
                this.Final_Address_Show = address
                this.place_id = id
                this.loading = false
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        falseAddAddress() {
            this.snack(`I Don't Do Anything Yet ¯\\_(ツ)_/¯`,'info',3000)
        },
        async addAddress() {
            try {
                this.submitLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_AddAddress?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/add-address?email=austin@matadorup.com`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Lat: (this.tempLocation.lat) ? this.tempLocation.lat : this.location.lat,
                            Lng: (this.tempLocation.lng) ? this.tempLocation.lng : this.location.lng,
                            Place_Id: this.place_id,
                            Address: this.Final_Address_Show,
                            Name: this.name,
                            Contact_Id: this.id,
                            Phone: this.phone
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.success = true
                this.submitLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.submitLoading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        infoOptions() {
            let content = ''
            if (this.phone) {
                content = `<h4>${this.name}</h4><br/>
                    <div>${this.Final_Address_Show}</div><br>
                    <div>Phone Number: ${this.phone}</div>`
            } else {
                content = `<h4>${this.name}</h4><br/>
                    <div>${this.Final_Address_Show}</div><br>`
            }
            return {
                content: content,
                pixelOffset: {
                    width: 0,
                    height: -50
                }
            }
        },
        mapOptions() {
            return {
                zoomControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false
            }
        }
    }
}
</script>

<style>

</style>