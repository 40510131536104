<template>
  <v-snackbar
    v-model="active"
    :timeout="timeout"
    :color="color">
    <!-- the text to display during the notification -->
    {{ text }}
</v-snackbar>
</template>

<script>
export default {
    Name: 'Snack Bar',
    props: {
        text: {type: String},
        color: {type: String},
        timeout: {type: Number},
        active: {type: Boolean}
    },
}
</script>

<style>

</style>