<template>
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card>
            <customTitle 
                :title="'Envoy Contact Not Created'" 
                :name="'envoyContact'" 
                @close="$emit('close')"/>
            <v-card-text>
                <p>
                    If a contact has not been created for Enovy, follow the steps below:
                </p>
                <ol>
                    <li>In the CRM, click on the Accounts 'To Envoy' button (under 'Envoy Details') or navigate to their account page in Envoy manually</li>
                    <li>Select the Users Tab in the bottom right</li>
                    <li>Select Create User</li>
                    <li>Fill out the form with the following information:
                        <ul>
                            <li>First Name</li>
                            <li>Last Name</li>
                            <li>Email</li>
                            <li>Role: Customer User</li>
                        </ul>
                    </li>
                    <li>Click "Save"</li>
                    <li>Done!</li>
                </ol>
            </v-card-text>
        </v-card>    
    </v-dialog>
</template>

<script>
import customTitle from './title.vue';
export default {
    name: 'EnvoyContact',
    props: {
        dialog: Boolean
    },
    components: {
        customTitle
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>

<style>

</style>