<template>
    <v-app>
        <v-main>
            <v-row class="pt-8" justify="center" align="center">
                <v-card width="90%" height="100%" class="pa-4" :loading="loading">
                    <template v-if="details.Company_Name">
                        <v-card-title v-if="!details.Denied" class="pb-2 d-flex justify-space-between align-center">
                            {{ details.Company_Name}}
                            <v-btn
                                v-if="details.NDA_Sent === 0"
                                color="secondary"
                                :loading="NDAloading"
                                @click="sendNDA()">
                                SEND NDA
                            </v-btn>
                            <v-card-subtitle
                                class="green--text pb-0"
                                v-else-if="details.NDA_Sent === 1">
                                <v-icon color="green">mdi-check</v-icon> NDA Sent
                            </v-card-subtitle>
                        </v-card-title>
                        <v-card-title v-else class="red--text">
                            {{ details.Company_Name}}: DENIED
                        </v-card-title>
                        <v-row>
                            <v-col>
                                <v-card class="pa-4" height="100%">
                                    <v-card-title class="d-flex justify-center align-center">
                                        General Information
                                    </v-card-title>
                                    <v-row class="pa-3">
                                        <v-text-field
                                            v-model="details.First_Name"
                                            label="First Name"
                                            class="pr-2"
                                            hide-details
                                            @change="updateLead(details)"/>
                                        <v-text-field
                                            v-model="details.Last_Name"
                                            label="Last Name"
                                            class="pr-2"
                                            hide-details
                                            @change="updateLead(details)"/>
                                    </v-row>
                                    <v-text-field
                                        v-model="details.Email"
                                        label="Email"
                                        class="pr-2"
                                        hide-details
                                        @change="updateLead(details)"/>
                                    <v-text-field
                                        v-model="details.Phone"
                                        label="Phone"
                                        class="pr-2"
                                        hide-details
                                        @change="updateLead(details)"/>
                                    <v-text-field
                                        v-model="details.Website"
                                        label="Website"
                                        class="pr-2"
                                        hide-details
                                        @change="updateLead(details)"/>
                                    <v-text-field
                                        v-model="details.Door_Count"
                                        label="Door Count"
                                        type="number"
                                        class="pr-2"
                                        hide-details
                                        @change="updateLead(details)"/>
                                    <v-checkbox
                                        v-model="details.Online_Store"
                                        label="Online Store"
                                        hide-details
                                        @change="updateLead(details)"/>
                                    <v-checkbox
                                        v-model="details.Majority_Online_Business"
                                        label="Majority Online Business"
                                        hide-details
                                        @change="updateLead(details)"/>
                                </v-card>
                            </v-col>
                            <v-col class="px-4 pb-2">
                                <v-card class="pa-4" height="100%">
                                    <v-card-title class="d-flex justify-center align-center">
                                        Classifications
                                    </v-card-title>
                                    <v-select
                                        label="Channel"
                                        class="pr-2"
                                        :items="channels"
                                        item-value="Id"
                                        item-text="Channel"
                                        hide-details
                                        @change="updateLead(details)"
                                        v-model="details.Channel_Id"/>
                                    <v-autocomplete
                                        label="Account Type"
                                        class="pr-2"
                                        :items="accountTypes"
                                        item-value="Id"
                                        item-text="Account_Type"
                                        hint="Selecting 'Distributor (B2B)' will move this lead to Envoy2"
                                        persistent-hint
                                        @change="setPrice(details.Account_Type_Id)"
                                        v-model="details.Account_Type_Id"/>
                                    <v-autocomplete
                                        label="Industry"
                                        class="pr-2"
                                        :items="industries"
                                        item-value="Id"
                                        item-text="Industry"
                                        hide-details
                                        @change="updateLead(details)"
                                        v-model="details.Industry_Id"/>
                                    <v-autocomplete
                                        label="Forecast Category"
                                        class="pr-2"
                                        :items="forecastCategories"
                                        item-value="Forecast_Id"
                                        item-text="Forecast_Name"
                                        hide-details
                                        @change="updateLead(details)"
                                        v-model="details.Forecast_Id"/>
                                    <v-autocomplete
                                        label="Internal Manager"
                                        class="pr-2"
                                        :items="managers"
                                        item-value="User ID"
                                        item-text="First Name"
                                        hide-details
                                        @change="updateLead(details)"
                                        v-model="details.Sales_Manager">
                                        <template v-slot:selection="{ item }">
                                            {{ item['First Name'] }} {{ item['Last Name']}}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ item['First Name'] }} {{ item['Last Name']}}
                                        </template>
                                    </v-autocomplete>
                                    <v-autocomplete
                                        label="Sales Rep"
                                        class="pr-2"
                                        :items="reps"
                                        item-value="Id"
                                        item-text="Agency"
                                        hide-details
                                        @change="updateLead(details)"
                                        v-model="details.External_Sales_Rep"/>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-autocomplete
                                                label="Primary Price List"
                                                class="pr-2"
                                                :items="priceCategories"
                                                item-value="Price ID"
                                                item-text="Name"
                                                hide-details="auto"
                                                hint="The purchase price in Envoy"
                                                @change="updateLead(details)"
                                                v-model="details.Primary_Price_Id"/>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-autocomplete
                                                label="Secondary Price List"
                                                class="pr-2"
                                                :items="priceCategories"
                                                item-value="Price ID"
                                                item-text="Name"
                                                hide-details="auto"
                                                hint="The comparison price in Envoy"
                                                @change="updateLead(details)"
                                                v-model="details.Secondary_Price_Id"/>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete
                                        label="Cost List"
                                        class="pr-2"
                                        :items="costCategories"
                                        item-value="Cost ID"
                                        item-text="Name"
                                        hide-details
                                        @change="updateLead(details)"
                                        v-model="details.Cost_Id"/>
                                </v-card>
                            </v-col>
                            <v-col class="px-4 pb-2">
                                <v-card class="pa-4" height="100%">
                                    <v-card-title class="d-flex justify-center align-center">
                                        Technical Information
                                    </v-card-title>
                                    <v-text-field
                                        v-model="details.Shipping_Account"
                                        label="Shipping Account"
                                        hide-details
                                        @change="updateLead(details)"/>
                                    <v-text-field
                                        v-model="details.AP_Email"
                                        label="AP Email"
                                        hide-details
                                        @change="updateLead(details)"/>
                                    <v-text-field
                                        v-model="details.Billing_Company"
                                        label="Billing Company"
                                        hide-details
                                        @change="updateLead(details)"/>
                                        <v-select
                                            :items="['ACH Transfer','Wire Transfer', 'Credit Card','VEEM']"
                                            v-model="details.Payment_Type"
                                            @change="updateLead(details)"
                                            label="Payment Type"/>
                                    <v-checkbox
                                        v-model="details.Prevent_Envoy_Import"
                                        label="Prevent Envoy Import"
                                        @change="updateLead(details)"/>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card class="pa-4" height="100%">
                                    <v-card-title class="d-flex justify-center align-center pt-0">
                                        Addresses
                                    </v-card-title>
                                    <v-row justify="center" align="center">
                                        <v-col cols="1" class="pr-0 d-flex justify-end align-center">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn 
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon
                                                        class="px-0 d-flex justify-end align-center"
                                                        @click="openAddressDialog('Billing Address', true)" 
                                                        color="primary">
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Add Address Manually
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="11" class="pl-0">
                                            <vuetify-google-autocomplete
                                                class="pr-4 pl-0"
                                                label="Billing Address"
                                                hint="Please choose from the autocomplete options. If any problems, select the pencil."
                                                id="billingMap"
                                                :disabled="poBoxCheck"
                                                v-model="Billing_Address_Show"
                                                v-on:placechanged="getBillingAddressData"
                                                placeholder="Billing Address"/>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="start" align="center">
                                        <v-col cols="1" class="px-0 d-flex justify-end align-center">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn 
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon
                                                        class="px-0 d-flex justify-end align-center"
                                                        @click="openAddressDialog('Shipping Address', true)" 
                                                        color="primary">
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Add Address Manually
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="11" class="pl-0">
                                            <vuetify-google-autocomplete
                                                class="pr-4 pl-0"
                                                label="Shipping Address"
                                                hint="Please choose from the autocomplete options. If any problems, select the pencil."
                                                id="shippingMap"
                                                v-model="Shipping_Address_Show"
                                                v-on:placechanged="getShippingAddressData"
                                                placeholder="Shipping Address"/>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card class="pa-4" height="100%">
                                    <v-card-title class="d-flex justify-center align-center py-0">
                                        Buyers
                                        <v-menu offset-y :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="primary"
                                                dark
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card class="pa-4">
                                                <v-card-title class="d-flex justify-center align-center">
                                                    Add Buyer
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-text-field
                                                        label="First Name"
                                                        hide-details
                                                        v-model="newBuyer.First_Name"/>
                                                    <v-text-field
                                                        label="Last Name"
                                                        hide-details
                                                        v-model="newBuyer.Last_Name"/>
                                                    <v-text-field
                                                        label="Email"
                                                        hide-details
                                                        v-model="newBuyer.Email"/>
                                                </v-card-text>
                                                <v-card-actions class="d-flex justify-center align-center">
                                                    <v-btn
                                                        color="primary"
                                                        :disabled="!newBuyer.First_Name.length || !newBuyer.Last_Name.length || !newBuyer.Email.length"
                                                        :loading="newContactLoading"
                                                        @click="createNewBuyer()">
                                                        Create
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                    </v-card-title>
                                    <v-carousel
                                        v-if="contacts.length > 0"
                                        light
                                        :continuous="false"
                                        :cycle="false"
                                        :show-arrows="false"
                                        delimiter-icon="mdi-minus"
                                        height="100%"
                                        class="mb-4"
                                        hide-delimiter-background>
                                        <v-carousel-item v-for="(item, index) in contacts" :key="index">
                                                <v-text-field
                                                    label="First Name"
                                                    hide-details
                                                    @change="updateLeadContact(item)"
                                                    v-model="item.First_Name"/>
                                                <v-text-field
                                                    label="Last Name"
                                                    hide-details
                                                    @change="updateLeadContact(item)"
                                                    v-model="item.Last_Name"/>
                                                <v-text-field
                                                    label="Email"
                                                    hide-details
                                                    @change="updateLeadContact(item)"
                                                    v-model="item.Email"/>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card class="pa-4" height="100%">
                                    <v-card-title class="d-flex justify-center align-center py-0">
                                        Comments
                                        <v-btn
                                            icon
                                            @click="updateLead(details)">
                                            <v-icon color="primary" class="pl-4">mdi-content-save-outline</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-textarea
                                        v-model="details.Comments"/>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-card-actions class="d-flex justify-space-around align-center pt-2 mt-2">
                            <v-menu 
                                :close-on-content-click="false"
                                v-model="deniedMenu"
                                offset-x>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        color="error"
                                        :disabled="details.Denied == 1 ? true : false">
                                        Deny  
                                    </v-btn>
                                </template>
                                <v-card class="pa-4">
                                    <v-card-title class="pb-1 d-flex justify-center align-center">
                                        Why?
                                    </v-card-title>
                                    <v-card-subtitle class="d-flex justify-center align-center py-2">
                                        This reason will be shared with the lead <br/>and accompanying sales rep if applicable.
                                    </v-card-subtitle>
                                    <v-text-field
                                        class="pt-0"
                                        label="Reason"
                                        v-model="deniedReason"
                                        hide-details/>
                                    <v-card-actions class="d-flex justify-space-around align-center">
                                        <v-btn @click="deniedMenu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn @click="denyLead()" :disabled="!this.deniedReason.length" color="error">
                                            Deny
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                            <v-btn
                                v-if="details.Denied === 1 && isAdmin"
                                color="secondary"
                                @click="undenyLead()">
                                Un-Deny
                            </v-btn>
                            <v-btn @click="deleteLead()" color="error">
                                Delete
                            </v-btn>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-on="on">
                                        <v-btn
                                            color="primary"
                                            v-bind="attrs"
                                            @click="convertLead()"
                                            :disabled="convertDisabled">
                                            Convert
                                        </v-btn>
                                    </div>  
                                </template>
                                <span v-if="convertDisabled">
                                    <div v-for="(message, index) in convertMessages" :key="index">
                                        {{ message }}
                                    </div>
                                </span>
                                <span v-else>
                                    This Action Cannot Be Undone
                                </span>
                            </v-tooltip>    
                        </v-card-actions>
                    </template>
                </v-card>
            </v-row>
            <!-- Overlay loading for converting lead -->
            <v-overlay
                v-if="convertLeadLoading"
                z-index="0">
                <v-progress-circular
                    indeterminate
                    color="primary"/>
            </v-overlay>
            <v-dialog
                width="50vw"
                v-model="convertLeadError.dialog">
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        {{ convertLeadError.type }}
                    </v-card-title>
                    <v-card-text class="center-text">
                        {{ convertLeadError.message }}
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            @click="convertLeadError.dialog = false" color="primary">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog 
                v-model="enterAddressDialog"
                width="50vw"
                persistent>
                <v-card class="overflow-y-auto pa-4" height="110%" width="50vw" :loading="loading">
                    <v-card-title class="d-flex justify-center align-center pb-4">
                        Confirm {{ addressType }}
                    </v-card-title>
                    <v-card-subtitle class="text-center" v-if="addressType === 'Shipping Address'">
                        Shipping addresses CANNOT be PO boxes
                    </v-card-subtitle>
                    <v-row class="px-4" justify="center" align="center" v-if="addressType === 'Billing Address'">
                        <v-checkbox
                            color="secondary"
                            label="PO Box"
                            append-icon="mdi-package-variant-closed"
                            v-model="poBoxCheck"/>
                    </v-row>
                    <v-row v-if="!poBoxCheck && addressType === 'Shipping Address'" justify="center" align="center" class="flex-column" width="100vw">
                        <v-text-field
                            label="Address 1"
                            v-model="tempShippingAddress.address1"
                            class="px-4"/>
                        <v-text-field
                            label="Address 2"
                            v-model="tempShippingAddress.address2"
                            class="px-4"/>
                        <v-text-field
                            label="City"
                            v-model="tempShippingAddress.city"
                            class="px-4"/>
                        <v-text-field
                            label="State"
                            v-model="tempShippingAddress.state"
                            class="px-4"/>
                        <v-text-field
                            label="Zip Code"
                            v-model="tempShippingAddress.zip"
                            class="px-4"/>
                        <v-text-field
                            label="Country"
                            v-model="tempShippingAddress.country"
                            class="px-4"/>
                    </v-row>
                    <v-row v-else-if="!poBoxCheck && addressType === 'Billing Address'" justify="center" align="center" class="flex-column" width="100vw">
                        <v-text-field
                            label="Address 1"
                            v-model="tempBillingAddress.address1"
                            class="px-4"/>
                        <v-text-field
                            label="Address 2"
                            v-model="tempBillingAddress.address2"
                            class="px-4"/>
                        <v-text-field
                            label="City"
                            v-model="tempBillingAddress.city"
                            class="px-4"/>
                        <v-text-field
                            label="State"
                            v-model="tempBillingAddress.state"
                            class="px-4"/>
                        <v-text-field
                            label="Zip Code"
                            v-model="tempBillingAddress.zip"
                            class="px-4"/>
                        <v-text-field
                            label="Country"
                            v-model="tempBillingAddress.country"
                            class="px-4"/>
                    </v-row>
                    <v-row justify="center" align="center" v-if="poBoxCheck" class="px-4 flex-column">
                        <v-text-field
                            label="PO Box Number"
                            v-model="tempBillingAddress.address1"
                            class="px-4"/>
                        <v-text-field
                            label="PO Box City"
                            v-model="tempBillingAddress.city"
                            class="px-4"/>
                        <v-text-field
                            label="PO Box State"
                            v-model="tempBillingAddress.state"
                            class="px-4"/>
                        <v-text-field
                            label="PO Box Zip Code"
                            v-model="tempBillingAddress.zip"
                            class="px-4"/>
                        <v-text-field
                            label="PO Box Contry"
                            v-model="tempBillingAddress.country"
                            class="px-4"/>
                    </v-row>
                    <v-row justify="space-around" align="center" class="mb-2">
                        <v-btn 
                            color="secondary"
                            @click="cancelAddress()">
                            Cancel
                        </v-btn>
                        <v-btn 
                            color="primary"
                            @click="fillOutAddress()">
                            Confirm
                        </v-btn>
                    </v-row>
                </v-card>
            </v-dialog>
        </v-main>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-app>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch("updateShowWaffle", true);
        document.title = "Sandbox - CRM";
        this.$store.dispatch("updateNavbarContent", [
            { title: "Leads", path: "/sales/crm/leads/home", icon: "mdi-account-box-outline", notification: false },
            { title: "Accounts", icon: "mdi-domain", path: "/sales/crm/accounts/home", notification: false },
            {title: 'Admin', path: '/sales/crm/admin/home', icon: 'mdi-cog', notification: false},
            {title: 'Batches', path: '/sales/crm/batches', icon: 'mdi-account-convert', notification: false},
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ]);
        this.$store.dispatch("updateNavbarTitle", "CRM Leads");
        this.id = window.location.href.split("/").at(-1);
        this.getLeadDetails();
    },
    data() {
        return {
            dialog: false,
            id: "",
            details: {},
            industries: [],
            channels: [],
            contacts: [],
            accountTypes: [],
            managers: [],
            reps: [],
            forecastCategories: [],
            priceCategories: [],
            costCategories: [],
            deniedMenu: false,
            deniedReason: '',
            addressType: '',
            newBuyer: {
                First_Name: '',
                Last_Name: '',
                Email: ''
            },
            tempShippingAddress: {
                address1: '',
                address2: '',
                city: '',
                zip: '',
                state: '',
                country: ''
            },
            tempBillingAddress: {
                address1: '',
                address2: '',
                city: '',
                zip: '',
                state: '',
                country: ''
            },
            enterAddressDialog: false,
            Billing_Address_Show: '',
            Shipping_Address_Show: '',
            poBoxCheck: false,
            loading: false,
            NDAloading: false,
            convertLeadLoading: false,
            newContactLoading: false,
            convertLeadError: {
                dialog: false,
                message: '',
                type: ''
            },
            snackBar: false,
            snackText: "",
            snackColor: "success",
            timeout: 3000,
        };
    },
    methods: {
        async getLeadDetails() {
            try {
                this.loading = true;
                const result = await fetch(
                //Lambda Function: 
                "https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/details?id=" + this.id, {
                    method: "GET",
                    Headers: {
                        "Content-Type": "application/json",
                    }
                });
                const { 
                    message, 
                    details, 
                    industries, 
                    channels, 
                    accountTypes,
                    managers, 
                    reps, 
                    contacts,
                    forecastCategories,
                    priceCategories,
                    costCategories
                } = await result.json();
                this.details = details[0];
                this.industries = industries;
                this.channels = channels;
                this.accountTypes = accountTypes;
                this.managers = managers;
                this.reps = reps;
                this.forecastCategories = forecastCategories;
                this.contacts = contacts
                this.costCategories = costCategories
                this.priceCategories = priceCategories

                let billingAddress = {
                    address1: this.details.Billing_Address_1,
                    address2: this.details.Billing_Address_2,
                    city: this.details.Billing_City,
                    state: this.details.Billing_State,
                    zip: this.details.Billing_Zip,
                    country: this.details.Billing_Country
                }
                this.formatShow('Billing_Address_Show',billingAddress)
                let shippingAddress = {
                    address1: this.details.Shipping_Address_1,
                    address2: this.details.Shipping_Address_2,
                    city: this.details.Shipping_City,
                    state: this.details.Shipping_State,
                    zip: this.details.Shipping_Zip,
                    country: this.details.Shipping_Country
                }
                this.formatShow('Shipping_Address_Show',shippingAddress)
                this.loading = false;
                this.snack(message, "success", 3000);
            }
            catch (error) {
                this.loading = false;
                this.snack(error, "error", 3000);
            }
        },
        async sendNDA() {
            try {
                this.NDAloading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_SendNDA?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/sendnda',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: this.details.Id,
                            companyName: this.details.Company_Name,
                            email: this.details.Email
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.details.NDA_Sent = 1
                this.NDAloading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.NDAloading = false
                this.snack(error,'error',3000)
            }
        },
        async undenyLead() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_UpdateLead?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: this.details.Id,
                            update: {
                                Denied: 0
                            }
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.details.Denied = 0
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async deleteLead() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_DeleteLead?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads?id=' + this.details.Id,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.loading = false
                this.snack(message,'success',3000)
                this.$router.push('/sales/crm/leads/home')
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateLead(lead) {
            try {
                this.loading = true
                if (typeof lead.Billing_Address == "object") {
                    lead.Billing_Address = JSON.stringify(lead.Billing_Address)
                }
                if (typeof lead.Shipping_Address == "object") {
                    lead.Shipping_Address = JSON.stringify(lead.Shipping_Address)
                }
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_UpdateLead?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: lead.Id,
                            update: {
                                AP_Email: lead.AP_Email,
                                Billing_Address_1: lead.Billing_Address_1,
                                Billing_Address_2: lead.Billing_Address_2,
                                Billing_City: lead.Billing_City,
                                Billing_State: lead.Billing_State,
                                Billing_Zip: lead.Billing_Zip,
                                Billing_Country: lead.Billing_Country,
                                Shipping_Address_1: lead.Shipping_Address_1,
                                Shipping_Address_2: lead.Shipping_Address_2,
                                Shipping_City: lead.Shipping_City,
                                Shipping_State: lead.Shipping_State,
                                Shipping_Zip: lead.Shipping_Zip,
                                Shipping_Country: lead.Shipping_Country,
                                Billing_Company: lead.Billing_Company,
                                Company_Name: lead.Company_Name,
                                Door_Count: lead.Door_Count,
                                Email: lead.Email,
                                First_Name: lead.First_Name,
                                Last_Name: lead.Last_Name,
                                Online_Store: lead.Online_Store,
                                Majority_Online_Business: lead.Majority_Online_Business,
                                Phone: lead.Phone,
                                Payment_Type: lead.Payment_Type,
                                Shipping_Account: lead.Shipping_Account,
                                Website: lead.Website,
                                Channel: lead.Channel,
                                Account_Type_Id: lead.Account_Type_Id,
                                Industry_Id: lead.Industry_Id,
                                Forecast_Id: lead.Forecast_Id,
                                Sales_Manager: lead.Sales_Manager,
                                External_Sales_rep: lead.External_Sales_rep,
                                Primary_Price_Id: lead.Primary_Price_Id,
                                Secondary_Price_Id: lead.Secondary_Price_Id,
                                Cost_Id: lead.Cost_Id,
                                Comments: lead.Comments,
                                Prevent_Envoy_Import: lead.Prevent_Envoy_Import
                            }
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async setPrice(accountTypeId) {
            if (accountTypeId === 1) {
                this.details.Primary_Price_Id = 5
                this.details.Secondary_Price_Id = 1
            }
            await this.updateLead(this.details)
        },
        async createNewBuyer() {
            try {
                this.newContactLoading = true
                this.newBuyer.Lead_Id = this.details.Id
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_CreateLeadContact?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/contacts',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(this.newBuyer)
                    }
                )
                const {
                    message,
                    contacts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.contacts = contacts
                this.newBuyer = {
                    First_Name: '',
                    Last_Name: '',
                    Email: ''
                }
                this.newContactLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.newContactLoading = false
                this.snack(error,'error',3000)
            }
        },
        async updateLeadContact(contact) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_UpdateLeadContacts?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/contacts',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            update: {
                                First_Name: contact.First_Name,
                                Last_Name: contact.Last_Name,
                                Email: contact.Email
                            },
                            id: contact.Id
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async sendEmail(v) {
            try {
                let subject = "Matador Credit Reference For " + this.details.Company_Name
                let body = `<html><body>Hi ${v.Primary_Contact},<br>
                            ${this.details.Company_Name} has listed ${v.Company_Name} as a trade reference.<br>
                            We would appreciate it if you would complete this <a href='https://clearmanlabs.com/#/Credit_Reference?id=${v.Id}'>form</a><br>
                            (https://clearmanlabs.com/#/Credit_Response?id=${v.Id})<br>
                            Please contact our Credit Manager, Christina, at (720) 242-6437 if you have any questions<br>
                            Thank you<body><html>`
                let to = [v.Email]
                v.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_SendEmail?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/email',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            subject: subject,
                            body: body,
                            to: to
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()

                //increment the amount of times this reference has been contacted
                let count = Number(v.Sent_Count)
                count += 1
                let d = new Date()
                d = d.toISOString()
                d.replace("T"," ")
                d = d.split(".")[0]
                const result2 = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_UpdateCreditReference?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/creditreference',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Id: v.Id,
                            Update: {
                                Sent_Count: count,
                                Last_Sent: d
                            }
                        })
                    }
                )
            
                const {
                    message2
                } = await result2.json()
                console.log(message2);
                v.Sent_Count = count

                v.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                v.loading = false
                this.snack(error,'error',3000)
            }
        },
        async denyLead() {
            try {
                this.loading = true
                let comment = ""
                if (this.details.Comments) {
                    comment = this.details.Comments + "\n" + "Denied Because: " + this.deniedReason
                } else {
                    comment = "Denied Because: " + this.deniedReason
                }
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_DenyLead?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/deny',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: this.details.Id,
                            comments: comment,
                            reason: this.deniedReason,
                            company: this.details.Company_Name,
                            rep: this.details.External_Sales_Rep,
                            email: this.details.Email,
                            manager: this.details.Sales_Manager
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
                this.details.Comments = comment
                this.details.Denied = 1
                this.deniedMenu = false
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async convertLead() {
            try {
                this.convertLeadLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_ConvertLead?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/convert',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: this.details.Id,
                            email: this.$store.getters.getUserEmail
                        })
                    }
                )

                const {
                    message,
                    errorType,
                    accountId
                } = await result.json()
                if (!result.ok) {
                    let error = {
                        message: message,
                        type: errorType
                    }
                    throw error
                }
                this.$router.push(`/sales/crm/accounts/${accountId}?firstTime=1`)
            } catch (error) {
                this.convertLeadLoading = false
                this.convertLeadError.dialog = true
                this.convertLeadError.message = error.message
                this.convertLeadError.type = error.type 
                this.snack(error,'error',3000)
            }
        },
        getBillingAddressData: async function (addressData) {
            let address = await this.getAddressDetails(addressData.place_id)
            this.tempBillingAddress = address
            this.openAddressDialog('Billing Address')
        },
        getShippingAddressData: async function (addressData) {
            let address = await this.getAddressDetails(addressData.place_id)
            console.log(address);
            this.tempShippingAddress = address
            this.openAddressDialog('Shipping Address')
        },
        openAddressDialog(type, manual) {
            this.addressType = type
            if (manual && type === 'Billing Address') {
                this.tempBillingAddress = {
                    address1: this.details.Billing_Address_1,
                    address2: this.details.Billing_Address_2,
                    city: this.details.Billing_City,
                    state: this.details.Billing_State,
                    zip: this.details.Billing_Zip,
                    country: this.details.Billing_Country
                }
            } else if (manual && type === 'Shipping Address') {
                this.tempShippingAddress = {
                    address1: this.details.Shipping_Address_1,
                    address2: this.details.Shipping_Address_2,
                    city: this.details.Shipping_City,
                    state: this.details.Shipping_State,
                    zip: this.details.Shipping_Zip,
                    country: this.details.Shipping_Country
                }
            }
            this.enterAddressDialog = true
        },
        formatShow(show, address) {
            let string = address.address1 + ', '
            if (address.address2 && address.address2.length > 0) {
                string = string + address.address2 + ', '
            }
            string = string + address.city + ', ' +
                    address.state + ' ' + 
                    address.zip + ' ' + 
                    address.country
            let check = string.replaceAll(",","")
            check = check.replaceAll(" ","")
            this[show] = (check.length === 0) ? '' : string

        },
        fillOutAddress() {
            if (this.addressType === 'Billing Address') {
                this.details.Billing_Address_1 = this.tempBillingAddress.address1
                this.details.Billing_Address_2 = this.tempBillingAddress.address2
                this.details.Billing_City = this.tempBillingAddress.city
                this.details.Billing_State = this.tempBillingAddress.state
                this.details.Billing_Zip = this.tempBillingAddress.zip
                this.details.Billing_Country = this.tempBillingAddress.country
                this.formatShow('Billing_Address_Show',this.tempBillingAddress)
            } else {
                this.details.Shipping_Address_1 = this.tempShippingAddress.address1
                this.details.Shipping_Address_2 = this.tempShippingAddress.address2
                this.details.Shipping_City = this.tempShippingAddress.city
                this.details.Shipping_State = this.tempShippingAddress.state
                this.details.Shipping_Zip = this.tempShippingAddress.zip
                this.details.Shipping_Country = this.tempShippingAddress.country
                this.formatShow('Shipping_Address_Show',this.tempShippingAddress)
            }
            this.updateLead(this.details)
            this.cancelAddress()
        },
        cancelAddress() {
            let address = {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: ''
            }
            this.tempBillingAddress = address
            this.tempShippingAddress = address
            this.poBoxCheck = false
            this.enterAddressDialog = false
        },
        async getAddressDetails(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/NDA_GetAddressDetails?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/nda/address?id=' + id,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    address
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.loading = false
                return address
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text, color, timeout) {
            this.snackBar = true;
            this.snackText = text;
            this.snackColor = color;
            this.timeout = timeout;
        },
    },
    computed: {
        convertDisabled() {
            if (this.convertMessages.length > 0 || this.loading) {
                return true
            } else {
                return false
            }
        },
        isAdmin() {
            let permissions = this.$store.getters.getPermissions
            for(let i = 0; i < permissions.length; i++) {
                if (permissions[i].includes('Admin')) {
                    return true
                }
            }
            return false
        },
        convertMessages() {
            let messages = []
            if (this.details.Denied) {
                messages.push('This Lead Was Denied')
                return messages
            }
            if (!this.details.Channel_Id) {
                messages.push('Channel Required')
            }
            if (!this.details.Account_Type_Id) {
                messages.push('Account Type Required')
            }
            if (!this.details.Industry_Id) {
                messages.push('Industry Required')
            }
            if (!this.details.Forecast_Id && this.details.Forecast_Id !== 0) {
                messages.push('Forecast Category Required')
            }
            if (!this.details.Sales_Manager) {
                messages.push('Internal Manager Required')
            }
            if (!this.details.External_Sales_Rep && this.details.External_Sales_Rep !== 0) {
                messages.push('External Sales Rep Required')
            }
            if (!this.details.Shipping_Address_1) {
                messages.push('Shipping Address Required')
            }
            if (!this.details.Billing_Address_1) {
                messages.push('Billing Address Required')
            }
            if (!this.details.Primary_Price_Id) {
                messages.push('Primary Price List Required')
            }
            if (!this.details.Secondary_Price_Id) {
                messages.push('Secondary Price List Required')
            }
            if (!this.details.Cost_Id) {
                messages.push('Cost List Required')
            }
            if(this.contacts.length === 0) {
                messages.push('Must have at least one Sales Contact')
            }
            if (this.contacts.map(item => item.Email).length !== this.contacts.length) {
                messages.push('Make sure all contacts have emails')
            }
            if (this.contacts.map(item => item.First_Name).length !== this.contacts.length) {
                messages.push('Make sure all contacts have First Names')
            }
            if (this.contacts.map(item => item.Last_Name).length !== this.contacts.length) {
                messages.push('Make sure all contacts have Last Names')
            }
            return messages
        }
    }
}
</script>

<style>

</style>