<template>
    <v-app>
        <v-main>
            <v-container fill-height fluid class="px-8">
                <v-row align="center" justify="center">
                    <productFilter
                        :categories="distinctCategories"
                        :colors="distinctColors"
                        @search-filter="searchFilter"
                        @color-filter="colorFilter"
                        @category-filter="categoryFilter"/>
                </v-row>
                <v-row 
                    justify="center"
                    align="center"
                    v-for="(rows, index) in filteredProductsPaginated" 
                    :key="index">
                    <v-col 
                        class="px-2"
                        v-for="(product, index2) in rows" 
                        :key="index2">
                        <productCard
                            :product="product"
                            :itemsPerRow="itemsPerRow"/>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center">
                    <v-col>
                        <v-pagination
                            v-model="page"
                            :length="paginationLength"/>
                    </v-col>
                    <v-col cols="1">
                        Rows per page
                        <v-select
                            class="pt-0"
                            hide-details
                            v-model="rows"
                            :items="rowOptions"/>
                    </v-col>
                </v-row>
                <v-snackbar
                    v-model="snackBar"
                    :timeout="timeout"
                    :color="snackColor">
                    {{ snackText }}
                </v-snackbar>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import productCard from './productCard.vue'
import productFilter from './productFilter.vue'

export default {
    components: {
        productCard, 
        productFilter
    },
    beforeMount(){
        this.$store.dispatch('updateNavbarTitle','Matador Store')
        this.$store.dispatch('updateShowWaffle', false)
        this.$store.dispatch("updateNavbarContent", [
            { title: "Home", path: "/store/home", icon: "mdi-home", notification: false },
            { title: "Cart", path: "/store/cart", icon: "mdi-cart", notification: false }
        ]);
        this.getStoreDetails()
    },
    watch: {
        filteredProductsPaginated() {
            console.log(this.filteredProductsPaginated[0[2]])
        }
    },
    data() {
        return {
            search: '',
            itemsPerRow: 4,
            page: 1,
            rows: 25,
            rowOptions: [5,10,25,50],
            products: [],
            stock: [],
            prices: [],
            selectedCategories: [],
            selectedColors: [],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getStoreDetails() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Store_GetDetails?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/store?id='+localStorage.shopperId,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
            
                const response = await result.json()

                if(!result.ok) {
                    throw(response.message)
                }
                this.products = response.products
                this.prices = response.prices
                this.stock = response.stock
                
                this.loading = false
                this.snack(response.message,'success',3000)
            } catch (error) {
                this.loading = false
                console.log(error);
                this.snack(error,'error',3000)
            }
        },
        colorFilter(filter) {
            this.selectedColors = filter
        },
        categoryFilter(filter) {
            this.selectedCategories = filter
        },
        searchFilter(filter) {
            this.search = filter
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        distinctCategories() {
            return [... new Set(this.products.map(item => item.Category))]
        },
        distinctColors() {
            return [... new Set(this.products.map(item => item.Option_1))]
        },
        filteredProducts() {
            if (this.selectedCategories.length && this.selectedColors.length && this.search.length) {
                return this.products.filter(item => 
                    this.selectedCategories.includes(item.Category) &&
                    this.selectedColors.includes(item['Option 1']) &&
                    item.Product_Name.toLowerCase().includes(this.search.toLowerCase())
                )
            } else if(this.selectedCategories.length && this.selectedColors.length && !this.search.length) {
                return this.products.filter(item => 
                    this.selectedCategories.includes(item.Category) &&
                    this.selectedColors.includes(item['Option 1'])
                )
            } else if (this.selectedCategories.length && !this.selectedColors.length && !this.search.length) {
                return this.products.filter(item => 
                    this.selectedCategories.includes(item.Category)
                )
            } else if (!this.selectedCategories.length && !this.selectedColors.length && !this.search.length) {
                return this.products
            } else if (!this.selectedCategories.length && !this.selectedColors.length && this.search.length) {
                return this.products.filter(item => 
                    item.Product_Name.toLowerCase().includes(this.search.toLowerCase())
                )
            } else if (!this.selectedCategories.length && this.selectedColors.length && this.search) {
                return this.products.filter(item => 
                    this.selectedColors.includes(item['Option 1']) &&
                    item.Product_Name.toLowerCase().includes(this.search.toLowerCase())
                )
            } else {
                return this.products
            }
        },
		paginationLength() {
            return Math.ceil(this.filteredProducts.length / this.rows)
		},
        rowsPerPage() {
            let totalRows = Math.ceil(this.filteredProducts.length / this.itemsPerRow)
            if (totalRows > 10) {
                return 10
            } else {
                return totalRows
            }
        },
        formattedProducts() {
            let place = 0
            let finalMatrix = []
            let rows = Math.ceil(this.filteredProducts.length / this.itemsPerRow)
            for(let r = 0; r < rows; r++) {
                let subArray = this.filteredProducts.slice(place, place + this.itemsPerRow)
                finalMatrix.push(subArray)
                place += this.itemsPerRow
            }
            return finalMatrix
        },
        filteredProductsPaginated() {
			let start = this.rowsPerPage * (this.page - 1)
			let end = this.rowsPerPage * this.page
            return this.formattedProducts.slice(start, end)
		},
    }
}
</script>

<style>

</style>