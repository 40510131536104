<template>
    <v-app>
        <v-main>
            <v-row justify="center" align="center" class="pt-4" v-if="!firstLoad">
                <v-card class="pa-4" height="75vh" width="75vw">
                    <v-card-title class="d-flex justify-center align-center">
                        Bulk Upload Forecast
                    </v-card-title>
                    <v-card-subtitle class="text-center red--text">
                        Please understand what this does before using it. <br>
                        This will overwrite ALL forecasts that are included in the uploading file. <br>
                        i.e. each SKU, Date, Forecast Category included in the file. <br>
                        This includes deleting preorder/preseason discounts and any notes associated with a forecast. <br>
                        It assumes all forecasts are only ASAP with NO discounts. <br>
                        There is no reversing this action once it is uploaded. <br>
                        If you want to be extra careful, it would be smart to save the forecast 
                        using the save button in the upper right of the forecast tool and then uploading the bulk update.
                    </v-card-subtitle> 
                    <v-card-subtitle class="text-center">
                        Months must be in the format of 1-12
                    </v-card-subtitle>
                    <v-row justify="center" align="center" class="py-8">
                        <download-csv
                            class   = "btn btn-default"
                            :data   = "[['Forecast Name','SKU','Month','Year','Qty']]"
                            name    = "Bulk Forecast Upload Template.csv">
                            <v-btn
                                color="primary"
                                @click="console.log('hi')">
                                Download Template
                            </v-btn>
                        </download-csv>
                    </v-row>
                    <v-row justify="center" align="center" class="py-8">
                        <v-btn
                            color="primary"
                            @click="getExistingForecast()">
                            Download Existing Forecast
                        </v-btn>
                    </v-row>
                    <v-file-input
                        accept=".csv"
                        label="Click here to Upload Forecast"
                        outlined
                        v-model="csvFile"/>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            color="primary"
                            @click="sendFile()"
                            :loading="loading"
                            :disabled="!csvFile">
                            Change the Course of History
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>
            <v-row v-else justify="center" align="center" class="pt-4">
                <v-progress-circular indeterminate color="primary"/>
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - Forecast Tool'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent',
        [
            {title: 'Home', path: '/sales/forecast/home', icon: 'mdi-home', notification: false},
            {title: 'Assign Lists', icon: 'mdi-account-box', path: '/sales/forecast/accounts', notification: false},
            {title: 'Products', icon: 'mdi-package-variant-closed', path: '/sales/forecast/products', notification: false},
            {title: 'Forecast Categories', icon: 'mdi-shape', path: '/sales/forecast/categories', notification: false},
            {title: 'Bulk Upload', icon: 'mdi-upload', path: '/sales/forecast/bulk-upload', notification: false},          
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ]
        )
        
        this.$store.dispatch('updateNavbarTitle','Forecast Tool')
    },
    data() {
        return {
            json: [],
            existingForecast: [],
            csvFile: '',
            firstLoad: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getExistingForecast() {
            this.snack(`An email will be sent to ${this.$store.getters.getUserEmail}`,'success',5000)
            try {
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_GetExistingForecastForDownload?tab=code
                    `https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/bulk-update?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
            } catch (error) {
                console.log(error)
            }
        },
        async sendFile() {
            try {
                this.loading = true

                let json = await this.processCSVFile(this.csvFile);
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_BulkUpload?tab=code
                    `https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/bulk-update`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            forecast: json,
                            user: this.$store.getters.getUsername,
                            email: this.$store.getters.getUserEmail
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                if (error === 'Endpoint request timed out') {
                    this.snack(`${this.$store.getUserEmail} will be emailed the result`,'info',false)
                } else {
                    this.snack(error,'error',3000)
                }
                
            }
        },
        async processCSVFile(csvFile) {
            const fileContent = await this.readFileAsync(csvFile);

            const csv = require('csvtojson');
            const jsonObject = await csv({
                noheader: false,
                output: 'json',
                flatKeys: true,
            }).fromString(fileContent);

            return jsonObject;
        },
        async readFileAsync(file) {
            let reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsText(file);
            });
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    }
}
</script>

<style>

</style>