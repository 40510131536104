<template>
    <v-dialog v-model="dialog" width="50vw" height="90vh" @click:outside="compareProducts = [];">
        <v-card class="pa-4" width="50vw" height="90vh" style="overflow-y: scroll;">
            <!-- lists the month and product name -->
            <v-card-title class="justify-space-around px-1 pt-1 pb-0 mb-1">
                <v-switch
                    class="mt-0"
                    label="Explain Chart"
                    hide-details
                    v-model="chart.explain"/>
                {{ category.Forecast_Name }} 
                <v-tooltip bottom>
                    <template v-slot:activator = "{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            icon 
                            @click="compareProductsDialog = true"
                            v-on="on"
                            v-bind="attrs">
                            <v-icon>mdi-compare-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <span>Add Products To Chart</span>
                </v-tooltip>
            </v-card-title>
            <v-card-title class="pt-0 px-1 mt-0 pr-0 pb-0">
                <!-- Column for the month selector -->
                <v-col cols="2" class="pl-0 pb-0">
                    <v-select
                        class="shrink"
                        hide-details
                        v-model="selectedMonth"
                        @change="save(false); $emit('Month-Change', {month: monthNames.indexOf(selectedMonth) + 1})"
                        :items="monthNames"
                        solo/>
                </v-col>
                <v-col cols="10" class="pr-0 pb-0">
                    <v-card-title class="d-flex justify-center align-center">
                        {{ product.Name || ''}}
                    </v-card-title>
                </v-col>
            </v-card-title>
            <v-divider horizontal/>
            <div>
                <product-chart
                    class="productChart"
                    elevation="2"
                    :chartData="chartData"
                    :refresh="chart.refresh"
                    :explain="chart.explain"
                    :category="category.Forecast_Name"
                    :year="year"
                    :sku="sku"
                    :compareSKUs="compareProducts.map(item => item.sku)"/>
            </div>
            <v-row>
                <v-col cols="4">
                    <v-card class="pa-4" height="100%">
                        <v-card-title class="d-flex justify-center align-center pt-0">
                            {{ selectedMonth }} Actual
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center pt-4">
                            {{ product[month][year].Actual_Qty || 0 }}
                        </v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card class="pa-4" height="100%">
                        <v-card-title class="d-flex justify-center align-center pt-0">
                            {{ costPriceInfo.Cost_Price_List_Name }}
                        </v-card-title>
                        <v-row class="pb-0">
                            <v-col cols="6">
                                <v-text-field
                                    v-model="price"
                                    label="Price"
                                    hide-details
                                    prefix="$"
                                    type="number"/>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="cost"
                                    label="Cost"
                                    hide-details
                                    prefix="$"
                                    type="number"/>
                            </v-col>
                        </v-row>
                        <v-row v-if="price !== costPriceInfo.Price || cost !== costPriceInfo.Cost" class="pt-0">
                            <v-card-subtitle class="text-center caption text--secondary py-0">
                                Apply change to all {{ costPriceInfo.Cost_Price_List_Name }} months? (Optional)
                            </v-card-subtitle>
                            <v-col cols="6">
                                <v-btn
                                    v-if="price !== costPriceInfo.Price"
                                    x-small
                                    :loading="priceLoading"
                                    @click="updateCostPrice('price')"
                                    color="primary">
                                    update prices
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                                    v-if="cost !== costPriceInfo.Cost"
                                    x-small
                                    :loading="costLoading"
                                    @click="updateCostPrice('cost')"
                                    color="primary">
                                    update costs
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card class="pa-4" height="100%">
                        <v-card-title class="d-flex justify-center align-center pt-0">
                            Forecast - {{ total | currency }}
                        </v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="discount"
                                    label="Discount"
                                    class="centered-input"
                                    hide-details
                                    min="0"
                                    suffix="%"
                                    type="number"/>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    label="Qty"
                                    hide-details
                                    min="0"
                                    class="centered-input"
                                    type="number"
                                    ref="input"
                                    @focus="$event.target.select()"
                                    @input="updateCopyForChart()"
                                    v-model="qty"/>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="px-4 pt-2">
                <v-text-field
                    :label="year + ' Notes'"
                    solo
                    v-model="notes"
                    hide-details
                    placeholder="No Notes">
                    <template v-slot:prepend-inner>
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-card class="pa-4">
                                <v-card-title class="d-flex justify-center align-center pt-0">
                                    {{ year - 1  }} Notes
                                </v-card-title>
                                <v-card-subtitle class="d-flex justify-center align-center">
                                    {{ product[month][year - 1].Notes || 'No Notes' }}
                                </v-card-subtitle>
                                <v-card-title class="d-flex justify-center align-center pt-0">
                                    {{ year - 2  }} Notes
                                </v-card-title>
                                <v-card-subtitle class="d-flex justify-center align-center">
                                    {{ product[month][year - 2].Notes || 'No Notes' }}
                                </v-card-subtitle>
                            </v-card>
                        </v-menu>
                    </template>
                </v-text-field>
            </v-row>
            <v-card-actions class="d-flex justify-space-between align-center pt-8">
                <v-btn 
                    color="secondary" 
                    @click="cancel()">
                    <v-icon>mdi-close-circle</v-icon>
                    Cancel
                </v-btn>
                <v-btn 
                    color="primary" 
                    :loading="productLoading"
                    @click="save()">
                    <v-icon>mdi-check-circle</v-icon>
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <compare-products
            @close="compareProductsDialog = false"
            @compareProducts="handleCompareProducts"
            :dialog="compareProductsDialog"
            :year="year"
            :sku="sku"
            :forecastId="category.Forecast_Id"/>
    </v-dialog>
</template>

<script>
import ProductChart from './productChart.vue'
import CompareProducts from './compareProducts.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        ProductChart,
        CompareProducts
    },
    created() {
        this.selectedMonth = this.monthNames[this.$props.month - 1]

        window.addEventListener('keydown', (e) => {
            if (e.key === 'ArrowRight' && this.$props.dialog) {
                e.preventDefault()
                this.incrementMonth()
            }
            if (e.key === 'ArrowLeft' && this.$props.dialog) {
                e.preventDefault()
                this.decrementMonth()
            }
            if (e.key == 'Enter' && this.$props.dialog) {
                this.save()
            }
        })
    },
    watch: {
        dialog() {
            if (this.$props.dialog) {
                this.price = this.$props.product[this.$props.month][this.$props.year].Listed_Cost_Price.Price
                this.cost = this.$props.product[this.$props.month][this.$props.year].Listed_Cost_Price.Cost
                this.qty = this.$props.product[this.$props.month][this.$props.year].Forecast_Qty || 0
                this.notes = this.$props.product[this.$props.month][this.$props.year].Notes || ''
                this.discount = this.$props.product[this.$props.month][this.$props.year].Discount || 0
                this.selectedMonth = this.monthNames[this.$props.month - 1]
                this.productCopy = JSON.parse(JSON.stringify(this.$props.product))
                setTimeout(() => {
                    this.$refs.input.focus() 
                }, 25)
            } else {
                this.loading =false
            }
        },
        month() {
            if (this.$props.dialog) {
                this.price = this.$props.product[this.$props.month][this.$props.year].Listed_Cost_Price.Price
                this.cost = this.$props.product[this.$props.month][this.$props.year].Listed_Cost_Price.Cost
                this.qty = this.$props.product[this.$props.month][this.$props.year].Forecast_Qty || 0
                this.notes = this.$props.product[this.$props.month][this.$props.year].Notes || ''
                this.discount = this.$props.product[this.$props.month][this.$props.year].Discount || 0
                this.selectedMonth = this.monthNames[this.$props.month - 1]
                this.productCopy = JSON.parse(JSON.stringify(this.$props.product))
                setTimeout(() => {
                    this.$refs.input.focus() 
                }, 25)
            }
        }
    },
    props: {
        category: {type: Object},
        dialog: {type: Boolean},
        product: {type: Object},
        sku: {type: String},
        month: {type: Number},
        year: {type: Number},
        priceName: {type: String},
        costName: {type: String},
    },
    data() {
        return {
            cost: 0,
            costLoading: false,
            priceLoading: false,
            price: 0,
            qty: 0,
            discount: 0,
            loading: false,
            selectedMonth: '',
            notes: '',
            productCopy: {},
            monthNames: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            chart: {
                refresh: 0,
                explain: false,
            },
            compareProductsDialog: false,
            compareProducts: [],
        }
    },
    methods: {
        save(hide = true) {
            if (this.forecastChanged) {
                this.loading = true
                this.$emit('Update-Forecast',{
                    SKU: this.$props.sku,
                    Month: this.$props.month,
                    Year: this.$props.year,
                    Qty: this.qty,
                    Discount_Percent: this.discount / 100,
                    Notes: this.notes,
                    Total: this.total,
                    Price: this.price,
                    Cost: this.cost,
                    hide: hide
                })
            }
        },
        cancel() {
            this.compareProducts = []
            this.$emit('close')
        },
        incrementMonth() {
            if (this.getOtherSettings.saveOnMonthChange) {
                this.save(false)
            }
            let m = 0
            if (this.$props.month < 12) {
                m = this.$props.month + 1
            } else {
                m = 1
            }
            this.$refs.input.blur()
            this.$emit('Month-Change', {month: m})
        },
        decrementMonth() {
            if (this.getOtherSettings.saveOnMonthChange) {
                this.save(false)
            }
            let m = 0
            if (this.$props.month > 1) {
                m = this.$props.month - 1
            } else {
                m = 12
            }
            this.$refs.input.blur()
            this.$emit('Month-Change', {month: m})
        },
        async updateCostPrice(type) {
            let listName = this.costPriceInfo.Cost_Price_List_Name
            const json = [{
				SKU: this.$props.sku,
				List_Name: listName
			}]
			let currentCategories = []

			if (type === 'price') {
                this.priceLoading = true
				json[0][this.$props.priceName] = this.price
				currentCategories[0] = {Name: this.$props.priceName}
			} 
			if (type === 'cost') {
                this.costLoading = true
				json[0][this.$props.costName] = this.cost
				currentCategories[0] = {Name: this.$props.costName}
			}

			try {
				const result = await fetch(
					'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/productdetails/productpricesandcosts',
					{
						method: 'PUT',
						Headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							data: json,
							type: type,
							currentCategories: currentCategories
						})
					}
				)

				const {
					response
				} = await result.json()
                
                console.log(response)

                this.$emit('Update-Price-Cost',{
                    sku: this.$props.sku,
                    listName: listName,
                    year: this.$props.year,
                    month: this.$props.month,
                    type: type,
                    value: type === 'price' ? this.price : this.cost
                })
                this.priceLoading = false
                this.costLoading = false
            } catch (error) {
                console.log(error)
            }

        },
        updateCopyForChart() {
            this.productCopy[this.$props.month][this.$props.year].Forecast_Qty = this.qty
            // this.chart.refresh++
        },
        handleCompareProducts(payload) {
            this.compareProducts = payload
        },
    },
    computed: {
        forecastChanged() {
            if (
                (
                    this.product[this.$props.month][this.$props.year].Forecast_Qty !== null &&
                    this.qty !== this.product[this.$props.month][this.$props.year].Forecast_Qty
                ) ||
                (
                    this.product[this.$props.month][this.$props.year].Discount_Percent !== null && 
                    this.discount !== this.product[this.$props.month][this.$props.year].Discount_Percent
                ) ||
                (
                    this.product[this.$props.month][this.$props.year].Notes !== null &&
                    this.notes !== null &&
                    this.notes !== this.product[this.$props.month][this.$props.year].Notes
                ) ||
                (
                    this.product[this.$props.month][this.$props.year].Listed_Cost_Price.Price !== null &&
                    this.price !== this.product[this.$props.month][this.$props.year].Listed_Cost_Price.Price
                ) ||
                (
                    this.product[this.$props.month][this.$props.year].Listed_Cost_Price.Cost !== null &&
                    this.cost !== this.product[this.$props.month][this.$props.year].Listed_Cost_Price.Cost
                )
            ) {
                return true
            } else {
                return false
            }
        },
        costPriceInfo() {
            let base = {
                Price: 0,
                Cost: 0,
                Cost_Price_List_Name: ''
            }
            let a = this.$props.product
            let b = (a) ? a[this.$props.month] : base
            let c = (b) ? b[this.$props.year] : base
            if ('Listed_Cost_Price' in c) {
                return c.Listed_Cost_Price
            } else {
                return base
            }
        },
        total() {
            return this.qty * ((1 - (this.discount / 100)) * this.price)
        },
        chartData() {
            if (this.$props.sku.length && this.$props.month && this.$props.year) {
                let data = []
                let max = 0
                for (let y = 0; y < this.chartYears.length; y++) {
                    let seriesData = {
                        label: this.chartYears[y].year.toString(),
                        sku: this.$props.sku,
                        fill: false,
                        borderColor: this.chartYears[y].color, 
                        data: [],
                        type: 'line'
                    }
                    let yearTotal = 0
                    for (let month in this.productCopy) {
                        if (typeof this.productCopy[month] !== 'string' && typeof this.productCopy[month] !== 'number') {
                            let qty = 0
                            if (String(this.chartYears[y].year).includes('Forecast')) {
                                qty = this.productCopy[month][this.chartYears[y].year.split(' ')[0]].Forecast_Qty || 0
                            } else {
                                qty = this.productCopy[month][this.chartYears[y].year].Actual_Qty || 0
                            }
                            max = (qty > max) ? qty : max
                            seriesData.data.push(qty)
                            yearTotal += qty
                        }
                    }
                    seriesData.label = seriesData.label + ': ' + yearTotal
                    data.push(seriesData)
                }
                data = data.concat(this.compareProducts)
                let currentMonthData = [0,0,0,0,0,0,0,0,0,0,0,0]
                currentMonthData[this.$props.month - 1] = max
                data.push({
                    label: 'Current Month',
                    fill: true,
                    type: 'bar',
                    backgroundColor: '#C0C0C040',
                    data: currentMonthData
                })

                return {
                    datasets: data,
                    labels: this.monthNames,
                    label: 'Overview'
                }
            } else {
                return {}
            }
        },
        chartYears() {
			return [
				{
					year: this.$props.year - 2,
					color: "#FEB92B",
					altColor: "#FFDFA9",
				},
				{
					year: this.$props.year - 1,
					color: "#00729C",
					altColor: "#A1B6CE",
				},
				{
					year: this.$props.year,
					color: "#B41131",
					altColor: "#DA9D92",
				},
				{
					year: this.$props.year + ' Forecast',
					color: "#542A76"
				}
			]
		},
        productLoading() {
            return this.$props.product[this.$props.month][this.$props.year].Loading
        },
        ...mapGetters([
            'getOtherSettings'
        ]),
    }
}
</script>

<style>
.productChart {
    margin-bottom: 20px;
    height: 400px;
    z-index: 500;
}
</style>