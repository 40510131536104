<template>
  <v-card class="mx-auto px-auto mt-16 justify-center align-center" 
    elevation="2" width="50vw" height="100%">
        <v-sheet
        tile
        height="54"
        class="d-flex"
        >
        <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
        >
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-card-title 
            class="justify-center align-center" 
            v-if="$refs.calendar"> 
            {{ $refs.calendar.title }}
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
        >
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        </v-sheet>
        <v-sheet height="600">
        <v-calendar
            ref="calendar"
            v-model="value"
            @change="getYear"
            :weekdays="weekday"
            type="month"
            :events="formattedEvents"
            event-overlap-mode="stack"
            :event-overlap-threshold="30"
        ></v-calendar>
        </v-sheet>
    </v-card>
</template>

<script>
export default {
    mounted () {
        this.$refs.calendar.checkChange()
        let year = this.$refs.calendar.title.slice(-4)
        this.$emit('input', Number(year))
    },
    watch: {
        events: function() {
            this.formatAllEvents()
        }
    },
    props: {
        events: {type: Array}
    },
    data: () => ({
        weekday: [0, 1, 2, 3, 4, 5, 6],
        year: 2021,
        value: '',
        formattedEvents: [],
        snackActive: false,
        snackTimout: 2000,
        snackColor: 'primary',
        snackText: '',
        loading: false,
    }),
    methods: {
        formatAllEvents() {
            let tempEvents = []
            for (let i = 0; i < this.$props.events.length; i++) {
                let currentEvent = this.$props.events[i]
                let start = new Date(currentEvent.Start_Date)
                let end = new Date(currentEvent.End_Date)

                tempEvents.push({
                    name: currentEvent.Event_Name,
                    start: start,
                    end: end,
                    color: currentEvent.Color,
                    timed: currentEvent.Added_Hours
                })
            }
            this.formattedEvents = tempEvents
        },
        getYear() {
            let year = this.$refs.calendar.title.slice(-4)
            this.$emit('input', Number(year))
        }
    }
}
</script>

<style>

</style>