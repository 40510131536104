<template>
	<v-container class="d-flex flex-column justify-center" fluid>
		<v-row :key="refreshProductsKey">
			<product-card v-for="(product, index) in filteredProductsPaginated" 
				@update-product="updateProduct"
				@product-floor-temp="updateProductFloorTemp"
				@submit-restock="submitRestock"
				@clear-search="clearSearch"
				@refresh-branch-transfers="refreshBranchTransfers"
				:key="index"
				:activeStatus="(product.Active === 1) ? true : false"
				:opsGroup="opsGroup"
				:lookForwardMonths="lookForwardMonths"
				:productName="product.Name"
				:productSKU="product.SKU"
				:productFloor="findProductFloor(product)"
				:color="product.Color"
				:cardHeight="cardHeight"
				:forecastData="filterForecastData(product)"
				:branchTransferData="filterBranchTransfers(product)"
				:stockMarketData="filterStockMarketData(product)"
				:SOH="filterSOH(product)"
				:forecastToViewDetails="filterDetails(product)"
			></product-card>
		</v-row>
		<v-row justify="center" align="center">
			<v-col>
				<v-pagination
					v-model="page"
					:length="paginationLength"/>
			</v-col>
			<v-col cols="1">
				Rows per page
				<v-select
					class="pt-0"
					hide-details
					v-model="rows"
					:items="rowOptions"/>
			</v-col>
			<v-col>
				<v-checkbox
					v-model="limitRows"
					hide-details
					label="Limit Rows"
					hint="Unchecking this box will cause lag"/>
			</v-col>
		</v-row>
		<v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
		<v-dialog 
			persistent
			v-model="loadingDataLoading" 
			width="50vw" 
			height="50vh">
			<v-card class="pa-4">
				<v-card-title class="d-flex justify-center align-center">
					Loading All Stock Market Data
				</v-card-title>
				<v-row justify="center" align="center">
					<v-col class="d-flex justify-center align-center">
						Status
					</v-col>
					<v-col>
						Data
					</v-col>
					<v-col>
						Message
					</v-col>
					<v-col class="d-flex justify-center align-center">
						Retry Counter
					</v-col>
					<v-col class="d-flex justify-center align-center">
						Retry Button
					</v-col>
				</v-row>
				<v-row v-for="(keys, index) in loadingData" :key="index" align="center" justify="center">
					<v-col class="d-flex justify-center align-center">
						<v-progress-circular indeterminate small v-if="keys.message === 'Loading'"/>
						<v-icon v-else :color="getIcon(keys.message).color">
							{{ getIcon(keys.message).icon }}
						</v-icon>
					</v-col>
					<v-col>
						{{ keys.name }}
					</v-col>
					<v-col>
						{{ keys.message }}
					</v-col>
					<v-col class="d-flex justify-center align-center">
						{{ keys.counter }}
					</v-col>
					<v-col class="d-flex justify-center align-center">
						<v-btn color="gray" icon @click="retryGetDataElement(keys)">
							<v-icon>mdi-refresh</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-card-subtitle v-if="loadingError" class="text-center">
					Looks like there was an error loading in the data, 
					you can select the refresh icon next to each data element to retry specific ones 
					or select Retry All to refresh all elements.
				</v-card-subtitle>
				<v-card-actions 
					v-if="loadingError"
					class="d-flex justify-space-around align-center">
					<v-btn
						color="secondary"
						@click="loadingDataLoading = false">
						Close
					</v-btn>
					<v-btn
						color="primary"
						@click="loadAllData()">
						Retry All
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import ProductCard from './productCard.vue';
export default {
	components: {
		ProductCard
	},
	props: {
		searchProducts: {type: String},
		cardHeight: {type: Number},
		opsGroup: {type: String},
		lookForwardMonths: {type: Number},
		includeDrafts: {type: Boolean},
		includeInactiveProducts: {type: Boolean},
		forecastToViewDetails: {type: Object},
		SOHProp: {type: Array},
		branchTransferProp: {type: Array},
		stockMarketProp: {type: Array},
		forecastProp: {type: Array},
	},
    async beforeMount() {
		await this.loadAllData()

		this.productsFormatted()
		this.emitForecastCheckData();
		this.$emit('input',this.loading)
		this.snack('Data Loaded','success','3000')
    },
	watch: {
		limitRows: function() {
			if (!this.limitRows) {
				this.snack('Disabling will cause lag','info',3000)
			}
		},
		lookForwardMonths: async function() {
			let item = this.loadingData.find(item => item.name === 'Forecast Data')
			this.loadingDataLoading = true
			await this.retryGetDataElement(item)
		},
		opsGroup: async function() {
			this.refreshProductsKey++
			let item = this.loadingData.find(item => item.name === 'Forecast Data')
			this.loadingDataLoading = true
			await this.retryGetDataElement(item)
		},
		SOHProp: function () {
			this.SOH = this.$props.SOHProp
		},
		branchTransferProp: function () {
			this.branchTransfers = this.$props.branchTransferProp
		},
		stockMarketProp: function () {
			this.stockMarketData = this.$props.stockMarketProp
		},
		forecastProp: function () {
			this.forecastData = this.$props.forecastProp
		},
	},
    data() {
        return {
			refreshProductsKey: 0,
			loadingDataLoading: false,
			loadingData: [
				{
					name: 'Product Data',
					message: 'Waiting',
					counter: 0,
					function: 'getProducts',
					dataElement: 'products',
					errors: []
				},
				{
					name: 'Forecast Data',
					message: 'Waiting',
					counter: 0,
					function: 'getForecastData',
					dataElement: 'forecastData',
					errors: []
				},
				{
					name: 'Branch Transfer Data',
					message: 'Waiting',
					counter: 0,
					function: 'getBranchTransferData',
					dataElement: 'branchTransfers',
					errors: []
				},
				{
					name: 'Stock Market Data',
					message: 'Waiting',
					counter: 0,
					function: 'getStockMarketData',
					dataElement: 'stockMarketData',
					errors: []
				},
				{
					name: 'SOH Data',
					message: 'Waiting',
					counter: 0,
					function: 'getSOHData',
					dataElement: 'SOH',
					errors: []
				}
			],
			page: 1,
			rows: 10,
			limitRows: true,
			rowOptions: [5,10,25,50],
			forecastData: [],
            branchTransfers: [],
            stockMarketData: [],
			SOH: [],
            products: [],
			productFloors: [],
			productsFormattedData: {},
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
		async loadAllData() {
			this.loadingDataLoading = true
			for (let i = 0; i < this.loadingData.length; i++) {
				await this.loadingDataTemplate(this.loadingData[i])
			}
			if (!this.loadingError) {
				for (let i = 0; i < this.loadingData.length; i++) {
					this.loadingData[i].counter = 0
				}
				this.loadingDataLoading = false
			}
		},
		async loadingDataTemplate(item) {

			item.message = 'Loading'
			try {
				await this[item.function]()
			} catch (error) {
				item.errors = error
			}
			
			if (this[item.dataElement].length > 0) {
				item.message = 'Complete'
			} else {

				while (this[item.dataElement].length === 0 && item.counter < 2) {
					item.message = 'Loading'
					await this[item.function]()
					item.counter += 1
				}

				if (this[item.dataElement].length === 0 && item.counter >= 2) {
					item.message = 'Failed'
				} else {
					item.message = 'Complete'
				}
			}
		},
        async getProducts() {
			//https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetProducts?tab=code
			//gets all products within the active product categories using the above lambda function 
			try {
			const response = await fetch(
				`https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/products`,
				{
					method: 'GET',
					Headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			const {
					products,
					productFloors
			} = await response.json()

			this.productFloors = productFloors
			this.products = products
			} catch (error) {
				return error
			}
		},
		async getSOHData() {
			try {
				const result = await fetch(
					//Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetStockData?tab=code
					'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/stock',
					{
						method: 'GET',
						Headers: {
							'Content-Type': 'application/json',
						}
					}
				)
				const {
					message,
					stock
				} = await result.json()
			
				if(!result.ok) {
					throw message
				}
				this.SOH = stock
			} catch (error) {
				return error
			}
		},
		async getForecastData() {
			try {
				const result = await fetch(
					//Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetSalesForecastData?tab=code
					'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/forecast?opsGroup=' + this.$props.opsGroup + '&lookAhead=' + this.$props.lookForwardMonths,
					{
						method: 'GET',
						Headers: {
							'Content-Type': 'application/json',
						}
					}
				)
				const {
					message,
					forecastData
				} = await result.json()
			
				if(!result.ok) {
					throw message
				}
				this.forecastData = forecastData
			
			} catch (error) {
				return error
			}
		},
		async getBranchTransferData() {
			try {
				const result = await fetch(
					//Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetBranchTransfers?tab=code
					'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/branchtransfers',
					{
						method: 'GET',
						Headers: {
							'Content-Type': 'application/json',
						}
					}
				)
				const {
					message,
					branchTransfers
				} = await result.json()
			
				if(!result.ok) {
					throw message
				}
				if (branchTransfers.length === 0) {
					throw await this.getErrors('Branch Transfer')
				}
				this.branchTransfers = branchTransfers
			} catch (error) {
				return await this.getErrors('Branch Transfer')
			}
		},
		async getStockMarketData() {
			try {
				const result = await fetch(
					//Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetStockMarketData?tab=code
					'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/stockmarket',
					{
						method: 'GET',
						Headers: {
							'Content-Type': 'application/json',
						}
					}
				)
				const {
					message,
					stockMarketData
				} = await result.json()
			
				if(!result.ok) {
					throw message
				}
				this.stockMarketData = stockMarketData
				
			} catch (error) {
				return error
			}
		},
		async getErrors(query) {
			try {
				const result = await fetch(
					//Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_GetError?newFunction=true&tab=code
					'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/error?query=' + query,
					{
						method: 'GET',
						Headers: {
							'Content-Type': 'application/json',
						}
					}
				)
				const {
					message,
					errorMessage
				} = await result.json()
			
				if(!result.ok) {
					throw message
				}
				return errorMessage
				
			} catch (error) {
				return error
			}
		},
		getSOHandForecastData() {
			const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/products/details'
			const request = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			}
			this.loading = true
			this.$emit('input',this.loading)
			fetch(url, request).then(async response => { 
				const data = await response.json()
			
				if (!response.ok) {
					const error = (data && data.message) || response.status
					return Promise.reject(error)
				}

				this.forecastData = data.values[0]
				this.stockMarketData = data.values[2]
                this.branchTransfers = data.values[1]
				this.SOH = data.values[3]
				
				this.productsFormatted()
				this.emitForecastCheckData();

				this.loading = false
				this.$emit('input',this.loading)
				
				this.snack('Data Loaded', 'success', 3000)
			}).catch(error => {
				this.loading = false
				this.$emit('input',this.loading)
				this.snack(error, 'error', 3000)
			})
		},
		getIcon(message) {
			if (message === 'Failed') {
				return {
					icon: 'mdi-water-boiler-alert',
					color: 'red'
				}
			} else if (message === 'Complete') {
				return {
					icon: 'mdi-check-circle-outline',
					color: 'green'
				}
			} else if (message === 'Waiting') {
				return {
					icon: 'mdi-progress-clock',
					color: 'gray'
				}
			} else {
				return {
					icon: '',
					color: ''
				}
			}
		},
		async retryGetDataElement(element) {
			console.log(element)
			element.counter += 1
			await this.loadingDataTemplate(element)
			if (!this.loadingError) {
				this.loadingDataLoading = false
			}
		},
		updateProduct(color, active, sku) {
			console.log(sku)
			this.products.find(item => item.SKU === sku).Color = color
			this.products.find(item => item.SKU === sku).Active = active

			const data = {
				sku: sku,
				active: active,
				color: color
			}
			this.sendUpdateProduct(data)
		},
		updateProductFloorTemp(floor, sku) {
			let productFloor = this.productFloors.find(item =>
				item.SKU === sku &&
				item.Ops_Group === this.$props.opsGroup)
			if (productFloor !== undefined) {
				productFloor.Floor = Number(floor)
			} else {
				this.productFloors.push({
					SKU: sku,
					Ops_Group: this.$props.opsGroup,
					Floor: Number(floor)
				})
			}
			const data = {
				sku: sku,
				floor: Number(floor),
				opsGroup: this.$props.opsGroup
			}
			this.$emit('update-product-floor',this.productFloors)
			this.sendUpdateProduct(data)
		},
		submitRestock(sku, month, year, restock) {
			let currentStockMarketData = this.stockMarketData.find(item => {
				return item.SKU === sku &&
				item.Month === month &&
				item.Year === year
			})
			if (currentStockMarketData !== undefined) {
				currentStockMarketData.Restock = restock
			} else {
				this.stockMarketData.push({
					Id: 404,
					Ops_Group: this.$props.opsGroup,
					SKU: sku,
					Month: month,
					Year: year,
					Restock: Number(restock)
				})
			}
			const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/products/details'
			const data = {
				Ops_Group: this.$props.opsGroup,
				SKU: sku,
				Month: month,
				Year: year,
				Restock: Number(restock)
			}
			const request = {
				method: 'POST',
				body:JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json'
				}
			}
			this.$emit('input',this.loading)
			fetch(url, request).then(async response => { 
				const data = await response.json()
			
				if (!response.ok) {
					const error = (data && data.message) || response.status
					return Promise.reject(error)
				}
				this.$emit('input',this.loading)
				this.snack(data.message, 'success', 3000)
			}).catch(error => {
				this.$emit('input',this.loading)
				this.snack(error, 'error', 3000)
			})
			this.emitForecastCheckData()
		},
		clearSearch(search) {
			this.$emit('clear-search',search)
		},
		sendUpdateProduct(body) {
			//Lambda Code: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_UpdateProduct?tab=code
			const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/products'
			const request = {
				method: 'PUT',
				body:JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json'
				}
			}
			this.loading = true
			fetch(url, request).then(async response => { 
				const data = await response.json()
			
				if (!response.ok) {
					const error = (data && data.message) || response.status
					return Promise.reject(error)
				}
				this.loading = false
				this.snack(data.message, 'success', 3000)
			}).catch(error => {
				this.loading = false
				this.snack(error, 'error', 3000)
			})
		},
		filterForecastData(product) {
			return this.forecastData.filter(item => 
				item.SKU === product.SKU
			).map(item => {
				item.Year = Number(item.Date.substring(0,4))
				item.Month = Number(item.Date.substring(5,7))
				return item
			})

		},
		filterBranchTransfers(product) {
			if (this.$props.includeDrafts) {
				return this.branchTransfers.filter(item => 
					item.SKU === product.SKU &&
					item.Ops_Group === this.$props.opsGroup
				)
			} else {
				return this.branchTransfers.filter(item => 
					item.SKU === product.SKU &&
					item.Ops_Group === this.$props.opsGroup &&
					item.Active == "1"
				)
			}
		},
		filterStockMarketData(product) {
			return this.stockMarketData.filter(item => 
				item.SKU === product.SKU &&
				item.Ops_Group === this.$props.opsGroup
			)
		},
		filterSOH(product) {
			if (this.SOH.length > 0) {
				let filteredSOH = this.SOH.filter(item => 
					item.SKU === product.SKU && 
					item.Ops_Group === this.$props.opsGroup
				)
				let SOH = filteredSOH.reduce(function(sum, record){return sum + record.SOH},0)
				return SOH
			} else {
				return 0
			}
		},
		filterDetails(product) {
			if (product.SKU === this.$props.forecastToViewDetails.sku) {
				let details = this.$props.forecastToViewDetails
				details.matched = true
				return details
			} else {
				let details = {matched: false}
				return details
			}
		},
		findProductFloor(product) {
			let floor = this.productFloors.find(item =>
				item.SKU === product.SKU &&
				item.Ops_Group === this.$props.opsGroup)
			if (floor !== undefined) {
				return Number(floor.Floor)
			} else {
				return 0
			}
		},
		emitForecastCheckData() {
			this.$emit('forecast-check',
				this.forecastData,
				this.branchTransfers,
				this.stockMarketData,
				this.filteredProducts,
				this.productFloors,
				this.SOH
			)
		},
		productsFormatted() {
			let item = this.products.reduce((acc, product) => {
				acc[product.SKU] = {
				...product,
				forecastData: this.filterForecastData(product),
				branchTransferData: this.filterBranchTransfers(product),
				stockMarketData: this.filterStockMarketData(product),
				SOH: this.filterSOH(product),
				forecastToViewDetails: this.filterDetails(product)
				}
				return acc
				}, {}
			)
			this.productsFormattedData = item
		},
		refreshBranchTransfers(id) {
			this.$emit('refresh-branch-transfers',id)
		},
        snack(text, color, timeout) {
            this.snackText = text
            this.snackColor = color
            this.snackTimeout = timeout
            this.snackActive = true
        }
    },
	computed: {
		filteredProducts() {
			if (this.$props.searchProducts.length) {
				let choosenProducts = []
				if (this.$props.includeInactiveProducts) {
					choosenProducts = this.products
				} else {
					choosenProducts = this.products.filter(item => item.Active === 1)
				}
				let finalListOfProducts = choosenProducts.filter(item => 
					item.Name.toLowerCase().includes(this.$props.searchProducts.toLowerCase())||
					item.SKU.toLowerCase().includes(this.$props.searchProducts.toLowerCase())
				)
				this.$emit('update-products', finalListOfProducts)
				return finalListOfProducts
			} else {
				if(this.$props.includeInactiveProducts) {
					this.$emit('update-products', this.products)
					return this.products
				} else {
					let finalListOfProducts = this.products.filter(item => item.Active === 1)
					this.$emit('update-products', finalListOfProducts)
					return finalListOfProducts
				}
			}
			
		},
		filteredProductsPaginated() {
			let start = this.rows * (this.page - 1)
			let end = this.rows * this.page
			if (this.limitRows) {
				return this.filteredProducts.slice(start, end)
			} else {
				return this.filteredProducts
			}
		},
		paginationLength() {
			if (this.$props.searchProducts.length) {
				return Math.ceil(this.filteredProducts.length / this.rows)
			} else {
				return Math.ceil(this.products.length / this.rows)
			}
		},
		loadingError() {
			let messages = this.loadingData.map(item => item.message)
			if (messages.includes('Failed')) {
				return true
			} else {
				return false
			}
		}

	}
}
</script>

<style>

</style>