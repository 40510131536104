<template>
    <v-row class="noScroll" justify="center" align="center">
      <v-col cols="6">
        <v-card elevation="2" width="25vw" class="px-5 py-5 mx-auto my-auto">
          <v-card-title class="justify-center">Login</v-card-title>
          <v-text-field 
            id="username"
            class="px-16"
            label="Email"
            v-model="email"/>
          <v-text-field
            id="password"
            type="password"
            class="px-16"
            label="Password"
            v-model="password"/>
            <v-row justify="center" class="py-5">
              <v-btn 
                @click="login()" 
                :disabled="formNotComplete" 
                color="primary"
                :loading="loading">Login</v-btn>
            </v-row>
        </v-card>
      </v-col>
      <v-col cols="6">
      <v-img 
        lazy-src="../../assets/loading.jpg"
        :src="randomPicture"
        @click="getRandomPicture()"
        max-height="95vh"
        max-width="50vw">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
      </v-img>
      </v-col>
      <v-snackbar
          v-model="snackActive"
          :color="snackColor"
          :timeout="snackTimeout">
          {{ snackText }}
      </v-snackbar>
    </v-row>
</template>
<style scoped>
.noScroll {
  overflow: hidden;
  height: 90vh;
}
</style>
<script>

export default {
  beforeMount() {
    this.$store.dispatch('updateNavbarTitle','Matador')
    this.$store.dispatch('updateShowWaffle', false)
    this.getRandomPicture()
  },
  data() {
    return {
      email: '',
      password: '',
      pictures: [
        'https://www.dropbox.com/s/en6ht5mdiz96qrt/IMG_5455.JPEG?raw=1',
        'https://www.dropbox.com/s/ciinm3o0chmcw6g/IMG_0417.jpeg?raw=1',
        'https://www.dropbox.com/s/aivc7e6q7eijc62/IMG_4928.JPEG?raw=1',
        'https://www.dropbox.com/s/f0jgurcmij56u9o/IMG_5293.JPEG?raw=1',
        'https://www.dropbox.com/s/4cv84ho22ypo8l7/IMG_5505.JPEG?raw=1',
        'https://www.dropbox.com/s/urtt2ll5gtdxhg8/IMG_5980.JPEG?raw=1',
        'https://www.dropbox.com/s/h8d8ilyftvxk7mr/IMG_7038.JPEG?raw=1',
        'https://www.dropbox.com/s/lzm251z4jb267qy/IMG_7482.JPG?raw=1',
        'https://www.dropbox.com/s/ua3txy0gbfk64v9/IMG_9303.JPG?raw=1',
        'https://www.dropbox.com/s/t3nxfzaw8k4gqh6/IMG_8698.jpg?raw=1',
        'https://www.dropbox.com/s/w3kt1skcemp0umb/IMG_20181006_132648.jpg?raw=1',
        'https://www.dropbox.com/s/40cmya1i8etqgpv/IMG_20190526_165750_429.jpg?raw=1',
        'https://www.dropbox.com/s/9lptk1qjybq39p7/IMG_20190627_200145.jpg?raw=1',
        'https://www.dropbox.com/s/hi6f5q38wena3r7/IMG_20190629_200521_348.jpg?raw=1',
        'https://www.dropbox.com/s/0misjvbr2wg1ulc/IMG_20190630_175600_118.jpg?raw=1',
        'https://www.dropbox.com/s/cxfdb47w4513r9k/IMG_20190719_194649_678.jpg?raw=1',
        'https://www.dropbox.com/s/pnpeul0tonr9vw2/IMG_20190719_194649_679.jpg?raw=1',
        'https://www.dropbox.com/s/18wuo5ny5avozg2/IMG_20190722_202159_721.jpg?raw=1',
        'https://www.dropbox.com/s/la9b0hewqokw2a7/IMG_20190722_202159_728.jpg?raw=1',
        'https://www.dropbox.com/s/e25f3c32sg53ulg/IMG_20190829_161407_290.jpg?raw=1',
        'https://www.dropbox.com/s/emsfw4f2fvij9ul/IMG_7966.JPG?raw=1',
        'https://www.dropbox.com/s/9n74y90blpzd4e7/IMG_8053.JPG?raw=1',
        'https://www.dropbox.com/s/uc892fw8vjqyare/IMG_8146.JPG?raw=1',
        'https://www.dropbox.com/s/n2o4t05g67yz9nc/IMG_9372.JPG?raw=1',
        'https://www.dropbox.com/s/ejj9sayy945iko2/IMG_20190829_161407_291.jpg?raw=1',
        'https://www.dropbox.com/s/1jhzwnx5qywaaxa/IMG_20191228_090146_Original.jpg?raw=1',
        'https://www.dropbox.com/s/1am306ct07l5cdo/IMG_20200321_125234_Original.jpg?raw=1',
        'https://www.dropbox.com/s/czh5d712fnr61it/IMG_20200904_095834.jpg?raw=1',
        'https://www.dropbox.com/s/ncomkftfxtg93vz/IMG_20201011_115319.jpg?raw=1',
        'https://www.dropbox.com/s/c1qauyxwlivtxlw/IMG_20201206_073351_066.jpg?raw=1',
        'https://www.dropbox.com/s/82li5kev190n3hs/IMG_20201206_073351_071.jpg?raw=1',
        'https://www.dropbox.com/s/cpypbxtojrem3ov/IMG_20201206_073351_074.jpg?raw=1',
        'https://www.dropbox.com/s/uj9gubm1bf49bok/IMG_20210622_193044_519.jpg?raw=1',
        'https://www.dropbox.com/s/wltlm9o3anm82s2/IMG_20210622_193044_607.jpg?raw=1',
        'https://www.dropbox.com/s/6kyfnm9c0lzbcxk/IMG_20210622_193044_641.jpg?raw=1',
        'https://www.dropbox.com/s/ndbdtdx8ywwof7r/IMG_20210622_193044_699.jpg?raw=1',
        'https://www.dropbox.com/s/7lidw0ewx0lwce4/IMG_20210622_193044_748.jpg?raw=1',
        'https://www.dropbox.com/s/i1pi0wiapxmrrm2/IMG_20210924_061348_710.jpg?raw=1',
        'https://www.dropbox.com/s/lfw4sx1r9x7wwbe/IMG_20210924_061348_951.jpg?raw=1',
        'https://www.dropbox.com/s/sz7z7h99wqspkq8/IMG_20210924_061348_968.jpg?raw=1',
        'https://www.dropbox.com/s/te942x8n4118h5w/Stephenson001613-R1-004-0A.jpg?raw=1',
        'https://www.dropbox.com/s/kjvvgsqkvqhnjiq/ralphonaboat.jpg?raw=1',
        'https://www.dropbox.com/s/biywn0owcdshflb/VideoCapture_20211020-143815.jpg?raw=1',
        'https://www.dropbox.com/s/5g9z7k2dvlnjmfm/1969-12-31%2005.00.00%20260.jpg?raw=1',
        'https://www.dropbox.com/s/lip662jjsxo0f33/2020-04-17%2012.01.29%201.jpg?raw=1',
        'https://www.dropbox.com/s/8updjs4hhpz8l1w/2020-10-11-020411354.jpg?raw=1',
        'https://www.dropbox.com/s/x70ry867roxtoco/Zion2015-26.JPG?raw=1',
        'https://www.dropbox.com/s/ibnjonesnfimg67/2021-04-08-071838808.jpg?raw=1',
        'https://www.dropbox.com/s/xry9izc6bk7my3w/2021-01-23-114741213.jpg?raw=1',
        'https://www.dropbox.com/s/7oxvr05egim29mu/20190326_082058.jpg?raw=1',
        'https://www.dropbox.com/s/gppkukirgxnfh24/20190326_082108.jpg?raw=1',
        'https://www.dropbox.com/s/x09ez8modqyqs1u/20190326_082117.jpg?raw=1',
        'https://www.dropbox.com/s/91jwfp7nqgvr8pl/2021-04-20-014702979.jpg?raw=1',
        'https://www.dropbox.com/s/tfk6wpk08wc5w6y/2021-05-04-091508517.jpg?raw=1',
        'https://www.dropbox.com/s/yjsamtmiqwag45k/20190326_082139.jpg?raw=1',
        'https://www.dropbox.com/s/8yk3ev6kuq2pb3d/20190326_082207.jpg?raw=1',
        'https://www.dropbox.com/s/sad9kjd2ol372cc/20190504_223737000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/ot3su1anaspp86m/20190326_082221.jpg?raw=1',
        'https://www.dropbox.com/s/r1en5pgzf1rdg6j/20190326_082308.jpg?raw=1',
        'https://www.dropbox.com/s/yalmawb6piy9gcc/20190329_005640.jpg?raw=1',
        'https://www.dropbox.com/s/uy7vfjodigwzs35/20190505_014610456_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/sf1n2j6lapcb2ie/20190518_183359580_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/hvkw91855a1zny7/20190614_070552.jpg?raw=1',
        'https://www.dropbox.com/s/dn3knczd0cq4cjr/20190614_070607.jpg?raw=1',
        'https://www.dropbox.com/s/h45erg1auwure4g/20190716_095636.jpg?raw=1',
        'https://www.dropbox.com/s/m1d7p2na4nkzm0z/20190716_095747.jpg?raw=1',
        'https://www.dropbox.com/s/r4l6399bhkfesjc/20190716_101035.jpg?raw=1',
        'https://www.dropbox.com/s/xyhuhjwrywfpyup/20190716_101057.jpg?raw=1',
        'https://www.dropbox.com/s/dz8m6n72ot4x3dj/20190716_112821.jpg?raw=1',
        'https://www.dropbox.com/s/tqrzaa95nisj8me/20190716_131916.jpg?raw=1',
        'https://www.dropbox.com/s/hk14l5ymuyc0y42/20190828_104221.jpg?raw=1',
        'https://www.dropbox.com/s/mhg63jvxi3e01ou/20190828_104238.jpg?raw=1',
        'https://www.dropbox.com/s/lu0an3ew0khi0ux/20190829_073114.jpg?raw=1',
        'https://www.dropbox.com/s/xz607xhqese7zg8/20190829_073121.jpg?raw=1',
        'https://www.dropbox.com/s/05ue9d8wsc1rixj/20190829_080805.jpg?raw=1',
        'https://www.dropbox.com/s/tea7ap8mb0sxbyw/20190829_080841.jpg?raw=1',
        'https://www.dropbox.com/s/mo30l84dljz8r05/20190829_083258.jpg?raw=1',
        'https://www.dropbox.com/s/cs46m4dtawtow27/20190829_083309.jpg?raw=1',
        'https://www.dropbox.com/s/woq7iecdffjjhk3/20190829_083316.jpg?raw=1',
        'https://www.dropbox.com/s/5z7bc7hc7wrmewz/20190916_034829000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/xhpeyes7zbyrmui/20190829_094956.jpg?raw=1',
        'https://www.dropbox.com/s/oep633c79gqrkyk/20190904_105100.jpg?raw=1',
        'https://www.dropbox.com/s/u25lh7o3btjm954/20190904_105111.jpg?raw=1',
        'https://www.dropbox.com/s/tywotf3dxhz90os/20191206_085020.jpg?raw=1',
        'https://www.dropbox.com/s/o39vhh3qiuafkzb/20191206_085023.jpg?raw=1',
        'https://www.dropbox.com/s/apkkvte8bu0bvvs/20191206_085026.jpg?raw=1',
        'https://www.dropbox.com/s/80gmtxzacifrqlb/20191229_130016000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/l4qdpw3i4a9fxby/20191231_002646000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/j84f3ffb8wcdw6a/20191231_182607000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/ntm00nmw3t1hmna/20200303_133439.jpg?raw=1',
        'https://www.dropbox.com/s/za1qpkj9p2glm48/20200304_075831.jpg?raw=1',
        'https://www.dropbox.com/s/sek1x81exvtrepx/20200304_075839.jpg?raw=1',
        'https://www.dropbox.com/s/0v2rsdj6onndvfi/20200507_194446_exported_1044_1588895893395.jpg?raw=1',
        'https://www.dropbox.com/s/yowipfp9y8qwdiz/20200426_163819.jpg?raw=1',
        'https://www.dropbox.com/s/w82c0r5xauube7y/20200507_194448.jpg?raw=1',
        'https://www.dropbox.com/s/xmsvavreegvb9vh/20200508_194651.jpg?raw=1',
        'https://www.dropbox.com/s/x91s0ofahiuhub6/20200520_073751.jpg?raw=1',
        'https://www.dropbox.com/s/cubg7s8nvsgkbsd/20200520_205655.jpg?raw=1',
        'https://www.dropbox.com/s/idbu57l6btt8ytb/20200522_201125000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/5vu35frnyhptf4z/20200522_202124000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/v6pqqz4eqf3muk9/20200522_202526000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/s2lqrjdyqfqeijp/20200701_102850.jpg?raw=1',
        'https://www.dropbox.com/s/wnhemxw46eihqlk/20200703_120648.jpg?raw=1',
        'https://www.dropbox.com/s/f1gy58zn8qscdc8/20200703_120654.jpg?raw=1',
        'https://www.dropbox.com/s/41hrql8mwcxe3jg/20200801_090544.jpg?raw=1',
        'https://www.dropbox.com/s/4sjq3qvlpzbgp2a/20200801_090602.jpg?raw=1',
        'https://www.dropbox.com/s/jo60681x8v8gyoc/20200816_123354.jpg?raw=1',
        'https://www.dropbox.com/s/x37kovfz0hzyvef/20201003_133435.jpg?raw=1',
        'https://www.dropbox.com/s/tde28xv0x1n3kc5/20201011_172656341_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/1yduxrqe7qylj21/20201106_080636.jpg?raw=1',
        'https://www.dropbox.com/s/o36dyibfvforoze/20201122_075559.jpg?raw=1',
        'https://www.dropbox.com/s/p1h6ukozm7eq19c/20201129_111139.jpg?raw=1',
        'https://www.dropbox.com/s/j4fxtiwwq85p8pp/20201205_113955.jpg?raw=1',
        'https://www.dropbox.com/s/fd5405sy5pkre9h/20201205_114000.jpg?raw=1',
        'https://www.dropbox.com/s/oj18fbwidhd3la4/20201205_114054.jpg?raw=1',
        'https://www.dropbox.com/s/e9ofy3ij1egxmab/20201205_114057.jpg?raw=1',
        'https://www.dropbox.com/s/msvndzrzk9nb486/20201205_114211.jpg?raw=1',
        'https://www.dropbox.com/s/e8tup5xiyl1e585/20201205_114230.jpg?raw=1',
        'https://www.dropbox.com/s/ebkoyqsnn9n9aqc/20201205_114234.jpg?raw=1',
        'https://www.dropbox.com/s/wjcaek2rnp8hm97/20201210_183028.jpg?raw=1',
        'https://www.dropbox.com/s/cdg6q37oibrkh0z/20210123_233313000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/ywq79mkmckfzot1/20210311_193011915_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/2yybgc5ovza85vj/20210311_193018505_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/qzbzyov36gmeli0/20210311_193018803_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/yf4r5osns7qrw42/20210311_193019389_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/mw8g1b5wx20z2do/20210311_193110209_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/a6gpg7tgm2pcy7p/20210311_193248508_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/lzs34ushngn23t1/20210311_193248564_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/vvi3t0zi56e6mja/20210311_193248612_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/gz6cftqp0mwo162/20210311_193311052_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/fgy0gu5cegy1uzm/20210402_165538883_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/hxqfl9rjmvpk7oi/20210408_215824000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/p1mun8b5qi4undg/20210408_221549000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/qbebspsw7lxeozb/20210408_221659000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/iw9khbcgg106tvc/20210409_175339377_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/lx8i284yqyec7hv/20210413_203934081_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/n5paxr8zti6szop/20210413_174630606_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/g82lumtmxvj1jyz/20210413_222906011_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/3091a3jxmiwlms5/20210414_154225898_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/6qo6xyvcu6rj636/20210414_185418000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/czugd1tpdayas9h/20210414_230022734_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/mg7ied46l2bu53c/20210428_135846.jpg?raw=1',
        'https://www.dropbox.com/s/f50xoyqsfct677b/20210611_020534272_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/xxm5qaz9rt39an5/20210608_200223.jpg?raw=1',
        'https://www.dropbox.com/s/31xl77udh7lx5ke/20210609_092455.jpg?raw=1',
        'https://www.dropbox.com/s/quc2wp5fjceqpff/20210612_044352.jpg?raw=1',
        'https://www.dropbox.com/s/fq7of9lrx0kmjhn/20210613_065111.jpg?raw=1',
        'https://www.dropbox.com/s/uc14ys1syte40ae/20210613_072323.jpg?raw=1',
        'https://www.dropbox.com/s/5jjsy9ey9i3wbs2/20210613_065115.jpg?raw=1',
        'https://www.dropbox.com/s/ejoviiu4f4nakn9/20210613_070858.jpg?raw=1',
        'https://www.dropbox.com/s/j3iseyzbgo5vl95/20210613_074147.jpg?raw=1',
        'https://www.dropbox.com/s/4e8qs862n7qv5rs/20210613_074427.jpg?raw=1',
        'https://www.dropbox.com/s/47d3zy1ogo0jgw9/20210613_074430.jpg?raw=1',
        'https://www.dropbox.com/s/i3jzjzmmulk4j99/20210613_074435.jpg?raw=1',
        'https://www.dropbox.com/s/z6vqes9stqz94cl/20210613_105144.jpg?raw=1',
        'https://www.dropbox.com/s/nji30muek0w1rx0/20210613_110145.jpg?raw=1',
        'https://www.dropbox.com/s/kwkap9wys3yodxs/20210613_143319.jpg?raw=1',
        'https://www.dropbox.com/s/0xbiunhu1zqbf1k/20210613_143332.jpg?raw=1',
        'https://www.dropbox.com/s/ivkqmpmut6wbr06/20210614_114420.jpg?raw=1',
        'https://www.dropbox.com/s/jr0pbowp7wxcrfu/20210613_154530.jpg?raw=1',
        'https://www.dropbox.com/s/edgr7j6u719zrqf/20210615_082744.jpg?raw=1',
        'https://www.dropbox.com/s/6mb2nl3ifibulw3/20210615_082751.jpg?raw=1',
        'https://www.dropbox.com/s/avpm27klwjbrifw/20210615_082756.jpg?raw=1',
        'https://www.dropbox.com/s/mntm2g8gmbv6607/20210617_135337.jpg?raw=1',
        'https://www.dropbox.com/s/xnkgoz8vfncif3i/20210617_135338.jpg?raw=1',
        'https://www.dropbox.com/s/dfqnk0vrh9qacxd/20210617_135353.jpg?raw=1',
        'https://www.dropbox.com/s/qhv4t4cawn6a5il/20210617_135405.jpg?raw=1',
        'https://www.dropbox.com/s/5efmiu82ov4fk1t/20210617_135410.jpg?raw=1',
        'https://www.dropbox.com/s/cw52vzlz21gypp2/20210617_135706.jpg?raw=1',
        'https://www.dropbox.com/s/gavtoujoy1o93dj/20210617_135720.jpg?raw=1',
        'https://www.dropbox.com/s/sxy62j6sobcidb2/20210617_135725.jpg?raw=1',
        'https://www.dropbox.com/s/q9tl3wj4tjha68e/20210617_135729.jpg?raw=1',
        'https://www.dropbox.com/s/2fmlvzhp0rwa1hd/20210617_135733.jpg?raw=1',
        'https://www.dropbox.com/s/61cze3s15vvnf1z/20210617_142251.jpg?raw=1',
        'https://www.dropbox.com/s/go0q61wcxbw4hok/20210617_142259.jpg?raw=1',
        'https://www.dropbox.com/s/7qeje2cea0elyyq/20210617_142301.jpg?raw=1',
        'https://www.dropbox.com/s/ll7do5qhsknsl9p/20210617_142313.jpg?raw=1',
        'https://www.dropbox.com/s/oj73ub8etoji04d/20210617_142317.jpg?raw=1',
        'https://www.dropbox.com/s/si8axz8xpg4g2fc/20210715_114658.jpg?raw=1',
        'https://www.dropbox.com/s/o8flis3le61l3py/20210902_193154939_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/sai2v270w3jclkq/20210719_194526.jpg?raw=1',
        'https://www.dropbox.com/s/cdivsit3shyhlxs/20210915_162725703_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/8qlilsnjwx5md83/20210810_135847.jpg?raw=1',
        'https://www.dropbox.com/s/aqob89yltb4uovq/20210916_162533.jpg?raw=1',
        'https://www.dropbox.com/s/yxxckco0llv76i1/20210916_163714.jpg?raw=1',
        'https://www.dropbox.com/s/cvzdj7y4p1487vt/20210917_220237000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/gmzl0d59o5k8mzi/20210918_130936584_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/wcf0uxjgrajusby/20210918_141941181_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/6znx12t899896i8/20210918_161325583_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/ya88ph5rdi19jbh/20210918_161326382_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/gdp9wp5qz9s804h/20210918_161448124_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/j5fzl0jbpii6o8j/20210918_162740729_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/bhoylam1fsc1ewj/20210918_162742095_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/wkayrvil84ccy7d/20210918_162747494_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/avudhh2mq4rv2xr/20210918_162755025_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/btkdxjjcpagloei/20210918_163816756_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/9oq4tbus1edqo38/20210918_164337502_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/3wlxe34rpb8i53e/20210918_165216887_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/1fd2b4d9vq38hil/20210918_180828988_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/jl49ie08tsdsliq/20210918_180834720_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/omo7hfouu7gjvw3/20210918_180848985_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/o9zh5njls0um663/20210918_180852676_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/erkz6pytj0b454l/20210918_181119088_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/eexadw371azgehb/20210918_181950140_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/727ni2qk2645iee/20210919_171038000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/7cb7vlybmr7zi03/20210919_171334000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/xop24jj2nytc2pu/20210919_171545000_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/bqx1i5rgbn0wvi7/20210924_143246438_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/d4pemc6hcffwov0/20211001_210737837_iOS.jpg?raw=1',
        'https://www.dropbox.com/s/c7md1zdafsroe5z/20190624061410_IMG_2428.JPG?raw=1',
        'https://www.dropbox.com/s/f7r51g2nnyiqwha/20190624052948_IMG_2417.JPG?raw=1',
        'https://www.dropbox.com/s/k160p8c43fhad9a/20190624074800_IMG_2432.JPG?raw=1',
        'https://www.dropbox.com/s/w6uaj0thztsrshy/20190626203801_IMG_2479.JPG?raw=1',
        'https://www.dropbox.com/s/kxcuyfzyi7e1qgj/20190626203808_IMG_2480.JPG?raw=1',
        'https://www.dropbox.com/s/pizxed0j38kx6y2/20190627114952_IMG_2506.JPG?raw=1',
        'https://www.dropbox.com/s/f2sucf0o17zfv1t/20210919100953_IMG_2741.JPG?raw=1',
        'https://www.dropbox.com/s/co25yb7p8p5pqkb/20210919104924_IMG_2782.JPG?raw=1',
        'https://www.dropbox.com/s/zd6c2rz3khcbgvh/20210919105840_IMG_2789.JPG?raw=1',
        'https://www.dropbox.com/s/6ynooaatk6j3ah2/DSC05116.JPG?raw=1',
        'https://www.dropbox.com/s/vw5n3is7nej9f8p/DSC05121.JPG?raw=1',
        'https://www.dropbox.com/s/kw6v232eiwd5ct0/IMG_1188.JPG?raw=1',
        'https://www.dropbox.com/s/4sx6t7j9tpffe6g/IMG_0331.JPG?raw=1',
        'https://www.dropbox.com/s/kxmodkdhylzliw8/DSC06260.JPG?raw=1',
        'https://www.dropbox.com/s/5s4wxp6mkk52zwg/IMG_0634.JPG?raw=1',
        'https://www.dropbox.com/s/ri1jfodaw16qqn3/IMG_1369.JPG?raw=1',
        'https://www.dropbox.com/s/nmbhzicfhz2l7u3/IMG_1659.JPG?raw=1',
        'https://www.dropbox.com/s/2oxsejley60depk/IMG_2676.JPG?raw=1',
        'https://www.dropbox.com/s/lmb0o4ueaxtsg29/IMG_3325.JPG?raw=1',
        'https://www.dropbox.com/s/utxm5pwryf0232p/IMG_4547.JPG?raw=1',
        'https://www.dropbox.com/s/c3e68fa8jj8dfo2/IMG_6523.JPraw=1',

      ],
      randomPicture: '',
      snackText: 'success',
      snackColor: 'success',
      snackTimeout: 3000,
      snackActive: false,
      loading: false,
    }
  },
  methods: {
    getRandomPicture() {
      const index = Math.floor(Math.random() * (this.pictures.length - 1))
      this.randomPicture = this.pictures[index]
    },
    login() {
      const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/admin/login';
      const data = {
        email: this.email,
        password: this.password
      }
      const request = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json'
          },
      }
      this.loading = true
      fetch(url,request).then(async response => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        
        if (data.success === true) {
          const user = {
            name: data.username,
            id: data.id,
            email: data.email
          }
          this.$store.dispatch('updateLoggedIn', true)
          this.$store.dispatch('updateUser', user)
          this.$store.dispatch('updatePermissions', data.permissions.map(item => item.Service))
          this.$store.dispatch('updateColor', data.color)
          localStorage.permissions = JSON.stringify(data.permissions.map(item => item.Service))
          localStorage.loggedIn = true
          localStorage.user = JSON.stringify(user)
          localStorage.color = JSON.stringify(data.color)
          localStorage.cashFlowModel = JSON.stringify({})

          this.$vuetify.theme.themes.light.primary = data.color.primary
          this.$vuetify.theme.themes.light.secondary = data.color.secondary
          this.$vuetify.theme.themes.light.accent = data.color.accent

          this.snackActive = true
          this.snackColor = 'success'
          this.snackText = 'Logged In'
          this.$router.push('/')
        } else {
          this.$store.dispatch('updateLoggedIn', false)
          this.snackActive = true
          this.snackColor = 'error'
          this.snackText = 'Incorrect Password'
        }
        this.loading = false
      }).catch((error) => {
        console.log(error);
        this.loading = false
        this.snackActive = true
        this.snackColor = 'error'
        this.snackText = error
      });
    }
  },
  snack(text, color, timeout) {
    this.snackText = text;
    this.snackColor = color;
    this.snackTimeout = timeout;
    this.snackActive = true;
  },
  computed: {
    formNotComplete() {
      if (this.email.length && this.password.length) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style>

</style>