<template>
    <v-app class="pt-8">
        <v-main class="pt-8">
            <v-row class="pt-8">
                <v-text-field
                    :loading="loading"
                    v-model="search"
                    label="search"
                    class="px-8"/>
            </v-row>
            <v-simple-table class="px-4">
                <thead>
                    <tr>
                        <th>Branch Name</th>
                        <th>Ops_Group (Be exact when typing, a misspelling will break things)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(branch, index) in filteredBranches" :key="index">
                        <td>
                            <a :href="'https://go.cin7.com/Cloud/CRM/Contact.aspx?mode=N&idMember=' + branch.Id + '&idWebSite=21518&idCustomerAppsLink=985802'">
                                {{ branch.Company }}
                            </a>
                        </td>
                        <td>
                            <v-text-field
                                v-model="branch.Ops_Group"
                                hide-details
                                @change="updateBranch(branch)"/>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - Stock Market'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Home', path: '/Ops/StockMarket/Home', icon: 'mdi-home', notifcation: ""},
            {title: 'Manage Groups', path: '/ops/stockmarket/manage', icon: 'mdi-account-group', notification: this.newGroup},
            {title: 'Manage Branch Transfers', path: '/ops/stockmarket/manageBranchTransfers', icon: 'mdi-source-branch', notification: this.countOfEmptyETAs},
            {title: 'Manage Branches', path: '/ops/stockmarket/manageBranches', icon: 'mdi-warehouse', notification: ""}
        ])
        this.$store.dispatch('updateNavbarTitle','Stock Market')
        this.getBranches()
    },
    data() {
        return {
            branches: [],
            search: '',
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getBranches() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetBranches?tab=code
                    `https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/managebranches?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    branches
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.branches = branches

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateBranch(branch) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_UpdateBranches?newFunction=true&tab=code
                    `https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/managebranches?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Id: branch.Id,
                            Ops_Group: branch.Ops_Group
                        })
                    }
                )
                const {
                    message
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        filteredBranches() {
            if (this.search.length) {
                return this.branches.filter(item =>
                    item.Company.toLowerCase().includes(this.search.toLowerCase())
                )
            } else {
                return this.branches
            }
            
        }
    }
}
</script>

<style>

</style>