<template>
    <v-container class="flex-column justify-center align-center pt-16 mt-8">
        <v-stepper width="100%" v-model="step">
            <v-stepper-header class="elevation-0">
                <v-stepper-step :complete="step > 1" step="1">Query</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2">Fields</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 3" step="3">Export</v-stepper-step>
            </v-stepper-header>
            <v-stepper-content step="1" height="100%" class="pa-4">
                <v-card-title class="d-flex justify-center align-center">Query</v-card-title>
                <v-row v-for="(or, index) in queryColumns" :key="index" class="flex-column">
                    <v-card-title v-if="index > 0" class="d-flex justify-center align-center">OR</v-card-title>
                    <v-card 
                        outlined 
                        rounded 
                        class="flex-column justify-center align-center pa-4">
                        <v-row 
                            class="px-4 pb-4" 
                            justify="space-between"
                            align="center"
                            v-for="(and, index2) in queryColumns[index]" 
                            :key="index2">
                        <v-card-title v-if="index2 > 0">AND</v-card-title>
                            <v-select
                                class="pr-2"
                                :items="tables"
                                @change="and.column = ''; and.value = ''; and.operator = ''"
                                item-text="text"
                                item-value="value"
                                label="Object"
                                hide-details
                                v-model="and.table"/>
                            <v-autocomplete
                                class="px-2"
                                :items="getColumns(and.table)"
                                item-text="column_show_name"
                                item-value="column_name"
                                :disabled="!and.table"
                                label="Column"
                                @change="and.value = '';and.operator = ''"
                                hide-details
                                v-model="and.column"/>
                            <v-select
                                v-if="returnDataType(and.table, and.column) !== 'tinyint'"
                                class="px-2"
                                :items="operatorsToReturn(returnDataType(and.table, and.column))"
                                @change="determineNull(and.operator, index, index2)"
                                label="Operator"
                                hide-details="auto"
                                :hint="getHint(and.operator)"
                                v-model="and.operator"/>
                            <div 
                                class="d-flex justify-center align-center pl-2"
                                v-if="dateTypes.indexOf(returnDataType(and.table, and.column)) !== -1 && returnDataType(and.table, and.column) !== 'tinytint'">
                                <v-menu bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            icon
                                            v-bind="attrs"
                                            v-on="on">
                                                <v-icon>
                                                mdi-calendar
                                                </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-date-picker
                                        v-model="and.value"
                                        @input="() => { and.value = and.value.toISOString().split('T')[0] }"
                                        no-title
                                        scrollable
                                        color="primary"
                                        ></v-date-picker>
                                </v-menu>
                                <v-text-field
                                    label="Date"
                                    hint="YYYY-MM-DD"
                                    :rules="[
                                        v => !!v || 'Date is required',
                                        v => v.length === 10 || 'Date must be in YYYY-MM-DD format',
                                        v => v.length && v.split('-').length === 3 || 'Date must be in YYYY-MM-DD format',
                                        v => v.length && v.split('-')[0].length === 4 || 'Date must be in YYYY-MM-DD format',
                                        v => v.length && v.split('-')[1].length === 2 || 'Date must be in YYYY-MM-DD format',
                                        v => v.length && v.split('-')[2].length === 2 || 'Date must be in YYYY-MM-DD format'
                                    ]"
                                    v-model="and.value"
                                    hide-details
                                    placeholder="YYYY-MM-DD"/>
                            </div>
                            <v-autocomplete
                                v-else-if="hasRelatedData(and.table, and.column)"
                                class="pl-2"
                                hide-details
                                v-model="and.value"
                                :items="findDependency(and.table, and.column)"
                                :item-text="findDependencyValues(and.table, and.column).text"
                                :item-value="findDependencyValues(and.table, and.column).value"
                                label="Value"/>
                            <v-select
                                v-else-if="returnDataType(and.table, and.column) === 'tinyint'"
                                class="pl-2"
                                @change="and.operator = '='"
                                :items="booleanOperators"
                                label="Value"
                                hide-details
                                v-model="and.value"/>
                            <v-text-field
                                v-else-if="returnDataType(and.table, and.column) !== 'tinyint' && and.operator !== 'IS NULL' && and.operator !== 'IS NOT NULL'"
                                class="pl-2"
                                label="Value"
                                hide-details
                                v-model="and.value"/>
                            <!-- remove query line -->
                            <v-btn 
                                color="error" 
                                text
                                v-if="index2 != 0 || index != 0"
                                @click="removeQuery(index, index2)">
                                X
                            </v-btn>
                        </v-row>
                        <v-card-actions class="d-flex justify-center align-center">
                            <v-btn color="primary" @click="queryColumns[index].push({table: '', column: '', operator: '', value: ''})">And</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-row>
                <v-row justify="space-around" class="pt-4">
                    <v-btn color="primary" @click="queryColumns.push([{table: '', column: '', operator: '', value: ''}])">OR</v-btn>
                </v-row>
                <v-row class="pa-4" align="center" justify="end">
                    <v-btn 
                    color="primary"
                    @click="step = 2"
                    :disabled="queryIncomplete">
                    Next
                </v-btn>
                </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
                <v-card-title class="d-flex justify-center align-center">Fields</v-card-title>
                <v-row class="pt-4">
                    <v-col cols="8">
                        <v-text-field
                            outlined
                            v-model="search"
                            class="pb-2"
                            label="Search"
                            hide-details/>
                        <v-card 
                            width="100%"
                            v-for="(table, index) in columnsToSelectFiltered" :key="index" 
                            outlined 
                            rounded 
                            class="flex-column justify-start align-start pa-4">
                            <v-card-title class="d-flex justify-center align-center">{{ table.table }}</v-card-title>
                            <v-row class="px-4 pb-4">
                                <v-col :cols="12 / tables.length" md="3" v-for="(column, index2) in table.columns" :key="index2">
                                    <v-btn
                                        class="pr-2"
                                        hide-details
                                        text
                                        small
                                        @click="addColumn(column)">
                                        {{ column.column_show_name }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card class="pa-4" height="100%" width="95%">
                            <v-card-title class="d-flex justify-center align-center">Selected</v-card-title>
                            <v-card-subtitle class="d-flex justify-center align-center">Drag and drop to reorder</v-card-subtitle>
                            <v-list class="flex-column justify-space-between align-center">
                                <draggable v-model="selectedColumns">
                                    <v-list-item 
                                        v-for="(column, index) in selectedColumns" 
                                        :key="index"
                                        class="d-flex justify-space-between align-center">
                                        <div>
                                            <v-icon style="cursor: grab">mdi-menu</v-icon>
                                            {{ column.text }}
                                        </div>
                                        <v-spacer/>
                                        <v-btn
                                            @click="removeColumn(column)"
                                            color="black"
                                            icon
                                            small>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-list-item>
                                </draggable>    
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="pa-4" justify="space-between">
                    <v-btn color="secondary" @click="step = 1">Back</v-btn>
                    <v-btn 
                        color="primary" 
                        :loading="loading"
                        @click="determineDateUpdate()"
                        :disabled="selectedColumns.length === 0">Next</v-btn>
                </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
                <v-card-title class="d-flex justify-center align-center">Export Review</v-card-title>
                <v-card-text class="flex-column justify-center align-center">
                    <div class="d-flex justify-center align-center" v-for="(line, index) in reviewText.split('<br>')" :key="index">
                        {{ line }}
                    </div>
                </v-card-text>
                <v-row class="pa-4" justify="space-between">
                    <v-btn color="secondary" @click="step = 2">Back</v-btn>
                    <download-csv
                        v-if="queryResult.length"
                        class   = "btn btn-default"
                        :data   = "queryResult"
                        name    = "CRM_Export.csv">
                        <v-btn
                            color="primary">
                            Download
                        </v-btn>
                    </download-csv>

                    <v-btn 
                        color="primary" 
                        @click="submitQuery()"
                        :loading="loading">Export</v-btn>
                </v-row>
            </v-stepper-content>
        </v-stepper>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-container>
</template>

<script>

import draggable from 'vuedraggable'

export default {
    beforeMount () {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - CRM'
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Leads', path: '/sales/crm/leads/home', icon: 'mdi-account-box-outline', notification: false},
            {title: 'Accounts', icon: 'mdi-domain', path: '/sales/crm/accounts/home', notification: false},
            {title: 'Admin', path: '/sales/crm/admin/home', icon: 'mdi-cog', notification: false},
            {title: 'Batches', path: '/sales/crm/batches', icon: 'mdi-account-convert', notification: false},
            {title: 'Export', path: '/sales/crm/accounts/export', icon: 'mdi-export-variant', notification: false},
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ])
        this.$store.dispatch('updateNavbarTitle','CRM Export')
        this.getInfo()
    },
    components: {
        draggable
    },
    data() {
        return {
            step: 1,
            search: '',
            tables: [],
            operators: ['=','!=','>','<','>=','<=','LIKE','NOT LIKE','IN','NOT IN','IS','IS NOT','IS NULL','IS NOT NULL'],
            columns: [],
            dateOperators: [
                {
                    text:'Before',
                    value: '<'
                },
                {
                    text:'After',
                    value: '>'
                },
                {
                    text:'On',
                    value: '='
                },
                {
                    text:'Not On',
                    value: '!='
                }
            ],
            dateTypes: ['date','timestamp','datetime'],
            booleanOperators: [
                {
                    text: 'IS TRUE',
                    value: '1'
                },
                {
                    text: 'IS FALSE',
                    value: '0'
                },
                {
                    text: 'IS NULL',
                    value: 'NULL'
                },
                {
                    text: 'IS NOT NULL',
                    value: 'NOT NULL'
                }
            ],
            queryColumns: [
                [{
                    table: '',
                    column: '',
                    operator: '',
                    value: ''
                }]
            ],
            selectedColumns: [],
            queryResult: '',
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_ExportGetInfo?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/export?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    tables,
                    columns
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.tables = tables.map(item => ({
                    text: item.split('_Data')[0].replaceAll('_',' '),
                    value: item
                }))
                this.columns = this.formatColumns(columns)
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async submitQuery() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_ExportQuery?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/export?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            columns: this.selectedColumns,
                            conditions: this.queryColumns
                        })
                    }
                )
                const {
                    message,
                    data
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.queryResult = data
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                if (error === 'Endpoint Requested Timeout') {
                    this.snack('The report is taking too long to generate. It will be emailed to you.','info',3000)
                } else {
                    this.snack(error,'error',3000)
                }
            }
        },
        async determineDateUpdate() {
            let dateInQueryColumns = this.queryColumns.some(item => item.some(item2 => item2.table === 'Account_Data_New' && (item2.column === 'Last_Dispatched_Date' || item2.column === 'Last_Submission_Date')))
            let dateInSelectedColumns = this.selectedColumns.some(item => item.value === '`Account_Data_New`.`Last_Dispatched_Date`' || item.value === '`Account_Data_New`.`Last_Submission_Date`')
            if (dateInQueryColumns || dateInSelectedColumns) {
                try {
                    this.loading = true
                    const result = await fetch(
                        //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_LogLastDates?tab=configure
                        `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/export/update-dates?email=${this.$store.getters.getUserEmail}`,
                        {
                            method: 'GET',
                            Headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                    )
                    const {
                        message,
                    } = await result.json()
                
                    if(!result.ok) {
                        throw message
                    }
                
                    this.loading = false
                    this.snack(message,'success',3000)
                } catch (error) {
                    this.loading = false
                    this.snack(error,'error',3000)
                }
            }
            this.step = 3
        },
        determineNull(operator, index, index2) {
            if (operator === 'IS NULL' || operator === 'IS NOT NULL') {
                this.queryColumns[index][index2].operator = operator.split(' NULL')[0]
                this.queryColumns[index][index2].value = 'NULL'
            }
        },
        getColumns(table) {
            return this.columns.filter(item => item.table_name === table)
        },
        getColumnsFiltered(table) {
            if (this.search.length === 0) {
                return this.columns.filter(item => item.table_name === table)
            }
            return this.columns.filter(item => item.table_name === table && item.column_show_name.toLowerCase().includes(this.search.toLowerCase()))
        },
        removeQuery(index, index2) {
            this.queryColumns[index].splice(index2,1)
            if(this.queryColumns[index].length === 0) {
                this.queryColumns.splice(index,1)
            }
        },
        addColumn(column) {
            let columnFormat = '';
            if ('related_data' in column) {
                columnFormat = `\`${column.related_table}\`.\`${Object.keys(column.related_data[0])[1]}\``;
            } else {
                columnFormat = `\`${column.table_name}\`.\`${column.column_name}\``;
            }
            let columnShow = column.table_name.split('_Data')[0].replaceAll('_',' ') + ': ' + column.column_show_name
            let temp = {
                value: columnFormat,
                text: columnShow
            }
            if ('related_data' in column) {
                temp.table = column.related_table;
                temp.related_table = column.table_name;
            }
            this.selectedColumns.push(temp)
        },
        removeColumn(column) {
            const index = this.selectedColumns.indexOf(column)
            this.selectedColumns.splice(index,1)

        },
        formatColumns(columns) {
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i].table_name + '_' + columns[i].column_name;
                if(column in this.replaceColumns) {
                    columns[i].column_show_name = this.replaceColumns[column]
                } else if (this.preventColumns.includes(column)) {
                    columns.splice(i,1)
                    i--
                } else {
                    columns[i].column_show_name = columns[i].column_name.replaceAll('_',' ')
                }
            }
            return columns
        },
        returnDataType(table, column) {
            if (this.columns.length && table && column){
                return this.columns.find(item => item.table_name === table && item.column_name === column).data_type
            } else {
                return ''
            }
        },
        operatorsToReturn(dataType) {
            if(this.dateTypes.includes(dataType)) {
                return this.dateOperators
            } else {
                return this.operators
            }
        },
        hasRelatedData(table, column) {
            const item = this.columnMap[`${table}_${column}`];
            return item && Object.prototype.hasOwnProperty.call(item, 'related_data');
        },
        findDependency(table, column) {
            return this.columnMap[`${table}_${column}`]?.related_data;
        },
        findDependencyValues(table, column) {
            const dependency = this.columnMap[`${table}_${column}`]?.related_data;
            if (!dependency || dependency.length === 0) return { text: '', value: '' };
            const [value, text] = Object.keys(dependency[0]);
            return {
                text: text,
                value: value
            };
        },
        getHint(operator) {
            if (operator === 'IN' || operator === 'NOT IN') {
                return 'Use , to separate values'
            } else {
                return ''
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        reviewText() {
            let text = 'Select<br>'
            for (let i = 0; i< this.selectedColumns.length; i++) {
                text += `${this.selectedColumns[i].text}<br>`
            }
            text += '<br>Where<br>'
            for (let i = 0; i < this.queryColumns.length; i++) {
                for (let c = 0; c < this.queryColumns[i].length; c++) {
                    text += `${this.queryColumns[i][c].table}.${this.queryColumns[i][c].column} ${this.queryColumns[i][c].operator} ${this.queryColumns[i][c].value}<br>`
                }
            }
            return text
        },
        queryIncomplete() {
            return this.queryColumns.some(item => 
                item.some(item2 => 
                    !item2.table || 
                    !item2.column || 
                    !item2.operator || 
                    item2.value === undefined ||
                    item2.value === '' ||
                    item2.value === null
                )
            )
        },
        types() {
            return [... new Set(this.columns.map(item => item.data_type))]
        },
        columnMap() {
            const map = {}
            this.columns.forEach(item => {
                map[item.table_name + '_' + item.column_name] = item
            })
            return map
        },
        columnsToSelectFiltered() {
            let array = []
            for (let i = 0; i < this.tables.length; i++) {
                const temp = {
                    table: this.tables[i].text,
                    columns: []
                }
                for (let c = 0; c < this.columns.length; c++) {
                    if(this.columns[c].table_name === this.tables[i].value) {
                        let format = `\`${this.columns[c].table_name}\`.\`${this.columns[c].column_name}\``
                        if ('related_data' in this.columns[c]) {
                            format = `\`${this.columns[c].related_table}\`.\`${Object.keys(this.columns[c].related_data[0])[1]}\``
                        }
                        let alreadySelected = this.selectedColumns.find(item => item.value === format)
                        if(alreadySelected === undefined && this.preventColumns.indexOf(this.columns[c].table_name + '_' + this.columns[c].column_name) === -1) {
                            temp.columns.push(this.columns[c])
                        }
                    }
                }
                array.push(temp)
            }

            if (this.search.length) {
                for (let i = 0; i < array.length; i++) {
                    array[i].columns = array[i].columns.filter(item => item.column_show_name.toLowerCase().includes(this.search.toLowerCase()))
                    if (array[i].columns.length === 0) {
                        array.splice(i,1)
                        i--
                    }
                }
            }
            return array
        },
        replaceColumns() {
            return {
                'Account_Data_New_Default Branch ID': 'Branch',
                'Account_Data_New_Industry_Id': 'Industry',
                'Account_Data_New_Price ID': 'Price Category',
                'Account_Data_New_Secondary_Price_Id': 'Secondary Price Category',
                'Account_Data_New_Cost ID': 'Cost Category',
                'Account_Data_New_External_Sales_Rep_Id': 'External Sales Rep',
                'Account_Data_New_Account_Type_Id': 'Account Type',
                'Account_Data_New_Channel_Id': 'Channel',
                'Account_Data_New_Forecast_Id': 'Forecast Category',
                'Store_Locator_Data_Place_Id': 'Google Place Id',
            }
        },
        preventColumns() {
            return [
                'Account_Data_New_Klaviyo_List_Id',
                'Account_Data_New_PH_Lead',
                'Account_Data_New_Lead_Id',
                'Account_Data_New_Volcom_Distribution_Method',
                'Account_Data_New_Volcom_Territory',
                'Account_Data_New_External Sales Rep',
                'Account_Data_New_Industry_Id_Old'
            ]
        }
    }
}
</script>

<style>

</style>