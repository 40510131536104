import VueRouter from 'vue-router'

import Home from './components/Home/Home.vue'
//Admin
import Admin_Login from './components/Admin/login.vue'
import Admin_Sign_Up from './components/Admin/signUp.vue'
import Admin_User from './components/Admin/userInfo.vue'
import Manage_Quotes from './components/Admin/manageQuotes/Home.vue'
import Manage_Employees from './components/Admin/manageEmployees/Home.vue'

//General
import General_PTO_Home from './components/General/PTOTracker/Home.vue'
import FAQ from './components/General/FAQ/faq.vue'
import Errors from './components/General/Errors/Home.vue'
import Merchandise_Tracker from './components/General/MerchandiseTracker/Home.vue'

//Ops Routes
//Stock Market
import Ops_Stock_Market_Home from './components/Ops/StockMarket/Home.vue'
import Ops_Stock_Market_Manage_Groups from './components/Ops/StockMarket/ManageGroups.vue'
import Ops_Stock_Market_Manage_Branch_Transfers from './components/Ops/StockMarket/ManageBranchTransfers.vue'
import Ops_Stock_Market_Manage_Branches from './components/Ops/StockMarket/manageBranches.vue'
//Customs Updater
import Ops_SS_Customs_Updater from './components/Ops/EcomCustomsForms/Home.vue'
import Ops_Volcom_Report from './components/Ops/VolcomReport/Home.vue'
import Ops_Manage_Store_Products from './components/Ops/ManageStore/products.vue'
//Inventory Report
import Ops_Inventory_Report from './components/Ops/InventoryReport/Home.vue'
import Ops_Inventory_Report_Settings from './components/Ops/InventoryReport/settings.vue'
import Ops_Inventory_Report_Purchases from './components/Ops/InventoryReport/Purchases.vue'
//Envoy
import Ops_Envoy_Update_Order from './components/Ops/EnvoyUpdate/Home.vue'
//store locator
import Ops_Store_Locator from './components/Ops/StoreLocator/map.vue'
import Ops_Store_Locator_Test from './components/Ops/StoreLocator/Home.vue'
import Ops_Store_Locator_Form from './components/Ops/StoreLocator/form.vue'
//Create Account
import Ops_Create_Account from './components/Ops/Accounts/Home.vue'
//Costs
import Ops_Manage_Costs from './components/Ops/Costs/Home.vue'
//EDI Stores
import Ops_EDI_Stores from './components/Ops/EDI/Home.vue'

//Marketing
import Marketing_Dashboard_Edit from './components/Marketing/Dashboard/App.vue'

//Accounting
import Accounting_Collectibles from './components/Accounting/Collectibles/Home.vue'
import Account_Cash_Flow_Model from './components/Accounting/CashflowModel/Home.vue'
import Accounting_Fix_Failed_Payments from './components/Accounting/Payments/Home.vue'

//Sales Routes
//CRM
import Sales_CRM_Leads_Home from './components/Sales/CRM/Leads/Home.vue'
import Sales_CRM_Leads_Lead from './components/Sales/CRM/Leads/Lead.vue'
import Sales_CRM_Accounts_Home from './components/Sales/CRM/Accounts/Home.vue'
import Sales_CRM_Accounts_Account from './components/Sales/CRM/Accounts/Account.vue'
import Sales_CRM_Credit_Reference_Response from './components/Sales/CRM/Accounts/CreditApplication/creditReferenceResponse.vue'
import Sales_CRM_Credit_Reference_Application from './components/Sales/CRM/Accounts/CreditApplication/creditApplication.vue'
import Sales_CRM_Admin from './components/Sales/CRM/Admin/settings.vue'
import Sales_CRM_Exprot from './components/Sales/CRM/Accounts/export.vue'
//Envoy Batches
import Sales_Envoy_Batches from './components/Sales/CRM/Accounts/batchReview.vue'
//NDA
import Sales_NDA_Links from './components/Sales/SalesReps/NDA/NDALinks.vue'
import NDA_Form from './components/Sales/SalesReps/NDA/Home.vue'
import NDA_Confirmation from './components/Sales/SalesReps/NDA/confirmation.vue'
//Lead Generation
import Sales_LeadGeneration_Home from './components/Sales/SalesReps/LeadGeneration/Home.vue'
import Sales_LeadGeneration_Matching from './components/Sales/SalesReps/LeadGeneration/Matching.vue'
//Store
import Sales_Store_Login from './components/Sales/Store/login.vue'
import Sales_Store_Home from './components/Sales/Store/home.vue'
import Sales_Store_Product from './components/Sales/Store/product.vue'
import Sales_Store_Cart from './components/Sales/Store/cart.vue'
//Forecasting
import Sales_Forecast_Home from './components/Sales/Forecast/pages/App.vue'
import Sales_Forecast_Accounts from './components/Sales/Forecast/pages/accounts.vue'
import Sales_Forecast_Categories from './components/Sales/Forecast/pages/categories.vue'
import Sales_Forecast_Products from './components/Sales/Forecast/pages/products.vue'
import Sales_Forecast_Bulk_Upload from './components/Sales/Forecast/pages/bulkUpload.vue'
//Amazon
import Sales_Amazon_Fees from './components/Sales/AmazonFees/Home.vue'
//Amazon Products
import Sales_Amazon_Products from './components/Sales/Amazon/Home.vue'
//Fix Database
import Sales_Database_Fix_Sales_Orders_Home from './components/Sales/FixDatabase/SalesOrders/Home.vue'
import Sales_Database_Fix_Credit_Notes_Home from './components/Sales/FixDatabase/CreditNotes/Home.vue'
import Sales_Database_Fix_Payments_Home from './components/Sales/FixDatabase/Payments/Home.vue'
//Sales Reps
import Sales_Sales_Reps_Manage_Reps from './components/Sales/SalesReps/ManageReps/Home.vue'
//Price Lists
import Sales_Price_Lists from './components/Sales/PriceLists/Home.vue'

//Random
import NotFoundComponent from './components/Admin/notFound.vue'
import Not_Done from './components/Admin/notDone.vue'
import Not_Allowed from './components/Admin/notAllowed.vue'

//PAM
import PAM from './components/PAM/Home.vue'

//Development
import store from './store'
const routes = [
    { 
      name: "Home", 
      path: '/', 
      component: Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['User', 'Special Accounting']
      }
    },
    { 
      name: "Merchandise Tracker", 
      path: '/General/Merchandise-Tracker', 
      component: Merchandise_Tracker,
      meta: {
        requiresAuth: true,
        requiredServices: ['User']
      }
    },
    { 
      name: "Pam", 
      path: '/pam', 
      component: PAM,
      meta: {
        requiresAuth: true,
        requiredServices: ['User']
      }
    },
    { 
      name: "FAQ", 
      path: '/faqs', 
      component: FAQ,
      meta: {
        requiresAuth: true,
        requiredServices: ['User']
      }
    },
    { 
      name: "Errors", 
      path: '/errors', 
      component: Errors,
      meta: {
        requiresAuth: true,
        requiredServices: ['User']
      }
    },
    { 
      name: "Quotes", 
      path: '/admin/quotes', 
      component: Manage_Quotes,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin']
      }
    },
    { 
      name: "Employees", 
      path: '/admin/employees', 
      component: Manage_Employees,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin']
      }
    },
    { 
      name: "Collectibles", 
      path: '/accounting/collectibles/home', 
      component: Accounting_Collectibles,
      meta: {
        requiresAuth: true,
        requiredServices: ['Accounting','Admin']
      }
    },
    {
      name: "Cash Flow Model", 
      path: '/accounting/cashflowmodel/home', 
      component: Account_Cash_Flow_Model,
      meta: {
        requiresAuth: true,
        requiredServices: ['Accounting','Admin', 'Special Accounting']
      }
    },
    {
      name: "Fix Failed Payments",
      path: '/accounting/payments/home',
      component: Accounting_Fix_Failed_Payments,
      meta: {
        requiresAuth: true,
        requiredServices: ['Accounting','Admin']
      }
    },
    { 
      name: "NDA Form", 
      path: '/NDA', 
      component: NDA_Form,
      meta: {
        requiresAuth: false
      }
    },
    { 
      name: "NDA Confirmation", 
      path: '/confirmation/:id', 
      component: NDA_Confirmation,
      meta: {
        requiresAuth: false
      }
    },
    { 
      name: "NDA Links", 
      path: '/sales/NDA/links', 
      component: Sales_NDA_Links,
      meta: {
        requiresAuth: true,
        requiredServices: ['Sales','Admin','Ops']
      }
    },
    { 
      name: "Credit Response", 
      path: '/Credit_Response', 
      component: Sales_CRM_Credit_Reference_Response,
      meta: {
        requiresAuth: false
      }
    },
    { 
      name: "Credit Application", 
      path: '/Credit_Application/:id', 
      component: Sales_CRM_Credit_Reference_Application,
      meta: {
        requiresAuth: false
      }
    },
    { 
      name: "Lead Generation Home", 
      path: '/Lead_Generation/Home', 
      component: Sales_LeadGeneration_Home,
      meta: {
        requiresAuth: false
      }
    },
    { 
      name: "Lead Generation Matching", 
      path: '/Sales/Lead_Generation/Matching', 
      component: Sales_LeadGeneration_Matching,
      meta: {
        requiresAuth: true,
        requiredServices: ['Sales','Admin','Ops']
      }
    },
    { 
      name: "Marketing Dashboard", 
      path: '/marketing/dashboard/edit', 
      component: Marketing_Dashboard_Edit,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin', 'Marketing']
      }
    },
    { 
      name: "Amazon Fees Home", 
      path: '/sales/amazon/fees/home', 
      component: Sales_Amazon_Fees,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin', 'Sales', 'Ops']
      }
    },
    { 
      name: "Fix Sales Orders", 
      path: '/sales/database/fix/salesorders', 
      component: Sales_Database_Fix_Sales_Orders_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin', 'Sales']
      }
    },
    { 
      name: "Fix Credit Notes", 
      path: '/sales/database/fix/creditnotes', 
      component: Sales_Database_Fix_Credit_Notes_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales']
      }
    },
    { 
      name: "Fix Payments", 
      path: '/sales/database/fix/payments', 
      component: Sales_Database_Fix_Payments_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales']
      }
    },
    { 
      name: "Forecast Home", 
      path: '/sales/forecast/home', 
      component: Sales_Forecast_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Forecast Accounts", 
      path: '/sales/forecast/accounts', 
      component: Sales_Forecast_Accounts,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Forecast Categories", 
      path: '/sales/forecast/categories', 
      component: Sales_Forecast_Categories,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Forecast Products", 
      path: '/sales/forecast/products', 
      component: Sales_Forecast_Products,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Bulk Upload Forecast", 
      path: '/sales/forecast/bulk-upload', 
      component: Sales_Forecast_Bulk_Upload,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Manage Sales Reps", 
      path: '/sales/salesreps/manage', 
      component: Sales_Sales_Reps_Manage_Reps,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops','Sales_Admin','Ops_Admin']
      }
    },
    {
      name: "Amazon Products",
      path: '/sales/amazon/products',
      component: Sales_Amazon_Products,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Accounts Home", 
      path: '/sales/crm/accounts/home', 
      component: Sales_CRM_Accounts_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Account Export", 
      path: '/sales/crm/accounts/export', 
      component: Sales_CRM_Exprot,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Accounts Page", 
      path: '/sales/crm/accounts/:id', 
      component: Sales_CRM_Accounts_Account,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Leads Home", 
      path: '/sales/crm/leads/home', 
      component: Sales_CRM_Leads_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "Leads Page", 
      path: '/sales/crm/leads/:id', 
      component: Sales_CRM_Leads_Lead,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Sales','Ops']
      }
    },
    { 
      name: "CRM Admin Page", 
      path: '/sales/crm/admin/home', 
      component: Sales_CRM_Admin,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin','Sales_Admin']
      }
    },
    {
      name: "Envoy Batches",
      path: '/sales/crm/batches',
      component: Sales_Envoy_Batches,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin','Sales_Admin','Sales','Ops']
      }
    },
    { 
      name: "Price Lists", 
      path: '/sales/priceLists/home', 
      component: Sales_Price_Lists,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin','Sales_Admin','Sales','Ops']
      }
    },
    { 
      name: "Store Home", 
      path: '/store/home', 
      component: Sales_Store_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['User','Shopper']
      }
    },
    { 
      name: "Store Cart", 
      path: '/store/cart', 
      component: Sales_Store_Cart,
      meta: {
        requiresAuth: true,
        requiredServices: ['User','Shopper']
      }
    },
    { 
      name: "Store Product", 
      path: '/store/product/:id', 
      component: Sales_Store_Product,
      meta: {
        requiresAuth: true,
        requiredServices: ['User','Shopper']
      }
    },
    { 
      name: "Store Login", 
      path: '/store/login', 
      component: Sales_Store_Login,
      meta: {
        requiresAuth: false,
        requiredServices: ['User']
      }
    },
    {
      name: "Create Account",
      path: '/Ops/CreateAccount/Home',
      component: Ops_Create_Account,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin','Ops']
      }
    },
    { 
      name: "Enovy Update", 
      path: '/Ops/Envoy/VPL', 
      component: Ops_Envoy_Update_Order,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin', 'Ops', 'Sales']
      }
    },
    { 
      name: "Volcom Report", 
      path: '/Ops/VolcomReport/Home', 
      component: Ops_Volcom_Report,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin']
      }
    },
    { 
      name: "Stock Market", 
      path: '/Ops/StockMarket/Home', 
      component: Ops_Stock_Market_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops','Ops_Admin']
      }
    },
    { 
      name: "Manage Branch Transfers", 
      path: '/Ops/StockMarket/ManageBranchTransfers', 
      component: Ops_Stock_Market_Manage_Branch_Transfers,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin']
      }
    },
    { 
      name: "Manage Branch Transfers", 
      path: '/Ops/StockMarket/ManageBranches', 
      component: Ops_Stock_Market_Manage_Branches,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin']
      }
    },
    { 
      name: "Manage Ops Groups", 
      path: '/Ops/StockMarket/Manage', 
      component: Ops_Stock_Market_Manage_Groups,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin']
      }
    },
    { 
      name: "Inventory Report", 
      path: '/Ops/InventoryReport/Home', 
      component: Ops_Inventory_Report,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops','Ops_Admin']
      }
    },
    { 
      name: "Inventory Report Settings", 
      path: '/Ops/InventoryReport/Settings', 
      component: Ops_Inventory_Report_Settings,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops','Ops_Admin']
      }
    },
    { 
      name: "Inventory Report Settings", 
      path: '/Ops/InventoryReport/Purchases', 
      component: Ops_Inventory_Report_Purchases,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops','Ops_Admin']
      }
    },
    {
      name: "ShipStation Customer Updater", 
      path: '/Ops/SSCustomsUpdater/Home', 
      component: Ops_SS_Customs_Updater,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin','Ops']
      }
    },
    {
      name: "Manage Store Products", 
      path: '/Ops/ManageStore/Products', 
      component: Ops_Manage_Store_Products,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin','Ops','Sales','Sales_Admin']
      }
    },
    {
      name: "Manage Costs", 
      path: '/Ops/Costs', 
      component: Ops_Manage_Costs,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin','Ops','Sales','Sales_Admin']
      }
    },
    {
      name: 'EDI Stores',
      path: '/Ops/EDI-Stores',
      component: Ops_EDI_Stores,
      meta: {
        requiresAuth: true,
        requiredServices: ['Admin','Ops_Admin','Ops','Sales','Sales_Admin']
      }
    },
    { 
      name: "Store Locator", 
      path: '/Store-Locator', 
      component: Ops_Store_Locator,
      meta: {
        requiresAuth: false,
        requiredServices: []
      }
    },
    { 
      name: "Store Locator Test", 
      path: '/Store-Locator/Test', 
      component: Ops_Store_Locator_Test,
      meta: {
        requiresAuth: false,
        requiredServices: []
      }
    },
    { 
      name: "Maps", 
      path: '/Store-Locator/Form', 
      component: Ops_Store_Locator_Form,
      meta: {
        requiresAuth: false,
        requiredServices: []
      }
    },
    { 
      name: "PTO Tracker", 
      path: '/General/PTOTracker/Home', 
      component: General_PTO_Home,
      meta: {
        requiresAuth: true,
        requiredServices: ['User']
      }
    },
    { 
      name: "Login", 
      path: '/admin/login', 
      component: Admin_Login,
      meta: {
        requiresAuth: false,
      }
    },
    { 
      name: "Sign Up", 
      path: '/admin/signup', 
      component: Admin_Sign_Up,
      meta: {
        requiresAuth: false,
      }
    },
    { 
      name: "User Info", 
      path: '/admin/user', 
      component: Admin_User,
      meta: {
        requiresAuth: true,
        requiredServices: ['User', 'Special Accounting']
      }
    },
    { 
      name: "Not Done", 
      path: '/NotDone', 
      component: Not_Done,
      meta: {
        requiresAuth: false,
        requiredServices: ['User', 'Special Accounting']
      }
    },
    { 
      name: "Not Allowed", 
      path: '/taboo', 
      component: Not_Allowed,
      meta: {
        requiresAuth: false,
        requiredServices: ['User', 'Special Accounting']
      },
    },
    { 
      path: '/:catchAll(.*)', 
      component: NotFoundComponent,
      name: 'NotFound',
      meta: {
        requiresAuth: false,
        requiredServices: ['User']
      }
    }
  ]

  const router = new VueRouter({
    routes
  })
  
  router.beforeEach((to, from, next) => {
    if (localStorage.loggedIn) {
      store.dispatch('updateLoggedIn', true)
      store.dispatch('updateUser', JSON.parse(localStorage.user))
    }

    if (localStorage.permissions) {
      store.dispatch('updatePermissions', JSON.parse(localStorage.permissions))
    }

    
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.isLoggedIn) {
        next({ name: 'Login' })
      } else if (to.meta.requiredServices) {
        let userPermissions = store.getters.getPermissions
        let mergedPermissions = to.meta.requiredServices.concat(userPermissions)
        let uniquePermissions = [... new Set(mergedPermissions)]
        if (uniquePermissions.length !== mergedPermissions.length) {
          next()
        } else {
          next({ name: 'Not Allowed'})
        }
      } else {
        next()
      }
    } else {
      next() 
    }
  })

export default router