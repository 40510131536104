<template>
  <div>
        <v-card-title>
            Single Items
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="values"
            :search="search"
            class="elevation-1">
            <template v-slot:[`item.Value`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.Value"
                    large
                    @save="save(prop.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.Value }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.Value"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
        </v-data-table>
        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            >
            {{ snackText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                v-bind="attrs"
                text
                @click="snack = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

export default {
    props: {
        inputtedDate: {type: Object}
    },
    beforeMount() {
        this.getSingleValues();
    },
    watch: {
        inputtedDate: function() {
            this.getSingleValues();
        }
    },
    data: () => ({
        values: [
            {
                "Name": 'Attentive Subscriber Count',
                "Value": '0'
            },
            {
                "Name": 'Facebook Follow Count',
                "Value": '0'
            }
        ],
        snack: false,
        snackColor: '',
        snackText: '', 
        search: '',
    }),
    methods: {
        getSingleValues: function() {
            if (this.$props.inputtedDate.startDate && this.$props.inputtedDate.endDate) {
                const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/singlevalues';
                const data = {
                    'startDate': this.$props.inputtedDate.startDate,
                    'endDate': this.$props.inputtedDate.endDate
                }
                const request = {
                    method: 'POST',
                    Headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(data)
                };
                fetch(url, request).then((response) => {
                    return response.json();
                }).then((data) => {
                    this.values = data;
                });
            }
        },
        save (item) {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Data saved'
            this.updateSingleValues(item.Name, item.Value)
            console.log(item);
        },
        cancel () {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Canceled'
        },
        open () {
            this.snack = true
            this.snackColor = 'info'
            this.snackText = 'Dialog opened'
        },
        close () {
            console.log('Dialog closed')
        },
        updateSingleValues(name, value) {
            const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/singlevalues';
            const data = {
                'Name': name,
                'Value': value,
                'startDate': this.$props.inputtedDate.startDate,
                'endDate': this.$props.inputtedDate.endDate
            }
            const request = {
                method: 'PUT',
                Headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(data)
            };
            fetch(url, request).then((response) => {
                return response.json();
            }).then((data) => {
                console.log(data)
            });
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: 'Name',
                    value: 'Name'
                },
                {
                    text: 'Value',
                    value: 'Value'
                },
            ]
        }
    }
}
</script>
