<template>
    <v-card 
        :style="'color: ' + color + ';'" 
        :loading="loading" 
        width="100vw" 
        :height="cardHeight + 'vh'"
        class="my-2 mx-4 d-flex justify-start">
        <div class="d-flex flex-no-wrap justify-space-between">
            <div>
                <v-menu
                    ref="colorPicker"
                    :close-on-content-click="false"
                    v-model="colorModal"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    width="20vw">
                    <template v-slot:activator="{ on, attrs }">
                        <v-card-title 
                            style="word-break: normal;"
                            class="text-h8 pb-0" 
                            v-bind="attrs"
                            v-on="on">
                            {{ productName }}
                        </v-card-title>
                    </template>
                        <v-card class="d-flex-column align-center">
                            <v-color-picker
                                v-model="tempColor"
                                class="ma-2"
                                dot-size="30"
                                hide-inputs
                            ></v-color-picker>
                            <v-switch
                                v-model="activeStatusTemp"
                                color="primary"
                                label="Active"/>
                            <v-card-actions class="d-flex justify-space-between">
                                <v-btn @click="colorModal = false">
                                    Cancel
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    @click="updateProduct()">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                <v-card-subtitle class="py-0">
                    {{ productSKU }}
                </v-card-subtitle>
                <v-menu
                    ref="breakout"
                    :close-on-content-click="false"
                    v-model="breakout"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    width="20vw">
                    <template v-slot:activator="{ on, attrs }">
                        <v-card-subtitle 
                            v-on="on"
                            v-bind="attrs"
                            @click="getBranchBreakout(productSKU, opsGroup)"
                            class="pt-0">
                            SOH: {{ SOH }}
                        </v-card-subtitle>
                    </template>
                    <v-card class="pa-4">
                        <v-card-title class="d-flex justify-center align-center">
                            SOH: Branch Breakout
                        </v-card-title>
                        <template v-if="branchBreakout.length">
                            <v-row
                                class="pl-2" 
                                v-for="(branch, index) in branchBreakout" :key="index">
                                {{ branch.Company }}: {{ branch.SOH }}
                            </v-row>
                            <v-row class="pl-2">
                                <strong class="pr-1">Total: </strong> {{ totalBreakOutQty }}
                            </v-row>
                        </template>
                        <v-card-text class="d-flex justify-center align-center" v-else>
                            <v-progress-circular color="primary" indeterminate/>
                        </v-card-text>
                        
                    </v-card>
                </v-menu>
                <v-card-actions class="pl-0 d-flex justify-start">
                    <v-text-field
                        class="shrink pl-0"
                        label="Product Floor"
                        v-model="productFloorTemp"
                        rounded
                        dense
                        hide-details/>
                    <v-btn
                        v-if="!disableProductFloorUpdate"
                        color="primary"
                        @click="updateProductFloor()"
                        :disabled="disableProductFloorUpdate">
                        Update Floor
                    </v-btn>
                </v-card-actions>
            </div>
        </div>
        <v-spacer/>
        <div class="pt-4">
            <forecast-chart
                :style="'height: ' + calculateChartHeight + 'px;'"
                v-model="chartEvent"
                :width="chartWidth"
                :height="chartHeight"
                :chartData="chartData"/>
        </div>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
        <v-menu
            v-model="inputRestockModal"
            absolute
            :close-on-content-click="false"
            :position-x="inputRestockX"
            :position-y="inputRestockY">
            <v-card>
                <v-card-actions>
                    <v-text-field
                        label="Restock Qty"
                        v-model="restockQty"
                        ref="input"
                        :hint="'Stock: ' + Math.round(selectedMonthStock)"
                        dense
                        outlined/>
                </v-card-actions>
            </v-card>
        </v-menu>
        <v-dialog
            v-model="restockDetailsModal">
            <restockDetailsDialog
                @close-restock-detail-modal="closeRestockDetailsModal"
                @change-restock-qty-from-dialog="changeRestockQtyFromDialog"
                @move-months="moveMonths"
                @refresh-branch-transfers="refreshBranchTransfers"
                :loading="loading"
                :opsGroup="opsGroup"
                :selectedMonth="months[selectedMonth - 1]"
                :selectedMonthNum="selectedMonth"
                :prevMonthSelected="prevMonthSelected"
                :prevYearSelected="prevYearSelected"
                :selectedYear="selectedYear"
                :productSKU="productSKU"
                :selectedRestockQty="Number(restockQty)"
                :productName="productName"
                :selectedStartQty="selectedStartQty"
                :selectedEndQty="selectedEndQty"
                :selectedBranchTransferDetails="filteredBranchTransfers"
                :selectedForecastDetails="filteredForecastDetails"
                :disableMoveLeft="disableMoveLeft"
                :disableMoveRight="disableMoveRight"
                :productFloor="productFloor"
            ></restockDetailsDialog>
        </v-dialog>
  </v-card>
</template>

<script>
import forecastChart from './forecastChart.vue'
import restockDetailsDialog from './restockDetailsDialog.vue'

export default {
    beforeMount() {
        this.chartHeight = this.calculateChartHeight
        this.setVisibleMonths()
        this.productFloorTemp = this.$props.productFloor
        this.activeStatusTemp = this.$props.activeStatus
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key === "Enter" && this.inputRestockModal) {
                this.submitRestock()
            }
        })
    },
    props: {
        productName: {type: String},
        productSKU: {type: String},
        productFloor: {type: Number},
        activeStatus: {type: Boolean},
        cardHeight: {type: Number},
        forecastData: {type: Array},
        branchTransferData: {type: Array},
        stockMarketData: {type: Array},
        SOH: {type: Number},
        color: {type: String},
        opsGroup: {type: String},
        lookForwardMonths: {type: Number},
        forecastToViewDetails: {type: Object}
    },
    components: {
        forecastChart,
        restockDetailsDialog
    },
    watch: {
        productFloor: function() {
            this.productFloorTemp = this.$props.productFloor
        },
        activeStatus: function() {
            this.activeStatusTemp = this.$props.activeStatus
        },
        chartEvent: function() {
            this.selectedIndex = this.chartEvent.index
            this.selectedMonthStock = this.chartData.datasets[1].data[this.chartEvent.index]

            if (this.selectedIndex === this.visibleMonths.length - 1) {
                this.disableMoveRight = true
                this.disableMoveLeft = false
            } else if (this.selectedIndex === 0) {
                this.disableMoveRight = false
                this.disableMoveLeft = true
            } else {
                this.disableMoveRight = false
                this.disableMoveLeft = false
            }
            if (this.chartEvent.type === 'hover') {
                this.chartHoverEvent(this.chartEvent.x, this.chartEvent.y)
            } else if (this.chartEvent.type === 'click') {
                this.fromForecastCheck = false
                this.getForecastDetails()
                this.chartClickEvent()
            }
        },
        forecastToViewDetails: function() {
            if (this.$props.forecastToViewDetails.matched) {
                this.fromForecastCheck = true
                //makes the visible months numbers (1 - 12) into a flat array to find the index
                let visibleMonthsMonthNumberFlat = this.visibleMonths.map(item => item.monthNum)
                //finds the index of the forecast to check details in the visible months array
                let index = visibleMonthsMonthNumberFlat.indexOf(this.$props.forecastToViewDetails.month)
                //sets selected index as the index variable
                this.selectedIndex = index
                this.getForecastDetails()
                this.chartClickEvent()
            }
        },
        lookForwardMonths: function() {
            this.setVisibleMonths()
        }
    },
    data() {
        return {
            selectedMonthStock: 0,
            chartHeight: 0,
            fromForecastCheck: false,
            tempColor: this.$props.color,
            colorModal: false,
            breakout: false,
            productFloorTemp: 0,
            activeStatusTemp: true,
            chartEvent: {},
            inputRestockModal: false,
            inputRestockX: 0,
            inputRestockY: 0,
            disableMoveLeft: false,
            disableMoveRight: false,
            restockDetailsModal: false,
            restockQty: 0,
            months: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            selectedIndex: 0,
            selectedStartQty: 0,
            selectedEndQty: 0,
            visibleMonths: [],
            selectedForecastDetails: [],
            branchBreakout: [],
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        async getBranchBreakout(sku, opsGroup) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetBranchBreakout?newFunction=true&tab=code
                    `https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/stock/breakout?email=${this.$store.getters.getUserEmail}&sku=${sku}&opsGroup=${opsGroup}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    branchBreakout
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.branchBreakout = branchBreakout
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        closeRestockDetailsModal() {
            this.restockDetailsModal = false
            if (this.fromForecastCheck) {
                this.$emit('clear-search', "")
            }
        },
        getRestockQty() {
            let restockQty = this.$props.stockMarketData.find(item => {
                return item.SKU == this.$props.productSKU &&
                item.Month == this.selectedMonth &&
                item.Year == this.selectedYear
            })
            
            if (restockQty !== undefined) {
                this.restockQty = restockQty.Restock
            } else {
                this.restockQty = 0
            }
        },
        changeRestockQtyFromDialog(newQty) {
            this.restockQty = newQty
            this.$emit('submit-restock', this.$props.productSKU, this.selectedMonth, this.selectedYear, Number(this.restockQty))
            this.calculateStartAndEndQty()
        },
        chartHoverEvent(x,y) {
            this.$nextTick(() => {
                this.inputRestockX = x + 5
                this.inputRestockY = y + 5
                this.getRestockQty()
                this.inputRestockModal = true

                //selects the text in the text field automatically
                setTimeout(() => {this.$refs.input.$el.querySelector('input').select()},50)
            })
        },
        chartClickEvent() {
            this.inputRestockModal = false
            this.getRestockQty()
            this.calculateStartAndEndQty()
            this.restockDetailsModal = true
        },
        moveMonths(direction) {
            if (direction === "right") {
                if (this.selectedIndex !== this.visibleMonths.length - 1) {
                    this.selectedIndex++
                    this.disableMoveRight = false
                    this.chartClickEvent()
                } else {
                    this.disableMoveRight = true
                }
                if (this.selectedIndex !== 0) {
                    this.disableMoveLeft = false
                }
            } else if (direction === "left") {
                if (this.selectedIndex !== 0) {
                    this.selectedIndex--
                    this.disableMoveLeft = false
                    this.chartClickEvent()
                } else {
                    this.disableMoveLeft = true
                }
                if (this.selectedIndex !== this.visibleMonths.length - 1) {
                    this.disableMoveRight = false
                }
            }
        },
        calculateStartAndEndQty() {
            let d = new Date()
            let month = d.getMonth()
            let thisMonthIndex = month
            let thisMonth = month + 1
            let prevMonth = 0
            //let monthIndex = 0
            let year = d.getFullYear()
            let prevYear = 0
            let thisYear = year
            let stockLeft = this.$props.SOH
            let previousMonthFromSelected = 0

            if (this.selectedMonth === 1) {
                previousMonthFromSelected = 12
            } else {
                previousMonthFromSelected = this.selectedMonth - 1
            }

            for (let i = 0; i < this.selectedIndex + 1; i++) {
                //monthIndex = this.visibleMonths[i].month
                month = this.visibleMonths[i].monthNum
                year = this.visibleMonths[i].year
                if (i > 0) {
                    prevMonth = this.visibleMonths[i - 1].monthNum
                    prevYear = this.visibleMonths[i - 1].year
                }
                let forecastQty = this.$props.forecastData.find(item =>
                    item.Month === month &&
                    item.Year === year
                )
                console.log(forecastQty)
                if (forecastQty !== undefined) {
                    let fractionOfMonth = 1

                    if (month === thisMonth && year === thisYear) {
                        let currentDay = d.getDate()
                        let daysInMonth = new Date(thisYear, thisMonthIndex, 0).getDate()
                        fractionOfMonth = (daysInMonth - currentDay + 1) / daysInMonth
                    }
                    stockLeft -= Number(forecastQty.Qty * fractionOfMonth)
                }
                //let halfMonth = Math.round(new Date(year, monthIndex, 0).getDate() / 2)
                
                if (this.$props.branchTransferData.length > 0) {
                    let branchTransferQty = this.$props.branchTransferData.reduce(function(sum, record) {

                        if ((record.ETADate <= 15 && record.ETAMonth === month && record.ETAYear === year) || 
                            (i > 0 && record.ETADate > 15 && record.ETAMonth === prevMonth && record.ETAYear === prevYear) ) {
                                return sum + Number(record.Qty)
                        } else {
                            return sum
                        }
                    },0)

                    stockLeft += Number(branchTransferQty)
                }
                if (this.$props.stockMarketData.length > 0) {
                    let stockMarketQty = this.$props.stockMarketData.find(item =>
                        item.Month === month && item.Year === year
                    )
                    if (stockMarketQty !== undefined) {
                        stockLeft += Number(stockMarketQty.Restock)
                    }
                }

                if (this.selectedIndex === 0) {
                    this.selectedStartQty = this.$props.SOH
                } else if (month === previousMonthFromSelected) {
                    this.selectedStartQty = Math.round(stockLeft)
                }

                if (month === this.selectedMonth) {
                    this.selectedEndQty = Math.round(stockLeft)
                }
            }
        },
        getForecastDetails() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetForecastDetails?tab=code
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/forecast/details'
            const data = {
                opsGroup: this.$props.opsGroup,
                sku: this.$props.productSKU,
                month: this.selectedMonth,
                year: this.selectedYear
            }
            const request = {
                method: 'POST',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.selectedForecastDetails = data.forecastDetails
                this.loading = false
            }).catch(error => {
                console.log(error)
                this.snack('Error Loading Forecast Details','error',3000)
                this.loading = false
            })
        },
        setVisibleMonths() {
            this.visibleMonths = []
            let d = new Date()
            let month = d.getMonth()
            let year = d.getFullYear()

            for (let i = 0; i < this.lookForwardMonths + 2; i++) {
                this.visibleMonths.push({
                    month: month,
                    monthNum: month + 1, 
                    year: year
                })
                if (month < 11) {
                    month++
                } else {
                    month = 0
                    year++
                }
            }
        },
        updateProductFloor() {
            this.loading = true
            this.$emit('product-floor-temp', this.productFloorTemp, this.$props.productSKU)
            this.loading = false
        },
        updateProduct() {
            if (this.activeStatusTemp) {
                this.activeStatusTemp = 1
            } else {
                this.activeStatusTemp = 0
            }
            this.$emit('update-product', this.tempColor, this.activeStatusTemp, this.$props.productSKU)
            this.colorModal = false
        },
        submitRestock() {
            this.$emit('submit-restock', this.$props.productSKU, this.selectedMonth, this.selectedYear, Number(this.restockQty))
            this.inputRestockModal = false
        },
        refreshBranchTransfers(id) {
            this.$emit('refresh-branch-transfers',id)
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        disableProductFloorUpdate() {
            if (!Number.isInteger(Number(this.productFloorTemp)) || this.productFloorTemp == this.$props.productFloor) {
                return true
            } else {
                return false
            }
        },
        selectedMonth() {
            return this.visibleMonths[this.selectedIndex].monthNum
        },
        selectedYear() {
            return this.visibleMonths[this.selectedIndex].year
        },
        prevMonthSelected() {
            if (this.selectedIndex > 0) {
                return this.visibleMonths[this.selectedIndex - 1].monthNum
            } else {
                return 0
            }
        },
        prevYearSelected() {
            if (this.selectedIndex > 0) {
                return this.visibleMonths[this.selectedIndex - 1].year
            } else {
                return 0
            }
        },
        filteredBranchTransfers() {
            let month = this.selectedMonth
            let year = this.selectedYear
            return this.branchTransferData.filter(item => {
                let etaDate = item.ETADate;
                let etaMonth = item.ETAMonth;
                let etaYear = item.ETAYear;
                if (etaMonth === month && etaYear === year) {
                    return etaDate <= 15;
                } else if (etaMonth === month - 1 && etaYear === year) {
                    return etaDate > 15;
                } else {
                    return false;
                }
            })
        },
        filteredForecastDetails() {
            return this.selectedForecastDetails.filter(item => item.Month === this.selectedMonth && item.Year === this.selectedYear)
        },
        calculateChartHeight() {
            return document.documentElement.clientHeight * ((this.$props.cardHeight * 0.9) / 100)
        },
        totalBreakOutQty() {
            return this.branchBreakout.reduce((sum, record) => {
                return sum + record.SOH
            }, 0)
        },
        chartWidth() {
            return document.documentElement.clientWidth * 0.8
        },
        chartData() {
            //makes labels
            let d = new Date()
            let month = d.getMonth()
            let thisMonthIndex = month
            let thisMonth = month + 1
            let year = d.getFullYear()
            let thisYear = year
            let stockLeft = this.$props.SOH
            let SOHData = [stockLeft]
            let labels = []
            let prevMonth = 0
            let prevYear = 0

            for (let i = 0; i < this.visibleMonths.length; i++) {
                month = this.visibleMonths[i].monthNum
                //let monthIndex = this.visibleMonths[i].month
                year = this.visibleMonths[i].year
                if (i > 0) {
                    prevMonth = this.visibleMonths[i - 1].monthNum
                    prevYear = this.visibleMonths[i - 1].year
                }
                
                //let halfMonth = Math.round(new Date(year, monthIndex, 0).getDate() / 2)
                // if (this.$props.forecastData.length > 0 && i > 0) {
                    //previous month and year is used because the forecast applies to the start of the next month
                    let forecastedQty = this.$props.forecastData.find(item =>
                        item.Month === month &&
                        item.Year === year
                    )

                    if (forecastedQty !== undefined) {
                        let fractionOfMonth = 1

                        if (month === thisMonth && year === thisYear) {
                            let currentDay = d.getDate()
                            let daysInMonth = new Date(thisYear, thisMonthIndex, 0).getDate()
                            fractionOfMonth = (daysInMonth - currentDay + 1) / daysInMonth
                        }

                        stockLeft -= Number(forecastedQty.Qty * fractionOfMonth)
                    }
                // }

                if (this.$props.branchTransferData.length > 0) {
                    let branchTransferQty = this.$props.branchTransferData.reduce(function(sum, record) {
                        if ((record.ETADate <= 15 && record.ETAMonth === month && record.ETAYear === year) || 
                            (i > 0 && record.ETADate > 15 && record.ETAMonth === prevMonth && record.ETAYear === prevYear)) {
                                return sum + Number(record.Qty)
                        } else {
                            return sum
                        }
                    },0)
                    stockLeft += Number(branchTransferQty)
                }
                let tempStockMarketQty = 0
                if (this.$props.stockMarketData.length > 0) {
                    let stockMarketQty = this.$props.stockMarketData.find(item =>
                        item.Month === month && item.Year === year
                    )
                    if (stockMarketQty !== undefined) {
                        stockLeft += Number(stockMarketQty.Restock)
                        tempStockMarketQty = Number(stockMarketQty.Restock)
                    } else {
                        tempStockMarketQty = 0
                    }
                }
                SOHData.push(stockLeft)
                labels.push([this.months[month - 1] + ' ' + year, tempStockMarketQty])
            }
            let data = {
                labels: labels,
                datasets: [
                    {
                        label: "Floor",
                        data: new Array(this.visibleMonths.length).fill(this.$props.productFloor),
                        borderColor: '#000000',
                        fill: false,
                        borderDash: [5,15],
                        pointRadius: 0
                    },
                    {
                        label: "Forecasted Stock",
                        fill: false,
                        data: SOHData,
                        borderColor: this.$props.color
                    }
                ]
            }
            return data
        }
    }
}
</script>

<style>

</style>