<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="error">mdi-skull-crossbones-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          Delete All Restock Data
        </v-card-title>
        <v-card-text justify="center" align="center" class="d-flex-column">
            <v-img height="40vh" width="40vw" src="../../../../assets/doubleCheck.jpg"/>
            Are you sure you want to delete all the restock data?<br>
            Make sure you have a file exported for reference.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-btn
                color="accent"
                @click="dialog = false"
            >
            i regret my actions
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="burnItToTheGround()"
            :loading="loading"
          >
            BURN IT TO THE GROUND
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar
          v-model="snackActive"
          :color="snackColor"
          :timeout="snackTimeout">
          {{ snackText }}
      </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    data () {
        return {
            dialog: false,
            loading: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        //when the confirmation button is clicked in the dialog, this sends a request to the lambda function to delete all the stock market data from the DB
        burnItToTheGround() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_DeleteRestockData?tab=code
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/products/details'
            const request = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.loading = false
                this.snack("it's all gone...", 'success', 3000)
                //emits the deletion up to Home so the local object is also emptied
                this.$emit('delete-restock', true)
                this.dialog = false
            }).catch(error => {
                this.loading = false
                this.snack("haha not today coper\n" + error, 'error', 3000)
                this.dialog = false
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    }
}
</script>

<style>

</style>