<template>
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card class="pa-4">
            <customTitle 
                :title="'Account Process'" 
                :name="'accountProcess'" 
                @close="$emit('close')"/>
                <v-row>
                    <v-card-text>
                        We have a very custom process for setting up accounts and as a result, its rather complex to explain so stick with me.
                        <ul>
                            <li>I will break the account process into 3 main categories</li>
                            <ul>
                                <li>Application</li>
                                <li>Acceptance/Denial</li>
                                <li>Management</li>
                            </ul>
                        </ul>
                        <h3>Application</h3>
                        <ul>
                            <li>
                                Accounts come into the system through our New Dealer Application (NDA). This is a custom form that is hosted in the Sandbox. <br>
                                Each Sales Rep (External) and Sales Manager (Internal) has their own unique link to the form.
                                <ul>
                                    <li>If a <strong>Sales Manager's</strong> link is used then the Sales Rep field is automatically assigned as 'In-House'.</li>
                                    <li>If a <strong>Sales Rep's</strong> link is used then the Sales Manager that is assigned, is the manager listed on the Sales Rep's record on the <a href="/sales/salesreps/manage" target="_blank">Manage Sales Rep Page</a></li>
                                    <li>If an account tries to fill out a form without valid link they will be met with <a href="/#/NDA" target="_blank">this</a> error message</li>
                                    <li>Example: 
                                        <v-btn 
                                            icon
                                            x-small
                                            @click="showManageSalesRepPage = !showManageSalesRepPage"
                                            color="primary">
                                            <v-icon>{{ showManageSalesRepPage ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                                        </v-btn>
                                    </li>
                                    <li v-if="showManageSalesRepPage">
                                        <v-img
                                            src="../src/Manage-Sales-Reps.png"
                                            aspect-ratio="1.5"
                                            contain
                                            class="mx-auto"/>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/NDA?rep=1" target="_blank">NDA Example</a>
                            </li>
                            <li>The user does not know they are filling out a form that is tied to a specific rep/manager other than the url id they see which doesn't mean anything to them.</li>
                            <li>There are four parts to the NDA form. I will not explain them in detail here as they are self explainatory and explained on the form itself</li>
                            <ul>
                                <li>Company Information</li>
                                <li>Buyer Information</li>
                                <ul>
                                    <li>The first contact is automatically assigned as the primary contact listed in 'Company Information' along with being the contact added in Cin7</li>
                                    <li>Any other contacts add after this will get an Envoy login through the bot creation. They will NOT get an entry in Cin7.</li>
                                </ul>
                                <li>Shipping/Billing Information</li>
                                <ul>
                                    <li>This must be a valid address chosen from the Google Maps autocomplete. But in a case where that fails, there is a <v-icon>mdi-plus</v-icon> icon that can be clicked for manual entry.</li>
                                </ul>
                                <li>Terms of Sale</li>
                            </ul>
                            <li>The form does not let the user enter until all fields are filled out and there is a notification at the bottom of the form that shows the user what fields they are missing if they expand the carrot.</li>
                            <li>Email Notifications:</li>
                            <ul>
                                <li>After the form is submitted, the sales manager that is assigned the form (as explained above), will get an email notifying them to complete the lead information before converting them to an account.</li>
                                <li>If the form was assigned to a Sales Rep, the main contact for that Sales Rep will recieve an email notifying them that their accounts application was submitted.</li>
                                <li>Any emails that are in the 'Additional Emails' field on the <a href="/#/sales/NDA/links" target="_blank">NDA links</a> page will also recieve an email.</li>
                            </ul>
                            <li>A lead can also be created manually by clicking the <v-icon>mdi-plus</v-icon> icon in the upper right of the <a href="/#/">lead home page</a>.</li>
                            <ul>
                                <li class="text--wrap">A sales manager can fill out part or all of the lead page for the potential buyer. If they only fill out part of the form, they can select 'Send NDA' which will send that contact a link with the form partially filled out (whichever fields the manager filled out) and the lead can finish off all the information</li>
                            </ul>
                        </ul>
                        <h3>Acceptance/Denial</h3>
                        <ul>
                            <li>Once clicking to the link from the email to review the account, the sales manager will need to select some classifications before being able to convert the lead to an account.</li>
                            <li>The required Classifications include:</li>
                            <ul>
                                <li>Channel</li>
                                <li>Account Type</li>
                                <ul>
                                    <li><strong>IMPORTANT: </strong>The 'Distributor' option will push the account to the 'Matador Global' Envoy instance</li>
                                </ul>
                                <li>Industry</li>
                                <li>Forecast Category</li>
                                <li>Primary Price List</li>
                                    <ul>
                                        <li>The price they pay in Envoy</li>
                                    </ul>
                                <li>Secondary Price List</li>
                                    <ul>
                                        <li>The compare to price in Envoy</li>
                                    </ul>
                            </ul>
                            <li>After filling out the necessary information the sales manager will accept or deny the lead</li>
                            <li><h4>Accepting</h4></li>
                            <ul>
                                <li>To accept a lead, just select 'convert' on their lead page.</li>
                                <li>First, an account will be created in Cin7</li>
                                <li>Then they are moved to the Account table within our internal database</li>
                            </ul>
                            <li><h4>Denying</h4></li>
                            <ul>
                                <li>To deny a lead, click the red 'Deny' button and provide a reason for denying the lead</li>
                                <li>This will send an email to both the lead main contact and their sales rep (if applicable) about their denial. This email WILL include the reason filled in by the sales manager.</li>
                            </ul>
                        </ul>
                        <h3>Account Management</h3>
                        <ul>
                            <li>See document below, if not loading click <a href="https://matadorup.sharepoint.com/:w:/s/Documents/EWzz8VjBhMZIvxHjeP5dRM0Bn6InzLO3MVIggs_KxI-yNw?e=qPvCeF">here</a>, if that link does work copy paste this url: https://matadorup.sharepoint.com/:w:/s/Documents/EWzz8VjBhMZIvxHjeP5dRM0Bn6InzLO3MVIggs_KxI-yNw?e=qPvCeF and if THAT doesn't work, navigate to OneDrive > Processes > Dev > Onboarding Process.docx</li>
                        </ul>
                    </v-card-text>
                </v-row>
                <v-row>
                    <iframe 
                        src="https://matadorup-my.sharepoint.com/personal/austin_matadorequipment_com/_layouts/15/Doc.aspx?sourcedoc={070c178a-3a80-46ee-aa75-0a16f89d2e43}&amp;action=embedview" 
                        width="100%" 
                        height="500px" 
                        frameborder="0"/>
                </v-row>
        </v-card>    
    </v-dialog>
</template>

<script>
import customTitle from '/src/components/PAM/pages/title.vue';

export default {
    name: 'AccountProcess',
    mounted() {
    },
    components: {
        customTitle,
    },
    props: {
        dialog: Boolean
    },
    data() {
        return {
            showManageSalesRepPage: false,
            editor: null,
        }
    },
    methods: {},
    computed: {
    }
}
</script>

<style>

</style>