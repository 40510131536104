<template>
  <v-app>
    <v-main class="d-flex justify-center align-center">
        <v-row justify="center" align="center">
            <v-card class="pa-4" width="50vw">
                <v-card-title class="d-flex justify-center align-center">
                    Custom Fields
                </v-card-title>
                <v-row align="center" class="pr-4">
                    <v-card-subtitle>
                        Channels ({{ channels.length }})
                    </v-card-subtitle>
                    <v-autocomplete
                        :items="channels"
                        item-value="Id"
                        append-icon="mdi-plus"
                        @click:append="addNew('Channel')">
                        <template v-slot:selection>
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.Channel }}
                            <v-spacer/>
                            <v-icon 
                                class="pr-4"
                                color="primary" 
                                @click="edit('Channel',item.Channel,item.Id)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="red" @click="del('Channel',item.Channel, item.Id)">mdi-close</v-icon>
                        </template>
                    </v-autocomplete>
                </v-row>
                <v-row align="center" class="pr-4">
                    <v-card-subtitle>
                        Account Types ({{ accountTypes.length }})
                    </v-card-subtitle>
                    <v-autocomplete
                        :items="accountTypes"
                        item-value="Id"
                        append-icon="mdi-plus"
                        @click:append="addNew('Account Type')">
                        <template v-slot:selection>
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.Account_Type }}
                            <v-spacer/>
                            <v-icon 
                                class="pr-4"
                                color="primary" 
                                @click="edit('Account Type',item.Account_Type,item.Id)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="red" @click="del('Account Type',item.Account_Type, item.Id)">mdi-close</v-icon>
                        </template>
                    </v-autocomplete>
                </v-row>
                <v-row align="center" class="pr-4">
                    <v-card-subtitle>
                        Industries ({{ industries.length }})
                    </v-card-subtitle>
                    <v-autocomplete
                        :items="industries"
                        item-value="Id"
                        append-icon="mdi-plus"
                        @click:append="addNew('Industry')">
                        <template v-slot:selection>
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.Industry }}
                            <v-spacer/>
                            <v-icon 
                                class="pr-4"
                                color="primary" 
                                @click="edit('Industry',item.Industry,item.Id)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="red" @click="del('Industry',item.Industry, item.Id)">mdi-close</v-icon>
                        </template>
                    </v-autocomplete>
                </v-row>
                <v-row align="center" class="pr-4">
                    <v-card-subtitle>
                        Payment Terms ({{ paymentTerms.length }})
                    </v-card-subtitle>
                    <v-autocomplete
                        :items="paymentTerms"
                        item-value="Payment_Terms"
                        return-object
                        @click:append="createNewPaymentTerms()"
                        append-icon="mdi-plus">
                        <template v-slot:selection></template>
                        <template v-slot:item="{ item }">
                            {{ item.Payment_Terms }}
                            <v-spacer/>
                            <v-icon 
                                class="pr-4"
                                color="primary" 
                                @click="editPaymentTerm(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                    </v-autocomplete>
                </v-row>
                <v-row align="center" class="pr-4">
                    <v-card-subtitle>
                        Forecast Categories ({{ forecastCategories.length }})
                    </v-card-subtitle>
                    <v-autocomplete
                        :items="forecastCategories"
                        item-value="Id"
                        append-icon="mdi-plus"
                        @click:append="addNew('Forecast Category')">
                        <template v-slot:selection>
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.Forecast_Name }}
                            <v-spacer/>
                            <v-icon  
                                class="pr-4"
                                color="primary" 
                                @click="edit('Forecast Category',item.Forecast_Name,item.Forecast_Id)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="red" @click="del('Forecast Category',item.Forecast_Name, item.Forecast_Id)">mdi-close</v-icon>
                        </template>
                    </v-autocomplete>
                </v-row>
                <v-row>
                    <v-card-subtitle>
                        Sales Reps - <router-link to="/sales/salesreps/manage">edit here</router-link>
                    </v-card-subtitle>
                </v-row>
                <v-row>
                    <v-card-subtitle>
                        Sales Managers - depenedent on seats within Cin7 and therefore must be updated by adding users to Cin7
                    </v-card-subtitle>
                </v-row>
            </v-card>
        </v-row>
        <v-dialog
            v-model="newDialog.dialog"
            width="30vw"
            height="20vh"
            :hide-overlay="true"
            transition="dialog-bottom-transition">
            <v-card width="30vw" height="100%" class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Add New {{ newDialog.type }}
                </v-card-title>
                <v-text-field
                    v-model="newDialog.value"
                    :label="'New ' + newDialog.type"/>
                <v-card-actions class="d-flex justify-space-around align-center">
                    <v-btn
                        @click="newDialog.dialog=false">
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="submit('insert')"
                        :disabled="!newDialog.value.length"
                        color="primary"
                        :loading="loading">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="editDialog.dialog"
            style="position: absolute; bottom: 0;"
            width="30vw"
            height="20vh"
            :hide-overlay="true"
            transition="dialog-bottom-transition">
            <v-card width="30vw" height="100%" class="pa-4">
                <v-card-title class="d-flex justify-center align-center pa-2">
                    Edit {{ editDialog.type }} Category Name
                </v-card-title>
                <v-text-field
                    v-model="editDialog.newValue"
                    label="Edit"/>
                <v-card-actions class="d-flex justify-space-around align-center">
                    <v-btn
                        @click="editDialog.dialog=false;">
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="submit('edit')"
                        :disabled="editDialog.newValue === editDialog.oldValue"
                        color="primary"
                        :loading="loading">
                        Edit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="deleteDialog.dialog"
            width="30vw"
            height="20vh"
            :hide-overlay="true"
            transition="dialog-bottom-transition">
            <v-card width="30vw" height="100%" class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Delete {{ deleteDialog.type }} Category
                </v-card-title>
                <v-card-text class="d-flex justify-center align-center center-text">
                    Are you sure you want to delete {{ deleteDialog.value }}
                </v-card-text>
                <v-card-actions class="d-flex justify-space-around align-center">
                    <v-btn
                        @click="deleteDialog.dialog=false;">
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="submit('delete')"
                        color="primary"
                        :loading="loading">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="newPaymentTerm.dialog"
            height="50vh"
            width="50vw">
            <v-card class="pa-4" width="50vw" height="100%">
                <v-card-title class="d-flex justify-center align-center">
                    Add New Payment Terms
                </v-card-title>
                <v-text-field
                    v-model="newPaymentTerm.Payment_Terms"
                    :readonly="newPaymentTerm.type === 'edit'"
                    persistent-hint
                    hint="Name cannot be updated after created"
                    label="Name"/>
                <v-text-field
                    v-model="newPaymentTerm.Months_Ahead"
                    type="number"
                    label="Months Ahead"/>
                <v-text-field
                    v-model="newPaymentTerm.Days_Ahead"
                    type="number"
                    label="Days Ahead"/>
                <v-text-field
                    v-model="newPaymentTerm.Deposit_Required"
                    type="number"
                    hint="If 0 then no deposit required. If 1 then full payment required."
                    label="Deposit Required"
                    :rules="[v => v >= 0 || 'Must be a number' , v => v <= 1 || 'Must be between 0 and 1']"
                    :max="1"/>
                <v-card-actions class="d-flex justify-space-around align-center">
                    <v-btn
                        @click="newPaymentTerm.dialog = false"
                        color="secondary">
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="submitNewPaymentTerm()"
                        color="primary"
                        :disabled="!newPaymentTerm.Payment_Terms.length || newPaymentTerm.Deposit_Required > 1">
                        <span v-if="newPaymentTerm.type === 'edit'">Update</span>
                        <span v-else>Submit</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="deleteFailedDialog"
            height="50vh"
            width="50vw">
            <v-card class="pa-4" width="50vw" height="100%">
                <v-card-title class="d-flex justify-center align-center">
                    Delete Failed
                </v-card-title>
                <v-card-text class="d-flex justify-center align-center text-center">
                    The following accounts are assigned to the {{ deleteDialog.type }} category of {{ deleteDialog.value }}. Please reassign these accounts to another category and then retry the delete.
                </v-card-text>
                <v-list height="30vh" class="overflow-y-auto">
                    <v-list-item v-for="(accounts, index) in deleteDependentAccounts" :key="index">
                        {{ accounts.Company }}
                    </v-list-item>
                </v-list>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn
                        @click="deleteFailedDialog = false"
                        color="primary">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - CRM'
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Leads', path: '/sales/crm/leads/home', icon: 'mdi-account-box-outline', notification: false},
            {title: 'Accounts', icon: 'mdi-domain', path: '/sales/crm/accounts/home', notification: false},
            {title: 'Admin', path: '/sales/crm/admin/home', icon: 'mdi-cog', notification: false},
            {title: 'Batches', path: '/sales/crm/batches', icon: 'mdi-account-convert', notification: false},
            {title: 'Export', path: '/sales/crm/accounts/export', icon: 'mdi-export-variant', notification: false},
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ])
        this.$store.dispatch('updateNavbarTitle','CRM Admin')
        this.getAdminDetails()
    },
    data() {
        return {
            newDialog: {
                dialog: false,
                type: '',
                value: ''
            },
            editDialog: {
                dialog: false,
                type: '',
                newValue: '',
                oldValue: '',
                id: 0
            },
            deleteDialog: {
                dialog: false,
                type: '',
                value: '',
                id: 0
            },
            info: {
                'Account Type': {
                    table: 'Account_Type_Info',
                    column: 'Account_Type',
                    column_id: 'Id'
                },
                'Industry': {
                    table: 'Industry_Info',
                    column: 'Industry',
                    column_id: 'Id'
                },
                'Channel': {
                    table: 'Channel_Info',
                    column: 'Channel',
                    column_id: 'Id'
                },
                'Forecast Category': {
                    table: 'Forecast_Info',
                    column: 'Forecast_Name',
                    column_id: 'Forecast_Id'
                },
                'Payment Terms': {
                    table: 'Payment_Terms_Info',
                    column: 'Payment_Terms',
                    column_id: 'Payment_Terms'
                }
            },
            newPaymentTerm: {
                type: '',
                Payment_Terms: '',
                dialog: false,
                Months_Ahead: 0,
                Days_Ahead: 30,
                Deposit_Required: 0
            },
            channels: [],
            industries: [],
            forecastCategories: [],
            accountTypes: [],
            paymentTerms: [],
            deleteDependentAccounts: [],
            deleteFailedDialog: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        addNew(type) {
            this.newDialog.dialog = true
            this.newDialog.type = type
            this.newDialog.value = ''
        },
        edit(type, value, id) {
            this.editDialog.dialog = true
            this.editDialog.type = type
            this.editDialog.newValue = value
            this.editDialog.oldValue = value
            this.editDialog.id = id
        },
        del(type, value, id) {
            this.deleteDialog.dialog = true
            this.deleteDialog.type = type
            this.deleteDialog.value = value
            this.deleteDialog.id = id
        },
        async submit(type) {
            try {
                this.loading = true
                let info = ''
                let body = {}
                if (type === 'insert') {
                    info = this.info[this.newDialog.type]
                    let insert = {}
                    insert[info.column] = this.newDialog.value
                    body = {
                        type: type,
                        table: info.table,
                        insert: insert
                    }
                } else if (type === 'edit') {
                    let update = {}
                    info = this.info[this.editDialog.type]
                    update[info.column] = this.editDialog.newValue
                    body = {
                        type: type,
                        table: info.table,
                        update: update,
                        columnId: info.column_id,
                        id: this.editDialog.id
                    }
                } else if (type === 'delete') {
                    info = this.info[this.deleteDialog.type]
                    body = {
                        type: type,
                        table: info.table,
                        column: info.column,
                        columnId: info.column_id,
                        id: this.deleteDialog.id
                    }
                }
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_AdminUpdateFields?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/admin',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body)
                    }
                )
            
                const {
                    value,
                    accounts,
                    message,
                } = await result.json()
                if (accounts) {
                    this.loading = false
                    this.deleteDependentAccounts = accounts
                    this.deleteFailedDialog = true
                } else {
                    if (info.table === "Account_Type_Info") {
                        this.accountTypes = value
                    } else if (info.table === "Industry_Info") {
                        this.industries = value
                    } else if (info.table === "Channel_Info") {
                        this.channels = value
                    } else if (info.table === "Forecast_Info") {
                        this.forecastCategories = value
                    }
                    this.loading = false
                    this.snack(message,'success',3000)
                }
                this.deleteDialog.dialog = false
                this.newDialog.dialog = false
                this.editDialog.dialog = false
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async getAdminDetails() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_GetAdminDetails?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/admin',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
            
                const {
                    message,
                    channels,
                    industries,
                    forecastCategories,
                    accountTypes,
                    paymentTerms
                } = await result.json()
                this.channels = channels
                this.industries = industries
                this.forecastCategories = forecastCategories
                this.accountTypes = accountTypes
                this.paymentTerms = paymentTerms

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        createNewPaymentTerms() {
            this.newPaymentTerm = {
                    Payment_Terms: '',
                    dialog: true,
                    Months_Ahead: 0,
                    Days_Ahead: 30,
                    Deposit_Required: 0
                }
        },
        editPaymentTerm(item) {
            this.newPaymentTerm.type = 'edit'
            this.newPaymentTerm.Payment_Terms = item.Payment_Terms
            this.newPaymentTerm.Months_Ahead = item.Months_Ahead
            this.newPaymentTerm.Days_Ahead = item.Days_Ahead
            this.newPaymentTerm.Deposit_Required = item.Deposit_Required
            this.newPaymentTerm.dialog = true
        },
        async submitNewPaymentTerm() {
            try {
                let insert = this.newPaymentTerm
                let type = this.newPaymentTerm.type
                delete insert.dialog
                delete insert.type
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_CreateNewPaymentTerms?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/admin/paymentterms',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            object: insert,
                            type: type
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.newPaymentTerm = {
                    Payment_Terms: '',
                    dialog: false,
                    Months_Ahead: 0,
                    Days_Ahead: 30,
                    Deposit_Required: 0
                }
                this.paymentTerms.push(insert)
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    }
}
</script>

<style>

</style>