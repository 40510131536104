<template>
    <v-dialog v-model="dialog" height="80vh" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                class="pr-6 pt-2"
                dark
                icon
                v-bind="attrs"
                v-on="on">
                    <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card class="pa-4 flex-column" height="80vh">
            <v-stepper v-model="currentStep" elevation="0">
                <v-stepper-header class="elevation-0">
                    <template v-for="(item, index) in progress"> 
                        <v-stepper-step
                            :key="index"
                            :complete="currentStep > index + 1"
                            :step="index + 1">
                            {{ item }}
                        </v-stepper-step>
                        <v-divider
                            v-if="index !== progress.length - 1"
                            :key="index + '1'"/>
                    </template>
                </v-stepper-header>
                <v-stepper-content step="1" class="pt-0">
                    <v-card-title class="d-flex justify-center align-center ma-0">Step 1: Company Information</v-card-title>
                    <v-form v-model="step1.form" class="flex-column justify-center align-center">
                        <v-row class="my-0">
                            <v-col cols="12" class="py-1">
                                <h3>Required Fields</h3>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-text-field 
                                    label="Company" 
                                    maxlength="250"
                                    required
                                    :rules="[v => !!v || 'Company is required']"
                                    v-model="step1.company"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-text-field 
                                    label="Billing Email" 
                                    required
                                    :rules="[
                                        v => v.length < 50 || 'E-mail must be less than 50 characters',
                                        v => !!v || 'Billing Email is required',
                                        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                                    ]"
                                    v-model="step1.billingEmail"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-switch
                                    label="Import to Envoy"
                                    hide-details
                                    v-model="step1.toEnvoy"/>
                            </v-col>
                        </v-row>
                        <v-row class="my-0 pt-2">
                            <v-col cols="12" class="py-1">
                                <h4>Optional Fields</h4>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-text-field 
                                    label="Website"
                                    maxlength="50"
                                    hide-details
                                    v-model="step1.website"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-select
                                    label="Envoy Discount"
                                    :disabled="!step1.toEnvoy"
                                    hide-details
                                    v-model="step1.envoyDiscount"
                                    :items="info.envoyDiscounts"/>
                            </v-col>
                            <v-col cols="12" class="py-1">
                                <v-textarea
                                    label="Sandbox Notes"
                                    maxlength="500"
                                    hide-details
                                    v-model="step1.sandboxNotes"/>
                            </v-col>
                        </v-row>
                    </v-form>   
                </v-stepper-content>
                <v-stepper-content step="2" class="pt-0">
                    <v-card-title>Step 2: Addresses</v-card-title>
                    <v-form v-model="step2.form" class="flex-column justify-center align-center">
                        <v-row justify="center">
                            <!-- <GmapAutocomplete @place_changed="setPlace"/> -->
                            <vuetify-google-autocomplete
                                class="px-4"
                                autocomplete="new-password"
                                selection-required
                                v-model="step2.shippingAddressShow"
                                id="shippingMap"
                                prepend-icon="mdi-magnify"
                                placeholder="Shipping Address"
                                v-on:placechanged="getShippingAddressData"/>
                            <v-checkbox
                                class="px-4"
                                v-model='step2.billingAddressSameAsShipping'
                                label="Billing Address Same As Shipping"/>
                        </v-row>
                        <v-row v-if="!step2.billingAddressSameAsShipping">
                            <vuetify-google-autocomplete
                                class="px-4"
                                hint="Please choose from the autocomplete options"
                                selection-required
                                v-model="step2.billingAddressShow"
                                id="billingMap"
                                prepend-icon="mdi-magnify"
                                placeholder="Billing Address"
                                v-on:placechanged="getBillingAddressData"/>
                        </v-row>
                        <v-row class="pb-4">
                            <v-text-field
                                class="px-4"
                                maxlength="35"
                                hint="If applicable, please include carrier, account number, and billing zip code for the shipping account. (List N/A if Not Applicable)"
                                v-model="step2.shippingAccount"
                                label="Shipping Acount"/>
                        </v-row>
                    </v-form>
                </v-stepper-content>    
                <v-stepper-content step="3" class="pt-0">
                    <v-card-title class="d-flex justify-center align-center ma-0">Step 3: Classifications</v-card-title>
                    <v-form v-model="step3.form" class="flex-column justify-center align-center">
                        <v-row class="my-0 pt-2">
                            <v-col cols="12" sm="6" class="py-1">
                                <v-autocomplete
                                    label="Sales Rep"
                                    required
                                    :items="managers"
                                    :rules="[v => !!v || 'Sales Rep is required']"
                                    return-object
                                    v-model="step3.manager">
                                    <template v-slot:item="{ item }">
                                        {{ item['First Name'] }} {{ item['Last Name'] }}
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        {{ item['First Name'] }} {{ item['Last Name'] }}
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-select
                                    label="External Sales Rep"
                                    :items="reps"
                                    return-object
                                    item-text="Agency"
                                    required
                                    :rules="[v => v !== null || 'External Sales Rep is required']"
                                    v-model="step3.salesRep"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-autocomplete
                                    label="Channel"
                                    required
                                    :items="channels"
                                    return-object
                                    item-text="Channel"
                                    v-model="step3.channel"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-autocomplete
                                    label="Account Type"
                                    required
                                    :items="accountTypes"
                                    :persistent-hint="step1.toEnvoy"
                                    :hint="(step1.toEnvoy) ? `Selecting 'Distributor' will create this contact in the Matador Global Envoy instance` : ''"
                                    return-object
                                    item-text="Account_Type"
                                    v-model="step3.accountType"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-autocomplete
                                    label="Industry"
                                    required
                                    :rules="[v => !!v || 'Industry is required']"
                                    :items="industries"
                                    return-object
                                    item-text="Industry"
                                    v-model="step3.industry"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-autocomplete
                                    label="Forecast"
                                    required
                                    :rules="[v => !!v || 'Forecast is required']"
                                    :items="forecastCategories"
                                    return-object
                                    item-text="Forecast_Name"
                                    v-model="step3.forecast"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-select
                                    label="Price Category"
                                    :items="priceCategories"
                                    required
                                    return-object
                                    item-text="Name"
                                    v-model="step3.price"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-select
                                    label="Secondary Price Category"
                                    :items="priceCategories"
                                    required
                                    return-object
                                    item-text="Name"
                                    v-model="step3.secondaryPrice"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1">
                                <v-select
                                    label="Cost Category"
                                    required
                                    :items="costCategories"
                                    return-object
                                    item-text="Name"
                                    v-model="step3.cost"/>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-1 d-flex justify-center align-center">
                                <v-text-field 
                                    label="Door Count"
                                    required
                                    class="pr-2"
                                    type="number"
                                    v-model="step3.doorCount"/>
                                <v-checkbox
                                    label="Online Store"
                                    class="px-2"
                                    v-model="step3.onlineStore"/>
                                <v-checkbox
                                    label="Majority Online Business"
                                    class="pl-2"
                                    v-model="step3.majorityOnlineBusiness"/>
                            </v-col>
                        </v-row>
                    </v-form>  
                </v-stepper-content>
                <v-stepper-content step="4" class="pt-0">
                    <v-card-title>Step 4: Contacts</v-card-title>
                    <v-form v-model="step4.form" class="flex-column justify-center align-center">
                        <v-row class="my-0" v-for="(contact, index) in step4.contacts" :key="index">
                            <v-col cols="2" class="py-1">
                                <v-text-field 
                                    label="First Name"
                                    maxlength="50"
                                    required
                                    :rules="[v => !!v || 'First Name is required']"
                                    v-model="contact.First_Name"/>
                            </v-col>
                            <v-col cols="2" class="py-1">
                                <v-text-field 
                                    label="Last Name"
                                    maxlength="50"
                                    required
                                    :rules="[v => !!v || 'Last Name is required']"
                                    v-model="contact.Last_Name"/>
                            </v-col>
                            <v-col cols="2" class="py-1">
                                <v-text-field 
                                    label="Email"
                                    maxlength="50"
                                    required
                                    :rules="[v => !!v || 'Email is required']"
                                    v-model="contact.Email"/>
                            </v-col>
                            <v-col cols="3" class="py-1">
                                <v-text-field
                                    label="Notes"
                                    maxlength="500"
                                    hide-details
                                    v-model="contact.Notes"/>
                            </v-col>
                            <v-col cols="2" class="py-1" v-if="step1.toEnvoy">
                                <v-switch
                                    label="Import to Envoy"
                                    hide-details
                                    v-model="contact.importToEnvoy"/>
                            </v-col>
                            <v-col cols="1" class="py-1" v-if="index !== 0">
                                <v-btn icon @click="step4.contacts.splice(index, 1)">
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-card-actions class="d-flex justify-space-around align-center">
                        <v-btn
                            color="accent"
                            @click="step4.contacts.push({First_Name: '', Last_Name: '', Email: '', importToEnvoy: step1.toEnvoy, Notes: ''})"    
                        >
                            Add Another Contact
                        </v-btn>
                    </v-card-actions>
                </v-stepper-content>
                <v-stepper-content step="5" class="pt-0">
                    <v-row>
                        <v-col>
                            <h2 class="d-flex justify-start align-center">
                                Company Information
                                <v-btn 
                                    text 
                                    small
                                    @click="currentStep = 1">
                                    edit
                                </v-btn>
                            </h2>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Company:</span> {{ step1.company }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Website:</span> {{ step1.website || 'N/A' }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Billing Email:</span> {{ step1.billingEmail }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Envoy Discount:</span> {{ step1.envoyDiscount || 'N/A' }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Sandbox Notes:</span> {{ step1.sandboxNotes || 'N/A' }}
                            </div>
                        </v-col>
                        <v-col>
                            <h2 class="d-flex justify-start align-center">
                                Address
                                <v-btn 
                                    text 
                                    small
                                    @click="currentStep = 2">
                                    edit
                                </v-btn>
                            </h2>
                            <u class="pt-1"><strong>Shipping Address:</strong></u> {{ step2.shippingAddressShow }}<br>
                            <u><strong>Billing Address:</strong></u> {{ billingAddressShow }}<br>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Shipping Account:</span> {{ step2.shippingAccount || 'N/A'}}
                            </div>
                        </v-col>
                        <v-col>
                                <h2 class="d-flex justify-start align-center">
                                    Classifications
                                    <v-btn 
                                        text 
                                        class="pb-0 mb-0"
                                        small
                                        @click="currentStep = 3">
                                        edit
                                    </v-btn>
                                </h2>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Industry:</span> {{ formatIndustry }}
                            </div>  
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Forecast:</span> {{ formatForecast }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Channel:</span> {{ formatChannel }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Account Type:</span> {{ formatAccountType }}
                            </div>  
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Manager:</span> {{ formatSalesRep }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">External Sales Rep:</span> {{ formatExternalSalesRep }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Price Category:</span> {{ formatPrice }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Secondary Price Category:</span> {{ formatSecondaryPrice }}
                            </div> 
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Cost Category:</span> {{ formatCost }}
                            </div> 
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Door Count:</span> {{ step3.doorCount }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Online Store:</span> {{ (step3.onlineStore) ? 'Yes' : 'No' }}
                            </div>
                            <div class="py-1">
                                <span class="text-decoration-underline font-weight-bold">Majority Online Business:</span> {{ (step3.majorityOnlineBusiness) ? 'Yes' : 'No' }}
                            </div>
                        </v-col>
                        <v-col>
                            <h2 class="d-flex justify-start align-center">
                                Contacts
                                <v-btn 
                                    text 
                                    small
                                    @click="currentStep = 4">
                                    edit
                                </v-btn>
                            </h2>
                            <v-row v-for="(contact, index) in step4.contacts" :key="index" class="py-1">
                                    <v-col>
                                        {{ contact.First_Name }} {{ contact.Last_Name }}
                                    </v-col>
                                    <v-col>
                                        {{ contact.Email }}
                                    </v-col>
                                    <v-col>
                                        {{ contact.Notes }}
                                    </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-stepper-content>
            </v-stepper>
            <v-card-actions class="d-flex justify-space-between align-end pa-1">
                <v-btn
                    color="red"
                    v-if="currentStep === 1"
                    @click="dialog = false">
                    Cancel
                </v-btn>
                <v-btn
                    color="secondary"
                    v-if="currentStep > 1"
                    @click="currentStep-=1">
                    Back
                </v-btn>
                <v-btn 
                    color="primary"
                    v-if="currentStep < 5"
                    @click="currentStep+=1"
                    :disabled="formDisabled || loading">
                    Next
                </v-btn>
                <v-btn
                    color="primary"
                    @click="submit()"
                    :loading="loading"
                    v-if="currentStep === 5">
                    Submit
                </v-btn>
            </v-card-actions> 
        </v-card>
        <v-dialog 
            v-model="step2.enterAddressDialog"
            width="50vw"
            persistent>
            <v-card class="overflow-y-auto pa-4" height="110%" width="50vw" :loading="loading">
                <v-card-title class="d-flex justify-center align-center pb-4">
                    Confirm {{ step2.addressType }}
                </v-card-title>
                <v-row v-if="step2.addressType === 'Shipping Address'" justify="center" align="center" class="flex-column" width="100vw">
                    <v-text-field
                        label="Address 1"
                        v-model="step2.shippingAddress.address1"
                        class="px-4"/>
                    <v-text-field
                        label="Address 2"
                        v-model="step2.shippingAddress.address2"
                        class="px-4"/>
                    <v-text-field
                        label="City"
                        v-model="step2.shippingAddress.city"
                        class="px-4"/>
                    <v-text-field
                        label="State (2-Letter Abbrv.)"
                        v-model="step2.shippingAddress.state"
                        class="px-4"/>
                    <v-text-field
                        label="Zip Code"
                        v-model="step2.shippingAddress.zip"
                        class="px-4"/>
                    <v-text-field
                        label="Country (2-Letter Abbrv.)"
                        maxlength="2"
                        v-model="step2.shippingAddress.country"
                        class="px-4"/>
                </v-row>
                <v-row v-else-if="step2.addressType === 'Billing Address'" justify="center" align="center" class="flex-column" width="100vw">
                    <v-text-field
                        label="Address 1"
                        v-model="step2.billingAddress.address1"
                        class="px-4"/>
                    <v-text-field
                        label="Address 2"
                        v-model="step2.billingAddress.address2"
                        class="px-4"/>
                    <v-text-field
                        label="City"
                        v-model="step2.billingAddress.city"
                        class="px-4"/>
                    <v-text-field
                        label="State (2-Letter Abbrv.)"
                        v-model="step2.billingAddress.state"
                        class="px-4"/>
                    <v-text-field
                        label="Zip Code"
                        v-model="step2.billingAddress.zip"
                        class="px-4"/>
                    <v-text-field
                        label="Country (2-Letter Abbrv.)"
                        maxlength="2"
                        v-model="step2.billingAddress.country"
                        class="px-4"/>
                </v-row>
                <v-row justify="space-around" align="center" class="mb-2">
                    <v-btn 
                        color="secondary"
                        @click="cancelAddress()">
                        Cancel
                    </v-btn>
                    <v-btn 
                        color="primary"
                        @click="fillOutAddress()">
                        Confirm
                    </v-btn>
                </v-row>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    mounted () {
        this.step3.manager = this.$props.managers.find(item => item['User ID'] === this.$props.userCin7Id);
    },
    props: {
        channels: {type: Array},
        accountTypes: {type: Array},
        industries: {type: Array},
        forecastCategories: {type: Array},
        managers: {type: Array},
        reps: {type: Array},
        costCategories: {type: Array},
        priceCategories: {type: Array},
        userCin7Id: {type: Number},
    },
    data() {
        return {
            dialog: false,
            progress: ['Company Information', 'Addresses', 'Classifications', 'Contacts', 'Review'],
            currentStep: 1,
            step1: {
                form: false,
                toEnvoy: true,
                company: '',
                website: '',
                billingEmail: '',
                envoyDiscount: '',
                sandboxNotes: '',
            },
            step2: {
                form: false,
                enterAddressDialog: false,
                addressType: '',
                billingAddressSameAsShipping: true,
                shippingAddress: {},
                shippingAccount: '',
                shippingAddressShow: '',
                billingAddressShow: '',
                billingAddress: {},
            },
            step3: {
                form: false,
                industry: null,
                manager: null,
                price: {
                    "Price ID":2,
                    "Name":"Wholesale",
                    "Cin7_Prefix":"WS",
                    "To_Envoy":1,
                    "In_Envoy":1,
                    "To_Cin7":1,
                    "In_Cin7":0
                },
                secondaryPrice: {
                    "Price ID":1,
                    "Name":"MatadorUp.com",
                    "Cin7_Prefix":"ECOM",
                    "To_Envoy":1,
                    "In_Envoy":1,
                    "To_Cin7":0,
                    "In_Cin7":0
                },
                cost: {"Cost ID":18,"Name":"Ocean DDP"},
                salesRep: null,
                accountType: {"Id":2,"Account_Type":"Retail"},
                doorCount: 1,
                onlineStore: false,
                majorityOnlineBusiness: false,
                channel: {"Id":2,"Channel":"Wholesale"},
                forecast: null,
            },
            step4: {
                form: false,
                contacts: [
                    {
                        First_Name: '',
                        Last_Name: '',
                        Email: '',
                        importToEnvoy: true,
                        Notes: ''
                    }
                ]
            },
            info: {
                envoyDiscounts: ['2DISCOUNT','5DISCOUNT','10DISCOUNT','15DISCOUNT'],
                dialog: false,
            },
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async submit() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_CreateAccountNew?newFunction=true&tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            cin7: this.cin7Object,
                            database: this.databaseAccountObject,
                            contacts: this.databaseContactObject,
                            salesContacts: this.databaseSalesContactObject,
                        })
                    }
                )
                const {
                    message,
                    accountId
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.loading = false
                this.$router.push(`/sales/crm/accounts/${accountId}?firstTime=1`)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async getAddressDetails(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_ConvertLead?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/nda/address?id=' + id,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    address
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.loading = false
                return address
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        getShippingAddressData: async function (addressData) {
            this.step2.shippingAddress = await this.getAddressDetails(addressData.place_id)
            this.openAddressDialog('Shipping Address')
        },
        getBillingAddressData: async function (addressData) {
            this.step2.billingAddress = await this.getAddressDetails(addressData.place_id);
            this.openAddressDialog('Billing Address')
        },
        openAddressDialog(type) {
            this.step2.addressType = type
            this.step2.enterAddressDialog = true
        },
        formatShow(show, address) {
            let string = address.address1 + ', '
            if (address.address2 && address.address2.length > 0) {
                string = string + address.address2 + ', '
            }
            string = string + address.city + ', ' +
                    address.state + ' ' + 
                    address.zip + ' ' + 
                    address.country
            let check = string.replaceAll(",","")
            check = check.replaceAll(" ","")
            this.step2[show] = (check.length === 0) ? '' : string

        },
        cancelAddress() {
            if (this.step2.addressType === 'Billing Address') {
                this.step2.billingAddressShow = ''
                this.step2.billingAddress = {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                }
            } else {
                this.step2.shippingAddressShow = ''
                this.step2.shippingAddress = {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                }
            }
            this.step2.enterAddressDialog = false
        },
        fillOutAddress() {
            if (this.step2.addressType === 'Billing Address') {
                this.formatShow('billingAddressShow',this.step2.billingAddress)
            } else {
                this.formatShow('shippingAddressShow',this.step2.shippingAddress)
            }
            this.step2.enterAddressDialog = false
        },
        findManager(id) {
            let m = this.$props.managers.find(item => item["User ID"] === id)
            return m['First Name'] + ' ' + m['Last Name']
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        formDisabled() {
            if (this.currentStep === 1) {
                return !this.step1.form
            } else if (this.currentStep === 2) {
                return !this.step2.form
            } else if (this.currentStep === 3) {
                return !this.step3.form
            } else if (this.currentStep === 4) {
                return !this.step4.form
            } else {
                return false
            }
        },
        cin7Object() {
            let notes = 'Sales Notes: ' + this.step1.sandboxNotes + '\n' + 'Shipping Account: ' + this.step2.shippingAccount
            return {
                isActive: true,
                company: this.step1.company,
                firstName: this.step1.company,
                email: this.step1.billingEmail,
                website: this.step1.website,
                notes: notes,
                address1: this.step2.shippingAddress.address1,
                address2: this.step2.shippingAddress.address2,
                city: this.step2.shippingAddress.city,
                state: this.step2.shippingAddress.state,
                postCode: this.step2.shippingAddress.zip,
                country: this.step2.shippingAddress.country,
                postalAddress1: this.billingAddress.address1,
                postalAddress2: this.billingAddress.address2,
                postalCity: this.billingAddress.city,
                postalState: this.billingAddress.state,
                postalPostCode: this.billingAddress.zip,
                postalCountry: this.billingAddress.country,
                paymentTerms: 'DOR - Full payment prior to dispatch',
                customFields: {
                    members_1000: this.step3.salesRep.Agency || '',
                    members_1001: this.step3.accountType.Account_Type || '',
                    members_1002: this.step3.doorCount,
                    members_1004: this.step3.onlineStore ? "Yes" : "No"
                },
                type: "Customer",
                salesPersonId: this.step3.manager['User ID'] || '',
                billingCompany: this.step1.company,
                billingEmail: this.step1.billingEmail,
                group: this.step3.industry.Industry || '',
                subGroup: this.step3.forecast.Forecast_Name || '',
                stages: 'Active Wholesale'
            }
        },
        databaseAccountObject() {
            let date = new Date()
            let update = date
            date = date.toISOString()
            update = update.toISOString().split(".")[0]
            date = date.split('T')[0]
            return {
                'Created Date': date,
                'Updated Date': update,
                Active: 1,
                Company: this.step1.company,
                Website: this.step1.website,
                'Account Status': 1,
                'Accounts First Name': this.step1.company,
                'Billing Email': this.step1.billingEmail,
                'Delivery Address': this.step2.shippingAddress.address1,
                'Delivery_Address_2': this.step2.shippingAddress.address2,
                'Delivery City': this.step2.shippingAddress.city,
                'Delivery State': this.step2.shippingAddress.state,
                'Delivery Postal Code': this.step2.shippingAddress.zip,
                'Delivery Country': this.step2.shippingAddress.country,
                'Billing Address': this.billingAddress.address1,
                'Billing_Address_2': this.billingAddress.address2,
                'Billing City': this.billingAddress.city,
                'Billing State': this.billingAddress.state,
                'Billing Postal Code': this.billingAddress.zip,
                'Billing Country': this.billingAddress.country,
                Industry_Id: this.step3.industry.Id,
                Stage: 'Active Wholesale',
                'Sales Rep': this.step3.manager['User ID'],
                'Price ID': this.step3.price['Price ID'],
                'Cost ID': this.step3.cost['Cost ID'],
                'External Sales Rep': this.step3.salesRep.Agency,
                External_Sales_Rep_Id: this.step3.salesRep.Id,
                Account_Type_Id: this.step3.accountType.Id,
                'Door Count': this.step3.doorCount,
                'Online Store': this.step3.onlineStore,
                Majority_Online_Business: this.step3.majorityOnlineBusiness,
                Channel_Id: this.step3.channel.Id,
                'Forecast_Id': this.step3.forecast.Forecast_Id,
                'Payment Terms': 'DOR - Full payment prior to dispatch',
                Lead_Id: 0,
                Shipping_Account: this.step2.shippingAccount,
                Secondary_Price_Id: this.step3.secondaryPrice['Price ID'],
                Sandbox_Notes: this.step1.sandboxNotes,
                Prevent_Envoy_Import: (this.step1.toEnvoy) ? 0 : 1,
            }
        },
        databaseContactObject() {
            return {
                Active: 1,
                Company: this.step1.company,
                'First Name': this.step1.company,
                Email: this.step1.billingEmail
            }
        },
        databaseSalesContactObject() {
            return this.step4.contacts.map(item => ({
                Active: 1,
                First_Name: item.First_Name,
                Last_Name: item.Last_Name,
                Email: item.Email
            }))
        },
        billingAddress() {
            if (this.step2.billingAddressSameAsShipping) {
                return this.step2.shippingAddress
            } else {
                return this.step2.billingAddress
            }
        },
        billingAddressShow() {
            if (this.step2.billingAddressSameAsShipping) {
                return this.step2.shippingAddressShow
            } else {
                return this.step2.billingAddressShow
            }
        },
        formatSalesRep() {
            if (this.step3.manager) {
                return this.step3.manager['First Name'] + ' ' + this.step3.manager['Last Name']
            } else {
                return ''
            }
        },
        formatExternalSalesRep() {
            if (this.step3.salesRep) {
                return this.step3.salesRep.Agency
            } else {
                return ''
            }
        },
        formatChannel() {
            if (this.step3.channel) {
                return this.step3.channel.Channel
            } else {
                return ''
            }
        },
        formatAccountType() {
            if (this.step3.accountType) {
                return this.step3.accountType.Account_Type
            } else {
                return ''
            }
        },
        formatIndustry() {
            if (this.step3.industry) {
                return this.step3.industry.Industry
            } else {
                return ''
            }
        },
        formatForecast() {
            if (this.step3.forecast) {
                return this.step3.forecast.Forecast_Name
            } else {
                return ''
            }
        },
        formatPrice() {
            if (this.step3.price) {
                return this.step3.price.Name
            } else {
                return ''
            }
        },
        formatSecondaryPrice() {
            if (this.step3.secondaryPrice) {
                return this.step3.secondaryPrice.Name
            } else {
                return ''
            }
        },
        formatCost() {
            if (this.step3.cost) {
                return this.step3.cost.Name
            } else {
                return ''
            }
        }
    }
}
</script>

<style>
.v-row > span {
  flex: 1;
  text-align: center;
}

</style>