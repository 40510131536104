import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import JsonCSV from 'vue-json-csv'
import Vue2Filters from 'vue2-filters'
import vuex from 'vuex'
import 'es6-promise/auto'
import store from '@/store/index.js'
import VueRouter from 'vue-router'
import router from './router.js'
import VueCompositionAPI from '@vue/composition-api'
import Vuelidate from 'vuelidate'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VuetifyMoney from "vuetify-money";
import 'tiptap-vuetify/dist/main.css'
import GmapVue from 'gmap-vue';

Vue.config.productionTip = false
Vue.component("downloadCsv", JsonCSV)
Vue.use(Vue2Filters)
Vue.use(VuetifyMoney);
Vue.use(vuex)
Vue.use(VueRouter)
Vue.use(VueCompositionAPI)
Vue.use(Vuelidate)
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyBLtgt3UMQ2ltcyWY13zslFA6nSLUoeht4'
});
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBLtgt3UMQ2ltcyWY13zslFA6nSLUoeht4',
    libraries: 'places',
    v: '3.6'
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})
Vue.use(TiptapVuetifyPlugin, {vuetify, iconsGroup: 'mdi'})

new Vue({
  vuetify,
  store,
  router: router,
  render: h => h(App)
}).$mount('#app')
