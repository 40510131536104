<template>
    <v-row class="pt-4 flex-column">
        <v-switch
            color="primary"
            label="Show Inactive Reps"
            v-model="showInactiveReps"/>
        <v-text-field
            v-model="search"
            class="px-8"
            label="Search"/>
        <v-card v-for="(agency, index) in salesRepDataFiltered" :key="index" class="pa-4 mb-2" width="80%">
            <v-row justify="center" align="center" class="pa-4">
                <v-switch
                    label="Active"
                    v-model="agency.Active"
                    @change="updateSalesRepData(agency)"
                    class="pr-4"
                    color="primary"/>
                <v-text-field
                    v-model="agency.Agency"
                    label="Agency Name"
                    hide-details
                    solo
                    @change="updateSalesRepData(agency)"/>
            </v-row>
            <v-row justify="center" align="center" class="pa-4">
                <v-text-field
                    v-model="agency.Name"
                    label="Name"
                    @change="updateSalesRepData(agency)"
                    hide-details/>
                <v-autocomplete
                    label="Klaviyo List"
                    v-model="agency.Klaviyo_List_Id"
                    @change="updateSalesRepData(agency)"
                    :items="lists"
                    hide-details
                    item-value="list_id"
                    item-text="list_name">
                    <template v-slot:append>
                        <v-btn icon @click="newListDialog = true; newListName = agency.Name">
                            <v-icon color="primary">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:prepend>
                        <v-btn 
                            icon 
                            :disabled="!agency.Klaviyo_List_Id"
                            @click="confirmDialog = true; refreshListId = agency.Klaviyo_List_Id">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                        v-bind="attrs"
                                        v-on="on"
                                        color="primary">
                                        mdi-refresh
                                    </v-icon>
                                </template>
                                <span>Clear list and reassign all current contacts to list</span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                </v-autocomplete>
                <v-select
                    label="Internal Manager"
                    hide-details
                    v-model="agency.Sales_Manager"
                    @change="updateSalesRepData(agency)"
                    :items="managers"
                    item-value="Id"
                    :item-text="fullName"/>
            </v-row>
            <v-row class="pa-4">
                <v-text-field
                    v-model="agency.Shipping_Address"
                    label="Shipping Address"
                    @change="updateSalesRepData(agency)"
                    hide-details/>
                <v-text-field
                    v-model="agency.Billing_Address"
                    label="Billing Address"
                    @change="updateSalesRepData(agency)"
                    hide-details/>
            </v-row>
            <v-row class="pa-4">
                <a :href="'https://clearmanlabs.com/#/NDA?rep=' + agency.Id">
                    https://clearmanlabs.com/#/NDA?rep={{ agency.Id }}
                </a>
                <v-textarea
                    v-model="agency.Territories"
                    label="Territories"
                    @change="updateSalesRepData(agency)"
                    hide-details/>
                <v-menu offset-y :close-on-content-click="false" v-model="confirmRemovalMenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="red"
                        dark
                        icon
                        :disabled="agency.Active === 1"
                        v-bind="attrs"
                        v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <v-card class="pa-4 overflow-x-auto">
                        <v-card-title class="d-flex justify-center align-center">
                            Remove sales rep from all future sales orders
                        </v-card-title>
                        <v-row justify="center" align="center">
                            Move Sales Orders
                        </v-row>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th width="100px">From</th>
                                    <th width="100px">To</th>
                                </tr>
                            </thead>
                            <tbody>
                                <td>
                                    {{ agency.Agency }}
                                </td>
                                <td>
                                    <v-select
                                        :items="salesRepData"
                                        item-text="Agency"
                                        item-value="Id"
                                        v-model="toSalesRep"/>
                                </td>
                            </tbody>
                        </v-simple-table>
                        <v-card-actions class="d-flex justify-space-around align-center">
                            <v-btn
                                @click="confirmRemovalMenu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                color="primary"
                                :loading="loading"
                                @click="removeSalesRep(agency.Id)">
                                Remove
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-row>
            <v-row>
                <v-subheader class="pl-2">
                    Emails that will get order confirmations
                </v-subheader>
            </v-row>
            <v-row class="px-2">
                <v-slide-group
                    class="d-flex justify-center align-center"
                    multiple
                    show-arrows>
                    <v-slide-item>
                        <v-text-field
                            append-icon="mdi-plus"
                            rounded
                            placeholder="New Email"
                            outlined
                            v-model="agency.NewEmail"
                            :disabled:append="!agency.NewEmail"
                            @click:append="newEmail(agency)"
                            :laoding="loading"
                            hide-details/>
                    </v-slide-item>
                    <v-slide-item v-for="(email, index) in getRepEmails(agency.Id)" :key="index">
                        <v-text-field
                            rounded
                            outlined
                            hide-details
                            prepend-inner-icon="mdi-upload"
                            @click:prepend-inner="updateEmail(email)"
                            append-icon="mdi-close"
                            :loading="loading"
                            @click:append="deleteEmail(email.Id)"
                            v-model="email.Email"/>
                    </v-slide-item>
                </v-slide-group>
            </v-row>
        </v-card>
        <v-dialog
            v-model="newListDialog"
            width="25vw">
            <v-card class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Create New Klaviyo List
                </v-card-title>
                <v-text-field
                    v-model="newListName"
                    prefix="Sales Rep:"
                    label="List Name"/>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn
                        :disabled="!newListName.length"
                        @click="createList"
                        :loading="loading"
                        color="primary">
                        Create List
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="confirmDialog"
            width="25vw">
            <v-card class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Are you sure?
                </v-card-title>
                <v-card-text class="d-flex justify-center align-center">
                    Proceeding will delete all current contacts in the Klaviyo list 
                    and replace them with all active contacts associated with this
                    sales rep.
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn
                        @click="assignLists()"
                        :loading="loading"
                        color="primary">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-row>
</template>

<script>
export default {
    props: {
        salesRepData: {type: Array},
        managers: {type: Array},
        lists: {type: Array}
    },
    beforeMount() {
        this.formattedSalesRep = []
        let mid = Math.ceil(this.salesRepData.length / 2)
        this.formattedSalesRep.push(this.salesRepData.slice(0, mid))
        this.formattedSalesRep.push(this.salesRepData.slice(mid + 1, this.salesRepData.length))
        this.getEmails()
    },
    watch: {
        salesRepData: function() {
            this.formattedSalesRep = []
            let mid = Math.ceil(this.salesRepData.length / 2)
            this.formattedSalesRep.push(this.salesRepData.slice(0, mid))
            this.formattedSalesRep.push(this.salesRepData.slice(mid + 1, this.salesRepData.length))
        },

    },
    data() {
        return {
            formattedSalesRep: [],
            newRepCard: false,
            toSalesRep: 0,
            fromSalesRep: '',
            klaviyoLists: [],
            repEmails: [],
            newListDialog: false,
            newListName: '',
            confirmDialog: false,
            confirmRemovalMenu: false,
            refreshListId: '',
            showInactiveReps: false,
            search: '',
            loading: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            newAgency: {
                Active: 1,
                Agency: '',
                Name: '',
                Position: '',
                Email: '',
                Territories: '',
                Link: '',
                Klaviyo_List_Id: ''
            }
        }
    },
    methods: {
        async updateSalesRepData(agency) {
            try {
                const response = await fetch(
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/manage',
                    {
                        method: 'PUT',
                        body: JSON.stringify({
                            Id: agency.Id,
                            Active: (agency.Active === true || agency.Active === 1) ? 1 : 0,
                            Agency: agency.Agency,
                            Name: agency.Name,
                            Position: agency.Position,
                            Email: agency.Email,
                            Territories: agency.Territories,
                            Link: agency.Link,
                            Sales_Manager: agency.Sales_Manager,
                            Klaviyo_List_Id: agency.Klaviyo_List_Id
                        }),
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const { message } = await response.json()
                this.snack(message, 'success', 3000)
            } catch (error) {
                this.snack(error,'error',3000)
            }
        },
        async addNewSalesRepData() {
            try {
                const response = await fetch(
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/manage',
                    {
                        method: 'POST',
                        body: JSON.stringify({newSalesRep: this.newAgency}),
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    salesRepData
                } = await response.json()
                this.newRepCard = false
                this.$emit('new-sales-rep-added', salesRepData)
                this.snack(message, 'success', 3000)
            } catch (error) {
                this.snack(error,'error',3000)
            }
        },
        async assignLists() {
            try {
                this.loading = true
                const response = await fetch(
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/klaviyolists',
                    {
                        method: 'PUT',
                        body: JSON.stringify({
                            list_id: this.refreshListId
                        }),
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message
                } = await response.json()
                
                this.loading = false
                this.snack(message, 'success', 3000)
            } catch (error) {
                this.snack(error,'error',3000)
            }
        },
        whereToPutAddButton(column) {
            if (this.formattedSalesRep.length > 0) {
                if (column == 0) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
            
        },
        salesRepClass(active) {
            if (active === 1) {
                return 'active-card'
            } else {
                return 'inactive-card'
            }
        },
        fullName(item) {
            if (item.lastName) {
                return `${item.firstName} ${item.lastName}`
            } else {
                return item.firstName
            }
        },
        async createList() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_SalesReps_CreateKlaviyoList?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/klaviyolists',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            listName: this.newListName
                        })
                    }
                )
                const {
                    message,
                    newList
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                this.$emit('new-list-added',newList)
                this.loading = false
                this.newListDialog = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async getLists() {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': 'pk_82285a26a53057891f750bb81f29b1022e'
                },
            };
            try {
                const response = await fetch('https://a.klaviyo.com/api/v2/lists?tag=salesRepList', requestOptions);
                const json = await response.json();
                if (response.ok) {
                    this.klaviyoLists = json.data
                } else {
                    this.snack(`Failed to get lists: ${json.message}`,'error',3000);
                }
            } catch (error) {
                this.snack(`Failed to get lists: ${error}`,'error',3000);
            }
        },
        async removeSalesRep(from) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_SalesRep_RemoveFromFutureSalesOrders?newFunction=true&tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/remove?to=${this.toSalesRep}&from=${from}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
                this.confirmRemovalMenu = false
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async getEmails() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_SalesReps_GetEmails?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/emails?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    repEmails
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.repEmails = repEmails
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async newEmail(agency) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_SalesReps_CreateEmail?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/emails?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Agency_Id: agency.Id,
                            New_Email: agency.NewEmail
                        })
                    }
                )
                const {
                    message,
                    repEmails
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                agency.NewEmail = ''
                this.repEmails = repEmails
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateEmail(email) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_SalesReps_UpdateEmail?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/emails?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: email.Id,
                            email: email.Email
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async deleteEmail(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_SalesReps_DeleteEmail?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/salesreps/emails?email=${this.$store.getters.getUserEmail}&id=${id}`,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    repEmails
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.repEmails = repEmails
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        getRepEmails(id) {
            return this.repEmails.filter(item => item.Agency_Id === id)
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        salesRepDataFiltered() {
            if (this.showInactiveReps) {
                return this.searchedSalesRepData
            } else {
                return this.searchedSalesRepData.filter(item => item.Active === 1)
            }
        },
        searchedSalesRepData() {
            if (this.search.length) {
                return this.salesRepData.filter(item => item.Agency.toLowerCase().includes(this.search.toLowerCase()))
            } else {
                return this.salesRepData
            }
        }
    }
}
</script>

<style>
.inactive-card {
    color: lightgray
}
.active-card {
    color: black
}
.container {
    display: flex
}
.col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

</style>