<template>
    <div>
        <v-card-title>
            Amazon
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="amazonAccounts"
            :search="search"
            class="elevation-1">
            <template v-slot:[`item.Conversions`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.Conversions"
                    large
                    @save="save(prop.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.Conversions }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.Conversions"
                            :min="1"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:[`item.Sessions`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.Sessions"
                    large
                    @save="save(prop.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.Sessions }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.Sessions"
                            :min="1"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
        </v-data-table>
        <br>
        <v-btn
            color="primary"
            elevation="2"
            small
            @click="updateOrders"
            :disabled="btndisabled"
        >Net Sales Do Not Look Right</v-btn>
        <v-snackbar
            v-model="snack"
            :timeout="snackTimeout"
            :color="snackColor"
            >
            {{ snackText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                v-bind="attrs"
                text
                @click="snack = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

export default {
    name: "AmazonAccounts",
    props: {
        inputtedDate: {type: Object}
    },
    beforeMount() {
        this.getAmazonAccounts();
    },
    watch: {
    },
    data:() => ({
        amazonAccounts: [
            {
                "Date": '',
                "Sub_Channel": '',
                "Net_Sales": '',
                "Conversions": '',
                "Sessions": ''
            }
        ],
        snack: false,
        snackColor: '',
        snackText: '',
        snackTimeout: 3000,
        btndisabled: false,
        search: '',
        rules: []
    }),
    methods: {
        save (item) {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Data saved'
            this.updateAmazonSales(item.Conversions, item.Sessions, item.Date, item.Sub_Channel)
        },
        cancel () {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Canceled'
        },
        open () {
            this.snack = true
            this.snackColor = 'info'
            this.snackText = 'Dialog opened'
        },
        close () {
            console.log('Dialog closed')
        },
        updateOrders: function() {
            const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/updateamazonorders'
            const request = {
                method: 'GET',
                Headers: {
                'Content-Type': 'application/json',
                },
            };
            fetch(url, request)
            this.snackTimeout = 6000;
            this.snack = true
            this.snackColor = 'error'
            this.btndisabled = true
            this.snackText = 'Please wait 5-10 min and then refresh the page'
            this.snackTimeout = 3000;
        },
        getAmazonAccounts: function() {
            const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/amazon';
            const data = {
                startDate: this.$props.inputtedDate.startDate,
                endDate: this.$props.inputtedDate.endDate
            }
            const request = {
                method: 'POST',
                Headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            };
            fetch(url, request).then((response) => {
                return response.json();
            }).then((data) => {
                // turn the data back into an object since it
                console.log(data);
                for (let items in data) {
                    data[items].Date = data[items].Date.toString().split('T')[0];
                }
                this.amazonAccounts = data
            });
        },
        updateAmazonSales: function(conversions, sessions, date, sub_channel) {
            const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/amazon';
                const data = {
                    'Conversions': conversions,
                    'Sessions': sessions,
                    'Date': date,
                    'Sub_Channel': sub_channel
                }
                const request = {
                    method: 'PUT',
                    Headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(data)
                };
                fetch(url, request).then((response) => {
                    return response.json();
                }).then((data) => {
                    console.log(data)
                });
        }

    },
    computed: {
        headers() {
            return [
                {
                    text: 'Date',
                    value: 'Date'
                },
                {
                    text: 'Amazon Account',
                    value: 'Sub_Channel'
                },
                {
                    text: 'Net Sales',
                    value: 'Net_Sales'
                },
                {
                    text: 'Conversions',
                    value: 'Conversions'
                },
                {
                    text: 'Sessions',
                    value: 'Sessions'
                },
            ]
        }
    }
}
</script>

<style>

</style>