<template>
<v-app>
    <v-main>
          <v-row align="center" justify="space-between" class="d-flex justify-space-between align-center">
            <v-col md="auto" class="pt-2 pb-0 d-flex justify-center align-center">
                <years class="pb-1" id="yearSelector" v-model="selectedYear"/>
                <forecastCategories class="pt-1" v-model="selectedCategory"/>
            </v-col>
            <v-col class="pt-4 py-0 pb-0 my-0" md="auto">
              <settings/>
            </v-col>
            <v-col md="auto" class="pt-4 pb-0 pl-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <strong><u>Cost Category:</u></strong><br>
                    <div class="d-flex justify-start align-center">
                      {{ costPriceInfo.costName }}
                      <v-btn icon small color="primary" :to="{name: 'Forecast Accounts', params: {filter: selectedCategory.Forecast_Name} }">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
                <span>
                  <div>
                    If a category has multiple costs, the first cost in the list will be used.<br>
                    Costs are choosen based on the following hierarchy:
                  </div>
                  <div v-for="(cost, index) in costNames" :key="index">
                    {{ index + 1 }}. {{ cost }}
                  </div>
                  <div>
                    <strong class="red--text">
                      IF SOMETHING SEEMS WRONG, PLEASE REFRESH THE PAGE AND TRY AGAIN BEFORE MAKING ANY CHANGES.
                    </strong>
                  </div>
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="flex-column justify-start align-center">
                    <strong><u>Price Category:</u></strong><br>
                    <div class="d-flex justify-start align-center">
                      {{ costPriceInfo.priceName }}
                      <v-btn icon small color="primary" :to="{name: 'Forecast Accounts', params: {filter: selectedCategory.Forecast_Name} }">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
                <span>
                    <div>
                      If a category has multiple prices, the first price in the list will be used.<br>
                      Prices are choosen based on the following hierarchy:
                    </div>
                    <div v-for="(price, index) in priceNames" :key="index">
                      {{ index + 1 }}. {{ price }}
                    </div>
                    <div>
                      <strong class="red--text">
                        IF SOMETHING SEEMS WRONG, PLEASE REFRESH THE PAGE AND TRY AGAIN BEFORE MAKING ANY CHANGES.
                      </strong>
                    </div>
                </span>
              </v-tooltip>
            </v-col>
            <v-col class="px-2 pb-0 pt-4">
                <goalProgress 
                  :part="goal.part"
                  :whole="goal.whole"/>
            </v-col>
            <v-col md="auto" class="pr-8" v-if="isAdmin">
              <saveForecast/>
            </v-col>
          </v-row>
        <forecastTable 
          @costPriceInfo="handleCostPriceInfo"
          :year="selectedYear" 
          :category="selectedCategory"/>
        <v-snackbar
          v-model="snackActive"
          :color="snackColor"
          :timeout="snackTimeout">
          {{ snackText }}
        </v-snackbar>
      </v-main>
</v-app>
</template>

<style src="../stylesheet.css" scoped></style>

<script>
import forecastTable from '../components/Home/Table/Home.vue'
import forecastCategories from '../components/Home/forecastCategories.vue'
import years from '../components/Home/years.vue'
import goalProgress from '../components/Home/goalProgress.vue'
import settings from '../components/Home/settings.vue'
import saveForecast from '../components/General/saveForecast.vue'

export default {
  name: 'Home',
  components: {
    forecastCategories,
    forecastTable,
    years,
    goalProgress,
    settings,
    saveForecast,
  },
  watch: {
    selectedYear: function() {
      if (this.selectedYear && this.selectedCategory) {
        this.getGoal()
      }
    },
    selectedCategory: function() {
      if (this.selectedYear && this.selectedCategory) {
        this.getGoal()
      }
    }
  },
  async beforeMount() {
    await this.getGoal()
    document.title = 'Sandbox - Forecast Tool'
    this.$store.dispatch('updateShowWaffle', true)
    this.$store.dispatch('updateNavbarContent',
      [
        {title: 'Home', path: '/sales/forecast/home', icon: 'mdi-home', notification: false},
        {title: 'Assign Lists', icon: 'mdi-account-box', path: '/sales/forecast/accounts', notification: false},
        {title: 'Products', icon: 'mdi-package-variant-closed', path: '/sales/forecast/products', notification: false},
        {title: 'Forecast Categories', icon: 'mdi-shape', path: '/sales/forecast/categories', notification: false},
        {title: 'Bulk Upload', icon: 'mdi-upload', path: '/sales/forecast/bulk-upload', notification: false},
        {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
      ]
    )
    
    this.$store.dispatch('updateNavbarTitle','Forecast Tool')
  },
  created() {
    let keysPressed = {}
		window.addEventListener('keydown', (e) => {
      keysPressed[e.key] = true
      if (keysPressed['Alt'] && e.key === 's') {
				let otherSettings = this.$store.getters.getOtherSettings
				otherSettings.speedRound = !otherSettings.speedRound
        //update local storage
        localStorage.otherSettings = JSON.stringify(otherSettings)
        //update vuex
				this.$store.dispatch('updateOtherSettings', otherSettings)
				if (otherSettings.speedRound) {
					this.snack('Speed Round Enabled','info',3000)
				} else {
					this.snack('Speed Round Disabled','info',3000)
				}
			}
    })
    window.addEventListener('keyup', (e) => {
			delete keysPressed[e.key]
		})
    
  },
  data: () => ({
    dialog: false,
    selectedYear: 0,
    selectedCategory: {
      Forecast_Name: '',
      Active: 0,
      Preorder: 0,
    }, 
    goal: {
      whole: 0,
      part: 0,
    },
    costNames: [],
    priceNames: [],
    costPriceInfo: {},
    path:'/sales/forecast/home',
    snackText: 'success',
    snackColor: 'success',
    snackTimeout: 3000,
    snackActive: false,
  }),
  methods: {
    //I also added price and cost list names to this function bc lazyness of making another api gateway/api call/lambda function sorryz...
    async getGoal() {
      try {
        let url = new URL('https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/goal')
        url.searchParams.append('email', this.$store.state.user.email)
        url.searchParams.append('year', this.selectedYear)
        url.searchParams.append('category', this.selectedCategory.Forecast_Id)

        const result = await fetch(
          //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_GetGoals?tab=code
          url,
          {
            method: 'GET',
            Headers: {
              'Content-Type': 'application/json',
            }
          }
        )
        const {
          message,
          whole,
          part,
          costNames,
          priceNames
        } = await result.json()
      
        if(!result.ok) {
          throw message
        }
        
        this.goal.whole = whole
        this.goal.part = part
        this.costNames = costNames
        this.priceNames = priceNames

      } catch (error) {
        console.log(error)
      }
    },
    snack(text, color, timeout) {
      this.snackText = text;
      this.snackColor = color;
      this.snackTimeout = timeout;
      this.snackActive = true;
    },
    handleCostPriceInfo(payload) {
      this.costPriceInfo = payload
    }
  },
  computed: {
    isAdmin() {
      let permissions = this.$store.getters.getPermissions
      if (permissions.includes('Sales_Admin') || permissions.includes('Admin')) {
        return true
      } else {
        return false
      }
    }
  }
};
</script>
