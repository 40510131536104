<template>
    <v-app>
        <v-main class="d-flex">
            <v-row class="pt-8 pb-6 pr-8" justify="end">
                <v-icon
                    color="primary"
                    @click="explainPage = !explainPage">
                    mdi-information-outline
                </v-icon>
            </v-row>
            <v-row justify="space-around">
                <fix-from-account/>
                <fix-from-date-range/>
                <fix-from-order-list/>
            </v-row>
            <v-snackbar
                v-model="snackActive"
                :color="snackColor"
                :timeout="snackTimeout">
                {{ snackText }}
            </v-snackbar>
            <v-overlay 
                :value="explainPage"
                :opacity="1"
                @click="explainPage = !explainPage">
                <v-img 
                    src="../../../../assets/fixingDataInfo.png"
                    max-height="90vh"
                    max-width="90vw"/>
            </v-overlay>
        </v-main>
    </v-app>
</template>

<script>
import fixFromAccount from '../CreditNotes/fixFromAccount.vue'
import fixFromDateRange from '../CreditNotes/fixFromDateRange.vue'
import fixFromOrderList from '../CreditNotes/fixFromOrderList.vue'

export default {
    components: {
        fixFromAccount,
        fixFromDateRange,
        fixFromOrderList
    },
    beforeMount() {
        document.title = 'Sandbox - Fix Database'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', 
            [
                {title: 'Sales Orders', path: '/sales/database/fix/salesorders', icon: 'mdi-currency-usd', notification: false},
                {title: 'Credit Notes', icon: 'mdi-credit-card-sync-outline', path: '/sales/database/fix/creditnotes', notification: false},
                {title: 'Payments', icon: 'mdi-cash-clock', path: '/sales/database/fix/payments', notification: false},
                {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
            ]
        )
        this.$store.dispatch('updateNavbarTitle','Fixing Credit Notes')
    },
    data() {
        return {
            explainPage: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            updateLoading: false,
            downloadLoading: false,
        }
    },
    methods: {
        downloadNewOrders() {
            let d = new Date()
            let yesterday = new Date(d.setDate(d.getDate() - 2))
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/updateorders'
            const data = {
                url: "https://api.cin7.com/api/v1/CreditNotes?where=CreatedDate>='" + yesterday.toISOString().split('T')[0] + "T00:00:00Z'&rows=250&page="
            }
            const request = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.downloadLoading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.downloadLoading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.downloadLoading = false
                this.snack(error, 'error', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    }
}
</script>

<style>

</style>