<template>
    <v-app>
        <v-main>
            <v-row justify="center" class="pt-8">
                <v-card class="pa-4" width="90vw">
                    <v-card-title class="d-flex justify-center align-center">
                        Application Submitted
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center align-center">
                        Please reach out to sales@matadorup.com to make changes to your application
                    </v-card-subtitle>
                    <v-card-text class="d-flex justify-center align-center" v-if="loading">
                        <v-progress-circular indeterminate color="primary"/><br/>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            Loading Confirmation...
                        </v-card-subtitle>
                    </v-card-text>
                    <v-list v-else>
                        <v-row>
                            <v-col>
                                <v-card-title class="d-flex justify-center align-center text-wrap">General Information</v-card-title>
                                <v-divider/>
                                <v-list-item><strong class="pr-1">Company Name:</strong> {{ details.Company_Name }}</v-list-item>
                                <v-list-item><strong class="pr-1">Phone:</strong> {{ details.Phone }}</v-list-item>
                                <v-list-item><strong class="pr-1">Door Count:</strong> {{ details.Door_Count }}</v-list-item>
                                <v-list-item><strong class="pr-1">Website:</strong> {{ details.Website }}</v-list-item>
                                <v-list-item><strong class="pr-1">Third Party Sites:</strong> {{ details.Third_Party_Sites }}</v-list-item>
                                <v-list-item><strong class="pr-1">AP Email:</strong> {{ details.AP_Email }}</v-list-item>
                                <v-list-item><strong class="pr-1">Online Store:</strong> 
                                    <v-icon v-if="details.Online_Store === 1" color="success">mdi-check-circle</v-icon>
                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                </v-list-item>
                                <v-list-item><strong class="pr-1">Majority of Business Online:</strong> 
                                    <v-icon v-if="details.Majority_Online_Business === 1" color="success">mdi-check-circle</v-icon>
                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                </v-list-item>
                                
                            </v-col>
                            <v-divider vertical/>
                            <v-col>
                                <v-card-title class="d-flex justify-center align-center text-no-wrap">Shipping Information</v-card-title>
                                <v-divider/>
                                <v-list-item><strong class="pr-1">Shipping Account: </strong> {{ details.Shipping_Account }}</v-list-item>
                                <v-list-item><strong class="pr-1">Shipping Address 1: </strong> {{ details.Shipping_Address_1 }}</v-list-item>
                                <v-list-item v-if="details.Shipping_Address_2.length"><strong class="pr-1">Shipping Address 2: </strong> {{ details.Shipping_Address_2 }}</v-list-item>
                                <v-list-item><strong class="pr-1">Shipping City: </strong> {{ details.Shipping_City }}</v-list-item>
                                <v-list-item><strong class="pr-1">Shipping State: </strong> {{ details.Shipping_State }}</v-list-item>
                                <v-list-item><strong class="pr-1">Shipping Zip Code: </strong> {{ details.Shipping_Zip }}</v-list-item>
                                <v-list-item><strong class="pr-1">Shipping Country: </strong> {{ details.Shipping_Country }}</v-list-item>
                            </v-col>    
                            <v-divider vertical/>
                            <v-col>
                                <v-card-title class="d-flex justify-center align-center text-no-wrap">Billing Information</v-card-title>
                                <v-divider/>
                                <v-list-item><strong class="pr-1">Billing Company: </strong> {{ details.Billing_Company }}</v-list-item>
                                <v-list-item><strong class="pr-1">Billing Address 1: </strong> {{ details.Billing_Address_1 }}</v-list-item>
                                <v-list-item v-if="details.Billing_Address_2.length"><strong class="pr-1">Billing Address 2:</strong> {{ details.Billing_Address_2 }}</v-list-item>
                                <v-list-item><strong class="pr-1">Billing City: </strong> {{ details.Billing_City }}</v-list-item>
                                <v-list-item><strong class="pr-1">Billing State: </strong> {{ details.Billing_State }}</v-list-item>
                                <v-list-item><strong class="pr-1">Billing Zip Code: </strong> {{ details.Billing_Zip }}</v-list-item>
                                <v-list-item><strong class="pr-1">Billing Country: </strong> {{ details.Billing_Country }}</v-list-item>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-card-title class="d-flex justify-center align-center">Contacts</v-card-title>
                        </v-row>  
                        <v-divider/> 
                        <v-row align="center" justify="center" class="pt-4">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact, index) in contacts" :key="index">
                                        <td>{{ contact.First_Name }}</td>
                                        <td>{{ contact.Last_Name }}</td>
                                        <td>{{ contact.Email }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-row>
                    </v-list>
                </v-card> 
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Matador NDA Confirmation'
        this.$store.dispatch('updateShowWaffle', false)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Matador New Dealer Application Confirmation')
    },
    async created() {
        if(this.$route.params.id) {
            await this.getInfo(this.$route.params.id)
        }
    },
    data() {
        return {
            details: {},
            contacts: [],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_GetLeadDetails?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/details?id=${id}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    details,
                    contacts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.details = details[0]
                this.contacts = contacts
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {

    }
}
</script>

<style>

</style>