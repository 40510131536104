<template>
    <v-container fliud>
        <v-row class="d-flex justify-center align-center px-0" v-if="whole > 0">
            <v-col md="auto" class="pb-0">
                <v-progress-circular
                    :value="percent"
                    :rotate="-90"
                    :size="70"
                    :width="15"
                    color="primary">
                    <template v-if="whole === 0">
                        <v-icon color="primary">mdi-infinity</v-icon>
                    </template>
                    <template v-else>
                        {{ percent }}
                    </template>
                </v-progress-circular>
            </v-col>
            <v-col md="auto" align="center" class="pb-0">
                {{ display }}
                <br>
                <span v-if="!isGoalComplete">{{ amountLeft }} remaining</span>
                <span v-else>Congrats! You're all done!</span>
            </v-col>
            <v-col class="pb-0 d-flex justify-start align-start" md="auto" align="start" v-if="isGoalComplete">
                <div class="d-flex justify-start align-start">
                    <iframe class="d-flex justify-start" style="height: 100px; width: 100px;" :src="link" frameBorder="0"/>
                </div>
            </v-col>
        </v-row>
        <v-row class="flex-column justify-center align-center px-0 pt-4" v-else>
            <v-icon color="red" x-large>mdi-cookie-alert</v-icon>
            <v-card-subtitle>
                No Goal
            </v-card-subtitle>
        </v-row>
    </v-container>
</template>

<script>
const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD'
})

export default {
    props: ['part','whole'],
    beforeMount() {
        if(this.isGoalComplete) {
            this.randomGif()
        }
    },
    watch: {
        isGoalComplete: function() {
            this.randomGif()
        }
    },
    data() {
        return {
            link: ''
        }
    },
    methods: {
        randomGif() {
            if(this.isGoalComplete) {
                const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/gif'
                const data = {
                    search: 'congratulations'
                }
                const request = {
                    method: 'POST',
                    body:JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                fetch(url, request).then(async response => { 
                    const data = await response.json()
                
                    if (!response.ok) {
                        const error = (data && data.message) || response.status
                        return Promise.reject(error)
                    }
                    this.link = data.gifEmbed
                })
            }
        }
    },
    computed: {
        percent() {
            return (this.part / this.whole * 100).toFixed(0) + '%'
        },
        display() {
            return formatter.format(this.part) + ' / ' + formatter.format(this.whole)
        },
        amountLeft() {
            return formatter.format(this.whole - this.part)
        },
        getPart() {
            return formatter.format(this.part)
        },
        getWhole() {
            return formatter.format(this.whole)
        },
        isGoalComplete() {
            if (this.whole - this.part <= 0) {
                return true
            } else {
                return false
            }
        },
    }
}
</script>

<style>

</style>