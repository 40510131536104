<template>
    <v-dialog v-model="dialog" width="75vw">
        <v-card width="75vw" class="pa-4" style="overflow-y: hidden">
            <v-card-title class="d-flex justify-center align-center">
                Overdue Details
            </v-card-title>
            <v-row v-if="loading" justify="center">
                <v-progress-circular indeterminate/>
            </v-row>
            <template  v-else-if="(formatted.length > 0)">
                <v-row justify="center" align="center">
                    <v-btn
                        icon 
                        color="black"
                        @click="overdueLookbackTemp--"
                        :disabled="(overdueLookbackTemp === 0)">
                        <v-icon large>mdi-chevron-down</v-icon>
                    </v-btn>
                    <div class="flex-column justify-center align-center">
                        <v-card-title class="d-flex justify-center align-center">
                            {{ overdueLookbackTemp }}
                        </v-card-title>
                        <v-card-subtitle class="text-center">
                            Weeks Ago
                        </v-card-subtitle>
                    </div>    
                    <v-btn
                        icon
                        color="black"
                        :disabled="overdueLookbackTemp === 52"
                        @click="overdueLookbackTemp++">
                        <v-icon large>mdi-chevron-up</v-icon>
                    </v-btn>
                </v-row>
                <v-expansion-panels>
                    <v-row justify="start" class="py-0">
                        <v-col class="pl-4 pr-0">
                            <v-card-title class="py-0">Company</v-card-title>
                        </v-col>
                        <v-col class="pl-0">
                            <v-card-title class="py-0 pl-0">Total Overdue {{ totalOverdue | currency }}</v-card-title>
                        </v-col>
                    </v-row>
                    <v-expansion-panel v-for="(item, index) in formatted" :key="index">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    {{ item.Company }}
                                </v-col>
                                <v-col>
                                    {{ item.Total_Owed | currency }}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Order Ref
                                        </th>
                                        <th class="text-left">
                                            Invoice Date
                                        </th>
                                        <th class="text-left">
                                            Payment Date
                                        </th>
                                        <th class="text-left">
                                            Days Left To Pay
                                        </th>
                                        <th class="text-left">
                                            Current Payment Amount
                                        </th>
                                        <th class="text-left">
                                            Order Total
                                        </th>
                                        <th class="text-left">
                                            Total Owing
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order, index1) in item.orders" :key="index1">
                                        <td><a :href='salesOrderUrl(order.Order_Id)' target="_blank">{{ order.Reference }}</a></td>
                                        <td>{{ formatDate(order.Invoice_Date) }}</td>
                                        <td>{{ formatDate(order.Calc_Payment_Date) }}</td>
                                        <td>{{ daysLeft(order.Calc_Payment_Date, order.Total_Owed)}}</td>
                                        <td>{{ order.Payment | currency }}</td>
                                        <td>{{ order.Order_Total | currency }}</td>
                                        <td>{{ order.Total_Owed | currency }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
        </v-card>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialogCount: {type: Number},
        overdueLookback: {type: Number}
    },
    watch: {
        dialogCount: async function() {
            this.overdueLookbackTemp = this.$props.overdueLookback
            this.dialog = true
            await this.getDetails()
        },
        overdueLookbackTemp: function() {
            this.$emit('overdue-lookback-changed', this.overdueLookbackTemp)
        }
    },
    data() {
        return {
            dialog: false,
            details: [],
            dorOverdue: [],
            overdueLookbackTemp: 6, 
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getDetails() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CashFlowModel_GetOverdueDetails?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/cashflowmodel/overduedetails',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    details,
                    dorOverdue
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.dorOverdue = dorOverdue
                this.details = details
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        salesOrderUrl(id) {
            return 'https://go.cin7.com/Cloud/TransactionEntry/TransactionEntry.aspx?idCustomerAppsLink=985806&OrderId=' + id
        },
        daysLeft(paymentDate, totalOwed) {
            if (totalOwed > 0) {
                const date1 = new Date();
                const date2 = new Date(paymentDate);
                const diffTime = date2 - date1;
                return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            } else {
                return 'Paid'
            }
        },
        formatDate(date) {
            if (!date) {
                return 'Not Invoiced'
            } else {
                let inputDate = new Date(date)
                return inputDate.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'}) 
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        minWeek() {
            const now = new Date();
            const yearStart = new Date(now.getFullYear(), 0, 1); // January 1st of the current year
            const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000; // milliseconds in a week
            
            // See how many milliseconds back we have to subtract
            const millisecondLookback = millisecondsPerWeek * this.$props.overdueLookback
            

            // Calculate the time difference between the current date and January 1st
            const timeDiff = now.getTime() - yearStart.getTime() - millisecondLookback;

            // Calculate the week number by dividing the time difference by the milliseconds in a week
            const weekNumber = Math.ceil((timeDiff / millisecondsPerWeek));

            // Format the result as YYYY-WW
            const formattedWeekNumber = now.getFullYear() + weekNumber.toString().padStart(2, '0');

            return Number(formattedWeekNumber);
        },
        formatted() {
            let finalArray = []
            let d = new Date()
            if (this.details.length > 0) {
                let companies = [... new Set(this.details.map(item => item.Company))]
                for (let i = 0; i < companies.length; i++) {
                    let orderTotal = 0
                    let payments = 0
                    let owed = 0
                    let orders = this.details.filter(item =>
                        item.Company === companies[i] &&
                        new Date(item.Calc_Payment_Date.split(' ')[0]) < d &&
                        Number(item.Calc_Payment_Week) >= this.minWeek
                    )
                    for (let o = 0; o < orders.length; o++) {
                        orderTotal += orders[o].Order_Total
                        payments += orders[o].Payment
                        owed += orders[o].Total_Owed
                    }
                    if (owed > 1) {
                        finalArray.push({
                            Company: companies[i],
                            Order_Total: orderTotal,
                            Payment: payments,
                            Total_Owed: owed,
                            orders: orders
                        })
                    }
                }
            }
            return finalArray.sort((a,b) => b.Total_Owed - a.Total_Owed)
        },
        totalOverdue() {
            return this.formatted.reduce((sum, record) => {
                return sum + record.Total_Owed
            },0)
        }
    }
}
</script>

<style>

</style>