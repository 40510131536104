<template>
    <v-main>
        <v-row class="pt-8" justify="center" align="center">
            <v-card width="75vw">
                <v-card-title class="headline">Batch Review
                    <v-spacer/>
                    <v-checkbox
                        v-model="showCompleted"
                        label="Show Completed"
                        hide-details/>
                </v-card-title>
                <v-card-subtitle>
                    Batch statuses can NOT update automatically and the page has to be refreshed to collect new status information.
                </v-card-subtitle>
                <v-data-table 
                    :headers="headers"
                    show-expand
                    :single-expand="false"
                    :expand.sync="expanded"
                    item-key="Id"
                    :page="page"
                    :items="filteredBatches"
                    :loading="loading">
                    <!-- for if no rows are returned -->
                    <template v-slot:no-data>
                        <v-alert :value="true" icon="mdi-emoticon-cool-outline">
                            All batches have been completed
                        </v-alert>
                    </template>
                    <template v-slot:[`item.Started_Time`]="{ item }">
                        {{ formatTimeToMST(item.Started_Time) }}
                    </template>
                    <template v-slot:[`item.Status`]="{ item }">
                        <v-chip
                            :color="colorMap[item.Status]">
                            {{ item.Status }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.Actions`]="{ item }">
                        <v-btn
                            v-if="!item.retry"
                            :disabled="item.Status !== 'Failed'"
                            color="primary"
                            @click="retryBatch(item.Id)"
                            icon>
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                        <v-progress-circular
                            v-else
                            color="primary"
                            indeterminate/>
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="primary">
                                    <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                            </template>
                            <v-card class="pa-4">
                                <v-card-title>Accounts in Batch</v-card-title>
                                <v-list>
                                    <v-list-item
                                        v-for="account in accountsInBatches[item.Id]"
                                        :key="account.id">
                                        <v-list-item-content>
                                            <router-link :to="'/sales/crm/accounts/' + account.id">
                                                <v-list-item-title>{{ account.company }}</v-list-item-title>
                                            </router-link>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                        <v-menu v-if="item.Status === 'Failed'">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="red">
                                    <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                            </template>
                            <v-card class="pa-4">
                                <v-card-title>Errors</v-card-title>
                                <v-card-text>{{ item.Message }}</v-card-text>
                            </v-card>
                        </v-menu>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                        <td :colspan="headers.length + 1">
                            <v-card flat class="flex-column justify-center align-center">
                                <v-tooltip 
                                    bottom 
                                    :key="index + 'Step'" 
                                    :disabled="step.Message === null"
                                    v-for="(step, index) in findBatchSteps(item.Id)">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip
                                            v-bind="attrs"
                                            v-on="on"
                                            :color="colorMap[step.Result]">
                                            {{ step.Stage }}
                                        </v-chip>
                                        <v-icon color="grey" v-if="index !== 1">mdi-chevron-right</v-icon>
                                    </template>
                                    <span>
                                        {{ step.Result }}<br>
                                        {{ step.Message }}
                                    </span>
                                </v-tooltip>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </v-row>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-main>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - Envoy Batch Review'
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Leads', path: '/sales/crm/leads/home', icon: 'mdi-account-box-outline', notification: false},
            {title: 'Accounts', icon: 'mdi-domain', path: '/sales/crm/accounts/home', notification: false},
            {title: 'Admin', path: '/sales/crm/admin/home', icon: 'mdi-cog', notification: false},
            {title: 'Batches', path: '/sales/crm/batches', icon: 'mdi-account-convert', notification: false},
            {title: 'Export', path: '/sales/crm/accounts/export', icon: 'mdi-export-variant', notification: false},
            {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},

        ])
        this.$store.dispatch('updateNavbarTitle','Envoy Batch Review')
        this.getInfo()
    },
    watch: {
        page: function() {
            this.getInfo()
        }
    },
    data() {
        return {
            batches: [],
            page: 1,
            batchSteps: [],
            accounts: [],
            expanded: [],
            colorMap: {
                'Complete': 'green',
                'Failed': 'red',
                'In Progress': 'yellow',
                'Retrying': 'blue',
                'Not Started': 'grey'
            },
            showCompleted: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sales_EnvoyBatchReviewGetInfo?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/envoy/batches?email=${this.$store.getters.getUserEmail}&page=${this.page}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    batches,
                    accounts,
                    batchSteps
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.batches = batches
                this.accounts = accounts
                this.batchSteps = batchSteps
                //add detail boolean to batches array
                for (let i = 0; i < this.batches.length; i++) {
                    this.batches[i].showDetails = false
                    this.batches[i].retry = false
                }
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async retryBatch(batchId) {
            try {
                this.changeBatch(batchId, 'retry')
                this.changeBatch(batchId, 'Status', 'Retrying')
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Envoy_StartCustomerUpload?tab=code
                    `https://y7bnre858h.execute-api.us-east-2.amazonaws.com/prod/checkcustomerupload?email=${this.$store.getters.getUserEmail}&batch=${batchId}&retry=1`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    batches
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.batches = batches
                //add detail boolean to batches array
                for (let i = 0; i < this.batches.length; i++) {
                    this.batches[i].showDetails = false
                    this.batches[i].retry = false
                }
                this.snack(message,'success',3000)
            } catch (error) {
                this.changeBatch(batchId, 'retry')
                this.snack(error,'error',3000)
            }
        },
        formatTimeToMST(time) {
            return new Date(time).toLocaleString('en-US', { timeZone: 'America/Denver' })
        },
        changeBatch(id, variable, value) {
            this.batches = this.batches.map(batch => {
                if (batch.Id === id) {
                    if (value) {
                        batch[variable] = value
                        return batch
                    } else {
                        batch[variable] = !batch[variable]
                        return batch
                    }
                } 
                return batch
            })
        },
        findBatchSteps(id) {
            return this.batchSteps.filter(step => step.Batch_Id === id)
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        accountsInBatches() {
            let obj = {}
            for (let i = 0; i < this.batches.length; i++) {
                obj[this.batches[i].Id] = this.accounts.filter(account => account.envoyBatch === this.batches[i].Id)
            }
            return obj
        },
        filteredBatches() {
            let copy = JSON.parse(JSON.stringify(this.batches))
            if (this.showCompleted) {
                return this.batches
            }
            return copy.filter(batch => batch.Status !== 'Complete')
        },
        headers() {
            return [
                {
                    text: 'Batch Number',
                    align: 'left',
                    sortable: false,
                    value: 'Id',
                },
                { text: 'Started Time', value: 'Started_Time' },
                { text: 'Status', value: 'Status' },
                { text: 'Retry Count', value: 'Count' },
                { text: 'Actions', value: 'Actions'}
            ]
        }
    },
}
</script>

<style>

</style>