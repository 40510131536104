<template>
    <v-card class="pt-4" style="overflow-y: scroll; overflow-x: hidden;" height="45vh" width="48vw">
        <v-card-title>
            <v-row justify="center" align="center">
                <v-col>
                    Total Owed - {{ totalOwed | currency }}<br>
                    <v-card-subtitle class="my-0 py-0">
                        {{ Object.keys(groupedByCompany).length }} Companies and {{ filteredPayments.length }} Orders
                    </v-card-subtitle>
                </v-col>
                <v-col>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="displayDates"
                            label="Payment Date Between"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"/>
                        </template>
                        <v-date-picker
                            v-model="dateRange"
                            range
                            no-title
                            scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(dateRange)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </v-card-title>
        <v-expansion-panels accordion>
            <v-row justify="start" class="py-0">
                <v-col class="pl-4 pr-0">
                    <v-card-title class="py-0">Company</v-card-title>
                </v-col>
                <v-col class="pl-0">
                    <v-card-title class="py-0 pl-0">Total Incoming</v-card-title>
                </v-col>
            </v-row>
            <v-expansion-panel
                v-for="(value, propertyName, index) in groupedByCompany"
                :key="index">
                <v-expansion-panel-header>
                    <v-row>
                        <v-col>
                            {{ propertyName }} 
                        </v-col>
                        <v-col>
                            {{ value.totalOwing | currency }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Order Ref
                                    </th>
                                    <th class="text-left">
                                        Invoice Date
                                    </th>
                                    <th class="text-left">
                                        Payment Date
                                    </th>
                                    <th class="text-left">
                                        Days Left To Pay
                                    </th>
                                    <th class="text-left">
                                        Current Payment Amount
                                    </th>
                                    <th class="text-left">
                                        Order Total
                                    </th>
                                    <th class="text-left">
                                        Total Owing
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in value.orders"
                                :key="item.Order_Id">
                                    <td><a :href='salesOrderUrl(item.Order_Id)' target="_blank">{{ item.Order_Ref }}</a></td>
                                    <td>{{ formatDate(item.Invoice_Date) }}</td>
                                    <td>{{ formatDate(item.Payment_Date) }}</td>
                                    <td :class="daysLeftFormat(item.Payment_Date)">{{ daysLeft(item.Payment_Date)}}</td>
                                    <td>{{ item.Payment | currency }}</td>
                                    <td>{{ item.Order_Total | currency }}</td>
                                    <td>{{ item.Total_Owing | currency }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
export default {
    beforeMount() {
        let lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 1)
        let nextMonth = new Date()
        nextMonth.setMonth(nextMonth.getMonth() + 1)
        nextMonth = nextMonth.toISOString().split('T')[0]
        lastMonth = lastMonth.toISOString().split('T')[0]
        this.dateRange = [lastMonth, nextMonth]
    },
    props: {
        orderData: {type: Array}
    },
    data() {
        return {
            menu: false,
            dateRange: [],
        }
    },
    methods: {
        salesOrderUrl(id) {
            return 'https://go.cin7.com/Cloud/TransactionEntry/TransactionEntry.aspx?idCustomerAppsLink=985806&OrderId=' + id
        },
        formatDate(date) {
            if (!date) {
                return 'Not Invoiced'
            } else {
                let inputDate = new Date(date)
                return inputDate.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'}) 
            }
        },
        daysLeft(paymentDate) {
            const date1 = new Date();
            const date2 = new Date(paymentDate);
            const diffTime = date2 - date1;
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        },
        daysLeftFormat(paymentDate) {
            const date1 = new Date();
            const date2 = new Date(paymentDate);
            const diffTime = date2 - date1;
            const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (days >= 0) {
                return 'black--text'
            } else {
                return 'red--text'
            }
        }
    },
    computed: {
        displayDates() {
            if (this.dateRange.length === 2) {
                const firstDate = new Date(this.dateRange[0])
                const secondDate = new Date(this.dateRange[1])
                const firstDateFormatted = firstDate.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'})
                const secondDateFormatted = secondDate.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'})
                if (firstDate > secondDate) {
                    return secondDateFormatted + ' to ' + firstDateFormatted
                } else {
                    return firstDateFormatted + ' to ' + secondDateFormatted
                }
            } else {
                return 'No Dates Selected'
            }
            
        },
        filteredPayments() {
            const firstDate = new Date(this.dateRange[0])
            const secondDate = new Date(this.dateRange[1])
            let startDate = new Date()
            let endDate = new Date()

            if (firstDate > secondDate) {
                startDate = secondDate
                endDate = firstDate
            } else {
                startDate = firstDate
                endDate = secondDate
            }
            if (this.dateRange.length === 2) {
                return this.$props.orderData.filter(item => new Date(item.Payment_Date) > startDate && new Date(item.Payment_Date) < endDate)
            } else {
                return this.$props.orderData
            }
        },
        groupedByCompany() {
            let listOfCompanies = {}
            if (this.filteredPayments.length) {
                for (let i = 0; i < this.filteredPayments.length; i++) {
                    if (Object.prototype.hasOwnProperty.call(listOfCompanies, this.filteredPayments[i].Company)) {
                        listOfCompanies[this.filteredPayments[i].Company].totalOwing += this.filteredPayments[i].Total_Owing
                        listOfCompanies[this.filteredPayments[i].Company].orders.push(this.filteredPayments[i])
                    } else {
                        listOfCompanies[this.filteredPayments[i].Company] = {
                            totalOwing: this.filteredPayments[i].Total_Owing,
                            orders: [this.filteredPayments[i]]
                        }
                    }
                }
                const sortable = Object.entries(listOfCompanies)
                    .sort(([,a],[,b]) => b.totalOwing-a.totalOwing)
                    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
                return sortable
            } else {
                return {}
            }
            
        },
        totalOwed() {
            return this.filteredPayments.reduce((sum, record) => {
                return sum + record.Total_Owing
            },0)
        }
    }
}
</script>

<style>

</style>