<template>
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card>
            <customTitle 
                :title="'Store Locator'" 
                :name="'storeLocator'" 
                @close="$emit('close')"/>
            <v-card-text>
                The Store Locator is a custom feature that allows Customers to find stores near them that have ordered Matador product
            </v-card-text>
            <v-card-text>
                There are three main components to the Store Locator:
                <ul>
                    <li>Address Input</li>
                    <li>Address Management</li>
                    <li>User Experience</li>
                </ul>
            </v-card-text>
            <v-card-title>
                Address Input
            </v-card-title>
            <v-card-text>
                The Address Input is a simple form that allows the B2B customer to input their address into a form that is SPECIFIC to them. If the url they are given/type in is incorrect, they will not be able to fill out the form.
                <v-card-subtitle class="grey--text">
                    For example, the account 'Wrill Southern Outdoors' will have the unique url of http://clearmanlabs.com/#/store-locator/form?id=2107 since their CIn7 customer Id is 2107. <br>
                    This also ties their orders to their stores so we can show what products they have ordered. 
                </v-card-subtitle>
                <ul>
                    <li>
                        Form Example (What the B2B customer sees)
                        <v-btn 
                            icon
                            x-small
                            color="primary" 
                            @click="showFormExample = !showFormExample">
                            <v-icon>
                                {{ showFormExample ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                            </v-icon>
                        </v-btn>
                    </li>
                    <li v-if="showFormExample">
                        <v-img
                            src="../src/Store-Locator-Form.png"
                            aspect-ratio="1.5"
                            contain
                            class="mx-auto"/>
                    </li>
                </ul>
            </v-card-text>
            <v-card-title>
                Address Management
            </v-card-title>
            <v-card-text>
                <ul>
                    <li>The management of every address is not centralized, but rather connected to each accounts Sandbox CRM page.</li>
                    <li>By clicking on the 'Addresses' button on the accounts <a href="/#/Sales/CRM/accounts/home" target="_blank">CRM page</a>, a pop up will display to manage the addresses.</li>
                    <li>You can delete and add new addresses from this pop up.</li>
                    <li>Deleting an address is done by right-clicking the entry and selecting 'Delete'.</li>
                    <li>If an address needs to be edited, the record should be deleted and a new one should be added.</li>
                    <li>
                        Address Management Example
                        <v-btn 
                            icon
                            x-small
                            color="primary" 
                            @click="showAddressManagementExample = !showAddressManagementExample">
                            <v-icon>
                                {{ showAddressManagementExample ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                            </v-icon>
                        </v-btn>
                    </li>
                    <ul  v-if="showAddressManagementExample">
                        <li>
                            <v-img
                                src="../src/Store-Locator-Address-Mgmt.png"
                                aspect-ratio="1.5"
                                contain
                                class="mx-auto"/>
                        </li>
                    </ul>
                </ul>
            </v-card-text>
            <v-card-title>
                User Experience
            </v-card-title>
            <v-card-text>
                <ul>
                    <li>Customers can view the store locator on the website <a href="https://www.matadorequipment.com/pages/matador-store-locator" target="_blank">here</a></li>
                    <li>When first visiting the page, it requests to access the browsers location</li>
                    <ul>
                        <li>If granted, results are organized by distance to the user.</li>
                        <li>If denied, results are organized by when they were entered. With the first entered being first.</li>
                        <li>To sort the results, the user have to enter a zip code (with the option to select a country)</li>
                    </ul>
                    <li>By clicking on 'Product(s) sold at this store' the user can see which skus the location has ordered in the past 6 months.</li>
                    <ul>
                        <li>Please note, for stores with multiple locations we do NOT filter by location, ONLY account. This level of tracking is beyond our current capabilities.</li>
                    </ul>
                    <li>It is possible for users to recieve an error when searching for zip codes, if the zip code, is invalid but that human error is out of my control</li>
                </ul>
            </v-card-text>
        </v-card>    
    </v-dialog>
</template>

<script>
import customTitle from './title.vue';
export default {
    name: 'StoreLocator',
    components: {
        customTitle
    },
    props: {
        dialog: Boolean
    },
    data() {
        return {
            showFormExample: false,
            showAddressManagementExample: false
        }
    },
    methods: {
        
    }
}
</script>

<style>

</style>