<template>
  <v-app>
      <v-main>
          <v-row justify="center" align="center">
            <v-card class="mt-8" width="80vw" height="80vh">
                <v-card-title class="d-flex justify-center align-center">No No No No No...</v-card-title>
                <v-img height="50vh" contain src="../../assets/noNo.jpg"/>
                <v-card-text class="d-flex justify-center align-center">
                    Looks like you do not have the correct permissions to access this part of the sandbox.<br>
                    Please contact the admin to grant you access to this part of the site
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn
                        color="primary"
                        to="/">
                        Return Home
                    </v-btn>
                </v-card-actions>
            </v-card>
          </v-row>
      </v-main>
  </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarTitle','Taboo...')
    },
}
</script>

<style>

</style>