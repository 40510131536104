<template>
    <v-app>
        <v-main>
            <v-row height="50vh" class="pt-6" justify="space-around">
                <payment-incoming
                    :orderData="incomingPayments"/>
                <overdue
                    :orderData="overduePayments"/>
            </v-row>
            <v-row class="pt-4" justify="center">
                <div style="width:25vw; position: fixed; bottom: 0; left: 0;"><iframe :src="gifEmbed" frameBorder="0"></iframe></div><p><a :href="gifUrl"></a></p>
                <total-owed
                    :orderData="allPayments"/>
                <!-- <v-btn v-if="loading" icon :loading="loading" style="position: fixed; bottom: 0; right: 0;" class="pr-4 pb-4">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn> -->
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        class="pr-4 pb-4"
                        style="position: fixed; bottom: 0; right: 0;" 
                        color="primary"
                        :loading="loading"
                        icon
                        v-bind="attrs"
                        v-on="on">
                        <v-icon large>mdi-cog</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>Settings</v-card-title>
                        <v-text-field
                            type="Number"
                            class="px-4"
                            prefix="$"
                            dense
                            v-model="margin"
                            label="Margin"/>
                    </v-card>
                </v-menu>
            </v-row>
            <v-snackbar
                v-model="snackActive"
                :color="snackColor"
                :timeout="snackTimeout">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
import Overdue from './overdue.vue'
import paymentIncoming from './paymentIncoming.vue'
import totalOwed from './totalOwed.vue'

export default {
    components:{
        Overdue,
        paymentIncoming,
        totalOwed
    },
    beforeMount() {
        document.title = 'Sandbox - Collectibles'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Collectibles')
        this.getCollectiblesData()
    },
    data() {
        return {
            orderData: [],
            loading: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            gifEmbed: '',
            gifUrl: '',
            margin: 1,
        }
    },
    methods: {
        getCollectiblesData() {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Collectibles_GetData?tab=code
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/collectibles'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.orderData = data.orderData
                this.gifEmbed = data.gifEmbed
                this.gifUrl = data.gifUrl

                this.loading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        incomingPayments() {
            let today = new Date()
            return this.orderData.filter(item => new Date(item.Payment_Date) >= today && item.Order_Total > 0 && item.Total_Owing > this.marginFormatted)
        },
        overduePayments() {
            let today = new Date()
            return this.orderData.filter(item => new Date(item.Payment_Date) < today && item.Order_Total > 0 && item.Total_Owing > this.marginFormatted)
        },
        allPayments() {
            return this.orderData.filter(item => item.Order_Total > 0 && item.Total_Owing > this.marginFormatted)
        },
        marginFormatted() {
            return Number(this.margin)
        },
    }
}
</script>

<style>

</style>