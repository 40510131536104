<template style="overflow-y: hidden;">
    
    <v-container class="pt-8">
        <!-- mobile version -->
        <v-row v-if="this.$vuetify.breakpoint.mobile" class="pt-12" justify="center" align="center" style="overflow-y: hidden; overflow-x: hidden;">
            <div class="flex-column">
                <v-row>
                    <GmapMap
                        :center="center"
                        :zoom="zoom"
                        :style="{width: '100%', height: '50vh'}"
                        :options="mapOptions">
                        <gmapInfoWindow 
                            @zoom_changed="zoom = $event"
                            :options="infoOptions" 
                            :position="infoWindowPos" 
                            :opened="infoWinOpen" 
                            @closeclick="infoWinOpen=false"/>
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="{lat: m.Lat, lng: m.Lng}"
                            :clickable="true"
                            :draggable="false"
                            @click="toggleInfoWindow(m)"/>
                    </GmapMap>
                </v-row> 
                <v-row>
                    <v-card class="pa-4" height="40vh" width="100%">
                        <v-text-field
                            v-model="zipCode"
                            :loading="loading"
                            v-on:keyup.enter="searchZipCode()"
                            label="Zip Code"
                            @click="clearZipCode()"
                            prepend-icon="mdi-map-marker"
                            @click:prepend="useCurrentLocation()"
                            hide-details>
                            <template v-slot:append>
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            color="black"
                                            dark
                                            v-on="on"
                                            :loading="loading"
                                            :disabled="loading"
                                            depressed>
                                            <span>{{ selectedCountry.code }}</span>
                                            <v-icon right>mdi-menu-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card height="75vw" class="overflow-y-auto">
                                        <v-text-field
                                            class="px-4"
                                            v-model="countrySearch"
                                            label="Search"
                                            hide-details
                                            prepend-icon="mdi-magnify"/>
                                        <v-list>
                                            <v-list-item
                                                v-for="(item, index) in countriesISO3166Filtered"
                                                :key="index"
                                                @click="clickCountry(item)">
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>                
                        <v-tabs v-model="tab">
                            <v-tab @click="zipCode=''">
                                All
                            </v-tab>
                            <v-tab>
                                Search
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab" style="overflow-y: scroll; height: 80%;">
                            <v-tab-item style="width: 100%;">
                                <v-list v-if="sortedMarkers.length">
                                    <v-list-item 
                                        v-for="(marker, index) in sortedMarkers" 
                                        :key="index" 
                                        @click="
                                            toggleInfoWindow(marker); 
                                            center = {lat: marker.Lat, lng: marker.Lng};
                                            zoom = 13"
                                        class="flex-column justify-start align-start text-wrap">
                                        <v-list-item-title>{{ marker.Name }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">{{ marker.Address }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-wrap">{{ marker.Details }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-wrap" v-if="marker.Phone">Phone: {{ marker.Phone }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-wrap">{{ (kilometers) ? marker.Distance.toFixed(2) + ' Kilometers': (marker.Distance * 0.621371).toFixed(2) + ' Miles'}} away</v-list-item-subtitle>
                                    </v-list-item>
                                </v-list>
                            </v-tab-item>
                            <v-tab-item style="width: 100%;">
                                <v-row class="pa-4 flex-column">
                                    <v-slider
                                        v-model="distance"
                                        hide-details
                                        :min="1"
                                        :max="250"
                                        color="primary"/>
                                    <v-text-field
                                        dense
                                        style="text-align:right"
                                        v-model="distance"
                                        hide-details
                                        type="number"
                                        :rules="[rules.min, rules.max]">
                                        <template v-slot:append>
                                            <v-btn
                                                x-small
                                                text
                                                @click="kilometers = !kilometers"
                                                :color="(kilometers) ? 'grey' : 'black'">
                                                Miles    
                                            </v-btn>
                                            <v-btn
                                                x-small
                                                text
                                                @click="kilometers = !kilometers"
                                                :color="(kilometers) ? 'black' : 'grey'">
                                                kilometers
                                            </v-btn>
                                        </template>
                                    </v-text-field>                    
                                    <v-list v-if="filteredMarkers.length">
                                        <v-list-item 
                                            v-for="(marker, index) in filteredMarkers" 
                                            :key="index" 
                                            @click="
                                                toggleInfoWindow(marker); 
                                                center = {lat: marker.Lat, lng: marker.Lng};
                                                zoom = 13"
                                            class="flex-column justify-start align-start text-wrap">
                                            <v-list-item-title>{{ marker.Name }}</v-list-item-title>
                                            <v-list-item-subtitle class="text-wrap">{{ marker.Address }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="text-wrap">{{ marker.Details }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="text-wrap">{{ (kilometers) ? marker.Distance.toFixed(2) + ' Kilometers': (marker.Distance * 0.621371).toFixed(2) + ' Miles'}} away</v-list-item-subtitle>
                                        </v-list-item>
                                    </v-list>
                                    <v-list v-else>
                                        <v-list-item>
                                            <v-list-item-subtitle class="text-wrap text-center">
                                                No locations match the current filters
                                            </v-list-item-subtitle>
                                        </v-list-item>
                                    </v-list>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-row>
            </div>
        </v-row>
        <!-- desktop version -->
        <v-row v-else class="pt-12" justify="center" align="center" style="overflow-y: hidden; overflow-x: hidden;">
            <v-col cols="9" class="pr-0 mr-0 pl-4">
                <GmapMap
                    :center="center"
                    :zoom="zoom"
                    :options="mapOptions"
                    @zoom_changed="zoom = $event"
                    style="height: 80vh">
                    <gmapInfoWindow 
                        :options="infoOptions" 
                        :position="infoWindowPos" 
                        :opened="infoWinOpen" 
                        @closeclick="infoWinOpen=false"/>
                    <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="{lat: m.Lat, lng: m.Lng}"
                        :clickable="true"
                        :draggable="false"
                        @click="toggleInfoWindow(m)"/>
                </GmapMap>
            </v-col>
            <v-col cols="3" class="pl-0 ml-0">
                <v-card class="pa-4" height="80vh" style="overflow-y:hidden;">
                    <v-text-field
                        v-model="zipCode"
                        :loading="loading"
                        v-on:keyup.enter="searchZipCode()"
                        label="Zip Code"
                        @click="clearZipCode()"
                        prepend-icon="mdi-map-marker"
                        @click:prepend="useCurrentLocation()"
                        hide-details>
                        <template v-slot:append>
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="black"
                                        dark
                                        v-on="on"
                                        depressed>
                                        <span>{{ selectedCountry.code }}</span>
                                        <v-icon right>mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-card height="75vw" class="overflow-y-auto">
                                    <v-text-field
                                        v-model="countrySearch"
                                        label="Search"
                                        hide-details
                                        prepend-icon="mdi-magnify"/>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in countriesISO3166Filtered"
                                            :key="index"
                                            @click="clickCountry(item)">
                                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-text-field>                
                    <v-tabs v-model="tab">
                        <v-tab @click="zipCode=''">
                            All
                        </v-tab>
                        <v-tab>
                            Search
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" style="overflow-y: scroll; height: 80%;">
                        <v-tab-item>
                            <v-list v-if="sortedMarkers.length">
                                <v-list-item 
                                    v-for="(marker, index) in sortedMarkers" 
                                    :key="index" 
                                    @click="
                                        toggleInfoWindow(marker); 
                                        center = {lat: marker.Lat, lng: marker.Lng};
                                        zoom = 13"
                                    class="flex-column justify-start align-start text-wrap">
                                    <v-list-item-title>{{ marker.Name }}</v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap">{{ marker.Address }}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-wrap">{{ marker.Details }}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-wrap">{{ (kilometers) ? marker.Distance.toFixed(2) + ' Kilometers': (marker.Distance * 0.621371).toFixed(2) + ' Miles'}} away</v-list-item-subtitle>
                                </v-list-item>
                            </v-list>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row class="pa-4 flex-column">
                                <v-slider
                                    v-model="distance"
                                    hide-details
                                    :min="1"
                                    :max="250"
                                    color="primary"/>
                                <v-text-field
                                    dense
                                    style="text-align:right"
                                    v-model="distance"
                                    hide-details
                                    type="number"
                                    :rules="[rules.min, rules.max]">
                                    <template v-slot:append>
                                        <v-btn
                                            x-small
                                            text
                                            @click="kilometers = !kilometers"
                                            :color="(kilometers) ? 'grey' : 'black'">
                                            Miles    
                                        </v-btn>
                                        <v-btn
                                            x-small
                                            text
                                            @click="kilometers = !kilometers"
                                            :color="(kilometers) ? 'black' : 'grey'">
                                            kilometers
                                        </v-btn>
                                    </template>
                                </v-text-field>                    
                                <v-list v-if="filteredMarkers.length">
                                    <v-list-item 
                                        v-for="(marker, index) in filteredMarkers" 
                                        :key="index" 
                                        @click="
                                            toggleInfoWindow(marker); 
                                            center = {lat: marker.Lat, lng: marker.Lng};
                                            zoom = 13"
                                        class="flex-column justify-start align-start text-wrap">
                                        <v-list-item-title>{{ marker.Name }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">{{ marker.Address }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-wrap">{{ marker.Details }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-wrap" v-if="marker.Phone">Phone: {{ marker.Phone }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-wrap">{{ (kilometers) ? marker.Distance.toFixed(2) + ' Kilometers': (marker.Distance * 0.621371).toFixed(2) + ' Miles'}} away</v-list-item-subtitle>
                                    </v-list-item>
                                </v-list>
                                <v-list v-else>
                                    <v-list-item>
                                        <v-list-item-subtitle class="text-wrap text-center">
                                            No locations match the current filters
                                        </v-list-item-subtitle>
                                    </v-list-item>
                                </v-list>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-row>
        
    </v-container>
</template>

<script>
export default {
    async beforeMount() {
        this.$store.dispatch('updateShowWaffle', false)
        document.title = 'Matador Store Locator'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Store Locator')
        await this.getInfo()
        await this.useCurrentLocation()
    },
    data() {
        return {
            markers: [],
            center: {
                lat: 40.018698, 
                lng: -105.245614
            },
            menu: false,
            infoWindowPos: null,
            searchLocation: {},
            infoWinOpen: false,
            currentMidx: null,
            tab: 0,
            countriesISO3166: [
                { name: "United States of America", code: "US" },
                { name: "United Kingdom of Great Britain and Northern Ireland", code: "GB" },
                { name: "Afghanistan", code: "AF" },
                { name: "Åland Islands", code: "AX" },
                { name: "Albania", code: "AL" },
                { name: "Algeria", code: "DZ" },
                { name: "American Samoa", code: "AS" },
                { name: "Andorra", code: "AD" },
                { name: "Angola", code: "AO" },
                { name: "Anguilla", code: "AI" },
                { name: "Antarctica", code: "AQ" },
                { name: "Antigua and Barbuda", code: "AG" },
                { name: "Argentina", code: "AR" },
                { name: "Armenia", code: "AM" },
                { name: "Aruba", code: "AW" },
                { name: "Australia", code: "AU" },
                { name: "Austria", code: "AT" },
                { name: "Azerbaijan", code: "AZ" },
                { name: "Bahamas", code: "BS" },
                { name: "Bahrain", code: "BH" },
                { name: "Bangladesh", code: "BD" },
                { name: "Barbados", code: "BB" },
                { name: "Belarus", code: "BY" },
                { name: "Belgium", code: "BE" },
                { name: "Belize", code: "BZ" },
                { name: "Benin", code: "BJ" },
                { name: "Bermuda", code: "BM" },
                { name: "Bhutan", code: "BT" },
                { name: "Bolivia (Plurinational State of)", code: "BO" },
                { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
                { name: "Bosnia and Herzegovina", code: "BA" },
                { name: "Botswana", code: "BW" },
                { name: "Bouvet Island", code: "BV" },
                { name: "Brazil", code: "BR" },
                { name: "British Indian Ocean Territory", code: "IO" },
                { name: "Brunei Darussalam", code: "BN" },
                { name: "Bulgaria", code: "BG" },
                { name: "Burkina Faso", code: "BF" },
                { name: "Burundi", code: "BI" },
                { name: "Cabo Verde", code: "CV" },
                { name: "Cambodia", code: "KH" },
                { name: "Cameroon", code: "CM" },
                { name: "Canada", code: "CA" },
                { name: "Cayman Islands", code: "KY" },
                { name: "Central African Republic", code: "CF" },
                { name: "Chad", code: "TD" },
                { name: "Chile", code: "CL" },
                { name: "China", code: "CN" },
                { name: "Christmas Island", code: "CX" },
                { name: "Cocos (Keeling) Islands", code: "CC" },
                { name: "Colombia", code: "CO" },
                { name: "Comoros", code: "KM" },
                { name: "Congo", code: "CG" },
                { name: "Congo (Democratic Republic of the)", code: "CD" },
                { name: "Cook Islands", code: "CK" },
                { name: "Costa Rica", code: "CR" },
                { name: "Côte d'Ivoire", code: "CI" },
                { name: "Croatia", code: "HR" },
                { name: "Cuba", code: "CU" },
                { name: "Curaçao", code: "CW" },
                { name: "Cyprus", code: "CY" },
                { name: "Czech Republic", code: "CZ" },
                { name: "Denmark", code: "DK" },
                { name: "Djibouti", code: "DJ" },
                { name: "Dominica", code: "DM" },
                { name: "Dominican Republic", code: "DO" },
                { name: "Ecuador", code: "EC" },
                { name: "Egypt", code: "EG" },
                { name: "El Salvador", code: "SV" },
                { name: "Equatorial Guinea", code: "GQ" },
                { name: "Eritrea", code: "ER" },
                { name: "Estonia", code: "EE" },
                { name: "Eswatini", code: "SZ" },
                { name: "Ethiopia", code: "ET" },
                { name: "Falkland Islands (Malvinas)", code: "FK" },
                { name: "Faroe Islands", code: "FO" },
                { name: "Fiji", code: "FJ" },
                { name: "Finland", code: "FI" },
                { name: "France", code: "FR" },
                { name: "French Guiana", code: "GF" },
                { name: "French Polynesia", code: "PF" },
                { name: "French Southern Territories", code: "TF" },
                { name: "Gabon", code: "GA" },
                { name: "Gambia", code: "GM" },
                { name: "Georgia", code: "GE" },
                { name: "Germany", code: "DE" },
                { name: "Ghana", code: "GH" },
                { name: "Gibraltar", code: "GI" },
                { name: "Greece", code: "GR" },
                { name: "Greenland", code: "GL" },
                { name: "Grenada", code: "GD" },
                { name: "Guadeloupe", code: "GP" },
                { name: "Guam", code: "GU" },
                { name: "Guatemala", code: "GT" },
                { name: "Guernsey", code: "GG" },
                { name: "Guinea", code: "GN" },
                { name: "Guinea-Bissau", code: "GW" },
                { name: "Guyana", code: "GY" },
                { name: "Haiti", code: "HT" },
                { name: "Heard Island and McDonald Islands", code: "HM" },
                { name: "Holy See", code: "VA" },
                { name: "Honduras", code: "HN" },
                { name: "Hong Kong", code: "HK" },
                { name: "Hungary", code: "HU" },
                { name: "Iceland", code: "IS" },
                { name: "India", code: "IN" },
                { name: "Indonesia", code: "ID" },
                { name: "Iran (Islamic Republic of)", code: "IR" },
                { name: "Iraq", code: "IQ" },
                { name: "Ireland", code: "IE" },
                { name: "Isle of Man", code: "IM" },
                { name: "Israel", code: "IL" },
                { name: "Italy", code: "IT" },
                { name: "Jamaica", code: "JM" },
                { name: "Japan", code: "JP" },
                { name: "Jersey", code: "JE" },
                { name: "Jordan", code: "JO" },
                { name: "Kazakhstan", code: "KZ" },
                { name: "Kenya", code: "KE" },
                { name: "Kiribati", code: "KI" },
                { name: "Korea (Democratic People's Republic of)", code: "KP" },
                { name: "Korea (Republic of)", code: "KR" },
                { name: "Kuwait", code: "KW" },
                { name: "Kyrgyzstan", code: "KG" },
                { name: "Lao People's Democratic Republic", code: "LA" },
                { name: "Latvia", code: "LV" },
                { name: "Lebanon", code: "LB" },
                { name: "Lesotho", code: "LS" },
                { name: "Liberia", code: "LR" },
                { name: "Libya", code: "LY" },
                { name: "Liechtenstein", code: "LI" },
                { name: "Lithuania", code: "LT" },
                { name: "Luxembourg", code: "LU" },
                { name: "Macao", code: "MO" },
                { name: "Macedonia (the former Yugoslav Republic of)", code: "MK" },
                { name: "Madagascar", code: "MG" },
                { name: "Malawi", code: "MW" },
                { name: "Malaysia", code: "MY" },
                { name: "Maldives", code: "MV" },
                { name: "Mali", code: "ML" },
                { name: "Malta", code: "MT" },
                { name: "Marshall Islands", code: "MH" },
                { name: "Martinique", code: "MQ" },
                { name: "Mauritania", code: "MR" },
                { name: "Mauritius", code: "MU" },
                { name: "Mayotte", code: "YT" },
                { name: "Mexico", code: "MX" },
                { name: "Micronesia (Federated States of)", code: "FM" },
                { name: "Moldova (Republic of)", code: "MD" },
                { name: "Monaco", code: "MC" },
                { name: "Mongolia", code: "MN" },
                { name: "Montenegro", code: "ME" },
                { name: "Montserrat", code: "MS" },
                { name: "Morocco", code: "MA" },
                { name: "Mozambique", code: "MZ" },
                { name: "Myanmar", code: "MM" },
                { name: "Namibia", code: "NA" },
                { name: "Nauru", code: "NR" },
                { name: "Nepal", code: "NP" },
                { name: "Netherlands", code: "NL" },
                { name: "New Caledonia", code: "NC" },
                { name: "New Zealand", code: "NZ" },
                { name: "Nicaragua", code: "NI" },
                { name: "Niger", code: "NE" },
                { name: "Nigeria", code: "NG" },
                { name: "Niue", code: "NU" },
                { name: "Norfolk Island", code: "NF" },
                { name: "Northern Mariana Islands", code: "MP" },
                { name: "Norway", code: "NO" },
                { name: "Oman", code: "OM" },
                { name: "Pakistan", code: "PK" },
                { name: "Palau", code: "PW" },
                { name: "Palestine, State of", code: "PS" },
                { name: "Panama", code: "PA" },
                { name: "Papua New Guinea", code: "PG" },
                { name: "Paraguay", code: "PY" },
                { name: "Peru", code: "PE" },
                { name: "Philippines", code: "PH" },
                { name: "Pitcairn", code: "PN" },
                { name: "Poland", code: "PL" },
                { name: "Portugal", code: "PT" },
                { name: "Puerto Rico", code: "PR" },
                { name: "Qatar", code: "QA" },
                { name: "Réunion", code: "RE" },
                { name: "Romania", code: "RO" },
                { name: "Russian Federation", code: "RU" },
                { name: "Rwanda", code: "RW" },
                { name: "Saint Barthélemy", code: "BL" },
                { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
                { name: "Saint Kitts and Nevis", code: "KN" },
                { name: "Saint Lucia", code: "LC" },
                { name: "Saint Martin (French part)", code: "MF" },
                { name: "Saint Pierre and Miquelon", code: "PM" },
                { name: "Saint Vincent and the Grenadines", code: "VC" },
                { name: "Samoa", code: "WS" },
                { name: "San Marino", code: "SM" },
                { name: "Sao Tome and Principe", code: "ST" },
                { name: "Saudi Arabia", code: "SA" },
                { name: "Senegal", code: "SN" },
                { name: "Serbia", code: "RS" },
                { name: "Seychelles", code: "SC" },
                { name: "Sierra Leone", code: "SL" },
                { name: "Singapore", code: "SG" },
                { name: "Sint Maarten (Dutch part)", code: "SX" },
                { name: "Slovakia", code: "SK" },
                { name: "Slovenia", code: "SI" },
                { name: "Solomon Islands", code: "SB" },
                { name: "Somalia", code: "SO" },
                { name: "South Africa", code: "ZA" },
                { name: "South Georgia and the South Sandwich Islands", code: "GS" },
                { name: "South Sudan", code: "SS" },
                { name: "Spain", code: "ES" },
                { name: "Sri Lanka", code: "LK" },
                { name: "Sudan", code: "SD" },
                { name: "Suriname", code: "SR" },
                { name: "Svalbard and Jan Mayen", code: "SJ" },
                { name: "Sweden", code: "SE" },
                { name: "Switzerland", code: "CH" },
                { name: "Syrian Arab Republic", code: "SY" },
                { name: "Taiwan", code: "TW" },
                { name: "Tajikistan", code: "TJ" },
                { name: "Tanzania, United Republic of", code: "TZ" },
                { name: "Thailand", code: "TH" },
                { name: "Timor-Leste", code: "TL" },
                { name: "Togo", code: "TG" },
                { name: "Tokelau", code: "TK" },
                { name: "Tonga", code: "TO" },
                { name: "Trinidad and Tobago", code: "TT" },
                { name: "Tunisia", code: "TN" },
                { name: "Turkey", code: "TR" },
                { name: "Turkmenistan", code: "TM" },
                { name: "Turks and Caicos Islands", code: "TC" },
                { name: "Tuvalu", code: "TV" },
                { name: "Uganda", code: "UG" },
                { name: "Ukraine", code: "UA" },
                { name: "United Arab Emirates", code: "AE" },
                { name: "United States Minor Outlying Islands", code: "UM" },
                { name: "Uruguay", code: "UY" },
                { name: "Uzbekistan", code: "UZ" },
                { name: "Vanuatu", code: "VU" },
                { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
                { name: "Viet Nam", code: "VN" },
                { name: "Virgin Islands (British)", code: "VG" },
                { name: "Virgin Islands (U.S.)", code: "VI" },
                { name: "Wallis and Futuna", code: "WF" },
                { name: "Western Sahara", code: "EH" },
                { name: "Yemen", code: "YE" },
                { name: "Zambia", code: "ZM" },
                { name: "Zimbabwe", code: "ZW" }
            ],
            selectedCountry: {code: 'US', name: 'United States of America'},
            countrySearch: '',
            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            rules: {
                min: value => value > 0 || '0 is the min',
                max: value => value <= 250 || '250 is the max',
            },
            zoom: 13,
            zipCode: '',
            distance: 50,
            kilometers: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_GetMarkers?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/get-markers?email=austin@matadorup.com`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    markers
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.markers = markers
                
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        toggleInfoWindow: function(marker) {
            this.infoWindowPos = {lat: marker.Lat, lng: marker.Lng}
            if (marker.Phone) {
                this.infoOptions.content = `<h4>${marker.Name}</h4><br/>
                    <div>${marker.Address}</div><br>
                    <div>${marker.Details}</div><br>
                    <div>Phone Number: ${marker.Phone}</div>`
            } else {
                this.infoOptions.content = `<h4>${marker.Name}</h4><br/>
                    <div>${marker.Address}</div><br>
                    <div>${marker.Details}</div>`
            }
            

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == marker.Id) {
              this.infoWinOpen = !this.infoWinOpen;
            } else {
            //if different marker set infowindow to open and reset current marker index
              this.infoWinOpen = true;
              this.currentMidx = marker.Id;
            }
        },
        clearZipCode() {
            if (this.zipCode === 'Your Location') {
                this.zipCode = ''
            }
        },
        async searchZipCode() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_FindZipCodeGeometry?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/find-zip-code-geometry?email=austin@matadorup.com&zip=${this.zipCode}&country=${this.selectedCountry.name}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    location
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.center = location

                if (this.filteredMarkers.length) {
                    this.center = {lat: this.filteredMarkers[0].Lat, lng: this.filteredMarkers[0].Lng}
                }
                
                this.tab = 1

                this.loading = false

                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async useCurrentLocation() {

            navigator.geolocation.getCurrentPosition(
            position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
                this.zipCode = 'Your Location'
                this.reverseGeocode()
            },
            error => {
                console.log(error.message)
                this.center = {
                    lat: 40.018698, 
                    lng: -105.245614
                }
            })
        },
        async reverseGeocode() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_ReverseGeocode?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/reverse-geocode?email=austin@matadorup.com`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            location: this.center
                        })
                    }
                )
                const {
                    message,
                    details
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                if ('results' in details) {
                    if ('address_components' in details.results[0]) {
                        for (let i = 0; i < details.results[0].address_components.length; i++) {
                            if (details.results[0].address_components[i].types.includes('country')) {
                                this.selectedCountry = this.countriesISO3166.find(item => item.code === details.results[0].address_components[i].short_name)
                            }
                        }
                    } else {
                        this.selectedCountry = this.countriesISO3166.find(item => item.code === 'US')
                    }
                } else {
                    this.selectedCountry = this.countriesISO3166.find(item => item.code === 'US')
                }

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        clickCountry(country) {
            this.selectedCountry = country
            this.menu = false
            if (this.zipCode !== 'Your Location') {
                this.searchZipCode()
            }
            
        },
        calculateDistance(lat1, lng1) {
            const R = 6371; // Radius of the Earth in kilometers
            const dLat = this.degToRad(this.center.lat - lat1);
            const dLng = this.degToRad(this.center.lng - lng1);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.degToRad(lat1)) * Math.cos(this.degToRad(this.center.lat)) *
                Math.sin(dLng / 2) * Math.sin(dLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = R * c; // Distance in kilometers
            return distance;
        },
        degToRad(degrees) {
            return degrees * (Math.PI / 180);
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        distanceMarkers() {
            let copy = JSON.parse(JSON.stringify(this.markers))
            return copy.map(obj => {
                obj.Distance = this.calculateDistance(obj.Lat, obj.Lng)
                return obj
            })
        },
        sortedMarkers() {
            let copy = JSON.parse(JSON.stringify(this.distanceMarkers))
            copy.sort((a,b) => {
                return a.Distance - b.Distance;
            })
            return copy
        },
        filteredMarkers() {
            let copy = JSON.parse(JSON.stringify(this.sortedMarkers))
            return copy.filter(item => item.Distance <= this.distance)
        },
        mapOptions() {
            return {
                zoomControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                styles: [{
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{
                        visibility: "off"
                    }]
                }]
            }
        },
        countriesISO3166Filtered() {
            if (this.countrySearch.length) {
                return this.countriesISO3166.filter(item => item.name.toLowerCase().includes(this.countrySearch.toLowerCase()))
            } else {
                return this.countriesISO3166
            }
        }
    }
}
</script>

<style>

</style>