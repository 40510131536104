<template>
    <v-card class="px-4 py-4" width="30vw">
        <v-card-title class="d-flex justify-center">Fix Orders From a given Account</v-card-title>
        <v-card-text>
            <v-row justify="center">
                <v-autocomplete
                    :items="allAccounts"
                    item-value="Id"
                    item-text="Name"
                    v-model="selectedAccount"
                    label="Select Account"/>
            </v-row>
            <v-row justify="center" align="center">
                All Orders
                <v-switch
                    class="px-2"
                    v-model="SpecificDateRangeToggle">
                </v-switch>
                Specific Date Range (Recommended)
            </v-row>
            <v-row justify="center" v-if="SpecificDateRangeToggle">
                <v-date-picker
                    range
                    v-model="dateRange"/>
            </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
            <v-btn
                color="primary"
                @click="fixOrders()"
                :loading="loading"
                :disabled="disableFixOrdersButton">
                Fix Orders
            </v-btn>
        </v-card-actions>
        <v-card-text class="d-flex justify-center" v-if="SpecificDateRangeToggle">
            {{ dateRangeLabel }}
        </v-card-text>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-card>
</template>

<script>
export default {
    beforeMount() {
        this.getAllAccounts()
    },
    watch: {

    },
    data() {
        return {
            loading: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            SpecificDateRangeToggle: true,
            dateRange: [],
            allAccounts: [],
            selectedAccount: '',
            contactIds: [],
        }
    },
    methods: {
        getAllAccounts() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/accounts'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.allAccounts = data.accounts
                this.contactIds = data.contactIds
                this.loading = false
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        fixOrders() {
            const url = `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/updateorders`
            let data = {}
            let contactIdCollection = this.contactIds.filter(item => item.accountId === this.selectedAccount)
            let contactIdArray = contactIdCollection.map(item => item.contactId)
            let contactIdString = contactIdArray.join(",")

            if (this.SpecificDateRangeToggle) {
                data = {
                    url: "https://api.cin7.com/api/v1/SalesOrders?where=memberId IN (" + contactIdString + ") AND CreatedDate>='" + this.sortedDates[0] + "T00:00:00Z' AND CreatedDate<='" + this.sortedDates[1] + "T00:00:00Z'&rows=250&page=",
                    email: this.$store.getters.getUserEmail
                }
            } else {
                data = {
                    url: "https://api.cin7.com/api/v1/SalesOrders?where=memberId IN (" + contactIdString + ")&rows=250&page=",
                    email: this.$store.getters.getUserEmail
                }
            }
            const request = {
                method: 'POST',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.loading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                console.log(error);
                this.snack('You will be emailed the result', 'info', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        disableFixOrdersButton() {
            if (this.selectedAccount !== null && this.selectedAccount !== "") {
                if (this.SpecificDateRangeToggle) {
                    if (this.dateRange && this.dateRange.length === 2) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        dateRangeLabel() {
            if (this.sortedDates && this.sortedDates.length > 0) {
                if (this.sortedDates.length === 2) {
                    return this.sortedDates[0] + " - " + this.sortedDates[1]
                } else if (this.sortedDates.length === 1) {
                    return this.sortedDates[0] + " - "
                } else {
                    return " - "
                }
            } else {
                return " - "
            }
        },
        sortedDates() {
            if (this.dateRange.length === 2) {
                let firstDate = new Date(Date.parse(this.dateRange[0]))
                let secondDate = new Date(Date.parse(this.dateRange[1]))

                if (firstDate > secondDate) {
                    return [this.dateRange[1], this.dateRange[0]]
                } else {
                    return [this.dateRange[0], this.dateRange[1]]
                }
            } else if (this.dateRange.length === 1) { 
                return [this.dateRange[0]]
            } else {
                return []
            }
        }
    }
}
</script>

<style>

</style>