<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
//import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData','refresh', 'explain', 'category', 'year', 'sku', 'compareSKUs'],
  components: {
    //ChartJSPluginDatalabels
  },
  data: () => ({
    skuByCompany: [],
    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }),
  mounted () {
    if (!localStorage.hiddenYears) {
      localStorage.hiddenYears = JSON.stringify({})
    }
    // Overwriting base render method with actual data.
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    refresh: function() {
      this.renderChart(this.chartData, this.options)
    },
    explain: async function() {
      if (this.explain) {
        await this.explainChart()
        setTimeout(() => {
          this.renderChart(this.chartData, this.options)
        }, 50)
      } else {
        this.skuByCompany = []
      }
    },
    // compareSKUs: function() {
    //   this.renderChart(this.chartData, this.options)
    // }
  },
  methods: {
    explainChart() {
      let skus = this.$props.compareSKUs
      skus.push(this.$props.sku)
			const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/chart/explain'
      const data = {
				category: this.category,
        year: this.year,
        skus: skus
			}
			const request = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			}
      this.$emit('input',true)
			fetch(url, request).then(async response => {
        const data = await response.json()
				if (!response.ok) {
					const error = (data && data.message) || response.status
					return Promise.reject(error)
				}
				this.skuByCompany = data.skuByCompany
        this.$emit('input',false)
			}).catch(error => {
				this.snack('Explaining Chart: ' + error, 'error', false)
			}) 
		},
  },
  computed: {
    options() {
      if (this.explain) {
        return {
          scales: {
            xAxes: [{
                gridLines: {
                  display: false
                }
            }],
            yAxes: [{
                gridLines: {
                  display: false
                },
                min: 0
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            onClick: function(event, element) {
              let hiddenYears = JSON.parse(localStorage.hiddenYears)
              let yearToToggle = element.text.split(':')[0]
              if (element.hidden) {
                delete hiddenYears[yearToToggle]
              } else {
                hiddenYears[yearToToggle] = true
              }
              localStorage.hiddenYears = JSON.stringify(hiddenYears)

              let index = element.datasetIndex
              this.chart.getDatasetMeta(index).hidden = !element.hidden
              
              this.chart.update()
            }
          },
          tooltips: {
            enabled: true,
            callbacks: {
              afterBody: ((tooltipItems, data) => {
                //for each item in the tooltip (normally only one)
                for (let i = 0; i < tooltipItems.length; i++) {
                  //create array to push companies and the qtys into
                  let append = []
                  //set the year based on the dataset legend label
                  let year = data.datasets[tooltipItems[i].datasetIndex].label.split(':')[0]
                  //set month by getting the tooltip items month and finding the position in monthNames and adding 1
                  let month = this.monthNames.indexOf(tooltipItems[i].label) + 1
                  //set sku given from the dataset.sku
                  let sku = data.datasets[tooltipItems[i].datasetIndex].sku
                  //filter the skuByCompany to get the items we want for this tooltip
                  let filter = this.skuByCompany.filter(item => item.Year == year && item.Month == month && item.SKU == sku)
                  //list over each item in the filter to add it to the append array that will be returned to the afterBody of the tooltip
                  for (let p = 0; p < filter.length; p++) {
                    let item = filter[p]
                    if (item.Active == 1) {
                      append.push('✓ ' + item.Company + ': ' + item.Qty)
                    } else {
                      append.push('x ' + item.Company + ': ' + item.Qty)
                    }
                    
                  }
                  return append
                }
              })
            }
          }
        }
      } else {
        return {
          scales: {
              xAxes: [{
                  gridLines: {
                    display: false
                  }
              }],
              yAxes: [{
                  gridLines: {
                    display: false
                  },
                  min: 0
              }]
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              onClick: function(event, element) {
                let hiddenYears = JSON.parse(localStorage.hiddenYears)
                let yearToToggle = element.text.split(':')[0]
                if (element.hidden) {
                  delete hiddenYears[yearToToggle]
                } else {
                  hiddenYears[yearToToggle] = true
                }
                localStorage.hiddenYears = JSON.stringify(hiddenYears)

                let index = element.datasetIndex
                this.chart.getDatasetMeta(index).hidden = !element.hidden
                
                this.chart.update()
              }
            },
            tooltips: {
              enabled: true,
            }
        }
      }
    }
  }
}
</script>