<template>
    <v-card class="px-4 py-4" width="30vw">
        <v-card-title class="d-flex justify-center">Fix Orders From a List of Orders Ids</v-card-title>
        Copy then Paste data from Excel
        <v-textarea
            outlined
            name="orderInput"
            v-model="orders"/>
        <v-card-actions class="d-flex justify-center">
            <v-btn
                :disabled="disableFixOrdersButton"
                @click="batchFixOrders()"
                :loading="loading"
                color="primary">
                Fix Orders
            </v-btn>
        </v-card-actions>
        <v-card-text class="pt-0 d-flex justify-center">
            {{ orderCount }}
        </v-card-text>
        <v-row v-if="bigBatch" class="flex-column" align="center" justify="start">
                <v-progress-circular 
                    :value="bigBatchProgress" 
                    :rotate="-90"
                    color="primary"
                    class="pr-2"/>

                <div>Oh... we have a big boy here...</div>

                <div>{{ bigBatchCounter }} / {{ bigBatchTotal }}</div>
        </v-row>
        <v-card-text v-if="stringInOrderIds && orders.length" class="red--text d-flex justify-center">
            Order Ids Can Only Have Numbers
        </v-card-text>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            loading: false,
            orders: "",
            bigBatch: false,
            bigBatchCounter: 0,
            bigBatchTotal: 0,
        }
    },
    methods: {
        async fixOrders(orders) {
            //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/MySQL_UpdateSalesOrders
            const url = `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/updateorders`
            let data = {
                url: "https://api.cin7.com/api/v1/SalesOrders?where=id IN (" + orders.join(",") + ")&rows=250&page=",
                email: this.$store.getters.getUserEmail
            }

            const request = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            try {
                const response = await fetch(url, request) 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = data.message || response.status
                    return Promise.reject(error)
                }

                this.snack('Complete', 'success', 3000)
                this.loading = false
            } catch(error) {
                this.loading = false
                console.log(error);
                this.snack('You will be emailed the result', 'info', 3000)
            }
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
        async batchFixOrders() {
            try {
                this.orders = this.orders.trim()
                let orders = this.orders.split(/\r?\n/).map(item => {
                        return parseInt(item, 10)
                    })
                let count = 1
                console.log(orders);
                if (orders.length > 250) {
                    this.bigBatch = true
                    this.bigBatchCounter = 1
                    this.bigBatchTotal = Math.ceil(orders.length / 250)
                    let batch = orders.slice(0, 249)
                    while (this.bigBatchCounter <= this.bigBatchTotal) {
                        batch = orders.slice((count - 1) * 249, count * 249)
                        console.log(batch[0] + " : " + batch[batch.length - 1])
                        console.log(batch.length)
                        count++
                        if (batch.length > 0) {
                            await this.fixOrders(batch)
                            this.bigBatchCounter++
                        } else {
                            this.bigBatch = false
                            this.bigBatchCounter = 1
                            this.bigBatchTotal = 1
                        }
                    }
                    this.bigBatch = false
                    this.bigBatchCounter = 1
                    this.bigBatchTotal = 1
                } else {
                    await this.fixOrders(orders)
                }
            } catch (error) {
                this.snack(error, 'error', 3000)
                this.bigBatch = false
            }
        }
    },
    computed: {
        disableFixOrdersButton() {
            if (this.orders.length >= 5 && !this.stringInOrderIds) {
                return false
            } else {
                return true
            }
        },
        orderCount() {
            return "Order Count: " + this.orders.split(/\r?\n/).length
        },
        stringInOrderIds() {
            let orderCheck = this.orders.split(/(?:\r\n|\r|\n)/g).join("")
            if (!/^\d+$/.test(orderCheck)) {
                return true
            } else {
                return false
            }
        },
        bigBatchProgress() {
            return this.bigBatchCounter / this.bigBatchTotal * 100
        }
    }
}
</script>

<style>

</style>