<template>
  <v-app>
    <v-main class="d-flex align-center justify-center">
      <v-card width="50vw" height="50vh" class="mx-auto my-auto" :loading="loading">
        <v-menu 
          max-width="10vw"
          v-model="usernameMenu"
          :close-on-content-click="false"
          absolute
          offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-card-title v-bind="attrs" v-on="on" class="justify-center">
              <v-icon color="black">
                mdi-account-edit-outline
              </v-icon>
              {{ $store.getters.getUsername }}
            </v-card-title>
          </template>
          <v-card justify-center width="10vw">
            <v-card-text>
              <v-text-field
                v-model="newUsername"/>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-around">
              <v-btn
                color="accent"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="updateUsername()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-row>
          <v-col>
            <v-card-title class="d-flex align-center justify-center">Update Password</v-card-title>
            <v-row justify="center">
              <v-text-field
                class="px-16"
                label="Old Password"
                v-model="oldPassword"/>
            </v-row>
            <v-row justify="center">
              <v-text-field
                class="px-16"
                label="New Password"
                v-model="newPassword"/>
            </v-row>
            <v-row justify="center">
              <v-btn
                :disabled="!newPassword.length || !oldPassword.length"
                @click="updatePassword()"
                color="primary">
                Change
              </v-btn>
            </v-row>
          </v-col>
          <v-col>
            <v-card-title class="d-flex align-center justify-center">Color Preference</v-card-title>
            <color-preference v-model="loading"/>
          </v-col>
        </v-row>
      </v-card>
      <v-snackbar
        v-model="snack"
        :timeout="snackTimeout"
        :color="snackColor">
        <!-- the text to display during the notification -->
        {{ snackText }}
    </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import ColorPreference from './colorPreference.vue'

export default {
  beforeMount() {
    document.title = 'Sandbox - User Info'
    this.$store.dispatch('updateNavbarTitle','User Info')
    this.$store.dispatch('updateNavbarContent', [])
    this.$store.dispatch('updateShowWaffle', true)
  },
  components: {
    ColorPreference,
  },
  data() {
    return {
      usernameMenu: false,
      newUsername: this.$store.getters.getUsername,
      loading: false,
      oldPassword: '',
      newPassword: '',
      snack: false,
      snackText: '',
      snackColor: '',
      snackTimeout: 3000,
    }
  },
  methods: {
    updatePassword() {
      const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/admin/user/password'
      //set the data that the lamdba function will need
      const data = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        id: this.$store.getters.getUserId
      }
      const request = {
        method: 'PUT',
        body:JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
      //start loading
      this.loading = true
      fetch(url, request).then(async response => { 
        const data = await response.json()
      
        if (!response.ok) {
          const error = data.response
          return Promise.reject(error)
        }
        //communicate the update to user
        this.snackBar(true, data.response, 'success', 3000)
        //stop loading
        this.loading = false
        //reset old password field
        this.oldPassword = ''
        //reset new password field
        this.newPassword = ''
      }).catch(error => {
        //communicate error to user
        this.snackBar(true, error,'error',3000)
        //stop loading
        this.loading = false
      })
    },
    updateUsername() {
      //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_Login?tab=code
      const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/admin/user/password'
      const data = {
        id: this.$store.getters.getUserId,
        username: this.newUsername,
      }
      const request = {
        method: 'PUT',
        body:JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
      this.loading = true
      fetch(url, request).then(async response => { 
        const data = await response.json()
      
        if (!response.ok) {
          const error = data.response || response.status
          return Promise.reject(error)
        }
        //update user info from the API call
        this.$store.dispatch('updateUser', data.user[0])
        //store the same information in the broswer data to be used next time the user visits the site
        localStorage.user = JSON.stringify(data.user[0])

        this.snackBar(true, data.response, 'success', 3000)
        //stop loading
        this.loading = false
        //close popup
        this.usernameMenu = false
      }).catch(error => {
        this.snackBar(true, error,'error',3000)
        this.loading = false
      })
    },
    snackBar(active, text, color, timeout) {
      this.snack = active
      this.snackColor = color
      this.snackText = text
      this.snackTimeout = timeout
    }
  }
}
</script>

<style>

</style>