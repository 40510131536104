var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',{staticClass:"d-flex justify-center align-center"},[_c('v-row',{attrs:{"justify":"space-around","align":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.refreshAllBranchTransfers()}}},[_vm._v(" Get Most Recent Branch Transfers ")]),_c('v-switch',{attrs:{"label":"Show Only Empty ETA","color":"primary"},model:{value:(_vm.filters.onlyEmptyETA),callback:function ($$v) {_vm.$set(_vm.filters, "onlyEmptyETA", $$v)},expression:"filters.onlyEmptyETA"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateAllETAs()}}},[_vm._v(" Get All ETA ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.branchTransferHeaders,"items":_vm.filteredBranchTransfers,"single-expand":false,"expanded":_vm.expanded,"search":_vm.search,"item-key":"Id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.lineItemHeaders,"items":_vm.getBranchTransferItems(item.Id)}})],1)]}},{key:"item.Reference",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://go.cin7.com/Cloud/TransactionEntry/TransactionEntry.aspx?idCustomerAppsLink=985828&OrderId=' + item.Id}},[_vm._v(_vm._s(item.Reference))])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.refreshBranchTransfer(item.Id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-refresh")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true),model:{value:(item.deleteConfirmation),callback:function ($$v) {_vm.$set(item, "deleteConfirmation", $$v)},expression:"item.deleteConfirmation"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"d-flex justify-center align-center"},[_vm._v(" Are you sure you want to delete? ")]),_c('v-card-subtitle',{staticClass:"d-flex justify-center align-center"},[_vm._v(" This action cannot be undone ")]),_c('v-card-actions',{staticClass:"d-flex justify-space-around align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary"},on:{"click":function($event){item.deleteConfirmation = false}}},[_vm._v(" No... I Regret My Actions ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){_vm.deleteBranchTransfer(item.Id); item.deleteConfirmation = false;}}},[_vm._v(" Send it! ")])],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.updateAllETAs(item.Id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-calendar-clock")])],1)]}}],null,true)},[_c('span',[_vm._v("Update ETA")])])]}},{key:"item.Is_Approved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.draft(item.Is_Approved))+" ")]}},{key:"item.ETA",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"return-value":_vm.etaDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.etaDate=$event},"update:return-value":function($event){_vm.etaDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({on:{"click":function($event){return _vm.setETADate(item.ETA)}}},'td',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatETA(item.ETA))+" ")])]}}],null,true),model:{value:(item.etaDateActive),callback:function ($$v) {_vm.$set(item, "etaDateActive", $$v)},expression:"item.etaDateActive"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.etaDate),callback:function ($$v) {_vm.etaDate=$$v},expression:"etaDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){item.etaDateActive = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.updateETA(_vm.etaDate, item)}}},[_vm._v(" OK ")])],1)],1)]}},{key:"item.Product_Total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.Product_Total))+" ")]}},{key:"item.Source_Branch_Id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatBranch(item.Source_Branch_Id))+" ")]}},{key:"item.Destination_Branch_Id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatBranch(item.Destination_Branch_Id))+" ")]}},{key:"item.Approval_Date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatApprovalDate(item.Approval_Date))+" ")]}},{key:"item.Dispatch_Date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDispatchDate(item.Dispatch_Date))+" ")]}},{key:"item.Received_Date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatReceivedDate(item.Received_Date))+" ")]}}],null,true)}),_c('v-snackbar',{attrs:{"color":_vm.snackColor,"timeout":_vm.snackTimeout},model:{value:(_vm.snackActive),callback:function ($$v) {_vm.snackActive=$$v},expression:"snackActive"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }