<template>
    <v-card style="overflow-y: scroll; overflow-x: hidden;" height="45vh" width="48vw">
        <v-card-title>
            <v-row justify="center" align="center">
                <v-col>
                    <span class="green--text my-0 py-0">Incoming Payments - {{ totalIncoming | currency }}</span>
                    <v-card-subtitle class="my-0 py-0">
                        {{ Object.keys(groupedByCompany).length }} Companies and {{ filteredPayments.length }} Orders
                    </v-card-subtitle>
                </v-col>
                <v-col md="auto">
                    Next
                </v-col>
                <v-col cols="1">
                    <v-text-field v-model="numberOfDateType" type="number"/>
                </v-col>
                <v-col cols="2">
                    <v-select
                        :items="dateTypes"
                        v-model="dateTypeSelected"/>
                </v-col>
            </v-row>
        </v-card-title>
        <v-expansion-panels accordion>
            <v-row justify="start" class="py-0">
                <v-col class="pl-4 pr-0">
                    <v-card-title class="py-0">Company</v-card-title>
                </v-col>
                <v-col class="pl-0">
                    <v-card-title class="py-0 pl-0">Total Incoming</v-card-title>
                </v-col>
            </v-row>
            <v-expansion-panel
                v-for="(value, propertyName, index) in groupedByCompany"
                :key="index">
                <v-expansion-panel-header>
                    <v-row>
                        <v-col>
                            {{ propertyName }} 
                        </v-col>
                        <v-col>
                            {{ value.totalOwing | currency }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Order Ref
                                    </th>
                                    <th class="text-left">
                                        Invoice Date
                                    </th>
                                    <th class="text-left">
                                        Payment Date
                                    </th>
                                    <th class="text-left">
                                        Days Left To Pay
                                    </th>
                                    <th class="text-left">
                                        Current Payment Amount
                                    </th>
                                    <th class="text-left">
                                        Order Total
                                    </th>
                                    <th class="text-left">
                                        Total Owing
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in value.orders"
                                :key="item.Order_Id">
                                    <td><a :href='salesOrderUrl(item.Order_Id)' target="_blank">{{ item.Order_Ref }}</a></td>
                                    <td>{{ formatDate(item.Invoice_Date) }}</td>
                                    <td>{{ formatDate(item.Payment_Date) }}</td>
                                    <td>{{ daysLeft(item.Payment_Date)}}</td>
                                    <td>{{ item.Payment | currency }}</td>
                                    <td>{{ item.Order_Total | currency }}</td>
                                    <td>{{ item.Total_Owing | currency }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>

export default {
    props: {
        orderData: {type: Array}
    },
    data() {
        return {
            dateTypeSelected: 'Months',
            dateTypes: ['Days','Months','Years'],
            numberOfDateType: 1,
        }
    },
    methods: {
        salesOrderUrl(id) {
            return 'https://go.cin7.com/Cloud/TransactionEntry/TransactionEntry.aspx?idCustomerAppsLink=985806&OrderId=' + id
        },
        daysLeft(paymentDate) {
            const date1 = new Date();
            const date2 = new Date(paymentDate);
            const diffTime = Math.abs(date2 - date1);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        },
        formatDate(date) {
            if (!date) {
                return 'Not Invoiced'
            } else {
                let inputDate = new Date(date)
                return inputDate.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'}) 
            }
        }
    },
    computed: {
        filteredPayments() {
            let setDate = new Date()
            if (this.dateTypeSelected === 'Days') {
                setDate.setDate(setDate.getDate() + Number(this.numberOfDateType))
                return this.$props.orderData.filter(item => new Date(item.Payment_Date) < setDate)
            } else if (this.dateTypeSelected === 'Months') {
                setDate.setMonth(setDate.getMonth() + Number(this.numberOfDateType))
                return this.$props.orderData.filter(item => new Date(item.Payment_Date) < setDate)
            } else if (this.dateTypeSelected === 'Years') {
                setDate.setFullYear(setDate.getFullYear() + Number(this.numberOfDateType))
                return this.$props.orderData.filter(item => new Date(item.Payment_Date) < setDate)
            } else {
                return this.$props.orderData
            }
        },
        groupedByCompany() {
            let listOfCompanies = {}
            if (this.filteredPayments.length) {
                for (let i = 0; i < this.filteredPayments.length; i++) {
                    if (Object.prototype.hasOwnProperty.call(listOfCompanies, this.filteredPayments[i].Company)) {
                        listOfCompanies[this.filteredPayments[i].Company].totalOwing += this.filteredPayments[i].Total_Owing
                        listOfCompanies[this.filteredPayments[i].Company].orders.push(this.filteredPayments[i])
                    } else {
                        listOfCompanies[this.filteredPayments[i].Company] = {
                            totalOwing: this.filteredPayments[i].Total_Owing,
                            orders: [this.filteredPayments[i]]
                        }
                    }
                }
                const sortable = Object.entries(listOfCompanies)
                    .sort(([,a],[,b]) => b.totalOwing-a.totalOwing)
                    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
                return sortable
            } else {
                return {}
            }
            
        },
        totalIncoming() {
            return this.filteredPayments.reduce((sum, record) => {
                return sum + record.Total_Owing
            },0)
        }
    },
}
</script>

<style>

</style>