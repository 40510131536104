<template>
    <v-dialog 
        v-model="dialog" 
        width="90vw" 
        height="75vh"
        @click:outside="$emit('contact', 0)">
        <v-card class="pa-4" width="90vw" height="75vh" style="overflow-y: auto;">
            <v-card-title v-if="!products.length && !loading">
                No products have been delivered to this store in the past 6 months.
            </v-card-title>
            <v-card-title class="d-flex justify-center align-center" v-else-if="!products.length && loading">
                <v-progress-circular indeterminate />
            </v-card-title>
            <!-- have title centered with the close button in the upper right -->
            <v-card-title v-else class="d-flex justify-center align-center">
                <h2>Products</h2>
                <v-card-subtitle class="text-center">Ordered in the past 6 months</v-card-subtitle>
            </v-card-title>
            <v-list>
                <v-list-item-group>
                    <v-list-item v-for="(product, index) in products" :key="index">
                        <v-list-item-content>
                            <v-list-item-title>{{ product.Name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    props: {
        contact: {
            type: Number,
            required: true
        }
    },
    watch: {
        contact: async function() {
            if (this.$props.contact !== 0) {
                this.dialog = true
                await this.getProducts()
            }
        }
    },
    data() {
        return {
            dialog: false,
            products: [],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getProducts() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_GetProducts?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/products?id=${this.$props.contact}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    products
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.products = products
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    }
}
</script>

<style>

</style>