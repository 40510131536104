<template>
    <div class="flex-column justify-center align-center">
      <div class="d-flex justify-center align-center">
        The expected {{ title }} for 
        <strong class="px-1">{{ monthName }} {{ year }}</strong> is 
        <strong class="px-1">{{ list }}</strong>
      </div><br>
      <div class="d-flex justify-center align-center">
        Please add a {{ nameType }} to 
        <strong class="px-1">{{ name }}</strong> 
        below if you wish to forecast this product.
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      list: String,
      monthName: String,
      year: Number,
      name: String,
      title: String,
    },
    computed: {
      nameType() {
        return this.title.includes('Cost') ? 'cost' : 'price';
      },
    },
  };
  </script>