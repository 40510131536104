<template>
    <v-app>
        <v-main>
            <v-row class="px-2 pt-4" justify="space-around" align="start">
                <v-col cols="3">
                    <v-card class="elevation-3 pa-4 overflow-y-auto" height="24vh">
                        <v-btn color="primary" class="mr-2" width="45%">
                            <download-csv
                                class   = "btn btn-default"
                                :data   = "tableData"
                                :name    = "'Inventory_Report_' + unitType + '.csv'">
                                Export Report
                            </download-csv>
                        </v-btn>
                        <v-btn
                            width="45%"
                            class="ml-2"
                            color="primary"
                            @click="getData()">
                            Refresh Report
                        </v-btn>
                        <v-container fluid class="pb-0">
                            <p>Value Type:</p>
                            <v-radio-group v-model="showQuantity">
                                <v-radio :value="true" label="Quantity"/>
                                <v-radio :value="false" label="Dollars"/>
                            </v-radio-group>
                        </v-container>
                        <v-btn 
                            width="45%"
                            class="mr-2"
                            color="secondary" 
                            @click="refreshCin7Costs()" 
                            :loading="cin7Loading">
                            Refresh Cin7 Prices
                        </v-btn>
                        <v-btn 
                            width="45%"
                            class="ml-2"
                            color="secondary" 
                            @click="refreshStock()" 
                            :loading="stockLoading">
                            Refresh Stock
                        </v-btn>
                        </v-card>
                        <v-card class="elevation-3 mt-2 overflow-y-auto" height="65vh" width="100%">
                            <v-card-title class="d-flex justify-center align-center">
                                Missing Costs
                            </v-card-title>
                            <v-card-subtitle class="text-center">
                                It is recommended to export skus and bulk update them in settings instead of fixing values individually here. The report has to be recalculated everytime a cost is changed.
                            </v-card-subtitle>
                            <v-expansion-panels>
                                <v-expansion-panel v-for="(item, key, index) in costWarnings" :key="index">
                                    <v-expansion-panel-header>
                                        <v-row align="center" justify="center">
                                            <v-col>
                                                {{ key }}
                                            </v-col>
                                            <v-spacer/>
                                            <v-col v-if="item.null.length > 0">
                                                <v-row align="center">
                                                    <span>{{ item.null.length }}</span><v-icon color="yellow">mdi-alert-circle</v-icon>
                                                </v-row>
                                            </v-col>  
                                            <v-col v-if="item.zero.length > 0">  
                                                <v-row align="center">
                                                    <span>{{ item.zero.length }}</span><v-icon color="orange">mdi-alert-circle</v-icon>
                                                </v-row>
                                            </v-col>
                                            <v-spacer/>
                                        </v-row>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-expansion-panels>
                                            <v-expansion-panel v-if="item.null.length > 0">
                                                <v-expansion-panel-header>
                                                    <v-row align="center">
                                                        <span>{{ item.null.length }}</span>
                                                        <v-icon color="yellow">mdi-alert-circle</v-icon>
                                                        <v-spacer/>
                                                        <span>Null Costs</span>
                                                        <v-spacer/>
                                                        <v-btn color="primary">
                                                            <download-csv
                                                                class   = "btn btn-default"
                                                                :data   = "exportSkus(item.null)"
                                                                :name    = "key + '_Null_Costs.csv'">
                                                                Export SKUs
                                                            </download-csv>
                                                        </v-btn>
                                                        <v-spacer/>
                                                    </v-row>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row v-for="(product, index1) in item.null" :key="index1" align="center" class="py-1">
                                                        <v-col cols="6">
                                                            {{ product.sku }}
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field
                                                                hide-details
                                                                @change="updateCost(key, product.sku, product.cost)"
                                                                v-model="product.cost"
                                                                lable="Cost"/>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                            <v-expansion-panel v-if="item.zero.length > 0">
                                                <v-expansion-panel-header>
                                                    <v-row align="center">
                                                        <span>{{ item.zero.length }}</span>
                                                        <v-icon color="orange">mdi-alert-circle</v-icon>
                                                        <v-spacer/>
                                                        <span>Zero Costs</span>
                                                        <v-spacer/>
                                                        <v-btn color="primary">
                                                            <download-csv
                                                                class   = "btn btn-default"
                                                                :data   = "exportSkus(item.zero)"
                                                                :name    = "key + '_Null_Costs.csv'">
                                                                Export SKUs
                                                            </download-csv>
                                                        </v-btn>    
                                                        <v-spacer/>
                                                    </v-row>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-row v-for="(product, index1) in item.zero" :key="index1" align="center" class="py-1">
                                                        <v-col cols="6">
                                                            {{ product.sku }}
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field
                                                                hide-details
                                                                v-model="product.cost"
                                                                @change="updateCost(key, product.sku, product.cost)"
                                                                lable="Cost"/>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                </v-col>
                <v-col cols="9">
                    <v-card class="elevation-3 overflow-y-auto pa-4" height="90vh">
                        <v-data-table
                            :disable-pagination="true"
                            hide-default-footer
                            :headers="tableHeaders"
                            :items="tableData">
                            <template v-for="(header, index) in tableHeaders.filter(headers => headers.format === 'currency')" v-slot:[`item.${header.text}`]="{ item }">
                                <span v-if="!showQuantity" :key="index + 'normal'">
                                    {{ item[header.text] | currency }} 
                                </span>
                                <v-menu
                                    v-else
                                    :key="index + 'quantity'"
                                    offset-y 
                                    :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span 
                                            :key="index"
                                            v-bind="attrs"
                                            v-on="on">
                                            {{ item[header.text] }} 
                                        </span>
                                    </template>
                                    <v-card class="pa-4">
                                        <v-card-title class="d-flex justify-center align-center">
                                            Change Quantity for<br/>{{ item.SKU }}
                                        </v-card-title>
                                        <v-text-field
                                            type="Number"
                                            v-model="item[header.text]"
                                            label="Qty"/>
                                        <v-card-actions>
                                            <v-btn
                                                :loading="loading"
                                                color="primary"
                                                @click="updateQuantity(header.text,item.SKU,item[header.text])">
                                                Update
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                                
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>

export default {
    beforeMount() {
        this.$store.dispatch("updateShowWaffle", true);
        document.title = "Sandbox - Inventory Report";
        this.$store.dispatch("updateNavbarContent", [
            { title: "Report", path: "/Ops/InventoryReport/Home", icon: "mdi-home", notification: false },
            { title: "Purchases", path: "/Ops/InventoryReport/Purchases", icon: "mdi-currency-usd", notification: false },
            { title: "Settings", path: "/Ops/InventoryReport/Settings", icon: "mdi-cog", notification: false },
            { title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ]);
        this.$store.dispatch("updateNavbarTitle", "Inventory Report");
        this.getData();
    },
    data() {
        return {
            data: [],
            holdingData: [],
            allProducts: [],
            allCosts: [],
            allCostCategories: [],
            showQuantity: false,
            stockLoading: false,
            cin7Loading: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getData() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_GetData?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    stock,
                    holding,
                    allProducts,
                    allCosts,
                    allCostCategories
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.holdingData = holding
                this.data = stock
                this.allProducts = allProducts
                this.allCosts = allCosts
                this.allCostCategories = allCostCategories
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async refreshStock() {
            try {
                this.stockLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Cin7_GetStock?tab=code
                    'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/stock',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.stockLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loadstockLoadinging = false
                this.snack(error,'error',3000)
            }
        },
        async refreshCin7Costs() {
            try {
                this.cin7Loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_RefreshCin7Prices?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/costs',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.snack(message,'success',3000)
                await this.getData()
                this.cin7Loading = false
            } catch (error) {
                this.cin7Loading = false
                this.snack(error,'error',3000)
            }
        },
        csvExport() {
            return this.tableData
        },
        exportSkus(object) {
            let skus = object.map(item => item.sku)
            let final = []
            for (let i = 0; i < skus.length; i++) {
                final.push({SKU: skus[i], Cost: 0})
            }
            return final
        },
        async updateCost(costCategoryName, sku, cost) {
            let costId = this.allCostCategories.find(item => item.Name === costCategoryName)['Cost ID']
            
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_UpdateSingleCost?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/costs/updatesinglecost',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            costId: costId,
                            sku: sku,
                            cost: cost
                        })
                    }
                )
                const {
                    message
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                await this.getData()
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateQuantity(branch, sku, quantity) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_FixStock?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/fix',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            branch: branch,
                            sku: sku,
                            quantity: quantity
                        })
                    }
                )
                const {
                    message
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                let data = this.data.find(item => item.Classification === branch && item.SKU === sku)
                data.SOH = Number(quantity)
                data.SOH_Value = Number(quantity) * data.Cost
                this.snack(message,'success',3000)
                this.loading = false
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        tableData() {
            let data = []
            //find totals
            let sohValue = ''
            let holdingValue = ''
            if (this.showQuantity) {
                sohValue = 'SOH'
                holdingValue = 'Holding'
            } else {
                sohValue = 'SOH_Value'
                holdingValue = 'Holding_Value'
            }
            let holdingTotal = this.holdingData.reduce((sum, record) => {
                return sum + record[holdingValue]
            },0)
            let grandTotal = holdingTotal
            let temp = {
                SKU: 'Totals',
                Holding: Math.round(holdingTotal * 100) / 100 
            }
            for (let i = 0; i < this.allCategories.length; i++) {
                let categoryTotal = this.data.reduce((sum, record) => {
                    if (record.Classification === this.allCategories[i]) {
                        return sum + record[sohValue]
                    } else {
                        return sum
                    }
                }, 0)
                grandTotal += categoryTotal
                temp[this.allCategories[i]] = Math.round(categoryTotal * 100) / 100
            }
            temp.Totals = Math.round(grandTotal * 100) / 100
            data.push(temp)
            //end finding totals

            let allData = this.data.concat(this.holdingData)
            let products = [... new Set(allData.map(item => item.SKU))]
            for (let i = 0; i < products.length; i++) {
                let holding = this.holdingData.reduce((sum, record) => {
                    if (record.SKU === products[i]) {
                        return sum + record[holdingValue]
                    } else {
                        return sum
                    }
                },0)
                let temp = {
                    SKU: products[i],
                    Holding: holding
                }
                grandTotal = holding
                for (let c = 0; c < this.allCategories.length; c++) {
                    let categoryTotal = this.data.reduce((sum, record) => {
                        if (record.SKU === products[i] && record.Classification === this.allCategories[c]) {
                            return sum + record[sohValue]
                        } else {
                            return sum
                        }
                    },0)
                    grandTotal += categoryTotal
                    temp[this.allCategories[c]] = Math.round(categoryTotal * 100) / 100
                }
                temp.Totals = Math.round(grandTotal * 100) / 100
                data.push(temp)
            }
            return data
        },
        tableHeaders() {
            let headers = [
                {
                    text: 'SKU',
                    align: 'start',
                    value: 'SKU',
                    format: 'text'
                }
            ]
            for (let i = 0; i < this.allCategories.length; i++) {
                headers.push({
                    text: this.allCategories[i],
                    align: 'start',
                    value: this.allCategories[i],
                    format: 'currency'
                })
            }
            headers.push({
                text: 'Holding',
                align: 'start',
                value: 'Holding',
                format: 'currency'
            })
            headers.push({
                text: 'Totals',
                align: 'start',
                value: 'Totals',
                format: 'currency'
            })
            return headers
        },
        costWarnings() {
            let finalArray = {}
            for (let c = 0; c < this.allCostCategories.length; c++) {
                finalArray[this.allCostCategories[c].Name] = {
                    null: [],
                    zero: []
                }
                for (let p = 0; p < this.allProducts.length; p++) {
                    let cost = this.allCosts.find(item => 
                        item['Cost ID'] === this.allCostCategories[c]['Cost ID'] &&
                        item.List_Id === 1 &&
                        item.SKU === this.allProducts[p].SKU
                    )
                    let temp = {
                        sku: this.allProducts[p].SKU,
                        cost: 0
                    }
                    if (!cost || cost.Cost === null || cost.Cost === undefined) {
                        finalArray[this.allCostCategories[c].Name].null.push(temp)
                    } else if (cost.Cost === 0) {
                        finalArray[this.allCostCategories[c].Name].zero.push(temp)
                    }
                }
            }
            for (let key in finalArray) {
                let nullCount = finalArray[key].null.length
                let zeroCount = finalArray[key].zero.length
                let sum = nullCount + zeroCount
                if (sum === 0) {
                    delete finalArray[key]
                }
            }
            return finalArray
        },
        allCategories() {
            return [... new Set(this.data.map(item => item.Classification))]
        },
        unitType() {
            if (this.showQuantity) {
                return 'Quantity'
            } else {
                return 'Dollars'
            }
        }
    }
}
</script>

<style>

</style>