<template>
  <v-data-table
        :headers="headers"
        :items="userEvents"
    >
        <template v-slot:[`item.Start_Date`]="{ item }">
        <span>{{ new Date(item.Start_Date).toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"}) }}</span>
        </template>
        <template v-slot:[`item.End_Date`]="{ item }">
        <span>{{ new Date(item.End_Date).toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"}) }}</span>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        events: {type: Array},
        year: {type: Number}
    },
    computed: {
        userEvents() {
            let userId = this.$store.getters.getUserId
            return this.$props.events.filter(item => {
                let d = new Date(item.Start_Date)
                let startYear = d.getFullYear()
                return (item.UserId === userId && startYear === this.$props.year)
            })
        },
        headers() {
            return [
                {
                text: 'Start Date',
                align: 'start',
                sortable: true,
                value: 'Start_Date'
                },
                {
                text: 'End Date',
                align: 'start',
                sortable: true,
                value: 'End_Date'
                },
                {
                text: 'Hours',
                align: 'start',
                sortable: true,
                value: 'Hours'
                },
                {
                text: 'Type',
                align: 'start',
                sortable: true,
                value: 'Type'
                },
                {
                text: 'Availability',
                align: 'start',
                sortable: true,
                value: 'Availability'
                },
            ]
        }
    }
}
</script>

<style>

</style>