<template>
    <v-app>
        <v-main>
            <v-row class="px-6 pt-4" align="center">
                <v-dialog v-model="createNewQuoteDialog" width="50vw">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="pr-8"
                            color="primary"
                            icon
                            v-bind="attrs"
                            v-on="on">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card width="50vw">
                        <v-card-title class="d-flex justify-center align-center">
                            Add New Quote
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            Add Line Breaks Where Necessary
                        </v-card-subtitle>
                        <v-textarea class="px-4" v-model="newQuote"/>
                        <v-card-actions class="d-flex justify-space-around align-center">
                            <v-btn
                                color="secondary"
                                @click="createNewQuoteDialog = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="createNewQuote()">
                                Submit
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-text-field v-model="search" label="Search Quotes"/>
            </v-row>
            <v-row justify="center" align="center">
                <v-simple-table v-if="searchQuotes.length">
                    <template v-slot:default>
                        <thead>
                            <th>Quote</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            <tr v-for="(items, index) in searchQuotes" :key="index">
                                <td>{{items.Quote}}</td>
                                <td>
                                    <v-btn
                                        color="primary"
                                        icon
                                        @click="openEditQuoteDialog(items.Id)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="red"
                                        icon
                                        @click="deleteQuote(quotes.Id)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-dialog v-model="editQuoteDialog" width="50vw">
                    <v-card width="50vw">
                        <v-card-title class="d-flex justify-center align-center">
                            Edit Quote
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            Add Line Breaks Where Necessary
                        </v-card-subtitle>
                        <v-textarea multi-line class="px-4" v-model="selectedQuote.Quote"/>
                        <v-card-actions class="d-flex justify-space-around align-center">
                            <v-btn
                                color="secondary"
                                @click="editQuoteDialog = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="editQuote()">
                                Submit Edit
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                    v-model="snackActive"
                    :color="snackColor"
                    :timeout="snackTimeout">
                    {{ snackText }}
                </v-snackbar>
            </v-row>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - Manage Quotes'
        this.$store.dispatch('updateNavbarTitle','Manage Quotes')
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateShowWaffle', true)
        this.getQuotes()
    },
    data() {
        return {
            quotes: [],
            search: '',
            selectedQuote: {},
            editQuoteDialog: false,
            newQuote: '',
            createNewQuoteDialog: false,
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        getQuotes() {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/quotes'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.quotes = data.quotes
                this.snack(data.message,'success',3000)
            }).catch(error => {
                this.snack(error,'error',3000)
                console.log(error)
            })
        },
        deleteQuote(id) {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/quotes'
            const data = {
                id: id
            }
            const request = {
                method: 'DELETE',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.snack(data.message,'success',3000)
            }).catch(error => {
                this.snack(error,'error',3000)
                console.log(error)
            })
        },
        createNewQuote() {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/quotes'
            const data = {
                quote: this.newQuote.replace('\n','\r\n')
            }
            const request = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.snack(data.message,'success',3000)
            }).catch(error => {
                this.snack(error,'error',3000)
                console.log(error)
            })
        },
        openEditQuoteDialog(id) {
            this.selectedQuote = this.quotes.find(item => item.Id === id)
            this.editQuoteDialog = true
        },
        editQuote() {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/quotes'
            const data = {
                id: this.selectedQuote.Id,
                quote: this.selectedQuote.Quote.replace('\n','\r\n')
            }
            const request = {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.snack(data.message,'success',3000)
            }).catch(error => {
                this.snack(error,'error',3000)
                console.log(error)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text
            this.snackColor = color
            this.snackTimeout = timeout
            this.snackActive = true
        },
    },
    computed: {
        searchQuotes() {
            if(this.search.length && this.quotes.length) {
                return this.quotes.filter(item => 
                    item.Quote.toLowerCase().includes(this.search.toLowerCase())
                )
            } else {
                return this.quotes
            }
        }
    }
}
</script>

<style>

</style>