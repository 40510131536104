<template>
    <v-app>
        <v-main>
            <v-row justify="center" class="pt-8">
                <v-progress-circular v-if="loading" indeterminate/>
                <v-card v-else width="75vw" class="pa-4">
                    <v-btn
                        icon
                        @click="openDialog('Create')">
                        <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>
                    <v-simple-table width="80vw">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Value
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in reports" :key="item.Id">
                                    <td>
                                        {{ formatDate(item.Date) }}
                                    </td>
                                    <td>
                                        {{ item.Description }}
                                    </td>
                                    <td>
                                        {{ item.Value | currency }}
                                    </td>
                                    <td>
                                        <v-btn icon @click="openDialog('Edit',item)">
                                            <v-icon color="grey">mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn icon @click="openDialog('Delete',item)">
                                            <v-icon color="red">mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-row>
            <v-dialog v-model="reportDetails.dialog" width="50vw" height="50vh">
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        {{ reportDetails.type }} Report
                    </v-card-title>
                    <v-row justify="space-around" align="center" v-if="reportDetails.type !== 'Delete'">
                        <v-col>
                            <v-date-picker
                                v-model="reportDetails.Date"/>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="reportDetails.Description"
                                label="Description"
                                hide-details/>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="reportDetails.Value"
                                label="Value"
                                hide-details/>
                        </v-col>
                    </v-row>
                    <v-row justify="center" v-else>
                        {{ reportDetails.Date }} - {{ reportDetails.Description }}
                    </v-row>
                    <v-card-actions class="d-flex justify-space-around align-center">
                        <v-btn @click="closeDetails()" color="grey">
                            Cancel
                        </v-btn>
                        <v-btn 
                            @click="submit()" 
                            color="primary"
                            :disabled="disableReportSubmit">
                            {{ reportDetails.type }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch("updateShowWaffle", true);
        document.title = "Sandbox - Inventory Report";
        this.$store.dispatch("updateNavbarContent", [
            { title: "Report", path: "/Ops/InventoryReport/Home", icon: "mdi-home", notification: false },
            { title: "Purchases", path: "/Ops/InventoryReport/Purchases", icon: "mdi-currency-usd", notification: false },
            { title: "Settings", path: "/Ops/InventoryReport/Settings", icon: "mdi-cog", notification: false },
            { title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ]);
        this.$store.dispatch("updateNavbarTitle", "Inventory Report - Upcoming Payments");
        this.getReports();
    },
    data() {
        return {
            reportDetails: {
                dialog: false,
                type: 'Edit',
                Description: '',
                Value: '',
                Date: '',
                Id: ''
            },
            reports: [],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getReports() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_GetReports?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/reports',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    reports
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.reports = reports
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async editReport() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_EditReport?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/reports',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Id: this.reportDetails.Id,
                            Update: {
                                Type: 'Inventory Purchase',
                                Date: this.reportDetails.Date.split('T')[0],
                                Description: this.reportDetails.Description
                            }
                        })
                    }
                )
                const {
                    message,
                    reports
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.reports = reports
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async deleteReport() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_DeleteReport?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/reports?id=' + this.reportDetails.Id,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                let index = this.reports.find(item => item.Id === this.reportDetails.Id)
                this.reports.splice(index,1)
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async createReport() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_CreateReport?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/reports',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Type: 'Inventory Purchase',
                            Description: this.reportDetails.Description,
                            Date: this.reportDetails.Date.split('T')[0],
                            Value: this.reportDetails.Value
                        })
                    }
                )
                const {
                    message,
                    reports
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                this.reports = reports
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        formatDate(date) {
            let inputDate = new Date(date)
            inputDate.setDate(inputDate.getDate() + 1)
            return inputDate.toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'}) 
        },
        openDialog(type, report) {
            this.reportDetails.type = type
            if (type === 'Create') {
                this.reportDetails.Description = ''
                this.reportDetails.Value = 0
                let d = new Date()
                this.reportDetails.Date = d.toISOString().split('T')[0]
            } else {
                this.reportDetails.Id = report.Id
                this.reportDetails.Description = report.Description
                this.reportDetails.Value = report.Value
                this.reportDetails.Date = report.Date.split('T')[0]
            }
            
            this.reportDetails.dialog = true
        },
        closeDetails() {
            this.reportDetails.dialog = false
            this.reportDetails.type = ''
            this.reportDetails.Description = ''
            this.reportDetails.Value = 0
            let d = new Date()
            this.reportDetails.Date = d.toISOString().split('T')[0]
        },
        submit() {
            let type = this.reportDetails.type
            if (type === 'Create') {
                this.createReport()
            } else if (type === 'Edit') {
                this.editReport()
            } else if (type === 'Delete') {
                this.deleteReport()
            }
            this.reportDetails.dialog = false
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        disableReportSubmit() {
            if (this.reportDetails.type !== 'Delete') {
                if (
                    this.reportDetails.Description.length === 0 ||
                    this.reportDetails.Value === 0 ||
                    this.reportDetails.Date.length === 0
                ) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }
}
</script>

<style>

</style>