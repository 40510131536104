<template>
    <div>
        <v-card-title>
            Upsell
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="upsellData"
            :search="search"
            class="elevation-1">
            <template v-slot:[`item.Conversion_Rate`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.Conversion_Rate"
                    large
                    @save="save(prop.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.Conversion_Rate }}%
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.Conversion_Rate"
                            :min="1"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:[`item.Revenue`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.Revenue"
                    large
                    @save="save(prop.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    ${{ prop.item.Revenue }}
                    <template v-slot:input>
                        <v-text-field
                            v-model="prop.item.Revenue"
                            :min="1"
                            label="Edit"
                            single-line
                            autofocus
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
            </template>
            <template v-slot:[`item.Active`]='prop'>
                <v-edit-dialog
                    :return-value.sync="prop.item.Active"
                    large
                    @save="save(prop.item)"
                    @cancel="cancel"
                    @open="open"
                    @close="close"
                >
                    {{ prop.item.Active }}
                    <template v-slot:input>
                        <v-select
                            v-model="prop.item.Active"
                            :items="ActiveChoices"
                            item-text="Label"
                            item-value="Value"
                            label="Active"
                        ></v-select>
                    </template>
                </v-edit-dialog>
            </template>
        </v-data-table>
        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            >
            {{ snackText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                v-bind="attrs"
                text
                @click="snack = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-container>
            <h3>Add New Offer</h3>
            <v-row 
            align="center"
            justify="center">
                <v-col>
                    <v-text-field
                        v-model="Upsell_Offer"
                        label="Upsell Offer"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="Where_It_Appears"
                        label="Where It Appears"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="Conversion_Rate"
                        label="Conversion Rate"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="Revenue"
                        label="Revenue"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn
                        color="primary"
                        elevation="2"
                        small
                        block
                        :disabled="addNewOfferButton"
                        @click="addNewOffer"
                    >Submit</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        inputtedDate: {type: Object}
    },
    beforeMount() {
        this.getUpsellData();
    },
    data:() => ({
        Upsell_Offer: '',
        Where_It_Appears: '',
        Conversion_Rate: '',
        Revenue: '',
        upsellData: [
            {
                "Date": '',
                "Upsell_Offer": '',
                "Where_It_Appears": '',
                "Conversion_Rate": '',
                "Revenue": '',
                "Active": ''
            }
        ],
        snack: false,
        snackColor: '',
        snackText: '',
        search: '',
        rules: [],
        ActiveChoices: [
            {
            "Label": "Yes",
            "Value": "1"
            },
            {
            "Label": "No",
            "Value": "0"
            }
        ]
    }),
    methods: {
        save (item) {
            this.snack = true;
            this.snackColor = 'success';
            this.snackText = 'Data saved';
            this.updateUpsellData(item.Upsell_Offer, item.Where_It_Appears, item.Conversion_Rate, item.Revenue, item.Active);
            this.getUpsellData()
        },
        cancel () {
            this.snack = true;
            this.snackColor = 'error';
            this.snackText = 'Canceled';
        },
        open () {
            this.snack = true;
            this.snackColor = 'info';
            this.snackText = 'Dialog opened';
        },
        close () {
            console.log('Dialog closed');
        },
        getUpsellData: function() {
            const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/upsell';
            const request = {
                method: 'GET',
                Headers: {
                'Content-Type': 'application/json',
                }
            };
            fetch(url, request).then((response) => {
                return response.json();
            }).then((data) => {
                // turn the data back into an object since it
                data = JSON.parse(data.body);
                for (let items in data) {
                    data[items].Date = data[items].Date.toString().split('T')[0];
                    data[items].Active = "Yes";
                    data[items].Conversion_Rate = (data[items].Conversion_Rate * 100).toFixed(2)
                }
                this.upsellData = data
            });
        },
        updateUpsellData: function(Upsell_Offer, Where_It_Appears, Conversion_Rate, Revenue, Active) {
            Conversion_Rate = Conversion_Rate / 100;
            const url = 'https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/upsell';
            const data = {
                'Date': this.$props.inputtedDate.endDate,
                'Upsell_Offer': Upsell_Offer,
                'Where_It_Appears': Where_It_Appears,
                'Conversion_Rate': Conversion_Rate,
                'Revenue': Revenue,
                'Active': Active
            }
            const request = {
                method: 'POST',
                Headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(data)
            };
            fetch(url, request).then((response) => {
                return response.json();
            }).then((data) => {
                console.log(data)
                this.Upsell_Offer = ''
                this.Where_It_Appears = ''
                this.Conversion_Rate = ''
                this.Revenue = ''
                this.snack = true;
                this.snackColor = 'success';
                this.snackText = 'Data Added';
            });
        },
        addNewOffer: function() {
            this.updateUpsellData(this.Upsell_Offer, this.Where_It_Appears, this.Conversion_Rate, this.Revenue, '1');
            setTimeout(() => { this.getUpsellData() }, 1000)
        }
    },
    computed: {
        addNewOfferButton() {
            if (this.Upsell_Offer && this.Where_It_Appears && this.Conversion_Rate && this.Revenue) {
                return false;
            } else {
                return true;
            }
        },
        headers() {
            return [
                {
                    text: 'Date',
                    value: 'Date'
                },
                {
                    text: 'Upsell Offer',
                    value: 'Upsell_Offer'
                },
                {
                    text: 'Where It Appears',
                    value: 'Where_It_Appears'
                },
                {
                    text: 'Conversion Rate',
                    value: 'Conversion_Rate'
                },
                {
                    text: 'Revenue',
                    value: 'Revenue'
                },
                {
                    text: 'Active',
                    value: 'Active'
                }
            ]
        }
    }
}
</script>

<style>

</style>