<template>
    <v-dialog v-model="dialog" max-width="800px">
        <v-card class="pa-4">
            <customTitle 
                :title="'Adding Payment Terms'"
                :name="'addingTerms'"
                @close="$emit('close')"/>
            <v-card-title class="d-flex justify-center align-center">
                IMPORTANT
            </v-card-title>
            <v-card-text class="red--text d-flex justify-center align-center">
                <strong><u>YOU CANNOT RENAME A PAYMENT TERM ONCE YOU CREATE IT. ENSURE IT IS THE SAME SPELLING AS THAT PUT IN CIN7.</u></strong>
            </v-card-text>
            <v-card-text>
                To add payment terms to the system, navigate to <a href="/#/sales/CRM/admin/home" target="_blank"><v-icon>mdi-cog</v-icon> CRM Admin</a> and click on the <v-icon>mdi-plus</v-icon> on the "Payment Terms" row.<br>
                Add a name to the payment term and how many months and days ahead the payment is due. For example, "30 Days" would be 30 days ahead of the invoice date.<br>
                The deposit required is a decimal number. For example, 50% would be 0.5.
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import customTitle from '/src/components/PAM/pages/title.vue';
export default {
    name: 'AddingTerms',
    components: {
        customTitle
    },
    props: {
        dialog: Boolean
    },
}
</script>

<style>

</style>