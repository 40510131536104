<template>
  <v-app>
    <v-main>
      <v-row justify="center">
        <div style="position: relative; text-align: center;">
          <v-img src="../../assets/homePicture.jpg" max-width="100vw" max-height="50vh" width="100vw"/>
          <div @click="randomQuote()" class="quoteTitle px-3 py-3 text-h5 white--text">
            <strong>OUT-OF-CONTEXT QUOTE OF THE DAY</strong><br>
                "{{ quote.Quote }}"
          </div>
        </div>
      </v-row>
      <v-row class="pt-2 px-4">
        <v-col>
          <v-card height="100%">
            <v-card-title class="justify-center text-h5">General</v-card-title>
            <v-row>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-airplane-clock</v-icon>
                  <router-link to="/General/PTOTracker/Home">PTO Tracker</router-link>
                </v-card-actions>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-badge-account-outline</v-icon>
                  <router-link to="/General/Merchandise-Tracker">Employee Purchase</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-chat-question-outline</v-icon>
                  <router-link to="/faqs">FAQs</router-link>
                </v-card-actions>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-alert-circle-outline</v-icon>
                  <router-link to="/errors">Errors</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-wizard-hat</v-icon>
                  <router-link to="/pam">PAM</router-link>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
          <v-card height="100%">
            <v-card-title class="justify-center text-h5">Sales</v-card-title>
            <v-row>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-card-account-details-outline</v-icon>
                  <router-link to="/sales/salesreps/manage">Manage Sales Reps</router-link>
                </v-card-actions>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-currency-usd-off</v-icon>
                <router-link to="/sales/amazon/fees/home">Amazon Fees</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-currency-usd</v-icon>
                  <router-link to="/sales/priceLists/home">Price Is Righteous</router-link>
                </v-card-actions>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-database-cog</v-icon>
                  <router-link to="/sales/database/fix/salesorders">Fix Database</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                  <v-menu 
                    offset-y
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x>
                    <template v-slot:activator="{ on }">
                      <v-card-actions class="flex-column justify-center" v-on="on" style="cursor: pointer;">
                        <v-icon color="primary" class="pb-2">mdi-account-group</v-icon>
                        <div>CRM</div>
                      </v-card-actions> 
                    </template>
                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/sales/CRM/accounts/home">Accounts</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/sales/CRM/leads/home">Leads</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/sales/CRM/accounts/export">Export</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/sales/CRM/batches">Batches</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/sales/CRM/admin/home">Settings</router-link>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-package-variant-closed</v-icon>
                  <router-link to="/sales/amazon/products">Amazon Products</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-link</v-icon>
                  <router-link to="/sales/NDA/links">NDA Links</router-link>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col>
          <v-card height="100%">
            <v-card-title class="justify-center text-h5">Marketing</v-card-title>
            <v-row>
              <v-col>
                <v-card-actions class="justify-center">
                  <a href="https://app.powerbi.com/groups/cfc479e3-a977-444e-8cb2-5b402d0b2479/reports/752df26d-6d09-45ad-a199-b738d633ae8e/ReportSection">Marketing Expenses</a>
                </v-card-actions>
                <v-card-actions class="justify-center">
                  <router-link to="/marketing/dashboard/edit">Marketing Dashboard Editor</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                <v-card-actions class="justify-center">
                  <router-link to="/NotDone">Marketing Master Attributes</router-link>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col> 
          <v-card height="100%">
            <v-card-title class="justify-center text-h5">Ops</v-card-title>
            <v-row>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-package-variant-plus</v-icon>
                  <router-link to="/Ops/InventoryReport/Home">Inventory Report</router-link>
                </v-card-actions>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-chart-line</v-icon>
                  <router-link to="/sales/forecast/home">Forecast Tool</router-link>
                </v-card-actions>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-store</v-icon>
                  <router-link to="/Ops/EDI-Stores">EDI Stores</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                  <v-menu 
                    offset-y
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x>
                    <template v-slot:activator="{ on }">
                      <v-card-actions class="flex-column justify-center" v-on="on" style="cursor: pointer;">
                        <v-icon color="primary" class="pb-2">mdi-abacus</v-icon>
                        <div>Stock Market</div>
                      </v-card-actions>
                    </template>
                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/Ops/StockMarket/Home">Home</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/Ops/StockMarket/manage">Manage Groups</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/Ops/StockMarket/manageBranchTransfers">Manage Branch Transfers</router-link>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <router-link to="/ops/stockmarket/manageBranches">Manage Branches</router-link>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-form-select</v-icon>
                  <router-link to="/Ops/SSCustomsUpdater/Home">SS Customs Updater</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-alpha-v-box-outline</v-icon>
                  <router-link to="/Ops/VolcomReport/Home">Volcom Royalty Report</router-link>
                </v-card-actions>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-lighthouse-on</v-icon>
                  <router-link to="/Ops/Envoy/VPL">Envoy VPL</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-account-plus-outline</v-icon>
                  <router-link to="/Ops/CreateAccount/Home">Create Account</router-link>
                </v-card-actions>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-currency-usd</v-icon>
                  <router-link to="/Ops/Costs">Manage Costs</router-link>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col>
          <v-card height="100%">
            <v-card-title class="justify-center text-h5">Accounting</v-card-title>
            <v-row>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-cash-fast</v-icon>
                  <router-link to="/accounting/collectibles/home">Collectibles</router-link>
                </v-card-actions>
              </v-col>  
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-cash-clock</v-icon>
                  <router-link to="/Accounting/CashFlowModel/Home">
                      Cash Flow Model
                  </router-link>
                </v-card-actions>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-cash-remove</v-icon>
                  <router-link to="/accounting/payments/home">Failed Payments</router-link>
                </v-card-actions>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col v-if="isAdmin">
          <v-card height="100%">
            <v-card-title class="justify-center text-h5">Admin</v-card-title>
            <v-row>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-account-tie</v-icon>
                  <router-link to="/admin/employees">Manage Employees</router-link>
                </v-card-actions>
              </v-col>
              <v-col>
                <v-card-actions class="flex-column justify-center">
                  <v-icon color="primary" class="pb-2">mdi-format-quote-close-outline</v-icon>
                  <router-link to="/admin/quotes">Manage Quotes</router-link>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
export default {
  beforeMount() {
    document.title = 'Matador Sandbox'
    this.$store.dispatch('updateShowWaffle', true)
    this.$store.dispatch('updateNavbarContent', [
      { title: 'Home', path: '/', icon: 'mdi-home'},
      { title: 'PAM', path: '/pam', icon: 'mdi-wizard-hat'}
    ])
    this.$store.dispatch('updateNavbarTitle','Home')
    this.getQuotes()
  },
  data() {
    return {
      quote: '',
      showAuthor: false,
      quotes: [],
      quotesBackup: [
        "We need money to buy plastic.",
        "That's so sick I'm going to throw up",
        "Plumbing is more important than out friendship?",
        "I don't think that's right...",
        "That is so out of context. I think I'm gonna throw up",
        "I cleaned my vents! I did it!",
        "I didn't even notice the machete",
        "Some dude at Natural Grocerers thought I was poor.",
        "Radio Names:\nOak Nuggins\nSlick Rubarb\nThe Chihuhua\nBaby Ripstop\nJT Swizz",
        "You know baby meat? The meat that babies are made out of?",
        "Somebody got through the 'terd' wall.",
        "I heard some Sugar Ray through the wall.",
        "Am I just chopped liver now?",
        "Ya'll talking about drinking your own urine?",
        "I didn't know skunks were little fuckers like that.",
        "It has to be fun. Like going to the beach.",
        "This is new... and I hate it...",
        "Dinkle that shit.",
        "Packages that long are always for Sam.",
        "Bootch Nozzle and the Billy Goats.",
        "Just a warm bag of sand right there. (Referencing Rumney)",
        "I went to horse camp as a kid and made best friends with a goat.",
        "Can't find it? Pluck it!",
        "Yo, homie's got a fat gourd.",
        "I hope you don't mind if there's turds on set.",
        "It's a me, Scenario",
        "Spray bottle is where it's at",
        "That's some nice zipper action right now.",
        "I wonder if rats have their own currency.",
        "Feel free to compare me to Beethoven.",
        "Bar-bara the morrocan Muskrat.",
        "You've never played slap the duffel?",
        "Rumney, you can't fart on your friends.",
        "I bet licking me is like the ginger to his poop sushi.",
        "I've been on the hard stuff since Vietnam",
        "Tiny Tents, they are to have and to hold, like your spouse.",
        "Corn bread, that's what she said. All fly away.",
        "You look like a black turtle.",
        "Do you have a slingshot?\n\nI'm not Bart Simpson, so no.",
        "I hate it when people speel things wrong.",
        "A coffee and life philosophy:\n- The 3 week steep\n- The long way around\n- The Mason infusion",
        "What's a belly whomper?",
        "Nobody just births one pup.",
        "What if humans birthed puppies?",
        "I just can't wait to tickle you someday.",
        "Why don't you just take the whole slice?",
        "A little hot leg syndrome.",
        "If you slapped me, I'd be rocked",
        "We're tired of your gong hoarding.",
        "Look how sexy that seam tape is.",
        "A shovel is almost a broom, but it's not.",
        "Have psot traumatic stress disorder? Pass it on to a child!\n(Ad for becoming a youth football coach)",
        'They are all different sizes. They carry different weight.',
        "I'm not really a milker.",
        "It smells like this lizard I had as a kid.",
        "Glass cage of emotion.",
        "THe basement of my mouth.",
        "I don't like next-morning water.",
        "Ryan, you got some good dead eyes.",
        "Smile, but make your eyes dead.",
        "They are going to add the hand-doing job.",
        "I look like a fuckin' lunch beast!",
        "Who are these people that get offended by flexi-boxes?",
        "I'll get my butt wet for science.",
        "You guys waste so much time talking.",
        "We could use the siberian hook instead of the Darth Vader helmet.",
        "Chronic persistant lameness.",
        "Gunbarrel is to Boulder as berries are to fruit.",
        "Your shoulders and butt are too close together.",
        "How much would a block of Hamster milk cheese go for?",
        "Great day for a measles story!",
        "Don't you know how to chicken wing a dog?",
        "Christina stands so tall it makes others feel low.",
        "In case you didn't know I make the rules about sneezing and happiness.",
        "Yeah I know about Pantene Prov.",
        "You should smell more goats.",
        "Hey weird neck.",
        "That's Yabba.",
        "You need to foil that banana.\nYou don't have to foil the banana if you're going to eat it.",
        "On a scale of Grateful Dead to Quiad, where does Kenny G. fall?",
        "Turtles are more durable than gerbils.",
        "Looks like a cat got run over and put on my head.",
        "Paul Simon isn't old school!\nPaul Simon is forever!",
        "'Bout to sneak up on some sales.",
        "Eat. Climb. Read. Pick up drugs.\nThat's my life.",
        "Old people and babies look exactly the same because they are so close to death.",
        "Ugh, if only I could be doing Excel right now.",
        "I think I would be a good drug lord.",
        "Hey, I didn't fuck up... I made a boo-boo.",
        "Don't get too comfortable. Love is fleeting.",
        "I've already fed once today.",
        "Can you go home and smoke for work?",
        "I'm irritated by people who have time to do useless stuff.",
        "12pm, that's noon, right?",
        "It just smells fishy, Lorenzo.",
        "Don't blame Asana, blame yourself.",
        "There I am. Hey buddy.",
        "When the world is ending, you're gonna need somewhere to sit.",
        "While we're giving up, why not give up all the way.",
        "I don't like colors...",
        "I have a spider jar",
        "I'm trying to get linked-in premium so I can reach out to jerky people",
        "I dont't take an hour to cook a streudel",
        "They're for skin...",
        "I think I'm dead...",
        "Good morning Last Day Boy",
        "So I gave it a couple extra licks. It was like 3 or 4 licks, but they were good licks",
        "I want a Randy",
        "Nobody sees it as I see it (referring to the On-Grid backpack)",
        "I don't know what that means, Austin",
        "Cadillic people are scammers",
        "I got jelly on my laptop",
        "Did you Google fish belt?",
        "We'll call it the strap on water bottle",
        "Deep in the beans",
        "NoodleMeyer Ding-hao. Nice to meet you",
        "The toilet snakes killed the shower",
        "Fish tank stuff is always awesome",
        "Duplicates = Double Ups = Dublubs",
        "'Candle on a stick by Matador' -Person 1\n'Travel is still dead, we're getting desperate' -Person 2",
        "A lot of doodads for such a weird little shape",
        "No. I just want to eat delicious sausage",
        "I'm not smirkin' I'm working",
        "I read 50 shades of grey and it was a lot like your experience when you read Harry Potter",
        "He just likes his head to be squeezed (About Sam wearing headphones)",
        "Big Daddy... always on my mind.",
        "I'm too dehydrated to whistle.",
        "All about the LOL",
        "Each bag comes with a safe word.",
        "Talking about putting a butt flap in a gimp suit?",
        "If we build you a hot GAK you would have no diseases",
        "Oh yeah. This looks like I'm making dark robot money.",
        "I enjoy having mayonaise on my sandwhich, but I need to know where it's been",
        "You just looked up possum nipples and you can't find them.",
        "I never think about polonium",
        "You tried to give me all of your love once... it was too much",
        "If we're gonna buy a bunch of long arms, we should probably purchase a lot of pulleys",
        "I feel real uncomfortable drawing butts.",
        "Biting might be a pretty dominate weapon in a second graders arsenal.",
        "Are you enjoying it? Or is it knind of throw-uppy",
        "Is it cannibalism if you drink it?",
        "You climbed up in my tree a long time ago",
        "You gotta pick it in the front cause there's no acces holes in the back.",
        "Give me a show",
        "I'm not frustrated, I'm just breathing.",
        "I had the rowdyness side bang.",
        "You think you're better than the sun?",
        "Good job touchin' pipes.",
        "I've been high the last 3 months",
        "Oh man. Still need to find this BROWN THURSDAY MEME.",
        "You look like a janky ham-BURG-ler",
        "I got Dickie Ding live",
        "Matador is the land of surprises.",
        "Can't get more naked than nude. Quote. [Finger Guns]",
        "My jar is full. I can't add chocolate.",
        "The shanghole goes to Uraguay.",
        "It was a spite return.",
        "Gloss in the pits and rough on the shultle, if you know what I mean.",
        "Look I'm light as a bat bro.",
        "Stick that in your whimsey.",
        "The road to children is paved with cats & dogs",
        "Translating is an art, not a science.",
        "It would have been my time to shine back then.",
        "Kevin Nutmegged Nellie.",
        "You tryina suck on that smoke?",
        "Is Kevin out there palying with his hose again.",
        "Had to invest in beanies now that my little head gets cold.",
        "You can't let the idiots beat you into being right.",
        "I want to somehow turn zero into one.",
        "Either we make the short D entry so small that it sucks...",
      ]
    }
  },
  methods: {
    randomQuote() {
      const index = Math.floor(Math.random() * (this.quotes.length - 1))
      this.quote = this.quotes[index]
    },
    getQuotes() {
      const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/quotes'
      const request = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      }
      this.loading = true
      fetch(url, request).then(async response => { 
          const data = await response.json()
      
          if (!response.ok) {
              const error = (data && data.message) || response.status
              return Promise.reject(error)
          }
          this.quotes = data.quotes
          this.randomQuote()
      }).catch(error => {
        console.log(error)
      })
    }
  },
  computed: {
    isAdmin() {
      let permissions = this.$store.getters.getPermissions
      if (permissions.includes('Admin')) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>

<style>
.quoteTitle {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  background: rgba(25, 25, 25, .5);
}
</style>