<template>
    <v-main>
        <v-container class="flex-column justify-center align-center">
            <v-row class="my-2">
                <h2>
                    Remaining Balance: {{ budgetLeft | currency}}
                </h2>
            </v-row>
            <v-row width="100vw" class="d-flex justify-space-around">
                <v-autocomplete
                    class="mr-2"
                    width="25vw"
                    :items="products"
                    item-text="Name"
                    item-value="SKU"
                    :filter="customFilter"
                    :hint="`$${String(selectedProduct.Price)}`"
                    label="Select a Product"
                    v-model="selectedProduct"
                    return-object>
                    <template v-slot:item="{ item }">
                        <div>
                            <v-card-text class="pb-0">{{ item.Name }}</v-card-text>
                            <v-card-subtitle class="pt-0 grey--text">{{ item.SKU }} - {{ item.Price | currency }}</v-card-subtitle>
                        </div>
                    </template>
                </v-autocomplete>
                <v-text-field
                    class="mx-2"
                    label="Quantity"
                    v-model="qty"
                    type="number"/>
                <v-checkbox
                    class="ml-2"
                    label="Family and Friends"
                    color="primary"
                    v-model="familyAndFriends"/>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            class="pb-2 pl-2"
                            color="primary">
                            mdi-information-outline
                        </v-icon>
                    </template>
                    <span>This will not spend any Employee Dollars and just applies a straight 70% discount</span>
                </v-tooltip>
            </v-row>
            <v-row class="mb-4" justify="center" align="center">
                <v-btn
                    color="primary"
                    :disabled="!selectedProduct.Name"
                    @click="addProduct()">
                    Add Product
                </v-btn>
            </v-row>
            <v-row class="mb-4">
                <v-card height="30vh" width="75vw">
                    <v-simple-table height="100%">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Product</th>
                                    <th class="text-left">Description</th>
                                    <th class="text-left">Price</th>
                                    <th class="text-left">Quantity</th>
                                    <th class="text-left">Total</th>
                                    <th class="text-left">Employee Dollars Used</th>
                                    <th class="text-left">Total To Pay</th>
                                    <th class="text-left">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="table.length === 0">
                                    <td colspan="8" class="text-center grey--text">
                                        Add a product to begin building your order
                                    </td>
                                </tr>
                                <tr v-for="(product, index) in table" :key="index">
                                    <td>{{ products.find(item => item.SKU === product.SKU).Name }}</td>
                                    <td>{{ product.Description }}</td>
                                    <td>{{ product.Cost | currency }}</td>
                                    <td>{{ product.Qty }}</td>
                                    <td>{{ product.Qty * product.Cost | currency }}</td>
                                    <td>{{ product.Employee_Dollars_Used | currency}}</td>
                                    <td>{{ product.Total_To_Pay | currency}}</td>
                                    <td>
                                        <v-btn
                                            color="grey"
                                            @click="order.splice(index, 1)"
                                            icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-card-subtitle class="text-center grey--text">
                        If the above table ever just goes blank, refresh the page and it will reappear.
                    </v-card-subtitle>
                </v-card>
            </v-row>
            <v-row>
                    Total Owed: {{ totalOwed | currency }}
            </v-row>
            <v-row>
                <v-radio-group
                    v-model="fullfillmentMethod"
                    label="Fullfillment Method"
                    color="primary">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-radio
                                label="Ship from Warehouse"
                                value="warehouse"
                                v-on="on"/>
                        </template>
                        <span>Shipping cost will be added at checkout</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-radio
                                label="Get From Office"
                                value="office"
                                v-on="on"/>
                        </template>
                        <span>No shipping required. You are responsible for collecting the items yourself.</span>
                    </v-tooltip>
                </v-radio-group>
            </v-row>
            <v-row class="mb-4">
                <v-btn
                    color="primary"
                    :disabled="order.length === 0"
                    :loading="submitLoading"
                    @click="submitOrder()">
                    Submit Order
                </v-btn>
            </v-row>
            <v-row class="pt-0 mt-0 pb-8">
                <v-card class="pa-4" width="75vw">
                    <v-card-title class="py-0">
                        <h3 class="text-center flex-grow-1">
                            <u>Order History</u>
                        </h3>
                    </v-card-title>
                    <v-tabs class="pa-4">
                        <v-tab>This Year</v-tab>
                        <v-tab>Other Years</v-tab>
                        <v-tab-item style="height: 20vh; overflow-y: auto;">
                            <history-table
                                :orders="thisYearOrders"
                                :products="products"/>
                        </v-tab-item>
                        <v-tab-item style="height: 20vh; overflow-y: auto;">
                            <history-table
                                :orders="otherOrders"
                                :products="products"/>
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-row>
            <v-dialog
                v-model="paymentDialog"
                width="50vw">
                <v-card class="pa-4">
                    <v-card-title v-if="payment_url" class="d-flex justify-center align-center">
                        Payment Link
                    </v-card-title>
                    <v-card-subtitle v-if="payment_url" class="d-flex justify-center align-center">
                        If the link does not open automatically, please click on, or copy and paste it into a new tab.
                    </v-card-subtitle>
                    <v-card-text v-if="payment_url" class="d-flex justify-center align-center">
                        <a :href="payment_url" target="_blank">{{ payment_url }}</a>
                    </v-card-text>
                    <v-card-text class="d-flex justify-center align-center">
                        <v-alert
                            v-if="fullfillmentMethod === 'warehouse'"
                            type="info"
                            outlined>
                            Please pay at the above link/the new tab that was just opened to complete your order.
                        </v-alert>
                        <v-alert
                            v-else-if="fullfillmentMethod === 'office' && paymentRequired"
                            type="info"
                            outlined>
                            Please pay at the above link/the new tab that was just opened and then grab the items from the office.
                        </v-alert>
                        <v-alert
                            v-else
                            type="info"
                            outlined>
                            Your order has been submitted and nothing is owed. Please grab the items from the office.
                        </v-alert>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            @click="paymentDialog = false; paymentRequired = false;"
                            color="primary">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>    
            </v-dialog>
        </v-container>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-main>
</template>

<script>
import historyTable from './historyTable.vue'
export default {
    name: 'MerchandiseTracker',
    async beforeMount() {
        document.title = 'Sandbox - Merchandise Tracker'
        this.$store.dispatch('updateShowWaffle', true)
        
        this.$store.dispatch('updateNavbarTitle','Merchandise Tracker')

        this.$store.dispatch('updateNavbarContent', [])
        this.getInfo()
    },
    components: {
        historyTable
    },
    data() {
        return {
            familyAndFriends: false,
            paymentRequired: false,
            remainingBalance: '',
            fullfillmentMethod: 'office',
            products: [],
            qty: 1,
            order: [],
            employee: {},
            selectedProduct: {},
            history: [],
            payment_url: '',
            paymentDialog: false,
            loading: false,
            submitLoading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/merchandise-tracker')
                url.searchParams.append('email', this.$store.state.user.email)
                //Lambda Function: 
                const response = await fetch(
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                
                const {
                    message,
                    employee,
                    products,
                    history
                } = await response.json()

                if (!response.ok) {
                    throw new Error(message)
                }
                this.employee = employee
                this.products = products
                this.history = history.reverse()

                this.loading = false
                this.snack(message, 'success', 3000)
            } catch(error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async submitOrder() {
            try {
                this.submitLoading = true
                let copy = JSON.parse(JSON.stringify(this.table))
                copy.forEach(item => {
                    item.Shipping = this.fullfillmentMethod
                    item.Employee = this.employee.Id
                    delete item.Total
                    delete item.Cost
                })
                this.paymentRequired = this.totalOwed > 0
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/merchandise-tracker')
                url.searchParams.append('email', this.$store.state.user.email)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_MerchandiseTrackerUpdate?tab=code
                    url,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Insert: copy,
                            Id: this.employee.Id,
                            Employee_Dollars: this.budgetLeft,
                            Method: this.fullfillmentMethod
                        })
                    }
                )
                const {
                    message,
                    history,
                    employee,
                    payment_url
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.payment_url = payment_url
                this.copyLink()
                this.order = []
                this.history = history.reverse()
                this.employee = employee
                this.paymentDialog = true
                this.submitLoading = false
                this.snack(message,'success',3000)
                window.open(payment_url, '_blank')
            } catch (error) {
                this.submitLoading = false
                this.snack(error,'error',3000)
            }
        },
        addProduct() {
            this.order.push({
                Date: new Date().toISOString().split("T")[0],
                Employee: this.employee.Id,
                SKU: this.selectedProduct.SKU,
                Cost: this.selectedProduct.Price,
                Qty: Number(this.qty),
                Description: (this.familyAndFriends) ? 'Friends and Family' : 'Employee Purchase'
            })
            this.selectedProduct = {}
            this.qty = 1
        },
        copyLink() {
            navigator.clipboard.writeText(this.payment_url);
            this.snack('Link Copied', 'success', 2000)
        },
        getProductName(sku) {
            return this.products.find(product => product.SKU === sku).Name
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        thisYearOrders() {
            return this.history.filter(order => {
                let date = new Date(order.Date)
                return date.getFullYear() === new Date().getFullYear()
            })
        },
        otherOrders() {
            return this.history.filter(order => {
                let date = new Date(order.Date)
                return date.getFullYear() !== new Date().getFullYear()
            })
        },
        customFilter() {
            return (item, queryText, itemText) => {
                const hasText = itemText.toLowerCase().includes(queryText.toLowerCase())
                const hasSKU = item.SKU.toLowerCase().includes(queryText.toLowerCase())
                return hasText || hasSKU
            }
        },
        table() {
            let formattedOrders =[];
            let budgetLeft = this.employee.Employee_Dollars

            for (let i = 0; i < this.order.length; i++) {
                let total = this.order[i].Cost * this.order[i].Qty
                let employeeDollarsUsed = total
                let totalToPay = 0

                if (budgetLeft - total < 0) {
                    employeeDollarsUsed = budgetLeft
                    totalToPay = (total - budgetLeft) * .3
                    budgetLeft = 0
                } else if (this.order[i].Description === 'Friends and Family') {
                    employeeDollarsUsed = 0
                    totalToPay = total * .3
                } else {
                    budgetLeft -= total
                }
                
                formattedOrders.push({
                    Date: this.order[i].Date,
                    SKU: this.order[i].SKU,
                    Description: this.order[i].Description,
                    Cost: this.order[i].Cost,
                    Qty: this.order[i].Qty,
                    Total: this.order[i].Cost * this.order[i].Qty,
                    Employee_Dollars_Used: employeeDollarsUsed,
                    Total_To_Pay: totalToPay
                })
            }
            return formattedOrders
        },
        totalOwed() {
            return this.table.reduce((acc, curr) => {
                acc += curr.Total_To_Pay
                return acc
            }, 0)
        },
        budgetLeft() {
            let totals = this.table.reduce((acc, curr) => {
                acc += curr.Employee_Dollars_Used
                return acc
            }, 0)
            return this.employee.Employee_Dollars - totals
        }
    }
}
</script>

<style>

</style>