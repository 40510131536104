<template>
    <v-app>
        <v-main>
            <v-row justify="center" align="center">
                <refresh-data
                    :otherLoading="loading"
                    :refreshBranchTransfer="refreshBranchTransfer"
                    @Sales-Forecast-Data="salesForecastRefreshed"
                    @Branch-Transfer-Data="branchTransfersRefreshed"
                    @SOH-Data="stockRefreshed"/>
                <v-col>
                    <forecast-check
                        v-model="forecastToViewDetails"
                        :lookForwardMonths="lookForwardMonths"
                        :forecastData="forecastData"
                        :branchTransferData="branchTransferData"
                        :stockMarketData="stockMarketData"
                        :productData="productData"
                        :productFloorData="productFloorData"
                        :SOHData="SOHData"
                        :opsGroup="selectedOpsGroup"
                        :includeDrafts="includeDrafts"/>
                </v-col>
                <v-col class="px-6 d-flex align-center">
                    <div class="d-flex align-center">
                        <v-select
                            label="Ops Group"
                            v-model="selectedOpsGroup"
                            :items="opsGroups"
                            item-text="Ops_Group"
                            item-value="Ops_Group"
                            hide-details/>
                    </div>
                </v-col>
                <v-col cols="3">
                    <div  class="py-0 d-flex-column justify-center align-center">
                        <v-subheader style="height: 100%;" class="d-flex justify-center py-0 mx-0">Card Height</v-subheader>
                        <v-slider
                            class="pt-0"
                            v-model="cardHeight"
                            hide-details
                            min="19"
                            max="50"/>
                    </div>
                </v-col>
                <v-col class="d-flex-column align-center justify-center">
                    <v-row>
                        <div class="d-flex align-center justify-center">
                            Visible Months
                        </div>
                    </v-row>
                    <v-row>
                        <div class="d-flex align-center justify-center">
                            <v-btn color="primary" icon @click="tempLookForwardMonths--">
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                            {{ tempLookForwardMonths }}
                            <v-btn color="primary" icon @click="tempLookForwardMonths++">
                                <v-icon>mdi-chevron-up</v-icon>
                            </v-btn>
                        </div>
                    </v-row>
                    <v-row>
                        <v-btn 
                            color="primary"
                            x-small 
                            :disabled="tempLookForwardMonths === lookForwardMonths"
                            @click="lookForwardMonths = tempLookForwardMonths">Submit</v-btn>
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center align-center">
                    <v-switch
                        v-model="includeDrafts"
                        label="Include BT Drafts"
                        color="primary"/>
                </v-col>
                <v-col class="d-flex justify-center align-center">
                    <v-switch
                        v-model="includeInactiveProducts"
                        label="Include Inactive Products"
                        color="primary"/>
                </v-col>
                <v-col cols="1">
                    <div class="d-flex justify-center align-center mr-2">
                        <v-btn
                            color="primary"
                            icon>
                            <download-csv
                                class="btn btn-default"
                                :data="stockMarketDataForExport()"
                                :name="exportFileName">
                                <v-icon>mdi-download</v-icon>
                            </download-csv>
                        </v-btn>
                        <v-spacer/>
                        <deleteButton
                            :v-if="isAdmin"
                            @delete-restock="deleteRestock"/>
                    </div>
                </v-col>
          </v-row>
          <v-row class="px-4">
              <v-text-field
                v-model="productSearch"
                label="Product Search"
                solo
                hide-details/>
          </v-row>
          <v-row justify="center">
              <product-list
                v-model="loading"
                @forecast-check="forecastCheckData"
                @clear-search="clearSearch"
                @update-product-floor="updateProductFloor"
                @refresh-branch-transfers="refreshBranchTransfers"
                @update-products="updateProducts"
                :forecastToViewDetails="forecastToViewDetails"
                :searchProducts="productSearch"
                :cardHeight="cardHeight"
                :opsGroup="selectedOpsGroup"
                :lookForwardMonths="lookForwardMonths"
                :includeDrafts="includeDrafts"
                :includeInactiveProducts="includeInactiveProducts"
                :SOHProp="SOHData"
                :branchTransferProp="branchTransferData"
                :stockMarketProp="stockMarketData"
                :forecastProp="forecastData"/>
          </v-row>
          <v-snackbar
              v-model="snackActive"
              :color="snackColor"
              :timeout="snackTimeout">
              {{ snackText }}
          </v-snackbar>
      </v-main>
  </v-app>
</template>

<script>
import ProductList from './components/productList.vue';
import ForecastCheck from './components/forecastCheck.vue'
import RefreshData from './components/refreshData.vue'
import deleteButton from './components/deleteButton.vue'

export default {
    beforeMount() {
        document.title = 'Sandbox - Stock Market'
        this.$store.dispatch('updateShowWaffle', true)
        //determines whether or not 
        this.areThereNewGroups();
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Home', path: '/Ops/StockMarket/Home', icon: 'mdi-home', notifcation: ""},
            {title: 'Manage Groups', path: '/ops/stockmarket/manage', icon: 'mdi-account-group', notification: this.newGroup},
            {title: 'Manage Branch Transfers', path: '/ops/stockmarket/manageBranchTransfers', icon: 'mdi-source-branch', notification: this.countOfEmptyETAs},
            {title: 'Manage Branches', path: '/ops/stockmarket/manageBranches', icon: 'mdi-warehouse', notification: ""}
        ])
        this.$store.dispatch('updateNavbarTitle','Stock Market')
        this.setVariables()
        this.getOpsGroups();
    },
    watch: {
        newGroup: function() {
            this.$store.dispatch('updateNavbarContent', [
                {title: 'Home', path: '/Ops/StockMarket/Home', icon: 'mdi-home', notifcation: false},
                {title: 'Manage Groups', path: '/ops/stockmarket/manage', icon: 'mdi-account-group', notification: this.newGroup},
                {title: 'Manage Branch Transfers', path: '/ops/stockmarket/manageBranchTransfers', icon: 'mdi-source-branch', notification: this.countOfEmptyETAs},
                {title: 'Manage Branches', path: '/ops/stockmarket/manageBranches', icon: 'mdi-warehouse', notification: ""}
            ])
        },
        countOfEmptyETAs: function() {
            this.$store.dispatch('updateNavbarContent', [
                {title: 'Home', path: '/Ops/StockMarket/Home', icon: 'mdi-home', notifcation: false},
                {title: 'Manage Groups', path: '/ops/stockmarket/manage', icon: 'mdi-account-group', notification: this.newGroup},
                {title: 'Manage Branch Transfers', path: '/ops/stockmarket/manageBranchTransfers', icon: 'mdi-source-branch', notification: this.countOfEmptyETAs},
                {title: 'Manage Branches', path: '/ops/stockmarket/manageBranches', icon: 'mdi-warehouse', notification: ""}
            ])
        },
        forecastToViewDetails: function() {
            this.productSearch = this.forecastToViewDetails.sku
        },
        selectedOpsGroup: function() {
            localStorage.stockMarket_settings_opsGroup = this.selectedOpsGroup
        },
        includeDrafts: function() {
            localStorage.stockMarke_settings_includeDrafts = this.includeDrafts
        },
        includeInactiveProducts: function() {
            localStorage.stockMarket_settings_includeInactiveProducts = this.includeInactiveProducts
        },
        cardHeight: function() {
            localStorage.stockMarket_settings_cardHeight = this.cardHeight
        },
        lookForwardMonths: function() {
            localStorage.stockMarket_settings_lookForwardMonths = this.lookForwardMonths
        },
    },
    components: {
        ProductList,
        ForecastCheck,
        RefreshData,
        deleteButton,
    },
    data() {
        return {
            path:'/Ops/StockMarket/Home',
            newGroup: false,
            countOfEmptyETAs: false,
            forecastToViewDetails: {},
            productSearch: '',
            forecastData: [],
            branchTransferData: [],
            stockMarketData: [],
            productData: [],
            productFloorData: [],
            SOHData: [],
            cardHeight: 20,
            includeDrafts: true,
            includeInactiveProducts: false,
            opsGroups: [],
            etaBot: {
                result: 'notComplete',
                message: '',
                attemptCounter: 0,
            },
            selectedOpsGroup: 'Synergy',
            lookForwardMonths: 6,
            tempLookForwardMonths: 6,
            refreshBranchTransfer: 0,
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        areThereNewGroups() {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/managegroups/notification'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.newGroup = data.newGroup
            })
        },
        clearSearch(search) {
            this.productSearch = search
        },
        forecastCheckData(forecastData, branchTransferData, stockMarketData, productData, productFloorData, SOHData) {
            this.forecastData = forecastData
            this.branchTransferData = branchTransferData
            this.stockMarketData = stockMarketData
            this.productData = productData
            this.productFloorData = productFloorData
            this.SOHData = SOHData
        },
        getOpsGroups() {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/opsgroups'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }

                this.opsGroups = data.opsGroups
                console.log(data.countOfEmptyETAs);
                if (data.countOfEmptyETAs[0].Count > 0) {
                    this.countOfEmptyETAs = data.countOfEmptyETAs[0].Count
                } else {
                    this.countOfEmptyETAs = false
                }
                
            }).catch(error => {
                this.snack(error, 'error', 3000)
            })
        },
        stockMarketDataForExport() {
            let exportList = []
            let tempObject = {}
            for (let i = 0; i < this.stockMarketData.length; i++) {
                let data = this.stockMarketData[i]
                if (data.Ops_Group === this.selectedOpsGroup) {
                    tempObject = {
                        SKU: data.SKU,
                        Month: data.Month,
                        Year: data.Year,
                        Restock: data.Restock
                    }
                    exportList.push(tempObject)
                }
            }
            return exportList
        },
        snack(text, color, timeout) {
            this.snackText = text
            this.snackColor = color
            this.snackTimeout = timeout
            this.snackActive = true
        },
        stockRefreshed(data) {
            this.SOHData = data
        },
        branchTransfersRefreshed(data) {
            this.branchTransferData = data
        },
        salesForecastRefreshed(data) {
            this.forecastData = data
        },
        setVariables() {
            if (!localStorage.stockMarket_settings_opsGroup) {
                localStorage.stockMarket_settings_opsGroup = this.selectedOpsGroup
            } else {
                this.selectedOpsGroup = localStorage.stockMarket_settings_opsGroup
            }
            if (!localStorage.stockMarket_settings_cardHeight) {
                localStorage.stockMarket_settings_cardHeight = this.cardHeight
            } else {
                this.cardHeight = Number(localStorage.stockMarket_settings_cardHeight)
            }
            if (!localStorage.stockMarket_settings_lookForwardMonths) {
                localStorage.stockMarket_settings_lookForwardMonths = this.lookForwardMonths
            } else {
                this.lookForwardMonths = Number(localStorage.stockMarket_settings_lookForwardMonths)
                this.tempLookForwardMonths = this.lookForwardMonths
            }
            if (!localStorage.stockMarket_settings_includeInactiveProducts) {
                localStorage.stockMarket_settings_includeInactiveProducts = this.includeInactiveProducts
            } else {
                this.includeInactiveProducts = (localStorage.stockMarket_settings_includeInactiveProducts === 'true')
            }
            if (!localStorage.stockMarket_settings_includeBranchTransferDrafts) {
                localStorage.stockMarket_settings_includeBranchTransferDrafts = this.includeDrafts
            } else {
                this.includeDrafts = (localStorage.stockMarket_settings_includeBranchTransferDrafts === 'true')
            }
        },
        deleteRestock() {
            console.log('hello');
            this.stockMarketData = []
        },
        updateProductFloor(data) {
            console.log(data)
            this.productFloorData = data
        },
        refreshBranchTransfers() {
            this.refresh++
        },
        updateProducts(products) {
            this.productData = products
        }
    },
    computed: {
        exportFileName() {
            let d = new Date()
            let date = d.getDate()
            let month = d.getMonth()
            let year = d.getFullYear()
            return "Stock_Market_Export_" + 
                this.selectedOpsGroup + " " + 
                year + "_" + month + "_" + date + ".csv"
        },
        isAdmin() {
            let permissions = this.$store.getters.getPermissions
            if (permissions.includes('Ops_Admin') || permissions.includes('Admin')) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style>

</style>