<template>
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card class="pa-4">
            <customTitle 
                :title="'Swap Envoy Order'" 
                :name="'swapEnvoyOrder'" 
                @close="$emit('close')"/>
            <v-card-text>
                <p>
                    To swap an Envoy order, follow the steps below:
                </p>
                <ol>
                    <li>Go to the VPL</li>
                    <li>Click on the 'View Orders' button in the 'Envoy Order Swap' row</li>
                    <li>Select the order from the dropdown that you would like to change</li>
                    <li>It will show you the current customer Id that is tied to the order. If a match Cin7 account is found, it will list the company name. If not, it will say that no customer was found</li>
                    <li>Choose the Cin7 account that it should be assigned to</li>
                    <li>Click "Swap Order"</li>
                    <li>Done with part one. The order will be updated in Envoy in 10-20 minutes</li>
                    <li>Go to the 'View Historical Orders' button in the 'Order Exports: Envoy > Cin7' row</li>
                    <li>Select the refresh button next to the order number that was just swapped to push it back over to Cin7.</li>
                    <li>Done!</li>
                </ol>
            </v-card-text>
            <v-row justify="center">
                <v-img src="../src/Swap-Envoy-Order.png"/>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import customTitle from './title.vue';

export default {
    name: 'SwapEnvoyOrder',
    components: {
        customTitle
    },
    props: {
        dialog: Boolean
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>

<style>

</style>