<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['chartData','refresh'],
    mounted () {
        // Overwriting base render method with actual data.
        this.renderChart(this.chartData, this.options)
    },
    
    watch: {
        refresh: function() {
            this.renderChart(this.chartData, this.options)
        }
    },
    methods: {
    },
    computed: {
        options() {
            return {
                scales: {
                    x: {
                        min: 0,
                        ticks: {
                            stepSize: 1
                        }
                    },
                    x2: { // add extra axes
                        position: 'bottom',
                        type: 'category'
                    },
                    // xAxes: [{
                    //     gridLines: {
                    //         display: false
                    //     }
                    // }],
                    // yAxes: [{
                    //     gridLines: {
                    //         display: false
                    //     },
                    //     min: 0
                    // }]
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                }
            }
        }
    }
}
</script>

<style>

</style>