<template>
    <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-filter-variant"
                v-bind="attrs"
                @input="change()"
                @click:append="on.click">
            </v-text-field>
        </template>
        <v-card width="25vw" class="pa-4">
            <v-row align="center" class="mb-2">
                <v-col class="py-0 pr-0">
                    Categories
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        v-model="selectedCategories"
                        :items="categories"
                        chips
                        hide-details
                        multiple
                        @change="change()"
                        class="px-2"/>
                </v-col>
            </v-row>
            <v-row align="center" class="my-2">
                <v-col class="py-0 pr-0">
                    Colors
                </v-col>
                <v-col cols="8" class="py-0 pl-0">
                    <v-select
                        :items="colors"
                        v-model="selectedColors"
                        chips
                        hide-details
                        multiple
                        @change="change()"
                        class="px-2"/>
                </v-col>
            </v-row>    
        </v-card>
    </v-menu>
</template>

<script>
export default {
    props:{
        categories: {type: Array},
        colors: {type: Array}
    },
    data() {
        return {
            search: '',
            selectedCategories: [],
            selectedColors: []
        }
    },
    methods: {
        change() {
            this.$emit('search-filter',this.search)
            this.$emit('color-filter',this.selectedColors)
            this.$emit('category-filter',this.selectedCategories)
        }
    },
    computed: {
        activeFilters() {
            if (this.selectedCategories.length && this.selectedColors.length) {
                return 2
            } else if (!this.selectedCategories.length && !this.selectedColors.length) {
                return 0
            } else {
                return 1
            }
        }
    }
}
</script>

<style>

</style>