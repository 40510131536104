<template>
    <v-app>
        <v-main>
            <v-row justify="center" class="pt-4">
                <v-col class="pr-2">
                    <v-row class="pb-2">
                        <v-card class="pa-4 overflow-y-auto" height="45vh" width="50vw">
                            <v-card-title class="d-flex justify-center align-center">
                                Branch Classifications
                            </v-card-title>
                            <v-card-subtitle class="text-center">
                                Classifications dependent on those created in the 'Matching Classifications to Cost Categories' section of this settings page
                            </v-card-subtitle>
                            <v-data-table
                                :loading="loading"
                                :headers="headers"
                                :disable-pagination="true"
                                hide-default-footer
                                :items="branches">
                                <template v-slot:[`item.Inventory_Classification`]="{ item }">
                                    <v-select
                                        :items="branchClassifications"
                                        item-text="Classification"
                                        item-value="Id"
                                        v-model="item.Inventory_Classification"
                                        @change="updateBranch(item['Branch ID'], item.Inventory_Classification)"/>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-row>
                    <v-row class="pt-2">
                        <v-card class="pa-4 overflow-y-auto" height="45vh" width="50vw">
                            <v-card-title class="d-flex justify-center align-center">
                                Included Sub Categories
                            </v-card-title>
                            <v-data-table
                                :loading="subCategoryLoading"
                                :headers="subCategoryHeaders"
                                :disable-pagination="true"
                                hide-default-footer
                                :items="formattedSubCategories">
                                <template v-slot:[`item.Included`]="{ item }">
                                    <v-checkbox
                                        :loading="subCategoryLoading"
                                        v-model="item.Included"
                                        @change="updateSubCategories(item.Included, item.Sub_Category)"
                                        color="primary"
                                        hide-details/>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-row>
                </v-col>
                <v-col cols="6" class="pl-2">
                    <v-row class="pb-2">
                        <v-card height="45vh" width="50vw" class="pa-4 overflow-y-auto">
                            <v-card-title class="d-flex justify-center align-center">
                                Update Costs
                            </v-card-title>
                            <v-select
                                :items="costCategories"
                                v-model="selectedCostCategory"
                                item-text="Name"
                                item-value="Cost ID">
                                <template v-slot:append-item>
                                    <v-text-field
                                        class="px-4"
                                        v-model="newCostCategory"
                                        label="New Cost Category"
                                        hint="Click '+' to Create Category">
                                        <template v-slot:append>
                                            <v-btn
                                                color="primary"
                                                icon
                                                :disabled="!newCostCategory.length"
                                                @click="createCostCategory()">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </template>
                            </v-select> 
                            <v-textarea
                                height="25vh"
                                v-model="costData"
                                label="CSV Copy/Paste"
                                hint="No Headers Plz"/>
                            <v-card-actions class="d-flex justify-center align-center">
                                <v-btn
                                    color="primary"
                                    :disabled="!costData.length"
                                    :loading="costUpdateLoading"
                                    @click="updateCosts()">
                                    Update
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-row>
                    <v-row class="pt-2">
                        <v-card height="45vh" width="50vw" class="pa-4 overflow-y-auto" :loading="classificationLoading">
                            <v-card-title class="d-flex justify-center align-center">
                                Matching Classifications to Cost Categories
                            </v-card-title>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Classification</th>
                                        <th>Cost Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in branchClassifications" :key="index">
                                        <td>
                                            {{ row.Classification }}
                                        </td>
                                        <td>
                                            <v-select
                                                @change="createClassification('update', row.Id, row.Cost_Category)"
                                                v-model="row.Cost_Category"
                                                :items="costCategories"
                                                item-text="Name"
                                                item-value="Cost ID"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <v-text-field
                                                label="New Classification"
                                                v-model="newClassification.Classification"
                                                hide-details/>
                                        </td>
                                        <td>
                                            <v-row align="center">
                                                <v-col cols="6" class="d-flex justify-center align-center">
                                                    <v-select
                                                        label="Cost Category"
                                                        v-model="newClassification.Cost_Category"
                                                        :items="costCategories"
                                                        hide-details
                                                        item-text="Name"
                                                        item-value="Cost ID"/>
                                                </v-col>
                                                <v-col cols="2" class="d-flex justify-center align-center">
                                                    <v-btn
                                                        color="primary"
                                                        :disabled="!newClassification.Classification.length || !newClassification.Cost_Category"
                                                        @click="createClassification('insert', newClassification.Classification, newClassification.Cost_Category)">
                                                        Create
                                                    </v-btn>
                                                </v-col>
                                        </v-row>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>

                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch("updateShowWaffle", true);
        document.title = "Sandbox - Inventory Report";
        this.$store.dispatch("updateNavbarContent", [
            { title: "Report", path: "/Ops/InventoryReport/Home", icon: "mdi-home", notification: false },
            { title: "Purchases", path: "/Ops/InventoryReport/Purchases", icon: "mdi-currency-usd", notification: false },
            { title: "Settings", path: "/Ops/InventoryReport/Settings", icon: "mdi-cog", notification: false },
            { title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
        ]);
        this.$store.dispatch("updateNavbarTitle", "Inventory Report - Branches");
        this.getSettingsData()
    },
    data() {
        return {
            costCategories: [],
            subCategories: [],
            excludedSubCategories: [],
            newCostCategory: '',
            selectedCostCategory: 1,
            costData: '',
            branches: [],
            branchClassifications: [],
            menu: false,
            newClassification: {
                Classification: '',
                Cost_Category: ''
            },
            subCategoryLoading: false,
            classificationLoading: false,
            costUpdateLoading: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getSettingsData() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_GetAllBranches?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/branches',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    branches,
                    costCategories,
                    branchClassifications,
                    subCategories,
                    excludedSubCategories
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                this.subCategories = subCategories.map(item => item['Sub Category'])
                this.excludedSubCategories = excludedSubCategories.map(item => item.Sub_Category)
                this.costCategories = costCategories
                this.branches = branches
                this.branchClassifications = branchClassifications
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async createClassification(type, classification, costCategory) {
            try {
                this.classificationLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_CreateBranchClassification?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/branches',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            type: type,
                            classification: classification,
                            costCategory: costCategory
                        })
                    }
                )
                const {
                    message,
                    branchClassifications
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.branchClassifications = branchClassifications
                this.newClassification.Classification = ''
                this.newClassification.Cost_Category = ''
                this.classificationLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.classificationLoading = false
                this.snack(error,'error',3000)
            }
        },
        async updateSubCategories(action, subCategory) {
            try {
                console.log(action);
                let type = ''
                if (action) {
                    type = 'delete'
                } else {
                    type = 'insert'
                }
                this.subCategoryLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_EditExcludedSubCategories?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/subcategories',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            type: type,
                            subCategory: subCategory
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.subCategoryLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.subCategoryLoading = false
                this.snack(error,'error',3000)
            }
        },
        async updateBranch(branch, classification) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_UpdateBranch?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/branches',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: branch,
                            classification: classification
                        })
                    }
                )
                const {
                    message,
                    branches
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.branches = branches
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateCosts() {
            try {
                this.costUpdateLoading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_UpdateCosts?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/costs',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            update: this.csvParsed
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.costData = ''
                this.costUpdateLoading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.costUpdateLoading = false
                this.snack(error,'error',3000)
            }
        },
        async createCostCategory() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/InventoryReport_CreateCostCategory?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/inventoryreport/costs',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            costCategory: this.newCostCategory
                        })
                    }
                )
                const {
                    message,
                    costCategories
                } = await result.json()
                
                if(!result.ok) {
                    throw message
                }
                this.costCategories = costCategories
                this.newCostCategory = ''
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        csvParsed() {
            let rows = this.costData.split('\n')
            let update = []
            for (let i = 0; i < rows.length; i++) {
                let attributes = rows[i].split("\t")
                let sku = attributes[0]
                let cost = Number(attributes[1])
                if (cost) {
                    if (attributes.length === 3) {
                        let listId = Number(attributes[2])
                        update.push({
                            'Cost ID': this.selectedCostCategory,
                            List_Id: listId,
                            SKU: sku,
                            Cost: cost
                        })
                    } else {
                        update.push({
                            'Cost ID': this.selectedCostCategory,
                            List_Id: 1,
                            SKU: sku,
                            Cost: cost
                        })
                    }
                    
                }
            }
            return update
        },
        distinctClassifications() {
            return [... new Set(this.branches.map(item => item.Inventory_Classification))]
        },
        formattedSubCategories() {
            let subCategories = []
            for (let i = 0; i < this.subCategories.length; i++) {
                let included = true
                if (this.excludedSubCategories.includes(this.subCategories[i])) {
                    included = false
                }
                subCategories.push({
                    Included: included,
                    Sub_Category: this.subCategories[i]
                })
            }
            return subCategories
        },
        headers() {
            return [
                {
                    text: 'Branch',
                    align: 'start',
                    value: 'Company'
                },
                {
                    text: 'Classification',
                    align: 'start',
                    value: 'Inventory_Classification'
                }
            ]
        },
        subCategoryHeaders() {
            return [
                {
                    text: 'Included',
                    align: 'start',
                    value: 'Included'
                },
                {
                    text: 'Sub Category',
                    align: 'start',
                    value: 'Sub_Category'
                }
            ]
        }
    }
}
</script>

<style>

</style>