var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-row',{staticClass:"pt-8 px-8"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{attrs:{"items":_vm.salesManagers,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._v(" Sales Managers ")]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['First Name'])+" "+_vm._s(item['Last Name'])+" ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://clearmanlabs.com/#/NDA?manager=' + item['User ID']}},[_vm._v("https://clearmanlabs.com/#/NDA?manager="+_vm._s(item['User ID'])+" ")])]}},{key:"item.emails",fn:function(ref){
var rep = ref.item;
return [_c('v-select',{attrs:{"multiple":"","chips":"","loading":rep.loading,"items":rep.emails},on:{"change":function($event){return _vm.updateExtraEmails(rep)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"label":"Email","dense":"","rules":[function (v) { return !!v || 'Email is required'; }, function (v) { return !_vm.validateEmail(v) || 'Email must be valid'; }]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","text":"","icon":"","disabled":_vm.validateEmail(rep.newEmail)},on:{"click":function($event){return _vm.updateExtraEmails(rep)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}],null,true),model:{value:(rep.newEmail),callback:function ($$v) {_vm.$set(rep, "newEmail", $$v)},expression:"rep.newEmail"}})],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-list-item-title',[_vm._v(_vm._s(item))]),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"primary","text":"","icon":""},on:{"click":function($event){return item.emails.splice(index, 1)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{attrs:{"label":"","color":"primary","small":""}},[_vm._v(" "+_vm._s(item)+" ")]):(index >= 2)?[_c('span',{staticClass:"grey--text text--lighten-1"},[_vm._v("+"+_vm._s(rep.emails.length - 2)+" others")])]:_vm._e()]}}],null,true),model:{value:(rep.emails),callback:function ($$v) {_vm.$set(rep, "emails", $$v)},expression:"rep.emails"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{attrs:{"items":_vm.salesReps,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._v(" Sales Reps ")]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Agency)+" ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://clearmanlabs.com/#/NDA?rep=' + item.Id}},[_vm._v("https://clearmanlabs.com/#/NDA?rep="+_vm._s(item.Id)+" ")])]}},{key:"item.emails",fn:function(ref){
var rep = ref.item;
return [_c('v-select',{attrs:{"multiple":"","chips":"","loading":rep.loading,"items":rep.emails},on:{"change":function($event){return _vm.updateExtraEmails(rep)}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"label":"Email","dense":"","rules":[function (v) { return !!v || 'Email is required'; }, function (v) { return !_vm.validateEmail(v) || 'Email must be valid'; }]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","text":"","icon":"","disabled":_vm.validateEmail(rep.newEmail)},on:{"click":function($event){return _vm.updateExtraEmails(rep)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}],null,true),model:{value:(rep.newEmail),callback:function ($$v) {_vm.$set(rep, "newEmail", $$v)},expression:"rep.newEmail"}})],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-list-item-title',[_vm._v(_vm._s(item))]),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"primary","text":"","icon":""},on:{"click":function($event){return item.emails.splice(index, 1)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('v-chip',{attrs:{"label":"","color":"primary","small":""}},[_vm._v(" "+_vm._s(item)+" ")]):(index >= 2)?[_c('span',{staticClass:"grey--text text--lighten-1"},[_vm._v("+"+_vm._s(rep.emails.length - 2)+" others")])]:_vm._e()]}}],null,true),model:{value:(rep.emails),callback:function ($$v) {_vm.$set(rep, "emails", $$v)},expression:"rep.emails"}})]}}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackColor},model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }