<template>
    <v-app>
        <v-main>
            <v-row class="py-4" justify="center">
                <v-card width="75vw" class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Match Generated Leads With Accounts
                    </v-card-title>
                    <v-row justify="center" align="center">
                        <v-checkbox
                            label="Show Only Unmatched Leads"
                            v-model="onlyUnmatched"
                            color="primary"/>
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :search="search"
                            :items="filteredLeads">
                            <template v-slot:top>
                                <v-text-field
                                v-model="search"
                                label="Search"
                                class="mx-4"/>
                            </template>
                        <template v-slot:[`item.Account_Id`]="{ item }">
                            <v-autocomplete
                                :items="accounts"
                                @change="assignAccount(item)"
                                v-model="item.Account_Id"
                                item-text="Company"
                                item-value="Id"/>
                        </template>
                        </v-data-table>
                    </v-row>
                </v-card>
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    async beforeMount() {
        document.title = 'Matador Matching Leads'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Matador Matching Leads')
        await this.getInfo()
    },
    data() {
        return {
            leads: [],
            accounts: [],
            search: '',
            onlyUnmatched: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/LeadGeneration_GetAllLeads?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/leadgeneration',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    leads,
                    accounts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.leads = leads
                this.accounts = accounts
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async assignAccount(lead) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/LeadGeneration_MatchLeads?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/leadgeneration',
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            lead: lead.Id,
                            account: lead.Account_Id
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        filteredLeads() {
            if (this.onlyUnmatched) {
                return this.leads.filter(item => item.Account_Id == null)
            } else {
                return this.leads
            }
        },
        headers() {
            return [
                {
                    text: 'Lead Company',
                    align: 'start',
                    sortable: true,
                    value: 'Company',
                },
                {
                    text: 'Lead Email',
                    align: 'start',
                    sortable: true,
                    value: 'Email',
                },
                {
                    text: 'Assigned Account',
                    align: 'start',
                    sortable: true,
                    value: 'Account_Id',
                },
            ]
        }
    }
}
</script>

<style>

</style>