<template>
  <v-app>
    <v-main>
      <productList></productList>
    </v-main>
  </v-app>
</template>

<style src="../stylesheet.css" scoped></style>

<script>

import productList from '../components/Products/productList.vue'

export default {
  name: 'Products',
  beforeMount() {
    document.title = 'Sandbox - Forecast Tool'
    this.$store.dispatch('updateShowWaffle', true)
    this.$store.dispatch('updateNavbarContent',
      [
        {title: 'Home', path: '/sales/forecast/home', icon: 'mdi-home', notification: false},
        {title: 'Assign Lists', icon: 'mdi-account-box', path: '/sales/forecast/accounts', notification: false},
        {title: 'Products', icon: 'mdi-package-variant-closed', path: '/sales/forecast/products', notification: false},
        {title: 'Forecast Categories', icon: 'mdi-shape', path: '/sales/forecast/categories', notification: false},
        {title: 'Bulk Upload', icon: 'mdi-upload', path: '/sales/forecast/bulk-upload', notification: false},
        {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
      ]
    )
    
    this.$store.dispatch('updateNavbarTitle','Forecast Tool')
  },
  components: {
    productList,
  },
};
</script>
