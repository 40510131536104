var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-row',{staticClass:"pt-8",attrs:{"justify":"center","align":"center"}},[_c('v-card',{attrs:{"width":"75vw"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Batch Review "),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":"Show Completed","hide-details":""},model:{value:(_vm.showCompleted),callback:function ($$v) {_vm.showCompleted=$$v},expression:"showCompleted"}})],1),_c('v-card-subtitle',[_vm._v(" Batch statuses can NOT update automatically and the page has to be refreshed to collect new status information. ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"show-expand":"","single-expand":false,"expand":_vm.expanded,"item-key":"Id","page":_vm.page,"items":_vm.filteredBatches,"loading":_vm.loading},on:{"update:expand":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"icon":"mdi-emoticon-cool-outline"}},[_vm._v(" All batches have been completed ")])]},proxy:true},{key:"item.Started_Time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimeToMST(item.Started_Time))+" ")]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.colorMap[item.Status]}},[_vm._v(" "+_vm._s(item.Status)+" ")])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [(!item.retry)?_c('v-btn',{attrs:{"disabled":item.Status !== 'Failed',"color":"primary","icon":""},on:{"click":function($event){return _vm.retryBatch(item.Id)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v("Accounts in Batch")]),_c('v-list',_vm._l((_vm.accountsInBatches[item.Id]),function(account){return _c('v-list-item',{key:account.id},[_c('v-list-item-content',[_c('router-link',{attrs:{"to":'/sales/crm/accounts/' + account.id}},[_c('v-list-item-title',[_vm._v(_vm._s(account.company))])],1)],1)],1)}),1)],1)],1),(item.Status === 'Failed')?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v("Errors")]),_c('v-card-text',[_vm._v(_vm._s(item.Message))])],1)],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('v-card',{staticClass:"flex-column justify-center align-center",attrs:{"flat":""}},_vm._l((_vm.findBatchSteps(item.Id)),function(step,index){return _c('v-tooltip',{key:index + 'Step',attrs:{"bottom":"","disabled":step.Message === null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.colorMap[step.Result]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(step.Stage)+" ")]),(index !== 1)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-chevron-right")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(step.Result)),_c('br'),_vm._v(" "+_vm._s(step.Message)+" ")])])}),1)],1)]}}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackColor},model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }