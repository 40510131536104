<template>
    <v-app>
        <v-main>
            <v-row class="px-8 pt-4" justify="center" align="center">
                <v-text-field class="pl-4 pr-2" v-model="newEmployee.name" label="Name"/>
                <v-text-field class="px-4" v-model="newEmployee.email" label="Email"/>
                <v-text-field class="px-4" v-model="newEmployee.password" label="Password"/>
                <v-menu :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            icon 
                            color="primary">
                            <v-icon>mdi-lock</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-chip-group multiple column v-model="newEmployee.permissions" active-class="primary">
                            <span>
                            <v-tooltip v-for="(permission, index) in permissions" 
                                :key="index" 
                                bottom
                                v-model="permission.Show">
                                <template v-slot:activator="{ on }">
                                    <v-chip @hover="permission.Show = !permission.Show" :value="permission" v-on="on">
                                    <!-- <v-chip v-for="(permission, index) in permissions" :key="index"> -->
                                        {{ permission.Service }}
                                    </v-chip>
                                </template>
                                <span>{{ permission.Description }}</span>
                            </v-tooltip>
                            </span>
                        </v-chip-group>
                    </v-card>
                </v-menu>
                <div class="flex-column justify-center align-center px-2">
                    <div>PTO Color</div>
                    <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="primary"
                            icon
                            v-bind="attrs"
                            v-on="on">
                                <v-icon class="d-flex justify-center align-center" :color="newEmployee.PTOColor">mdi-circle</v-icon>
                            </v-btn>
                        </template>
                        <v-color-picker v-model="newEmployee.PTOColor"/>
                    </v-menu>
                </div>
                <v-btn color="primary" :disabled="newEmployeeDisabled" @click="createEmployee()">
                    Submit
                </v-btn>
            </v-row>
            <v-row class="px-8" width="90vw" justify="center" align="center">
                <v-progress-linear
                    v-if="this.loading"
                    indeterminate
                    color="primary"/>
                <v-simple-table width="90vw" :loading="loading">
                    <thead>
                        <th>Active</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Permissions</th>
                        <th>PTO Color</th>
                        <th>Employee Dollars</th>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in employees" :key="index">
                            <td>
                                <v-switch
                                    v-model="item.Active"
                                    color="primary"
                                    @change="updateEmployee(item)"/>
                            </td>
                            <td>
                                <v-text-field
                                    v-model="item.Username"
                                    label="Username"
                                    @change="updateEmployee(item)"
                                    hide-details/>
                            </td>
                            <td>
                                <v-text-field
                                    v-model="item.Email"
                                    label="Email"
                                    @change="updateEmployee(item)"
                                    hide-details/>
                            </td>
                            <td>
                                <v-text-field
                                    v-model="item.Password"
                                    label="Password"
                                    @change="updateEmployee(item)"
                                    hide-details/>
                            </td>
                            <td>
                                <v-menu :close-on-content-click="false" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            v-bind="attrs"
                                            v-on="on"
                                            icon 
                                            color="primary">
                                            <v-icon>mdi-lock</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-chip-group multiple column v-model="item.Permissions" active-class="primary">
                                            <span >
                                            <v-tooltip v-for="(permission, index) in permissions" 
                                                :key="index" 
                                                bottom
                                                v-model="permission.Show">
                                                <template v-slot:activator="{ on }">
                                                    <v-chip @hover="permission.Show = !permission.Show" :value="permission" v-on="on" close @click:close="item.Permissions.splice(item.Permissions.indexOf(permission),1)">
                                                    <!-- <v-chip v-for="(permission, index) in permissions" :key="index"> -->
                                                        {{ permission.Service }}
                                                    </v-chip>
                                                </template>
                                                <span>{{ permission.Description }}</span>
                                            </v-tooltip>
                                            <v-btn color='primary' @click="updateEmployee(item)" :loading="loading">Update</v-btn>
                                            </span>
                                        </v-chip-group>
                                    </v-card>
                                </v-menu>
                            </td>
                            <td>
                                <v-menu offset-y :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="primary"
                                        icon
                                        v-bind="attrs"
                                        v-on="on">
                                            <v-icon class="d-flex justify-center align-center" :color="item.Identifying_Color">mdi-circle</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-color-picker v-model="item.Identifying_Color " @input="updateEmployee(item)"/>
                                </v-menu>
                            </td>
                            <td>
                                <v-text-field
                                    v-model="item.Employee_Dollars"
                                    label="Employee Dollars"
                                    prefix="$"
                                    @change="updateEmployee(item)"
                                    hide-details/>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-row>
            <v-snackbar
                v-model="snackActive"
                :color="snackColor"
                :timeout="snackTimeout">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - Manage Employees'
        this.$store.dispatch('updateNavbarTitle','Manage Employees')
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateShowWaffle', true)
        this.getPermissions()
    },
    watch: {
        'newEmployee.name': function() {
            if (this.newEmployee.name.length > 0) {
                this.randomPassword()
            }
        }
    },
    data() {
        return {
            newEmployee: {
                name: '',
                email: '',
                password: '',
                colorPreset: 0,
                PTOColor: '#000000',
                permissions: []
            },
            selectedEmployee: {},
            employees: [],
            permissions: [],
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        getEmployees() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/employees'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }

                for (let i=0; i < data.employees.length; i++) {
                    data.employees[i].Permissions = data.employeePermissions.filter(item => 
                        item.Employee_Id === data.employees[i].Id)
                        .map(function(element) {
                            delete element.Employee_Id
                            element.Show = false
                            return element
                        });
                }
                this.employees = data.employees
                this.loading = false
                this.snack(data.message,'success',3000)
            }).catch(error => {
                this.loading = false
                this.snack(error,'error',3000)
                console.log(error)
            })
        },
        getPermissions() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/employees/permissions'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.permissions = data.permissions
                this.permissions.forEach(item => this.$set(item, 'Show', false))
                this.loading = false
                this.getEmployees()
                this.snack(data.message,'success',3000)
            }).catch(error => {
                this.loading = false
                this.snack(error,'error',3000)
                console.log(error)
            })
        },
        updateEmployee(employee) {
            //lambda function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_UpdateEmployee?tab=code
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/employees'
            const data = {
                employee: employee
            }
            const request = {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.snack(data.message,'success',3000)
                this.loading = false
            }).catch(error => {
                this.snack(error,'error',3000)
                this.loading = false
                console.log(error)
            })
        },
        createEmployee() {
            //lambda function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Sandbox_CreateEmployee?tab=code
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/employees'
            const data = {
                employee: this.newEmployee
            }
            const request = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.newEmployee = {
                    name: '',
                    email: '',
                    password: '',
                    colorPreset: 0,
                    PTOColor: '#000000',
                    permissions: []
                }
                this.getEmployees()
                this.snack(data.message,'success',3000)
                this.loading = false
            }).catch(error => {
                this.snack(error,'error',3000)
                this.loading = false
                console.log(error)
            })
        },
        randomPassword() {
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            let password = ''
            for (let i = 0; i < 4; i++) {
                password += characters.charAt(Math.floor(Math.random() * characters.length))
            }
            this.newEmployee.password = password
        },
        snack(text, color, timeout) {
            this.snackText = text
            this.snackColor = color
            this.snackTimeout = timeout
            this.snackActive = true
        },
    },
    computed: {
        newEmployeeDisabled() {
            if (this.newEmployee.name.length &&
                this.newEmployee.email.length &&
                this.newEmployee.password.length &&
                this.employees.map(item => item.Identifying_Color).indexOf(this.newEmployee.PTOColor) === -1) {
                    return false
                } else {
                    return true
                }
        },
        
    }
}
</script>

<style>

</style>