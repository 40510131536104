<template>
  <v-app>
      <v-main class="d-flex align-center">
          <v-img src="../../assets/notDonePic.jpg" max-width="30vw"/>
          <div class="d-flex justify-center text-h5">Austin Has Not Completed This Page Yet...</div>
      </v-main>
  </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateNavbarTitle','Whoops')
        this.$store.dispatch('updateShowWaffle', true)
    }
}
</script>

<style>

</style>