<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="primary">mdi-download</v-icon>
        </v-btn>
      </template>

      <v-card class="d-flex-column justify-center align-center">
        <v-card-title class="text-h5">
          Save Forecast
        </v-card-title>
        <v-card-text justify="center" align="center" class="d-flex-column">
            <v-img src="../../../../../assets/save.jpg"/>
            <div class="py-2 text-h5 black--text">
                <div><u>Latest Version</u></div>
                <div>'{{ previousForecastVersion.Name }}': {{ previousForecastVersion.Date }}</div>
            </div>
            Once you save this forecast, it can not be edited.<br>
            Only save once you know you are 100% done with this round of forecasting<br>
            If you wish to continue, add a Forecast View Name and SEND IT!
        </v-card-text>
        <v-text-field
            class="px-4 pb-4"
            v-model="forecastViewTitle"
            hide-details
            label="Forecast View Name"/>
        <v-divider></v-divider>

        <v-card-actions>
            <v-btn
                color="accent"
                @click="dialog = false"
            >
            i regret my actions
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :disabled="!forecastViewTitle.length"
            @click="sendIt()"
            :loading="loading"
          >
            SEND IT
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar
          v-model="snackActive"
          :color="snackColor"
          :timeout="snackTimeout">
          {{ snackText }}
      </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            forecastViewTitle: '',
            previousForecastVersion: {},
            loading: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    watch: {
        dialog: function() {
            if (this.dialog) {
                this.getLastestVersion()
            }
        }
    },
    methods: {
        sendIt() {
            const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/saveforecast?forecastViewName=' + this.forecastViewTitle
            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.loading = false
                this.dialog = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })

        },
        getLastestVersion() {
            const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/saveforecast'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.previousForecastVersion = data.previousForecastVersion[0]
                this.loading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    }
}
</script>

<style>

</style>