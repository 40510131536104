<template>
  <v-app id="app">
    <template v-if="loggedIn">
      <v-navigation-drawer
        class="secondary lighten-4"
        v-model="drawer"
        app
        clipped
        temporary>
        <v-card class="flex" color="secondary">
          <v-card-title class="justify-space-between" style="color: white">
            <router-link to="/admin/user" style="text-decoration: none; color: white;">
              <v-icon color="white" class="pr-3">mdi-account-edit-outline</v-icon>
              {{ username }}
            </router-link>
              <v-btn icon>
              <v-icon @click="logOut()" color="white">mdi-logout-variant</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-card color="secondary">
          <router-link to="/" style="text-decoration: none; color: white;">
            <v-card-title class="justify-center align-center">
              <v-icon class="pr-2" color="white">mdi-home-outline</v-icon>
              Home
            </v-card-title>
          </router-link>
        </v-card>
        <waffle/>
      </v-navigation-drawer>
    </template>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon v-if="showWaffle" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-h5 text-no-wrap" style="overflow: visible; text-overflow: clip; width: 300px;">
            {{ navbarTitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tabs
            icons-and-text
            @input="change(link.path)"
            right>
            <v-tab v-for="(link,index) in navbarContent" 
            style="text-decoration: none;"
            :key="index"
            :to="link.path">
            <div v-if="link.notification" class="d-flex-column justify-end">
                <v-badge
                  dot
                  color="red">
                  <v-icon>{{ link.icon }}</v-icon>
                </v-badge>
                  <div>{{ link.title }}</div>
            </div>
            <div v-else class="d-flex-column justify-space-between">
              <v-icon>{{ link.icon }}</v-icon>
              <div>{{ link.title }}</div>
            </div>
            </v-tab>
        </v-tabs>
    </v-app-bar>
    <router-view/>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import waffle from './components/Admin/waffle.vue';

export default {
  components: { waffle },
  name: 'App',
  beforeMount() {
    this.$vuetify.theme.themes.light.primary = this.color.primary
    this.$vuetify.theme.themes.light.secondary = this.color.secondary
    this.$vuetify.theme.themes.light.accent = this.color.accent
  },
  data: () => ({
    drawer: false,
    closeDrawer: true,
  }),
  methods: {
    logOut() {
      this.drawer = false
      this.$store.dispatch('updateLoggedIn', false)
      localStorage.loggedIn = false
      this.$router.push('/admin/login')
    },
  },
  computed: {
    ...mapGetters({
      navbarTitle: 'getNavbarTitle',
      navbarContent: 'getNavbarContent',
      username: 'getUsername',
      color: 'getColor',
      loggedIn: 'isLoggedIn',
      showWaffle: 'getShowWaffle'
    })
  }
};
</script>
