<template>
    <v-app>
        <v-main>
            <v-row class="pt-8 px-8">
                <v-col cols="6">
                    <v-data-table
                        :items="salesManagers"
                        :headers="headers">
                        <template v-slot:top class="center-text">
                            Sales Managers
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                            {{ item['First Name'] }} {{ item['Last Name'] }}
                        </template>
                        <template v-slot:[`item.link`]="{ item }">
                            <a :href="'https://clearmanlabs.com/#/NDA?manager=' + item['User ID']">https://clearmanlabs.com/#/NDA?manager={{ item['User ID']}} </a>
                        </template>
                        <template v-slot:[`item.emails`]="{ item: rep }">
                            <v-select
                                multiple
                                chips
                                :loading="rep.loading"
                                @change="updateExtraEmails(rep)"
                                v-model="rep.emails"
                                :items="rep.emails">
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-text-field
                                                v-model="rep.newEmail"
                                                label="Email"
                                                dense
                                                :rules="[v => !!v || 'Email is required', v => !validateEmail(v) || 'Email must be valid']">
                                                <template v-slot:append>
                                                    <v-btn
                                                        color="primary"
                                                        text
                                                        icon
                                                        :disabled="validateEmail(rep.newEmail)"
                                                        @click="updateExtraEmails(rep)">
                                                        <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-slot:item="{ item, index }">
                                    <div class="d-flex justify-space-between align-center">
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                        <v-list-item-action>
                                            <v-btn
                                                color="primary"
                                                text
                                                icon
                                                @click="item.emails.splice(index, 1)">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </div>
                                </template>
                                <!-- only show the first two selected and then a plus icon -->
                                <template v-slot:selection="{ item, index }">
                                    <v-chip
                                        v-if="index < 2"
                                        label
                                        color="primary"
                                        small>
                                        {{ item }}
                                    </v-chip>
                                    <template v-else-if="index >= 2">
                                        <span class="grey--text text--lighten-1">+{{ rep.emails.length - 2 }} others</span>
                                    </template>
                                </template>
                            </v-select>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="6">
                    <v-data-table
                        :items="salesReps"
                        :headers="headers">
                        <template v-slot:top class="center-text">
                            Sales Reps
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                            {{ item.Agency }}
                        </template>
                        <template v-slot:[`item.link`]="{ item }">
                            <a :href="'https://clearmanlabs.com/#/NDA?rep=' + item.Id">https://clearmanlabs.com/#/NDA?rep={{ item.Id}} </a>
                        </template>
                        <template v-slot:[`item.emails`]="{ item: rep }">
                            <v-select
                                multiple
                                chips
                                :loading="rep.loading"
                                @change="updateExtraEmails(rep)"
                                v-model="rep.emails"
                                :items="rep.emails">
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-text-field
                                                v-model="rep.newEmail"
                                                label="Email"
                                                dense
                                                :rules="[v => !!v || 'Email is required', v => !validateEmail(v) || 'Email must be valid']">
                                                <template v-slot:append>
                                                    <v-btn
                                                        color="primary"
                                                        text
                                                        icon
                                                        :disabled="validateEmail(rep.newEmail)"
                                                        @click="updateExtraEmails(rep)">
                                                        <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <template v-slot:item="{ item, index }">
                                    <div class="d-flex justify-space-between align-center">
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                        <v-list-item-action>
                                            <v-btn
                                                color="primary"
                                                text
                                                icon
                                                @click="item.emails.splice(index, 1)">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </div>
                                </template>
                                <!-- only show the first two selected and then a plus icon -->
                                <template v-slot:selection="{ item, index }">
                                    <v-chip
                                        v-if="index < 2"
                                        label
                                        color="primary"
                                        small>
                                        {{ item }}
                                    </v-chip>
                                    <template v-else-if="index >= 2">
                                        <span class="grey--text text--lighten-1">+{{ rep.emails.length - 2 }} others</span>
                                    </template>
                                </template>
                            </v-select>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - NDA Links'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','NDA Links')
        this.getNDALinkDetails()
    },
    data() {
        return {
            salesManagers: [],
            salesReps: [],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        validateEmail(email) {
            //https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
            var re = /\S+@\S+\.\S+/
            return !re.test(email)
        },
        async getNDALinkDetails() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/NDA_GetAllLinkDetails?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/nda/links',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
            
                const {
                    message,
                    salesManagers,
                    salesReps,
                    extraEmails
                } = await result.json()
                this.salesManagers = salesManagers
                this.salesReps = salesReps
                //add the field newEmail to each sales rep
                for (let i = 0; i < this.salesReps.length; i++) {
                    this.salesReps[i].newEmail = ''
                    this.salesReps[i].loading = false
                    this.salesReps[i].emails = extraEmails.filter(item => item.Related_Id === this.salesReps[i].Id).map(item => item.Email)
                }
                //add the field newEmail for each sales manager
                for (let i = 0; i < this.salesManagers.length; i++) {
                    this.salesManagers[i].newEmail = ''
                    this.salesManagers[i].loading = false
                    this.salesManagers[i].emails = extraEmails.filter(item => item.Related_Id === this.salesManagers[i]['User ID']).map(item => item.Email)
                }
                this.loading = false

                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateExtraEmails(item) {
            try {
                if (item.newEmail.length) {
                    item.emails.push(item.newEmail)
                }
                item.newEmail = ''
                item.menu = false

                item.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/nda/extra-emails')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/NDA_UpdateExtraEmails?newFunction=true&tab=code
                    url,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            item: item
                        })
                    }
                )
                const {
                    message,
                    emails
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                for (let i = 0; i < this.salesManagers.length; i++) {
                    this.salesManagers[i].emails = emails.filter(item => item.Related_Id === this.salesManagers[i]['User ID']).map(item => item.Email)
                }
                for (let i = 0; i < this.salesReps.length; i++) {
                    this.salesReps[i].emails = emails.filter(item => item.Related_Id === this.salesReps[i].Id).map(item => item.Email)
                }

                item.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                item.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name'
                },
                {
                    text: 'Link',
                    align: 'start',
                    value: 'link'
                },
                {
                    text: 'Additional Emails',
                    align: 'start',
                    value: 'emails'
                }
            ]
        }
    }
}
</script>

<style>

</style>