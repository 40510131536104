<template>
    <v-app>
        <v-main>
            <v-row class="px-12 py-6" justify="space-around" align="center">
                <v-text-field
                    class="pr-6"
                    v-model="search"
                    label="Search"
                    hide-details="auto"/>
                <v-switch
                    class="pl-6"
                    v-model="onlyEmpty"
                    color="primary"
                    hide-details="auto"
                    label="Only Show N/A Categories"/>
            </v-row>
            <v-simple-table :loading="loading">
                <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Sales Forecast Name
                        </th>
                        <th class="text-left">
                            Ops Forecast Name
                        </th>
                        <th class="text-left">
                            Ops Group
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in filteredForecastGroups"
                        :key="item.Forecast_Id">
                            <td>
                                {{ item.Forecast_Name }}
                            </td>
                            <td>
                                <v-text-field
                                    v-model="item.Ops_Forecast"
                                    @change="updateForecastGroup(item)"
                                    hide-details/>
                            </td>
                            <td>
                                <v-text-field
                                    v-model="item.Ops_Group"
                                    @change="updateForecastGroup(item)"
                                    hide-details/>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-snackbar
                v-model="snackActive"
                :color="snackColor"
                :timeout="snackTimeout">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Stock Market - Manage Ops Groups'
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Home', path: '/Ops/StockMarket/Home', icon: 'mdi-home', notification: false},
            {title: 'Manage Groups', path: '/ops/stockmarket/manage', icon: 'mdi-account-group', notification: false},
            {title: 'Manage Branch Transfers', path: '/ops/stockmarket/manageBranchTransfers', icon: 'mdi-source-branch', notification: false},
            {title: 'Manage Branches', path: '/ops/stockmarket/manageBranches', icon: 'mdi-warehouse', notification: ""}
        ])
        this.$store.dispatch('updateNavbarTitle','Manage Ops Groups')
        this.getForecastGroups()
    },
    data() {
        return {
            forecastGroups: [],
            search: '',
            onlyEmpty: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        getForecastGroups() {
            //https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_GetOpsGroups?tab=code
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/managegroups'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.forecastGroups = data.data.reverse()
                this.loading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        updateForecastGroup(group) {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/managegroups   '
            const data = {
                group: group
            }
            const request = {
                method: 'PUT',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.loading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        filteredForecastGroups() {
            if (this.search.length && !this.onlyEmpty) {
                return this.forecastGroups.filter(item => 
                    item.Forecast_Name.toLowerCase().includes(this.search.toLowerCase()) ||
                    item.Ops_Group.toLowerCase().includes(this.search.toLowerCase()) ||
                    item.Ops_Forecast.toLowerCase().includes(this.search.toLowerCase())
                )
            } else if (!this.search.length && this.onlyEmpty) {
                return this.forecastGroups.filter(item => 
                    item.Ops_Forecast === "N/A"
                )
            } else if (this.search.length && this.onlyEmpty) {
                return this.forecastGroups.filter(item => 
                    item.Ops_Forecast === "N/A" ||
                    item.Forecast_Name.toLowerCase().includes(this.search.toLowerCase()) ||
                    item.Ops_Group.toLowerCase().includes(this.search.toLowerCase()) ||
                    item.Ops_Forecast.toLowerCase().includes(this.search.toLowerCase())
                )
            } else {
                return this.forecastGroups
            }
        }
    }
}
</script>

<style>

</style>