<template>
    <v-app>
        <v-main>
            <v-row class="pt-8">
                <v-col cols="6">
                    <v-data-table
                        :items="assignedProducts"
                        :headers="headers">
                        <template v-slot:top>
                            Assigned
                        </template>
                        <template v-slot:[`item.Parent`]="{ item }">
                            <v-row align="center" justify="start">
                                <v-img max-height="50px" max-width="50px" :src="item.Image"/>
                                <v-text-field
                                    v-model="item.Parent_Name"
                                    :hint="item.Parent_Id"
                                    :hide-details="false"
                                    label="Shown Product Name"/>
                                    <v-btn>
                                        Refresh Pictures
                                    </v-btn>
                            </v-row>
                        </template>
                        <template v-slot:[`item.Child`]="{ item }">
                            <v-chip-group>
                                <v-chip 
                                    v-for="(children, index) in item.Children" 
                                    :key="index">
                                    {{ children.Child_Id }}
                                </v-chip>
                            </v-chip-group>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="6">
                    <v-data-table
                        :items="unassignedProducts"
                        :headers="unassignedProductsHeaders">
                        <template v-slot:top>
                            Unassigned
                        </template>
                        <template v-slot:[`item.Image`]="{ item }">
                            <v-img :src="item.Image" max-width="50px" max-height="50px"/>
                        </template>
                        <template v-slot:[`item.Action`]="child">
                            <v-btn
                                chip
                                @click="assign(child.item.sku, child.item.sku)">
                                Make Parent
                            </v-btn>
                            <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="primary"
                                    chip
                                    dark
                                    v-bind="attrs"
                                    v-on="on">
                                    Make Child
                                    </v-btn>
                                </template>
                                <v-card class="pa-4">
                                    <v-autocomplete
                                        :items="allParents"
                                        v-model="selectedParent"
                                        label="Avaiable Parents"
                                        @input="assign(selectedParent, child.item.sku); selectedParent = ''"/>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
            <v-menu
                v-model="childCard"
                :close-on-content-click="false"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y>
                <v-card class="pa-4">
                    
                </v-card>
            </v-menu>
        </v-main>
    </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - Manage Store'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Manage Store')
        this.getDetails()
    },
    data() {
        return {
            products: [],
            selectedParent: '',
            productHierarchy: [],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getDetails() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/managestore/products',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
            
                const {
                    products,
                    productHierarchy,
                    message,
                } = await result.json()
                
                this.products = products
                this.productHierarchy = productHierarchy

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        assign(parent, child) {
            this.productHierarchy.push({
                Parent_Id: parent,
                Child_Id: child,
                Color: '#000000'
            })
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        allParents() {
            return this.productHierarchy.map(item => item.Parent_Id)
        },
        assignedProducts() {
            let products = []
            let tracker = []

            for (let p = 0; p < this.productHierarchy.length; p++) {
                
                let parent = this.productHierarchy[p].Parent_Id
                let child = this.productHierarchy[p].Child_Id
                let parentDetails = this.products.find(item => item['Style Code'] === parent)
                let childDetails = this.products.find(item => item['Style Code'] === child)
                let image = ''
                if (parentDetails.Images && parentDetails.Images.length) {
                    image = JSON.parse(parentDetails.Images)[0]
                } else {
                    image = '../../../assets/placeholder.jpg'
                }
                if (tracker.indexOf(parent) === -1) {
                    products.push({
                        Image: image,
                        Parent_Id: parent,
                        Parent_Name: parentDetails['Product Name'],
                        Parent_Option: parentDetails['Option 1'],
                        Children: [
                            {
                                Child_Id: child,
                                Child_Option: childDetails['Option 1']
                            }
                        ]
                    })
                    tracker.push(parent)
                } else {
                    products.find(item => item.Parent_Id === parent).Children.push({
                        Child_Id: child,
                        Child_Option: childDetails['Option 1']
                    })
                }
            }
            return products
        },
        unassignedProducts() {
            let parents = this.productHierarchy.map(item => item.Parent_Id)
            let children = this.productHierarchy.map(item => item.Child_Id)
            let allAssignedProducts = parents.concat(children) 
            let unassignedProducts = []
            for (let p = 0; p < this.products.length; p++) {
                let product = this.products[p]
                if (allAssignedProducts.indexOf(product['Style Code']) === -1) {
                    let image = ''
                    if (product.Images && product.Images.length) {
                        image = JSON.parse(product.Images)[0]
                    } else {
                        image = '../../../assets/placeholder.jpg'
                    }
                    unassignedProducts.push({
                        sku: product['Style Code'],
                        Option_1: product['Option 1'],
                        Image: image
                    })
                } 
            }
            return unassignedProducts
        },
        headers() {
            return [
                {
                    text: 'Parent Product',
                    align: 'start',
                    value: 'Parent'
                },
                {
                    text: 'Child Product',
                    align: 'start',
                    value: 'Child'
                },
            ]
        },
        unassignedProductsHeaders() {
            return [
                {
                    text: 'Image',
                    align: 'start',
                    value: 'Image',
                    width: '50px'
                },
                {
                    text: 'Product',
                    align: 'start',
                    value: 'sku'
                },
                {
                    text: 'Option',
                    align: 'start',
                    value: 'Option_1'
                },
                {
                    text: 'Action',
                    align: 'start',
                    value: 'Action'
                },
            ]
        }
    }
}
</script>

<style>

</style>