<template>
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card class="pa-4">
            <customTitle 
                :title="'Forecast Tool'" 
                :name="'forecastTool'" 
                @close="$emit('close')"/>
            <v-card-text>
                <p>
                    The Forecast Tool is a tool that allows you to see the historical sales for a specific product and input qtys per month, per product, per year, per forecast category. 
                </p>
                <p>
                    The Forecast Tool is broken into 6 main sections:
                </p>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>1. Home Page</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-title class="d-flex justify-center align-center">
                                Year and Category Selection
                            </v-card-title>
                            <v-card-text>
                                Working from upper left to lower right, the home page is dependent on the Year and Category selected in the upper left corner. Once those two are selected, the table will display all products and their historical sales data separated by months. 
                            </v-card-text>
                            <v-card-title class="d-flex justify-center align-center">
                                <v-icon color="primary">mdi-cog</v-icon> Settings
                            </v-card-title>
                            <v-card-text>
                                The settings button will open a dialog that will allow you to change the following: (all of these are also shown when hovering over the settings toggle)
                                <ul>
                                    <li><strong><u>Sticky Total Row:</u></strong> Keep the total row for the whole category at the bottom of your screen</li>
                                    <li><strong><u>Collapse After Submit:</u></strong> When using the detail dialog input (not speed round) turning this option on will automatically close the pop up after submiting the forecast.</li>
                                    <li><strong><u>Speed Round:</u></strong> Edit directly in the table without pulling up the detail dialog</li>
                                    <li><strong><u>Save on Month Change:</u></strong> When changing months within the detail dialog (using the arrow keys or selecting a new month), turning this on will save any changes made to the forecast when switching</li>
                                    <li><strong><u>Hide Inactive Products:</u></strong> This will hide any products are are inactive. If this is turned off, inactive products will show in red and are not editable</li>
                                    <li><strong><u>Show Price Warning:</u></strong> If there is a different cost/price associated with the selected forecast than the one found in the categories current selected cost/price list there will be a warning that pops up telling you such. To diable this warning, turn off this setting.</li>
                                </ul>
                            </v-card-text>
                            <v-card-title class="d-flex justify-center align-center">
                                Cost and Price Category
                            </v-card-title>
                            <v-card-text>
                                The Cost and Price category next to the Year and Category selectors show which price and cost lists are being used for inputed forecasts. 
                                <strong>If this selection is wrong, all inputed forecasts will contain the wrong price/cost and therefore the wrong revenue/profit targets.</strong> 
                                To edit the price and cost lists, click the <v-icon>mdi-pencil</v-icon> next to the category. This will take you to the Assign Lists page where you will need to reassign accounts specific price and cost lists, keeping the heirarchy shown when hovering over the cost/price categories on the home page in mind. 
                                The first cost/price category in the list assigned to an account within the forecast category will be the one used for the forecast. For Example the following three accounts are all within the same forecast category: <br>
                                <ul>
                                    <li>Account A has "Ocean DDP" costs and "Amazon.com" pricing</li>
                                    <li>Account B has "Ocen DDP" costs and "Wholesale" pricing</li>
                                    <li>Account C has "ExWorks Hong Kong" costs and "Wholesale" pricing</li>
                                </ul>
                                In this example, the forecast category would select "Ocean DDP" costs and "Wholesale" pricing since those ones are the first in the cost and price category list.<br>
                                If you wanted to change this, you would have to reassign the cost and price lists for Account A and B.
                            </v-card-text>
                            <v-card-title class="d-flex justify-center align-center">
                                <v-progress-circular :value="75" color="primary" class="mr-4" :width="3"/>Goal
                            </v-card-title>
                            <v-card-text>
                                The goal graph is to show what the current revenue projection is for the year for the selected category. The denominator of the goal is set in the <a href="/#/sales/forecast/categories" target="_blank"><v-icon>mdi-shape</v-icon> Forecast Category</a>. 
                                You can only edit the current year or next year. There is no way to edit previous years.
                            </v-card-text>
                            <v-card-title class="d-flex justify-center align-center">
                                <v-icon color="primary">mdi-table</v-icon>Main Table
                            </v-card-title>
                            <v-card-text>
                                The main table is what shows all the information for the selected category and year. The table is can only be sorted by product name. <br>
                                Each month as two columns, historical data and forecast data. The historical data is the first column for the month and shows the previous 3 years of sales (including this year) in the following format:<br>
                                <ul>
                                    <li>Two Years Ago: Qty</li>
                                    <li>Two Years Ago: Revenue</li>
                                    <li>Last Year: Qty</li>
                                    <li>Last Year: Revenue</li>
                                    <li>This Year: Qty</li>
                                    <li>This Year: Revenue</li>
                                </ul>
                                The second column for the month shows the forecasted data for the selected year in the following format: <br>
                                <ul>
                                    <li>Forecast Qty</li>
                                    <li>Forecasted Percent Increase from the previous years actual qty. Equation = (This Years Forecast - Last Years Actual) / Last Years Actual</li>
                                    <li>Forecast Revenue</li>
                                </ul>
                                This is repeated for each month in the year. Between June and July there is are two columns for the first half of the year that totals up all the data from Jan - June.<br>
                                The second half of the year is totaled right before the total for the entire year at the far right side of the table.
                            </v-card-text>
                            <v-card-title class="d-flex justify-center align-center">
                                Detail Dialog
                            </v-card-title>
                            <v-card-text>
                                When speed round is turned off, clicking on a cell in the main table will open the detail dialog. 
                                The detail dialog is where you can see the historical sales data for a product in a graphical display. 
                                The previous 3 years (including this year) are displayed on a graph along with the forecasted data for the selected year.
                                <ul>
                                    <li>
                                        Explain Chart: This is pull all the company specific data regarding the product being shown on the graph. After the data has been loaded, you can hover over each year/month to see the break down of which companies purchased how many of the viewed product in that month/year. 
                                        This feature is less useful for a category like "REI" where there is only one company, but is very useful for a category like "B2B General" where there are many companies.
                                    </li>
                                    <li>
                                        <v-icon>mdi-compare-horizontal</v-icon> Compare: This will allow you to select another product/year to overlay on the graph. This is helpful for if there is a product family you would like to see compared against each other on the graph. 
                                        This information is cleared when the detail dialog is closed.
                                    </li>
                                    <li>
                                        The month selector lets you change months directly in the detail dialog without exiting to the home page. This can also be done using the arrow keys.
                                    </li>
                                    <li>
                                        The Price and Cost card in the bottom middle of the detail dialog, shows the List name (FW24, SS25) at the top followed by the specific price and cost for the product. 
                                        These numbers can be individually changed, however this only impacts a single product/month and will not persist product/month changes.
                                    </li>
                                    <li>
                                        The bottom right card of the detail dialog is where the discount and qty is input. The discount is a percentage off the price and is used to calculate the revenue. The qty is the forecasted qty for the product in the selected month. The calculated revenue is shown at the top of this card.
                                    </li>
                                    <li>
                                        Finally, notes can be added to the product/month. These notes are saved and can be viewed by selecting the <v-icon>mdi-dots-vertical</v-icon> in the notes field.
                                    </li>
                                </ul>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>2. Account Assignment</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>
                                This is where you can assign specific accounts to specific forecast categories, price, and cost lists. 
                                This is essentially the relevant CRM data for the forecast tool. 
                                Anything that is changed here is also changed in the CRM, Cin7, <strong>and if its the price list thats changed, Envoy.</strong>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>3. Product Management</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>
                                The top section of this page is where you can manage which Product Categories are pulled into the forecast tool. These are all pulled from product assignments in Cin7. Active categories are included in the forecast tool and inactive products are not pulled in. 
                                This tool is not meant to allow you to add or edit products, just as a way to understand why a product is or is not showing up in the forecast tool. <br>
                                The 'Should Be Included' column displays if the system is including the product in the forecast tool or not. If the value in the column is 'No', you can hover over the 'No' to view why the product is not being included. <br>
                                If there is something you would like to change about a product, please do so in Cin7 and then navigate to the <a href="/#/ops/envoy/vpl" target="_blank">VPL</a> and select 'Refresh' next to 'Products: Cin7 > Database'
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>4. Category Management</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>
                                This is where you can manage which Forecast Categories are pulled into the forecast tool. You are able to edit/add forecast categories here, including if they are active, preseason eligible, their name, and their yearly revenue goal.
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>5. Bulk Uploads</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            This is where you can download a CSV template and then edit the forecast data in Excel. Once you have made your changes, you can upload the CSV back into the forecast tool and it will update the forecast data for the selected year and category. <br>
                            PLEASE READ THE RED WARNING BEFORE DOING SO
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>6. Post Forecast Data Flow (SGT, Stock Market)</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            The forecast tool is reading from a 'Holding table' that allows for quick edits without processing the graduality that our other tool require (SGT, Stock Market). 
                            Once an edit is made on the Forecast Tool, the holding table is updated which triggers a function to execute on the database that breaks that data into the account level data needed for the other tools. 
                            This change is relatively instant, but if there is a lot of data being processed, like with a bulk upload, it can take a few minutes to update. <br>
                            When using tools like the Stock Market and the SGT, they need to be refreshed (either the page or the data) after there has been any changes to the forecast tool.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import customTitle from '/src/components/PAM/pages/title.vue';
export default {
    name: 'Forecast-Tool',
    components: {
        customTitle
    },
    props: {
        dialog: Boolean
    }
}
</script>

<style>

</style>