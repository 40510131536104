<template>
    <v-app>
        <v-main class="d-flex justify-center align-center px-8">
            <v-img src="../../../../../assets/homePicture.jpg" max-width="100vw" max-height="50vh" class="pa-4"/>
            <v-card class="pa-4" :loading="loading">
                <template v-if="reference.Company_Name">
                    <v-card-title class="d-flex justify-center align-center">
                        {{ reference.Company_Name }} Credit Reference for {{ account }}
                    </v-card-title>
                    <v-row class="px-4">
                        <v-col class="px-8">
                            <v-row class="px-3">
                                <v-menu
                                    ref="openDateMenu"
                                    v-model="openDateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="reference.Account_Open_Date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="reference.Account_Open_Date"
                                            label="Account Open Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"/>
                                    </template>
                                    <v-card color="primary">
                                        <v-card-title class="d-flex justify-center align-center white--text">Account Opened Date</v-card-title>
                                        <v-date-picker
                                            v-model="reference.Account_Open_Date"
                                            no-title
                                            scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="secondary"
                                                @click="openDateMenu = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                @click="$refs.openDateMenu.save(reference.Account_Open_Date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-card>
                                </v-menu>
                            </v-row>
                            <v-row class="px-3">
                                <v-select
                                    label="Terms"
                                    :class="termsClass"
                                    :items="terms"
                                    v-model="termChoice"
                                    hide-details/>
                                <v-text-field
                                    v-if="termChoice == 'Net'"
                                    class="pl-2"
                                    label="Net Length"
                                    v-model="termLength"
                                    type="number"
                                    hide-details/>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="reference.High_Credit"
                                        class="pr-2"
                                        prefix="$"
                                        type="number"
                                        hide-details
                                        label="High Credit"/>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="reference.Current_Balance"
                                        type="number"
                                        prefix="$"
                                        hide-details
                                        class="pl-2"
                                        label="Current Balance"/>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-menu
                                    ref="lastOrderDateMenu"
                                    v-model="lastOrderDateMenu"
                                    :close-on-content-click="false"
                                    :return-value.sync="reference.Last_Order_Date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="reference.Last_Order_Date"
                                            label="Last Order Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"/>
                                    </template>
                                    <v-date-picker
                                        v-model="reference.Last_Order_Date"
                                        no-title
                                        scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="secondary"
                                            @click="lastOrderDateMenu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="$refs.lastOrderDateMenu.save(reference.Last_Order_Date)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="reference.Amount_Past_Due"
                                    type="number"
                                    prefix="$"
                                    hide-details
                                    label="Amount Past Due"/>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="reference.Average_Days_To_Pay"
                                    type="number"
                                    hide-details
                                    label="Average Days To Pay"/>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-textarea
                                height="100"
                                class="pt-10"
                                hide-details
                                v-model="reference.Comments"
                                label="Comments"/>
                        </v-col>
                    </v-row>
                    <v-card-actions class="d-flex justify-center align-center pt-4">
                        <v-btn
                            color="primary"
                            @click="sendResponse()"
                            :disabled="$v.$invalid"
                            :loading="sendLoading">
                            Submit
                        </v-btn>
                    </v-card-actions>
                </template>
            </v-card>
        </v-main>
        <v-dialog
            persistent
            v-model="warningDialog"
            width="80vw">
            <v-card>
                <v-card-title class="d-flex justify-center align-center">
                    Invalid URL
                </v-card-title>
                <v-card-text class="text-center">
                    Looks like your URL is invalid. Please contact <a href="mailto:customerservice@matadorequipment.com">customerservice@matadorequipment.com</a> if you believe this to be a mistake.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            v-model="confirmationDialog"
            width="80vw">
            <v-card>
                <v-card-title class="d-flex justify-center align-center">
                    Thank You!
                </v-card-title>
                <v-card-text class="text-center">
                    Thank you for submitting a credit reference for {{ account }}. You can refresh or revisit this page if you would like to update the form at any point.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-app>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
    beforeMount() {
        document.title = 'Matador Credit Reference'
        this.$store.dispatch('updateShowWaffle', false)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Matador Credit Reference')
    },
    created() {
        if(this.$route.query.id) {
            this.getReference(this.$route.query.id)
        } else {
            this.warningDialog = true
        }
    },  
    mixins: [validationMixin],
    validations() {
        return {
            termChoice: { required },
            termLength: { required },
            reference: {
                Account_Open_Date: { required },
                Amount_Past_Due: { required },
                Average_Days_To_Pay: { required },
                High_Credit: { required },
                Current_Balance: { required },
                Last_Order_Date: { required }
            }
        }
    },
    data() {
        return {
            terms: ['Net','COD','Prepay'],
            termChoice: '',
            termLength: 30,
            confirmationDialog: false,
            warningDialog: false,
            lastOrderDateMenu: false,
            openDateMenu: false,
            reference: {},
            account: '',
            sendLoading: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getReference(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_GetCreditReference?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/creditreference?id=' + id,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
            
                const {
                    message,
                    reference,
                    account
                } = await result.json()

                if (reference.length == 0) {
                    this.loading = false
                    this.warningDialog = true
                    return
                }
                this.reference = reference[0]
                this.account = account[0].Company
                if (this.reference.Account_Open_Date) {
                    this.reference.Account_Open_Date = this.reference.Account_Open_Date.split('T')[0]
                }
                if (this.reference.Last_Order_Date) {
                    this.reference.Last_Order_Date = this.reference.Last_Order_Date.split('T')[0]
                }
                if (this.reference.Terms && this.reference.Terms.includes("Net")) {
                    this.termChoice = 'Net'
                    this.termLegth = this.reference.Terms.substring(3,-1)
                } else {
                    this.termChoice = this.reference.Terms
                }
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.warningDialog = true
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async sendResponse() {
            try {
                this.sendLoading = true
                if (this.termChoice == "Net") {
                    this.reference.Terms = this.termChoice + this.termLength.toString()
                } else {
                    this.reference.Terms = this.termChoice
                }
                let id = this.reference.Id
                delete this.reference.Id

                this.reference.Account_Open_Date = this.reference.Account_Open_Date.split('T')[0]
                this.reference.Last_Order_Date = this.reference.Last_Order_Date.split('T')[0]
                
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_CreditReferenceResponse?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/creditreference',
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            id: id,
                            reference: this.reference
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()

                if (!result.ok) {
                    throw message
                }
                this.confirmationDialog = true
                this.sendLoading = false
            } catch (error) {
                this.sendLoading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        termsClass() {
            if (this.termChoice == 'Net') {
                return 'pr-2'
            } else {
                return ''
            }
        }
    }
}
</script>

<style>

</style>