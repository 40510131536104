<template>
    <v-container fluid class="flex-column justify-center">
        <v-card md="auto" class="mt-2" elevation="0">
            <v-row class="mt-2 d-flex justify-center align-center">
                <v-col md="auto">
                    <v-text-field
                    v-model="newCategory.name"
                    label="Create New Category">
                    </v-text-field>
                </v-col>
                <v-col md="auto">
                    <v-switch
                        color="primary"
                        v-model="newCategory.preorder"
                        label="Pre Order Eligible"
                    ></v-switch>
                </v-col>
                <v-col md="auto">
                    <v-select
                        label="Goal Year"
                        :items="[this.year, this.year + 1]"
                        v-model="selectedYear"/>
                </v-col>
                <v-col md="auto">
                    <v-text-field
                        v-model="newCategory.goal"
                        number
                        label="Goal"
                    ></v-text-field>
                </v-col>
                <v-col md="auto">
                    <v-btn 
                        @click="createNewCategory"
                        :disabled="newCategory.name.length == 0"
                        color='primary'
                    >Submit</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="snackColor"
        >
            <template v-if="!loading">
                {{ snackText}}
            </template>
            <template v-if="loading">
                <v-text-field
                    color="white"
                    :loading="loading"
                ></v-text-field>
            </template>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    text
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    beforeMount() {
        this.year = new Date()
        this.year = this.year.getFullYear()
        this.selectedYear = this.year
    },
    data() {
        return {
            newCategory: {
                name: '',
                preorder: false,
                goal: 0,
            },
            selectedYear: 0,
            year: 0,
            loading: false,
			snackbar: false,
			snackColor: '',
			snackText: '',
			timeout: 3000,
        }
    },
    methods: {
        createNewCategory: function() {
            const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/categories'
            const data = {
                Category: this.newCategory.name,
                Preorder: this.newCategory.preorder,
                Year: this.selectedYear,
                Goal: this.newCategory.goal
            }
            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
            this.snack('','primary',true)
            fetch(url,request).then(async response => {
                
                const data = await response.json()

                if (!response.ok) {
                    const error = data && data.message || response.status
                    Promise.reject(error)
                }

                this.$emit('input', data.categories)
                this.newCategory.preorder = 0
                this.newCategory.name = ''
                this.newCategory.goal = 0

                this.snack('Success','success',false)
                this.newCategoryName = ''
            }).catch(error => {
                this.snack(error,'error',false)
            })
        },
        snack(text, color, loading) {
			if (loading) {
				this.loading = true
				this.snackbar = true
				this.snackText = text
				this.snackColor = color
				this.timeout = -1
			} else {
				this.loading = false
				this.snackbar = true
				this.snackText = text
				this.snackColor = color
				this.timeout = 2000
			}
		},
    }
}
</script>

<style>

</style>