var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2"},[_c('v-row',{staticClass:"mx-2 mb-6",attrs:{"justify":"center","align":"center"}},[_c('v-col',[_vm._v("Categories in Forecast")]),_vm._l((_vm.allCategories),function(item,index){return _c('v-col',{key:index},[_c('v-switch',{attrs:{"label":item.category,"color":"primary"},on:{"change":function($event){return _vm.updateActiveProductCategories(item.active,item.category)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)})],2),_c('v-row',{staticClass:"mx-2",attrs:{"justify":"center","align":"center"}},[_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.productData},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.Active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"readonly":"","true-value":1,"false-value":0,"color":"primary"},model:{value:(item.Active),callback:function ($$v) {_vm.$set(item, "Active", $$v)},expression:"item.Active"}})]}},{key:"item.included",fn:function(ref){
var item = ref.item;
return [(item.included === 'No')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.included)+" ")])]}}],null,true)},[_c('span',_vm._l((item.reasons),function(reason,index){return _c('div',{key:index},[_vm._v(_vm._s(reason))])}),0)]):_c('span',[_vm._v(" "+_vm._s(item.included)+" ")])]}}],null,true)})],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }