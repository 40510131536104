<template>
    <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card class="pa-4">
            <customTitle 
                :title="'Printer Setup'" 
                :name="'printerSetup'" 
                @close="$emit('close')"/>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>PC</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        
                                    <ol>
                                        <li>Open Settings > Devices > Printers & Scanners</li>
                                        <li>Select 'Add a printer or scanner'</li>
                                        <li>Wait for 'The printer that I want isn't listed'</li>
                                        <li>Select 'Add printer usering TCP/IP address or hostname'</li>
                                        <li>Fill out the form:</li>
                                        <ul>
                                            <li>Device Type: Autodetect</li>
                                            <li>Hostname or IP address: 10.1.10.76</li>
                                            <li>Port Name: Leave blank</li>
                                            <li>Check 'Query printer and automatically select the driver to use'</li>
                                        </ul>
                                    </ol>
                                    <p>After you've done that, you should be able to print a test page. If that doesn't work, yell at the printer and pray to the printer gods</p>
                                    
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>Mac</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <ol>
                            <li>Select the Apple icon in the upper left corner</li>
                            <li>Select 'about this Mac'</li>
                            <li>Look at what macOS version you're on and keep that in mind (the number not the name)</li>
                            <li>Download the driver from <a href="https://epson.com/Support/Printers/All-In-Ones/ET-Series/Epson-ET-5850/s/SPT_C11CJ29201" target="_blank">this website</a> by selecting the maxOS you found in step 3</li>
                            <li>Save the driver in a spot you will easily be able to find it again</li>
                            <li>Select the Apple icon in the upper left corner</li>
                            <li>Select 'System Settings'</li>
                            <li>Scroll to the bottom on the left bar to Printer & Scanners</li>
                            <li>Select Add Printer</li>
                            <li>Select the globe icon in the upper middle</li>
                            <li>In the Address field type '10.1.10.76'</li>
                            <li>Name the printer an easy to remember name (I like Printer McPrint Face)</li>
                            <li>Seelect the dropdown next to 'Use'</li>
                            <li>Select 'Other'</li>
                            <li>Select the driver you downloaded in step 5</li>
                            <li>Select 'Add'</li>
                            <li>Print a test page</li>
                            <li>If that doesn't work, yell at the printer and pray to the printer gods</li>
                        </ol>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </v-dialog>
</template>

<script>
import customTitle from './title.vue';
export default {
    components: {
        customTitle
    },
    props: {
        dialog: {type: Boolean}
    },
    data() {
        return {
        }
    }
}
</script>

<style>

</style>