<template style="overflow-y: hidden;">
    <v-dialog v-model="dialog" width="100vw">
        <v-card class="pa-4" width="100vw">
            <v-row justify="center" align="center" style="overflow-y: hidden; overflow-x: hidden;">
                <v-col cols="9" class="pr-0 mr-0 pl-4">
                    <GmapMap
                        :center="center"
                        :zoom="zoom"
                        :options="mapOptions"
                        style="height: 80vh">
                        <gmapInfoWindow 
                            :options="infoOptions" 
                            :position="infoWindowPos" 
                            :opened="infoWinOpen" 
                            @closeclick="infoWinOpen=false"/>
                        <gmapInfoWindow 
                            :options="newInfoOptions" 
                            :position="(tempLocation.lat) ? tempLocation : location" 
                            :opened="(tempLocation.lat || location.lat) ? true : false" 
                            @closeclick="infoWinOpen=false"/>
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in filteredMarkers"
                            :position="{lat: m.Lat, lng: m.Lng}"
                            :clickable="true"
                            :draggable="m.Draggable"
                            :icon="{url: require('../../../../assets/marker.png'), scaledSize: {width: 42, height: 53}}"
                            @drag="changeLocation($event.latLng)"
                            @click="toggleInfoWindow(m,index)"/>
                    </GmapMap>
                </v-col>
                <v-col cols="3" class="pl-0 ml-0">
                    <v-card class="pa-4" height="80vh" style="overflow-y:hidden;">
                        <v-text-field
                            v-model="zipCode"
                            :loading="loading"
                            v-on:keyup.enter="searchZipCode()"
                            label="Zip Code"
                            class="pb-2"
                            prepend-icon="mdi-map-marker"
                            @click:prepend="useCurrentLocation()"
                            hide-details/>
                            <v-tabs v-model="tab" class="d-flex justify-start align-start">
                                <v-tab>
                                    All
                                </v-tab>
                                <v-tab>
                                    New
                                </v-tab>
                                <v-tab>
                                    Hints
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab" style="overflow-y: scroll; height: 80%;">
                                <v-tab-item>
                                    <v-list>
                                        <v-list-item 
                                            v-for="(marker, index) in sortedMarkers" 
                                            :key="index" 
                                            @contextmenu="toggleDelete(marker, $event)"
                                            @click="
                                                toggleInfoWindow(marker, marker.Index); 
                                                center = {lat: marker.Lat, lng: marker.Lng};
                                                zoom = 13"
                                            class="flex-column justify-start align-start text-wrap">

                                            <v-list-item-title>{{ marker.Name }}</v-list-item-title>
                                            <v-list-item-subtitle class="text-wrap">{{ marker.Address }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="text-wrap">{{ marker.Phone }}</v-list-item-subtitle>
                                        </v-list-item>
                                    </v-list>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-sheet class="pa-4 flex-column justify-center align-center">
                                        <vuetify-google-autocomplete
                                            class="px-4"
                                            id="addressMap"
                                            label="Enter Address"
                                            hide-details
                                            v-model="Address_Show"
                                            v-on:placechanged="getAddressDetails"/>
                                        <v-text-field
                                            v-model="name"
                                            class="px-4"
                                            label="Name"
                                            hide-details/>
                                        <v-text-field
                                            v-model="Final_Address_Show"
                                            label="Address"
                                            class="px-4"
                                            hide-details/>
                                        <v-text-field
                                            v-model="phone"
                                            label="Phone (Please format as it should be shown)"
                                            class="px-4 pb-2"
                                            hide-details/>
                                        <div class="pl-4">
                                            Lat: {{ (tempLocation.lat) ? tempLocation.lat : location.lat }}
                                        </div>
                                        <div class="pl-4">
                                            Lng: {{ (tempLocation.lng) ? tempLocation.lng : location.lng }}
                                        </div>
                                        <div class="d-flex justify-center align-center py-2">
                                            <v-btn
                                                @click="addAddress()"
                                                color="primary"
                                                small
                                                :disabled="disableNewAddress">
                                                Add
                                            </v-btn>
                                        </div>
                                        <div v-if="tempLocation.lat" class="d-flex justify-center align-center">
                                            <v-btn
                                                @click="tempLocation = {}"
                                                color="primary"
                                                small>
                                                Revert
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-sheet>
                                        Right click to delete
                                    </v-sheet>
                                </v-tab-item>
                            </v-tabs-items>
                    </v-card>
                </v-col>
                <v-menu offset-y :close-on-content-click="false" v-model="deleteMenu" :position-x="x" :position-y="y">
                    <v-card class="pa-4">
                        <v-card-title class="d-flex justify-center align-center">
                            Delete?
                        </v-card-title>
                        <v-card-actions class="d-flex justify-space-around align-center">
                            <v-btn @click="deleteMenu = false">
                                No
                            </v-btn>
                            <v-btn
                                color="primary" 
                                @click="deleteAddress(selectedMarkerToDelete)">
                                Yes
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-snackbar
                    v-model="snackBar"
                    :timeout="timeout"
                    :color="snackColor">
                    {{ snackText }}
                </v-snackbar>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    beforeMount() {
        this.getInfo()
    },
    watch: {
        dialogTrigger() {
            this.dialog = true
        }
    },
    props: {
        dialogTrigger: {type: Number},
        contactId: {type: Number}
    },
    data() {
        return {
            dialog: false,
            markers: [],
            center: {},
            infoWindowPos: null,
            addAddressDialog: false,
            searchLocation: {},
            location: {},
            Address_Show: '',
            Final_Address_Show: '',
            place_id: '',
            phone: '',
            name: '',
            countriesISO3166: [
                { name: "United States of America", code: "US" },
                { name: "United Kingdom of Great Britain and Northern Ireland", code: "GB" },
                { name: "Afghanistan", code: "AF" },
                { name: "Åland Islands", code: "AX" },
                { name: "Albania", code: "AL" },
                { name: "Algeria", code: "DZ" },
                { name: "American Samoa", code: "AS" },
                { name: "Andorra", code: "AD" },
                { name: "Angola", code: "AO" },
                { name: "Anguilla", code: "AI" },
                { name: "Antarctica", code: "AQ" },
                { name: "Antigua and Barbuda", code: "AG" },
                { name: "Argentina", code: "AR" },
                { name: "Armenia", code: "AM" },
                { name: "Aruba", code: "AW" },
                { name: "Australia", code: "AU" },
                { name: "Austria", code: "AT" },
                { name: "Azerbaijan", code: "AZ" },
                { name: "Bahamas", code: "BS" },
                { name: "Bahrain", code: "BH" },
                { name: "Bangladesh", code: "BD" },
                { name: "Barbados", code: "BB" },
                { name: "Belarus", code: "BY" },
                { name: "Belgium", code: "BE" },
                { name: "Belize", code: "BZ" },
                { name: "Benin", code: "BJ" },
                { name: "Bermuda", code: "BM" },
                { name: "Bhutan", code: "BT" },
                { name: "Bolivia (Plurinational State of)", code: "BO" },
                { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
                { name: "Bosnia and Herzegovina", code: "BA" },
                { name: "Botswana", code: "BW" },
                { name: "Bouvet Island", code: "BV" },
                { name: "Brazil", code: "BR" },
                { name: "British Indian Ocean Territory", code: "IO" },
                { name: "Brunei Darussalam", code: "BN" },
                { name: "Bulgaria", code: "BG" },
                { name: "Burkina Faso", code: "BF" },
                { name: "Burundi", code: "BI" },
                { name: "Cabo Verde", code: "CV" },
                { name: "Cambodia", code: "KH" },
                { name: "Cameroon", code: "CM" },
                { name: "Canada", code: "CA" },
                { name: "Cayman Islands", code: "KY" },
                { name: "Central African Republic", code: "CF" },
                { name: "Chad", code: "TD" },
                { name: "Chile", code: "CL" },
                { name: "China", code: "CN" },
                { name: "Christmas Island", code: "CX" },
                { name: "Cocos (Keeling) Islands", code: "CC" },
                { name: "Colombia", code: "CO" },
                { name: "Comoros", code: "KM" },
                { name: "Congo", code: "CG" },
                { name: "Congo (Democratic Republic of the)", code: "CD" },
                { name: "Cook Islands", code: "CK" },
                { name: "Costa Rica", code: "CR" },
                { name: "Côte d'Ivoire", code: "CI" },
                { name: "Croatia", code: "HR" },
                { name: "Cuba", code: "CU" },
                { name: "Curaçao", code: "CW" },
                { name: "Cyprus", code: "CY" },
                { name: "Czech Republic", code: "CZ" },
                { name: "Denmark", code: "DK" },
                { name: "Djibouti", code: "DJ" },
                { name: "Dominica", code: "DM" },
                { name: "Dominican Republic", code: "DO" },
                { name: "Ecuador", code: "EC" },
                { name: "Egypt", code: "EG" },
                { name: "El Salvador", code: "SV" },
                { name: "Equatorial Guinea", code: "GQ" },
                { name: "Eritrea", code: "ER" },
                { name: "Estonia", code: "EE" },
                { name: "Eswatini", code: "SZ" },
                { name: "Ethiopia", code: "ET" },
                { name: "Falkland Islands (Malvinas)", code: "FK" },
                { name: "Faroe Islands", code: "FO" },
                { name: "Fiji", code: "FJ" },
                { name: "Finland", code: "FI" },
                { name: "France", code: "FR" },
                { name: "French Guiana", code: "GF" },
                { name: "French Polynesia", code: "PF" },
                { name: "French Southern Territories", code: "TF" },
                { name: "Gabon", code: "GA" },
                { name: "Gambia", code: "GM" },
                { name: "Georgia", code: "GE" },
                { name: "Germany", code: "DE" },
                { name: "Ghana", code: "GH" },
                { name: "Gibraltar", code: "GI" },
                { name: "Greece", code: "GR" },
                { name: "Greenland", code: "GL" },
                { name: "Grenada", code: "GD" },
                { name: "Guadeloupe", code: "GP" },
                { name: "Guam", code: "GU" },
                { name: "Guatemala", code: "GT" },
                { name: "Guernsey", code: "GG" },
                { name: "Guinea", code: "GN" },
                { name: "Guinea-Bissau", code: "GW" },
                { name: "Guyana", code: "GY" },
                { name: "Haiti", code: "HT" },
                { name: "Heard Island and McDonald Islands", code: "HM" },
                { name: "Holy See", code: "VA" },
                { name: "Honduras", code: "HN" },
                { name: "Hong Kong", code: "HK" },
                { name: "Hungary", code: "HU" },
                { name: "Iceland", code: "IS" },
                { name: "India", code: "IN" },
                { name: "Indonesia", code: "ID" },
                { name: "Iran (Islamic Republic of)", code: "IR" },
                { name: "Iraq", code: "IQ" },
                { name: "Ireland", code: "IE" },
                { name: "Isle of Man", code: "IM" },
                { name: "Israel", code: "IL" },
                { name: "Italy", code: "IT" },
                { name: "Jamaica", code: "JM" },
                { name: "Japan", code: "JP" },
                { name: "Jersey", code: "JE" },
                { name: "Jordan", code: "JO" },
                { name: "Kazakhstan", code: "KZ" },
                { name: "Kenya", code: "KE" },
                { name: "Kiribati", code: "KI" },
                { name: "Korea (Democratic People's Republic of)", code: "KP" },
                { name: "Korea (Republic of)", code: "KR" },
                { name: "Kuwait", code: "KW" },
                { name: "Kyrgyzstan", code: "KG" },
                { name: "Lao People's Democratic Republic", code: "LA" },
                { name: "Latvia", code: "LV" },
                { name: "Lebanon", code: "LB" },
                { name: "Lesotho", code: "LS" },
                { name: "Liberia", code: "LR" },
                { name: "Libya", code: "LY" },
                { name: "Liechtenstein", code: "LI" },
                { name: "Lithuania", code: "LT" },
                { name: "Luxembourg", code: "LU" },
                { name: "Macao", code: "MO" },
                { name: "Macedonia (the former Yugoslav Republic of)", code: "MK" },
                { name: "Madagascar", code: "MG" },
                { name: "Malawi", code: "MW" },
                { name: "Malaysia", code: "MY" },
                { name: "Maldives", code: "MV" },
                { name: "Mali", code: "ML" },
                { name: "Malta", code: "MT" },
                { name: "Marshall Islands", code: "MH" },
                { name: "Martinique", code: "MQ" },
                { name: "Mauritania", code: "MR" },
                { name: "Mauritius", code: "MU" },
                { name: "Mayotte", code: "YT" },
                { name: "Mexico", code: "MX" },
                { name: "Micronesia (Federated States of)", code: "FM" },
                { name: "Moldova (Republic of)", code: "MD" },
                { name: "Monaco", code: "MC" },
                { name: "Mongolia", code: "MN" },
                { name: "Montenegro", code: "ME" },
                { name: "Montserrat", code: "MS" },
                { name: "Morocco", code: "MA" },
                { name: "Mozambique", code: "MZ" },
                { name: "Myanmar", code: "MM" },
                { name: "Namibia", code: "NA" },
                { name: "Nauru", code: "NR" },
                { name: "Nepal", code: "NP" },
                { name: "Netherlands", code: "NL" },
                { name: "New Caledonia", code: "NC" },
                { name: "New Zealand", code: "NZ" },
                { name: "Nicaragua", code: "NI" },
                { name: "Niger", code: "NE" },
                { name: "Nigeria", code: "NG" },
                { name: "Niue", code: "NU" },
                { name: "Norfolk Island", code: "NF" },
                { name: "Northern Mariana Islands", code: "MP" },
                { name: "Norway", code: "NO" },
                { name: "Oman", code: "OM" },
                { name: "Pakistan", code: "PK" },
                { name: "Palau", code: "PW" },
                { name: "Palestine, State of", code: "PS" },
                { name: "Panama", code: "PA" },
                { name: "Papua New Guinea", code: "PG" },
                { name: "Paraguay", code: "PY" },
                { name: "Peru", code: "PE" },
                { name: "Philippines", code: "PH" },
                { name: "Pitcairn", code: "PN" },
                { name: "Poland", code: "PL" },
                { name: "Portugal", code: "PT" },
                { name: "Puerto Rico", code: "PR" },
                { name: "Qatar", code: "QA" },
                { name: "Réunion", code: "RE" },
                { name: "Romania", code: "RO" },
                { name: "Russian Federation", code: "RU" },
                { name: "Rwanda", code: "RW" },
                { name: "Saint Barthélemy", code: "BL" },
                { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
                { name: "Saint Kitts and Nevis", code: "KN" },
                { name: "Saint Lucia", code: "LC" },
                { name: "Saint Martin (French part)", code: "MF" },
                { name: "Saint Pierre and Miquelon", code: "PM" },
                { name: "Saint Vincent and the Grenadines", code: "VC" },
                { name: "Samoa", code: "WS" },
                { name: "San Marino", code: "SM" },
                { name: "Sao Tome and Principe", code: "ST" },
                { name: "Saudi Arabia", code: "SA" },
                { name: "Senegal", code: "SN" },
                { name: "Serbia", code: "RS" },
                { name: "Seychelles", code: "SC" },
                { name: "Sierra Leone", code: "SL" },
                { name: "Singapore", code: "SG" },
                { name: "Sint Maarten (Dutch part)", code: "SX" },
                { name: "Slovakia", code: "SK" },
                { name: "Slovenia", code: "SI" },
                { name: "Solomon Islands", code: "SB" },
                { name: "Somalia", code: "SO" },
                { name: "South Africa", code: "ZA" },
                { name: "South Georgia and the South Sandwich Islands", code: "GS" },
                { name: "South Sudan", code: "SS" },
                { name: "Spain", code: "ES" },
                { name: "Sri Lanka", code: "LK" },
                { name: "Sudan", code: "SD" },
                { name: "Suriname", code: "SR" },
                { name: "Svalbard and Jan Mayen", code: "SJ" },
                { name: "Sweden", code: "SE" },
                { name: "Switzerland", code: "CH" },
                { name: "Syrian Arab Republic", code: "SY" },
                { name: "Taiwan", code: "TW" },
                { name: "Tajikistan", code: "TJ" },
                { name: "Tanzania, United Republic of", code: "TZ" },
                { name: "Thailand", code: "TH" },
                { name: "Timor-Leste", code: "TL" },
                { name: "Togo", code: "TG" },
                { name: "Tokelau", code: "TK" },
                { name: "Tonga", code: "TO" },
                { name: "Trinidad and Tobago", code: "TT" },
                { name: "Tunisia", code: "TN" },
                { name: "Turkey", code: "TR" },
                { name: "Turkmenistan", code: "TM" },
                { name: "Turks and Caicos Islands", code: "TC" },
                { name: "Tuvalu", code: "TV" },
                { name: "Uganda", code: "UG" },
                { name: "Ukraine", code: "UA" },
                { name: "United Arab Emirates", code: "AE" },
                { name: "United States Minor Outlying Islands", code: "UM" },
                { name: "Uruguay", code: "UY" },
                { name: "Uzbekistan", code: "UZ" },
                { name: "Vanuatu", code: "VU" },
                { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
                { name: "Viet Nam", code: "VN" },
                { name: "Virgin Islands (British)", code: "VG" },
                { name: "Virgin Islands (U.S.)", code: "VI" },
                { name: "Wallis and Futuna", code: "WF" },
                { name: "Western Sahara", code: "EH" },
                { name: "Yemen", code: "YE" },
                { name: "Zambia", code: "ZM" },
                { name: "Zimbabwe", code: "ZW" }
            ],
            selectedCountry: '',
            tempLocation: {},
            infoWinOpen: false,
            currentMidx: null,
            tab: 0,
            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -50
                }
            },
            rules: {
                min: value => value > 0 || '0 is the min',
                max: value => value <= 250 || '250 is the max',
            },
            zoom: 13,
            zipCode: '',
            kilometers: false,
            deleteMenu: false,
            x: 0,
            y: 0,
            selectedMarkerToDelete: 0,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_GetMarkers?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/get-markers?email=austin@matadorup.com`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    markers
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.markers = markers
                if (this.markers.length) {
                    this.center = {
                        lat: this.markers[0].Lat,
                        lng: this.markers[0].Lng
                    }
                } else {
                    this.center = {lat: 0, lng: 0}
                }
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async getAddressDetails(addressDetails) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_GetLatLong?tab=code
                    'https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/lat-long?id=' + addressDetails.place_id,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    location,
                    address
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.location = location
                this.center = location
                this.Final_Address_Show = address
                this.place_id = addressDetails.place_id
                this.loading = false
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async addAddress() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_AddAddress?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/add-address?email=austin@matadorup.com`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Lat: (this.tempLocation.lat) ? this.tempLocation.lat : this.location.lat,
                            Lng: (this.tempLocation.lng) ? this.tempLocation.lng : this.location.lng,
                            Place_Id: this.place_id,
                            Address: this.Final_Address_Show,
                            Name: this.name,
                            Phone: this.phone,
                            Contact_Id: this.$props.contactId,
                        })
                    }
                )
                const {
                    message,
                    markers
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                
                this.markers = markers
                
                this.details = ''
                this.name = ''
                this.Final_Address_Show = ''
                this.Address_Show = ''
                this.location = {}
                this.tempLocation = {}
                this.tab = 0

                this.success = true
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async deleteAddress(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_DeleteMarker?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/get-markers?email=austin@matadorup.com&id=${id}`,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    markers
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.markers = markers
                this.deleteMenu = false
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        toggleDelete(marker, event) {
            event.preventDefault()
            if (this.deleteMenu) {
                this.selectedMarkerToDelete = ''
                this.y = 0
                this.x = 0
            } else {
                this.selectedMarkerToDelete = marker.Id
                this.y = event.clientY
                this.x = event.clientX
                this.deleteMenu = true
            }
        },
        toggleInfoWindow: function(marker, idx) {
            this.infoWindowPos = {lat: marker.Lat, lng: marker.Lng}
            if (marker.Phone) {
                this.infoOptions.content = `<h4>${marker.Name}</h4><br/>
                    <div>${marker.Address}</div><br>
                    <div>Phone: ${marker.Phone}</div>`
            } else {
                this.infoOptions.content = `<h4>${marker.Name}</h4><br/>
                    <div>${marker.Address}</div><br>
                    <div>${marker.Details}</div>`
            }

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
              this.infoWinOpen = !this.infoWinOpen;
            }

            //if different marker set infowindow to open and reset current marker index
            else {
              this.infoWinOpen = true;
              this.currentMidx = idx;
            }
        },
        async searchZipCode() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_FindZipCodeGeometry?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/find-zip-code-geometry?email=austin@matadorup.com&zip=${this.zipCode}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    location
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.center = location
                if (this.sortedMarkers.length) {
                    this.center = {lat: this.sortedMarkers[0].Lat, lng: this.sortedMarkers[0].Lng}
                }

                this.loading = false

                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        useCurrentLocation() {
            navigator.geolocation.getCurrentPosition(
            position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
                this.zipCode = 'Your Location'
            },
            error => {
                console.log(error.message)
                this.center = {
                    lat: 0,
                    lng: 0
                }
            })
        },
        changeLocation(location) {
            this.tempLocation = {
                lat: location.lat(),
                lng: location.lng()
            }
        },
        calculateDistance(lat1, lng1) {
            const R = 6371; // Radius of the Earth in kilometers
            const dLat = this.degToRad(this.center.lat - lat1);
            const dLng = this.degToRad(this.center.lng - lng1);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.degToRad(lat1)) * Math.cos(this.degToRad(this.center.lat)) *
                Math.sin(dLng / 2) * Math.sin(dLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = R * c; // Distance in kilometers
            return distance;
        },
        degToRad(degrees) {
            return degrees * (Math.PI / 180);
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        newInfoOptions() {
            let content = '';
            if (this.phone) {
                content = `<h4>${this.name}</h4><br/>
                    <div>${this.Final_Address_Show}</div><br>
                    <div>Phone: ${this.phone}</div>`
            } else {
                content = `<h4>${this.name}</h4><br/>
                    <div>${this.Final_Address_Show}</div>`
            }
            return {
                content: content,
                pixelOffset: {
                    width: 0,
                    height: -50
                }
            }
        },
        filteredMarkers() {
            let copy = JSON.parse(JSON.stringify(this.markers))
            if (this.tempLocation.lat || this.location.lat) {
                let loc = (this.tempLocation.lat) ? this.tempLocation : this.location
                copy.push({
                    Address: this.Final_Address_Show,
                    Contact_Id: this.$props.contactId,
                    Details: this.details,
                    Lat: loc.lat,
                    Lng: loc.lng,
                    Name: this.name,
                    Draggable: true,
                    Color: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                })
            }
            return copy.filter(item => item.Contact_Id === this.$props.contactId)
        },
        distanceMarkers() {
            let copy = JSON.parse(JSON.stringify(this.filteredMarkers))
            return copy.map(obj => {
                obj.Distance = this.calculateDistance(obj.Lat, obj.Lng)
                if (!obj.Color) {
                    obj.Draggable = false,
                    obj.Menu = false,
                    obj.Color = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                }
                return obj
            })
        },
        sortedMarkers() {
            let copy = JSON.parse(JSON.stringify(this.distanceMarkers))
            copy.sort((a,b) => {
                return a.Distance - b.Distance;
            })
            return copy
        },
        mapOptions() {
            return {
                zoomControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false
            }
        },
        disableNewAddress() {
            let loc = (this.tempLocation.lat) ? this.tempLocation : this.location
            if (this.name.length && String(loc.lat).length && this.Final_Address_Show.length) {
                return false
            } else {
                return true
            }
        }
    }
}
</script>

<style>

</style>