<template>
    <v-app>
        <v-main>
            <v-card class="pa-4">
                <v-row justify="center">
                    <v-img src="../../../../../assets/homePicture.jpg" max-width="80vw" max-height="50vh" class="pa-4"/>
                </v-row>
                <v-card-title class="text-h3 ml-4 my-4 d-flex justify-center align-center">
                    Credit Application For {{ details.Company }}
                </v-card-title>
                <v-row class="px-3" justify="center">
                    <v-card width="80vw" class="mb-4 pa-2">
                        <v-card-title class="d-flex justify-center align-center">
                            Banking Information
                        </v-card-title>
                        <v-card-text class="d-flex justify-center align-center">
                            <v-text-field
                                v-model="bankName"
                                :error-messages="requiredError('bankName')"
                                required
                                @input="$v.bankName.$touch()"
                                @blur="$v.bankName.$touch()"
                                class="pl-4 pr-2"
                                label="Bank Name"/>
                            <v-text-field
                                v-model="accountNumber"
                                :error-messages="requiredError('accountNumber')"
                                required
                                @input="$v.accountNumber.$touch()"
                                @blur="$v.accountNumber.$touch()"
                                class="px-4"
                                label="Account Number"/>
                            <v-select
                                v-model="typeOfAccount"
                                :error-messages="requiredError('typeOfAccount')"
                                required
                                @input="$v.typeOfAccount.$touch()"
                                @blur="$v.typeOfAccount.$touch()"
                                class="pl-2 pr-4"
                                label="Type of Account"
                                :items="['Checking','Savings']"/>
                            </v-card-text>
                        </v-card>
                </v-row>
                <v-row justify="center" width="80vw">
                    <v-card width="81vw" class="d-flex justify-center align-center elevation-0 pa-0 ma-0">
                        <v-col v-for="(v, index) in  $v.vendorInformation.$each.$iter" :key="index">
                            <v-card class="mb-4 pa-2">
                                <v-card-title class="d-flex justify-center align-center">
                                    Vendor {{ Number(index) + 1}} Information
                                </v-card-title>
                                <v-text-field
                                    class="pl-4 pr-2"
                                    required
                                    v-model="v.companyName.$model"
                                    :error-messages="requiredErrorArray(index,'companyName')"
                                    @input="v.companyName.$touch()"
                                    @blur="v.companyName.$touch()"
                                    label="Company Name"/>
                                <v-text-field
                                    class="px-4"
                                    :error-messages="requiredErrorArray(index,'primaryContact')"
                                    required
                                    @input="v.primaryContact.$touch()"
                                    @blur="v.primaryContact.$touch()"
                                    v-model="v.primaryContact.$model"
                                    label="Primary Contact"/>
                                <v-text-field
                                    class="px-4"
                                    :error-messages="requiredErrorArray(index,'phone')"
                                    required
                                    @input="v.phone.$touch()"
                                    @blur="v.phone.$touch()"
                                    v-model="v.phone.$model"
                                    label="Phone"/>
                                <v-text-field
                                    class="px-4"
                                    :error-messages="emailErrors(index)"
                                    required
                                    @input="v.email.$touch()"
                                    @blur="v.email.$touch()"
                                    v-model="v.email.$model"
                                    label="email"/>
                                <v-text-field
                                    class="px-4"
                                    :error-messages="requiredErrorArray(index,'country')"
                                    required
                                    @input="v.country.$touch()"
                                    @blur="v.country.$touch()"
                                    v-model="v.country.$model"
                                    label="Country"/>
                            </v-card>
                        </v-col> 
                    </v-card>
                </v-row>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn
                        color="primary"
                        @click="submitCreditApplication()"
                        :hint="completeForm"
                        :loading="loading"
                        :disabled="$v.$invalid">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-dialog 
                v-model="confirmDialog"
                width="50vw"
                persistent>
                <v-card width="50vw">
                    <v-card-title class="d-flex justify-center align-center">
                        <v-icon color="success" class="pr-2">mdi-check-circle</v-icon>
                        Submitted
                    </v-card-title>
                    <v-card-text class="text-center">
                        A form has been sent to the given vendors.<br>Once they fill out the form and a sales manager has reviewed the responses, the sales manager will reach out regarding the status of your application. 
                    </v-card-text>
                    <v-card-text class="text-h5 d-flex justify-center align-center">
                        Please Close This Tab
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="warningDialog"
                width="50vw"
                persistent>
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        Form Already Filled Out
                    </v-card-title>
                    <v-card-text class="d-flex justify-center align-center">
                        If you believe this to be a mistake please reach out to your sales manager or <a href="mailto:sales@matadorequipment.com"> sales@matadorequipment.com </a> with questions.
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="error.dialog"
                width="50vw">
                <v-card class="pa-4">
                    <v-card-title class="d-flex justify-center align-center">
                        {{ error.code }}
                    </v-card-title>
                    <v-card-text class="d-flex justify-center align-center">
                        {{ error.message }}
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center align-center">
                        <v-btn
                            color="primary"
                            @click="error.dialog = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
export default {
    mounted() {
        this.$store.dispatch("updateShowWaffle", false);
        document.title = "Matador Credit Application";
        this.$store.dispatch("updateNavbarContent", []);
        this.$store.dispatch("updateNavbarTitle", "Matador Credit Application");
        if(this.$route.params.id) {
            this.getContactDetails(this.$route.params.id);
        }
    },
    mixins: [validationMixin],
    validations() {
        return {
            bankName: { required },
            accountNumber: { required },
            typeOfAccount: { required },
            vendorInformation: {
                required,
                minLength: minLength(3),
                $each: {
                    companyName: { required },
                    primaryContact: { required },
                    phone: { required },
                    email: { required, email },
                    country: { required },
                }
            }
        }
    },
    data() {
        return {
            details: {},
            confirmDialog: false,
            warningDialog: false,
            bankName: '',
            accountNumber: '',
            typeOfAccount: '',
            vendorInformation: [
                {
                    companyName: '',
                    primaryContact: '',
                    phone: '',
                    email: '',
                    country: ''
                },
                {
                    companyName: '',
                    primaryContact: '',
                    phone: '',
                    email: '',
                    country: ''
                },
                {
                    companyName: '',
                    primaryContact: '',
                    phone: '',
                    email: '',
                    country: ''
                }
            ],
            error: {
                dialog: false,
                code: '',
                message: ''
            },
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getContactDetails(id) {
            try {
                this.loading = true;
                const result = await fetch(
                //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_AccountDetails?newFunction=true&tab=code
                "https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/accounts/details?contactId=" + id, {
                    method: "GET",
                    Headers: {
                        "Content-Type": "application/json",
                    }
                });
                const { 
                    message, 
                    details, 
                } = await result.json();

                if (details[0].Bank_Name) {
                    this.warningDialog = true
                    this.loading = false
                    this.details = details[0];
                } else {
                    this.details = details[0];
                    this.loading = false;
                    this.snack(message, "success", 3000);
                }
            }
            catch (error) {
                this.loading = false;
                this.snack(error, "error", 3000);
            }
        },
        async submitCreditApplication() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_SubmitCreditApplication?newFunction=true&tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/leads/creditreference/submitapplication',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            contactId: this.details.Id,
                            accountId: this.details['Account ID'],
                            companyName: this.details.Company,
                            bankName: this.bankName,
                            accountNumber: this.accountNumber,
                            typeOfAccount: this.typeOfAccount,
                            vendorInformation: this.vendorInformation
                        })
                    }
                )
            
                const {
                    message,
                } = await result.json()
                if (!result.ok) {
                    throw message
                }
                this.confirmDialog = true
                this.loading = false
            } catch (error) {
                this.error.dialog = true
                this.error.code = error.code
                this.error.message = error.sqlMessage
            }
        },
        requiredError(item) {
            const errors = []
            if (!this.$v[item].$dirty) return errors
            !this.$v[item].required && errors.push('Required')
            return errors
        },
        requiredErrorArray(index,item) {
            const errors = []
            if (!this.$v.vendorInformation.$each.$iter[index][item].$dirty) return errors
            !this.$v.vendorInformation.$each.$iter[index][item].required && errors.push('Required')
            return errors
        },
        emailErrors(index) {
            const errors = []
            if (!this.$v.vendorInformation.$each.$iter[index].email.$dirty) return errors
            !this.$v.vendorInformation.$each.$iter[index].email.email && errors.push('Must be valid e-mail')
            !this.$v.vendorInformation.$each.$iter[index].email.required && errors.push('E-mail is required')
            return errors
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
    }
}
</script>

<style>

</style>