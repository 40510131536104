<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <h2>Failed Payments</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-switch v-model="showSuccessful" label="Show Successful Inserts"></v-switch>
                        <v-data-table :headers="headers" :items="filteredPayments" :items-per-page="10" class="elevation-1">
                            <template #item.Status="{ item }">
                                {{ (item.Status === 'Successful') ? 'Yes' : 'No' }}
                            </template>
                            <template #item.loading="{ item }">
                                <v-btn 
                                    v-if="!item.loading"
                                    color="primary" 
                                    icon
                                    @click="retryPayment(item)">
                                    <v-icon>mdi-reload</v-icon> 
                                </v-btn>
                                <v-progress-circular indeterminate v-else/>   
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - Failed Payments'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Failed Payments')
        this.getInfo()
    },
    data() {
        return {
            showSuccessful: false,
            payments: [],
            headers: [
                { text: 'Id', value: 'Id' },
                { text: 'Retry Count', value: 'Count' },
                { text: 'Successful', value: 'Status' },
                { text: 'Actions', value: 'loading' }
            ],
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        };
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/payments')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Accounting_GetFailedPayments?tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    payments
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.payments = payments
                this.payments.forEach(payment => {
                    payment.loading = false
                })
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async retryPayment(paymentObj) {
            try {
                paymentObj.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/accounting/payments/fix')
                url.searchParams.append('id', paymentObj.Id)
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Accounting_StartPaymentRetry?tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                paymentObj.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                paymentObj.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        filteredPayments() {
            if (this.showSuccessful) {
                return this.payments;
            } else {
                return this.payments.filter(payment => payment.Status !== 'Successful');
            }
        },
    },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
