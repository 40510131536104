<template>
    <v-app>
        <v-main class="d-flex">
            <v-row class="pt-8 pb-6" justify="space-around">
                <v-col cols="11">
                    <v-row justify="space-around" align="center">
                        <v-btn
                            @click="downloadNewOrders()"
                            :loading="downloadLoading"
                            color="primary">
                            Download Orders From The Past Day
                        </v-btn>
                        <v-tooltip bottom>
                            <template v-slot:activator="{on,attrs}">
                            <v-btn
                                @click="updateOrders()"
                                :loading="updateLoading"
                                v-bind="attrs"
                                v-on="on"
                                color="primary">
                                Pull Updates Made in Cin7
                            </v-btn>
                            </template>
                            <span class="d-flex justify-center align-center">
                                This button has great power.<br>
                                With great power comes great responsibility.<br>
                                Use only as needed
                            </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{on,attrs}">
                            <v-btn
                                @click="checkEmptyOrders()"
                                :loading="emptyOrdersLoading"
                                v-bind="attrs"
                                v-on="on"
                                color="primary">
                                Check Empty Orders
                            </v-btn>
                            </template>
                            <span class="d-flex justify-center align-center">
                                Click this button if you believe<br>
                                there are orders which do not have lines attached
                            </span>
                        </v-tooltip>
                    </v-row>
                </v-col>
                <v-col cols="1" class="d-flex justify-center">
                    <v-icon
                        color="primary"
                        @click="explainPage = !explainPage">
                        mdi-information-outline
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="space-around">
                <fix-from-account/>
                <fix-from-date-range/>
                <fix-from-order-list/>
            </v-row>
            <v-snackbar
                v-model="snackActive"
                :color="snackColor"
                :timeout="snackTimeout">
                {{ snackText }}
            </v-snackbar>
            <v-overlay 
                :value="explainPage"
                :opacity="1"
                @click="explainPage = !explainPage">
                <v-img 
                    src="../../../../assets/fixingDataInfo.png"
                    max-height="90vh"
                    max-width="90vw"/>
            </v-overlay>
        </v-main>
    </v-app>
</template>

<script>
import fixFromAccount from '../SalesOrders/fixFromAccount.vue'
import fixFromDateRange from '../SalesOrders/fixFromDateRange.vue'
import fixFromOrderList from '../SalesOrders/fixFromOrderList.vue'

export default {
    components: {
        fixFromAccount,
        fixFromDateRange,
        fixFromOrderList
    },
    beforeMount() {
        document.title = 'Sandbox - Fix Database'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', 
            [
                {title: 'Sales Orders', path: '/sales/database/fix/salesorders', icon: 'mdi-currency-usd', notification: false},
                {title: 'Credit Notes', icon: 'mdi-credit-card-sync-outline', path: '/sales/database/fix/creditnotes', notification: false},
                {title: 'Payments', icon: 'mdi-cash-clock', path: '/sales/database/fix/payments', notification: false},
                {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
            ]
        )
        this.$store.dispatch('updateNavbarTitle','Fixing Sales Orders')
    },
    data() {
        return {
            explainPage: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            updateLoading: false,
            downloadLoading: false,
            emptyOrdersLoading: false,
        }
    },
    methods: {
        downloadNewOrders() {
            let d = new Date()
            let yesterday = new Date(d.setDate(d.getDate() - 2))
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/updateorders'
            const data = {
                url: "https://api.cin7.com/api/v1/SalesOrders?where=CreatedDate>='" + yesterday.toISOString().split('T')[0] + "T00:00:00Z'&rows=250&page="
            }
            const request = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.downloadLoading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.downloadLoading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.downloadLoading = false
                this.snack(error, 'error', 3000)
            })
        },
        updateOrders() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/undispatchedorders'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.updateLoading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.updateLoading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.updateLoading = false
                this.snack(error, 'error', 3000)
            })
        },
        checkEmptyOrders() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/checkemptyorders'
            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.emptyOrdersLoading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.emptyOrdersLoading = false
                this.snack(data.message, 'success', 3000)
            }).catch(error => {
                this.emptyOrdersLoading = false
                this.snack(error, 'error', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    }
}
</script>

<style>

</style>