<template>
    <v-dialog
        v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on">
            View Payment Terms
            </v-btn>
        </template>
        <v-card>
            <v-card-actions class="d-flex justify-space-between">
                <v-btn icon @click="openPaymentTerms()">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn icon @click="dialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
            <div class="d-flex justify-center">
                <v-img max-width="50vw" src="../../../../assets/paymentTerms.png"/>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {

    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        openPaymentTerms() {
            window.open("https://www.dropbox.com/s/a3cus690vec4o67/Matador_PaymentInformationForMatador.pdf?dl=0","_blank")
        }
    },
    computed: {

    }
}
</script>

<style>

</style>