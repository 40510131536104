<template>
  <v-container fliud>
      <v-dialog v-model="dialog" style="overflow: hidden;" width="50vw">
        <template v-slot:activator="{ on: dialog, attrs:dialogAttrs }">
            <v-tooltip right>
                <template v-slot:activator="{ on:tooltip, attrs:tooltipAttrs }">
                    <v-btn
                    color="primary"
                    darky
                    v-bind="{...dialogAttrs, ...tooltipAttrs}"
                    v-on="{...dialog, ...tooltip}">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <span>Settings</span>
            </v-tooltip>
        </template>
            <v-card class="pa-4" style="overflow: hidden;" width="50vw">
                <v-card-title class="d-flex justify-center align-center">
                    Settings
                </v-card-title>
                <v-row justify="center" align="center">
                    <v-col>
                        <v-switch
                            v-model="otherSettings.showTotalRow"
                            :change="change()">
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on">Sticky Total Row</span>
                                    </template>
                                    Have total row stick to the bottom as you scroll
                                </v-tooltip>
                            </template>
                        </v-switch>
                        <v-switch
                            v-model="otherSettings.hideModalAfterSubmit"
                            :change="change()">
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on">Collapse After Submit</span>
                                    </template>
                                    After submiting forecast, close forecast window
                                </v-tooltip>
                            </template>
                        </v-switch>
                        <v-switch
                            v-model="otherSettings.speedRound"
                            :change="change()">
                            <template v-slot:label>
                                <v-tooltip color="black" bottom class="d-flex justify-center">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on">Speed Round</span>
                                    </template>
                                    Only show input forecast when clicking of forecast month and product<br>
                                    Keyboard Shortcut: Alt + S
                                </v-tooltip>
                            </template>
                        </v-switch>
                    </v-col>
                    <v-col>
                        <v-switch
                            v-model="otherSettings.saveOnMonthChange"
                            :change="change()">
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on">Save on Month Change</span>
                                    </template>
                                    Automatically save changes when switching months or products
                                </v-tooltip>
                            </template>
                        </v-switch>
                        <v-switch
                            v-model="otherSettings.hideInactiveProducts"
                            :change="change()">
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on">Hide Inactive Products</span>
                                    </template>
                                    Hides all the inactive products from view
                                </v-tooltip>
                            </template>
                        </v-switch>
                        <v-switch
                            v-model="otherSettings.showPriceWarning"
                            :change="change()">
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                        v-bind="attrs"
                                        v-on="on">Show Price Warning</span>
                                    </template>
                                    Will warn user if there is a mismatch between the previous forecasted price and the current price selected
                                </v-tooltip>
                            </template>
                        </v-switch>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="snackColor">
                {{ snackText}}
            <!-- dismiss button -->
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    text
                    @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
  </v-container>
</template>

<script>

export default {
    name:'Settings',
    beforeMount() {
        this.username = localStorage.username
        if(localStorage.otherSettings) {
            this.otherSettings = JSON.parse(localStorage.otherSettings)
        } else {
            localStorage.otherSettings = JSON.stringify(this.otherSettings)
        }
        this.change()
    },
    props: {
        settingsReset: {type: Number},
        doors: {type: Object},
        category: {type: Object},
        year: {type: Number},
    },
    data() {
        return {
            dialog: false,
            username: '',
            otherSettings: {
                showTotalRow: false,
                hideModalAfterSubmit: true,
                speedRound: true,
                saveOnMonthChange: true,
                hideInactiveProducts: true,
                specificForecastProducts: false,
                freezeGrandTotal: false,
                showPriceWarning: false
            },
            snackbar: false,
			snackColor: '',
			snackText: '',
			timeout: 3000,
        }
    },
    methods: {
        changeUsername() {
            localStorage.username = this.username
        },
        change() {
            localStorage.otherSettings = JSON.stringify(this.otherSettings)
            this.$store.dispatch('updateOtherSettings', this.otherSettings)
        }
    },
}
</script>

<style>

</style>