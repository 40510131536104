<template>
  <v-dialog
        v-model="dialog"
        @click:outside="$emit('close')"
        width="75vw">
        <v-card class="pa-4">
            <customTitle 
                :title="'Voiding Branch Transfers Correctly'" 
                :name="'voidingBranchTransfersCorrectly'" 
                @close="$emit('close')"/>
            <v-card-text>
                <p>
                    While voiding a branch transfer seems simple at first, there are few extra steps to make it work with the stock market.
                </p>
                <ul>
                    <li>Change the project name in include the word 'delete' (case insensitive)</li>
                    <li>Change the ETA in the Branch Transfer within Cin7 to before the start of last month</li>
                    <li>Navigate to <a href="/#/ops/stockmarket/managebranchtransfers" target="_blank">Manage Branch Transfers</a> in the stock market</li>
                    <li>Either refresh the ETA date (<v-icon>mdi-calendar-clock-outline</v-icon>) or manually change it (by clicking on the ETA field) to the same date as whats listed in Cin7</li>
                    <li>Select the <v-icon color="red">mdi-delete-outline</v-icon> icon and then select 'send it'</li>
                    <li>Select 'Get Most Recent Branch Transfers' and confirm that the branch transfer did not come over from Cin7</li>
                    <li>The branch transfer will no longer come over to the Sandbox/Stock Market</li>
                </ul>
                <v-card-title class="d-flex justify-center align-center">
                    Reversing the Void
                </v-card-title>
                <ul>
                    <li>Navigate to the voided branch transfer in Cin7</li>
                    <li>Remove the word 'delete' from the project name</li>
                    <li>Change the ETA to a date in the future</li>
                    <li>Navigate to <a href="/#/ops/stockmarket/managebranchtransfers" target="_blank">Manage Branch Transfers</a> in the stock market</li>
                    <li>Select 'Get Most Revent Branch Transfer'</li>
                    <li>The branch transfer will now come over to the Sandbox/Stock Market</li>
                </ul>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import customTitle from './title.vue';
export default {
    name: 'SwapEnvoyOrder',
    components: {
        customTitle
    },
    props: {
        dialog: Boolean
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>

<style>

</style>