<template>
    <v-main>
        <v-row justify="center" align="center" class="pt-8">
            <v-card class="pa-4" width="80vw">
                <v-card-title class="d-flex justify-center align-center">
                    Create a New Account
                </v-card-title>
                <v-form v-model="valid">
                    <v-row class="px-4">
                        <v-text-field
                            class="pr-2"
                            v-model="companyName"
                            label="Company Name"
                            required/>
                        <v-select
                            class="pl-2"
                            v-model="companyPaymentTerms"
                            :items="paymentsTerms"
                            label="Payment Terms"
                            item-value="Payment_Terms"
                            item-text="Payment_Terms"
                            required/>
                    </v-row>
                    <v-row class="px-4">
                        <v-text-field
                            class="pr-2"
                            v-model="firstName"
                            required
                            label="First Name (Required)"/>
                        <v-text-field
                            class="pl-2"
                            v-model="lastName"
                            label="Last Name (Optional)"/>
                    </v-row>
                    <v-row class="px-4">
                        <v-text-field
                            class="pr-2"
                            v-model="accountingEmail"
                            label="Accounting Email"
                            :rules="emailRules"
                            required/>
                        <v-text-field
                            class="pl-2"
                            v-model="shippingEmail"
                            label="Shipping Email (Main Email)"
                            :rules="emailRules"
                            required/>
                    </v-row>
                    <v-row>
                        <vuetify-google-autocomplete
                            class="px-4"
                            autocomplete="new-password"
                            selection-required
                            required
                            v-model="Shipping_Address_Show"
                            id="shippingMap"
                            prepend-icon="mdi-magnify"
                            placeholder="Shipping Address"
                            @click:prepend="openAddressDialog('Shipping Address')"
                            v-on:placechanged="getShippingAddressData"/>
                        <v-checkbox
                            class="px-4"
                            v-model="shippingAccount"
                            label="Shipping Account"/>
                        <v-checkbox
                            class="px-4"
                            v-model='billingAddressSameAsShipping'
                            label="Billing Address Same As Shipping"/>
                    </v-row>
                    <v-row v-if="!billingAddressSameAsShipping">
                        <vuetify-google-autocomplete
                            class="px-4"
                            autocomplete="new-password"
                            selection-required
                            required
                            v-model="Billing_Address_Show"
                            id="billingMap"
                            prepend-icon="mdi-magnify"
                            @click:prepend="openAddressDialog('Billing Address')"
                            placeholder="Billing Address"
                            v-on:placechanged="getBillingAddressData"/>
                    </v-row>
                </v-form>
                <v-card-actions class="d-flex justify-center align-center">
                    <v-btn 
                        color="primary"
                        :disabled="!valid"
                        :loading="loading"
                        @click="submit()">
                        Submit
                    </v-btn>
                </v-card-actions>
                <v-card-actions v-if="cin7Id !== ''" class="d-flex justify-space-around align-center">
                    <v-btn
                        color="accent"
                        @click="openCin7()">
                        To Cin7 CRM
                    </v-btn>
                    <v-btn
                        color="accent"
                        @click="openCin7SalesOrder()">
                        Create New Cin7 Sales Order    
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
        <v-dialog 
            v-model="enterAddressDialog"
            width="50vw"
            persistent>
            <v-card class="overflow-y-auto pa-4" height="110%" width="50vw" :loading="loading">
                <v-card-title class="d-flex justify-center align-center pb-4">
                    Confirm {{ addressType }}
                </v-card-title>
                <v-row v-if="addressType === 'Shipping Address'" justify="center" align="center" class="flex-column" width="100vw">
                    <v-text-field
                        label="Address 1"
                        v-model="shippingAddress.address1"
                        class="px-4"/>
                    <v-text-field
                        label="Address 2"
                        v-model="shippingAddress.address2"
                        class="px-4"/>
                    <v-text-field
                        label="City"
                        v-model="shippingAddress.city"
                        class="px-4"/>
                    <v-text-field
                        label="State (2-Letter Abbrv.)"
                        v-model="shippingAddress.state"
                        class="px-4"/>
                    <v-text-field
                        label="Zip Code"
                        v-model="shippingAddress.zip"
                        class="px-4"/>
                    <v-text-field
                        label="Country (2-Letter Abbrv.)"
                        maxlength="2"
                        v-model="shippingAddress.country"
                        class="px-4"/>
                </v-row>
                <v-row v-else-if="addressType === 'Billing Address'" justify="center" align="center" class="flex-column" width="100vw">
                    <v-text-field
                        label="Address 1"
                        v-model="billingAddress.address1"
                        class="px-4"/>
                    <v-text-field
                        label="Address 2"
                        v-model="billingAddress.address2"
                        class="px-4"/>
                    <v-text-field
                        label="City"
                        v-model="billingAddress.city"
                        class="px-4"/>
                    <v-text-field
                        label="State (2-Letter Abbrv.)"
                        v-model="billingAddress.state"
                        class="px-4"/>
                    <v-text-field
                        label="Zip Code"
                        v-model="billingAddress.zip"
                        class="px-4"/>
                    <v-text-field
                        label="Country (2-Letter Abbrv.)"
                        maxlength="2"
                        v-model="billingAddress.country"
                        class="px-4"/>
                </v-row>
                <v-row justify="space-around" align="center" class="mb-2">
                    <v-btn 
                        color="secondary"
                        @click="cancelAddress()">
                        Cancel
                    </v-btn>
                    <v-btn 
                        color="primary"
                        @click="fillOutAddress()">
                        Confirm
                    </v-btn>
                </v-row>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-main>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - Create Account'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Create Account')
        this.getDetails()
    },
    data() {
        return {
            valid: false,
            paymentsTerms: [],
            shippingAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: ''
            },
            Shipping_Address_Show: '',
            Billing_Address_Show: '',
            billingAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: ''
            },
            cin7Id: '',
            enterAddressDialog: false,
            accountingEmail: '',
            shippingEmail: '',
            companyName: '',
            companyPaymentTerms: '',
            billingAddressSameAsShipping: true,
            shippingAccount: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            addressType: '',
            firstName: '',
            lastName: '',
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getDetails() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_GetAccountCreationInfo?tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/create-account`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    paymentTerms
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }

                this.paymentsTerms = paymentTerms
                this.loading = false
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async submit() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Ops_CreateAccount?newFunction=true&tab=code
                    `https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/ops/create-account?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            first_name: this.firstName,
                            last_name: this.lastName,
                            shipping_account: this.shippingAccount,
                            company_name: this.companyName,
                            payment_terms: this.companyPaymentTerms,
                            accounting_email: this.accountingEmail,
                            shipping_email: this.shippingEmail,
                            shipping_address: this.shippingAddress,
                            billing_address: this.billingAddressSameAsShipping ? this.shippingAddress : this.billingAddress
                        })
                    }
                )
                const {
                    message,
                    cin7Id
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.cin7Id = cin7Id
                this.clearForm()
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        clearForm() {
            this.firstName = ''
            this.lastName = ''
            this.shippingAccount = false
            this.companyName = ''
            this.companyPaymentTerms = ''
            this.accountingEmail = ''
            this.shippingEmail = ''
            this.shippingAddress = {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: ''
            }
            this.billingAddress = {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: ''
            }
            this.Shipping_Address_Show = ''
            this.Billing_Address_Show = ''
            this.billingAddressSameAsShipping = true
        },
        getShippingAddressData: async function (addressData) {
            this.shippingAddress = await this.getAddressDetails(addressData.place_id)
            this.openAddressDialog('Shipping Address')
        },
        getBillingAddressData: async function (addressData) {
            this.billingAddress = await this.getAddressDetails(addressData.place_id)
            this.openAddressDialog('Billing Address')
        },
        async getAddressDetails(id) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/CRM_ConvertLead?tab=code
                    'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/crm/nda/address?id=' + id,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    message,
                    address
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.loading = false
                return address
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        cancelAddress() {
            if (this.addressType === 'Shipping Address') {
                this.shippingAddress = {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                }
                this.Shipping_Address_Show = ''
            } else {
                this.billingAddress = {
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                }
                this.Billing_Address_Show = ''
            }
            this.enterAddressDialog = false
        },
        fillOutAddress() {
            if(this.addressType === 'Shipping Address') {
                this.Shipping_Address_Show = this.shippingAddress.address1 + ' ' + this.shippingAddress.address2 + ' ' + this.shippingAddress.city + ' ' + this.shippingAddress.state + ' ' + this.shippingAddress.zip + ' ' + this.shippingAddress.country
            } else {
                this.Billing_Address_Show = this.billingAddress.address1 + ' ' + this.billingAddress.address2 + ' ' + this.billingAddress.city + ' ' + this.billingAddress.state + ' ' + this.billingAddress.zip + ' ' + this.billingAddress.country
            }
            this.enterAddressDialog = false
        },
        openAddressDialog(type) {
            this.addressType = type
            this.enterAddressDialog = true
        },
        openCin7SalesOrder() {
            window.open(`https://go.cin7.com/Cloud/TransactionEntry/TransactionEntry.aspx?idCustomerAppsLink=985806&MemberId=${this.cin7Id}`, '_blank').focus()
        },
        openCin7() {
            window.open(`https://go.cin7.com/Cloud/CRM/Contact.aspx?mode=N&idMember=${this.cin7Id}&idCustomerAppsLink=985802`, '_blank').focus()
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    }
}
</script>

<style>

</style>