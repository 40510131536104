<template>
  <v-app>
    <v-main>
      <v-row>
        <v-col>
          <input-date
            v-model="createEventStatus"/>
        </v-col>
        <v-col>
          <calendar
            v-model="year"
            :events="events"/>
        </v-col>
        <v-col>
          <review-dates
            :year="year"
            :events="events"/>
        </v-col>
      </v-row>
      <snackbar
        :snackActive="snackActive"
        :snackTimout="snackTimout"
        :snackColor="snackColor"
        :snackText="snackText"/>
    </v-main>
  </v-app>
</template>


<script>
import Snackbar from '../../Admin/snackbar.vue'
import Calendar from './components/calendar.vue'
import InputDate from './components/inputDate.vue'
import reviewDates from './components/reviewDates.vue'

export default {
  beforeMount() {
    document.title = 'Sandbox - PTO Tracker'
    this.$store.dispatch('updateShowWaffle', true)
    this.$store.dispatch('updateNavbarContent', [
      {title: 'FAQ', path: '/faqs', icon: 'mdi-help-circle', notification: false},
    ])
    this.$store.dispatch('updateNavbarTitle','PTO Tracker')
    this.getAllEvents()
  },
  watch: {
    createEventStatus: function() {
      if (this.createEventStatus === "Success") {
        this.snackActive = true
        this.snackColor = 'success'
        this.snackText = "Event Saved"
        this.snackTimout = 3000
        this.getAllEvents()
      } else {
        this.snackActive = true
        this.snackColor = 'error'
        this.snackText = this.createEventStatus
        this.snackTimout = 3000
      }
    }
  },
  components: { 
    reviewDates,
    InputDate,
    Calendar,
    Snackbar,
  },
  data () {
    return {
      events: [],
      year: 2021,
      createEventStatus: '',
      snackActive: false,
      snackColor: '',
      snackText: '',
      snackTimout: 3000,
    }
  },
  methods: {
    getAllEvents() {
      const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/pto/events';
      const request = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      };
      this.loading = true
      fetch(url, request).then(async response => {
        //error handling
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        this.events = data.events
        
        this.snackActive = true
        this.snackColor = 'success'
        this.snackText = "Loaded"
        this.snackTimout = 2000
      }).catch(error => {
        this.snackActive = true
        this.snackColor = 'error'
        this.snackText = error
        this.snackTimout = 2000
      })
    }
  }
}
</script>