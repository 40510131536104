<template>
    <v-dialog
        v-model="checkForecastDialog"
        width="80vw">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-check-all</v-icon> Check Forecast
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="d-flex justify-center align-center">
                Check Forecast
                <v-spacer/>
                Hover
                <v-switch
                    class="px-4"
                    v-model="modalViewType"
                    color="primary"/>
                Click
                <v-spacer/>
                <v-btn class="d-flex justify-center align-center mr-4 mt-4" icon @click="checkForecastDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-simple-table
                class="gridTable"
                fixed-header
                scrollable>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>SKU</th>
                            <th v-for="(item, index) in visibleMonths" :key="index">
                                {{ item.month }} {{ item.year }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product, index) in productData" :key="index">
                            <td>{{ product.Name }} - {{ product['Option 1'] }}</td>
                            <td>{{ product.SKU }}</td>
                            <td v-for="(item, index2) in visibleMonths"
                                :key="index2"
                                :class="isAboveFloor(item.calendarMonthNum, item.year, product.SKU)"
                                @click="clickForDetails(item.calendarMonthNum, item.year, product.SKU, $event)"
                                @mouseover="hoverForDetails(item.calendarMonthNum, item.year, product.SKU, $event)">
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-menu
                v-model="forecastInformationModal"
                offset-y
                :position-x="x + 5"
                :position-y="y - 5"
                :close-on-content-click="false">
                <v-card>
                    <v-card-title>{{ selectedSKU }} - {{ months[selectedMonth - 1] }} {{ selectedYear }}</v-card-title>
                    <v-card-subtitle>
                        Start Stock: <span :class="SOHClass">{{ selectedForecastDetails.SOH}}</span><br>
                        Forecast: {{ selectedForecastDetails.forecast}} <v-icon>mdi-arrow-down</v-icon><br>
                        Branch Transfer: {{ selectedForecastDetails.branchTransferQty}} <v-icon>mdi-arrow-up</v-icon><br>
                        Stock Market Restock: {{ selectedForecastDetails.stockMarketQty}} <v-icon>mdi-arrow-up</v-icon><br>
                        End Stock: <span :class="stockLeftClass">{{ selectedForecastDetails.stockLeft }}</span><br>
                    </v-card-subtitle>
                    <v-card-actions>
                        <v-btn
                            @click="viewForecast()"
                            color="primary">
                            View Forecast
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        forecastData: {type: Array},
        branchTransferData: {type: Array},
        stockMarketData: {type: Array},
        productData: {type: Array},
        productFloorData: {type: Array},
        SOHData: {type: Array},
        opsGroup: {type: String},
        lookForwardMonths: {type: Number},
        includeDrafts: {type: Boolean}
    },
    beforeMount() {
        this.fillMonths()
    },
    watch: {
        checkForecastDialog: function() {
            if (this.checkForecastDialog) {
                this.checkForecast()
            }
        },
        lookForwardMonths: function() {
            this.fillMonths()
        },
        includeDrafts: function() {
            this.checkForecast()
        }
    },
    data() {
        return {
            checkForecastDialog: false,
            months: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            visibleMonths: [],
            modalViewType: true,
            evaluatedForecast: [],
            selectedForecastDetails: {},
            selectedMonth: "",
            selectedYear: 0,
            selectedSKU: "",
            forecastInformationModal: false,
            x: 0,
            y: 0
        }
    },
    methods: {
        viewForecast() {
            this.forecastInformationModal = false
            this.checkForecastDialog = false
            let details = {
                sku: this.selectedSKU,
                month: this.selectedMonth,
                year: this.selectedYear
            }
            this.$emit('input', details)
        },
        isAboveFloor(month, year, sku) {
            let result = this.evaluatedForecast.find(item =>
                item.SKU === sku &&
                item.month === month &&
                item.year === year
            )
            if (result !== undefined) {
                if (result.aboveFloor === undefined) {
                    return 'atFloor'
                } else if (result.aboveFloor) {
                    return "aboveFloor"
                } else {
                    return "belowFloor"
                }
            } else {
                return "notFound"
            }
        },
        findForecastDetails(month, year, sku, event) {
            this.x = event.clientX
            this.y = event.clientY
            this.selectedForecastDetails = this.evaluatedForecast.find(item =>
                item.SKU === sku &&
                item.month === month &&
                item.year === year
            )
            this.selectedMonth = month
            this.selectedYear = year
            this.selectedSKU = sku
            this.forecastInformationModal = true
        },
        checkForecast() {
            this.evaluatedForecast = []
            let products = this.$props.productData
            let d = new Date()
            let month = d.getMonth()
            let monthIndex = 0
            let thisMonth = month + 1
            let thisMonthIndex = month
            let prevMonth = 0
            let year = d.getFullYear()
            let thisYear = year
            let prevYear = 0
            let opsGroup = this.$props.opsGroup
            let stockLeft = 0
            let forecastQtyAmount = 0
            let productFloor = 0
            let aboveFloor = false
            let restockQty = 0
            let recordedSOH = 0
            for (let i = 0; i < products.length; i++) {
                let SOH = this.$props.SOHData.find(item => 
                    item.Ops_Group === opsGroup && 
                    item.SKU === products[i].SKU
                )

                productFloor = this.$props.productFloorData.find(item =>
                    item.SKU === products[i].SKU &&
                    item.Ops_Group === opsGroup
                )

                if (productFloor === undefined) {
                    productFloor = 0
                } else {
                    productFloor = productFloor.Floor
                }
                
                if (SOH === undefined) {
                    recordedSOH = 0
                    stockLeft = 0
                } else {
                    recordedSOH = SOH.SOH
                    stockLeft = Number(SOH.SOH)
                }

                for (let m = 0; m < this.visibleMonths.length; m++) {
                    month = this.visibleMonths[m].calendarMonthNum
                    monthIndex = this.visibleMonths[m].monthNum
                    year = this.visibleMonths[m].year
                    if (m > 0) {
                        prevMonth = this.visibleMonths[m - 1].calendarMonthNum
                        prevYear = this.visibleMonths[m - 1].year
                    }
                    
                    let halfMonth  = Math.round(new Date(year, monthIndex , 0).getDate() / 2)
                    let forecastQty = this.$props.forecastData.find(item =>
                        item.Month === month &&
                        item.Year === year &&
                        item.SKU === products[i].SKU &&
                        item.Ops_Group === opsGroup
                    )
                

                    if (forecastQty !== undefined) {
                        let fractionOfMonth = 1
                        
                        if (month === thisMonth && year === thisYear) {
                            let currentDay = d.getDate()
                            let daysInMonth = new Date(thisYear, thisMonthIndex, 0).getDate()
                            fractionOfMonth = (daysInMonth - currentDay + 1) / daysInMonth
                        } 

                        forecastQtyAmount = Number(forecastQty.Qty * fractionOfMonth)
                        stockLeft -= forecastQtyAmount
                    } else {
                        forecastQtyAmount = 0
                    }

                    let branchTransferQty = this.filterBranchTransfers.reduce(function(sum, record) {
                        if ((record.ETADate <= halfMonth && record.ETAMonth === month && record.ETAYear === year && record.Ops_Group === opsGroup && record.SKU === products[i].SKU) || 
                            (m > 0 && record.ETADate > halfMonth && record.ETAMonth === prevMonth && record.ETAYear === prevYear && record.Ops_Group === opsGroup && record.SKU === products[i].SKU) ) {
                                return sum + Number(record.Qty)
                        } else {
                            return sum
                        }
                    },0)

                    stockLeft += Number(branchTransferQty)

                    let stockMarketQty = this.$props.stockMarketData.find(item =>
                        item.Month === month &&
                        item.Year === year &&
                        item.Ops_Group === opsGroup &&
                        item.SKU === products[i].SKU
                    )
                    if (stockMarketQty !== undefined) {
                        restockQty = Number(stockMarketQty.Restock)
                        stockLeft += restockQty
                    } else {
                        restockQty = 0
                    }
                    if (stockLeft > productFloor) {
                        aboveFloor = true
                    } else if (stockLeft === productFloor) {
                        aboveFloor = undefined
                    } else {
                        aboveFloor = false
                    }
                    this.evaluatedForecast.push({
                        SKU: products[i].SKU,
                        name: products[i].Name,
                        SOH: recordedSOH,
                        month: month,
                        year: year,
                        floor: productFloor,
                        forecast: Math.round(forecastQtyAmount),
                        branchTransferQty: branchTransferQty,
                        stockMarketQty: restockQty,
                        stockLeft: Math.round(stockLeft),
                        aboveFloor: aboveFloor
                    })
                    recordedSOH = Math.round(stockLeft)
                }
            }
        },
        fillMonths() {
            this.visibleMonths = []
            let d = new Date()
            let month = d.getMonth() - 1
            let year = d.getFullYear()
            for (let i = 0; i < this.$props.lookForwardMonths + 2; i++) {
                if (month < 11) {
                    month++
                } else {
                    month = 0
                    year++
                }
                this.visibleMonths.push({
                    month: this.months[month], 
                    year: year,
                    monthNum: month,
                    calendarMonthNum: month + 1
                })
            }
        },
        clickForDetails(month, year, sku, event) {
            if (this.modalViewType) {
                this.findForecastDetails(month, year, sku, event)
            }
        },
        hoverForDetails(month, year, sku, event) {
            if (!this.modalViewType) {
                this.findForecastDetails(month, year, sku, event)
            }
        }

    },
    computed: {
        stockLeftClass() {
            if (this.selectedForecastDetails.stockLeft < 0) {
                return 'red--text'
            } else if (this.selectedForecastDetails.stockLeft > 0 ) {
                return 'green--text'
            } else if (this.selectedForecastDetails.stockLeft === 0) {
                return 'yellow--text'
            } else {
                return 'black--text'
            }
        },
        SOHClass() {
            if (this.selectedForecastDetails.SOH < 0) {
                return 'red--text'
            } else if (this.selectedForecastDetails.SOH > 0 ) {
                return 'green--text'
            } else if (this.selectedForecastDetails.SOH === 0) {
                return 'yellow--text'
            } else {
                return 'black--text'
            }
        },
        filterBranchTransfers() {
			if (this.$props.includeDrafts) {
				return this.$props.branchTransferData.filter(item => 
					item.Ops_Group === this.$props.opsGroup
				)
			} else {
				return this.$props.branchTransferData.filter(item => 
					item.Ops_Group === this.$props.opsGroup &&
					item.Active == "1"
				)
			}
		},

    }
}
</script>

<style>
.gridTable table th + th { border-left:1px solid #dddddd; }
.gridTable table td + td { border-left:1px solid #dddddd; }

.aboveFloor {
    background-color: white
}
.atFloor {
    background-color: orange;
}
.belowFloor {
    background-color: red;
}
.notFound {
    background-color: black;
}
.aboveFloor:hover {
    cursor: pointer;
}
.atFloor:hover {
    cursor: pointer;
}
.belowFloor:hover {
    cursor: pointer;
}
</style>