<template>
    <v-dialog
        v-model="infoDialog">
        <v-card class="pa-4">
            <v-spacer/>
            <v-card-title class="text-h4 d-flex justify-space-between align-center">
                Cash Flow Model Info
                <v-btn @click="infoDialog = false" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-title class="text-h5 d-flex justify-center align-center">
                Left Column Info
            </v-card-title>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-img src="https://www.dropbox.com/s/hfnfnjeidk5u7r2/First%20Element.png?raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col cols="6">
                    Adjust how many weeks the model looks ahead using the upper most element
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-img class="pic" src="https://www.dropbox.com/s/04jxetsqkfgy9gl/Second%20Element.png?raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col cols="6">
                    The expected bank balance after the selected amount 
                    of weeks. The second, smaller, number shows the difference between the current 
                    bank balance and the predicted bank balance. This balance includes the Overdue balance 
                    selected from the next element
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-img class="pic" src="https://www.dropbox.com/s/fxqwb0yhw85k0yi/overdueElement.png?raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col cols="6">
                    The amount of overdue payments that are owed in the past x weeks. 
                    If 0 is selected that will only show payments that are overdue from THIS week, if 1 is selected, 
                    it will show the payments overdue from THIS week and LAST week and so on. 
                    <strong class="pl-1">The overdue payment number can be selected to show a detailed breakdown of which companies and which orders are overdue and by how much</strong>
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-img src="https://www.dropbox.com/s/0x0wijlmxv2bgjn/Third%20Element.png?raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col cols="6">
                    The current bank balance is given from the QBO API. 
                    If the number is incorrect the number can be clicked on to input the correct amount. 
                    <strong>(This change will NOT persist when the page is refreshed)</strong>
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-img src="https://www.dropbox.com/scl/fi/pl75s24nays2uqudxz377/settings.png?rlkey=tw5bh0h6cus9k8gyj96vz5mg2&raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col cols="6">
                    The settings: <br/>
                    <ul>
                        <li>
                            The 'Add Expense' button will open a dialog to input an expense to be added to the 'Large Expense' column.
                        </li>
                        <li>
                            The 'Save Forecast' button will prompt you to save the current forecast to be compared with the actual data later. 
                            Make sure you adjust the growth rates and add expenses before saving.
                        </li>
                        <li>
                            The 'Parse Expenses' button will parse the expenses from the QBO API if a week was missed/inaccurate and needs to be redone.
                        </li>
                        <li>
                            The 'See Chart' button will just show all the actual data points with the line of best fit shown. 
                            <strong>This is also where the forecasted to actual bank balance is tracked</strong>
                        </li>
                        <li>
                            The 'Show Details' toggle will hide all columns except 'Total Expenses', 'Total Revenue', and 'Bank Balance'.
                        </li>
                        <li>
                            The 'Use Growth vs Use Forecast' toggle will change the forecasted revenue to either use the growth rates listed below 
                            in combination with the previous years data or use the forecasted revenue from the Sandbox Forecasting tool. 
                        </li>
                    </ul>
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-img src="https://www.dropbox.com/s/5lq63sio4bwq2q0/Fifth%20Element.png?raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col cols="6">
                    The element under the settings shows all columns and their associated growth rates. This growth rate 
                    is applied to the same week from the previous year (these changes WILL persist a refresh). The checkbox next to each name will normalize the data set 
                    by replacing outliers (using the IQR method) with the average of the historical year of data.
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-card-title>
                        Important Notes
                    </v-card-title>
                </v-col>
                <v-col cols="6">
                    <ul>
                        <li>
                            The Bank Balance in the last week will NOT match the final bank balance listed in the upper left. 
                            This is because the tables bank balance does NOT take into account the overdue balance as that is 
                            added in after the fact.
                        </li>
                        <li>
                            You can click on any future revenue weeks and see the previous years value and the growth rate currently assigned to it
                        </li>
                        <li>
                            You can click on any cell in the Wholesale column to get a detailed breakdown of what payments are expected from what companies.
                        </li>
                        <li>
                            If the value is orange it is a predicted value, if it is black then it is a real value
                        </li>
                    </ul>
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-card-title class="text-h5 d-flex justify-center align-center">
                Table Info
            </v-card-title>
            <v-row justify="center" class="d-flex justify-center align-center">
                <v-col cols="3">
                    <v-img src="https://www.dropbox.com/scl/fi/q6z3w25fxp1pa9xpi0j92/growthDetails.png?rlkey=cg89d96ej01garj0iz05z1p8y&raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col cols="3" class="d-flex justify-center align-center">
                    <v-img src="https://www.dropbox.com/scl/fi/tlhuv20iahesh5zt2g8jr/forecastDetails.png?rlkey=c8et7rpfxqsco34bdmt89bnjb&raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col cols="6">
                    The revenue columns (currently, TinyTents.com, Amazon, MicroSend, MatadorUp.com, and Wholesale) can all be clicked to show details. <br>
                    If the model is in 'Use Growth' the details will show what growth rate is being applied to what revenue figure from that week last year. <br>
                    If the model is in 'Use Forecast' the details will show what the forecasted revenue from Sales/Ops is for that week.
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-img src="https://www.dropbox.com/scl/fi/2tifguf1rp61168wafww1/wholesale-details.png?rlkey=q90zxfgdpkjtqxh02v24uvuw6&raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col>
                    The Wholesale cells can be clicked to get an overview of what payments are expected from what companies. <br>
                    This overview shows which companies and sales orders are expected to bring in Revenue that week based on the companies terms and the invoice date. <br>
                    As shown in the key, if the wholesale number is <span class="black--text">black</span> then the payment is ACTUAL booked revenue. <br>
                    If the wholesale number is <span class="orange--text">orange</span> then the payment is PREDICTED revenue based on that week last year plus the growth rate. <br>
                </v-col>
            </v-row>
            <v-divider class="py-2"/>
            <v-row justify="center">
                <v-col cols="6" class="d-flex justify-center align-center">
                    <v-img src="https://www.dropbox.com/scl/fi/ox3x2bes8jz4wnipq7a3u/Large-Expense.png?rlkey=x91jhqgvqnuml44b0pvm3i840&raw=1" contain max-width="300px" height="100%" max-height="250px"/>
                </v-col>
                <v-col>
                    Clicking on the 'Large Expenses' column will show which large expenses are added to that week.
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogCount: {type: Number}
    },
    watch: {
        dialogCount: function() {
            this.infoDialog = true
        }
    },
    data(){
        return {
            infoDialog: false
        }
    }
}
</script>

<style>

</style>