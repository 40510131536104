<template>
    <v-dialog
        v-model="dialog"
        max-width="75vw"
        @click:outside="$emit('close')">
        <v-card class="pa-4">
            <customTitle
                :title="'Add Forecast Category'"
                :name="'addForecastCategory'"
                @close="$emit('close')"/>
            <v-card-text>
                <p>
                    Adding a forecast category impacts the following tools and understanding how each are impacted will help you understand when and how to create new forecast categories:
                    <ul>
                        <li>Forecast Tool</li>
                        <li>Stock Market</li>
                        <li>SGT</li>
                    </ul>
                </p>
                <p>
                    <ol>
                        <li>
                            First you will need to create the actual forecast category in the <a href="https://clearmanlabs.com/#/sales/forecast/categories" target="_blank">Forecast Tool - Forecast Categories</a> page. <br>
                            At the top of the page you can input the forecast category name and their revenue goal for the year (optional) and click the "Submit" button.
                        </li>
                        <li>
                            <strong>There should be at least one account assigned to this forecast category</strong> in order to make everything work properly. This can be done within the <a href="/#/sales/crm/accounts/home">CRM - Accounts</a> page.
                        </li>
                        <li>
                            Within the stock market, if the new category should be assigned to any Ops Group other than 'Synergy', you will need to navigate to the <a href="https://clearmanlabs.com/#/ops/stockMarket/manageGroups" target="_blank">Stock Market - Manage Groups</a> page and edit the category to include the appropriate Ops Group and Ops Forecast Name.
                        </li>
                    </ol>
                </p>
            </v-card-text>
        </v-card>    
    </v-dialog>
</template>

<script>
import customTitle from './title.vue'
export default {
    components: {
        customTitle
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    methods: {

    }
}
</script>

<style>

</style>