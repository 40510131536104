<template>
    <v-container fliud class="d-flex justify-center align-center">
        <div class="d-flex justify-center align-center" style="width:60vw">
            <v-data-table
                :headers="headers"
                :items="categories"
                :search="search"
                :sort-desc="[false,true]"
                :loading="loading"
                multi-sort
                dense
                height="60vh"
                class="elevation-2"
                fixed-headers>
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                    ></v-text-field>
                </template>
                <template v-slot:item="{ item }">
                    <tr>
                        <td>
                            <v-switch
                                v-model="item.Active"
                                @change="updateCategory(item)"/>
                        </td>
                        <td>
                            <v-switch
                                v-model="item.Preorder"
                                @change="updateCategory(item)"/>
                        </td>
                        <td>
                            <v-text-field
                                dense
                                v-model="item.Forecast_Name"
                                append-outer-icon="mdi-send"
                                @click:append-outer="updateCategory(item)"/>
                        </td>
                        <td>
                            <v-text-field 
                                dense
                                v-model="item[year]"
                                append-outer-icon="mdi-send"
                                prepend-icon="mdi-currency-usd"
                                @click:append-outer="submit(item, year)">
                                {{ getCategoryGoal(item.Forecast_Id, year) }}
                            </v-text-field>
                        </td>
                        <td>
                            <v-text-field 
                                dense
                                v-model="item[year + 1]"
                                append-outer-icon="mdi-send"
                                prepend-icon="mdi-currency-usd"
                                @click:append-outer="submit(item, year + 1)">
                                {{ getCategoryGoal(item.Forecast_Id, year + 1) }}
                            </v-text-field>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText}}
            </v-snackbar>
        </div>
    </v-container>
</template>

<script>
export default {
    props: ['refreshedCategories'],
    watch: {
        refreshedCategories: function() {
            this.categories = this.$props.refreshedCategories
        }
    },
    data() {
        return {
            categories: [],
            goals: [],
            search: '',
            loading: false,
			snackbar: false,
			snackColor: '',
			snackText: '',
			timeout: 3000,
            year: 2021,
            goal1: 0,
            goal2: 0,
        }
    },
    async beforeMount() {
        this.year = new Date()
        this.year = this.year.getFullYear()
        await this.getCategories()
        await this.getGoals()
    },
    methods: {
        async getCategories() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    `https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/categories?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const data = await result.json()
            
                if(!result.ok) {
                    throw "error"
                }

                this.categories = data
                this.loading = false
                this.snack('Success','success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async getGoals() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    `https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/categories/goals?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    goals
                } = await result.json()
            
                if(!result.ok) {
                    throw "error"
                }
                this.goals = goals
                for (let i = 0; i < this.categories.length; i++) {
                    this.categories[i][this.year] = this.goals.find(item => item.Category == this.categories[i].Forecast_Name && item.Year == this.year)?.Goal
                    this.categories[i][this.year + 1] = this.goals.find(item => item.Category == this.categories[i].Forecast_Name && item.Year == this.year + 1)?.Goal
                }
                this.loading = false
                this.snack("Success",'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        getCategoryGoal(category, year) {
            let filter = this.categories.find(item => item.Forecast_Id == category)
            return filter[year]
        },
        async submit(category, year) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/Forecast_UpdateCategoryGoal?tab=code
                    `https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/categories/goals?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Forecast_Id: category.Forecast_Id,
                            Year: year,
                            Goal: category[year]
                        })
                    }
                )
                const {
                    message,
                    goals
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.goals = goals

                this.loading = false
                this.snack("Success",'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateCategory(category) {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: 
                    `https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/categories?email=${this.$store.getters.getUserEmail}`,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            Forecast_Id: category.Forecast_Id,
                            Forecast_Name: category.Forecast_Name,
                            Active: category.Active,
                            Preorder: category.Preorder
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                this.loading = false
                this.snack("Success",'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text, color) {
            this.snackbar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = 2000
		},
    },
    computed: {
        headers() {
            return [
                {
                    text: 'Active',
                    width: '50px',
                    align: 'start',
                    value: 'Active',
                    fixed: true,
                },
                {
                    text: 'Preorder',
                    width: '50px',
                    align: 'start',
                    value: 'Preorder',
                    fixed: true,
                },
                {
                    text: 'Forecast Category',
                    align: 'start',
                    value: 'Forecast_Name',
                    fixed: true,
                },
                {
                    text: this.year,
                    width: '200px',
                    align: 'start',
                    value: 'year1',
                    fixed: true,
                },
                {
                    text: this.year + 1,
                    width: '200px',
                    align: 'start',
                    value: 'year2',
                    fixed: true,
                },
            ]
        }
    }
}
</script>

<style>

</style>