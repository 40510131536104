<template>
    <v-card class="px-4 py-4" width="30vw">
        <v-card-title class="d-flex justify-center">Fix Credit Notes In A Date Range</v-card-title>
        <v-card-text width="25vw">
            <v-row justify="center">
                <v-date-picker
                    width="25vw"
                    range
                    v-model="dateRange"/>
            </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
            <v-btn
                color="primary"
                @click="fixOrders()"
                :loading="loading"
                :disabled="disableFixOrdersButton">
                Fix Credit Notes
            </v-btn>
        </v-card-actions>
        <v-card-text class="d-flex justify-center">
            {{ dateRangeLabel }}
        </v-card-text>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            updateText: '',
            loading: false,
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            dateRange: []
        }
    },
    methods: {
        fixOrders() {
            // const batchId = Math.floor(100000 + Math.random() * 900000)
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/updateorders'
            let data = {
                url: "https://api.cin7.com/api/v1/CreditNotes?where=CreatedDate>='" + this.sortedDates[0] + "T00:00:00Z' AND CreatedDate<='" + this.sortedDates[1] + "T00:00:00Z'&rows=250&page=",
                // batchId: batchId
            }
            const request = {
                method: 'POST',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }
                this.loading = false
                this.snack(data.message, 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })

            // let processed = false
            // while (processed === false) {
            //     const url = ""
            //     const request = {
            //         method: 'POST',
            //         body: JSON.stringify({batchId: batchId}),
            //         headers: {
            //             'Content-Type': 'application/json'
            //         }
            //     }

            //     await fetch(url, request).then(async response => {
            //         const data = await response.json()
            
            //         if (!response.ok) {
            //             const error = (data && data.message) || response.status
            //             return Promise.reject(error)
            //         }
            //         processed = data.processed

            //     }).catch(error => {
            //         console.log(error);
            //         processed = true
            //     })
            // }
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        disableFixOrdersButton() {
            if (this.dateRange.length === 2) {
                return false
            } else {
                return true
            }
        },
        dateRangeLabel() {
            if (this.sortedDates && this.sortedDates.length > 0) {
                if (this.sortedDates.length === 2) {
                    return this.sortedDates[0] + " - " + this.sortedDates[1]
                } else if (this.sortedDates.length === 1) {
                    return this.sortedDates[0] + " - "
                } else {
                    return " - "
                }
            } else {
                return " - "
            }
        },
        sortedDates() {
            if (this.dateRange.length === 2) {
                let firstDate = new Date(Date.parse(this.dateRange[0]))
                let secondDate = new Date(Date.parse(this.dateRange[1]))

                if (firstDate > secondDate) {
                    return [this.dateRange[1], this.dateRange[0]]
                } else {
                    return [this.dateRange[0], this.dateRange[1]]
                }
            } else if (this.dateRange.length === 1) { 
                return [this.dateRange[0]]
            } else {
                return []
            }
        }
    }
}
</script>

<style>

</style>