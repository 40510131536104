<template>
  <v-app>
    <v-main class="d-flex justify-center align-center">
        <v-row justify="space-around" align="center">
            <v-btn
                color="primary"
                @click="refreshAllBranchTransfers()">
                Get Most Recent Branch Transfers
            </v-btn>
            <v-switch
                v-model="filters.onlyEmptyETA"
                label="Show Only Empty ETA"
                color="primary"/>
            <v-btn
                color="primary"
                @click="updateAllETAs()">
                Get All ETA
            </v-btn>
        </v-row>
        <v-data-table
            :loading="loading"
            :headers="branchTransferHeaders"
            :items="filteredBranchTransfers"
            :single-expand="false"
            :expanded.sync="expanded"
            :search="search"
            item-key="Id"
            show-expand
            class="elevation-1">
            <template v-slot:top>
				<v-text-field
				v-model="search"
				label="Search"
				class="mx-4"
				></v-text-field>
			</template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-data-table
                        :headers="lineItemHeaders"
                        :items="getBranchTransferItems(item.Id)">
                    </v-data-table>
                </td>
            </template>
            <template v-slot:[`item.Reference`]="{ item }">
                <a :href="'https://go.cin7.com/Cloud/TransactionEntry/TransactionEntry.aspx?idCustomerAppsLink=985828&OrderId=' + item.Id">{{ item.Reference }}</a>
            </template>
            <template v-slot:[`item.Actions`] = "{ item }">
                <v-btn 
                    color="primary"
                    @click="refreshBranchTransfer(item.Id)"
                    icon>
                    <v-icon color="primary">mdi-refresh</v-icon>
                </v-btn>
                <v-menu 
                    :close-on-content-click="false"
                    v-model="item.deleteConfirmation"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on">
                        <v-icon color="red">mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                    <v-card class="pa-4">
                        <v-card-title class="d-flex justify-center align-center">
                            Are you sure you want to delete?
                        </v-card-title>
                        <v-card-subtitle class="d-flex justify-center align-center">
                            This action cannot be undone
                        </v-card-subtitle>
                        <v-card-actions class="d-flex justify-space-around align-center">
                            <v-btn
                                color="secondary"
                                class="mr-2"
                                @click="item.deleteConfirmation = false">
                                No... I Regret My Actions
                            </v-btn>
                            <v-btn
                                color="error"
                                class="mr-2"
                                @click="deleteBranchTransfer(item.Id); item.deleteConfirmation = false;">
                                Send it!
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            icon
                            @click="updateAllETAs(item.Id)"
                            v-bind="attrs"
                            v-on="on">
                            <v-icon color="primary">mdi-calendar-clock</v-icon>
                        </v-btn>
                    </template>
                    <span>Update ETA</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.Is_Approved`]="{ item }">
                {{ draft(item.Is_Approved) }}
            </template>
            <template v-slot:[`item.ETA`]="{ item }">
                <v-menu
                    v-model="item.etaDateActive"
                    :close-on-content-click="false"
                    :return-value.sync="etaDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <td
                            v-bind="attrs"
                            v-on="on"
                            @click="setETADate(item.ETA)">
                            {{ formatETA(item.ETA) }}
                        </td>
                    </template>
                    <v-date-picker
                        v-model="etaDate"
                        no-title
                        scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="secondary"
                            @click="item.etaDateActive = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="updateETA(etaDate, item)">
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </template>
            <template v-slot:[`item.Product_Total`]="{ item }">
                {{ item.Product_Total | currency }}
            </template>
            <template v-slot:[`item.Source_Branch_Id`]="{ item }">
                {{ formatBranch(item.Source_Branch_Id) }}
            </template>
            <template v-slot:[`item.Destination_Branch_Id`]="{ item }">
                {{ formatBranch(item.Destination_Branch_Id) }}
            </template>
            <template v-slot:[`item.Approval_Date`]="{ item }">
                {{ formatApprovalDate(item.Approval_Date) }}
            </template>
            <template v-slot:[`item.Dispatch_Date`]="{ item }">
                {{ formatDispatchDate(item.Dispatch_Date) }}
            </template>
            <template v-slot:[`item.Received_Date`]="{ item }">
                {{ formatReceivedDate(item.Received_Date) }}
            </template>
        </v-data-table>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
    beforeMount() {
        document.title = 'Sandbox - Stock Market'
        this.$store.dispatch('updateShowWaffle', true)
        this.$store.dispatch('updateNavbarContent', [
            {title: 'Home', path: '/Ops/StockMarket/Home', icon: 'mdi-home', notifcation: false},
            {title: 'Manage Groups', path: '/ops/stockmarket/manage', icon: 'mdi-account-group', notification: false},
            {title: 'Manage Branch Transfers', path: '/ops/stockmarket/manageBranchTransfers', icon: 'mdi-source-branch', notification: false},
            {title: 'Manage Branches', path: '/ops/stockmarket/manageBranches', icon: 'mdi-warehouse', notification: ""}
        ])
        this.$store.dispatch('updateNavbarTitle','Stock Market')
        this.getBranchTransfers()
    },
    data() {
        return {
            search: '',
            filters: {
                onlyEmptyETA: false
            },
            expanded: [],
            ETAMenu: false,
            etaDate: '',
            branches: [],
            branchTransfers: [],
            lineItems: [],
            loading: false,
            snackText: 'Hello',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
        }
    },
    methods: {
        async getBranchTransfers() {
            //https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_AllBranchTransfers?newFunction=true&tab=code
            try {
                this.loading = true
                const response = await fetch(
                    'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/managebranchtransfers',
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                const {
                    branchTransfers,
                    branchTransferLineItems,
                    branches
                } = await response.json()

                this.branches = branches
                this.branchTransfers = branchTransfers
                this.branchTransfers.map(item => ({...item, etaDateActive: false, deleteConfirmation: false}))
                this.lineItems = branchTransferLineItems
                this.loading = false
            } catch(error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async deleteBranchTransfer(id) {
            try {
                this.loading = true
                let url = new URL('https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/managebranchtransfers')
                url.searchParams.append('id', id)
                url.searchParams.append('email', this.$store.state.user.email)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_DeleteBranchTransfer?newFunction=true&tab=code
                    url,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                } 
                let deletedBT = this.branchTransfers.find(item => item.Id === id)
                let index = this.branchTransfers.indexOf(deletedBT)
                this.branchTransfers.splice(index, 1)
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateETA(date, item) {
            try {
                this.loading = true
                //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_UpdateETADate?tab=code
                const response = await fetch(
                    'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/managebranchtransfers',
                    {
                        method: 'PUT',
                        body: JSON.stringify({
                            date: date,
                            id: item.Id,
                            reference: item.Reference
                        }),
                        Headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                const {
                    message
                 } = await response.json()

                let index = this.branchTransfers.findIndex(object => {
                    return object.Id === item.Id
                })

                this.branchTransfers[index].ETA = date
                this.branchTransfers[index].etaDateActive = false

                this.snack(message,'info',3000)
                this.loading = false

            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async updateAllETAs(id) {
            try {

                let ids = this.filteredBranchTransfers.map(item => item.Id)
                if (id) {
                    ids = [id]
                }
                this.snack('An email will be sent once complete','info',5000)
                let url = new URL('https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/bot')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_ETABot?tab=code
                    url,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ids: ids
                        })
                    }
                )
                const {
                    message,
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
            
                // this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                // this.snack(error,'error',3000)
            }
        },
        refreshAllBranchTransfers() {
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/cin7branchtransfers'
            const request = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    return Promise.reject(data.message)
                }
                this.getBranchTransfers()
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        refreshBranchTransfer(id) {
            //AWS Url
            //https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StockMarket_RefreshBranchTransfer?newFunction=true&tab=code
            const url = 'https://0uxy8dccoi.execute-api.us-east-2.amazonaws.com/prod/refresh/branchtransfers'
            const data = {
                id: id
            }
            const request = {
                method: 'PUT',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = (data && data.message) || response.status
                    return Promise.reject(error)
                }

                this.loading = false
                this.snack(data.message, 'success', 3000)
                this.getBranchTransfers()
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        getBranchTransferItems(id) {
            return this.lineItems.filter(item => item.Transaction_Id === id)
        },
        draft(draft) {
            if (draft === 1) {
                return 'Approved'
            } else {
                return 'Draft'
            }
        },
        formatETA(date) {
            if (date === null) {
                return 'Empty'
            } else if (date.indexOf('T') !== -1) {
                return date.split('T')[0]
            } else {
                return 'Empty'
            }
        },
        formatApprovalDate(date) {
            if (date === null || date === undefined) {
                return ''
            } else {
                return date.split('T')[0]
            }
        },
        formatDispatchDate(date) {
            if (date === null || date === undefined) {
                return ''
            } else {
                return date.split('T')[0]
            }
        },
        formatReceivedDate(date) {
            if (date === null || date === undefined) {
                return ''
            } else {
                return date.split('T')[0]
            }
        },
        formatBranch(id) {
            let branch = this.branches.find(item => item.Id == id)
            if (branch) {
                return branch.Company
            } else {
                return ''
            }
        },
        setETADate(date) {
            if (date === null || date === undefined) {
                this.etaDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }
            if (date.indexOf('T') === -1) {
                this.etaDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            } else if (date !== '0000-00-00' && date !== null) {
                this.etaDate = date.split('T')[0]
            } else {
                this.etaDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }
        },
        snack(text, color, timeout) {
            this.snackText = text
            this.snackColor = color
            this.snackTimeout = timeout
            this.snackActive = true
        }
    },
    computed:{ 
        filteredBranchTransfers() {
            console.log(this.filters.onlyEmptyETA);
            if (this.filters.onlyEmptyETA) {
                return this.branchTransfers.filter(item => 
                    item.ETA === '0000-00-00' || item.ETA === null
                )
            } else {
                return this.branchTransfers
            }
        },
        branchTransferHeaders() {
            return [
                {
                    text: 'Ref',
                    align: 'start',
                    sortable: true,
                    value: 'Reference'
                },
                {
                    text: 'Actions',
                    align: 'start',
                    sortable: true,
                    value: 'Actions'
                },
                {
                    text: 'Project Name',
                    align: 'start',
                    sortable: true,
                    value: 'Project_Name'
                },
                {
                    text: 'Draft',
                    align: 'start',
                    sortable: true,
                    value: 'Is_Approved'
                },
                {
                    text: 'ETA',
                    align: 'start',
                    sortable: true,
                    value: 'ETA'
                },
                {
                    text: 'Total Value',
                    align: 'start',
                    sortable: true,
                    value: 'Product_Total'
                },
                {
                    text: 'Source Branch',
                    align: 'start',
                    sortable: true,
                    value: 'Source_Branch_Id'
                },
                {
                    text: 'Dest Branch',
                    align: 'start',
                    sortable: true,
                    value: 'Destination_Branch_Id'
                },
                {
                    text: 'Approval Date',
                    align: 'start',
                    sortable: true,
                    value: 'Approval_Date'
                },
                {
                    text: 'Dispatch Date',
                    align: 'start',
                    sortable: true,
                    value: 'Dispatch_Date'
                },
                {
                    text: 'Received Date',
                    align: 'start',
                    sortable: true,
                    value: 'Received_Date'
                },
            ]
        },
        lineItemHeaders() {
            return [
                {
                    text: 'Line Id',
                    align: 'start',
                    sortable: true,
                    value: 'Id'
                },
                {
                    text: 'SKU',
                    align: 'start',
                    sortable: true,
                    value: 'Style_Code'
                },
                {
                    text: 'Product Name',
                    align: 'start',
                    sortable: true,
                    value: 'Name'
                },
                {
                    text: 'Option 1',
                    align: 'start',
                    sortable: true,
                    value: 'Option_1'
                },
                {
                    text: 'Qty',
                    align: 'start',
                    sortable: true,
                    value: 'Qty'
                },
                {
                    text: 'Qty Transferred',
                    align: 'start',
                    sortable: true,
                    value: 'Qty_Transferred'
                },
                {
                    text: 'Unit Cost',
                    align: 'start',
                    sortable: true,
                    value: 'Unit_Cost'
                },
            ]
        }
    }
}
</script>

<style>

</style>