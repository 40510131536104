<template>
  <v-card class="mx-auto px-auto mt-16" height="100%" width="20vw">
      <v-card-title class="justify-center">Input Time Off</v-card-title>
      <v-card-text>
        <v-row>
          <v-date-picker
            header-color="accent"
            v-model="selectedDates"
            width="100%"
            range/>
        </v-row>
        <v-row>
          <v-checkbox
            v-model="addHours"
            hide-details
            label="Add Specific Hours"/>
        </v-row>
        <v-row v-if="addHours">
          <v-col>
            <v-dialog
              ref="startTimeDialog"
              v-model="startTimeModal2"
              :return-value.sync="startTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startTime"
                  label="Start Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="startTimeModal2"
                v-model="startTime"
                full-width
                ampm-in-title
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="startTimeModal2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startTimeDialog.save(startTime)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="endTimeDialog"
              v-model="endTimeModal2"
              :return-value.sync="endTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endTime"
                  label="End Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="endTimeModal2"
                v-model="endTime"
                full-width
                ampm-in-title
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="endTimeModal2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endTimeDialog.save(endTime)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-select
              class="px-5 py-2"
              justify="center"
              align="center"
              label="Type"
              hide-details
              v-model="type"
              :items="typeItems"
            ></v-select>
          </v-col>
          <v-col class="py-0 justify-center align-center">
            <v-select
              class="px-5 py-2"
              label="Availability"
              hide-details
              v-model="availability"
              :items="availabilityItems"
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-text-field
            class="px-5"
            hide-details
            label="Event Title"
            v-model="eventTitle"/>
        </v-row>
        <v-row justify="center">
          <v-subheader>Total Hours: {{ totalHours }}</v-subheader>
        </v-row>
        <v-row justify="center">
          <v-btn 
            :loading="loading"
            @click="submitEvent()"
            :disabled="sumbitDisabled" 
            color="primary">
            Submit
          </v-btn>
        </v-row>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
  beforeMount() {
    this.eventTitle = this.$store.getters.getUsername.split(' ')[0] + ' OOO'
    this.selectedDates[0] = new Date().toJSON().slice(0, 10)
  },
  watch: {
    availability: function() {
      let name = this.$store.getters.getUsername.split(' ')[0]
      if (this.eventTitle === name + " WFH" && this.availability === "Unavailable") {
        this.eventTitle = name + " OOO"
      } else if (this.eventTitle === name + " OOO" && this.availability === "Available") {
        this.eventTitle = name + " WFH"
      }
    }
  },
  data() {
    return {
      loading: false,
      addHours: false,
      selectedDates: [],
      eventTitle: '',
      startTimeModal2: false,
      startTimeDialog: false,
      startTime: '08:00',
      endTime: '17:00',
      endTimeModal2: false,
      endTimeDialog: false,
      type: '',
      availability: '',
      availabilityItems: ['Available','Unavailable'],
      typeItems: ['Vacation','Sick']
    }
  },
  methods: {
    submitEvent() {
      let startDate = new Date()
      let endDate = new Date()
      this.selectedDates.sort((a,b) => {
        return new Date(a) - new Date(b)
      })

      if (!this.addHours && this.selectedDates.length === 1) {
        startDate = new Date(this.selectedDates[0] + 'T00:00:00')
        endDate = new Date(this.selectedDates[0] + 'T00:00:00')
      } else if (!this.addHours && this.selectedDates.length === 2) {
        startDate = new Date(this.selectedDates[0] + 'T00:00:00')
        endDate = new Date(this.selectedDates[1] + 'T00:00:00')
      } else if (this.addHours && this.selectedDates.length === 1) {
        startDate = new Date(this.selectedDates[0] + 'T' + this.startTime)
        endDate = new Date(this.selectedDates[0] + 'T' + this.endTime)
      } else if (this.addHours && this.selectedDates.length === 2) {
        startDate = new Date(this.selectedDates[0] + 'T' + this.startTime)
        endDate = new Date(this.selectedDates[1] + 'T' + this.endTime)
      }

      const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/general/pto/events';
      const data = { 
        eventName: this.eventTitle,
        userId: this.$store.getters.getUserId,
        startDate: startDate,
        endDate: endDate,
        hours: this.totalHours,
        type: this.type,
        availability: this.availability,
        addedHours: this.addHours,
      }

      const request = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        }
      };
      this.loading = true
      fetch(url, request).then(async response => {
        const data = await response.json()

        if (!response.ok) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }

        //reset the input fields
        this.selectedDates = []
        this.addHours = false
        this.starTime = '08:00'
        this.endTime = '17:00'
        this.type = ''
        this.availability = ''
        this.eventTitle = this.$store.getters.getUsername.split(' ')[0] + ' OOO'

        this.$emit('input','Success')
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.$emit('input',error)
      })
    },
  },
  computed: {
    totalHours() {
      let hours = 0
      if (!this.addHours && this.selectedDates.length === 2) {
        let date1 = new Date(this.selectedDates[0])
        let date2 = new Date(this.selectedDates[1])
        let startDate = Math.min(date1.getTime(), date2.getTime())
        let endDate = Math.max(date1.getTime(), date2.getTime())
        let differenceInTime = endDate - startDate
        let differenceInDays = differenceInTime / (1000 * 3600 * 24)
        hours = differenceInDays * 8 + 8
      } else if (!this.addHours && this.selectedDates.length === 1) {
        hours = 8
      } else if (this.addHours && this.selectedDates.length === 1) {
        let startDate = new Date(this.selectedDates[0]+'T'+this.startTime)
        let endDate = new Date(this.selectedDates[0]+'T'+this.endTime)
        let milliseconds = endDate - startDate
        //fix
        hours = (milliseconds / 3600000) - 1
      } else if (this.addHours && this.selectedDates.length === 2) {
        let date1 = new Date(this.selectedDates[0])
        let date2 = new Date(this.selectedDates[1])
        let startDate = Math.min(date1.getTime(), date2.getTime())
        let endDate = Math.max(date1.getTime(), date2.getTime())
        let differenceInTime = endDate - startDate
        let differenceInDays = differenceInTime / (1000 * 3600 * 24)

        let firstDateStartTime = new Date(this.selectedDates[0]+'T'+this.startTime)
        let firstDateEndTime = new Date(this.selectedDates[0]+'T17:00')
        let secondDateStartTime = new Date(this.selectedDates[1]+'T08:00')
        let secondDateEndTime = new Date(this.selectedDates[1]+'T'+this.endTime)
        let firstDateMilliseconds = firstDateEndTime - firstDateStartTime
        let firstDateSpecificHours = (firstDateMilliseconds / 3600000)
        let secondDateMilliseconds = secondDateEndTime - secondDateStartTime
        let secondDateSpecificHours = (secondDateMilliseconds / 3600000)

        if (firstDateSpecificHours > 8) {
          firstDateSpecificHours = 8
        }
        if(secondDateSpecificHours > 8) {
          secondDateSpecificHours = 8
        }
        console.log(firstDateSpecificHours);
        console.log(secondDateSpecificHours);
        hours = (differenceInDays - 1) * 8 + firstDateSpecificHours + secondDateSpecificHours
      }

      return Math.abs(hours).toFixed(2)

    },
    sumbitDisabled() {
      if (this.type.length && this.availability.length && this.selectedDates.length) {
        return false
      } else {
        return true
      }
    },
  }
}
</script>

<style>

</style>