<template>
    <v-container class="flex-column justify-center align-center pt-16">
        <v-row class="pt-8" width="100%">
            <v-text-field
                width="100%"
                v-model="search"
                label="Search"
                hide-details
                append-icon="mdi-magnify"
                class="mx-2"/>
        </v-row>
        <v-row class="pt-8">
            <v-simple-table>
                <thead>
                    <tr>
                        <th>
                            <v-menu
                                bottom
                                v-if="!loading"
                                :close-on-content-click="false"
                                v-model="menu"
                                transition="scale-transition"
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        icon
                                        color="primary">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-list>
                                        <v-list-item @click="newProductDialog = true">
                                            <v-list-item-icon>
                                                <v-icon>mdi-plus</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Add Product</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="newColumnDialog = true">
                                            <v-list-item-icon>
                                                <v-icon>mdi-plus</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Add Column</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-menu>
                            <v-progress-circular
                                v-else
                                indeterminate
                                color="primary"/>
                        </th>
                        <th v-for="header in headers" :key="header.value" class="text-left">
                            {{ header.text }}
                            <v-btn 
                                v-if="!excludeColumns.includes(header.value)"
                                icon
                                @click="openDeleteColumnDialog(header.value)"
                                color="red">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="red">mdi-delete</v-icon>
                                    </template>
                                    <span>Delete Column</span>
                                </v-tooltip>
                            </v-btn>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product, index) in filteredAmazonProducts" :key="index">
                        <td>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        icon
                                        v-on="on"
                                        color="red"
                                        @click="openDeleteProductDialog(product)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete Product</span>
                            </v-tooltip>
                        </td>
                        <td v-for="header in headers" :key="header.value">
                            <template v-if="header.value === 'Matador_SKU'">
                                {{ product[header.value] }}
                            </template>
                            <v-text-field
                                v-else
                                @change="editP(product)"
                                v-model="product[header.value]"
                                hide-details
                                class="mx-2"/>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-row>
        <!-- New Product Dialog -->
        <v-dialog
            v-model="newProductDialog"
            max-width="75vw">
            <v-card class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Add New Product
                </v-card-title>
                <v-row>
                    <v-autocomplete
                        v-model="newProduct.Matador_SKU"
                        :items="products"
                        required
                        item-text="Name"
                        item-value="SKU"
                        label="Product*"
                        clearable
                        hide-details
                        class="mx-2"/>
                    <v-text-field
                        label="ASIN*"
                        required
                        v-model="newProduct.ASIN"
                        hide-details
                        class="mx-2"/>
                    <v-text-field
                        label="Title"
                        v-model="newProduct.Title"
                        hide-details
                        class="mx-2"/>    
                    <v-text-field
                        v-for="column in extraColumns"
                        :key="column.value"
                        :label="column.text"
                        hide-details
                        class="mx-2"
                        v-model="newProduct[column.value]"/>
                </v-row>
                <v-card-actions class="d-flex justify-space-around align-center pt-8">
                    <v-btn
                        color="secondary"
                        @click="newProductDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="loading"
                        :disabled="!newProduct.Matador_SKU || !newProduct.ASIN"
                        @click="createNewProduct()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- New Column Dialog -->
        <v-dialog
            v-model="newColumnDialog"
            max-width="75vw">
            <v-card class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Add New Column
                </v-card-title>
                <v-row>
                    <v-text-field
                        label="Column Name"
                        v-model="newColumn"
                        hide-details
                        class="mx-2"/>
                </v-row>
                <v-card-actions class="d-flex justify-space-around align-center pt-8">
                    <v-btn
                        color="secondary"
                        @click="newColumnDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="loading"
                        :disabled="!newColumn"
                        @click="addColumn()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Delete Product Dialog -->
        <v-dialog
            v-model="deleteProductDialog"
            width="75vw">
            <v-card width="75vw" class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Are you sure you want to delete {{ deleteProduct }} product?
                </v-card-title>
                <v-card-actions class="d-flex justify-space-around align-center pt-8">
                        <v-btn
                            color="secondary"
                            @click="deleteProductDialog = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            :loading="loading"
                            @click="deleteP()">
                            Delete
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Delete Column Dialog -->
        <v-dialog
            v-model="deleteColumnDialog"
            width="75vw">
            <v-card width="75vw" class="pa-4">
                <v-card-title class="d-flex justify-center align-center">
                    Are you sure you want to delete {{ deleteColumn }} column?
                </v-card-title>
                <v-card-actions class="d-flex justify-space-around align-center pt-8">
                        <v-btn
                            color="secondary"
                            @click="deleteColumnDialog = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            :loading="loading"
                            @click="deleteColumnAction(deleteColumn)">
                            Delete
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
            :color="snackColor">
            {{ snackText }}
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    watch: {
        extraColumns() {
            //clear existing columns
            let keys = Object.keys(this.newProduct)
            for (let i = 0; i < keys.length; i++) {
                if (this.excludeColumns.indexOf(keys[i]) === -1) {
                    delete this.newProduct[keys[i]]
                }
            }
            for (let i = 0; i < this.extraColumns.length; i++) {
                this.newProduct[this.extraColumns[i].value] = ''
            }
        }
    },
    beforeMount() {
        this.$store.dispatch('updateShowWaffle', true)
        document.title = 'Sandbox - Amazon'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Manage Amazon Products')
        this.getInfo()
    },
    data() {
        return {
            products: [],
            amazonProducts: [],
            excludeColumns: ['Matador_SKU','ASIN','Title'],
            deleteProduct: '',
            newProduct: {
                Matador_SKU: '',
                ASIN: '',
                Title: ''
            },
            newColumn: '',
            deleteColumn: '',
            search: '',
            menu: false,
            newProductDialog: false,
            newColumnDialog: false,
            deleteProductDialog: false,
            deleteColumnDialog: false,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazon/products')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/AmazonProducts_GetInfo?newFunction=true&tab=code
                    url,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    products,
                    amazonProducts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.products = products
                this.amazonProducts = amazonProducts

                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async createNewProduct() {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazon/products')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/AmazonProducts_AddProduct?newFunction=true&tab=code
                    url,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            insert: this.newProduct
                        })
                    }
                )
                const {
                    message,
                    amazonProducts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.amazonProducts = amazonProducts
                this.addProductDialog = false
                this.newProduct = {
                    Matador_SKU: '',
                    ASIN: '',
                    Title: ''
                }
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async editP(product) {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazon/products')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/AmazonProducts_EditProduct?newFunction=true&tab=code
                    url,
                    {
                        method: 'PUT',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            edit: product
                        })
                    }
                )
                const {
                    message,
                    amazonProducts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.amazonProducts = amazonProducts
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        async deleteP() {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazon/products')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                url.searchParams.append('sku', this.deleteProduct)

                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/AmazonProducts_DeleteProducts?newFunction=true&tab=code
                    url,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    amazonProducts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.amazonProducts = amazonProducts
                this.loading = false
                this.deleteProductDialog = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        openDeleteProductDialog(product) {
            this.deleteProduct = product.Matador_SKU
            this.deleteProductDialog = true
        },
        async addColumn() {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazon/columns')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/AmazonProducts_AddColumn?newFunction=true&tab=code
                    url,
                    {
                        method: 'POST',
                        Headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            column: this.newColumn
                        })
                    }
                )
                const {
                    message,
                    amazonProducts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.amazonProducts = amazonProducts
                this.newColumnDialog = false
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        openDeleteColumnDialog(column) {
            this.deleteColumn = column
            this.deleteColumnDialog = true
            this.menu = false
        },
        async deleteColumnAction(column) {
            try {
                this.loading = true
                let url = new URL('https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/amazon/columns')
                url.searchParams.append('email', this.$store.getters.getUserEmail)
                url.searchParams.append('column', column)

                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/AmazonProducts_DeleteColumn?newFunction=true&tab=code
                    url,
                    {
                        method: 'DELETE',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    amazonProducts
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.amazonProducts = amazonProducts
                this.deleteColumnDialog = false
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    },
    computed: {
        filteredAmazonProducts() {
            if (this.search.length) {
                return this.amazonProducts.filter(product => {
                    return product.Matador_SKU.toLowerCase().includes(this.search.toLowerCase()) ||
                        (product.ASIN && product.ASIN.toLowerCase().includes(this.search.toLowerCase())) ||
                        (product.Title && product.Title.toLowerCase().includes(this.search.toLowerCase())) ||
                        this.extraColumns.some(column => product[column.value] && product[column.value].toLowerCase().includes(this.search.toLowerCase()))
                })
            } else {
                return this.amazonProducts
            }
        },
        headers() {
            return this.amazonProducts.length ? this.amazonProducts[0] ? Object.keys(this.amazonProducts[0]).map(key => ({
                text: key.replaceAll('_',' '),
                value: key,
            })) : [] : []
        },
        extraColumns() {
            return this.headers.filter(header => !this.excludeColumns.includes(header.value))
        }
    },
}
</script>

<style>

</style>