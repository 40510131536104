<template>
    <v-card class="px-4 py-4" width="30vw">
        <v-card-title class="d-flex justify-center">Fix Credit Notes From a List of Credit Note Ids</v-card-title>
        Copy then Paste data from Excel
        <v-textarea
            outlined
            name="orderInput"
            v-model="orders"/>
        <v-card-actions class="d-flex justify-center">
            <v-btn
                :disabled="disableFixOrdersButton"
                @click="fixOrders()"
                :loading="loading"
                color="primary">
                Fix Credit Notes
            </v-btn>
        </v-card-actions>
        <v-card-text class="pt-0 d-flex justify-center">
            {{ orderCount }}
        </v-card-text>
        <v-card-text v-if="stringInOrderIds && orders.length" class="red--text d-flex justify-center">
            Credit Note Ids Can Only Have Numbers
        </v-card-text>
        <v-snackbar
            v-model="snackActive"
            :color="snackColor"
            :timeout="snackTimeout">
            {{ snackText }}
        </v-snackbar>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            snackText: 'success',
            snackColor: 'success',
            snackTimeout: 3000,
            snackActive: false,
            loading: false,
            orders: "",
        }
    },
    methods: {
        fixOrders() {
            const url = 'https://1qsj72aerg.execute-api.us-east-2.amazonaws.com/prod/sales/database/updateorders'
            this.orders = this.orders.trim()
            let formattedOrders = this.orders.split(/\r?\n/).map(item => {
                    return parseInt(item, 10)
                })
            let data = {
                url: "https://api.cin7.com/api/v1/CreditNotes?where=id IN (" + formattedOrders.join(",") + ")&rows=250&page="
            }

            const request = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            this.loading = true
            fetch(url, request).then(async response => { 
                const data = await response.json()
            
                if (!response.ok) {
                    const error = data.message || response.status
                    return Promise.reject(error)
                }
                this.loading = false
                this.snack('Complete', 'success', 3000)
            }).catch(error => {
                this.loading = false
                this.snack(error, 'error', 3000)
            })
        },
        snack(text, color, timeout) {
            this.snackText = text;
            this.snackColor = color;
            this.snackTimeout = timeout;
            this.snackActive = true;
        },
    },
    computed: {
        disableFixOrdersButton() {
            if (this.orders.length >= 5 && !this.stringInOrderIds) {
                return false
            } else {
                return true
            }
        },
        orderCount() {
            return "Credit Note Count: " + this.orders.split(/\r?\n/).length
        },
        stringInOrderIds() {
            let orderCheck = this.orders.split(/(?:\r\n|\r|\n)/g).join("")
            if (!/^\d+$/.test(orderCheck)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style>

</style>