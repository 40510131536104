<template>
<v-container>
  <v-col>
  <v-row>
      <v-date-picker
        v-model="dates"
        range
        @input="changeDate()"
      ></v-date-picker>
  </v-row>
  <v-row>
      <v-text-field
        v-model="dateRangeText"
        label="Date range"
        prepend-icon="mdi-calendar"
        @input="changeDate()"
        readonly
      ></v-text-field>
  </v-row>
  </v-col>
</v-container>
</template>

<script>
  export default {
    beforeMount() {
      const d = new Date()
      let sunday = new Date()
      let monday = new Date()
      sunday.setDate(d.getDate() - d.getDay());
      monday.setDate(d.getDate() - d.getDay() - 6)

      this.dates[1] = sunday.getFullYear() + '-' + ('0' + (sunday.getMonth()+1)).slice(-2) + '-' + ('0' + sunday.getDate()).slice(-2);
      this.dates[0] = monday.getFullYear() + '-' + ('0' + (monday.getMonth()+1)).slice(-2) + '-' + ('0' + monday.getDate()).slice(-2);
    },
    data: () => ({
      dates: ['2021-09-10', '2021-09-20']
    }),
    computed: {
      dateRangeText () {
        return this.dates.join(' - ')
      },
    },
    watch: {
      dates: function() {
        if (this.dates) {
          this.changeDate();
        }
      }
    },
    methods: {
      changeDate: function() {
        this.$emit('input', this.dates);
      }
    }
  }
</script>
