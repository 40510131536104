import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
//sets other settings if it is not already saved in the browser
if (!localStorage.otherSettings) {
    localStorage.otherSettings = JSON.stringify({
        showTotalRow: false, 
        hideModalAfterSubmit: true, 
        speedRound: true, 
        saveOnMonthChange: true, 
        hideInactiveProducts: true, 
        specificForecastProducts: false,
        showPriceWarning: false
    })
}
if (!localStorage.color) {
    localStorage.color = JSON.stringify({
        name: 'Default',
        primary: '#000000',
        secondary: '#FFFFFF',
        accent: '#ADD8E6',
        id: 0
    })
}

const dataState = createPersistedState({
    paths: ['data']
})

export default new Vuex.Store({
    plugins: [dataState],
    state: {
        preorder: { switch: false, discount: 0 },
        otherSettings: JSON.parse(localStorage.otherSettings),
        errors:[],
        navbarContent: [],
        navbarTitle: 'Matador',
        user: {
            id: 0,
            name: 'Name',
            email: '',
        },
        color: JSON.parse(localStorage.color),
        loggedIn: false,
        permissions: [],
        showWaffle: true,
        selectedAccount: {},
        clickedCellId: '',
    },
    getters: {
        getClickedCellId(state) {
            return state.clickedCellId
        },
        getOtherSettings(state) {
            return state.otherSettings
        },
        getPreorder(state) {
            return state.preorder
        },
        getNavbarTitle(state) {
            return state.navbarTitle
        },
        getNavbarContent(state) {
            return state.navbarContent
        },
        getUsername(state) {
            return state.user.name
        },
        isLoggedIn(state) {
            return state.loggedIn
        },
        getUserId(state) {
            return state.user.id
        },
        getPermissions(state) {
            return state.permissions
        },
        getColor(state) {
            return state.color
        },
        getShowWaffle(state) {
            return state.showWaffle
        },
        getUserEmail(state) {
            return state.user.email
        },
        getSelectedAccount(state) {
            return state.selectedAccount
        }
    },
    mutations: {
        CHANGE_CLICKED_CELL_ID(state, id) {
            state.clickedCellId = id
        },
        CHANGE_OTHER_SETTINGS (state, otherSettings) {
            state.otherSettings = otherSettings
        },
        CHANGE_PRICE_WARNING(state, priceWarning) {
            state.otherSettings.showPriceWarning = priceWarning
        },
        CHANGE_PREORDER(state, preorder) {
            state.preorder = preorder
        },
        ERROR(state, error) {
            state.errors.push(error)
        },
        CHANGE_PREORDER_DISCOUNT(state, discount) {
            state.preorder.discount = discount
        },
        CHANGE_NAVBAR_CONTENT(state, content) {
            state.navbarContent = content
        },
        CHANGE_NAVBAR_TITLE(state, title) {
            state.navbarTitle = title
        },
        CHANGE_USER(state, user) {
            state.user = user
        },
        CHANGE_LOGGED_IN(state, loggedIn) {
            state.loggedIn = loggedIn
        },
        CHANGE_PERMISSIONS(state, permissions) {
            state.permissions = permissions
        },
        CHANGE_COLOR(state, color) {
            state.color = color
        },
        CHANGE_SHOW_WAFFLE(state, waffle) {
            state.showWaffle = waffle
        },
        CHANGE_SELECTED_ACCOUNT(state, account) {
            state.selectedAccount = account
        }
    },
    actions: {
        updateClickedCellId({commit}, id) {
            try {
                commit('CHANGE_CLICKED_CELL_ID', id)
            } catch (error) {
                commit('ERROR', error)
            }
        },
        updateNavbarContent({commit}, content) {
            try {
                commit('CHANGE_NAVBAR_CONTENT', content)
            } catch (error) {
                commit('ERROR', error)
            }
        },
        updateNavbarTitle({commit}, title) {
            try {
                commit('CHANGE_NAVBAR_TITLE', title)
            } catch (error) {
                commit('ERROR', error)
            }
        },
        updateOtherSettings({commit},otherSettings) {
            try {
                commit('CHANGE_OTHER_SETTINGS', otherSettings)
            } catch (error) {
                commit('ERROR',error) 
            }
        },
        updatePriceWarning({commit},priceWarning) {
            try {
                commit('CHANGE_PRICE_WARNING', priceWarning)
            } catch (error) {
                commit('ERROR',error)
            }
        },
        updatePreorder({commit},preorder) {
            try {
                commit('CHANGE_PREORDER', preorder)
            } catch (error) {
                commit('ERROR',error)
            }
        },
        updatePreorderDiscount({commit},discount) {
            try {
                commit('CHANGE_PREORDER_DISCOUNT',discount)
            } catch (error) {
                commit('ERROR',error)
            }
        },
        updateUser({commit},user) {
            try {
                commit('CHANGE_USER',user)
            } catch (error) {
                commit('ERROR',error)
            }
        },
        updateLoggedIn({commit}, loggedIn) {
            try {
                commit('CHANGE_LOGGED_IN',loggedIn)
            } catch (error) {
                commit('ERROR',error)
            }
        },
        updatePermissions({commit}, permissions) {
            try {
                commit('CHANGE_PERMISSIONS',permissions)
            } catch (error) {
                commit('ERROR',error)
            }
        },
        updateColor({commit}, color) {
            try {
                commit('CHANGE_COLOR',color)
            } catch (error) {
                commit('ERROR',error)
            }
        },
        updateShowWaffle({commit}, waffle) {
            try {
                commit('CHANGE_SHOW_WAFFLE',waffle)
            } catch (error) {
                commit('ERROR',error)
            }
        },
        updateSelectedAccount({commit}, account) {
            try {
                commit('CHANGE_SELECTED_ACCOUNT',account)
            } catch (error) {
                commit('ERROR',error)
            }
        },
    }
})