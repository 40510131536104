<template>
    <v-container fluid class="d-flex justify-center align-center">
        <v-select
            v-model="selectedYear"
            @input="changeYear()"
            :items="years"
            label="Select Year"
            dense
            hide-details
            solo
        ></v-select>
    </v-container>
</template>

<script>
const fetch = require('node-fetch');

export default {
  name: 'Year',
  delimiters: ['${', '}'],
  beforeMount() {
    this.getYears();
      if (localStorage.year) {
        this.selectedYear = Number(localStorage.year)
      } else {
        let year = new Date().getFullYear();
        this.selectedYear = Number(year)
        localStorage.year = this.selectedYear
      }
    this.changeYear()
  },
  data: () => ({
    years: [],
    selectedYear: 0,
  }),
  methods: {
    getYears: function() {
      const url = 'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/years';
      const request = {
        method: 'GET',
        Headers: {
          'Content-Type': 'application/json',
        },
      };
      fetch(url, request).then((response) => {
        return response.json();
      }).then((data) => {
        // turn the data back into an object since it
        const yearObject = JSON.parse(data.body);
        for (const year in yearObject) {
          if (yearObject[year].Year !== null) {
            this.years.push(yearObject[year].Year);
          }
        }
        // adds an extra year on the end of all the years in the Order_Data table
        this.years.push(parseInt(this.years[this.years.length - 1], 10) + 1);
      });
    },
    changeYear: function() {
      localStorage.year = this.selectedYear
      this.$emit('input', this.selectedYear);
    },
  },
};
</script>
