<template>
  <v-app>
    <v-main>
      <template>
        <v-row>
          <v-col cols = "2">
            <DateSelector v-model="dates"></DateSelector>
          </v-col>
          <v-col cols = "10">
            <template>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Matador Facebook Campaigns
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Facebook
                      :inputtedDate="finalDates"
                      :channel="MatadorChannel"
                    ></Facebook>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Tiny Tents Facebook Campaigns
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Facebook
                      :inputtedDate="finalDates"
                      :channel="TinyTentsChannel"
                    ></Facebook>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Microsend Facebook Campaigns
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Facebook
                      :inputtedDate="finalDates"
                      :channel="MicrosendChannel"
                    ></Facebook>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Amazon Countries
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Amazon
                      :inputtedDate="finalDates"
                    ></Amazon>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Attentive Subscribers / Facebook Followers
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <SingleValues
                      :inputtedDate="finalDates"
                    ></SingleValues>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Matador Metrics
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Shopify
                      :inputtedDate="finalDates"
                      :channel="MatadorChannel"
                    ></Shopify>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Tiny Tents Metrics
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Shopify
                      :inputtedDate="finalDates"
                      :channel="TinyTentsChannel"
                    ></Shopify>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Mircosend Metrics
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Shopify
                      :inputtedDate="finalDates"
                      :channel="MicrosendChannel"
                    ></Shopify>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Upsell App
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Upsell
                      :inputtedDate="finalDates"
                    ></Upsell>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <template>
              <v-btn
                color="primary"
                elevation="2"
                small
                @click="sendEmail"
                :disabled="submitDisabled"
              >Submit</v-btn>
              <v-snackbar
                  v-model="snack"
                  :timeout="3000"
                  :color="snackColor"
                  >
                  {{ snackText }}

                  <template v-slot:action="{ attrs }">
                      <v-btn
                      v-bind="attrs"
                      text
                      @click="snack = false"
                      >
                      Close
                      </v-btn>
                  </template>
              </v-snackbar>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-main>
  </v-app>
</template>

<script>

import DateSelector from './DateSelector';
import Amazon from './amazon';
import Facebook from './facebook';
import SingleValues from './singleValues'
import Shopify from './shopify';
import Upsell from './upsell'
import fetch from 'node-fetch'
export default {
  name: 'Marketing Dashboard',
  components: {
    DateSelector,
    Amazon,
    Facebook,
    SingleValues,
    Shopify,
    Upsell,
  },
  beforeMount() {
    document.title = 'Sandbox - Marketing Dashboard'
    this.$store.dispatch('updateNavbarContent', [])
    this.$store.dispatch('updateNavbarTitle','Marketing Dashboard')
    const d = new Date()
    let sunday = new Date()
    let monday = new Date()
    sunday.setDate(d.getDate() - d.getDay());
    monday.setDate(d.getDate() - d.getDay() - 6)

    this.dates[1] = sunday.getFullYear() + '-' + ('0' + (sunday.getMonth()+1)).slice(-2) + '-' + ('0' + sunday.getDate()).slice(-2);
    this.dates[0] = monday.getFullYear() + '-' + ('0' + (monday.getMonth()+1)).slice(-2) + '-' + ('0' + monday.getDate()).slice(-2);
    this.dates = this.dates.sort((a,b) => a - b)
  },
  data: () => ({
    dates: [],
    MatadorChannel: "Matador",
    TinyTentsChannel: "TinyTents",
    MicrosendChannel: "Microsend",
    snack: false,
    snackColor: '',
    snackText: '',
    submitDisabled: false,

  }),
  methods: {
    sendEmail: function() {
      this.snack = true;
      this.snackColor = 'success';
      this.snackText = 'Email Sent - Please Wait. Refresh Page To Submit Again';
      this.submitDisabled = true;

      const request = {
        method: 'GET',
        Headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
        },
      }
      const url = "https://dxo2z89hkb.execute-api.us-east-2.amazonaws.com/prod/email"

      fetch(url,request);

    }
  },
  computed: {
    finalDates() {
      let final = {};
      if (this.dates[0] && this.dates[1]) {
        final = {
          startDate: this.dates[0],
          endDate: this.dates[1]
        }
      } else {
        final = {
          startDate: null,
          endDate: null
        };
      }
      return final;
    }
  }
};
</script>
