<template style="overflow-y: hidden;">
    
    <v-container class="pt-8">
        <!-- mobile version -->
        <div v-if="this.$vuetify.breakpoint.mobile" class="pt-8" style="overflow-y: hidden; overflow-x: hidden;">
            <v-card height="45vh" width="100vw">
                <customMap
                    :markers="markers"
                    :center="center"
                    @center="center = $event"
                    :selectedMarker="selectedMarker"
                    @selected-marker="selectedMarker = $event"/>
            </v-card> 
            <v-card height="45vh" width="100vw">
                <list
                    :markers="markers"
                    :center="center"
                    @center="center = $event"
                    :selectedMarker="selectedMarker"
                    @selected-marker="selectedMarker = $event"
                    @products="contactId = $event"/>
            </v-card>
        </div>
        <!-- desktop version -->
        <v-row v-else class="pt-12" style="overflow-y: hidden; overflow-x: hidden; height: 90vh;">
            <v-col cols="9" class="pr-0 mr-0 pl-4" style="height: 100%;">
                <customMap
                    :markers="markers"
                    :center="center"
                    :selectedMarker="selectedMarker"
                    @selected-marker="selectedMarker = $event"
                    @center="center = $event"/>
            </v-col>
            <v-col cols="3" class="pl-0 ml-0" style="height: 100%;">
                <list
                    :markers="markers"
                    :selectedMarker="selectedMarker"
                    :center="center"
                    @center="center = $event"
                    @selected-marker="selectedMarker = $event"
                    @products="contactId = $event"/>
            </v-col>
            <v-snackbar
                v-model="snackBar"
                :timeout="timeout"
                :color="snackColor">
                {{ snackText }}
            </v-snackbar>
        </v-row>
        <products
            @contact="contactId = $event"
            :contact="contactId"/>
    </v-container>
</template>

<script>
import customMap from './components/map.vue'
import list from './components/list.vue'
import products from './components/products.vue'

export default {
    components: {
        customMap,
        list,
        products
    },
    async beforeMount() {
        this.$store.dispatch('updateShowWaffle', false)
        document.title = 'Matador Store Locator'
        this.$store.dispatch('updateNavbarContent', [])
        this.$store.dispatch('updateNavbarTitle','Store Locator')
        await this.getInfo()
        
    },
    data() {
        return {
            markers: [],
            center: {
                lat: 40.018698, 
                lng: -105.245614
            },
            contactId: 0,
            selectedMarker: {},
            menu: false,
            infoWindowPos: null,
            searchLocation: {},
            infoWinOpen: false,
            currentMidx: null,
            tab: 0,
            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            zoom: 13,
            loading: false,
            snackBar: false,
            snackText: '',
            snackColor: 'success',
            timeout: 3000,
        }
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true
                const result = await fetch(
                    //Lambda Function: https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/StoreLocator_GetMarkers?tab=code
                    `https://q70b0pmeae.execute-api.us-east-2.amazonaws.com/prod/get-markers?email=austin@matadorup.com`,
                    {
                        method: 'GET',
                        Headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                const {
                    message,
                    markers
                } = await result.json()
            
                if(!result.ok) {
                    throw message
                }
                this.markers = markers
                
                this.loading = false
                this.snack(message,'success',3000)
            } catch (error) {
                this.loading = false
                this.snack(error,'error',3000)
            }
        },
        snack(text,color,timeout) {
            this.snackBar = true
            this.snackText = text
            this.snackColor = color
            this.timeout = timeout
        },
    }
}
</script>

<style>

</style>