<template>
    <v-dialog v-model="dialog" width="50vw">
        <v-card style="overflow: hidden;">
            <v-card-title class="d-flex justify-center align-center">
                {{ issue }}
            </v-card-title>
            <v-card-text v-if="type !== list">
                <div v-if="type === 'both'">
                <v-row>
                    <v-col>
                        <InfoBlock 
                            :list="list" 
                            :monthName="monthName" 
                            :year="year" 
                            :name="costName" 
                            title="Cost List"/>
                    </v-col>
                    <v-divider vertical/>
                    <v-col>
                        <InfoBlock 
                            :list="list" 
                            :monthName="monthName" 
                            :year="year" 
                            :name="priceName" 
                            title="Price List"/>
                    </v-col>
                </v-row>
                </div>
                <div v-else>
                    <InfoBlock 
                        :list="list" 
                        :monthName="monthName" 
                        :year="year" 
                        :name="type === 'cost' ? costName : priceName" 
                        :title="type === 'cost' ? 'Cost List' : 'Price List'"/>
                </div>
            </v-card-text>
            <v-card-text v-else class="d-flex justify-center align-center">
                There is no Cost/Price List (FW22, SS23, FW23, etc.) assigned to {{ monthName }} {{ year }}<br>
                Please add a Cost/Price List to cover this date and upload prices and costs for each sku 
                that will be forecasted
            </v-card-text>
            <v-row v-if="type !== 'list'" justify="space-around" align="center">
                <v-col 
                    class="ml-4 mr-2">
                    <v-text-field 
                        v-if="type === 'cost' || type === 'both'"
                        prefix="$"
                        type="number"
                        class="centered-input pb-2"
                        label="Cost"
                        v-model="newCost"
                        hide-details/>
                </v-col>
                <v-col 
                    class="ml-2 mr-4">
                    <v-text-field 
                        v-if="type === 'price' || type === 'both'"
                        label="Price"
                        prefix="$"
                        type="number"
                        class="centered-input pb-2"
                        v-model="newPrice"
                        hide-details/>
                </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-space-around align-center">
                <v-btn 
                    color="secondary" 
                    @click="$emit('close')">
                    Cancel
                </v-btn>
                <v-btn 
                    v-if="type !== 'list'"
                    color="primary" 
                    :loading="loading"
                    :disabled="costPriceListNotFoundSubmitDisabled" 
                    @click="manageSubmit()">
                    Save
                </v-btn>
                <v-btn 
                    v-else
                    color="primary" 
                    :to="{name: 'Forecast Products', params: {newList: true} }">
                    Add List
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import InfoBlock from './priceNotFoundInfoBlock.vue'
export default {
    components: {
        InfoBlock
    },
    props: {
        dialog: {type: Boolean},
        month: {type: Number},
        sku: {type: String},
        year: {type: Number},
        priceName: {type: String},
        costName: {type: String},
        priceNotFoundInfo: {type: Object},
    },
    data() {
        return {
            monthNames: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            newCost: 0,
            newPrice: 0,
            loading: false,
        }
    },
    methods: {
        async manageSubmit() {
            this.loading = true
            if (this.type === 'cost' || this.type === 'price') {
                await this.submit(this.type)
            } else if (this.type === 'both') {
                await this.submit('cost')
                await this.submit('price')
            }
            this.loading = false
        },
        async submit(type) {
            let listName = this.$props.priceNotFoundInfo.listName
            const json = [{
				SKU: this.$props.sku,
				List_Name: listName
			}]
			let currentCategories = []

			if (type === 'price') {
				json[0][this.$props.priceName] = this.newPrice
				currentCategories[0] = {Name: this.$props.priceName}
			} 
			if (type === 'cost') {
				json[0][this.$props.costName] = this.newCost
				currentCategories[0] = {Name: this.$props.costName}
			}

			try {
				const result = await fetch(
					'https://ww62v3jt5m.execute-api.us-east-2.amazonaws.com/prod/productdetails/productpricesandcosts',
					{
						method: 'PUT',
						Headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							data: json,
							type: type,
							currentCategories: currentCategories
						})
					}
				)

				const {
					response
				} = await result.json()
                
                console.log(response)

                this.$emit('Update-Price-Cost',{
                    sku: this.$props.sku,
                    listName: listName,
                    year: this.$props.year,
                    month: this.$props.month,
                    type: type,
                    value: type === 'price' ? this.newPrice : this.newCost
                })
                this.$emit('close')
            } catch (error) {
                console.log(error)
            }

        }
    },
    computed: {
        costPriceListNotFoundSubmitDisabled() {
			if (this.type === 'cost' && this.newCost !== 0) {
				return false
			} else if (this.type === 'price' && this.newPrice !== 0) {
				return false
			} else if (this.type === 'both' && this.newPrice !== 0 && this.newCost !== 0) {
				return false
			} else {
				return true
			}
		},
        monthName() {
            return this.monthNames[this.month - 1]
        },
        type() {
            return this.$props.priceNotFoundInfo.type
        },
        list() {
            return this.$props.priceNotFoundInfo.listName
        },
        listId() {
            return this.$props.priceNotFoundInfo.listId
        },
        issue() {
            if (this.$props.priceNotFoundInfo.type === 'cost') {
                return 'Cost Not Found for ' + this.$props.sku
            } else if (this.$props.priceNotFoundInfo.type === 'price') {
                return 'Price Not Found for ' + this.$props.sku
            } else if (this.$props.priceNotFoundInfo.type === 'both') {
                return 'Cost and Price Not Found ' + this.$props.sku
            } else {
                return ''
            }
        }
    }
}
</script>